import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './budget.scss';
// import { DepartmentCard } from './budgetCardsUi_v2';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CallMadeIcon from '@material-ui/icons/CallMade';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Label } from 'recharts';
import { BudgetSendBtn, SmallLinkedButton } from './budgetButton';
import { BasicTable } from './table';

import formatters from '../../../../../Core/Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';

const setPercentage = (profit, budget) => {
    let value = (profit / budget) * 100;
    return Math.round(value);
};

const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));

export const DepartmentCard = (props) => {
    // let setWidthByScreenSize = () => {
    //     let widthSize = 160;
    //     if (WindowWidths > 1370) {
    //         widthSize = 160;
    //     } else if (WindowWidths >= 1280 && WindowWidths <= 1370) {
    //         widthSize = 130;
    //     } else if (WindowWidths >= 1187 && WindowWidths <= 1280) {
    //         widthSize = 100;
    //     } else if (WindowWidths <= 1187) {
    //         widthSize = 80;
    //     }

    //     return widthSize;
    // };

    const { value, profit, budget } = props;
    const [WindowWidths, WindowHeights] = useWindowSize();

    return (
        <Grid container style={{ textAlign: 'justify' }} justifyContent="space-around" alignItems="center" className="BOprofitloss_Card">
            <Grid item>
                <div className="ringProperty">
                    <CircularProgressbar
                        value={setPercentage(profit, budget)}
                        text={`${setPercentage(profit, budget) || 0}%`}
                        styles={buildStyles({
                            textColor:
                                setPercentage(profit, budget) < 50
                                    ? '#D92641'
                                    : setPercentage(profit, budget) >= 50 && setPercentage(profit, budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(profit, budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            pathColor:
                                setPercentage(profit, budget) < 50
                                    ? '#D92641'
                                    : setPercentage(profit, budget) >= 50 && setPercentage(profit, budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(profit, budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            textSize: '27px'
                        })}
                    />
                </div>
            </Grid>
            {/* <Grid item={2}></Grid> */}

            <Grid item xs={7}>
                <div className="BOsecondaryHeading" style={{ padding: '0px 0px 12px 0px' }}>
                    {props.dept}
                </div>
                <div style={{ marginBottom: 5, display: 'flex', justifyContent: 'space-between' }}>
                    <div className="BOtype3_Text w50">Total Profit</div>
                    <div className="BOtype1_Text alignLeft w50">{formatters.CurrencyThousandSeparatorWithoutZero(profit)}</div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="BOtype3_Text alignLeft w50">Total Budget</div>
                    <div className="BOtype1_Text alignLeft w50">{formatters.CurrencyThousandSeparatorWithoutZero(budget)}</div>
                </div>
            </Grid>
            {/* <Grid item></Grid> */}
        </Grid>
    );
};

export const DepartmentBudgetCard = (props) => {
    const { icon, value, label, profitVal, showLink } = props;
    return (
        <div className="BObranchOverViewKip_card" style={{ margin: '12px 6px' }}>
            {showLink ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '7px 0px' }}>
                    <BudgetSendBtn />
                </div>
            ) : null}

            <div className="BOtype1_Text" style={{ paddingBottom: '4px' }}>
                {value || 0}
            </div>
            <div className="BOtype2_Text">{label || 'N/A'}</div>
            <div className="BOtype3_Text" style={{ paddingTop: '12px' }}>
                BUDGET
            </div>
            <div
                className="BOtype1_Text p4"
                style={{
                    color: icon == 'up' ? '#569101' : '#E51A54'
                    // fontSize: 26
                }}
            >
                <span>{profitVal || 0}</span>
                <span style={{ verticalAlign: 'middle' }}>
                    {icon === 'down' ? <ArrowDownwardIcon className="BOtype2_Text" /> : <ArrowUpwardIcon className="BOtype2_Text" />}
                </span>
            </div>
        </div>
    );
};

export const ProfitLossCards = (props) => {
    const [WindowWidths, WindowHeights] = useWindowSize();

    const { value, profit, budget, branchName, retailsUnits, motabilityUnits, tradeUnits, newUnits, usedUnits, branchID } = props;
    return (
        <Grid container justifyContent="space-around" alignItems="center" className="SPnLprofitloss_Card" style={{ padding: '0px 10px ' }}>
            <Grid item xs={12}>
                <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between' }}>
                    <div className="SPnLsecondaryHeading p4">{branchName}</div>
                    <BudgetSendBtn onClick={() => props.onRedirectFun(branchID)} />
                </div>
            </Grid>
            <Grid item>
                <div className="PanLringProperty">
                    <CircularProgressbar
                        value={setPercentage(profit, budget)}
                        text={`${setPercentage(profit, budget)}%`}
                        styles={buildStyles({
                            textColor:
                                setPercentage(profit, budget) < 50
                                    ? '#D92641'
                                    : setPercentage(profit, budget) >= 50 && setPercentage(profit, budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(profit, budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            pathColor:
                                setPercentage(profit, budget) < 50
                                    ? '#D92641'
                                    : setPercentage(profit, budget) >= 50 && setPercentage(profit, budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(profit, budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            textSize: '27px',
                            dominantBaseline: 'central'
                        })}
                    />
                </div>
            </Grid>
            <Grid item>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="SPnLtype3_Text alignLeft ">Total Profit</div>
                    <div className="SPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(profit)}</div>
                </div>

                <div style={{ width: '100%' }}>
                    <div className="SPnLtype3_Text alignLeft ">Total Budget</div>
                    <div className="SPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(budget)}</div>
                </div>
            </Grid>
            {props.selectedFilterBtnId == 'New' || props.selectedFilterBtnId == 'Used' ? (
                <Grid item>
                    <div style={{ width: '100%', marginBottom: 10 }}>
                        <div className="SPnLtype3_Text alignLeft ">Retail Units</div>
                        <div className="SPnLtype1_Text  alignLeft">{formatters.ThousandSeparatorWithoutZero(retailsUnits)}</div>
                    </div>

                    <div style={{ width: '100%' }}>
                        <div className="SPnLtype3_Text alignLeft ">{props.selectedFilterBtnId == 'New' ? 'Motability Units' : 'Trade Units'}</div>
                        <div className="SPnLtype1_Text  alignLeft">
                            {props.selectedFilterBtnId == 'New'
                                ? formatters.ThousandSeparatorWithoutZero(motabilityUnits)
                                : formatters.ThousandSeparatorWithoutZero(tradeUnits)}
                        </div>
                    </div>
                </Grid>
            ) : (
                <Grid item style={{ margin: '10px 0px' }}>
                    <div style={{ width: '100%', marginBottom: 10 }}>
                        <div className="SPnLtype3_Text alignLeft">New Units</div>
                        <div className="SPnLtype1_Text  alignLeft">{formatters.ThousandSeparatorWithoutZero(newUnits)}</div>
                    </div>

                    <div style={{ width: '100%' }}>
                        <div className="SPnLtype3_Text alignLeft">Used Units</div>
                        <div className="SPnLtype1_Text  alignLeft">{formatters.ThousandSeparatorWithoutZero(usedUnits)}</div>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export const ASalePandLCard = (props) => {
    const [WindowWidths, WindowHeights] = useWindowSize();

    const { value, profit, budget } = props;
    return (
        <Grid container justifyContent="space-around" alignItems="center" className="ASPnLprofitloss_Card">
            <Grid item xs={12}>
                <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between' }}>
                    <div className="ASPnLsecondaryHeading p4">{props.label}</div>
                    <BudgetSendBtn />
                </div>
            </Grid>
            <Grid item>
                <div className="PanLringProperty">
                    <CircularProgressbar
                        value={value || 0}
                        text={`${value || 0}%`}
                        styles={buildStyles({
                            textColor: value < 50 ? '#D92641' : value >= 50 && value < 75 ? '#ff9f00' : value >= 75 ? '#569101' : '#D92641',
                            pathColor: value < 50 ? '#D92641' : value >= 50 && value < 75 ? '#ff9f00' : value >= 75 ? '#569101' : '#D92641',
                            textSize: '27px'
                        })}
                    />
                </div>
            </Grid>
            <Grid item style={{ margin: 10 }}>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="ASPnLtype3_Text alignLeft ">Total Profit</div>
                    <div className="ASPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(profit)}</div>
                </div>

                <div style={{ width: '100%' }}>
                    <div className="ASPnLtype3_Text alignLeft ">Total Budget</div>
                    <div className="ASPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(budget)}</div>
                </div>
            </Grid>
        </Grid>
    );
};

export const DetailsCards = (props) => {
    const { up, value, label, profitVal } = props;
    return (
        <div className="containerPadding">
            <Grid container className="PnLDbranchOverViewKip_card" alignItems="center" alignContent="center">
                <Grid item xs={12}>
                    <div className="PnLDtype3_Text p4">{label || 'N/A'}</div>
                </Grid>
                <Grid item xs={12}>
                    <div className="PnLDtype1_Text p4">{value || 0}</div>
                </Grid>
                <Grid item xs={12}>
                    <div className="PnLDtype3_Text p4">BUDGET</div>
                </Grid>

                <Grid item xs={12}>
                    <div
                        className="PnLDtype1_Text p4"
                        style={{
                            padding: 0,
                            color: up ? '#569101' : '#E51A54'
                        }}
                    >
                        <span>{profitVal || 0}</span>
                        <span style={{ verticalAlign: 'middle' }}>
                            {!up ? <ArrowDownwardIcon className="PnLDtype2_Text" /> : <ArrowUpwardIcon className="PnLDtype2_Text" />}
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export const DetailsCardsWithoutBudget = (props) => {
    const { value, label, up, showBudget } = props;
    return (
        <div className="containerPadding">
            <Grid container className="PnLDbranchOverViewKip_card" alignItems="center" alignContent="center">
                <Grid item xs={12}>
                    <div className="PnLDtype3_Text p4">{label || 'N/A'}</div>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <div
                            className="PnLDtype1_Text p4"
                            style={{
                                color: showBudget ? (!up ? '#E51A54' : '#569101') : 'black'
                            }}
                        >
                            <span>{value || 0}</span>
                            <span style={{ verticalAlign: 'middle' }}>
                                {showBudget ? !up ? <ArrowDownwardIcon className="PnLDtype2_Text" /> : <ArrowUpwardIcon className="PnLDtype2_Text" /> : null}
                            </span>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export const ProfitLossDetailsCards = (props) => {
    const [WindowWidths, WindowHeights] = useWindowSize();

    const { data } = props;
    return (
        <Grid container justifyContent="space-around" alignItems="center" className="kpiCard">
            <Grid item xs={12}>
                <div style={{ display: 'flex', alignTtems: 'center', alignContent: 'center', justifyContent: 'space-between' }}>
                    <div className="PnLDsecondaryHeading p4">{props.label}</div>
                </div>
            </Grid>
            <Grid item>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="PnLDtype3_Text alignLeft ">Total Profit</div>
                    <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.profit)}</div>
                </div>

                <div style={{ width: '100%' }}>
                    <div className="PnLDtype3_Text alignLeft ">Budget Variance</div>
                    <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.variance)}</div>
                </div>
            </Grid>
            <Grid item>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="PnLDtype3_Text alignLeft">Total Budget</div>
                    <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.budget)}</div>
                </div>

                <div style={{ width: '100%' }}>
                    {data.ppu ? (
                        <>
                            <div className="PnLDtype3_Text alignLeft">Total PPU</div>
                            <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.ppu)}</div>
                        </>
                    ) : (
                        <>
                            <div className="PnLDtype3_Text alignLeft">&nbsp;</div>
                            <div className="PnLDtype1_Text  alignLeft">&nbsp;</div>
                        </>
                    )}
                </div>
            </Grid>
            <Grid item>
                <div className="PanLringProperty">
                    <CircularProgressbar
                        value={setPercentage(data?.profit, data?.budget)}
                        text={`${setPercentage(data?.profit, data?.budget)}%`}
                        styles={buildStyles({
                            textColor:
                                setPercentage(data?.profit, data?.budget) < 50
                                    ? '#D92641'
                                    : setPercentage(data?.profit, data?.budget) >= 50 && setPercentage(data?.profit, data?.budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(data?.profit, data?.budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            pathColor:
                                setPercentage(data?.profit, data?.budget) < 50
                                    ? '#D92641'
                                    : setPercentage(data?.profit, data?.budget) >= 50 && setPercentage(data?.profit, data?.budget) < 75
                                    ? '#ff9f00'
                                    : setPercentage(data?.profit, data?.budget) >= 75
                                    ? '#569101'
                                    : '#D92641',
                            textSize: '27px'
                        })}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <BasicTable data={props.table} />
            </Grid>
            <Grid item xs={12}></Grid>
        </Grid>
    );
};

export const UsedStocksCard = (props) => {
    const { CurrentStockValue, AverageStockValue, PreviousYearAverage } = props;
    const handleRedirect = (link, branchID) => {
        let searchQuery = `?isTrue=${true}`;
        let array = [];
        if (branchID) {
            array.push(branchID);
        }
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: array }));
        props.history.push({
            pathname: link,
            search: searchQuery
        });
    };

    return (
        <div>
            <Grid container className="USVUnitKipTwo_card">
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className="USVsecondaryHeading alignLeft">{props.branch}</div>
                                </Grid>
                                <Grid item>
                                    <div style={{ width: '100%' }}>
                                        <div className="USVtype3_Text">Current Stock Value</div>
                                        <div className="USVtype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(CurrentStockValue)}</div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{ width: '100%', marginBottom: 5, display: 'flex' }}>
                                        <div className="USVtype3_Text">Average Stock Value</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(AverageStockValue)}
                                        </div>
                                    </div>

                                    <div style={{ width: '100%', display: 'flex' }}>
                                        <div className="USVtype3_Text">Previous Year Average</div>&nbsp;&nbsp;&nbsp;
                                        <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(PreviousYearAverage)}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <SmallLinkedButton
                                        style={{ marginBottom: 5 }}
                                        active={props.active}
                                        onClick={() => handleRedirect(props.stockLink, props.branchID)}
                                    >
                                        STOCK LIST &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                                    </SmallLinkedButton>
                                </Grid>

                                <Grid item>
                                    <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)}>
                                        OVERAGE &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                                    </SmallLinkedButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                    <div className="secondaryHeading p4 alignLeft">{props.branch}</div>
                </Grid>
                <Grid item>
                    <div style={{ width: '100%' }}>
                        <div className="type3_Text">Current Stock Value</div>
                        <div className="type1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(CurrentStockValue)}</div>
                    </div>
                </Grid>

                <Grid item>
                    <div style={{ display: 'block' }}>
                        <SmallLinkedButton style={{ marginBottom: 5 }} active={props.active} onClick={() => handleRedirect(props.stockLink, props.branchID)}>
                            STOCK LIST &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                        </SmallLinkedButton>

                        <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)}>
                            OVERAGE &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                        </SmallLinkedButton>
                    </div>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <div style={{ width: '100%', marginBottom: 5, display: 'flex' }}>
                        <div className="type3_Text">Average Stock Value</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div className="type3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(AverageStockValue)}
                        </div>
                    </div>

                    <div style={{ width: '100%', display: 'flex' }}>
                        <div className="type3_Text">Previous Year Average</div>&nbsp;&nbsp;&nbsp;
                        <div className="type3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(PreviousYearAverage)}
                        </div>
                    </div>
                </Grid> */}
            </Grid>
        </div>
    );
};

export const UsedStocksCardMobileView = (props) => {
    const { CurrentStockValue, AverageStockValue, PreviousYearAverage, showStock, showOverage } = props;
    const handleRedirect = (link, branchID) => {
        let array = [];
        if (branchID) {
            array.push(branchID);
        }
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: array }));
        props.history.push({
            pathname: link
        });
        if (props.screenReload) {
            props.screenReload(new Date());
        }
    };

    return (
        <div>
            <Grid container className="USVUnitKipTwo_card" spacing={0}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={7}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className="USVsecondaryHeading alignLeft">{props.branch}</div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={5}>
                            <Grid container justifyContent="flex-end">
                                {showStock && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton
                                            // style={{ marginBottom: 5 }}
                                            active={props.active}
                                            onClick={() => handleRedirect(props.stockLink, props.branchID)}
                                        >
                                            STOCK LIST
                                            {/* <CallMadeIcon style={{ fontSize: 10 }} /> */}
                                        </SmallLinkedButton>
                                    </Grid>
                                )}

                                {showOverage && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)}>
                                            OVERAGE
                                            {/* <CallMadeIcon style={{ fontSize: 10 }} /> */}
                                        </SmallLinkedButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <div style={{ width: '100%', marginTop: 0 }}>
                                <div className="USVtype3_Text">Current Stock Value</div>
                                <div className="USVtype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(CurrentStockValue)}</div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', marginTop: 5 }}>
                                <div>
                                    <div style={{ fontSize: 10 }}>Average Stock Value</div>
                                    <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(AverageStockValue)}
                                    </div>
                                </div>

                                <div style={{ marginLeft: 8 }}>
                                    <div style={{ fontSize: 10 }}>Previous Year Average</div>
                                    <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(PreviousYearAverage)}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            style={{
                                background: '#569101',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '12px',
                                marginTop: 10
                            }}
                        >
                            <div>
                                <div style={{ textAlign: 'center', fontWeight: 600 }}>{props.Units}</div>
                                <div style={{ fontWeight: 600 }}>UNITS</div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                    <div className="secondaryHeading p4 alignLeft">{props.branch}</div>
                </Grid>
                <Grid item>
                    <div style={{ width: '100%' }}>
                        <div className="type3_Text">Current Stock Value</div>
                        <div className="type1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(CurrentStockValue)}</div>
                    </div>
                </Grid>

                <Grid item>
                    <div>
                        <SmallLinkedButton
                            style={{ marginBottom: 5, padding: '1px 10px' }}
                            active={props.active}
                            onClick={() => handleRedirect(props.stockLink, props.branchID)}
                        >
                            STOCK
                            <span className="dIcon">
                                &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                            </span>
                        </SmallLinkedButton>

                        <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)} style={{ padding: '1px 10px' }}>
                            OVERAGE
                            <span className="dIcon">
                                &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                            </span>
                        </SmallLinkedButton>
                    </div>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <div style={{ width: '100%', marginBottom: 5, display: 'flex' }}>
                        <div className="type3_Text">Average Stock Value</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div className="type3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(AverageStockValue)}
                        </div>
                    </div>

                    <div style={{ width: '100%', display: 'flex' }}>
                        <div className="type3_Text">Previous Year Average</div>&nbsp;&nbsp;&nbsp;
                        <div className="type3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(PreviousYearAverage)}
                        </div>
                    </div>
                </Grid> */}
            </Grid>
        </div>
    );
};

export const UsedStocksCardTWO = (props) => {
    const { list } = props;
    return (
        <>
            {list.length > 0 ? (
                <Grid container justifyContent="space-evenly" alignItems="center" className="USVUnitKipThree_card">
                    {list.map((m, index) => {
                        return (
                            <Grid item>
                                <Grid container direction="column" spacing={1} alignItems="center">
                                    <Grid item className="USVtype3_Text">
                                        {m.valuationDate1}
                                    </Grid>
                                    <Grid item className="USVtype1_Text">
                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue1)}
                                    </Grid>

                                    {list.length - 1 == index ? (
                                        <Grid item className="kpifonts">
                                            &nbsp;
                                        </Grid>
                                    ) : (
                                        <Grid
                                            item
                                            className="USVtype3_Text"
                                            style={{ fontWeight: 'bold', color: m.getCalculatedvalue >= 0 ? '#569101' : '#E51A54' }}
                                        >
                                            {parseFloat(m.getCalculatedvalue).toFixed(2)}%
                                            {m.getCalculatedvalue >= 0 ? (
                                                <ArrowUpwardIcon className="USVtype3_Text" />
                                            ) : (
                                                <ArrowDownwardIcon className="USVtype3_Text" />
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            // <Grid item>
                            //     <div>
                            //         <div className="type3_Text mb5 mt5">{m.valuationDate1}</div>
                            //         <div className="type1_Text mb5">{formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue1)}</div>
                            //         {list.length - 1 == index ? (
                            //             <div className="kpifonts">&nbsp;</div>
                            //         ) : (
                            //             <div
                            //                 className="type3_Text mb5"
                            //                 style={{ fontWeight: 'bold', color: m.getCalculatedvalue >= 0 ? '#569101' : '#E51A54', padding: 0 }}
                            //             >
                            //                 {parseFloat(m.getCalculatedvalue).toFixed(2)}% &nbsp;
                            //                 {m.getCalculatedvalue >= 0 ? (
                            //                     <ArrowUpwardIcon className="type3_Text" />
                            //                 ) : (
                            //                     <ArrowDownwardIcon className="type3_Text" />
                            //                 )}
                            //             </div>
                            //         )}
                            //     </div>
                            // </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Grid container spacing={1} justifyContent="space-evenly" className="USVUnitKipThree_card" alignItems="center">
                    <Grid item>
                        <div>
                            <div className="panLlabel mb5 mt5">No Record Found</div>
                        </div>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export const AgedStockCard = (props) => {
    const { data } = props;
    const handleRedirect = (link, branchID) => {
        let array = [];
        if (branchID) {
            array.push(branchID);
        }
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: array }));
        props.history.push({
            pathname: link
        });
    };

    return (
        <>
            <Grid container className="AVSUnitKipTwo_card">
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <div className="AVStype2_Text">{data.branch}</div>
                            <div className="AVStype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data.total)}</div>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container direction="column" alignContent="flex-end" spacing={1}>
                                <Grid item>
                                    <SmallLinkedButton active={props.active} onClick={() => handleRedirect(props.stockLink, props.branchID)}>
                                        STOCK LIST &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                                    </SmallLinkedButton>
                                </Grid>
                                <Grid item>
                                    <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)}>
                                        OVERAGE &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                                    </SmallLinkedButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent="space-between" style={{ marginTop: '8px' }}>
                        <Grid item xs={7}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total CAP Clean
                                        </Grid>
                                        <Grid item className="AVStype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total Variance&nbsp;&nbsp;
                                        </Grid>
                                        <Grid
                                            item
                                            className="AVStype3_Text"
                                            style={{ textAlign: 'left', fontWeight: 'bold', color: true ? '#569101' : '#E51A54' }}
                                        >
                                            <span>{formatters.CurrencyThousandSeparatorWithoutZero(data.totalVariance)}</span>
                                            <span style={{ verticalAlign: 'middle' }}>
                                                {!true ? <ArrowDownwardIcon className="AVStype3_Text" /> : <ArrowUpwardIcon className="type3_Text" />}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <div className="AVStype3_Text">Avg DIS</div>
                                    <div className="AVStype1_Text" style={{ textAlign: 'center' }}>
                                        {data.avgDis}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{ borderRight: '1px solid', height: '100%' }}></div>
                                </Grid>
                                <Grid item>
                                    <div className="AVStype3_Text">Stock Turn</div>
                                    <div className="AVStype1_Text" style={{ textAlign: 'center', fontWeight: 'bold', color: props.color }}>
                                        {data.stockTurn}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid
                container
                alignItems="center"
                // alignContent="center"
                justifyContent="space-between"
                className="branchOverViewKip_card"
                alignContent="space-evenly"
            >
                <Grid item>
                    <div style={{ width: '100%' }}>
                        <div className="type2_Text">{data.branch}</div>
                        <div className="type1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data.total)}</div>
                    </div>
                </Grid>
                <Grid item>
                    <div style={{ display: 'flex' }}>
                        <SmallLinkedButton
                            style={{ margin: '0px 5px 10px 0px' }}
                            active={props.active}
                            onClick={() => handleRedirect(props.stockLink, props.branchID)}
                        >
                            STOCK LIST &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                        </SmallLinkedButton>

                        <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)}>
                            OVERAGE &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                        </SmallLinkedButton>
                    </div>
                </Grid>
                <Grid item container justifyContent="space-between" className="mt5">
                    <Grid item xs={12} sm={7}>
                        <div style={{ width: '100%', marginBottom: 5, display: 'flex' }}>
                            <div className="type3_Text">Total CAP Clean</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="type3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                {formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean)}
                            </div>
                        </div>

                        <div style={{ width: '100%', display: 'flex' }}>
                            <div className="type3_Text">Total Variance</div>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="type3_Text" style={{ textAlign: 'left', fontWeight: 'bold', color: true ? '#569101' : '#E51A54' }}>
                                <span>{formatters.CurrencyThousandSeparatorWithoutZero(data.totalVariance)}</span>
                                <span style={{ verticalAlign: 'middle' }}>
                                    {!true ? <ArrowDownwardIcon className="type3_Text" /> : <ArrowUpwardIcon className="type3_Text" />}
                                </span>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={5} item style={{ display: 'flex' }}>
                        <div style={{ width: '100%', borderRight: '1px solid' }}>
                            <div className="type3_Text">Avg DIS</div>
                            <div className="type1_Text" style={{ textAlign: 'center' }}>
                                {data.avgDis}
                            </div>
                        </div>

                        <div style={{ width: '100%' }}>
                            <div className="type3_Text">Stock Turn</div>
                            <div className="type1_Text" style={{ textAlign: 'center', fontWeight: 'bold', color: props.color }}>
                                {data.stockTurn}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid> */}
        </>
    );
};

export const AgedStocksCardTWO = (props) => {
    const { list } = props;
    return (
        <div className="kpiCard">
            <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                {list.map((m) => {
                    if (m.branchID === props.branchID) {
                        return (
                            <Grid item>
                                <div>
                                    <div className="panLlabel mb5">{m.valuationDays1}</div>
                                    <div className="panLvalue mb5">{`${m.totalCount1} (${m.percent1}%)`}</div>
                                    <div className="budgetCard2BudgetNum" style={{ textAlign: 'center', fontSize: 18 }}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue1)}
                                    </div>
                                </div>
                            </Grid>
                        );
                    }
                })}
            </Grid>
        </div>
    );
};
export const AgedStocksDayRangeCard = (props) => {
    const { list } = props;
    return (
        // <div className="AgedsingleStockCard">
        <Grid container justifyContent="space-evenly" spacing={1} alignItems="center" className="AVSUnitKipThree_card">
            {list.map((m) => {
                if (m.branchID === props.branchID) {
                    return (
                        <>
                            <Grid item>
                                <Grid container spacing={1} direction="column" alignItems="center">
                                    <Grid item className="AVStype3_Text">
                                        0 - 30 DAYS
                                    </Grid>
                                    <Grid item className="AVStype1_Text">{`${m.totalCount0to30} (${m.totalPerc0to30}%)`}</Grid>
                                    <Grid item className="AVStype3_Text">
                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue0to30)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1} direction="column" alignItems="center">
                                    <Grid item className="AVStype3_Text">
                                        31 -60 DAYS
                                    </Grid>
                                    <Grid item className="AVStype1_Text">{`${m.totalCount31to60} (${m.totalPerc31to60}%)`}</Grid>
                                    <Grid item className="AVStype3_Text">
                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue31to60)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1} direction="column" alignItems="center">
                                    <Grid item className="AVStype3_Text">
                                        61 - 90 DAYS
                                    </Grid>
                                    <Grid item className="AVStype1_Text">{`${m.totalCount61to90} (${m.totalPerc61to90}%)`}</Grid>
                                    <Grid item className="AVStype3_Text">
                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue61to90)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1} direction="column" alignItems="center">
                                    <Grid item className="AVStype3_Text">
                                        91 - 120 DAYS
                                    </Grid>
                                    <Grid item className="AVStype1_Text">{`${m.totalCount91to120} (${m.totalPerc91to120}%)`}</Grid>
                                    <Grid item className="AVStype3_Text">
                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue91to120)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1} direction="column" alignItems="center">
                                    <Grid item className="AVStype3_Text">
                                        121 - 150 DAYS
                                    </Grid>
                                    <Grid item className="AVStype1_Text">{`${m.totalCount121to150} (${m.totalPerc121to150}%)`}</Grid>
                                    <Grid item className="AVStype3_Text">
                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue121to150)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1} direction="column" alignItems="center">
                                    <Grid item className="AVStype3_Text">
                                        150+ DAYS
                                    </Grid>
                                    <Grid item className="AVStype1_Text">{`${m.totalCount150Plus} (${m.totalPerc150Plus}%)`}</Grid>
                                    <Grid item className="AVStype3_Text">
                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue150Plus)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    );
                }
            })}
        </Grid>
        // </div>
    );
};

export const AgedStockCardMobileView = (props) => {
    const { data, showOverage, showStock } = props;
    let stockTurnColor = data.stockTurn <= 5 ? 'red' : data.stockTurn < 10 ? '#FFBF00' : '#569101';
    const handleRedirect = (link, branchID) => {
        let array = [];
        if (branchID) {
            array.push(branchID);
        }
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: array }));
        props.history.push({
            pathname: link
        });
    };

    return (
        <>
            <Grid container className="AVSUnitKipTwo_card">
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                            <div className="AVStype2_Text">{data.branchShortName}</div>
                            <div className="AVStype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data.total)}</div>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container alignContent="flex-end">
                                {showStock && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton active={props.active} onClick={() => handleRedirect(props.stockLink, props.branchID)}>
                                            STOCK LIST
                                            {/* <CallMadeIcon style={{ fontSize: 10 }} /> */}
                                        </SmallLinkedButton>
                                    </Grid>
                                )}
                                {showOverage && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)}>
                                            OVERAGE
                                            {/* <CallMadeIcon style={{ fontSize: 10 }} /> */}
                                        </SmallLinkedButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ marginTop: '13px' }}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total CAP Clean
                                        </Grid>
                                        <Grid item className="AVStype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '10px' }}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total Variance&nbsp;&nbsp;
                                        </Grid>
                                        <Grid
                                            item
                                            className="AVStype3_Text"
                                            style={{
                                                textAlign: 'left',
                                                fontWeight: 'bold',
                                                color: true ? '#569101' : '#E51A54'
                                            }}
                                        >
                                            <span>{formatters.CurrencyThousandSeparatorWithoutZero(data.totalVariance)}</span>
                                            <span style={{ verticalAlign: 'middle' }}>
                                                {!true ? <ArrowDownwardIcon className="AVStype3_Text" /> : <ArrowUpwardIcon className="type3_Text" />}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} direction="column">
                                <Grid item style={{ padding: 0 }}>
                                    <div style={{ fontSize: 9, textAlign: 'center' }}>Avg DIS</div>
                                    <div div style={{ textAlign: 'center', fontSize: 15 }}>
                                        {data.avgDis}
                                    </div>
                                </Grid>
                                <Grid item style={{ padding: 5 }}>
                                    <div style={{ borderBottom: '1px solid' }}></div>
                                </Grid>
                                <Grid item style={{ padding: 0 }}>
                                    <div style={{ fontSize: 9, textAlign: 'center' }}>Stock Turn</div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            color: stockTurnColor,
                                            fontSize: 15
                                        }}
                                    >
                                        {data.stockTurn}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            style={{
                                background: '#569101',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '12px',
                                marginRight: 5,
                                padding: 6
                            }}
                        >
                            <div>
                                <div style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>{props.units}</div>
                                <div style={{ fontSize: 15, fontWeight: 600 }}>UNITS</div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Grid container spacing={1} justifyContent="space-evenly">
                        {props.list.map((m) => {
                            if (m.branchID === props.branchID) {
                                return (
                                    <>
                                        <Grid item>
                                            <Grid container spacing={0} direction="column" alignItems="center">
                                                <Grid item className="AVStype4_Text">
                                                    0 - 30 DAYS
                                                </Grid>
                                                <Grid item className="AVStype1_Text">{`${m.totalCount0to30}`}</Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        <Grid item>
                                            <Grid container spacing={0} direction="column" alignItems="center">
                                                <Grid item className="AVStype4_Text">
                                                    31 -60 DAYS
                                                </Grid>
                                                <Grid item className="AVStype1_Text">{`${m.totalCount31to60}`}</Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        <Grid item>
                                            <Grid container spacing={0} direction="column" alignItems="center">
                                                <Grid item className="AVStype4_Text">
                                                    61 - 90 DAYS
                                                </Grid>
                                                <Grid item className="AVStype1_Text">{`${m.totalCount61to90} `}</Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        <Grid item>
                                            <Grid container spacing={0} direction="column" alignItems="center">
                                                <Grid item className="AVStype4_Text">
                                                    91 - 120 DAYS
                                                </Grid>
                                                <Grid item className="AVStype1_Text">{`${m.totalCount91to120}`}</Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        <Grid item>
                                            <Grid container spacing={0} direction="column" alignItems="center">
                                                <Grid item className="AVStype4_Text">
                                                    121 - 150 DAYS
                                                </Grid>
                                                <Grid item className="AVStype1_Text">{`${m.totalCount121to150}`}</Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        <Grid item>
                                            <Grid container spacing={0} direction="column" alignItems="center">
                                                <Grid item className="AVStype4_Text">
                                                    150+ DAYS
                                                </Grid>
                                                <Grid item className="AVStype1_Text">{`${m.totalCount150Plus}`}</Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            }
                        })}
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid
                container
                alignItems="center"
                // alignContent="center"
                justifyContent="space-between"
                className="branchOverViewKip_card"
                alignContent="space-evenly"
            >
                <Grid item>
                    <div style={{ width: '100%' }}>
                        <div className="type2_Text">{data.branch}</div>
                        <div className="type1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data.total)}</div>
                    </div>
                </Grid>
                <Grid item xs={4} lg={6} xl={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <SmallLinkedButton
                                // style={{ margin: '0px 5px 10px 0px' }}
                                style={{ padding: '2px 8px' }}
                                active={props.active}
                                onClick={() => handleRedirect(props.stockLink, props.branchID)}
                            >
                                STOCK
                                <span className="dIcon">
                                    &nbsp;
                                    <CallMadeIcon style={{ fontSize: 10 }} />
                                </span>
                            </SmallLinkedButton>
                        </Grid>
                        <Grid item xs={6}>
                            <SmallLinkedButton style={{ padding: '2px 8px' }} onClick={() => handleRedirect(props.priorityLink, props.branchID)}>
                                OVERAGE
                                <span className="dIcon">
                                    &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                                </span>
                            </SmallLinkedButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container justifyContent="space-between" className="mt5">
                    <Grid item xs={12} sm={7}>
                        <div style={{ width: '100%', marginBottom: 5, display: 'flex' }}>
                            <div className="type3_Text">Total CAP Clean</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="type3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                {formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean)}
                            </div>
                        </div>

                        <div style={{ width: '100%', display: 'flex' }}>
                            <div className="type3_Text">Total Variance</div>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="type3_Text" style={{ textAlign: 'left', fontWeight: 'bold', color: true ? '#569101' : '#E51A54' }}>
                                <span>{formatters.CurrencyThousandSeparatorWithoutZero(data.totalVariance)}</span>
                                <span style={{ verticalAlign: 'middle' }}>
                                    {!true ? <ArrowDownwardIcon className="type3_Text" /> : <ArrowUpwardIcon className="type3_Text" />}
                                </span>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={5} item style={{ display: 'flex' }}>
                        <div style={{ width: '100%', borderRight: '1px solid' }}>
                            <div className="type3_Text">Avg DIS</div>
                            <div className="type1_Text" style={{ textAlign: 'center' }}>
                                {data.avgDis}
                            </div>
                        </div>

                        <div style={{ width: '100%' }}>
                            <div className="type3_Text">Stock Turn</div>
                            <div className="type1_Text" style={{ textAlign: 'center', fontWeight: 'bold', color: props.color }}>
                                {data.stockTurn}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid> */}
        </>
    );
};

export const SalesCard = (props) => {
    const { up, value, label, profitVal, subLabelOne, subLabelTwo } = props;
    return (
        <div className="containerPadding">
            <Grid container className="SRbranchOverViewKip_card" alignItems="center" alignContent="center">
                <Grid item xs={12} className="SRtype3_Text p4">
                    {label || 'N/A'}
                </Grid>
                <Grid item xs={12} className="SRtype1_Text p4">
                    {formatters.ThousandSeparatorWithoutZero(value) || 0}
                </Grid>

                <Grid item xs={12} style={{ display: 'flex ' }}>
                    <div style={{ width: '100%', borderRight: '1px solid' }}>
                        <div className="SRtype3_Text p4">{subLabelOne}</div>
                        <div className="SRtype3_Text p4" style={{ color: '#569101' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(props.newProfit)}
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        <div className="SRtype3_Text p4">{subLabelTwo}</div>
                        <div className="SRtype3_Text p4" style={{ color: '#569101' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(props.newPpu)}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
