import React, { useEffect, useState, useContext } from 'react';
import { IconButton, MenuItem, FormLabel, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import DataGridComp from '../../../Core/Grid';
import AppContext from '../../../App/AppContext';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { getStandardWriteUp } from './../../../Core/Service/techWriteUp-service';
import { DialogContents, DialogTitles, Dialogs, SecondaryButton, TextBox } from '../../../Core/FormInput';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { makeStyles } from '@material-ui/core/styles';
import _, { some } from 'lodash';

let searchTimer;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },

    table: {
        border: '1px solid rgba(0,0,0,0.05)',

        '& .MuiTableCell-root': {
            padding: '5px 7px 5px 0px'
        },

        '& .MuiTableRow-root:hover': {
            backgroundColor: 'orange'
        }
    },
    tbody: {
        padding: '0px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    textError: {
        color: 'white'
    },
    textSucc: {
        color: 'black'
    }
}));

const DisplayGrid = (props) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [state, setState] = useState({
        rows: [],
        selectedData: [],
        langCode: 'en',
        enLanguages: [],
        filterText: '',
        filterTableData: [],
        selectedRowData: {}
    });
    const { hideModal, showToast, getUserPreference } = useContext(AppContext);
    const [windowWidths, windowHeights] = useWindowSize();

    useEffect(async () => {
        await pullStandardAndUpdateState();
    }, [state.langCode]);

    useEffect(async () => {
        let data = getUserPreference();
        setState((st) => {
            return { ...st, langCode: data.details[0].languageCode };
        });
    }, []);

    const pullStandardAndUpdateState = async () => {
        let result = await getStandardWriteUp('N', state.langCode);
        if (result.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.rows = result.data?.list;
                newSt.languages = result.data?.languages;
                newSt.show = false;
                newSt.showDel = false;
                if (state.langCode === 'en') {
                    newSt.enLanguages = result.data?.list;
                }
                return newSt;
            });
        }
        if (!result.success) {
            showToast(result.message);
        }
    };

    const handleClose = () => {
        props.onFormSubmit();
        hideModal();
    };

    const onSubmitStandard = (RowData) => {
        let tempObj = RowData || state.selectedRowData;
        console.log(tempObj);
        if (state.langCode === 'en') {
            props.onStandardWriteup(tempObj);
        } else {
            let findEnglish = state.enLanguages.find((a) => a.id == tempObj.id);
            props.onStandardWriteup(findEnglish);
        }
        props.onFormSubmit();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const setFilterText = (e) => {
        const vl = e.target.value;
        setState((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setState((st) => {
            const newSt = { ...st };
            const { filterText, rows } = newSt;

            let filterd = [];
            filterd = [...rows];
            if (filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }

            newSt.filterTableData = filterd;
            return newSt;
        });
    };
    useEffect(() => {
        setFilteredRow();
    }, [state.rows]);

    const clickHandler = (rowData) => (event) => {
        if (event.detail == 2) {
            onSubmitStandard(rowData);
        } else {
            setState((st) => {
                return { ...st, selectedRowData: rowData };
            });
        }
    };

    return (
        <div>
            <Dialogs open={true} onClose={handleClose} maxWidth="lg" fullWidth>
                <Box
                    display="flex"
                    alignItems="center"
                    // className="setStandardWidth"
                    style={{ height: '40px' }}
                >
                    <Box flexGrow={1}>
                        <DialogTitles style={{ padding: '0px 0px 0px 22px' }}>Select Standard Write Up</DialogTitles>
                    </Box>
                    <Box style={{ marginRight: '0px' }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <DialogContents
                    //  className="setStandardWidth removeScroll"
                    style={{ height: '68vh' }}
                >
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={7}>
                                    <TextBox
                                        margin="normal"
                                        required
                                        name="Search"
                                        label="Search"
                                        onChange={setFilterText}
                                        value={state.filterText}
                                        // style={{ paddingTop: '14px', paddingBottom: '5px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <SecondaryButton
                                        style={{
                                            // position: 'fixed',
                                            borderRadius: '20px',
                                            // margin: -8
                                            marginTop: '15px'
                                        }}
                                        onClick={() => onSubmitStandard()}
                                        disabled={_.isEmpty(state.selectedRowData) ? true : false}
                                    >
                                        Select
                                    </SecondaryButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormLabel component="legend">Language</FormLabel>
                            <TextBox select value={state.langCode} name="langCode" onChange={fieldChange} rows={2}>
                                {state.languages?.length > 0 &&
                                    state.languages.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                            </TextBox>
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6}>
                            <ButtonComp />
                        </Grid> */}
                        <Grid item xs={12}>
                            <TableContainer style={{ maxHeight: 500, width: '100%' }}>
                                <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
                                    <TableHead>
                                        <TableRow hover>
                                            <TableCell
                                                align={'left'}
                                                // width={}
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: 600,
                                                    padding: '10px 5px'
                                                    // minWidth: headCell.minWidth ? headCell.minWidth : 'auto'
                                                }}
                                            >
                                                Standard Write Up
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody className={classes.tbody}>
                                        {state.filterTableData.map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className={state.selectedRowData.id == row.id ? 'days-past-due-date-in-40' : ''}
                                                    onClick={clickHandler(row)}
                                                >
                                                    <TableCell
                                                        className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        <p>
                                                            <span style={{ color: 'red' }}>Complaint:</span> {`${row.complaint}`},{' '}
                                                            <span style={{ color: 'red' }}>Cause:</span> {`${row.cause}`},{' '}
                                                            <span style={{ color: 'red' }}>Cure:</span> {`${row.cure}`}
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContents>
            </Dialogs>
        </div>
    );
};
export default DisplayGrid;
