import React, { useState, useEffect } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import './imageModal.scss';

import {
    Grid,
    Paper,
    InputLabel,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Typography,
    Tooltip,
    CircularProgress,
    FormHelperText,
    Badge
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';

import ImageCard from './imageCard';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';
import { SecondaryButton } from '../../../../../Core/FormInput';
import DialogComp from '../../../../../Core/Modal/dialogModal';

const MultiImageSliderModal = (props) => {
    const [windowWidth, windowHeight] = useWindowSize();

    const [image, setImage] = useState();

    const scrollNext = (id) => {
        document.getElementById('slider-scroll').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
    };

    const scrollPrev = (id) => {
        document.getElementById('slider-scroll').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
    };

    const setCurrentImage = (image) => {
        setImage(image);
    };

    const TitleComp = (props) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Documents</div>
        </div>
    );

    return (
        <DialogComp
            title={
                <TitleComp
                // onImageUpload={onImageUpload} onImageRemoveAll={onImageRemoveAll}
                />
            }
            onClose={props.onClose}
            maxWidth="md"
            fullWidth
            className="dialog-container"
        >
            <div className="avatar">
                {props.disabled ? null : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained" color="primary">
                            <BackupIcon />
                            <label>
                                {<span>{`\u00a0 Add Documents`}</span>}
                                <input
                                    name="branchStampImage"
                                    multiple
                                    type="file"
                                    onChange={(e) => props.onImageUpload(e)}
                                    accept="image/*,application/pdf"
                                    hidden
                                />
                            </label>
                        </Button>
                        {props.isUploading && <CircularProgress size={30} className="upload-progress" color="secondary" />}
                    </div>
                )}
                {props.isLoading ? (
                    <div className="image-card" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        <CircularProgress />
                    </div>
                ) : props.imageList.length > 0 ? (
                    <ImageCard avtar={!image ? props.imageList[0].docFile : image} />
                ) : (
                    <div className="image-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography>No image found</Typography>
                    </div>
                )}
                <div className="carousel-wrapper-class">
                    <div className="other-images d-flex" id={'slider-scroll'}>
                        {props.imageList.map((im, i) => (
                            <span className="other-image" style={{ position: 'relative' }} key={i}>
                                {!props.isDelete ? null : (
                                    <span style={{ position: 'absolute', top: '-8px', right: '-10px', zIndex: 10000000 }}>
                                        <RemoveCircleIcon onClick={() => props.onImageRemove(im)} style={{ fill: '#f44336', cursor: 'pointer' }} />
                                    </span>
                                )}
                                <img className={im.docFile === image ? 'active' : ''} onClick={() => setCurrentImage(im.docFile)} src={im.docFile} />
                            </span>
                        ))}
                        <div className="arrow">
                            <span className="arrow-left" onClick={() => scrollPrev()}>
                                <ChevronLeftIcon />
                            </span>
                            <span className="arrow-right" onClick={() => scrollNext()}>
                                <ChevronRightIcon />
                            </span>
                        </div>
                    </div>
                </div>
                {/* {props.disabled ? null : (
                    <div style={{ marginTop: '5px' }}>
                        <SecondaryButton onClick={props.submitImages}>Submit</SecondaryButton>
                    </div>
                )} */}
            </div>
        </DialogComp>
    );
};

export default MultiImageSliderModal;
