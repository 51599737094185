import { DefaultButton, SecondaryButton } from '../../../../Core/FormInput';
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";


const HavePendingMarking = ({ showConfirmation, removeAndClose, handleClose }) => {

    const triggerRemoveAndClose = () => {
        removeAndClose();
        handleClose();
    }
    return <Dialog open={showConfirmation} onClose={handleClose} className="dialog-custom">
        <DialogTitle>Are you sure?
            <div className="title-header-actions">
                <span className="close-icon" onClick={handleClose}>&times;</span>
            </div>
        </DialogTitle>
        <DialogContent>
            <Typography>You have some unsaved changes, you want to remove these and close?</Typography>
            <Grid container className="mt-10">
                <Grid item xs={5}>
                    <SecondaryButton fullWidth onClick={triggerRemoveAndClose}>Remove and Close</SecondaryButton>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5}>
                    <DefaultButton fullWidth onClick={handleClose}>Cancel</DefaultButton>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
}

export default HavePendingMarking;
