import { useEffect } from 'react';
import { useState } from 'react';
import { FormControl, FormControlLabel, Grid, InputLabel, Radio, RadioGroup } from '@material-ui/core';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';
import { SelectBox } from '../../../../../Core/FormInput';
import { useContext } from 'react';
import AppContext from '../../../../../App/AppContext';
import WorkCompleteModal from './statusModals/workComplete';
import CheckedOutModal from './statusModals/checkedOut';
import InWorkShopModal from './statusModals/inWorkShop';
import AwaitingAuthorityModal from './statusModals/awaitingAuthority';
import AwaitingPartModal from './statusModals/awaitingParts';
import CommonStatusModal from './statusModals/commonStatus';

const ModalTitle = {
    8: 'Awaiting Technical Assistance',
    9: 'Waiting Subcontractor',
    7: 'Awaiting Full Order Number',
    11: 'In Query'
};

const ArrivedStatusModal = (props) => {
    const [state, setState] = useState({
        messaegList: [],
        actionLog: false,
        editActionLog: false,
        selectedStatus: +props.statusList[0].id,
        smsText: '',
        sendSMSUpdate: false,
        errors: {}
    });

    useEffect(async () => {
        if (state.selectedStatus) {
            let tempMessage = props.statusList.find((q) => q.id == state.selectedStatus);
            if (tempMessage) {
                setState((st) => ({ ...st, smsText: tempMessage.smsText, sendSMSUpdate: tempMessage.sendSMSUpdate }));
            }
        }
    }, [state.selectedStatus]);

    const handleInput = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === 'selectedStatus') {
            setState((st) => ({ ...st, [name]: value, workInProgress: '', supplierName: '' }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };

    return (
        <div>
            <Grid container>
                <Grid item row xs={6}>
                    {props.statusList.length > 2 ? (
                        <Grid item xs={12} style={{ paddingBottom: '10px' }}>
                            <InputLabel shrink>Select Status</InputLabel>
                            <SelectBox List={props.statusList} name="selectedStatus" value={+state.selectedStatus} onChange={handleInput} insertEmpty={false} />
                        </Grid>
                    ) : (
                        <FormControl component="fieldset">
                            <Grid item xs={12}>
                                <RadioGroup row aria-label="selectedStatus" name="selectedStatus" value={+state.selectedStatus} onChange={handleInput}>
                                    {props.statusList.map((p) => {
                                        return (
                                            <Grid item>
                                                <FormControlLabel value={p.id} control={<Radio />} label={p.name} />
                                            </Grid>
                                        );
                                    })}
                                </RadioGroup>
                            </Grid>
                        </FormControl>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {state.selectedStatus == 4 && (
                        <InWorkShopModal
                            headerRecordID={props.headerRecordID}
                            onClose={props.onClose}
                            selectedStatus={state.selectedStatus}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}

                    {state.selectedStatus == 12 && (
                        <WorkCompleteModal
                            headerRecordID={props.headerRecordID}
                            onClose={props.onClose}
                            selectedStatus={state.selectedStatus}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}

                    {state.selectedStatus == 13 && (
                        <CheckedOutModal
                            headerRecordID={props.headerRecordID}
                            onClose={props.onClose}
                            selectedStatus={state.selectedStatus}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}
                    {state.selectedStatus == 10 && (
                        <AwaitingAuthorityModal
                            headerRecordID={props.headerRecordID}
                            onClose={props.onClose}
                            selectedStatus={state.selectedStatus}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}
                    {(state.selectedStatus == 5 || state.selectedStatus == 7 || state.selectedStatus == 6) && (
                        <AwaitingPartModal
                            partStatusList={props.partStatusList}
                            onClose={props.onClose}
                            headerRecordID={props.headerRecordID}
                            selectedStatus={state.selectedStatus}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}
                    {(state.selectedStatus == 8 || state.selectedStatus == 9 || state.selectedStatus == 11) && (
                        <CommonStatusModal
                            btnTitle={ModalTitle[state.selectedStatus]}
                            headerRecordID={props.headerRecordID}
                            onClose={props.onClose}
                            selectedStatus={state.selectedStatus}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default ArrivedStatusModal;
