import React from 'react';
import { FormLabel, Grid } from '@material-ui/core';
import BreadCrumbs from './../../../../../Core/Controls/Breadcrumb/index';
import formatters from '../../../../../Core/Grid/inputFormatter';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import { getReportsSalesDropdownsResolveData } from '../../../../../Core/Service/reportService';
import { DatePicker } from '../../../../../Core/FormInput';
import JobNotesModal from '../../../dashboard/NewSalesDashboard/WorkFlow/JobNotes';
import { useMemo } from 'react';
import ReactDOM from 'react-dom';

const baseColumns = [
    { title: 'Stock No', field: 'sn', width: 80, sortable: true },
    { title: 'Status', field: 's', width: 60, sortable: true },
    {
        title: 'Progress',
        field: 'pd',
        width: 80,
        isDropDown: true,
        listIdFromAPIResponse: 'progressCodes',
        dropDownValueField: 'id',
        dropDownLableField: 'name',
        sortable: true
    },
    {
        title: 'Model Code',
        field: 'mc',
        width: 90,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'modelRecords',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Vehicle Description',
        field: 'vd',
        width: 250,
        sortable: true,

        cssClass: 'text-center'
    },
    {
        title: 'Comm Number',
        field: 'cn',
        width: 100,
        sortable: true
    },

    {
        title: 'Cons Date',
        field: 'cd',
        width: 90,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Build Date',
        field: 'bd',
        width: 90,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Days',
        field: 'd',
        width: 90,
        sortable: true
    },
    {
        title: 'Sold By',
        field: 'bn',
        width: 150,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'branchList',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Notes',
        field: 'n',
        width: 150,
        sortable: true
    },
    {
        title: 'Latest Loc',
        field: 'rsc',
        width: 90,
        sortable: true
        // renderer: function (value, record) {
        //     return addCommas(value);
        // }
    },
    {
        title: 'Vehicle Group',
        field: 'mac',
        width: 100,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'modelAnalysisCodes',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'New Vehicle Stock', active: true }
];

const SalesPerformanceNewStock = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let Prampd = params.get('pd') || '';
    let PramMC = params.get('mc') || '';
    let PramMAC = params.get('mac') || '';
    let PramAge = params.get('ageRange') || '';
    let Pramfn = params.get('Franchise') || '';

    const [state, setState] = React.useState({ handleVehicleNotes: false });

    const HandelNotesModal = (value = {}) => {
        setState((st) => ({
            ...st,
            handleVehicleNotes: !st.handleVehicleNotes,
            records: { sn: value.sn, vd: value.vd },
            selectedStockID: value.newStockID || null
        }));
    };
    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <div
                //  style={{ cursor: 'pointer', fontWeight: 600 }} onClick={() => HandelNotesModal(record)}
                >
                    {value}
                </div>
                {/* <EditIcon fontSize="small" style={{ color: 'white' }} /> */}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        let columns = [...baseColumns];
        let StockCol = columns.find((k) => k.field === 'sn');

        if (StockCol) {
            StockCol.renderer = editButton;
        }

        return columns;
    }, []);

    const baseUrl = `Sales/NewSalesStockPerformance?ageRange=${PramAge}&fn=${Pramfn}`;

    const CustomeTitle = () => {
        return (
            <div style={{ marginTop: -8 }}>
                <div>Vehicle Notes -{state.records.sn}</div>
                <div style={{ fontSize: '18px', lineHeight: 1 }}>{state.records.vd}</div>
            </div>
        );
    };

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container>
                <Grid container>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        displayPagination={true}
                        FilterdParamsValues={{ pd: Prampd, mc: PramMC, mac: PramMAC }}
                        downloadName={'New Vehicle Stock'}
                        dropDownListProvider={getReportsSalesDropdownsResolveData}
                    />
                </Grid>
            </Grid>
            {state.handleVehicleNotes && (
                <JobNotesModal title={CustomeTitle()} stockID={state.selectedStockID} onClose={HandelNotesModal} onNotesAdded={HandelNotesModal} />
            )}
        </div>
    );
};

export default SalesPerformanceNewStock;
