import { CircularProgress } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../../App/AppContext';
import TabView from '../../../Core/Controls/Tabs';
import { getProcesses } from '../../../Core/Service/process-service';
import WorkflowList from './workflowList';

const Workflow = (props) => {
    const [state, setState] = useState({
        processes: null,
    });

    const { showToast } = useContext(AppContext);

    useEffect(async () => {
        pullProcessTabs()
    }, []);

    const pullProcessTabs = async () => {
        let res = await getProcesses();
        if (res.success) {
            setState((st) => {
                const nst = {
                    ...st,
                    processes: res.data
                };
                return nst;
            });
        } else {
            showToast("Error Occurred - Could Not Get Workflow Processes");
        }
    }

    const tabs = useMemo(() => {
        let arr = []

        state.processes?.map((process) => {
            const toPush = {
                label: process.processName,
                body: <WorkflowList process={process} />
            }
            arr.push(toPush)
        })

        return arr;
    }, [state.processes]);

    return (
        <div>
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Workflows
                </Typography>
            </Breadcrumbs>

            <TabView tabList={tabs} id="testing_tabs" tabLableSize="1.0rem" tabLableWeight="600" />

            {!state.processes && (
                <div style={{ display: 'flow', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};
export default Workflow;