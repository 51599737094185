import React, { useEffect } from 'react';
import { Grid, Typography, InputLabel, FormHelperText, Box, IconButton, Chip, Avatar } from '@material-ui/core';
import { SecondaryButton, TextBox, Dialogs, DialogTitles, DialogContents, DialogsActions } from '../../../../Core/FormInput/index';
import { useState } from 'react';
import { getVehicleStatusID, Job_UpdateJobDescription } from '../../../../Core/Service/communicator-service';
import { useContext } from 'react';
import AppContext from '../../../../App/AppContext';
import CloseIcon from '@material-ui/icons/Close';

const JobDescModal = (props) => {
    const { showToast, showModal, userRoles } = useContext(AppContext);
    const { onClose } = props;
    const [state, setState] = useState({
        errors: {},
        workDetails: props.workDetails || '',
        reg: props.reg,
        wip: props.wip
    });

    const handleInput = (e) => {
        const { name, value } = e.target;

        setState((st) => ({ ...st, [name]: value }));
    };

    const validation = () => {
        let { workDetails } = state;
        let isValid = true;
        let errors = {};

        if (!workDetails) {
            isValid = false;
            errors.workDetails = 'Work Details is required';
        }
        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            let data = {
                headerRecordID: props.headerRecordID,
                workDetails: state.workDetails
            };
            let res = await Job_UpdateJobDescription(data);
            if (res.success) {
                showToast(res.message);
                props.onClose(true, state.workDetails);
            } else {
                showToast(res.message);
            }
        }
    };

    const addActionLogHeaderComp = (ModalTitle) => {
        return (
            <Grid container>
                <Grid item>
                    <span>Job Description</span>
                </Grid>
            </Grid>
        );
    };

    return (
        <Dialogs open={true} onClose={() => props.onClose(false, null)} maxWidth="lg" width="300px">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{<DialogTitles>{addActionLogHeaderComp()}</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={() => props.onClose(false, null)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll" style={{ width: '700px' }}>
                <Grid item style={{ fontSize: 15, textAlign: 'left' }}>
                    WIP No:&nbsp; <b> {props.wip}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.reg}</b>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel shrink error>
                            Job Description
                        </InputLabel>
                        <TextBox
                            placeholder="Job Description"
                            id="outlined-multiline-static"
                            multiline
                            rows={6}
                            variant="outlined"
                            name="workDetails"
                            value={state.workDetails}
                            onChange={handleInput}
                            disabled={state.isReadOnly}
                            autoFocus={true}
                        />
                        <FormHelperText error>{state.errors.workDetails}</FormHelperText>
                    </Grid>
                </Grid>
            </DialogContents>
            {!state.isReadOnly && (
                <DialogsActions className="dialogActions">
                    <Grid item xs={6}>
                        <SecondaryButton className="Submitbtn" fullWidth onClick={handleSubmit} disabled={state.isReadOnly}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <SecondaryButton className="Submitbtn" fullWidth onClick={() => onClose(false, null)}>
                            Cancel
                        </SecondaryButton>
                    </Grid>
                </DialogsActions>
            )}
        </Dialogs>
    );
};

export default JobDescModal;
