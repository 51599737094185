import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { TablePagination } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'stockNO',
        width: '8%',
        label: 'STOCK NO'
    },
    {
        id: 'regNo',
        width: '7%',
        label: 'REG NO'
    },
    {
        id: 'make',

        label: 'MAKE'
    },
    {
        id: 'model',

        label: 'MODEL'
    },
    {
        id: 'dor',
        width: '7%',
        label: 'DOR'
    },
    {
        id: 'dayInStock',
        width: '11%',
        label: 'DAYS IN STOCK'
    },
    {
        id: 'mileage',
        width: '7%',
        label: 'MILEAGE'
    },
    {
        id: 'standInValue',
        // width: '10%',
        label: 'STAND IN VALUE'
    },
    {
        id: 'capClean',
        // width: '22%',
        label: 'CAP CLEAN'
    },
    {
        id: 'variance',

        label: 'VARIANCE'
    }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        width={headCell.width}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 1450,
        '& .MuiTableCell-root': {
            padding: '16px 7px'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

export default function UsedStocksTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <TableContainer style={{ maxHeight: 300 }}>
                <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.list?.length}
                        className="type3_Text"
                    />
                    <TableBody>
                        {stableSort(props.list, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        // aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.stockNO}

                                        // selected={isItemSelected}
                                    >
                                        <TableCell className="type3_Text" component="th" id={labelId} scope="row">
                                            {row.stockNO}
                                        </TableCell>
                                        <TableCell align="left" className="type3_Text">
                                            {row.regNo}
                                        </TableCell>
                                        <TableCell align="left" className="type3_Text">
                                            {row.make}
                                        </TableCell>
                                        <TableCell align="left" className="type3_Text">
                                            {row.model}
                                        </TableCell>
                                        <TableCell align="left" className="type3_Text">
                                            {formatters.YearFormatter(row.dor)}
                                        </TableCell>
                                        <TableCell align="left" className="type3_Text">
                                            {row.dayInStock}
                                        </TableCell>
                                        <TableCell align="left" className="type3_Text">
                                            {row.mileage}
                                        </TableCell>
                                        <TableCell align="left" className="type3_Text">
                                            {formatters.CurrencyThousandSeparatorWithoutZero(row.standInValue)}
                                        </TableCell>
                                        <TableCell align="left" className="type3_Text">
                                            {formatters.CurrencyThousandSeparatorWithoutZero(row.capClean)}
                                        </TableCell>
                                        <TableCell align="left" className="type3_Text" style={{ color: true ? '#539101' : '#E51A54' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(row.capClean - row.standInValue)}
                                            {!true ? <ArrowDownwardIcon style={{ fontSize: 15 }} /> : <ArrowUpwardIcon style={{ fontSize: 15 }} />}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 15, 30, 50]}
                component="div"
                count={props.list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}
