import { get, post } from './http-calls';
import { nicoleDownloadFileWithExt } from './ReportScreens-service';

export const getService24ByID = async (id) => {
    let res = await get(`Service24Jobs/GetDetails/${id}`, { useAuthToken: true });
    return res;
};

export const getService24Dropdowns = async () => {
    let res = await get(`Service24Jobs/Dropdowns`, { useAuthToken: true });
    return res;
};

export const postService24 = async (data) => {
    let res = await post(`Service24Jobs`, data, { useAuthToken: true });
    return res;
};

export const getService24PDF = async (jobID, fileName) => {
    let res = await get(`Service24Jobs/GetService24PDF?Id=${jobID}`, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
    nicoleDownloadFileWithExt(res, 'pdf', fileName);
};

export const searchJobByBranchAndText = async (branchID, searchText) => {
    let res = await get(`Service24Jobs/SearchWipByBranch/${branchID}/${searchText}`, { useAuthToken: true });
    return res;
};
export const postLinkWips = async (data) => {
    let res = await post(`Service24Jobs/LinkWips`, data, { useAuthToken: true });
    return res;
};
