import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CallMadeIcon from '@material-ui/icons/CallMade';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles({
    table: {
        minWidth: '100%'
    },
    container: {
        maxHeight: 360,
        minHeight: 360
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        padding: 6
        // fontWeight: 700,
        // fontFamily: `Montserrat Regular`
        // backgroundColor: '#0e2132',
        // color: theme.palette.common.white
    }
    // body: {
    //     fontSize: 12,
    //     fontFamily: `Montserrat Regular`
    // }
}))(TableCell);

export const BasicTable = (props) => {
    const classes = useStyles();

    return (
        <TableContainer className={classes.container}>
            <Table className={classes.table} aria-label="sticky table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <StyledTableCell width="35%"></StyledTableCell>
                        <StyledTableCell width="20%" align="right" className="type3_Text">
                            CURRENT
                        </StyledTableCell>
                        <StyledTableCell width="25%" align="right" className="type3_Text">
                            PRIOR YEAR
                        </StyledTableCell>
                        <StyledTableCell width="20%" align="right" className="type3_Text">
                            DIFFERENCE
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data?.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row" className="type3_Text">
                                {row.description}
                            </TableCell>
                            <TableCell align="right" className="type3_Text">
                                {row.current}
                            </TableCell>
                            <TableCell align="right" className="type3_Text">
                                {row.priorYear}
                            </TableCell>
                            <TableCell align="right" className="type3_Text" style={{ color: false ? 'red' : '#569101' }}>
                                <b>{row.diff || 0}&nbsp;</b>
                                {false ? <ArrowDownwardIcon style={{ fontSize: 12 }} /> : <ArrowUpwardIcon style={{ fontSize: 12 }} />}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
