import { get, post } from './http-calls';
import { memoize } from 'lodash';

export const getDataByEwipNo = (wipNo) => {
    let res = get(`WorkflowJobs/GetJobsByWIPNumber/${wipNo}`, { useAuthToken: true });
    return res;
};

export const getDataByRegNo = (regNo) => {
    let res = get(`Services/DVLA/${regNo}`, { useAuthToken: true });
    return res;
};
