import React, { useEffect } from 'react';
import { useState } from 'react';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';

import { useContext } from 'react';
import AppContext from '../../../../../App/AppContext';
import ArrivalDateModal from './statusModals/arrivalDate';
import RebookingRequiredModal from './statusModals/rebookingReq';
import RebookedModal from './statusModals/rebooked';
import CommonStatusModal from './statusModals/commonStatus';

const BookedStatusModal = (props) => {
    const [state, setState] = useState({
        messaegList: [],
        actionLog: false,
        editActionLog: false,
        selectedStatus: +props.statusList[0].id,
        smsText: '',
        sendSMSUpdate: false,
        statusList: [
            {
                id: 3,
                name: 'Vehicle Arrived'
            },
            {
                id: 2,
                name: 'Cancel Booking'
            }
        ],
        errors: {}
    });

    useEffect(async () => {
        if (state.selectedStatus) {
            let tempMessage = props.statusList.find((q) => q.id == state.selectedStatus);
            if (tempMessage) {
                setState((st) => ({ ...st, smsText: tempMessage.smsText, sendSMSUpdate: tempMessage.sendSMSUpdate }));
            }
        }
    }, [state.selectedStatus]);

    const handleInput = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === 'selectedStatus') {
            setState((st) => ({ ...st, [name]: value, dateArrived: '', rebookingDate: '' }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };

    return (
        <div>
            <Grid container>
                <Grid item row xs={6}>
                    <FormControl component="fieldset">
                        <Grid item xs={12}>
                            <RadioGroup row aria-label="selectedStatus" name="selectedStatus" value={+state.selectedStatus} onChange={handleInput}>
                                {props.statusList.map((p) => {
                                    return (
                                        <Grid item>
                                            <FormControlLabel value={p.id} control={<Radio />} label={p.name} />
                                        </Grid>
                                    );
                                })}
                            </RadioGroup>
                        </Grid>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    {state.selectedStatus == 3 && (
                        <ArrivalDateModal
                            partStatusList={props.partStatusList}
                            onClose={props.onClose}
                            headerRecordID={props.headerRecordID}
                            selectedStatus={state.selectedStatus}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}
                    {state.selectedStatus == 16 && (
                        <RebookingRequiredModal
                            partStatusList={props.partStatusList}
                            onClose={props.onClose}
                            headerRecordID={props.headerRecordID}
                            selectedStatus={state.selectedStatus}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}
                    {state.selectedStatus == 14 && (
                        <RebookedModal
                            partStatusList={props.partStatusList}
                            onClose={props.onClose}
                            headerRecordID={props.headerRecordID}
                            selectedStatus={state.selectedStatus}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}
                    {state.selectedStatus == 2 && (
                        <CommonStatusModal
                            btnTitle={'Cancel Booking'}
                            headerRecordID={props.headerRecordID}
                            onClose={props.onClose}
                            selectedStatus={state.selectedStatus}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default BookedStatusModal;
