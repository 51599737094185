import { get } from './http-calls';
import { memoize } from 'lodash';

export const getWarrantyDashBoard = async (startDate, endDate, branchID, fn) => {
    let res = await get(`services/WarrantyDashBoard?StartDate=${startDate}&EndDate=${endDate}&branchID=${branchID}&fn=${fn}`, { useAuthToken: true });
    return res;
};

export const getSoldHoursDashBoard = async (startDate, endDate, branchID, fn) => {
    let res = await get(`services/SoldHoursDashBoard?StartDate=${startDate}&EndDate=${endDate}&branchID=${branchID}&fn=${fn}`, { useAuthToken: true });
    return res;
};

export const getWIPDashBoard = async (startDate, endDate, branchID, fn) => {
    let res = await get(`services/WIPDashBoard?StartDate=${startDate}&EndDate=${endDate}&branchID=${branchID}&fn=${fn}`, { useAuthToken: true });
    return res;
};

export const getGetUserFilterRecords = async (startDate, endDate, branchID, fn) => {
    let res = await get(`services/GetUserFilterRecords`, { useAuthToken: true });
    return res;
};

export const getIDLERecored = async (startDate, endDate, branchID, fn) => {
    let res = await get(`services/IdleHoursAndTechEff?StartDate=${startDate}&EndDate=${endDate}&branchID=${branchID}&fn=${fn}`, { useAuthToken: true });
    return res;
};
