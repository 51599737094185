import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { AppStorage } from '../../Service/storage-service';
const dashboards = [
    { label: 'eWIP', path: '/eWIPDashboard', id: 1 },
    { label: 'Reports', path: '/ReportsDashboard', id: 2 },
    { label: 'Invoices', path: '/invoice/dashboard', id: 3 },
    { label: 'Communicator', path: '/communicator_dash', id: 4 }
];
const BreadCrumbs = (props) => {
    const [state, setState] = useState({ crumbs: props.crumbs || [] });
    const getDefaultInternalDashboard = AppStorage.getDefaultInternalDashboard();
    useEffect(() => {
        let getCrumb = props.crumbs;
        //Update breadcrum path if default dashboard set then set crumbs path as default dashboard path
        if (getDefaultInternalDashboard && getCrumb.length >= 3 && dashboards.find((dashboard) => dashboard.id == getDefaultInternalDashboard)) {
            const matchingDashboard = dashboards.find((dashboard) => dashboard.id == getDefaultInternalDashboard);
            const matchingCrumb = getCrumb.find((crumb) => crumb.path == matchingDashboard.path);
            if (matchingCrumb) {
                matchingCrumb.path = '/';
                setState((st) => ({ ...st, crumbs: [...getCrumb] }));
            }
        } else {
            setState((st) => ({ ...st, crumbs: props.crumbs }));
        }
    }, [props]);
    return (
        <Breadcrumbs separator=">>" aria-label="breadcrumb">
            {state.crumbs
                ? state.crumbs.map((crumb, i) => {
                      return (
                          <div key={i}>
                              {crumb.active ? (
                                  <Typography color="secondary">{crumb.name}</Typography>
                              ) : (
                                  <Link to={crumb.path} style={{ textDecoration: 'none' }}>
                                      {crumb.name}
                                  </Link>
                              )}
                          </div>
                      );
                  })
                : null}
        </Breadcrumbs>
    );
};

export default BreadCrumbs;
