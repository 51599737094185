import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions, DefaultButton, YesNoButton } from '../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import '../User Management/UserManagement.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { addWebForm } from '../../../Core/Service/webForm-service';
import { MenuItem } from '@material-ui/core';
import { getWorkflows } from '../../../Core/Service/workflow-service';
import { getDynamicFormDetails, saveDynamicForms } from '../../../Core/Service/dynamic-forms-service';
import { SketchPicker } from 'react-color';
import { FieldWrappper } from '../../../Core/FieldWrapper';

const AddEditDynamicFormsModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        name: '',
        buttonLabel: '',
        url: '',
        description: '',
        errors: {},
        fields: {},
        nextWorkFlow: null,
        workFlowList: [],
        navigateToDashboard: false,
        dynamicFormBackgroundColor: '#FFFFFF',
        dynamicFormTextColor: '#000000'
    });

    useEffect(async () => {
        let result = await getWorkflows();
        if (result.success) {
            setState((st) => ({ ...st, workFlowList: result.data }));
        }
        if (props.recordId) {
            let res = await getDynamicFormDetails(props.recordId);
            if (res.success) {
                let data = res.data.list.table[0];
                setState((st) => ({
                    ...st,
                    name: data.name,
                    buttonLabel: data.buttonLabel,
                    description: data.description,
                    nextWorkFlow: data.editableWorkflowID,
                    navigateToDashboard: data.navigateToDashboardOnSubmit,
                    dynamicFormBackgroundColor: data.backgroundColor || '#FFFFFF',
                    dynamicFormTextColor: data.textColor || '#000000'
                }));
            }
        }
    }, []);

    const handleClose = () => {
        props.onClose(false);
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            const nst = { ...st, [name]: value, fields: fields };
            return nst;
        });
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handelSubmit = async () => {
        if (handleValidation()) {
            let data = {
                Id: props.recordId || null,
                name: state.name,
                buttonLabel: state.buttonLabel,
                description: state.description,
                EditableWorkflowID: state.nextWorkFlow,
                navigateToDashboardOnSubmit: state.navigateToDashboard,
                dynamicFormBackgroundColor: state.dynamicFormBackgroundColor,
                dynamicFormTextColor: state.dynamicFormTextColor
            };

            let res = await saveDynamicForms(data);
            if (!res.success) {
                console.error(res);
            } else {
                props.onClose(true);
            }
            showToast(res.message);
        }
    };

    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.name) {
            formIsValid = false;
            errors['name'] = 'Name cannot be empty';
        }

        if (!state.buttonLabel) {
            formIsValid = false;
            errors['buttonLabel'] = 'Button label cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{props.recordId ? <DialogTitles>Update Dynamic Form</DialogTitles> : <DialogTitles>Add Dynamic Form</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents style={{ padding: '12px 24px' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Name
                        </FormLabel>
                        <TextBox autoFocus name="name" onChange={fieldChange} value={state.name} />
                        <span className="mandatoryfields">{state.errors['name']}</span>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Button Label
                        </FormLabel>
                        <TextBox name="buttonLabel" onChange={fieldChange} value={state.buttonLabel} />
                        <span className="mandatoryfields">{state.errors['buttonLabel']}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <FieldWrappper heading="Form Themes" isAccordion={true} style={{ margin: '0px' }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={4} sm={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormLabel component="legend">
                                                <b>Form Background</b>
                                            </FormLabel>
                                        </Grid>
                                        <Grid item>
                                            <SketchPicker
                                                color={state.dynamicFormBackgroundColor}
                                                onChange={(updatedColor) =>
                                                    setState((state) => ({
                                                        ...state,
                                                        dynamicFormBackgroundColor: updatedColor.hex
                                                    }))
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormLabel component="legend">
                                                <b>Form Text</b>
                                            </FormLabel>
                                        </Grid>
                                        <Grid item>
                                            <SketchPicker
                                                color={state.dynamicFormTextColor}
                                                onChange={(updatedColor) =>
                                                    setState((state) => ({
                                                        ...state,
                                                        dynamicFormTextColor: updatedColor.hex
                                                    }))
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FieldWrappper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormLabel component="legend">Editable in Workflow</FormLabel>
                        <TextBox select name="nextWorkFlow" value={state.nextWorkFlow} onChange={fieldChange} variant="outlined">
                            {state.workFlowList
                                .filter((m) => m.id)
                                .map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.workflowName}
                                    </MenuItem>
                                ))}
                        </TextBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container style={{ textAlign: 'left' }} spacing={2}>
                            <Grid xs={12} container item>
                                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                                    <FormLabel>Navigate to dashboard?</FormLabel>
                                </Grid>
                                <YesNoButton
                                    state={state.navigateToDashboard}
                                    name="navigateToDashboard"
                                    onYesClick={() => handleClick('navigateToDashboard', true)}
                                    onNoClick={() => handleClick('navigateToDashboard', false)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Description</FormLabel>
                        <TextBox name="description" onChange={fieldChange} value={state.description} multiline rows={3} />
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddEditDynamicFormsModal;
