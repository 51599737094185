import React from 'react';
import { TextBox, SecondaryButton } from '../../../../Core/FormInput';
import Typography from '@material-ui/core/Typography';
import { FormHelperText, Grid } from '@material-ui/core';
import { useEffect } from 'react';
import { GetApprovedDetails } from '../../../../Core/Service/communicator-service';
import { CustomeSignPad } from '../../../../Core/SignaturePad';
import formatters from '../../../../Core/Grid/inputFormatter';

export default function ApprovalDetailsModal(props) {
    const [state, setState] = React.useState({ approvedOrderSignature: null });

    useEffect(async () => {
        let res = await GetApprovedDetails(props.headerRecordID);
        if (res.success) {
            let data = res.data.list.table[0];
            setState((st) => ({
                ...st,
                approvedOrderDate: formatters.DateTimeFormatter(data.approvedOrderDate),
                approvedOrderNumber: data.approvedOrderNumber,
                approvedOrderSignature: data.approvedOrderSignature,
                approvedUserID: data.approvedUserID,
                displayName: data.displayName,
                vehicleStatus: data.vehicleStatus
            }));
        }
    }, []);

    const GetTechnicianSign = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };
    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            storedSignature: null,
            signature: null
        }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} style={{ fontSize: 15, textAlign: 'left' }}>
                Approved By:&nbsp; <b> {state.displayName}</b>
            </Grid>
            <Grid item xs={6} style={{ fontSize: 15, textAlign: 'left' }}>
                Order Number:&nbsp; <b> {state.approvedOrderNumber}</b>
            </Grid>
            <Grid item xs={6} style={{ fontSize: 15, textAlign: 'left' }}>
                Approved Date:&nbsp; <b> {state.approvedOrderDate}</b>
            </Grid>
            {state.approvedOrderSignature && (
                <Grid item xs={12}>
                    <CustomeSignPad
                        onChange={GetTechnicianSign}
                        content={'Signature'}
                        sign={state.approvedOrderSignature}
                        name="signature"
                        clearSig={clearSig}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <SecondaryButton className="Submitbtn" fullWidth onClick={props.onClose}>
                    Cancel
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}
