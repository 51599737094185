import { Button, Grid, IconButton, InputLabel } from '@material-ui/core';
import { Dashboard } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import './ApprisalStyle.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getAppraisalDashboard, getStockbookList } from '../../../Core/Service/apprisal-service';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import { TextBox } from '../../../Core/FormInput';

let DashboardList = [
    {
        name: 'Awaiting Approval',
        count: 0,
        valueFieldName: 'awaitingApproval',
        id: 1
    },
    {
        name: 'Awaiting Review',
        count: 0,
        valueFieldName: 'awaitingReview',
        id: 2
    },
    {
        name: 'Under Review',
        count: 0,
        valueFieldName: 'underReview',
        id: 4
    }
];

const ApprisalMaindashboard = () => {
    const history = useHistory();
    const [state, setState] = useState({ list: [], totalApprCount: 0, showMoadal: false, stockNo: '', List: [] });

    useEffect(async () => {
        let res = await getAppraisalDashboard();
        console.log(res, 'res');
        let total = 0;
        DashboardList.forEach((a) => {
            total += res.data.list[0][a.valueFieldName];
        });

        setState((st) => ({ ...st, list: res.data.list[0], totalApprCount: total }));
    }, []);

    const handleScreenRedirect = (obj) => {
        let query = `?screen=${obj.name}${obj.id ? `&id=${obj.id}` : ''}`;
        history.push({
            pathname: '/appraisal_Details',
            search: query
        });
    };

    const handleStockbook = () => {
        setState((st) => ({
            ...st,
            showMoadal: !st.showMoadal
        }));
    };

    const handleSearchStock = async () => {
        const searchQuery = `?stockId=${state.stockNo}`;
        history.push({
            pathname: `/Appraisal_createAppraisal`,
            search: searchQuery
        });
    };

    const handleChange = (e) => {
        setState((st) => ({
            ...st,
            stockNo: e.target.value
        }));
    };
    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
                <Grid item xs={12} sm={12} lg={10}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={6} lg={4}>
                            <div
                                className="maninDiv"
                                onClick={() => {
                                    history.push({
                                        pathname: `/Appraisal_createAppraisal`
                                    });
                                }}
                            >
                                <div className="iconStyle">
                                    <AddCircleOutlineIcon style={{ fontSize: 40 }} />
                                </div>
                                <div className="iconfont">Create Appraisal</div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <div className="maninDiv" onClick={handleStockbook}>
                                <div className="iconStyle">
                                    <SearchIcon style={{ fontSize: 40 }} />
                                </div>
                                <div className="iconfont">Create Appraisal From Stock Book</div>
                                <div></div>
                            </div>
                        </Grid>
                        {DashboardList?.map((b) => (
                            <Grid item xs={12} sm={6} lg={4} key={`#ApprDash${b.id}`}>
                                <div className="maninDiv" onClick={() => handleScreenRedirect(b)}>
                                    <div className="iconStyle">{state.list[b.valueFieldName] || 0}</div>
                                    <div className="iconfont">{b.name}</div>
                                </div>
                            </Grid>
                        ))}
                        <Grid item xs={12} sm={6} lg={4}>
                            <div
                                className="maninDiv"
                                onClick={() =>
                                    handleScreenRedirect({
                                        name: 'All Appraisal',
                                        count: 0,
                                        valueFieldName: 'allAppraisal',
                                        id: null
                                    })
                                }
                            >
                                <div className="iconStyle">{state.totalApprCount || 0}</div>
                                <div className="iconfont">All </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {state.showMoadal ? (
                <DialogComp title="Create Appraisal From Stock Book" onClose={handleStockbook}>
                    <Grid container style={{ paddingBottom: 20 }} spacing={2}>
                        <Grid item xs={10}>
                            {/* <InputLabel>Stock No</InputLabel> */}
                            <TextBox placeholder="Stock No" type="number" onChange={handleChange} autoFocus={true} />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                onClick={handleSearchStock}
                                disabled={state.stockNo.length > 5 ? false : true}
                                color="primary"
                                variant="contained"
                                style={{ marginTop: 8 }}
                            >
                                <SearchIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogComp>
            ) : null}
        </div>
    );
};

export default ApprisalMaindashboard;
