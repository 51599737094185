import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
const ViewPdfModal = (props) => {
    const [state, setState] = useState({ url: '' });
    useEffect(() => {
        const url = window.URL.createObjectURL(new Blob([props.pdfFileData], { type: 'application/pdf; charset=utf-8' }));
        setState((st) => ({ ...st, url: url }));
    }, [props.pdfFileData]);

    return (
        <>
            {!props.pdfFileData ? (
                <div style={{ width: '100%' }}>
                    <div
                        style={{
                            height: props.height ? props.height : 680,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '2px solid #d3d3d3'
                        }}
                    >
                        <CircularProgress />
                    </div>
                </div>
            ) : (
                <iframe
                    // src="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                    src={`${state.url}`}
                    id="editviewer"
                    frameborder="0"
                    scrolling="auto"
                    width="100%"
                    height={props.height ? props.height : '680'}
                ></iframe>
            )}
        </>
    );
};
export default ViewPdfModal;
