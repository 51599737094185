import { get, post } from './http-calls';
export const getCustomerConsultations = async (id) => {
    let res = await get(`CustomerConsultation/${id}`, { useAuthToken: true });
    return res;
};
export const addCustomerConsultation = async (Id, DefectReported, CustomerSignature, serviceAdvisorSign, CustomerConsultationID, results) => {
    let res = await post(
        `CustomerConsultation`,
        {
            HeaderRecordID: Id,
            CustomerConsultationDefectReported: DefectReported,
            CustomerConsultationCustomerSignature: CustomerSignature,
            CustomerConsultationServiceAdvisorSignature: serviceAdvisorSign,
            CustomerConsultationID: CustomerConsultationID,
            CustomerConsultationResult: results
        },
        { useAuthToken: true }
    );
    return res;
};
