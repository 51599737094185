import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { Checkbox, Container, FormControlLabel } from '@material-ui/core';
import { getCommunicatorSmsStatus, postCommunicatorSms } from '../../../../Core/Service/communicator-service';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import AppContext from '../../../../App/AppContext';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { AppStorage } from '../../../../Core/Service/storage-service';

const StyledTableCell = withStyles((theme) => ({
    head: {
        // backgroundColor: 'black',
        // color: 'White',
        fontSize: 16
    },
    body: {
        fontSize: 16,
        padding: '5px 16px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        border: '1px solid lightgrey'
    },
    container: {
        maxHeight: 440
    }
});

const SMSStatusUpdate = () => {
    const portalSetting = AppStorage.getPortalSetting();
    const classes = useStyles();
    const { hideModal, showToast } = useContext(AppContext);

    const [state, setState] = useState({
        updateSmsList: [],
        showLoader: true
    });
    useEffect(async () => {
        let res = await getCommunicatorSmsStatus();
        if (res.success) {
            setState((st) => ({ ...st, updateSmsList: res?.data?.list?.table, showLoader: false }));
        }
    }, []);

    const handleCheckbox = (id) => (e) => {
        let tempList = [...state.updateSmsList];
        let index = state.updateSmsList.findIndex((m) => m.id === id);
        const {checked, name} = e.target;

        tempList[index][name] = checked;
        tempList[index].validation = true

        setState((st) => ({ ...st, updateSmsList: tempList }));
    };

    const handleChange = (id) => (e) => {
        let tempList = [...state.updateSmsList];
        let index = state.updateSmsList.findIndex((m) => m.id === id);
        tempList[index].smsText = e.target.value;
        tempList[index].validation = true;
        setState((st) => ({ ...st, updateSmsList: tempList }));
    };

    const handleSubmit = async (val) => {
        let data = { 
            vehicleStatusCode: val.id, 
            sendSMSUpdate: val.sendSMSUpdate, 
            sMSText: val.smsText || '',
            includeInSiteVehicles: val.includeInSiteVehicles,
            includeInVORVehicles: val.includeInVORVehicles
        };
        let res = await postCommunicatorSms(data);
        if (res.success) {
            showToast('SMS Updated Successfully');
        } else {
            alert('Failed To Update');
        }
    };

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Communicator', active: true },
        { name: 'Vehicle Status Update', active: true }
    ];

    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <div style={{ margin: 10 }}>
                <TableContainer className="custom-scroll" style={{ maxHeight: 'calc(100vh - 185px)' }}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell
                                    width="30%"
                                    style={{ background: portalSetting.portalHeaderBackgroundColour, color: portalSetting.portalHeaderTextColour }}
                                >
                                    Vehicle Status
                                </StyledTableCell>
                                <StyledTableCell
                                    width="12%"
                                    align="center"
                                    style={{ background: portalSetting.portalHeaderBackgroundColour, color: portalSetting.portalHeaderTextColour }}
                                >
                                    Include In Site Vehicles
                                </StyledTableCell>
                                <StyledTableCell
                                    width="12%"
                                    align="center"
                                    style={{ background: portalSetting.portalHeaderBackgroundColour, color: portalSetting.portalHeaderTextColour }}
                                >
                                    Include In VOR Vehicles
                                </StyledTableCell>
                                <StyledTableCell
                                    width="12%"
                                    align="center"
                                    style={{ background: portalSetting.portalHeaderBackgroundColour, color: portalSetting.portalHeaderTextColour }}
                                >
                                    Send Text
                                </StyledTableCell>
                                <StyledTableCell
                                    width="50%"
                                    align="left"
                                    style={{ background: portalSetting.portalHeaderBackgroundColour, color: portalSetting.portalHeaderTextColour }}
                                >
                                    SMS Text
                                </StyledTableCell>
                                <StyledTableCell
                                    width="5%"
                                    align="left"
                                    style={{ background: portalSetting.portalHeaderBackgroundColour, color: portalSetting.portalHeaderTextColour }}
                                >
                                    Action
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.showLoader ? null : state.updateSmsList.length > 0 ? (
                                state.updateSmsList.map((m) => (
                                    <StyledTableRow key={m.id}>
                                        <StyledTableCell component="th" scope="row" style={{ paddingLeft: 16 }}>
                                            {m.vehicleStatusDescription}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Checkbox checked={m.includeInSiteVehicles} onChange={handleCheckbox(m.id)} name="includeInSiteVehicles" />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Checkbox checked={m.includeInVORVehicles} onChange={handleCheckbox(m.id)} name="includeInVORVehicles" />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Checkbox checked={m.sendSMSUpdate} onChange={handleCheckbox(m.id)} name="sendSMSUpdate" />
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <TextBox
                                                placeholder="Type a new message"
                                                name="smsText"
                                                value={m.smsText}
                                                onChange={handleChange(m.id)}
                                                disabled={!m.sendSMSUpdate || false}
                                                error={(m.sendSMSUpdate && !m.smsText) || false}
                                            />
                                            {/* {m.smsTextMessage ? <FormControlLabel error>{m.smsTextMessage}</FormControlLabel> : null} */}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <SecondaryButton onClick={() => handleSubmit(m)} disabled={(!m.smsText && m.sendSMSUpdate) || !m.validation}>
                                                Update
                                            </SecondaryButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div style={{ marginLeft: 30 }}>No Data Available</div>
                                    </StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default SMSStatusUpdate;
