import { PhoneIphone, AccessTime, Email } from '@material-ui/icons';
import React, { useContext, useMemo } from 'react';
import { BrowserRouter, NavLink } from 'react-router-dom';
import AppContext from '../../App/AppContext';
import { Navbar } from '../Root/Navbar';
import TreeImgLogo from '../../Components/_shared/treeImgesLogo';
import { useWindowSize } from '../Controls/ScreenResolution';
import MiniDrawer from '../Root/LeftDrawer';
import { useEffect } from 'react';
import { useState } from 'react';
import IdleTimeOutHandler from '../Root/sessionClose';
import { AppStorage } from '../Service/storage-service';
// import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

export let Header = (props) => {
    const { portalSettings, showTopNavAndMenu, loggedIn, isTopNav, getUserPreference } = useContext(AppContext);
    const [WindowWidths, WindowHeights] = useWindowSize();
    const [menuType, setMenuType] = useState('T');
    const img = !!portalSettings.portalSettingMainLogo ? portalSettings.portalSettingMainLogo : `${process.env.PUBLIC_URL}/${portalSettings.portalSettingLogo}`;
    const [isActive, setIsActive] = useState(true);
    const [isLogout, setLogout] = useState(false);

    const navBarPreferences = () => {
        if (localStorage.getItem('_AppbarLocation_') && localStorage.getItem('_AppbarLocation_') != 'undefined') {
            return localStorage.getItem('_AppbarLocation_');
        } else {
            return portalSettings.portalSettingMenuType;
        }
    };

    useEffect(() => {
        setMenuType(navBarPreferences());
    }, [localStorage.getItem('_AppbarLocation_')]);

    const handleLogout = () => {
        AppStorage.performLogout();
        window.location = '/';
    };

    return (
        <BrowserRouter>
            {AppStorage.isLoggedIn() ? (
                <IdleTimeOutHandler
                    onActive={() => {
                        setIsActive(true);
                    }}
                    onIdle={() => {
                        setIsActive(false);
                    }}
                    onLogout={() => {
                        setLogout(true);
                    }}
                    Logout={handleLogout}
                />
            ) : null}
            <div className="header">
                {showTopNavAndMenu && (
                    <div
                        className="portal-info"
                        style={{
                            backgroundColor: portalSettings.portalHeaderBackgroundColour,
                            color: portalSettings.portalHeaderTextColour,
                            display: isTopNav == false ? 'none' : ''
                            // position: 'fixed',
                            // width: '100%'
                        }}
                    >
                        <NavLink to="/" className="branding-logo">
                            <img src={img} />
                        </NavLink>
                        {[
                            { text: 'Call US', value: portalSettings.portalSettingCallUs, icon: PhoneIphone, link: 'tel:' + portalSettings.callUs },
                            { text: 'Opening Time', value: portalSettings.portalSettingOpeningTime, icon: AccessTime },
                            {
                                text: 'Email US',
                                value: portalSettings.portalSettingEmailUs,
                                icon: Email,
                                link: 'mailto:' + portalSettings.portalSettingEmailUs + '?subject=eWIP Query',
                                isDynamicDisplay: true
                            }
                        ].map((c, i) => (
                            <div className={`info ${c.isDynamicDisplay ? 'nav-bar-icons' : ''}`} key={i}>
                                <div className="icon">
                                    <c.icon fontSize="large" />
                                </div>
                                <div className="key-value ">
                                    <label>{c.text}</label>
                                    <label className="value">
                                        {c.link ? (
                                            <a
                                                href={c.link}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'inherit'
                                                }}
                                            >
                                                {c.value}
                                            </a>
                                        ) : (
                                            c.value
                                        )}
                                    </label>
                                </div>
                            </div>
                        ))}
                        {loggedIn && portalSettings.portalSettingeWIPEnabled && (
                            <div style={{ padding: '15px', marginRight: WindowWidths > 750 ? 30 : 0 }}>
                                <TreeImgLogo />
                            </div>
                        )}
                    </div>
                )}
                {menuType === 'S' ? <MiniDrawer /> : <Navbar></Navbar>}
            </div>
        </BrowserRouter>
    );
};
