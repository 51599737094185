import { get, post } from './http-calls';

export const getQCSheet = async (id) => {
    let res = await get(`QCSheet/${id}`, { useAuthToken: true });
    return res;
};
export const addQCSheet = async (id, note, sign, mileage, headerID, results, QCHeaderSignatureName, qcHeaderMOTJob) => {
    let res = await post(
        `QCSheet`,
        {
            HeaderRecordID: id,
            QCHeaderNotes: note,
            QCHeaderSignature: sign,
            QCHeaderMileage: mileage,
            qcHeaderMOTJob: qcHeaderMOTJob,
            QCHeaderID: headerID,
            qCResults: results,
            QCHeaderSignatureName: QCHeaderSignatureName
        },
        { useAuthToken: true }
    );
    return res;
};

export const getQCDocuments = async (headerRecordId, documentQCItemID) => {
    let res = await get(`QcSheet/GetQCDocuments/${headerRecordId}/${documentQCItemID}`, {
        useAuthToken: true
    });
    return res;
};

export const saveQCDocuments = async (headerRecordId, documentQCItemID, formData) => {
    let res = await post(`QCSheet/UploadDoc/${headerRecordId}/${documentQCItemID}`, formData, {
        useAuthToken: true
    });

    return res;
};

export const deleteQCDocument = async (headerRecordId, documentID, formData) => {
    let res = await post(
        `QCSheet/DeleteDoc/${headerRecordId}/${documentID}`,
        {},
        {
            useAuthToken: true
        }
    );

    return res;
};
