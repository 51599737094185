import React, { useContext, useState, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextBox } from '../../../../Core/FormInput';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import '../../User Management/UserManagement.css';
import { getProcesses, getWorkFlowList } from '../../../../Core/Service/process-service';
const SetReturnReasonsNotification = (props) => {
    const { returnReasonNotificationList, handelworkflowNotification } = props;
    console.log(returnReasonNotificationList, 'returnReasonNotificationList');
    return (
        <Grid container spacing={2}>
            {returnReasonNotificationList.length > 0
                ? returnReasonNotificationList.map((m) => (
                      <React.Fragment>
                          <Grid item lg={6}>
                              {m.branchShortName}
                              <TextBox
                                  name="returnReasonNotificationEmail"
                                  placeholder="Email"
                                  value={m.returnReasonNotificationEmail}
                                  onChange={handelworkflowNotification(m.returnReasonNotificationBranchID)}
                              />
                          </Grid>
                      </React.Fragment>
                  ))
                : null}
        </Grid>
    );
};

export default SetReturnReasonsNotification;
