import { IconButton } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { GridOverlay } from '@material-ui/data-grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../../App/AppContext';
import { SecondaryButton } from '../../../Core/FormInput/index';
import DataGridComp from '../../../Core/Grid';
import { getDefectReportedCategories } from '../../../Core/Service/DefectReportedCategory-service';
import AddDefectReportedCategoriesModal from './AddUpdateModal';

const DefectReportedCategories = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        DefectReportedCategoryDetails: []
    });
    const { showToast } = useContext(AppContext);

    useEffect(async () => {
        pullDefectReportedCatagoriesAndUpdateState();
    }, []);

    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                DefectReportedCategoryDetails: params.value.row
            };
            return nst;
        });
    };

    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Defect Reported Catagory" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const column_new = [
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 120,
            renderCell: (params) => <Arrow value={params} />
        },
        {
            field: 'id', //defectReportedCategoryID
            headerName: 'Code',
            width: 200
        },
        {
            field: 'defectReportedCategoryDescription',
            headerName: 'Description',
            flex: 1
        }
    ];

    const pullDefectReportedCatagoriesAndUpdateState = async () => {
        let result = await getDefectReportedCategories();
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result?.data?.list?.map((w) => ({
                    ...w,
                    id: w.defectReportedCategoryID,
                    defectReportedCategoryDescription: w.defectReportedCategoryDescription
                })),
                show: false,
                showDel: false
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };

    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                DefectReportedCategoryDetails: null
            };
        });
    };

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No defect reported categories available</div>
            </GridOverlay>
        );
    }

    const closeUpdateUser = (refreshGrid) => {
        setState((st) => ({ ...st, show: false, showDel: false }));
        refreshGrid && pullDefectReportedCatagoriesAndUpdateState();
    };

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                    Add Defect Reported Category
                </SecondaryButton>
            </div>
            {state.show ? (
                <AddDefectReportedCategoriesModal onFormSubmit={closeUpdateUser} DefectReportedCategoryDetails={state.DefectReportedCategoryDetails} />
            ) : null}
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Defect Reported Catagories
                </Typography>
            </Breadcrumbs>
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={250} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default DefectReportedCategories;
