import React, { useContext, useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import AppContext from '../../../App/AppContext';
import { SecondaryButton, PrimaryButton } from '../../../Core/FormInput';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { DeleteWebForm } from './../../../Core/Service/webForm-service';
import Typography from '@material-ui/core/Typography';

const DeleteWebFormModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        WebFormName: ''
    });
    const WebFormId = props.WebFormId;
    const name = props.WebFormDetails.name;
    useEffect(async () => {
        setState((st) => {
            const nst = { ...st, WebFormName: name };
            return nst;
        });
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const handleDelete = async () => {
        let res = await DeleteWebForm(WebFormId);
        if (!res.success) {
            console.error(res);
        } else {
            hideModal();
            props.onFormSubmit(true);
        }
        showToast(res.message);
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitle>Delete Web Form - {state.WebFormName}</DialogTitle>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent style={{ width: '600px', height: '50px' }}>
                <Typography variant="h6" display="block" gutterBottom>
                    Are you sure to delete this web form
                </Typography>
            </DialogContent>
            <DialogActions>
                <SecondaryButton className="Submitbtn" onClick={handleClose}>
                    No
                </SecondaryButton>
                <PrimaryButton className="Submitbtn" onClick={handleDelete}>
                    Yes
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteWebFormModal;
