import React, { useContext, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppContext from '../../App/AppContext';
import { PasswordBox, SecondaryButton, PrimaryButton } from './../FormInput';
import { makeStyles } from '@material-ui/core/styles';
import { SetUserPassword } from '../Service/changePassword-service';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import './model.css';
import { AppStorage } from '../Service/storage-service';

const ResetPasswordModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        new: '',
        confirm: '',
        enableChange: false
    });

    const handleClose = () => {
        // hideModal();
        props.history.push('/');
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            nst.enableChange = nst.new && nst.confirm && nst.new === nst.confirm;
            return nst;
        });
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (state.new && state.confirm) {
            const res = await SetUserPassword(state.new, state.confirm, props.match.params.id, window.location.search.substring(1));
            if (!res.success) {
                console.error(res);
                let minLength = AppStorage.getPortalSettingPassLength();
                showToast(`Password should be complex - ${minLength} minimum characters, at least one uppercase and one numeric value`);
                // showToast(res.messages);
            } else {
                showToast(res.message);
                props.history.push('/');
            }
        } else {
            showToast('New password and confirm password is required.');
        }
        hideModal();
    };
    return (
        <Dialog open={true} onClose={handleClose} maxWidth="sm">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitle>Reset Password</DialogTitle>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent>
                <PasswordBox autoFocus label="New Password" name="new" onChange={fieldChange} />
                <PasswordBox label="Confirm Password" name="confirm" onChange={fieldChange} />
                <PrimaryButton className="Submitbtn" onClick={handleChangePassword} disabled={!state.enableChange}>
                    Submit
                </PrimaryButton>
            </DialogContent>
        </Dialog>
    );
};

export default ResetPasswordModal;
