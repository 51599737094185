import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { TextBox } from './../../../../Core/FormInput';

import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import moment, { relativeTimeRounding } from 'moment';

const ServiceTime = (props) => {
    const { service24, setService24, validation } = props;

    const addTimeRow = () => {
        setService24((st) => [
            ...st,
            {
                service24TimeID: null,
                service24Name: '',
                service24DrivingStartTime: '',
                service24DrivingEndTime: '',
                service24RepairStartTime: '',
                service24RepairEndTime: '',
                service24ServiceVan: '',
                service24DrivingStartMileage: null,
                service24DrivingFinishMileage: null,
                service24FinishPostCode: '',
                service24StartPostCode: ''
            }
        ]);
    };

    const removeTimeRow = (indx) => {
        setService24((st) => {
            const newSt = [...st];
            newSt.splice(indx, 1);
            return newSt;
        });
    };

    const handlepart = (index) => (e) => {
        let { name, value } = e.target;
        setService24((st) => {
            let newSt = [...st];
            let findIndex = [...st].find((n, i) => i === index);
            if (findIndex) {
                findIndex[name] = value;
            }

            return newSt;
        });
    };

    const TotalRepairTimeCal = (index) => {
        let totalRepair = '';
        let repairHours = '';
        let repairMinutes = '';
        let TotalRepairTime = '';
        let service24RepairStartTime = service24[index].service24RepairStartTime;
        let service24RepairEndTime = service24[index].service24RepairEndTime;

        if (service24RepairEndTime && service24RepairStartTime) {
            totalRepair = moment(service24RepairEndTime).diff(service24RepairStartTime, 'minute');
            repairHours = Math.floor(totalRepair / 60);
            repairMinutes = totalRepair % 60;
            TotalRepairTime = service24RepairEndTime && totalRepair > 0 ? `${repairHours} hours ${repairMinutes} minutes` : totalRepair < 0 ? '' : null;
        }
        return TotalRepairTime;
    };

    const TotalTravelTimeCal = (index) => {
        let Traveltimestart = '';
        let TraveltimeEnd = '';
        let TravelDiff = '';
        let travelHours = '';
        let travelMinutes = '';
        let TotalTravelTime = '';
        let service24RepairStartTime = service24[index].service24RepairStartTime;
        let service24DrivingStartTime = service24[index].service24DrivingStartTime;
        let service24DrivingEndTime = service24[index].service24DrivingEndTime;
        let service24RepairEndTime = service24[index].service24RepairEndTime;

        if (service24RepairStartTime && service24DrivingStartTime && service24DrivingEndTime && service24RepairEndTime) {
            Traveltimestart = moment(service24RepairStartTime).diff(service24DrivingStartTime, 'minute');
            TraveltimeEnd = moment(service24DrivingEndTime).diff(service24RepairEndTime, 'minute');
            TravelDiff = Traveltimestart + TraveltimeEnd;
            travelHours = Math.floor(TravelDiff / 60);
            travelMinutes = TravelDiff % 60;
            TotalTravelTime = service24DrivingEndTime && TravelDiff > 0 ? `${travelHours} hours ${travelMinutes} minutes` : TravelDiff < 0 ? '' : null;
        }
        return TotalTravelTime;
    };

    const TechTimeCal = (index) => {
        let totalRepair = '';
        let Traveltimestart = '';
        let TraveltimeEnd = '';
        let TravelDiff = '';
        let TechTime = '';
        let techHH = '';
        let techMM = '';
        let service24RepairEndTime = service24[index].service24RepairEndTime;
        let service24RepairStartTime = service24[index].service24RepairStartTime;
        let service24DrivingEndTime = service24[index].service24DrivingEndTime;
        let service24DrivingStartTime = service24[index].service24DrivingStartTime;
        let TotaltechTime = '';
        if (service24RepairEndTime && service24RepairStartTime && service24DrivingEndTime && service24DrivingStartTime) {
            totalRepair = moment(service24RepairEndTime).diff(service24RepairStartTime, 'minute');
            Traveltimestart = moment(service24RepairStartTime).diff(service24DrivingStartTime, 'minute');
            TraveltimeEnd = moment(service24DrivingEndTime).diff(service24RepairEndTime, 'minute');
            TravelDiff = Traveltimestart + TraveltimeEnd;

            TechTime = totalRepair + TravelDiff;
            techHH = Math.floor(TechTime / 60);
            techMM = TechTime % 60;
            TotaltechTime = TechTime > 0 ? `${techHH} hours ${techMM} minutes` : TravelDiff < 0 ? '' : null;
        }
        return TotaltechTime;
    };

    return (
        <Grid container spacing={1} justifyContent="flex-start">
            {service24.map((row, index) => {
                return (
                    <React.Fragment key={index}>
                        <Grid item xs={12}>
                            <div style={{ float: 'right' }}>
                                {index !== 0 && (
                                    <RemoveCircleIcon
                                        style={{ color: 'red', cursor: 'pointer' }}
                                        fontSize="medium"
                                        onClick={() => {
                                            removeTimeRow(index);
                                        }}
                                    />
                                )}
                                &nbsp;
                                {index === service24.length - 1 && (
                                    <AddBoxIcon style={{ color: 'green', cursor: 'pointer' }} fontSize="medium" onClick={addTimeRow} />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Name</InputLabel>
                            <TextBox
                                type="text"
                                placeholder="Name"
                                name="service24Name"
                                value={row.service24Name}
                                onChange={handlepart(index)}
                                inputProps={{ maxLength: 64 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Driving Start Time</InputLabel>
                            <TextBox
                                type="datetime-local"
                                name="service24DrivingStartTime"
                                value={row.service24DrivingStartTime}
                                onChange={handlepart(index)}
                                InputProps={{
                                    inputProps: { max: `${row.service24RepairStartTime}` }
                                }}
                            />
                            <FormHelperText error>{row.drivingStartTimeErrorMessage}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Work Start Time</InputLabel>
                            <TextBox
                                type="datetime-local"
                                name="service24RepairStartTime"
                                value={row.service24RepairStartTime}
                                onChange={handlepart(index)}
                                disabled={!row.service24DrivingStartTime}
                                InputProps={{ inputProps: { max: `${row.service24RepairEndTime}`, min: `${row.service24DrivingStartTime}` } }}
                            />
                            <FormHelperText error>{row.repairStartTimeErrorMessage}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Work Completed End Time</InputLabel>
                            <TextBox
                                type="datetime-local"
                                name="service24RepairEndTime"
                                value={row.service24RepairEndTime}
                                onChange={handlepart(index)}
                                disabled={!row.service24RepairStartTime}
                                InputProps={{
                                    inputProps: {
                                        max: `${row.service24DrivingEndTime}`,
                                        min: `${row.service24RepairStartTime}`
                                    }
                                }}
                            />
                            <FormHelperText error>{row.repairEndTimeErrorMessage}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Driving Finish Time</InputLabel>
                            <TextBox
                                disabled={!row.service24RepairEndTime}
                                type="datetime-local"
                                name="service24DrivingEndTime"
                                value={row.service24DrivingEndTime}
                                onChange={handlepart(index)}
                                InputProps={{ inputProps: { min: `${row.service24RepairEndTime}` } }}
                            />
                            <FormHelperText error>{row.drivingEndTimeErrorMessage}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Grid container>
                                <Grid item xs={12} sm={6} style={{ paddingRight: '8px' }}>
                                    <InputLabel shrink>Start Post Code</InputLabel>
                                    <TextBox
                                        // type="number"
                                        name="service24StartPostCode"
                                        value={row.service24StartPostCode}
                                        onChange={handlepart(index)}
                                        placeholder="Post Code"
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Start Mileage</InputLabel>
                                    <TextBox
                                        type="number"
                                        name="service24DrivingStartMileage"
                                        value={row.service24DrivingStartMileage}
                                        onChange={handlepart(index)}
                                        placeholder="Start Mileage"
                                    />
                                    <FormHelperText error>{row.drivingStartMileageErrorMessage}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Grid container>
                                <Grid item xs={12} sm={6} style={{ paddingRight: '8px' }}>
                                    <InputLabel shrink>Finish Post Code</InputLabel>
                                    <TextBox
                                        // type="number"
                                        name="service24FinishPostCode"
                                        value={row.service24FinishPostCode}
                                        onChange={handlepart(index)}
                                        placeholder="Post Code"
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Finish Mileage</InputLabel>
                                    <TextBox
                                        type="number"
                                        name="service24DrivingFinishMileage"
                                        value={row.service24DrivingFinishMileage}
                                        onChange={handlepart(index)}
                                        placeholder="Finish Mileage"
                                    />
                                    <FormHelperText error>{row.repairEndMilageMessage}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Service Van</InputLabel>
                            <TextBox
                                type="text"
                                name="service24ServiceVan"
                                value={row.service24ServiceVan}
                                onChange={handlepart(index)}
                                placeholder="Service Van"
                                inputProps={{ maxLength: 64 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Total Repair Time</InputLabel>
                            <TextBox disabled={true} value={TotalRepairTimeCal(index)} placeholder="Total Repair Time" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Total Travel Time</InputLabel>
                            <TextBox disabled={true} value={TotalTravelTimeCal(index)} placeholder="Total Travel Time" />{' '}
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Technician Time</InputLabel>
                            <TextBox disabled={true} value={TechTimeCal(index)} placeholder="Technician Time" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Total Mileage</InputLabel>
                            <TextBox disabled={true} value={row.service24DrivingEndTime && row.service24DrivingFinishMileage - row.service24DrivingStartMileage} placeholder="Total Mileage" />
                        </Grid>
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};

export default ServiceTime;
