import React, { useEffect, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AppContext from '../../../../App/AppContext';
import { Link } from 'react-router-dom';
import { Avatar, Card, CardContent, Typography, Container } from '@material-ui/core';
import '../../../Auditor/Dashboard/dashboard.scss';
import { getDashboardForInvoice } from '../../../../Core/Service/InvoiceScreen-service';
import EmailIcon from '@material-ui/icons/Email';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ForumIcon from '@material-ui/icons/Forum';
import TelegramIcon from '@material-ui/icons/Telegram';
import { RenderTiles } from '../../../customer/CustCommonDash';
import { AppStorage } from '../../../../Core/Service/storage-service';
const InvoicesDashboard = (props) => {
    const [state, setState] = useState({
        rows_new: []
    });
    const { showToast, userRoles } = useContext(AppContext);
    let isCustomer = userRoles[0] === 'customer';

    useEffect(async () => {
        let res = await getDashboardForInvoice();
        if (res.success) {
            setState((st) => ({ ...st, rows_new: res.data.list[0] }));
        } else {
            showToast(res.message);
        }
    }, []);
    const cards = [
        {
            id: 1,
            name: 'All Invoices',
            color: '#1abc9c',
            count: state.rows_new.allInvoices || 0,
            icon: <ForumIcon />,
            url: '/invoice/allInvoices'
        },
        {
            id: 2,
            name: 'Invoices Outstanding',
            color: '#2ecc71',
            count: state.rows_new.unPaidInvoices || 0,
            icon: <AnnouncementIcon />,
            url: '/invoice/Invoice_Outstanding'
        },
        {
            id: 3,
            name: 'Invoices Queried',
            color: '#e74c3c',
            count: state.rows_new.queriedCount || 0,
            icon: <LiveHelpIcon />,
            url: '/invoice/Invoice_Queried'
        },
        {
            id: 4,
            name: 'Invoices Paid',
            color: '#3498db',
            count: state.rows_new.paidInvoices || 0,
            icon: <AttachMoneyIcon />,
            url: '/invoice/Invoice_Paid'
        },
        {
            id: 5,
            name: 'Messages Sent',
            color: '#95a5a6',
            count: state.rows_new.messagesSent || 0,
            icon: <TelegramIcon />,
            url: '/invoice/messagesSent'
        },
        {
            id: 5,
            name: 'Messages Received',
            color: '#9b6bcc',
            count: state.rows_new.messagesReceived || 0,
            icon: <EmailIcon />,
            url: '/invoice/messagesReceived'
        }
    ];

    if (!isCustomer) {
        cards.push({
            id: 5,
            name: 'Total Customer',
            color: '#2ecc71',
            count: state.rows_new.customerCount || 0,
            icon: <PeopleIcon />,
            url: '/invoice/Customers'
        });
    }

    return (
        // <div className="auditor-dashboard">
        //     <Container className="cardGrid" maxWidth="xl">
        //         <Grid container spacing={4} justifyContent="space-between">
        //             {cards.map((option) => (
        //                 <Grid item key={option.id} xs={6} sm={6} md={4}>
        //                     <Link style={{ textDecoration: 'none' }} color="inherit" to={option.url}>
        //                         <Card
        //                             className="card"
        //                             style={{
        //                                 backgroundColor: `${option.color}`
        //                             }}
        //                         >
        //                             <CardContent className="cardContent">
        //                                 <Grid container spacing={2} justifyContent="space-between">
        //                                     <Grid item xs={3}>
        //                                         <Avatar style={{ background: 'skyblue', height: 70, width: 70, marginLeft: 5, marginTop: 5 }}>
        //                                             {option.icon ? option.icon : <span style={{ fontSize: 40 }}>£</span>}
        //                                         </Avatar>
        //                                     </Grid>
        //                                     <Grid item xs={9} style={{ textAlign: 'left' }}>
        //                                         <Typography variant="h4" gutterBottom style={{ marginBottom: 0, fontWeight: 'bold' }}>
        //                                             {option.count}
        //                                         </Typography>
        //                                         <Typography variant="h6" gutterBottom>
        //                                             {option.name}
        //                                         </Typography>
        //                                     </Grid>
        //                                 </Grid>
        //                             </CardContent>
        //                         </Card>
        //                     </Link>
        //                 </Grid>
        //             ))}
        //         </Grid>
        //     </Container>
        // </div>
        <div style={{ margin: 10 }}>
            <Grid
                container
                spacing={1}
                direction="row"
                // justify="space-between"
                alignItems="center"
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
            >
                {AppStorage.getInvoiceAllowed() && (
                    <Grid item xs={12} style={{ padding: '6px 8px' }}>
                        <RenderTiles cards={cards} label="Invoices" />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};
export default InvoicesDashboard;
