import React, { useEffect, useMemo, useState } from 'react';
// import './appraisal.scss';
import ReportGijgoGrid from '../ReportsScreens/GijgoForReportsScreen';
import { getAppraisalDropdown, getAppraisalDropdownsResolveData } from '../../../Core/Service/apprisal-service';
import formatters from '../../../Core/Grid/inputFormatter';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import AddIcon from '@material-ui/icons/Add';
import { Grid, Tooltip } from '@material-ui/core';
import { SquareButton } from '../../../Core/FormInput/AppButton';
import EditIcon from '@material-ui/icons/Edit';
// import ReactDOM from 'react-dom';

const baseColumns = [
    { title: 'Action', field: 'action', width: 100, sortable: true, align: 'left' },
    { title: 'Ref', field: 'recordId', width: 100, sortable: true },
    { title: 'Reg No', field: 'regNo', width: 120, sortable: true },
    {
        title: 'Type',
        field: 'vehicleTypeId',
        width: 130,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'vehicleTypes',
        dropDownValueField: 'id',
        dropDownLableField: 'name'
    },
    { title: 'Make', field: 'make', width: 200, sortable: true },
    { title: 'Value', field: 'expectedValue', width: 150, sortable: true },
    { title: 'Mileage', field: 'mileage', sortable: true },
    { title: 'Date', field: 'appraisalDate', sortable: true, width: 200, renderer: formatters.DateTimeFormatter },
    { title: 'Submitted By', field: 'displayName', sortable: true }
];

const AppraisalDashboard = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const screenName = params.get('screen');
    const screenId = params.get('id');

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Appraisal', path: '/Appraisal_Dashboard' },
        { name: screenName, active: true }
    ];
    const history = useHistory();
    const baseUrl = `Appraisal?${screenId ? `appraisalStatusID=${screenId}` : 'appraisalStatusID='}`;
    const randerVehicleType = (value, record) => {
        const spn = document.createElement('span');
        const lnk = <div>{record.vehicleTypeName}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const randerVehicleStatus = (value, record) => {
        const spn = document.createElement('span');
        const lnk = <div>{record.statusDescription}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const randerRef = (value, record) => {
        const spn = document.createElement('span');
        const lnk = <div style={{ color: 'blue', cursor: 'pointer' }}>{value}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const renderActionCol = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center" style={{ paddingRight: '7px' }}>
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px',
                        marginLeft: '10px'
                    }}
                    onClick={() => {
                        const searchQuery = `?appraisalId=${record?.recordId}&Vehid=${record?.vehicleTypeId}`;
                        history.push({
                            pathname: `/Appraisal_createAppraisal`,
                            search: searchQuery
                        });
                    }}
                >
                    {/* <Tooltip title="Add Appraisal" aria-label="add"> */}
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                    {/* </Tooltip> */}
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        const typeCol = tempCols.find((j) => j.field === 'vehicleTypeId');
        const refCol = tempCols.find((j) => j.field === 'recordId');
        const ActionCol = tempCols.find((j) => j.field === 'action');
        if (ActionCol) {
            ActionCol.renderer = renderActionCol;
        }
        if (typeCol) {
            typeCol.renderer = randerVehicleType;
        }
        if (refCol) {
            refCol.renderer = randerRef;
        }
        !props.id &&
            tempCols.push({
                title: 'Status',
                field: 'appraisalStatusID',
                sortable: true,
                isDropDown: true,
                listIdFromAPIResponse: 'status',
                dropDownValueField: 'id',
                dropDownLableField: 'name',
                renderer: randerVehicleStatus
            });
        let temp2 = tempCols.map((c) => {
            let obj = {};
            if (c.title !== 'Action') {
                obj.events = {
                    style: { cursor: 'pointer' },
                    click: function (e) {
                        const searchQuery = `?appraisalId=${e?.data?.record?.recordId}&Vehid=${e?.data?.record?.vehicleTypeId}`;
                        history.push({
                            pathname: `/Appraisal_createAppraisal`,
                            search: searchQuery
                        });
                    }
                };
            }

            return {
                ...c,
                ...obj
            };
        });

        return temp2;
    }, []);

    const addButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center" style={{ paddingRight: '7px' }}>
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px',
                        marginLeft: '20px'
                    }}
                    onClick={() =>
                        history.push({
                            pathname: `/Appraisal_createAppraisal`
                        })
                    }
                >
                    {/* <Tooltip title="Add Appraisal" aria-label="add"> */}
                    <AddIcon fontSize="small" style={{ color: 'white' }} />
                    {/* </Tooltip> */}
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    return (
        <div style={{ padding: 10 }}>
            <BreadCrumbs crumbs={crumbs} />
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res?.data?.list,
                    total: res?.data?.total
                })}
                columns={columns}
                getUrl={baseUrl}
                AddBtn={addButton}
                dropDownListProvider={getAppraisalDropdownsResolveData}
            />
        </div>
    );
};

export default AppraisalDashboard;
