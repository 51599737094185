import React, { useMemo } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { AppStorage } from '../../../../../Core/Service/storage-service';

let addCommas = (val) => {
    return val ? `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
};

const baseColumns = [
    { field: 'model', sortable: false, title: `Model`, width: 120, filterable: false },
    {
        field: 'currentProvision',
        title: `Current Provision`,
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false,
        isCurrency: true
    },
    {
        field: 'provisionRequired',
        sortable: true,
        title: `Provision Required`,
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false,
        isCurrency: true
    },
    {
        field: 'provisionDifference',
        sortable: true,
        title: `Provision Difference`,
        width: 130,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false,
        isCurrency: true
    },
    { field: 'totalAmount', sortable: true, title: `Total`, width: 120, showColumnTotal: true, align: 'right', filterable: false, isCurrency: true },
    { field: 'currentMonthTotal', sortable: true, title: `Current`, width: 120, showColumnTotal: true, align: 'right', filterable: false },
    {
        field: 'oneMonthTotal',
        sortable: true,
        title: `1 Month`,
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false
    },
    { field: 'twoMonthsTotal', sortable: true, title: `2 Month`, width: 120, showColumnTotal: true, align: 'right', filterable: false },
    { field: 'threeMonthsTotal', sortable: true, title: `3 Month`, width: 120, showColumnTotal: true, align: 'right', filterable: false },
    {
        field: 'fourMonthsAndOverTotal',
        sortable: true,
        title: '4+ Month',
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false
    },
    {
        field: 'unallocatedItemsTotal',
        title: 'Unallocated',
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false
    }
];
const crumbs = (val, menuPermissionCounter) => {
    let list = [{ name: 'Home', path: '/' }];
    if (menuPermissionCounter !== 1) {
        list.push({ name: 'Reports', path: '/ReportsDashboard' });
    }
    if (val) {
        list.push({ name: 'Aged Measures', path: '/AgedMeasures' }, { name: val, active: true });
    }
    return list;
};

const NewStocks = (props) => {
    const history = useHistory();
    let menuPermissionCounter = AppStorage.menuPermissionCounter();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let Measuredesc = decodeURIComponent(params.get('measuredesc') || '');
    let MeasureID = params.get('MeasureID') || '';
    let ParamFranchise = params.get('Franchise');
    const redirectToScreens = (value, record, MeasureID, field) => {
        let searchQuery = `?measuredesc=${Measuredesc}&MeasureID=${MeasureID}&franchise=${ParamFranchise}&month=${
            field.month == 0 ? 0 : field.month || '-9'
        }&mc=${record.model || ''}`;
        history.push({
            pathname: '/AgedMeasures/GetUsedAndNewVehicleDebt',
            search: searchQuery
        });
    };

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid title={value} onClick={() => redirectToScreens(value, record, MeasureID, field)} style={{ cursor: 'pointer' }}>
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const monthsActionHandler = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                title={field.toolTipLabel ? record[field.toolTipLabel] : value}
                onClick={() => redirectToScreens(value, record, MeasureID, field)}
                style={{ cursor: 'pointer' }}
            >
                {addCommas(value)}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    // const totalAmountWrapper = (value, record) => {
    //     const spn = document.createElement('span');
    //     const lnk = (
    //         <Grid>
    //             {addCommas(record.currentMonthTotal + record.oneMonthTotal + record.twoMonthsTotal + record.threeMonthsTotal + record.fourMonthsAndOverTotal)}
    //         </Grid>
    //     );
    //     ReactDOM.render(lnk, spn);
    //     return spn;
    // };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let modelCol = tempCols.find((element) => element.field === 'model');
        // let totalAmountCol = tempCols.find((element) => element.field === 'totalAmount');
        let currentMonthTotalCol = tempCols.find((element) => element.field === 'currentMonthTotal');
        let oneMonthTotalCol = tempCols.find((element) => element.field === 'oneMonthTotal');
        let twoMonthTotalCol = tempCols.find((element) => element.field === 'twoMonthsTotal');
        let threeMonthTotalCol = tempCols.find((element) => element.field === 'threeMonthsTotal');
        let fourPlusMonthTotalCol = tempCols.find((element) => element.field === 'fourMonthsAndOverTotal');
        let unallocatedTotalCol = tempCols.find((element) => element.field === 'unallocatedItemsTotal');
        if (modelCol) {
            modelCol.renderer = editButton({});
        }
        // if (totalAmountCol) {
        //     totalAmountCol.renderer = totalAmountWrapper;
        // }
        if (currentMonthTotalCol) {
            currentMonthTotalCol.renderer = monthsActionHandler({ month: 0, toolTipLabel: 'currentMonthCount' });
        }
        if (oneMonthTotalCol) {
            oneMonthTotalCol.renderer = monthsActionHandler({ month: 1, toolTipLabel: 'oneMonthCount' });
        }
        if (twoMonthTotalCol) {
            twoMonthTotalCol.renderer = monthsActionHandler({ month: 2, toolTipLabel: 'twoMonthsCount' });
        }
        if (threeMonthTotalCol) {
            threeMonthTotalCol.renderer = monthsActionHandler({ month: 3, toolTipLabel: 'threeMonthsCount' });
        }
        if (fourPlusMonthTotalCol) {
            fourPlusMonthTotalCol.renderer = monthsActionHandler({ month: 4, toolTipLabel: 'fourMonthsAndOverCount' });
        }
        if (unallocatedTotalCol) {
            unallocatedTotalCol.renderer = monthsActionHandler({ unallocated: 1, toolTipLabel: 'unallocatedItemsCount' });
        }
        return tempCols;
    }, [MeasureID]);

    const baseUrl = `AgedMeasures/GetNewVehicleDebtByModel?Franchise=${ParamFranchise}`;
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(Measuredesc, menuPermissionCounter)} />
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list?.map((p) => {
                                return {
                                    ...p,
                                    totalAmount: p.currentMonthTotal + p.oneMonthTotal + p.twoMonthsTotal + p.threeMonthsTotal + p.fourMonthsAndOverTotal
                                };
                            }),
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isShowTotal={true}
                        // hidePagination={true}
                        hideFilterRow={true}
                        downloadName={Measuredesc}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default NewStocks;
