import { Grid, Typography } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import formatters from '../../../../Core/Grid/inputFormatter';
import './miniStyle.scss';

const setPercentage = (profit, budget) => {
    if (!profit) {
        return 0;
    } else {
        if (budget == null || budget == 0) {
            return 100;
        } else {
            let value = (+profit / +budget) * 100;

            return Math.round(value) <= 100 ? Math.round(value) : 100;
        }
    }
};

export const VehicleCard = (props) => {
    const { mtdProfit, mtdBudget, mtdUnit, mtdBudgetunit, label, ytdProfit, ytdBudget, ytdUnit, ytdBudgetunit } = props;

    return (
        <Grid container style={{ textAlign: 'justify', padding: '0px 5px ' }} justifyContent="space-around" alignItems="center" className="detailDashmainCard">
            <Grid item xs={12} className="p-100">
                <Typography className="mainCardTextPrimary  ml-10">{label}</Typography>
            </Grid>
            <Grid item container xs={6} spacing={2} justifyContent="center">
                <Grid item xs={12} className="p-100">
                    <Typography className="mainCardTextPrimary  ml-10">MTD</Typography>
                </Grid>
                <Grid item>
                    <div className="vehicleRing">
                        <CircularProgressbar
                            value={setPercentage(mtdProfit, mtdBudget)}
                            text={`${setPercentage(mtdProfit, mtdBudget) || 0}%`}
                            styles={buildStyles({
                                textColor:
                                    setPercentage(mtdProfit, mtdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(mtdProfit, mtdBudget) >= 50 && setPercentage(mtdProfit, mtdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(mtdProfit, mtdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                pathColor:
                                    setPercentage(mtdProfit, mtdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(mtdProfit, mtdBudget) >= 50 && setPercentage(mtdProfit, mtdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(mtdProfit, mtdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                trailColor: 'lightgrey',
                                // strokeLinecap: '',
                                textSize: '27px'
                            })}
                            strokeWidth={8}
                        />
                    </div>
                </Grid>
                {/* <Grid item={2}></Grid> */}

                {props.isElectric ? (
                    <Grid item xs={12}>
                        <div
                            style={{
                                marginBottom: 5,
                                // display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div className="salesCardTextPrimary  ">ACTUAL</div>
                            <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                {formatters.ThousandSeparatorWithoutZero(mtdProfit) || 0}
                            </div>
                        </div>

                        <div
                            style={{
                                // display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div className="salesCardTextPrimary  ">BUDGET</div>
                            <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                {formatters.ThousandSeparatorWithoutZero(mtdBudget) || 0}
                            </div>
                        </div>
                    </Grid>
                ) : (
                    <>
                        {props.hideProfit ? (
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        marginBottom: 5,
                                        // display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">ACTUAL</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {mtdProfit || 0}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        //  display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">BUDGET</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {mtdBudget || 0}
                                    </div>
                                </div>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        marginBottom: 5,
                                        // display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">ACTUAL</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {mtdUnit || 0}/{formatters.CurrencyThousandSeparatorWithoutZero(mtdProfit) || 0}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        //  display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">BUDGET</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {mtdBudgetunit || 0}/{formatters.CurrencyThousandSeparatorWithoutZero(mtdBudget) || 0}
                                    </div>
                                </div>
                            </Grid>
                        )}
                    </>
                )}
                {/* <Grid item></Grid> */}
            </Grid>
            <Grid item container xs={6} spacing={2} justifyContent="center">
                <Grid item xs={12} className="p-100">
                    <Typography className="mainCardTextPrimary  ml-10">YTD</Typography>
                </Grid>
                <Grid item>
                    <div className="vehicleRing">
                        <CircularProgressbar
                            value={setPercentage(ytdProfit, ytdBudget)}
                            text={`${setPercentage(ytdProfit, ytdBudget) || 0}%`}
                            styles={buildStyles({
                                textColor:
                                    setPercentage(ytdProfit, ytdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdProfit, ytdBudget) >= 50 && setPercentage(ytdProfit, ytdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdProfit, ytdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                pathColor:
                                    setPercentage(ytdProfit, ytdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdProfit, ytdBudget) >= 50 && setPercentage(ytdProfit, ytdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdProfit, ytdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                trailColor: 'lightgrey',
                                // strokeLinecap: '',
                                textSize: '27px'
                            })}
                            strokeWidth={8}
                        />
                    </div>
                </Grid>
                {/* <Grid item={2}></Grid> */}

                {props.isElectric ? (
                    <Grid item xs={12}>
                        <div
                            style={{
                                marginBottom: 5,
                                // display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div className="salesCardTextPrimary  ">ACTUAL</div>
                            <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                {formatters.ThousandSeparatorWithoutZero(ytdProfit) || 0}
                            </div>
                        </div>

                        <div
                            style={{
                                // display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div className="salesCardTextPrimary  ">BUDGET</div>
                            <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                {formatters.ThousandSeparatorWithoutZero(ytdBudget) || 0}
                            </div>
                        </div>
                    </Grid>
                ) : (
                    <>
                        {props.hideProfit ? (
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        marginBottom: 5,
                                        // display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">ACTUAL</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {ytdProfit || 0}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        //  display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">BUDGET</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {ytdBudget || 0}
                                    </div>
                                </div>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        marginBottom: 5,
                                        // display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">ACTUAL</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {ytdUnit || 0}/{formatters.CurrencyThousandSeparatorWithoutZero(ytdProfit) || 0}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        //  display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">BUDGET</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {ytdBudgetunit || 0}/{formatters.CurrencyThousandSeparatorWithoutZero(ytdBudget) || 0}
                                    </div>
                                </div>
                            </Grid>
                        )}
                    </>
                )}
                {/* <Grid item></Grid> */}
            </Grid>

            <Grid item xs={12}>
                <div
                    style={{
                        marginBottom: 20
                    }}
                ></div>
            </Grid>
        </Grid>
    );
};
export const VehicleCardNew = (props) => {
    const { mtdProfit, mtdBudget, mtdUnit, mtdBudgetunit, label, ytdProfit, ytdBudget, ytdUnit, ytdBudgetunit, actUnit, budgUnit } = props;

    return (
        <Grid container style={{ textAlign: 'justify', padding: '0px 5px ' }} justifyContent="space-around" alignItems="center" className="detailDashmainCard">
            <Grid item xs={12} className="p-100">
                <Typography className="mainCardTextPrimary  ml-10">{label}</Typography>
            </Grid>
            <Grid item container xs={6} spacing={2} justifyContent="center">
                <Grid item xs={12} className="p-100">
                    <Typography className="mainCardTextPrimary  ml-10">MTD</Typography>
                </Grid>
                <Grid item>
                    <div className="vehicleRing">
                        <CircularProgressbar
                            value={setPercentage(mtdUnit, mtdBudgetunit)}
                            text={`${setPercentage(mtdUnit, mtdBudgetunit) || 0}%`}
                            styles={buildStyles({
                                textColor:
                                    setPercentage(mtdUnit, mtdBudgetunit) < 50
                                        ? '#D92641'
                                        : setPercentage(mtdUnit, mtdBudgetunit) >= 50 && setPercentage(mtdUnit, mtdBudgetunit) < 75
                                        ? '#ff9f00'
                                        : setPercentage(mtdUnit, mtdBudgetunit) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                pathColor:
                                    setPercentage(mtdUnit, mtdBudgetunit) < 50
                                        ? '#D92641'
                                        : setPercentage(mtdUnit, mtdBudgetunit) >= 50 && setPercentage(mtdUnit, mtdBudgetunit) < 75
                                        ? '#ff9f00'
                                        : setPercentage(mtdUnit, mtdBudgetunit) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                trailColor: 'lightgrey',
                                // strokeLinecap: '',
                                textSize: '27px'
                            })}
                            strokeWidth={8}
                        />
                    </div>
                </Grid>
                {/* <Grid item={2}></Grid> */}

                <>
                    <Grid item xs={12}>
                        <div
                            style={{
                                marginBottom: 5,
                                // display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div className="salesCardTextPrimary  ">ACTUAL</div>
                            <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                {' '}
                                {mtdUnit || 0}/{formatters.CurrencyThousandSeparatorWithoutZero(mtdProfit) || 0}
                            </div>
                        </div>

                        <div
                            style={{
                                //  display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div className="salesCardTextPrimary  ">BUDGET</div>
                            <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                {' '}
                                {mtdBudgetunit || 0}/{formatters.CurrencyThousandSeparatorWithoutZero(mtdBudget) || 0}
                            </div>
                        </div>
                    </Grid>
                </>

                {/* <Grid item></Grid> */}
            </Grid>
            <Grid item container xs={6} spacing={2} justifyContent="center">
                <Grid item xs={12} className="p-100">
                    <Typography className="mainCardTextPrimary  ml-10">YTD</Typography>
                </Grid>
                <Grid item>
                    <div className="vehicleRing">
                        <CircularProgressbar
                            value={setPercentage(ytdUnit, ytdBudgetunit)}
                            text={`${setPercentage(ytdUnit, ytdBudgetunit) || 0}%`}
                            styles={buildStyles({
                                textColor:
                                    setPercentage(ytdUnit, ytdBudgetunit) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdUnit, ytdBudgetunit) >= 50 && setPercentage(ytdUnit, ytdBudgetunit) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdUnit, ytdBudgetunit) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                pathColor:
                                    setPercentage(ytdUnit, ytdBudgetunit) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdUnit, ytdBudgetunit) >= 50 && setPercentage(ytdUnit, ytdBudgetunit) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdUnit, ytdBudgetunit) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                trailColor: 'lightgrey',
                                // strokeLinecap: '',
                                textSize: '27px'
                            })}
                            strokeWidth={8}
                        />
                    </div>
                </Grid>
                {/* <Grid item={2}></Grid> */}

                {props.isElectric ? (
                    <Grid item xs={12}>
                        <div
                            style={{
                                marginBottom: 5,
                                // display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div className="salesCardTextPrimary  ">ACTUAL</div>
                            <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                {formatters.ThousandSeparatorWithoutZero(ytdProfit) || 0}
                            </div>
                        </div>

                        <div
                            style={{
                                // display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div className="salesCardTextPrimary  ">BUDGET</div>
                            <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                {formatters.ThousandSeparatorWithoutZero(ytdBudget) || 0}
                            </div>
                        </div>
                    </Grid>
                ) : (
                    <>
                        {props.hideProfit ? (
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        marginBottom: 5,
                                        // display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">ACTUAL</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {ytdProfit || 0}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        //  display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">BUDGET</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {ytdBudget || 0}
                                    </div>
                                </div>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        marginBottom: 5,
                                        // display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">ACTUAL</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {ytdUnit || 0}/{formatters.CurrencyThousandSeparatorWithoutZero(ytdProfit) || 0}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        //  display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div className="salesCardTextPrimary  ">BUDGET</div>
                                    <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                        {' '}
                                        {ytdBudgetunit || 0}/{formatters.CurrencyThousandSeparatorWithoutZero(ytdBudget) || 0}
                                    </div>
                                </div>
                            </Grid>
                        )}
                    </>
                )}
                {/* <Grid item></Grid> */}
            </Grid>

            <Grid item xs={12}>
                <div
                    style={{
                        marginBottom: 20
                    }}
                ></div>
            </Grid>
        </Grid>
    );
};
