import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    body: {
        padding: '5px 10px 10px 5px',
        display: 'flex',
        justifyContent: 'center',
        background: 'white',
        color: 'black'
    }
}));

export default function SimpleAccordion({ label, children, rest }) {
    const classes = useStyles();

    return (
        // <div >
        <Accordion className={classes.root} style={rest}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.body}>{children}</AccordionDetails>
        </Accordion>
        // </div>
    );
}
