import React from 'react';
import moment from 'moment';
import { Grid, FormLabel, Breadcrumbs, InputLabel } from '@material-ui/core';
import { SingelSelect, TextBox } from '../../../../Core/FormInput';
import NestedGijgoGrid from '../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { useState } from 'react';
import { useEffect } from 'react';
import { getSalesVehicleDropdown } from '../../../../Core/Service/salesDasboard-service';
import { useMemo } from 'react';
import ReactDOM from 'react-dom';
import DialogComp from '../../../../Core/Modal/dialogModal';
import { VehicleNotes } from './VehicleNotes';
import JobNotesModal from '../../dashboard/UsedSalesDashboard/WorkFlow/JobNotes';

let addCommas = (val) => {
    return val ? `${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '0.00';
};

var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

let baseColumns = [
    { title: 'Exec', field: 'sp', width: 100, sortable: true },
    { title: 'Bought By', field: 'buyer', width: 90, sortable: true },
    { title: 'VSB', field: 'vsb', width: 90, sortable: true },
    { title: 'Customer', field: 'cust', width: 150, sortable: true },
    { title: 'Branch', field: 'loc', width: 90, sortable: true },
    {
        title: 'Reg Number',
        field: 'reg',
        width: 80,
        sortable: true
    },
    { title: 'Vehicle', field: 'v', width: 150, sortable: true },
    {
        title: 'Invoice Date',
        field: 'id',
        width: 90,
        sortable: true,
        // type: 'date',
        format: 'dd-mmm-yyyy',
        cssClass: 'text-center',
        renderer: function (value, record) {
            if (record.id != null) {
                let date = new Date(record.id);
                return date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear();
            }
            return '';
        }
    },
    // {
    //     title: 'Sale Price',
    //     field: 'sale',
    //     width: 90,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.sale + '"  >£' + addCommas(record.sale) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Cost Price',
    //     field: 'cost',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.cost + '"  >£' + addCommas(record.cost) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Disc O/A',
    //     field: 'discoval',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.Discoval + '"  >£' + addCommas(record.Discoval) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Bonuses',
    //     field: 'bonuses',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.Bonuses + '"  >£' + addCommas(record.Bonuses) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Finance',
    //     field: 'finance',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.Finance + '"  >£' + addCommas(record.Finance) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Prep Cost',
    //     field: 'prepCost',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.PrepCost + '"  >£' + addCommas(record.PrepCost) + '</span>';
    //     },
    //     align: 'right'
    // },
    {
        title: 'Total Sale',
        field: 'ts',
        width: 90,
        sortable: true,
        renderer: function (value, record) {
            return '<span style="cursor:pointer" title="' + record.ts + '"  >£' + addCommas(record.ts) + '</span>';
        },
        align: 'right'
    },
    {
        title: 'Total Cost',
        field: 'tc',
        width: 70,
        sortable: true,
        renderer: function (value, record) {
            return '<span style="cursor:pointer" title="' + record.tc + '"  >£' + addCommas(record.tc) + '</span>';
        },
        align: 'right'
    },
    {
        title: 'Gross Profit',
        field: 'gp',
        width: 70,
        sortable: true,
        renderer: function (value, record) {
            return '<span style="cursor:pointer" title="' + record.gpp + '"  >£' + addCommas(record.gp) + '</span>';
        },
        align: 'right'
    }
];

const detailTemplate = () => {
    return `<div style='width:100%'>
    <table style='width:100%'>
        <tr style='background-color:#DCDCDC;'>
            <th style='font-weight:750; text-align:left;'>Sale Price</th>
            <th style='font-weight:750; text-align:left;'>Cost Price</th>
            <th style='font-weight:750; text-align:left;'>Disc O/A</th>
            <th style='font-weight:750; text-align:left;'>Bonuses</th>
            <th style='font-weight:750; text-align:left;'>Finance</th>
            <th style='font-weight:750; text-align:left;'>Prep Cost</th>
            <th style='font-weight:750; text-align:left;'>ACC Sale</th>
            <th style='font-weight:750; text-align:left;'>ACC Cost</th>      
            <th style='font-weight:750; text-align:left;'>War Sale</th>
            <th style='font-weight:750; text-align:left;'>War Cost</th>
            <th style='font-weight:750; text-align:left;'>Body Cost</th>
            <th style='font-weight:750; text-align:left;'>Subsidy</th>
            <th style='font-weight:750; text-align:left;'>CPI</th>
            <th style='font-weight:750; width:20%; text-align:left;'>Finance Company</th>
        </tr> 
        <tr>
            <td>£{sale}</td>
            <td>£{cost}</td>
            <td>£{discoval}</td>
            <td>£{bonuses}</td>
            <td>£{finance}</td>
            <td>£{prepCost}</td>
            <td>£{accSale}</td>
            <td>£{accCost}</td>
            <td>£{warSale}</td>
            <td>£{warCost}</td>
            <td>£{bodyCost}</td>
            <td>£{subsidy}</td>
            <td>£{cpi}</td>
            <td>{fcompany}</td>
            </tr></div>`;
};

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'Used Vehicle Profit Report', path: '/', active: true }
];

let startDate = moment().startOf('month').format('YYYY-MM-DD');
let endDate = moment().format('YYYY-MM-DD');

const UsedVehicleProfitReport = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let PramCategoryType = params.get('CategoryType') || '';
    let startDateParams = params.get('start') || '';
    let endDateParams = params.get('end') || '';
    let typeID = params.get('type') || '';
    let modelID = params.get('model') || '';

    const [state, setState] = useState({
        categoriesList: [],
        categoriesId: {},
        modelAnalysisCodeList: [],
        categoriesId: typeID ? (typeID === '1' ? { id: 1, name: 'VAN' } : { id: 2, name: 'TRUCK' }) : {},
        modelAnalysisCodeId: {},
        startDate: startDateParams || startDate,
        endDate: endDateParams || endDate,
        handleVehicleNotes: false,
        records: {}
    });

    useEffect(async () => {
        let res = await getSalesVehicleDropdown(true);

        if (res.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.categoriesList = res.data?.category || [];
                newSt.modelAnalysisCodeList = res.data?.modelAnalysisCode || [];
                if (PramCategoryType) {
                    newSt.categoriesId = res.data?.category.find((r) => r.id == PramCategoryType);
                }
                return newSt;
            });
        }
    }, []);

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st, [nm]: val };
            return newSt;
        });
    };

    useEffect(async () => {
        if (modelID) {
            setState((st) => {
                let newSt = { ...st };
                newSt.modelAnalysisCodeId = state.modelAnalysisCodeList.find((k) => k.id === modelID) || {};

                return newSt;
            });
        }
    }, [modelID, state.modelAnalysisCodeList]);

    useEffect(async () => {
        setState((st) => {
            let newSt = { ...st };
            if (JSON.stringify(state.categoriesId) !== '{}') {
                newSt.filterAnalysiscodelist = state.modelAnalysisCodeList.filter((k) => k.cid === state.categoriesId?.id);
            } else {
                newSt.filterAnalysiscodelist = [...st.modelAnalysisCodeList];
            }
            return newSt;
        });
    }, [state.categoriesId, state.modelAnalysisCodeList]);

    const baseUrl = `Sales/UsedVehicleProfitReport?StartDate=${state.startDate}&EndDate=${state.endDate}&VehicleCategoryID=${
        state.categoriesId?.id || ''
    }&rmac=${state.modelAnalysisCodeId?.id || ''}`;

    const HandelNotesModal = (value = {}) => {
        setState((st) => ({
            ...st,
            handleVehicleNotes: !st.handleVehicleNotes,
            records: { sn: value.vsb, vd: value.v, rn: value.reg },
            selectedStockID: value.usedStockID || null
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <div
                //  style={{ cursor: 'pointer', fontWeight: 600 }} onClick={() => HandelNotesModal(record)}
                >
                    {value}
                </div>
                {/* <EditIcon fontSize="small" style={{ color: 'white' }} /> */}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];

        let StockCol = tempCols.find((k) => k.field === 'vsb');
        if (StockCol) {
            StockCol.renderer = editButton;
        }

        return tempCols;
    }, []);
    const CustomeTitle = () => {
        return (
            <div style={{ marginTop: -8 }}>
                <div>Vehicle Notes -{state.records.sn}</div>
                <div style={{ fontSize: '18px', lineHeight: 1, color: 'gray' }}>
                    {state.records.rn} &nbsp;{state.records.vd}
                </div>
            </div>
        );
    };
    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <Grid container style={{ padding: '0px 10px' }}>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={6} sm={6} md={3}>
                        <InputLabel className="mandatoryfields" shrink>
                            Start Date
                        </InputLabel>
                        <TextBox
                            placeholder="Start Date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={handleinput}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <InputLabel className="mandatoryfields" shrink>
                            End Date
                        </InputLabel>

                        <TextBox
                            placeholder="End Date"
                            name="endDate"
                            fullWidth
                            type="date"
                            value={state.endDate}
                            onChange={handleinput}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <InputLabel shrink>Vehicle Categories</InputLabel>
                        <SingelSelect
                            options={state.categoriesList || []}
                            value={state.categoriesId}
                            onChange={singleSelectChange('categoriesId')}
                            // disabled={state.isReadOnly}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <InputLabel shrink>Model</InputLabel>
                        <SingelSelect
                            options={state.filterAnalysiscodelist || []}
                            value={state.modelAnalysisCodeId}
                            onChange={singleSelectChange('modelAnalysisCodeId')}
                            // disabled={state.isReadOnly}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <NestedGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        displayPagination={true}
                        detailTemplate={detailTemplate}
                        downloadName={'Used Vehicle Profit Report'}
                    />
                </Grid>
            </Grid>

            {state.handleVehicleNotes ? (
                <JobNotesModal title={CustomeTitle()} stockID={state.selectedStockID} onClose={HandelNotesModal} onNotesAdded={HandelNotesModal} />
            ) : null}
        </div>
    );
};

export default UsedVehicleProfitReport;
