import {
    Card,
    CircularProgress,
    Grid,
    Hidden,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
    withStyles
} from '@material-ui/core';
import React, { useEffect } from 'react';
import './salesDashboardSetting.scss';
import { getSaleDashDatabyID, getNewStockbyID, getUsedStockabyID, getFinanceList } from '../../../../../Core/Service/SalesPerformance-service';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { useState } from 'react';
import OrderTakeScreen from './OrderTakeTable';
import RegistrationReportsTable from './RegistrationTable';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import { fontWeight } from '@material-ui/system';
import SimpleAccordion from '../../../../../Core/Controls/Accordion';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';
import { SingelSelect } from '../../../../../Core/FormInput';

const years = () => {
    const years = [];
    const d = new Date();
    let startYear = d.getFullYear() - 10;
    let endYear = d.getFullYear() + 10;

    while (endYear - startYear >= 0) {
        years.push({ name: `${startYear}`, id: `${startYear}` });
        startYear = startYear + 1;
    }
    return years;
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 1,
        border: '1px solid white ',
        Opacity: 0.9,
        minWidth: 120,
        padding: '5px 0px',
        background: 'white',
        borderRadius: 10
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#b9b4b4',
        fontSize: 16,
        borderRight: '1px solid White',
        color: theme.palette.common.white,
        padding: '5px'
    },
    body: {
        backgroundColor: 'white',
        fontSize: 14,
        borderRight: '1px solid white',
        // color: theme.palette.common.white,
        padding: '5px',
        fontWeight: 600
    }
}))(TableCell);

let checkValueColor = (value) => {
    return { color: value >= 0 ? 'green' : 'red' };
};

const SalesDashboardSettingComp = (props) => {
    const [width, height] = useWindowSize();
    const history = useHistory();
    const handleOrderTakeRedirect = (MonthID) => {
        let start = '';
        let end = '';
        if (MonthID === 'year') {
            start = moment().startOf('year').format('YYYY-MM-DD');
            end = moment().endOf('year').format('YYYY-MM-DD');
        } else {
            start = moment().month(MonthID).startOf('month').format('YYYY-MM-DD');
            end = moment().month(MonthID).endOf('month').format('YYYY-MM-DD');
        }
        // let searchQuery = `?startDate=${start}&endDate=${end}}`;
        history.push({
            pathname: `/salesPefromance/orderTake`
            // search: searchQuery
        });
    };
    const handleRegistrationRedirect = (qtr) => {
        // let start = '';
        // let end = '';
        // if (MonthID === 'year') {
        //     start = moment().startOf('year').format('YYYY-MM-DD');
        //     end = moment().endOf('year').format('YYYY-MM-DD');
        // } else {
        //     start = moment().month(MonthID).startOf('month').format('YYYY-MM-DD');
        //     end = moment().month(MonthID).endOf('month').format('YYYY-MM-DD');
        // }
        let searchQuery = `?Qtr=${qtr}`;
        history.push({
            pathname: `/salesPefromance/Registration`,
            search: qtr ? searchQuery : ''
        });
    };
    const handleInvoicedRedirect = (qtr) => {
        // let start = '';
        // let end = '';
        // if (MonthID === 'year') {
        //     start = moment().startOf('year').format('YYYY-MM-DD');
        //     end = moment().endOf('year').format('YYYY-MM-DD');
        // } else {
        //     start = moment().month(MonthID).startOf('month').format('YYYY-MM-DD');
        //     end = moment().month(MonthID).endOf('month').format('YYYY-MM-DD');
        // }
        // let searchQuery = `?startDate=${start}&endDate=${end}}`;

        let searchQuery = `?Qtr=${qtr}`;
        history.push({
            pathname: `/salesPefromance/invoiced`,
            search: qtr ? searchQuery : ''
        });
    };

    const HandelLeagueClick = (val) => {
        let query = `?name=${val.name}&id=${val.id}`;
        history.push({
            pathname: '/league_Detail',
            search: query
        });
    };

    const classes = useStyles();
    const [value, setValue] = React.useState('1');
    const [state, setState] = useState({
        salesDashboarObj: {},
        league: [],
        showLoader: true,
        FranchiseList: [],
        fn: null,
        year: { name: moment().format('YYYY'), id: moment().format('YYYY') },
        yearList: years() || []
    });

    const PullDashboardData = async () => {
        let response = await getSaleDashDatabyID(props.ID, state.fn?.id, state.year?.id);
        if (response.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.salesDashboarObj = response.data.list[0];
                newSt.league = response.data?.leagueTable;
                newSt.showLoader = false;
                return newSt;
            });
        }
    };
    useEffect(async () => {
        PullDashboardData();
    }, [state.fn, state.year]);

    useEffect(async () => {
        let response = await getFinanceList();
        if (response.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.FranchiseList =
                    response.data.map((m) => ({
                        id: m.letterIdentifier,
                        name: m.franchiseName
                    })) || [];
                return newSt;
            });
        }
        PullDashboardData();
    }, []);

    const dashobj = state.salesDashboarObj;

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st, [nm]: val };
            return newSt;
        });
    };

    return (
        <div style={{ padding: 10 }}>
            {state.showLoader ? (
                <Grid container style={{ height: 500 }} justifyContent="center" alignContent="center">
                    <CircularProgress color="secondary" />
                </Grid>
            ) : (
                <Grid container spacing={2} justifyContent="space-around" alignContent="center">
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <div
                                    style={{
                                        margin: '5px 10px 0px 10px',
                                        textAlign: 'left',
                                        fontSize: 20,
                                        fontWeight: 500
                                    }}
                                >
                                    ORDER TAKE
                                </div>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3} xl={2}>
                                <SingelSelect
                                    placeholder="Select Franchise"
                                    onChange={singleSelectChange('fn')}
                                    options={state.FranchiseList || []}
                                    value={state.fn}
                                />
                            </Grid>
                            <Grid item xs={6} md={3} lg={3} xl={2}>
                                <SingelSelect
                                    placeholder="Select Year"
                                    onChange={singleSelectChange('year')}
                                    options={state.yearList || []}
                                    value={state.year}
                                />
                            </Grid>
                        </Grid>
                        {width < 1023 ? (
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <Grid container spacing={1} justifyContent={width >= 1633 ? 'space-between' : 'flex-start'}>
                                        <Grid item xs={width > 380 ? 6 : 12} sm={4} className="Cursor" onClick={handleOrderTakeRedirect}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> DAY</span>
                                                <div className="valueFont">
                                                    <span
                                                        style={{
                                                            color: dashobj.dayOrdersBudgetUnits <= dashobj.dayOrdersUnits ? 'green' : 'red'
                                                        }}
                                                    >
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.dayOrdersUnits)}
                                                    </span>
                                                    <small
                                                        style={{
                                                            fontSize: 14,
                                                            color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red'
                                                        }}
                                                    >
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.dayOrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={width > 380 ? 6 : 12} sm={4} className="Cursor" onClick={handleOrderTakeRedirect}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> MTD</span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.mtdOrdersUnits)}
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.mtdOrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={width > 380 ? 6 : 12} sm={4} className="Cursor" onClick={handleOrderTakeRedirect}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> YTD </span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.ytdOrdersUnits)}
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.ytdOrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={width > 380 ? 6 : 12} sm={4} className="Cursor" onClick={handleOrderTakeRedirect}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> QTR-1</span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.q1OrdersUnits)}
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q1OrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={width > 380 ? 6 : 12} sm={4} className="Cursor" onClick={handleOrderTakeRedirect}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> QTR-2</span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.q2OrdersUnits)}
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q2OrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={width > 380 ? 6 : 12} sm={4} className="Cursor" onClick={handleOrderTakeRedirect}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> QTR-3</span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.q3OrdersUnits)}
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q3OrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={width > 380 ? 6 : 12} sm={4} className="Cursor" onClick={handleOrderTakeRedirect}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> QTR-4</span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.q4OrdersUnits)}
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q4OrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Grid container spacing={1} justifyContent={width >= 1633 ? 'space-between' : 'flex-start'}>
                                    {/* <Card className={classes.root}> */}
                                    <Grid item className="Cursor" onClick={handleOrderTakeRedirect}>
                                        <Card
                                            className="orderCard"
                                            // style={{ color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red' }}
                                        >
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> DAY</span>
                                                <div className="valueFont">
                                                    <span
                                                        style={{
                                                            color: dashobj.dayOrdersBudgetUnits <= dashobj.dayOrdersUnits ? 'green' : 'red'
                                                        }}
                                                    >
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.dayOrdersUnits)}
                                                    </span>
                                                    &nbsp;
                                                    <small
                                                        style={{
                                                            fontSize: 14,
                                                            color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red'
                                                        }}
                                                    >
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.dayOrdersProfit, true)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item className="Cursor" onClick={handleOrderTakeRedirect}>
                                        <Card className="orderCard" style={checkValueColor(dashobj.mtdOrdersUnits)}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> MTD</span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.mtdOrdersUnits)}&nbsp;
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.mtdOrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item className="Cursor" onClick={handleOrderTakeRedirect}>
                                        <Card className="orderCard" style={checkValueColor(dashobj.ytdOrdersUnits)}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> YTD </span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.ytdOrdersUnits)}&nbsp;
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.ytdOrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item className="Cursor" onClick={handleOrderTakeRedirect}>
                                        <Card className="orderCard" style={checkValueColor(dashobj.q1OrdersUnits)}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> QTR-1</span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.q1OrdersUnits)}&nbsp;
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q1OrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item className="Cursor" onClick={handleOrderTakeRedirect}>
                                        <Card className="orderCard" style={checkValueColor(dashobj.q2OrdersUnits)}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> QTR-2</span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.q2OrdersUnits)}&nbsp;
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q2OrdersProfit)})
                                                    </small>{' '}
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item className="Cursor" onClick={handleOrderTakeRedirect}>
                                        <Card className="orderCard" style={checkValueColor(dashobj.q3OrdersUnits)}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> QTR-3</span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.q3OrdersUnits)}&nbsp;
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q3OrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item className="Cursor" onClick={handleOrderTakeRedirect}>
                                        <Card className="orderCard" style={checkValueColor(dashobj.q4OrdersUnits)}>
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> QTR-4</span>
                                                <div className="valueFont">
                                                    {formatters.ThousandSeparatorWithoutZero(dashobj.q4OrdersUnits)}&nbsp;
                                                    <small style={{ fontSize: 14 }}>
                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q4OrdersProfit)})
                                                    </small>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>

                                    {/* </Card> */}
                                </Grid>
                            </Grid>
                        )}

                        {/* </Grid> */}
                        {/* //Stocking Cost Estimate */}
                        <Grid item xs={12}>
                            <Grid container spacing={2} justifyContent="space-between">
                                {/* //REGISTRATIONS */}
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Card className="otherCards">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={8}>
                                                <div
                                                    style={{
                                                        margin: '10px 10px',
                                                        textAlign: 'left',
                                                        fontSize: 20,
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    REGISTRATIONS
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1} style={{ padding: '0px 20px' }}>
                                            <Grid
                                                item
                                                xs={width > 380 ? 6 : 12}
                                                sm={6}
                                                onClick={() => handleRegistrationRedirect(`Q${moment().quarter()}`)}
                                                className="CursorHov"
                                            >
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> MTD</span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.mtdRegistrationsUnits)}
                                                        <small style={{ fontSize: 14 }}>
                                                            &nbsp;({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.mtdRegistrationsProfit)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={width > 380 ? 6 : 12}
                                                sm={6}
                                                onClick={() => handleRegistrationRedirect(`Q${moment().quarter()}`)}
                                                className="CursorHov"
                                            >
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> YTD </span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.ytdRegistrationsUnits)}&nbsp;
                                                        <small style={{ fontSize: 14 }}>
                                                            ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.ytdRegistrationsProfit)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={width > 380 ? 6 : 12} sm={6} onClick={() => handleRegistrationRedirect('Q1')} className="CursorHov">
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> QTR-Q1</span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.q1RegistrationsUnits)}&nbsp;
                                                        <small style={{ fontSize: 14 }}>
                                                            ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q1RegistrationsAmount)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={width > 380 ? 6 : 12} sm={6} onClick={() => handleRegistrationRedirect('Q2')} className="CursorHov">
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> QTR-Q2</span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.q2RegistrationsUnits)}&nbsp;
                                                        <small style={{ fontSize: 14 }}>
                                                            ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q2RegistrationsAmount)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={width > 380 ? 6 : 12} sm={6} onClick={() => handleRegistrationRedirect('Q3')} className="CursorHov">
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> QTR-Q3</span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.q3RegistrationsUnits)}&nbsp;
                                                        <small style={{ fontSize: 14 }}>
                                                            ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q3RegistrationsAmount)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={width > 380 ? 6 : 12} sm={6} onClick={() => handleRegistrationRedirect('Q4')} className="CursorHov">
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> QTR-Q4</span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.q4RegistrationsUnits)}&nbsp;
                                                        <small style={{ fontSize: 14 }}>
                                                            ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q4RegistrationsAmount)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                {/* //INVOICED */}
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Card className="otherCards">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={8}>
                                                <div
                                                    style={{
                                                        margin: '10px 10px',
                                                        textAlign: 'left',
                                                        fontSize: 20,
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    INVOICED
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1} style={{ padding: '0px 20px' }}>
                                            <Grid
                                                item
                                                xs={width > 380 ? 6 : 12}
                                                sm={6}
                                                onClick={() => handleInvoicedRedirect(`Q${moment().quarter()}`)}
                                                className="CursorHov"
                                            >
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> MTD</span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.mtdInvoicingUnits)}
                                                        &nbsp;
                                                        <small style={{ fontSize: 14 }}>
                                                            ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.mtdInvoicingProfit)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={width > 380 ? 6 : 12}
                                                sm={6}
                                                onClick={() => handleInvoicedRedirect(`Q${moment().quarter()}`)}
                                                className="CursorHov"
                                            >
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> YTD </span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.ytdInvoicingUnits)} &nbsp;
                                                        <small style={{ fontSize: 14 }}>
                                                            ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.ytdInvoicingProfit)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                            {/* *********
                                             ************
                                             *******************
                                             ******************
                                             ****************
                                             ********************
                                             *******************
                                             ***************
                                             ************* */}
                                            <Grid item xs={width > 380 ? 6 : 12} sm={6} onClick={() => handleInvoicedRedirect('Q1')} className="CursorHov">
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> QTR-Q1</span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.q1InvoicingUnits)}&nbsp;
                                                        <small style={{ fontSize: 14 }}>
                                                            ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q1InvoicingProfit)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={width > 380 ? 6 : 12} sm={6} onClick={() => handleInvoicedRedirect('Q2')} className="CursorHov">
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> QTR-Q2</span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.q2InvoicingUnits)}&nbsp;
                                                        <small style={{ fontSize: 14 }}>
                                                            ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q2InvoicingProfit)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={width > 380 ? 6 : 12} sm={6} onClick={() => handleInvoicedRedirect('Q3')} className="CursorHov">
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> QTR-Q3</span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.q3InvoicingUnits)}&nbsp;
                                                        <small style={{ fontSize: 14 }}>
                                                            ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q3InvoicingProfit)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={width > 380 ? 6 : 12} sm={6} onClick={() => handleInvoicedRedirect('Q4')} className="CursorHov">
                                                <div
                                                    style={{
                                                        // paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> QTR-Q4</span>
                                                    <div className="valueFont">
                                                        {formatters.ThousandSeparatorWithoutZero(dashobj.q4InvoicingUnits)} &nbsp;
                                                        <small style={{ fontSize: 14 }}>
                                                            ({formatters.CurrencyThousandSeparatorWithoutZero(dashobj.q4InvoicingProfit)})
                                                        </small>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} lg={4}>
                                    <Card className="otherCards">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <div
                                                    style={{
                                                        margin: '10px 10px',
                                                        textAlign: 'left',
                                                        fontSize: 20,
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Forecast
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1} style={{ padding: '0px 20px' }}>
                                            <Grid item container xs={6} spacing={2}>
                                                <Grid item xs={12}>
                                                    <div
                                                        style={{
                                                            marginTop: 9,
                                                            textAlign: 'left',
                                                            fontSize: 20,
                                                            fontWeight: 500
                                                        }}
                                                    >
                                                        Registration
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div
                                                        style={{
                                                            // paddingRight: 20,
                                                            paddingBottom: 5,
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <span className="lableFont">{moment().format('YYYY')}</span>
                                                        <div className="valueFont">
                                                            {formatters.ThousandSeparatorWithoutZero(dashobj.forecastRegCompleteUnit)}
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div
                                                        style={{
                                                            // paddingRight: 20,
                                                            paddingBottom: 5,
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <span className="lableFont"> {+moment().format('YYYY') + 1}</span>
                                                        <div className="valueFont">
                                                            {formatters.ThousandSeparatorWithoutZero(dashobj.forecastRegFutureUnit)}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={6} spacing={2}>
                                                <Grid item xs={12}>
                                                    <div
                                                        style={{
                                                            marginTop: 9,
                                                            textAlign: 'left',
                                                            fontSize: 20,
                                                            fontWeight: 500
                                                        }}
                                                    >
                                                        Financial
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div
                                                        style={{
                                                            // paddingRight: 20,
                                                            paddingBottom: 5,
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <span className="lableFont"> {moment().format('YYYY')}</span>
                                                        <div className="valueFont">{formatters.ThousandSeparatorWithoutZero(dashobj.forecastfinancialMTD)}</div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div
                                                        style={{
                                                            // paddingRight: 20,
                                                            paddingBottom: 5,
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <span className="lableFont"> {+moment().format('YYYY') + 1}</span>
                                                        <div className="valueFont">{formatters.ThousandSeparatorWithoutZero(dashobj.forecastfinancialYtd)}</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Hidden only="xs">
                            <Grid item sm={12}>
                                <Card className="otherCards" style={{ padding: 0 }}>
                                    <Grid container spacing={1} style={{ padding: '10px 10px' }}>
                                        <TableContainer style={{ height: 430, maxHeight: 430 }}>
                                            <Table size="small" aria-label="a dense table" stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell colSpan={9} style={{ backgroundColor: 'gray', color: 'white' }}>
                                                            {' '}
                                                            <div
                                                                style={{
                                                                    margin: '5px 10px 5px 0px',
                                                                    textAlign: 'left',
                                                                    fontSize: 20,
                                                                    fontWeight: 500
                                                                }}
                                                            >
                                                                LEAGUE TABLE
                                                            </div>
                                                        </StyledTableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <StyledTableCell width={'11%'}>Name</StyledTableCell>
                                                        <StyledTableCell align="center" width={'11%'}>
                                                            MTD
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" width={'11%'}>
                                                            YTD
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" width={'11%'}>
                                                            Q1
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" width={'11%'}>
                                                            Q2
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" width={'11%'}>
                                                            Q3
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" width={'11%'}>
                                                            Q4
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" width={'11%'}>
                                                            Points
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {state.league.map((k) => {
                                                        return (
                                                            <TableRow onClick={() => HandelLeagueClick(k)} className="CursorHov" hover>
                                                                <StyledTableCell>{k.name}</StyledTableCell>
                                                                <StyledTableCell align="center" className="valueFont">
                                                                    <div className="valueFont"></div>
                                                                    {formatters.ThousandSeparatorWithoutZero(k.mtdunit)}&nbsp;
                                                                    <small style={{ fontSize: 14 }}>
                                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(k.mtdProfit)})
                                                                    </small>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" className="valueFont">
                                                                    {formatters.ThousandSeparatorWithoutZero(k.ytdunit)}&nbsp;
                                                                    <small style={{ fontSize: 14 }}>
                                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(k.ytdProfit)})
                                                                    </small>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" className="valueFont">
                                                                    {formatters.ThousandSeparatorWithoutZero(k.q1Unit)}&nbsp;
                                                                    <small style={{ fontSize: 14 }}>
                                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(k.q1Profit)})
                                                                    </small>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" className="valueFont">
                                                                    {formatters.ThousandSeparatorWithoutZero(k.q2Unit)}&nbsp;
                                                                    <small style={{ fontSize: 14 }}>
                                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(k.q2Profit)})
                                                                    </small>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" className="valueFont">
                                                                    {formatters.ThousandSeparatorWithoutZero(k.q3Unit)}&nbsp;
                                                                    <small style={{ fontSize: 14 }}>
                                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(k.q3Profit)})
                                                                    </small>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" className="valueFont">
                                                                    {formatters.ThousandSeparatorWithoutZero(k.q4Unit)}&nbsp;
                                                                    <small style={{ fontSize: 14 }}>
                                                                        ({formatters.CurrencyThousandSeparatorWithoutZero(k.q4Profit)})
                                                                    </small>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center" className="valueFont">
                                                                    {k.p}
                                                                </StyledTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Hidden>
                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                            <Grid item sm={12} style={{ width: '100%' }}>
                                <div
                                    style={{
                                        margin: '5px 10px 0px 10px',
                                        textAlign: 'left',
                                        fontSize: 20,
                                        fontWeight: 500
                                    }}
                                >
                                    LEAGUE TABLE
                                </div>
                                {state.league.map((k) => (
                                    <SimpleAccordion label={k.name} rest={{ background: 'grey', margin: 5, color: 'white' }}>
                                        <Grid
                                            container
                                            spacing={1}
                                            justifyContent="space-around"
                                            style={{ fontSize: 14, cursor: 'pointer' }}
                                            onClick={() => HandelLeagueClick()}
                                        >
                                            <Grid item xs={6}>
                                                MTD&nbsp;:&nbsp;
                                                <b>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(+k.mtdProfit)} / {k.mtdunit}
                                                </b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                YTD&nbsp;:&nbsp;
                                                <b>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(+k.ytdProfit)} / {k.ytdunit}
                                                </b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                Q1&nbsp;:&nbsp;
                                                <b>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(+k.q1Profit)} / {k.q1Unit}
                                                </b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                Q2&nbsp;:&nbsp;
                                                <b>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(+k.q2Profit)} / {k.q2Unit}
                                                </b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                Q3&nbsp;:&nbsp;
                                                <b>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(k.q3Profit)} / {k.q3Unit}
                                                </b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                Q4&nbsp;:&nbsp;
                                                <b>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(k.q4Profit)} / {k.q4Unit}
                                                </b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                Points&nbsp;:&nbsp;
                                                <b>{k.p}</b>
                                            </Grid>
                                        </Grid>
                                        {/* <div style={{ textAlign: 'left', fontSize: 13 }}>
                                        <div>
                                            MTD&nbsp;:&nbsp;&nbsp;
                                            <b>
                                                {formatters.CurrencyThousandSeparatorWithoutZero(+k.mtdProfit)} / {k.mtdunit}
                                            </b>
                                        </div>
                                        <div>
                                            YTD&nbsp;:&nbsp;&nbsp;
                                            <b>
                                                {formatters.CurrencyThousandSeparatorWithoutZero(+k.ytdProfit)} / {k.ytdunit}
                                            </b>
                                        </div>
                                        <div>
                                            Q1&nbsp;:&nbsp;&nbsp;
                                            <b>
                                                {formatters.CurrencyThousandSeparatorWithoutZero(+k.q1Profit)} / {k.q1Unit}
                                            </b>
                                        </div>
                                        <div>
                                            Q2&nbsp;:&nbsp;&nbsp;
                                            <b>
                                                {formatters.CurrencyThousandSeparatorWithoutZero(+k.q2Profit)} / {k.q2Unit}
                                            </b>
                                        </div>
                                        <div>
                                            Q3&nbsp;:&nbsp;&nbsp;
                                            <b>
                                                {formatters.CurrencyThousandSeparatorWithoutZero(k.q3Profit)} / {k.q3Unit}
                                            </b>
                                        </div>
                                        <div>
                                            Q4&nbsp;:&nbsp;&nbsp;
                                            <b>
                                                {formatters.CurrencyThousandSeparatorWithoutZero(k.q4Profit)} / {k.q4Unit}
                                            </b>
                                        </div>
                                        <div>
                                            Points&nbsp;:&nbsp;&nbsp;
                                            <b>{k.p}</b>
                                        </div>
                                    </div> */}
                                    </SimpleAccordion>
                                ))}
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default SalesDashboardSettingComp;
