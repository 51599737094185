import { get, post } from './http-calls';

export let getInvoicePdf = async (InvoiceFileName) => {
    let data = await get(`Invoices/DownloadInvoice?id=${InvoiceFileName}`, { useAuthToken: true, responseType: 'blob' });
    return data;
};

export let getInvoicesByID = async (id) => {
    let data = await get(`Invoices/${id}`, { useAuthToken: true });
    return data;
};

export let invoiceScreenDropDown = async (id) => {
    let data = await get(`Invoices/ScreenDropDown`, { useAuthToken: true });
    return data;
};

export let getMessageGridList = async (id) => {
    let data = await get(`Invoices/GetInvoiceMessageList?id=${id}`, { useAuthToken: true });
    return data;
};

export const postInvoiceMessage = async (data) => {
    let res = await post(`Invoices/SaveInvoiceMessages`, data, { useAuthToken: true });
    return res;
};

export let getsendInvoice = async (list) => {
    let data = await get(`Invoices/SendInvoices?Invoices=${list}`, { useAuthToken: true });
    return data;
};

export const postMulitpleInvoices = async (data) => {
    let res = await post(`Invoices/SendMulitpleInvoices`, data, { useAuthToken: true });
    return res;
};

export const postUpdateInvoice = async (data) => {
    let res = await post(`Invoices/Update`, data, { useAuthToken: true });
    return res;
};
