import React from 'react';
import { Button, Collapse, FormLabel, Grid, Hidden, Typography } from '@material-ui/core';
import { BudgetCard, BudgetCardTwo, BudgetCardThree, BudgetSubmeter } from './budgetCard';
import './budget.scss';
import ReactSpeedometer from 'react-d3-speedometer';
import { Multiselect, TextBox, DatePicker } from '../../../Core/FormInput';
import { useState } from 'react';
import moment from 'moment';
import { useEffect } from 'react';
import { getReportsDropdowns, getRportDashByID } from '../../../Core/Service/reportService';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useHistory } from 'react-router-dom';
const BudgetScreen = () => {
    let startDate = moment().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        branchList: [],
        franchise: [],
        unitsList: {},
        branchBudgets: {},
        groupIds: [],
        franchiseIds: []
    });

    const [temp, tempOption] = useState();
    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    useEffect(async () => {
        let DropDownRes = await getReportsDropdowns();
        if (DropDownRes.success) {
            setState((st) => ({
                ...st,
                branchList: DropDownRes.data.branch,
                franchise: DropDownRes.data.franchiseCode
            }));
        }
    }, []);

    useEffect(async () => {
        let res = await getRportDashByID(state.startDate, state.endDate, state.franchiseIds, state.groupIds);
        setState((st) => ({
            ...st,
            unitsList: res?.data?.units[0],
            branchBudgets: res?.data?.overAllTotal[0]
        }));
    }, [state.startDate, state.endDate, state.franchiseIds, state.groupIds]);

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const handleChanges = (event, value) => {
        tempOption((st) => ({ ...st, groupVal: value }));
        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handleChangesfranch = (event, value) => {
        tempOption((st) => ({ ...st, franchiseVal: value }));
        setState((st) => {
            const nst = { ...st, franchiseIds: value.map((c) => c.id) };
            return nst;
        });
    };
    const history = useHistory();

    const GoToEnquiry = (val) => {
        let searchQuery = `&type=${val || ''}&start=${state.startDate}&end=${state.endDate}`;
        history.push({
            pathname: '/AgedMeasures/EnquirieList',
            search: searchQuery,
            state: { fnCode: state.franchiseIds, bn: state.groupIds }
        });
    };

    const GoToSales = (val) => {
        let searchQuery = `&start=${state.startDate}&end=${state.endDate}`;
        history.push({
            pathname: `/sales/${val}`,
            search: searchQuery
        });
    };

    return (
        <div className="budgetScreen">
            <Grid container spacing={2}>
                <Hidden only={['lg', 'md', 'xl']}>
                    <Grid item xs={12}>
                        <div style={{ float: 'right' }}>
                            <Button onClick={handleChange} variant="contained" color="secondary">
                                <FilterListIcon />
                                &nbsp; Filters
                            </Button>
                        </div>
                    </Grid>
                    <Collapse in={checked}>
                        <Grid item container spacing={2} xs={12} style={{ textAlign: 'left' }}>
                            <Grid item xs={12}>
                                <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 2 }}>
                                    Group
                                </FormLabel>
                                <Multiselect options={state.branchList || []} onChange={handleChanges} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 2 }}>
                                    Franchise
                                </FormLabel>
                                <Multiselect options={state.franchise || []} onChange={handleChangesfranch} style={{ height: 49 }} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                    Start Date
                                </FormLabel>
                                <DatePicker placeholder="Start Date" name="startDate" fullWidth value={state.startDate} onChange={handleinput} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                    End Date
                                </FormLabel>
                                <DatePicker placeholder="End Date" name="endDate" fullWidth value={state.endDate} onChange={handleinput} />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Hidden>
                <Hidden only={['sm', 'xs']}>
                    <Grid item container spacing={1} xs={12} style={{ textAlign: 'left' }}>
                        <Grid item xs={6} md={3} lg={4}>
                            <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 2 }}>
                                Group
                            </FormLabel>
                            <Multiselect options={state.branchList || []} onChange={handleChanges} />
                        </Grid>
                        <Grid item xs={6} md={3} lg={4}>
                            <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 2 }}>
                                Franchise
                            </FormLabel>
                            <Multiselect options={state.franchise || []} onChange={handleChangesfranch} style={{ height: 49 }} />
                        </Grid>
                        <Grid item xs={4} md={3} lg={2}>
                            <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                Start Date
                            </FormLabel>
                            <DatePicker placeholder="Start Date" name="startDate" fullWidth value={state.startDate} onChange={handleinput} />
                        </Grid>
                        <Grid item xs={4} md={3} lg={2}>
                            <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                End Date
                            </FormLabel>
                            <DatePicker placeholder="End Date" name="endDate" fullWidth value={state.endDate} onChange={handleinput} />
                        </Grid>
                    </Grid>
                </Hidden>

                <Grid item container spacing={2} xs={12} md={12} lg={6} xl={5}>
                    <Grid item xs={12} sm={6} md={4} lg={6} xl={6} className="bgrid">
                        <div onClick={() => GoToEnquiry('new')} style={{ cursor: 'pointer' }}>
                            <BudgetCard
                                count={state?.unitsList?.newUnitSales}
                                label={'NEW UNIT SALES'}
                                icon_1={state?.unitsList?.newUnitDailyRunRateIcon}
                                subCount1={state?.unitsList?.newUnitDailyRunRate}
                                icon_2={state?.unitsList?.newUnitDailyBudgetIcon}
                                sublabel1={'DAILY RUN RATE'}
                                subCount2={state?.unitsList?.newUnitDailyBudget}
                                sublabel2={'BUDGET'}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                        <div onClick={() => GoToEnquiry('used')} style={{ cursor: 'pointer' }}>
                            <BudgetCard
                                count={state?.unitsList?.usedUnitSales}
                                label={'USED UNIT SALES'}
                                icon_1={state?.unitsList?.usedUnitDailyRunRateIcon}
                                subCount1={state?.unitsList?.usedUnitDailyRunRate}
                                icon_2={state?.unitsList?.usedUnitDailyBudgetIcon}
                                sublabel1={'DAILY RUN RATE'}
                                subCount2={state?.unitsList?.usedUnitDailyBudget}
                                sublabel2={'BUDGET'}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={6} xl={6} className="bgrid">
                        <div onClick={() => GoToSales('NewVehicleProfitReport')} style={{ cursor: 'pointer' }}>
                            <BudgetCardTwo
                                icon={state?.unitsList?.newInvoicedDailyBudgetIcon}
                                count={state?.unitsList?.newInvoicedSales}
                                label={'NEW INVOICE'}
                                subCount={state?.unitsList?.newInvoicedDailyBudget}
                                sublabel={'Budget'}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                        <div onClick={() => GoToSales('UsedVehicleProfitReport')} style={{ cursor: 'pointer' }}>
                            <BudgetCardTwo
                                count={state?.unitsList?.usedInvoiceSales}
                                icon={state?.unitsList?.usedUnitDailyBudgetIcon}
                                label={'USED INVOICED'}
                                subCount={state?.unitsList?.usedUnitDailyBudget}
                                sublabel={'Budget'}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={6} xl={6} className="bgrid">
                        <div onClick={() => GoToEnquiry('new')} style={{ cursor: 'pointer' }}>
                            <BudgetCardThree
                                val={state?.unitsList?.totalRetailLabourTotalLabour}
                                icon_1={state?.unitsList?.totalRetailLabourRunRateIcon}
                                icon_2={state?.unitsList?.totalRetailLabourBudgetIcon}
                                label={'TOTAL RETAIL LABOUR'}
                                count_1={state?.unitsList?.totalRetailLabourRunRate}
                                count_2={state?.unitsList?.totalRetailLabourBudget}
                                label_1={'DAILY RUN RATE'}
                                label_2={'BUDGET'}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                        <div onClick={() => GoToEnquiry('new')} style={{ cursor: 'pointer' }}>
                            <BudgetCardThree
                                val={state?.unitsList?.totalLabourTotalLabour}
                                icon_1={state?.unitsList?.totalLabourRunRateIcon}
                                icon_2={state?.unitsList?.totalLabourBudgetIcon}
                                label={'TOTAL LABOUR'}
                                count_1={state?.unitsList?.totalLabourRunRate}
                                count_2={state?.unitsList?.totalLabourBudget}
                                label_1={'DAILY RUN RATE'}
                                label_2={'BUDGET'}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12} md={12} lg={6} xl={7}>
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <div className="meter_budgetCard">
                            <Hidden only={['lg', 'md', 'xl']}>
                                <ReactSpeedometer
                                    maxValue={100}
                                    width={230}
                                    needleHeightRatio={0.7}
                                    value={state?.branchBudgets?.overAllTotal}
                                    needleColor="black"
                                    ringWidth={20}
                                    segments={4}
                                    height={150}
                                    segmentColors={['#ff0000', '#ff0000', '#FFBF00', '#00FF00']}
                                    currentValueText={`${state?.branchBudgets?.overAllTotal}% Overall Total`}
                                />
                            </Hidden>
                            <Hidden only={['sm', 'xs']}>
                                <ReactSpeedometer
                                    maxValue={100}
                                    width={360}
                                    needleHeightRatio={0.6}
                                    value={state?.branchBudgets?.overAllTotal}
                                    needleColor="black"
                                    ringWidth={35}
                                    segments={4}
                                    height={220}
                                    segmentColors={['#ff0000', '#ff0000', '#FFBF00', '#00FF00']}
                                    currentValueText={`${state?.branchBudgets?.overAllTotal}% Overall Total`}
                                />
                            </Hidden>

                            <Grid container spacing={3} style={{ padding: 15 }}>
                                <Grid item xs={6} style={{ borderRight: '2px solid #e6e6e6' }}>
                                    <div className="bheadingOne" style={{ fontSize: 20, padding: '8px 5px 7px' }}>
                                        Profit
                                    </div>
                                    <div className="bheadingOne" style={{ fontSize: 17, padding: 5 }}>
                                        {state?.branchBudgets?.overAllProfit ? (
                                            <>£{state?.branchBudgets?.overAllProfit.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</>
                                        ) : (
                                            <>£0.00</>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="bheadingOne" style={{ fontSize: 20, padding: '8px 5px 7px' }}>
                                        Budget
                                    </div>
                                    <div className="bheadingOne" style={{ fontSize: 17, padding: 5 }}>
                                        {state?.branchBudgets?.overAllBudget ? (
                                            <>£{state?.branchBudgets?.overAllBudget.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</>
                                        ) : (
                                            <>£0.00</>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} xl={4}>
                        <BudgetSubmeter
                            title={'NEW CARS'}
                            val={state?.branchBudgets?.newCar}
                            subVal_1={state?.branchBudgets?.newCarProfit}
                            subVal_2={state?.branchBudgets?.newCarBudget}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} xl={4}>
                        <BudgetSubmeter
                            title={'USED CARS'}
                            val={state?.branchBudgets?.usedCar}
                            subVal_1={state?.branchBudgets?.usedCarProfit}
                            subVal_2={state?.branchBudgets?.usedCarBudget}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} xl={4}>
                        <BudgetSubmeter
                            title={'AFTERSALES'}
                            val={state?.branchBudgets?.afterSales}
                            subVal_1={state?.branchBudgets?.afterSalesProfit}
                            subVal_2={state?.branchBudgets?.afterSalesBudget}
                        />
                    </Grid>
                </Grid>
                {/* </div> */}
            </Grid>
        </div>
    );
};

export default BudgetScreen;
