import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import './part.scss';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#A0E8F4',
        color: theme.palette.common.black,
        padding: 0,
        fontSize: 12,
        border: '1px solid #1BB4C8',
        fontWeight: 700
    },
    body: {
        color: theme.palette.common.black,
        padding: 0,
        fontSize: 14,
        border: '1px solid #1BB4C8'
    }
}))(TableCell);

const StyledTableEmptyCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        padding: 0,
        border: 'none'
    },
    body: {
        fontSize: 14,
        padding: 0,
        border: '1px solid #1BB4C8'
        // border: 'none'
    }
}))(TableCell);

const EmptyCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        padding: 0,
        border: 'none'
    },
    body: {
        fontSize: 12,
        padding: 0,
        // border: '1px solid #1BB4C8'
        border: 'none'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: '#A0E8F4'
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        width: '100%'
    }
});

const MonthSummery = () => {
    const classes = useStyles();

    return (
        <div style={{ padding: 10 }}>
            <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <EmptyCell width="8%"></EmptyCell>
                            <EmptyCell width="8%"></EmptyCell>
                            <StyledTableCell align="center" width="15%">
                                Budget
                            </StyledTableCell>
                            <EmptyCell width={12}></EmptyCell>
                            <StyledTableCell align="center" width="10%">
                                Actuals
                            </StyledTableCell>
                            <EmptyCell width={12}></EmptyCell>
                            <StyledTableCell align="center" width="10%">
                                MTD VARIANCE
                            </StyledTableCell>
                            <EmptyCell width={12}></EmptyCell>
                            <StyledTableCell align="center" width="25%">
                                RUN RATE PROJECTION
                            </StyledTableCell>
                            <EmptyCell width={12}></EmptyCell>
                            <StyledTableCell align="center" width="20%">
                                BALANCE TO HIT BUDGET
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <EmptyCell></EmptyCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={4} style={{ borderRight: '1px solid #1BB4C8', padding: 10 }}>
                                        Total month
                                    </Grid>
                                    <Grid item xs={4} style={{ borderRight: '1px solid #1BB4C8', padding: 10 }}>
                                        Month to date 5 days
                                    </Grid>
                                    <Grid item xs={4} style={{ padding: 10 }}>
                                        Daily rate
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    {/* <Grid item xs={6}>Total month</Grid> */}
                                    <Grid item xs={6} style={{ borderRight: '1px solid #1BB4C8', padding: 10 }}>
                                        Month to date 5 days
                                    </Grid>
                                    <Grid item xs={6} style={{ padding: 10 }}>
                                        Daily rate
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    {/* <Grid item xs={6}>Total month</Grid> */}
                                    <Grid item xs={6} style={{ borderRight: '1px solid #1BB4C8', padding: 10 }}>
                                        Month to date 5 days
                                    </Grid>
                                    <Grid item xs={6} style={{ padding: 10 }}>
                                        Month to date 5 days
                                    </Grid>
                                    {/* <Grid item xs={6}>Daily rate</Grid> */}
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 10 }}>
                                        Full month at run rate
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 10 }}>
                                        Full month budget
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 10 }}>
                                        Full month last year
                                    </Grid>
                                    <Grid item xs={3} style={{ padding: 10 }}>
                                        Variance to budget
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                {' '}
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 10 }}>
                                        Based on MTD Actual
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 10 }}>
                                        Daily rate required
                                    </Grid>
                                    <Grid item xs={6} style={{ padding: 10, color: 'red' }}>
                                        Run rate improvement required
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <EmptyCell></EmptyCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={4} style={{ borderRight: '1px solid #1BB4C8' }}>
                                        £
                                    </Grid>
                                    <Grid item xs={4} style={{ borderRight: '1px solid #1BB4C8' }}>
                                        £
                                    </Grid>
                                    <Grid item xs={4}>
                                        £
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    {/* <Grid item xs={6}>Total month</Grid> */}
                                    <Grid item xs={6} style={{ borderRight: '1px solid #1BB4C8' }}>
                                        £
                                    </Grid>
                                    <Grid item xs={6}>
                                        £
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    {/* <Grid item xs={6}>Total month</Grid> */}
                                    <Grid item xs={6} style={{ borderRight: '1px solid #1BB4C8' }}>
                                        £
                                    </Grid>
                                    <Grid item xs={6}>
                                        %
                                    </Grid>
                                    {/* <Grid item xs={6}>Daily rate</Grid> */}
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8' }}>
                                        £
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8' }}>
                                        £
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8' }}>
                                        £
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                {' '}
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8' }}>
                                        £
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8' }}>
                                        £
                                    </Grid>
                                    <Grid item xs={6} style={{ padding: 0, color: 'red' }}>
                                        %
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <StyledTableEmptyCell align="center">
                                <b>Knowsley Van</b>
                            </StyledTableEmptyCell>
                            <StyledTableEmptyCell align="center">Workshop</StyledTableEmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={4} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        145,320
                                    </Grid>
                                    <Grid item xs={4} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        36,330
                                    </Grid>
                                    <Grid item xs={4} style={{ padding: 8 }}>
                                        7,266
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    {/* <Grid item xs={6}>Total month</Grid> */}
                                    <Grid item xs={6} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        35,962
                                    </Grid>
                                    <Grid item xs={6} style={{ padding: 8 }}>
                                        7,192
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    {/* <Grid item xs={6}>Total month</Grid> */}
                                    <Grid item xs={6} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        (368)
                                    </Grid>
                                    <Grid item xs={6} style={{ padding: 8 }}>
                                        -1.0%
                                    </Grid>
                                    {/* <Grid item xs={6}>Daily rate</Grid> */}
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        143,850
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        145,320
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        176,567
                                    </Grid>
                                    <Grid item xs={3} style={{ padding: 8 }}>
                                        (1,470)
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                {' '}
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        109,358
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        7,291
                                    </Grid>
                                    <Grid item xs={6} style={{ color: 'red', padding: 8 }}>
                                        1.4%
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <EmptyCell></EmptyCell>
                            <StyledTableEmptyCell align="center">Counter</StyledTableEmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={4} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        145,320
                                    </Grid>
                                    <Grid item xs={4} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        36,330
                                    </Grid>
                                    <Grid item xs={4} style={{ padding: 8 }}>
                                        7,266
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    {/* <Grid item xs={6}>Total month</Grid> */}
                                    <Grid item xs={6} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        35,962
                                    </Grid>
                                    <Grid item xs={6} style={{ padding: 8 }}>
                                        7,192
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    {/* <Grid item xs={6}>Total month</Grid> */}
                                    <Grid item xs={6} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        (368)
                                    </Grid>
                                    <Grid item xs={6} style={{ padding: 8 }}>
                                        -1.0%
                                    </Grid>
                                    {/* <Grid item xs={6}>Daily rate</Grid> */}
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        143,850
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        145,320
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        176,567
                                    </Grid>
                                    <Grid item xs={3} style={{ padding: 8 }}>
                                        (1,470)
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                {' '}
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        109,358
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        7,291
                                    </Grid>
                                    <Grid item xs={6} style={{ color: 'red', padding: 8 }}>
                                        1.4%
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <EmptyCell></EmptyCell>
                            <StyledTableEmptyCell align="center">Total</StyledTableEmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={4} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        145,320
                                    </Grid>
                                    <Grid item xs={4} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        36,330
                                    </Grid>
                                    <Grid item xs={4} style={{ padding: 8 }}>
                                        7,266
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    {/* <Grid item xs={6}>Total month</Grid> */}
                                    <Grid item xs={6} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        35,962
                                    </Grid>
                                    <Grid item xs={6} style={{ padding: 8 }}>
                                        7,192
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    {/* <Grid item xs={6}>Total month</Grid> */}
                                    <Grid item xs={6} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        (368)
                                    </Grid>
                                    <Grid item xs={6} style={{ padding: 8 }}>
                                        -1.0%
                                    </Grid>
                                    {/* <Grid item xs={6}>Daily rate</Grid> */}
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        143,850
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        145,320
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        176,567
                                    </Grid>
                                    <Grid item xs={3} style={{ padding: 8 }}>
                                        (1,470)
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <EmptyCell></EmptyCell>
                            <StyledTableCell align="center">
                                {' '}
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        109,358
                                    </Grid>
                                    <Grid item xs={3} style={{ borderRight: '1px solid #1BB4C8', padding: 8 }}>
                                        7,291
                                    </Grid>
                                    <Grid item xs={6} style={{ color: 'red', padding: 8 }}>
                                        1.4%
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                        </TableRow>
                        <StyledTableRow>
                            <EmptyCell align="center"></EmptyCell>
                            <EmptyCell align="center"></EmptyCell>
                            <EmptyCell align="center"></EmptyCell>
                            <EmptyCell></EmptyCell>
                            <EmptyCell align="center"></EmptyCell>
                            <EmptyCell></EmptyCell>
                            <EmptyCell align="center"></EmptyCell>
                            <EmptyCell></EmptyCell>
                            <EmptyCell align="center"></EmptyCell>
                            <EmptyCell></EmptyCell>
                            <EmptyCell align="center"></EmptyCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default MonthSummery;
