import { get, post } from './http-calls';

export const saveDocumentStamps = async (jobId, docId, docName, stamps) => {
    let res = await post(
        `Jobdocuments/SetDocumentStampsJSON`,
        {
            headerRecordId: jobId,
            ocrDocumentId: docId,
            documentName: docName,
            documentStampsJSON: stamps
        },
        {
            useAuthToken: true
        }
    );
    return res;
};

export const saveNewStockJobDocumentsStamps = async (stockId, docId, docName, stamps) => {
    let res = await post(
        `NewStockJobDocuments/SetDocumentStampsJSON`,
        {
            // headerRecordId: jobId,
            ocrDocumentId: docId,
            documentName: docName,
            documentStampsJSON: stamps,
            stockId: stockId
        },
        {
            useAuthToken: true
        }
    );
    return res;
};
export const saveUsedStockJobDocumentsStamps = async (stockId, docId, docName, stamps) => {
    let res = await post(
        `UsedStockJobDocuments/SetDocumentStampsJSON`,
        {
            // headerRecordId: jobId,
            ocrDocumentId: docId,
            documentName: docName,
            documentStampsJSON: stamps,
            stockId: stockId
        },
        {
            useAuthToken: true
        }
    );
    return res;
};

//this is new stamping logic, we send one canvas not all multiple stamps
export const saveDocumentStampsV2 = async (headerRecordId, docId, docName, canvasofPages) => {
    let res = await post(
        `Jobdocuments/v2/SetDocumentStampsJSON`,
        {
            // headerRecordId: jobId,
            ocrDocumentId: docId,
            documentName: docName,
            canvasofPages,
            headerRecordId: headerRecordId
        },
        {
            useAuthToken: true
        }
    );
    return res;
};
export const saveNewStockJobDocumentsStampsV2 = async (stockId, docId, docName, canvasofPages) => {
    let res = await post(
        `NewStockJobDocuments/v2/SetDocumentStampsJSON`,
        {
            stockId: stockId,
            ocrDocumentId: docId,
            documentName: docName,
            canvasofPages
        },
        {
            useAuthToken: true
        }
    );
    return res;
};

export const saveUsedStockJobDocumentsStampsV2 = async (stockId, docId, docName, canvasofPages) => {
    let res = await post(
        `UsedStockJobDocuments/v2/SetDocumentStampsJSON`,
        {
            stockId: stockId,
            ocrDocumentId: docId,
            documentName: docName,
            canvasofPages
        },
        {
            useAuthToken: true
        }
    );
    return res;
};

export const getDocumentStamps = async (jobId, docId, docName) => {
    let res = await get(`Jobdocuments/GetDocumentStampsJSON/${jobId}?ocrDocumentId=${docId}&documentName=${docName}`, {
        useAuthToken: true
    });
    return res;
};

export const getNewStockJobDocumentsStamps = async (StockID, docId, docName) => {
    let res = await get(`NewStockJobDocuments/GetDocumentStampsJSON/${StockID}?ocrDocumentId=${docId}&documentName=${docName}`, {
        useAuthToken: true
    });
    return res;
};

export const getUsedStockJobDocumentsStamps = async (StockID, docId, docName) => {
    let res = await get(`UsedStockJobDocuments/GetDocumentStampsJSON/${StockID}?ocrDocumentId=${docId}&documentName=${docName}`, {
        useAuthToken: true
    });
    return res;
};
