import React, { useEffect } from 'react';
import { Dialog, Grid, Typography, DialogTitle, DialogContent, Box, IconButton } from '@material-ui/core';
import { getReportingGetVehicleDetails } from '../../../../Core/Service/ReportScreens-service';
import { useState } from 'react';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import MotHistoryScreen from '../MotHistoryScreen';
import { SecondaryButton } from '../../../../Core/FormInput';
import formatters from '../../../../Core/Grid/inputFormatter';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

export const VehicleModalDetail = (props) => {
    const [state, setState] = useState({
        showMotHistory: false,
        details: {}
    });
    let data = props.selectedData;

    const getVehicleDataByVehicleNo = async () => {
        let res = await getReportingGetVehicleDetails(props.selectedData.vn);
        let data = res.data.list[0];
        if (res.success) {
            setState((st) => ({
                ...st,
                details: data || {}
            }));
        }
    };

    // const DialogTitle = withStyles(styles)((props) => {
    //     const { children, classes, onClose, ...other } = props;
    //     return (
    //         <MuiDialogTitle disableTypography className={classes.root} {...other}>
    //             <Typography variant="h6">{children}</Typography>
    //             {onClose ? (
    //                 <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
    //                     <CloseIcon />
    //                 </IconButton>
    //             ) : null}
    //         </MuiDialogTitle>
    //     );
    // });

    useEffect(() => {
        getVehicleDataByVehicleNo();
    }, [props.selectedData]);

    return (
        <Dialog onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.open} fullWidth maxWidth="lg">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitle>
                        {' '}
                        Vehicle Details &nbsp;&nbsp;
                        <SecondaryButton onClick={() => setState((st) => ({ ...st, showMotHistory: true }))}>MOT History</SecondaryButton>
                    </DialogTitle>
                </Box>
                <Box>
                    <IconButton onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            {/* <DialogContent dividers> */}
            <div style={{ padding: '0px 20px' }}>
                <DialogContent dividers>
                    <Grid container spacing={1} justifyContent="space-evenly">
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Reg No.
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.regNumber || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Vehicle Description
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.vehicleDescription || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Model
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.model || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Frenchise
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.franchiseName || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Driver Customer
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.driverCustomer || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Sales Customer
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.salesCustomer || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Customer
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.customer || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Telephone
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.telephoneNumber || '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogContent dividers>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="secondary" style={{ textAlign: 'left', fontWeight: 600 }}>
                                Important Dates
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Last Service
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.MonthShortFormatter(state.details.lastServiceDate)} @{' '}
                                {formatters.ThousandSeparatorWithoutZero(state.details.lastMileageReading)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Service Interval
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.serviceInterval || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                MOT Due Date
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.MonthShortFormatter(state.details.motDueDate)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Safety Check
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.MonthShortFormatter(state.details.nextSafetyCheck)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Next Service
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.MonthShortFormatter(state.details.nextServiceDate)} @{' '}
                                {formatters.ThousandSeparatorWithoutZero(state.details.nextServiceMileage)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Recalibration Interval
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.recalibNInterval || '-'}
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Next Tacho Service
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.MonthShortFormatter(state.details.nextTachoService)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Next Recalibration
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.MonthShortFormatter(state.details.nextRecalibration)}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogContent dividers>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="secondary" style={{ textAlign: 'left', fontWeight: 600 }}>
                                Warranty
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Warranty Expiry Date
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.MonthShortFormatter(state.details.warrantyExpiryDate)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Extended Warranty Date
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.MonthShortFormatter(state.details.extendedWarrDate)}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={3}>
                    <Typography variant="body2" style={{ textAlign: 'left' }}>
                         
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                        1234600
                    </Typography>
                </Grid> */}
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Date Warranty Invalid
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.MonthShortFormatter(state.details.dateWarrInvalid)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Warranty Status
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.warrantyStatus || '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogContent dividers>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="secondary" style={{ textAlign: 'left', fontWeight: 600 }}>
                                Spend
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Life Time Total
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.CurrencyThousandSeparator(state.details.lifeToDateValue)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Part Sale (LTD)
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.CurrencyThousandSeparator(state.details.partsSalesLTD)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Counter Parts (LTD)
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.CurrencyThousandSeparator(state.details.counterPartsLTD)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Labour Sales (LTD)
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.CurrencyThousandSeparator(state.details.labourSalesLTD)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Sublet Sales (LTD)
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.CurrencyThousandSeparator(state.details.subletSalesLTD)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                YTD Total
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.CurrencyThousandSeparator(state.details.ytdValue)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Part Sale (YTD)
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.CurrencyThousandSeparator(state.details.partsSalesYTD)}
                            </Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Counter Parts (YTD)
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.CurrencyThousandSeparator(state.details.counterPartsYTD)}
                            </Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Labour Sales (YTD)
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.CurrencyThousandSeparator(state.details.labourSalesYTD)}
                            </Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Sublet Sales (YTD)
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.CurrencyThousandSeparator(state.details.subletSalesYTD)}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogContent dividers>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="secondary" style={{ textAlign: 'left', fontWeight: 600 }}>
                                Maintenance Contract
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Maintenance Refernce
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {state.details.maintenanceRefernce || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Start Date
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.MonthShortFormatter(state.details.maintenanceStart)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                End Date
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.MonthShortFormatter(state.details.maintenanceEnd)}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={2}>
                        <Typography variant="body2" style={{ textAlign: 'left' }}>
                            Maintenance Length
                        </Typography>
                        <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                            {state.details.maintenanceLength||"-"}
                        </Typography>
                    </Grid> */}
                        {/* <Grid item xs={2}>
                        <Typography variant="body2" style={{ textAlign: 'left' }}>
                            Maintenance Code
                        </Typography>
                        <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                            {state.details.maintenanceCode||"-"}
                        </Typography>
                    </Grid> */}
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Start Mileage
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.ThousandSeparatorWithoutZero(state.details.maintStartMileage)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" style={{ textAlign: 'left' }}>
                                Maintenance Mileage
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 600, textAlign: 'left', fontSize: 17 }}>
                                {formatters.ThousandSeparatorWithoutZero(state.details.maintenanceMileage)}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>

                {state.showMotHistory && (
                    <DialogComp
                        title="MOT History"
                        onClose={() => setState((st) => ({ ...st, showMotHistory: false }))}
                        maxWidth="md"
                        fullWidth
                        overflow={'auto'}
                        height="480px"
                    >
                        <MotHistoryScreen regNo={data.reg} FranchiseCode={data.fr} hideSeachText={true} />
                    </DialogComp>
                )}
            </div>
        </Dialog>
    );
};
