import { get, post, put } from './http-calls';

export const addReportingCodes = async (id, description) => {
    let res = await post(
        `/api/ReportingCodes`,
        {
            ReportingCode: id,
            ReportCodeDescription: description,
            Mode: 'A'
        },
        { useAuthToken: true }
    );
    return res;
};

export const updateReportingCodes = async (id, description) => {
    let res = await post(
        `/api/ReportingCodes`,
        {
            ReportingCode: id,
            ReportCodeDescription: description,
            Mode: 'U'
        },
        { useAuthToken: true }
    );
    return res;
};

export const getReportingCodes = async () => {
    let res = await get('/api/ReportingCodes', { useAuthToken: true });
    return res;
};

export const getDepartmentCodes = async () => {
    let res = await get('/api/ReportingDepartmentCodes', { useAuthToken: true });
    return res;
};
export const getDepartmentCodeByCode = async (id) => {
    let res = await get(`/api/ReportingDepartmentCodes/${id}`, { useAuthToken: true });
    return res;
};
export const addDepartmentCode = async (data) => {
    let res = await post(`/api/ReportingDepartmentCodes`, data, { useAuthToken: true });
    return res;
};

export const updateDepartmentCode = async (data) => {
    let res = await post(`/api/ReportingDepartmentCodes`, data, { useAuthToken: true });
    return res;
};
