import React, { useContext, useState, useEffect } from 'react';
import { YesNoButton } from '../../../../Core/FormInput/index';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';

const RoleBasedColumns = (props) => {
    const { data, handleRoleColsClick } = props;
    const [state, setState] = useState({ data: {} });
    console.log(data, 'dattaaaaaaaaaaaaaaaa');
    console.log(state, 'state from role columns');

    useEffect(() => {
        setState(data);
    }, [data]);
    return (
        <>
            <Grid container spacing={1}>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Required?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.required}
                        name="required"
                        onYesClick={() => handleRoleColsClick('required', true, state.roleId)}
                        onNoClick={() => handleRoleColsClick('required', false, state.roleId)}
                    />
                </Grid>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Readonly?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.readonly}
                        name="readonly"
                        onYesClick={() => handleRoleColsClick('readonly', true, state.roleId)}
                        onNoClick={() => handleRoleColsClick('readonly', false, state.roleId)}
                    />
                </Grid>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Hidden?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.hidden}
                        name="hidden"
                        onYesClick={() => handleRoleColsClick('hidden', true, state.roleId)}
                        onNoClick={() => handleRoleColsClick('hidden', false, state.roleId)}
                    />
                </Grid>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Edit Required?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.editRequired}
                        name="editRequired"
                        onYesClick={() => handleRoleColsClick('editRequired', true, state.roleId)}
                        onNoClick={() => handleRoleColsClick('editRequired', false, state.roleId)}
                    />
                </Grid>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Edit Readonly?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.editReadonly}
                        name="editReadonly"
                        onYesClick={() => handleRoleColsClick('editReadonly', true, state.roleId)}
                        onNoClick={() => handleRoleColsClick('editReadonly', false, state.roleId)}
                    />
                </Grid>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Edit Hidden?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.editHidden}
                        name="editHidden"
                        onYesClick={() => handleRoleColsClick('editHidden', true, state.roleId)}
                        onNoClick={() => handleRoleColsClick('editHidden', false, state.roleId)}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default RoleBasedColumns;
