import React, { useState } from 'react';
import { Grid, InputLabel, FormHelperText } from '@material-ui/core';
import { TextBox } from '../../../Core/FormInput';
import { SecondaryButton } from './../../../Core/FormInput/AppButton';
import DataGridComp from '../../../Core/Grid';
import { useEffect } from 'react';
import { getMessageGridList, postSendMessage } from '../../../Core/Service/CustUserCommunicator/communicator-service';
import AppContext from '../../../App/AppContext';
import { useContext } from 'react';
import moment from 'moment';

const columns = [
    {
        field: 'ActionTime',
        headerName: 'Action By',
        width: 180,
        renderCell: (params) => (params.value ? moment(params.value).format('DD-MMM-YYYY') : '-')
    },
    {
        field: 'LogAction',
        headerName: 'Action Log',
        flex: 1
    }
];

const InvoiceMessage = (props) => {
    const { showToast } = useContext(AppContext);
    const [state, setState] = useState({
        error: {},
        messageList: [],
        list: []
    });

    useEffect(async () => {
        let res = await getMessageGridList(props.headerRecordID);
        let dataList = res.data?.list || [];
        let newList = [];
        if (dataList.length > 0) {
            newList = dataList.map((n, i) => {
                return { ...n, id: i };
            });
        }
        if (res.success) {
            setState((st) => ({
                ...st,
                messageList: newList
            }));
        }
    }, []);

    let Validation = () => {
        let isValid = true;
        let error = {};

        if (!state.logAction) {
            isValid = false;
            error.logAction = 'Message is required';
        }
        setState((st) => ({
            ...st,
            error: error
        }));
        return isValid;
    };

    const SubmitMessage = async () => {
        if (Validation()) {
            let data = {
                logAction: state.logAction,
                headerRecordID: props.headerRecordID
            };
            let res = await postSendMessage(data);
            if (res.success) {
                showToast(`Send Message Successfully`);
                props.onClose(true);
            } else {
                showToast(res.message);
            }
        }
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel shrink className="mandatoryfields">
                        New Message
                    </InputLabel>
                    <TextBox multiline rows={6} onChange={handleFieldChange} name="logAction" value={state.logAction} />
                    <FormHelperText error>{state.error.logAction}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton fullWidth onClick={SubmitMessage}>
                        Send Message
                    </SecondaryButton>
                </Grid>
                {/* <Grid item xs={12}>
                    <DataGridComp columns={columns} rows={state.messageList || []} headerHeight={40} rowHeight={45} offset={250} />
                </Grid> */}
                <Grid item xs={12}>
                    {state.messageList.length > 0 ? (
                        <div style={{ border: '1px solid #E8E8E8', maxHeight: 500, overflow: 'auto' }} className="custom-scroll">
                            {state.messageList.map((m) => (
                                <div style={{ borderBottom: '1px solid #E8E8E8', padding: 10, color: m.logHighPriority ? 'red' : 'black' }}>
                                    <div style={{ fontWeight: 500 }}>{m.logText} </div>

                                    <div style={{ paddingBottom: 4 }}>
                                        <span style={{ fontSize: 11 }}>
                                            {m.createdBy
                                                ? ` Created by :
                                            ${m.createdBy ? m.createdBy : ''} @
                                            ${m.logDateCreated ? moment(m.logDateCreated).format('LLL') : ''} `
                                                : ''}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div style={{ border: '1px solid #E8E8E8' }}>
                            <div style={{ textAlign: 'center', padding: 10 }}>No Messages Available</div>
                        </div>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default InvoiceMessage;
