import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions, Multiselect } from '../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import '../User Management/UserManagement.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { Autocomplete } from '@material-ui/lab';
import { DefaultButton } from '../../../Core/FormInput';
import { addVideoCategory, getRoles, getVideoCategory } from '../../../Core/Service/video-service';

const AddVideoCategoryModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        id: '',
        videoCategoryDescription: '',
        videoCategoryActive: true,
        roleIDs: [],
        errors: {},
        fields: {}
    });

    const [roleData, setRoleData] = useState({
        roles: []
    });

    const [selectedData, setSelectedData] = useState({
        roles: []
    });

    const AutoRoleChange = (event, value) => {
        setSelectedData((st) => ({ ...st, roles: value }));
        setState((st) => {
            const nst = { ...st, roleIDs: value.map((c) => c.id) };
            return nst;
        });
    };

    const VideoCategoryDetails = props.VideoCategoryDetails;

    useEffect(async () => {
        const VideoCategoryData = VideoCategoryDetails;

        let res = await getRoles();
        if (res.success) {
            const roles = res.data.roles.map((w) => ({
                id: w.id,
                name: w.name
            }));
            setRoleData((st) => ({ ...st, roles }));
        }
        if (VideoCategoryData) {
            setState((st) => ({
                ...st,
                id: VideoCategoryData.id,
                videoCategoryDescription: VideoCategoryData.videoCategoryDescription,
                videoCategoryActive: VideoCategoryData.videoCategoryActive
            }));
        }
    }, []);

    useEffect(() => {
        if (props.VideoCategoryDetails) {
            const rolesToAdd = [];
            props.VideoCategoryDetails.roles
                .replace(/ /g, '')
                .split(',')
                .map((role) => {
                    roleData.roles.map((r) => {
                        if (r.name.toLowerCase() == role) {
                            rolesToAdd.push(r.id);
                        }
                    });
                    setState((st) => ({
                        ...st,
                        roleIDs: rolesToAdd
                    }));
                });
        }
    }, [roleData.roles]);

    useEffect(() => {
        if (props.VideoCategoryDetails) {
            const a = state.roleIDs.map((i) => i);
            const selectedModule = roleData.roles?.filter((w) => state.roleIDs.indexOf(w.id) > -1);
            setSelectedData((st) => ({ ...st, roles: selectedModule }));
        }
    }, [roleData.roles, state.roleIDs]);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            const nst = { ...st, [name]: value, fields: fields };
            return nst;
        });
    };

    const handelSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            if (VideoCategoryDetails) {
                let res = await addVideoCategory(state.id, state.videoCategoryDescription, state.videoCategoryActive, state.roleIDs);
                if (!res.success) {
                    console.error(res);
                } else {
                    hideModal();
                    props.onFormSubmit(true);
                }
            } else {
                let res = await addVideoCategory(null, state.videoCategoryDescription, state.videoCategoryActive, state.roleIDs);
                if (!res.success) {
                    console.error(res);
                } else {
                    hideModal();
                    props.onFormSubmit(true);
                }
            }
        }
    };

    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.videoCategoryDescription) {
            formIsValid = false;
            errors['videoCategoryDescription'] = 'Description cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    {VideoCategoryDetails ? <DialogTitles>Update Video Category</DialogTitles> : <DialogTitles>Add Video Category</DialogTitles>}
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll" style={{ overflow: 'auto' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Description
                        </FormLabel>
                        <TextBox name="videoCategoryDescription" onChange={fieldChange} value={state.videoCategoryDescription} autoFocus={true} />
                        <span className="mandatoryfields">{state.errors['videoCategoryDescription']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Multiselect name="roles" options={roleData.roles} value={selectedData.roles} onChange={AutoRoleChange} />
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <FormLabel component="legend" className="btn_add">
                            Active?
                        </FormLabel>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                        <DefaultButton
                            className={state.videoCategoryActive ? 'btnActive' : 'btninActive'}
                            value={true}
                            name="navigateToDashboard"
                            onClick={() => handleClick('videoCategoryActive', true)}
                        >
                            Yes
                        </DefaultButton>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                        <DefaultButton
                            className={state.videoCategoryActive ? 'btninActive' : 'btnActiveNo'}
                            value={false}
                            name="videoCategoryActive"
                            onClick={() => handleClick('videoCategoryActive', false)}
                        >
                            No
                        </DefaultButton>
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddVideoCategoryModal;
