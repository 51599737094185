import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions } from '../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import '../User Management/UserManagement.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { addBatteryResults, updateBatteryResults } from '../../../Core/Service/BatteryResults-service';

const AddBatteryResultsModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        id: '',
        description: '',
        errors: {},
        fields: {}
    });

    const BatteryResultDetails = props.BatteryResultsDetails;

    useEffect(async () => {
        const BatteryResultData = BatteryResultDetails;
        if (BatteryResultDetails) {
            setState((st) => ({
                ...st,
                id: BatteryResultData.id,
                description: BatteryResultData.batteryTestResultName
            }));
        }
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            const nst = { ...st, [name]: value, fields: fields };
            return nst;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            if (BatteryResultDetails) {
                let res = await updateBatteryResults(state.id, state.description);
                if (!res.success) {
                    console.error(res);
                } else {
                    hideModal();
                    props.onFormSubmit(true);
                }
            } else {
                let res = await addBatteryResults(state.id, state.description);
                if (!res.success) {
                    console.error(res);
                } else {
                    hideModal();
                    props.onFormSubmit(true);
                }
                showToast(res.message);
            }
        }
    };

    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.description) {
            formIsValid = false;
            errors['description'] = 'Battery Result Name cannot be empty';
        }
        if (state.description.length > 50) {
            formIsValid = false;
            errors['description'] = 'description cannot be larger than 50 character';
        }
        if (!state.id) {
            formIsValid = false;
            errors['id'] = 'Battery Result Code cannot be empty';
        }
        if (state.id.length > 1) {
            formIsValid = false;
            errors['id'] = 'ID cannot be larger than one character';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    {BatteryResultDetails ? <DialogTitles>Update Battery Results</DialogTitles> : <DialogTitles>Add Battery Result</DialogTitles>}
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll" style={{ overflow: 'auto', height: '22vh', width: '56vh' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Battery Result Code
                        </FormLabel>
                        <TextBox
                            name="id"
                            onChange={fieldChange}
                            value={state.id}
                            disabled={BatteryResultDetails ? true : false}
                            autoFocus={BatteryResultDetails ? false : true}
                        />
                        <span className="mandatoryfields">{state.errors['id']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Battery Result Name
                        </FormLabel>
                        <TextBox name="description" onChange={fieldChange} value={state.description} autoFocus={BatteryResultDetails ? true : false} />
                        <span className="mandatoryfields">{state.errors['description']}</span>
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddBatteryResultsModal;
