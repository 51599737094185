import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MonthSummery from './monthSummery';
import { Grid, Typography } from '@material-ui/core';
import './part.scss';
import { RadianGraphPart } from './radianGraph';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        padding: 10,
        fontWeight: 700
    },
    body: {
        fontSize: 14,
        padding: 10
    }
}))(TableCell);

const DiffTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'lightGrey',
        color: theme.palette.common.black,
        fontWeight: 700,
        padding: 10
    },
    body: {
        backgroundColor: 'lightGrey',
        color: theme.palette.common.black,
        fontSize: 14,
        fontWeight: 700,
        padding: 10
    }
}))(TableCell);

const TotalTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'grey',
        color: theme.palette.common.black,
        fontWeight: 700,
        padding: 10
    },
    body: {
        fontWeight: 700,
        fontSize: 14,
        padding: 10
    }
}))(TableCell);

const StyledTableEmptyCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        padding: 10,
        border: 'none'
    },
    body: {
        fontSize: 14,
        padding: 10,
        border: 'none'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9)
];

const useStyles = makeStyles({
    table: {
        minWidth: 700
    }
});

const HeadLinesDashboard = () => {
    const [state, setState] = useState({ showSummeryScreen: false });
    const classes = useStyles();

    const showSummenry = () => {
        setState((st) => ({
            ...st,
            showSummeryScreen: true
        }));
    };
    return (
        <>
            {!state.showSummeryScreen ? (
                <div className="part_container">
                    <div className="part_mainSection">
                        <Grid container justifyContent="space-evenly" spacing={2}>
                            {/* <Grid item xs={6}>
                                <RadianGraphPart />
                            </Grid>
                            <Grid item xs={6}>
                                <RadianGraphPart />
                            </Grid> */}
                            <Grid item xs={12}>
                                <Typography variant="h6">Total Parts Sales Counter/Workshop </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">December 2022</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">
                                    Working Days : &nbsp; <b>05</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table className={classes.table} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell align="center">KVN</StyledTableCell>
                                                <StyledTableCell align="center">KNT</StyledTableCell>
                                                <StyledTableCell align="center">Dee</StyledTableCell>
                                                <StyledTableCell align="center">TRA</StyledTableCell>
                                                <StyledTableCell align="center">STO</StyledTableCell>
                                                <StyledTableEmptyCell align="center"></StyledTableEmptyCell>
                                                <TotalTableCell align="center">Total Group</TotalTableCell>
                                                <DiffTableCell align="center">Difference</DiffTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <StyledTableCell className="bgGreen">Parts sales Counter</StyledTableCell>
                                                <StyledTableCell className="bgGreen">Need</StyledTableCell>
                                                <StyledTableCell align="center" className="bgGreen" onClick={() => showSummenry()}>
                                                    £43,486
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgGreen">
                                                    £37,730
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgGreen">
                                                    £26,871
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgGreen">
                                                    £66,130
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgGreen">
                                                    £56,593
                                                </StyledTableCell>
                                                <StyledTableEmptyCell align="center"></StyledTableEmptyCell>
                                                <TotalTableCell align="center" className="bgGreen">
                                                    £230,820
                                                </TotalTableCell>
                                                <DiffTableCell align="center"></DiffTableCell>
                                            </TableRow>

                                            <TableRow>
                                                <StyledTableCell className="bgGreen">Parts sales Counter</StyledTableCell>
                                                <StyledTableCell className="bgGreen">Done</StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £42,825
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgGreen">
                                                    £95,194
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgGreen">
                                                    £31,742
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £45,054
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £42,820
                                                </StyledTableCell>
                                                <StyledTableEmptyCell align="center"></StyledTableEmptyCell>
                                                <TotalTableCell align="center" className="bgGreen">
                                                    £257,634
                                                </TotalTableCell>
                                                <DiffTableCell align="center">£26,824</DiffTableCell>
                                            </TableRow>

                                            <TableRow>
                                                <StyledTableCell className="bgBlue">Parts sales Workshop</StyledTableCell>
                                                <StyledTableCell className="bgBlue">Need</StyledTableCell>
                                                <StyledTableCell align="center" className="bgBlue">
                                                    £36,330
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgBlue">
                                                    £47,732
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgBlue">
                                                    £51,754
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgBlue">
                                                    £57,981
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgBlue">
                                                    £58,307
                                                </StyledTableCell>
                                                <StyledTableEmptyCell align="center"></StyledTableEmptyCell>
                                                <TotalTableCell align="center" className="bgBlue">
                                                    £252,104
                                                </TotalTableCell>
                                                <DiffTableCell align="center"></DiffTableCell>
                                            </TableRow>

                                            <TableRow>
                                                <StyledTableCell className="bgBlue">Parts sales Workshop</StyledTableCell>
                                                <StyledTableCell className="bgBlue">Done</StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £35,962
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £28,726
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £24,417
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £42,862
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £48,248
                                                </StyledTableCell>
                                                <StyledTableEmptyCell align="center"></StyledTableEmptyCell>
                                                <TotalTableCell align="center" className="bgBlue">
                                                    £180,216
                                                </TotalTableCell>
                                                <DiffTableCell align="center" style={{ color: 'red' }}>
                                                    -£71,887
                                                </DiffTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell className="bgYellow">Profit </StyledTableCell>
                                                <StyledTableCell className="bgYellow"> Need</StyledTableCell>
                                                <StyledTableCell align="center" className="bgYellow">
                                                    £15,000
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgYellow">
                                                    £17,093
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgYellow">
                                                    £15,003
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgYellow">
                                                    £23,023
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgYellow">
                                                    £23,023
                                                </StyledTableCell>
                                                <StyledTableEmptyCell align="center"></StyledTableEmptyCell>
                                                <TotalTableCell align="center" className="bgYellow">
                                                    £22,176
                                                </TotalTableCell>
                                                <DiffTableCell align="center" style={{ color: 'red' }}></DiffTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell className="bgYellow">Profit </StyledTableCell>
                                                <StyledTableCell className="bgYellow"> Done</StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £14,989
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgYellow">
                                                    £18,775
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £12,002
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £18,615
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgRed">
                                                    £18,558
                                                </StyledTableCell>
                                                <StyledTableEmptyCell align="center"></StyledTableEmptyCell>
                                                <TotalTableCell align="center" className="bgRed">
                                                    £82,938
                                                </TotalTableCell>
                                                <DiffTableCell align="center" style={{ color: 'red' }}>
                                                    -£9,356
                                                </DiffTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell className="bgYellow">Profit % </StyledTableCell>
                                                <StyledTableCell className="bgYellow"> </StyledTableCell>
                                                <StyledTableCell align="center" className="bgYellow">
                                                    19.02%
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgYellow">
                                                    15.15%
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgYellow">
                                                    21.37%
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgYellow">
                                                    21.17%
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="bgYellow">
                                                    20.38%
                                                </StyledTableCell>
                                                <StyledTableEmptyCell align="center"></StyledTableEmptyCell>
                                                <TotalTableCell align="center" className="bgYellow">
                                                    18.94%
                                                </TotalTableCell>
                                                <DiffTableCell align="center" style={{ color: 'red' }}></DiffTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ) : (
                <MonthSummery />
            )}
        </>
    );
};

export default HeadLinesDashboard;
