import _, { find } from 'lodash';
import React, { createContext, useEffect, useState } from 'react';
import {
    getDynamicFormDataByID,
    getDynamicFormFieldsByFormID,
    getOptionsByDynamicFormId,
    saveDynamicFormData
} from '../../../../Core/Service/dynamic-forms-service';
import { getDiagnosticSheetHeaderDetails } from '../../../../Core/Service/diagnosticSheets';
import AppContext from '../../../../App/AppContext';
import { useContext } from 'react';
import getMultiImgResized from '../../../../Core/CommonFunctions/multiImgResizer';

export const ScreenContext = createContext();

export const DynamicFormFieldProvider = (props) => {
    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({
        workFlowStepList: [],
        formFields: [],
        selectedOcrDocId: undefined,
        selectedOcrDocName: undefined,
        imagesFromUrl: [],
        showLoader: true,
        imageList: []
    });
    const [dynamicFormData, setDynamicFormData] = useState({
        currentChanges: {},
        fullMergedWorkflowData: {},
        tempMergedData: {},
        signData: {},
        dynamicFormDescription: '',
        workflowDocs: []
    });

    const [screenState, setScreenState] = useState({
        allFilled: false
    });
    useEffect(() => {
        pullDynamicFormData();
    }, [props.headerRecordId]);

    useEffect(async () => {
        let res = await getDynamicFormFieldsByFormID(props.dynamicFormID);
        if (res.success) {
            setState((st) => ({
                ...st,
                formFields: res.data.list,
                showLoader: false
            }));
        }
    }, []);

    useEffect(async () => {
        let result1 = await getOptionsByDynamicFormId(props.dynamicFormID);
        if (result1.success) {
            setState((st) => ({
                ...st,
                workFlowStepList: result1.data
            }));
        }
    }, []);

    useEffect(() => {
        let fulFormFilled = true;
        state.formFields
            .filter((s) => s.isRequired)
            .forEach((s) => {
                fulFormFilled = fulFormFilled && !!dynamicFormData.tempMergedData[s.jsonPropertyName];
                return fulFormFilled;
            });

        setScreenState((st) => ({
            ...st,
            allFilled: fulFormFilled
        }));
    }, [dynamicFormData.tempMergedData, state.formFields]);

    const pullDynamicFormData = async () => {
        let result = await getDiagnosticSheetHeaderDetails(props.headerRecordId);
        getDynamicFormDataByID(props.headerRecordId, props.dynamicFormID).then((res) => {
            if (res.success) {
                setDynamicFormData((st) => ({
                    ...st,
                    currentChanges: {},
                    fullMergedWorkflowData: res.data.dynamicFormData,
                    tempMergedData: {
                        ...res.data.dynamicFormData
                    },
                    signData: {
                        ...res.data.dynamicFormData
                    },
                    workflowDocs: res.data.documents,
                    dynamicFormDescription: res.data.dynamicFormDescription,
                    hasNotes: res.data.hasNotes,
                    hasSomethingToSave: false,
                    jobBasicDetail: result?.data?.headerDetails[0] || {}
                }));
            }
        });
    };

    const tryToSaveDynamicFormData = async (markComplete) => {
        let res = await saveDynamicFormData(props.headerRecordId, props.dynamicFormID, {
            done: markComplete,
            DynamicFormData: dynamicFormData.currentChanges,
            DynamicFormID: props.dynamicFormID
        });
        if (res.success) {
            pullDynamicFormData();
            props.closeFormModal();
        }
        showToast(res.message);
        return res;
    };

    const getListOptionById = (id) => {
        const list = state.workFlowStepList?.filter((l) => l.id === id);
        return list && list.length === 1 ? list[0].listOptions : [];
    };

    const dropDownValueSelected = (o) => {
        setDynamicFormData((dt) => {
            const newSt = {
                ...dt
            };
            newSt.currentChanges = {
                ...newSt.currentChanges,
                ...o
            };
            newSt.tempMergedData = {
                ...newSt.tempMergedData,
                ...o
            };
            newSt.hasSomethingToSave = !_(newSt.currentChanges)
                .omitBy((a, b) => (newSt.fullMergedWorkflowData[b] || '') === a)
                .isEmpty();
            return newSt;
        });
    };

    const updateState = (update) => {
        setState((st) => ({
            ...st,
            ...update
        }));
    };

    const getDocumentFromOcrDocId = (docId) => {
        return find(dynamicFormData.workflowDocs, {
            ocrDocId: docId
        });
    };

    const setDocumentWithOcrDocId = (ocrDocId, docName) => {
        const doc = getDocumentFromOcrDocId(ocrDocId);
        if (doc) {
            doc.docName = docName;
            setDynamicFormData((st) => ({
                ...st,
                workflowDocs: [...st.workflowDocs]
            }));
        } else {
            setDynamicFormData((st) => ({
                ...st,
                workflowDocs: [
                    ...st.workflowDocs,
                    {
                        ocrDocId,
                        docName
                    }
                ]
            }));
        }
    };
    const addDocumentImagesData = (ocrDocId, docName, imgBase64Src) => {
        setState((st) => {
            const imagesFromUrl = [...st.imagesFromUrl];
            imagesFromUrl.push(imgBase64Src);
            return {
                ...st,
                selectedOcrDocId: ocrDocId,
                selectedOcrDocName: docName,
                imagesFromUrl
            };
        });
    };

    const showMail = (docInfoToMail) => {
        setScreenState((st) => ({
            ...st,
            showMailModal: true,
            docInfoToMail
        }));
    };

    const hideMail = () => {
        setScreenState((st) => ({
            ...st,
            showMailModal: false
        }));
    };

    return (
        <ScreenContext.Provider
            value={{
                state,
                getListOptionById,
                dropDownValueSelected,
                dynamicFormData,
                screenState,
                tryToSaveDynamicFormData,
                setDocumentWithOcrDocId,
                updateState,
                addDocumentImagesData,
                getDocumentFromOcrDocId,
                showMail,
                hideMail
            }}
        >
            {props.children}
        </ScreenContext.Provider>
    );
};
