import {get, post, put } from './http-calls';

export const addBatteryManufacturers = async(id, name) => {
    let res = await post(`/api/BatteryManufacturers`, {
        BatteryManufacturerID: id,
        BatteryManufacturerName: name,
        Mode: "A",
    }, { useAuthToken: true });
    return res;
}

export const updateBatteryManufacturers = async(id, name) => {
    let res = await post(`/api/BatteryManufacturers`, {
        BatteryManufacturerID: id,
        BatteryManufacturerName: name,
        Mode: "U",
    }, { useAuthToken: true });
    return res;
};

export const getBatteryManufacturers = async() => {
    let res = await get('/api/BatteryManufacturers', { useAuthToken: true });
    return res;
}