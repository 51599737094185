import { Checkbox, FormControlLabel, FormLabel, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import AppContext from '../../../../App/AppContext';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { SecondaryButton, SelectBox, TextBox } from '../../../../Core/FormInput';
import { getBranchParametersById, getBranchParametersScreenDropdown, postBranchParameters } from '../../../../Core/Service/communicator-service';
import TableChartIcon from '@material-ui/icons/TableChart';
import { AppStorage } from '../../../../Core/Service/storage-service';
import CustomeEditor from '../../../../Core/CkEditor';
import { makeStyles } from '@material-ui/core/styles';

const crumbs = () => [
    { name: 'Home', path: '/' },
    { name: 'Communicator', active: true },
    { name: 'Branch Parameters', active: true }
];

const useStyles = makeStyles({
    branchSelectLable: {
        color: (props) => props.portalHeaderTextColour
    },
    branchSelectInput: {
        // background: (props) => props.portalHeaderBackgroundColour,
        // color: (props) => props.portalHeaderTextColour,

        '& .MuiOutlinedInput-input': {
            padding: '3.5px 3.5px',
            color: (props) => props.portalMenuBarTextColour,
            backgroundColor: (props) => props.portalMenuBarBackgroundColour
        },
        '& .MuiSelect-icon': {
            color: (props) => props.portalMenuBarTextColour
        }
    }
});

const BranchParametersScreen = (props) => {
    const portalSetting = AppStorage.getPortalSetting();
    const { hideModal, showToast } = useContext(AppContext);
    const classes = useStyles(portalSetting);

    const [state, setState] = useState({
        branchID: '',
        overdueEmail: '',
        partStatusEmail: '',
        urgentActionEmailAddress: '',
        cashCustomerMessage: '',
        feedbackEmailAddress: '',
        technicalAssistanceEmail: '',
        workshopDiaryEmail: '',
        branchJobApprovedEmail: '',
        collectionStatusEmail: '',
        jobApprovedEmail: '',
        branchOverdueBodyTemplate: '',
        branchTechnicalAssistanceBodyTemplate: '',
        branchPartStatusBodyTemplate: '',
        branchWorkshopDiaryBodyTemplate: '',
        branchFeedbackBodyTemplate: '',
        branchUrgentActionBodyTemplate: '',
        branchJobApprovedBodyTemplate: '',
        branchCollectionStatusBodyTemplate: '',
        branchList: [],
        branchVORStatusEmail: '',
        branchVORStatusBodyTemplate: '',
        branchSiteVehicleStatusEmail: '',
        branchSiteVehicleStatusBodyTemplate: ''
    });

    const pullBranchDropDown = async () => {
        let res = await getBranchParametersScreenDropdown();
        setState((st) => ({ ...st, branchList: res.data.list.table }));
    };

    const pullBranchById = async () => {
        let res = await getBranchParametersById(state.branchID);
        let data = res.data.list.table[0];
        setState((st) => ({
            ...st,
            overdueEmail: data.overdueEmail || '',
            partStatusEmail: data.partStatusEmail || '',
            urgentActionEmailAddress: data.urgentActionEmailAddress || '',
            cashCustomerMessage: data.cashCustomerMessage || '',
            feedbackEmailAddress: data.feedbackEmailAddress || '',
            technicalAssistanceEmail: data.technicalAssistanceEmail || '',
            workshopDiaryEmail: data.workshopDiaryEmail || '',
            branchJobApprovedEmail: '',
            collectionStatusEmail: data.collectionStatusEmail || '',
            jobApprovedEmail: data.jobApprovedEmail || '',
            branchOverdueBodyTemplate: data.branchOverdueBodyTemplate || '',
            branchTechnicalAssistanceBodyTemplate: data.branchTechnicalAssistanceBodyTemplate || '',
            branchPartStatusBodyTemplate: data.branchPartStatusBodyTemplate || '',
            branchWorkshopDiaryBodyTemplate: data.branchWorkshopDiaryBodyTemplate || '',
            branchFeedbackBodyTemplate: data.branchFeedbackBodyTemplate || '',
            branchUrgentActionBodyTemplate: data.branchUrgentActionBodyTemplate || '',
            branchJobApprovedBodyTemplate: data.branchJobApprovedBodyTemplate || '',
            branchCollectionStatusBodyTemplate: data.branchCollectionStatusBodyTemplate || '',
            branchBookingRequestEmail: data.branchBookingRequestEmail,
            branchVORStatusEmail: data.branchVORStatusEmail,
            branchVORStatusBodyTemplate: data.branchVORStatusBodyTemplate || '',
            branchSiteVehicleStatusEmail: data.branchSiteVehicleStatusEmail,
            branchSiteVehicleStatusBodyTemplate: data.branchSiteVehicleStatusBodyTemplate || ''
        }));
    };

    useEffect(() => {
        pullBranchDropDown();
    }, []);

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = checked;
            return nst;
        });
    };

    useEffect(() => {
        if (state.branchID) {
            pullBranchById();
        } else {
            setState((st) => ({
                ...st,
                overdueEmail: '',
                partStatusEmail: '',
                urgentActionEmailAddress: '',
                cashCustomerMessage: '',
                feedbackEmailAddress: '',
                technicalAssistanceEmail: '',
                workshopDiaryEmail: '',
                branchJobApprovedEmail: '',
                collectionStatusEmail: '',
                jobApprovedEmail: '',
                branchOverdueBodyTemplate: '',
                branchTechnicalAssistanceBodyTemplate: '',
                branchPartStatusBodyTemplate: '',
                branchWorkshopDiaryBodyTemplate: '',
                branchFeedbackBodyTemplate: '',
                branchUrgentActionBodyTemplate: '',
                branchJobApprovedBodyTemplate: '',
                branchCollectionStatusBodyTemplate: '',
                branchVORStatusEmail: '',
                branchVORStatusBodyTemplate: '',
                branchSiteVehicleStatusEmail: '',
                branchSiteVehicleStatusBodyTemplate: ''
            }));
        }
    }, [state.branchID]);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleSubmit = async () => {
        let data = {
            branchID: state.branchID,
            feedbackEmailAddress: state.feedbackEmailAddress,
            overdueEmail: state.overdueEmail,
            technicalAssistanceEmail: state.technicalAssistanceEmail,
            workshopDiaryEmail: state.workshopDiaryEmail,
            urgentActionEmailAddress: state.urgentActionEmailAddress,
            partStatusEmail: state.partStatusEmail,
            collectionStatusEmail: state.collectionStatusEmail,
            cashCustomerMessage: state.cashCustomerMessage,
            jobApprovedEmail: state.jobApprovedEmail,
            branchOverdueBodyTemplate: state.branchOverdueBodyTemplate,
            branchTechnicalAssistanceBodyTemplate: state.branchTechnicalAssistanceBodyTemplate,
            branchPartStatusBodyTemplate: state.branchPartStatusBodyTemplate,
            branchWorkshopDiaryBodyTemplate: state.branchWorkshopDiaryBodyTemplate,
            branchFeedbackBodyTemplate: state.branchFeedbackBodyTemplate,
            branchUrgentActionBodyTemplate: state.branchUrgentActionBodyTemplate,
            branchJobApprovedBodyTemplate: state.branchJobApprovedBodyTemplate,
            branchCollectionStatusBodyTemplate: state.branchCollectionStatusBodyTemplate,
            branchBookingRequestEmail: state.branchBookingRequestEmail,
            branchVORStatusBodyTemplate: state.branchVORStatusBodyTemplate,
            branchVORStatusEmail: state.branchVORStatusEmail,
            branchSiteVehicleStatusEmail: state.branchSiteVehicleStatusEmail,
            branchSiteVehicleStatusBodyTemplate: state.branchSiteVehicleStatusBodyTemplate
        };

        let res = await postBranchParameters(data);
        if (!res.success) {
            console.error(res);
            showToast(res.message);
        } else {
            showToast('Branch Parameters save successfully');
            pullBranchById();
        }
    };

    const handleCkeditorstate = (name) => (event, editor) => {
        const data = editor.getData();
        setState((st) => ({ ...st, [name]: data }));
    };

    return (
        <div>
            <BreadCrumbs crumbs={crumbs()} />
            <Grid container style={{ padding: '0px 10px' }}>
                <Grid item xs={12}>
                    <Grid container style={{ border: '1px solid lightgrey', paddingBottom: 20 }}>
                        <Grid item xs={12}>
                            <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                                style={{
                                    // background: 'red',
                                    background: portalSetting.portalHeaderBackgroundColour,
                                    color: portalSetting.portalHeaderTextColour,
                                    minHeight: '60px',
                                    padding: '0px 10px'
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Branch Parameters</span>
                                        </Grid>
                                        <Grid item xs={6} md={5} lg={4} xl={3}>
                                            <Grid container alignItems="center" spacing={2}>
                                                <Grid item xs={12}>
                                                    {/* <InputLabel shrink className={classes.branchSelectLable}>
                                                        Branch Parameters
                                                    </InputLabel> */}
                                                    <SelectBox
                                                        onChange={handleFieldChange}
                                                        value={state.branchID}
                                                        name="branchID"
                                                        List={state.branchList || []}
                                                        className={classes.branchSelectInput}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <SecondaryButton onClick={handleSubmit}>Update</SecondaryButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <Grid container spacing={2}>
                                {/* <Grid item xs={12}>
                                    <Grid container alignItems="center" spacing={3}>
                                        <Grid item xs={6}>
                                            <FormLabel style={{ paddingBottom: 10 }}>Branch Parameters</FormLabel>
                                            <SelectBox onChange={handleFieldChange} value={state.branchID} name="branchID" List={state.branchList || []} />
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid item container xs={12} sm={12} spacing={1}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <FormLabel>Booking Email Received request</FormLabel>
                                        <TextBox
                                            value={state.branchBookingRequestEmail}
                                            placeholder="Branch Booking Request Email"
                                            onChange={handleFieldChange}
                                            name="branchBookingRequestEmail"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <FormLabel>Cash Customer Message</FormLabel>
                                        <TextBox
                                            value={state.cashCustomerMessage}
                                            placeholder="Cash Customer Message"
                                            onChange={handleFieldChange}
                                            name="cashCustomerMessage"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormLabel>Overdue Reminder Email</FormLabel>
                                                <TextBox
                                                    value={state.overdueEmail}
                                                    placeholder="Overdue Reminder Email"
                                                    onChange={handleFieldChange}
                                                    name="overdueEmail"
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <CustomeEditor
                                                    data={state.branchOverdueBodyTemplate}
                                                    onChange={handleCkeditorstate('branchOverdueBodyTemplate')}
                                                    placeholder="Add description here"
                                                    name="branchOverdueBodyTemplate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormLabel>Job Approved Email</FormLabel>
                                                <TextBox
                                                    value={state.jobApprovedEmail}
                                                    placeholder="Job Approved Email"
                                                    onChange={handleFieldChange}
                                                    name="jobApprovedEmail"
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <CustomeEditor
                                                    data={state.branchJobApprovedBodyTemplate}
                                                    onChange={handleCkeditorstate('branchJobApprovedBodyTemplate')}
                                                    placeholder="Add description here"
                                                    name="branchJobApprovedBodyTemplate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormLabel>Awaiting Parts Email</FormLabel>
                                                <TextBox
                                                    value={state.partStatusEmail}
                                                    placeholder="Awaiting Parts Email"
                                                    onChange={handleFieldChange}
                                                    name="partStatusEmail"
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <CustomeEditor
                                                    data={state.branchPartStatusBodyTemplate}
                                                    onChange={handleCkeditorstate('branchPartStatusBodyTemplate')}
                                                    placeholder="Add description here"
                                                    name="branchPartStatusBodyTemplate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormLabel>High Priority Jobs Email</FormLabel>
                                                <TextBox
                                                    value={state.urgentActionEmailAddress}
                                                    placeholder="High Priority Jobs Email"
                                                    onChange={handleFieldChange}
                                                    name="urgentActionEmailAddress"
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <CustomeEditor
                                                    data={state.branchUrgentActionBodyTemplate}
                                                    onChange={handleCkeditorstate('branchUrgentActionBodyTemplate')}
                                                    placeholder="Add description here"
                                                    name="branchUrgentActionBodyTemplate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item xs={12} sm={12} md={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormLabel>Feedback Email</FormLabel>
                                                <TextBox
                                                    value={state.feedbackEmailAddress}
                                                    placeholder="Feedback Email"
                                                    onChange={handleFieldChange}
                                                    name="feedbackEmailAddress"
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <CustomeEditor
                                                    data={state.branchFeedbackBodyTemplate}
                                                    onChange={handleCkeditorstate('branchFeedbackBodyTemplate')}
                                                    placeholder="Add description here"
                                                    name="branchFeedbackBodyTemplate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormLabel>Technical Assistance Required Email</FormLabel>
                                                <TextBox
                                                    value={state.technicalAssistanceEmail}
                                                    placeholder="Technical Assistance Required Email"
                                                    onChange={handleFieldChange}
                                                    name="technicalAssistanceEmail"
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <CustomeEditor
                                                    data={state.branchTechnicalAssistanceBodyTemplate}
                                                    onChange={handleCkeditorstate('branchTechnicalAssistanceBodyTemplate')}
                                                    placeholder="Add description here"
                                                    name="branchTechnicalAssistanceBodyTemplate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormLabel>Workshop Vehicles Email</FormLabel>
                                                <TextBox
                                                    value={state.workshopDiaryEmail}
                                                    placeholder="Workshop Vehicles Email"
                                                    onChange={handleFieldChange}
                                                    name="workshopDiaryEmail"
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <CustomeEditor
                                                    data={state.branchWorkshopDiaryBodyTemplate}
                                                    onChange={handleCkeditorstate('branchWorkshopDiaryBodyTemplate')}
                                                    placeholder="Add description here"
                                                    name="branchWorkshopDiaryBodyTemplate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormLabel>Awaiting Collection Email</FormLabel>
                                                <TextBox
                                                    value={state.collectionStatusEmail}
                                                    placeholder="Awaiting Collection Email"
                                                    onChange={handleFieldChange}
                                                    name="collectionStatusEmail"
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <CustomeEditor
                                                    data={state.branchCollectionStatusBodyTemplate}
                                                    onChange={handleCkeditorstate('branchCollectionStatusBodyTemplate')}
                                                    placeholder="Add description here"
                                                    name="branchCollectionStatusBodyTemplate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormLabel>VOR Status Email</FormLabel>
                                                <TextBox
                                                    value={state.branchVORStatusEmail}
                                                    placeholder="VOR Status Email"
                                                    onChange={handleFieldChange}
                                                    name="branchVORStatusEmail"
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <CustomeEditor
                                                    data={state.branchVORStatusBodyTemplate}
                                                    onChange={handleCkeditorstate('branchVORStatusBodyTemplate')}
                                                    placeholder="Add description here"
                                                    name="branchVORStatusBodyTemplate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormLabel>Site Vehicle Email</FormLabel>
                                                <TextBox
                                                    value={state.branchSiteVehicleStatusEmail}
                                                    placeholder="Site Vehicle Email"
                                                    onChange={handleFieldChange}
                                                    name="branchSiteVehicleStatusEmail"
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <CustomeEditor
                                                    data={state.branchSiteVehicleStatusBodyTemplate}
                                                    onChange={handleCkeditorstate('branchSiteVehicleStatusBodyTemplate')}
                                                    placeholder="Add description here"
                                                    name="branchSiteVehicleStatusBodyTemplate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item container xs={12} sm={12} md={6} spacing={1}></Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default BranchParametersScreen;
