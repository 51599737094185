import React, { useContext, useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import AppContext from '../../../App/AppContext';
import { SecondaryButton, GreenButton } from '../../../Core/FormInput';
import { getWorkflowSteps, deleteWorflow } from '../../../Core/Service/workflow-service';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

const DeleteWorkflowModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        workflowName: []
    });
    const workflowId = props.workflowId;
    useEffect(async () => {
        if (workflowId) {
            let result = await getWorkflowSteps(workflowId);
            if (true) {
                setState((st) => ({
                    ...st,
                    workflowName: result.data.workflowName
                }));
            }
        }
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const handleDelete = async () => {
        let res = await deleteWorflow(workflowId);
        if (res.success) {
            showToast(res?.data?.details[0]?.msg);
            props.onFormSubmit(true);
            hideModal();
        } else {
            showToast(res.msg);
        }
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="sm">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitle>Delete Workflow - {state.workflowName}</DialogTitle>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent>
                <span>All workflow steps and settings will be deleted. Are you sure do you want to delete this workflow?</span>
            </DialogContent>
            <DialogActions>
                <SecondaryButton className="Submitbtn" onClick={handleClose}>
                    No
                </SecondaryButton>
                <GreenButton onClick={handleDelete}>Yes</GreenButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteWorkflowModal;
