import React, { useState, useEffect } from 'react';
import { Grid, Avatar, Typography, Paper, Tabs, Tab, CircularProgress } from '@material-ui/core';
import { DashboardTemplets, SalesBarChart, SalesLineChart, SalesTable, SalePieChart } from '../Dashboard/dashboardTemplates';
import ReactSpeedometer from 'react-d3-speedometer';
import { CustomTab } from './tabViewScreen';

import { getUsedVehicle, getNewVehicle, getVehicleContingency, getOrderVehicle } from './../../../../../Core/Service/salesDasboard-service';

const TabScreen1 = (props) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Vehicle Count By Status
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.newVehicleStatus} />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Vehicle Count By Model Analysis Code
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.newVehicleModel} />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Vehicle Count By Model
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.newVehicleModelCode} />
                </div>
            </Grid>
        </Grid>
    );
};
const TabScreen2 = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Vehicle By Franchise
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.usedVehicleFrenchise} />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Total Value By Franchise
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.usedVehicleFrenchise} totalVal={true} />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Vehicle By Branch
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.usedVehicleBranch} />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Total Value By branch
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.usedVehicleBranch} totalVal={true} />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Vehicle By Model Codes
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.usedVehicleModelCode} />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Total Value By Model Codes
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.usedVehicleModelCode} totalVal={true} />
                </div>
            </Grid>
        </Grid>
    );
};
const TabScreen3 = (props) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Sales Branches Total
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.contBranch} />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Sales Department Total
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.contDepartment} />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <div style={{ height: 350 }}>
                    <Typography variant="caption" gutterBottom>
                        Sales Franchise Total
                    </Typography>
                    <SalesBarChart color="#fb7104" data={props.contFranchise} />
                </div>
            </Grid>
        </Grid>
    );
};

const SalesDashboard = () => {
    const [state, setState] = useState({
        usedVehicleFrenchise: [],
        usedVehicleBranch: [],
        usedVehicleModelCode: [],
        newVehicleStatus: [],
        newVehicleModel: [],
        newVehicleModelCode: [],
        contBranch: [],
        contDepartment: [],
        contFranchise: [],
        showLoader: true
    });
    const pullUsedVehicleData = async () => {
        let usedVehicleRes = await getUsedVehicle();

        if (usedVehicleRes.success) {
            setState((st) => ({
                ...st,
                usedVehicleFrenchise: usedVehicleRes.data?.table.map((m) => ({
                    name: m?.franchiseName,
                    total: m?.numberOfVehicles,
                    totalvalue: m?.totalValue
                })),
                usedVehicleBranch: usedVehicleRes.data?.table1.map((m) => ({
                    name: m?.branchShortName,
                    total: m?.numberOfVehicles,
                    totalvalue: m?.totalValue
                })),
                usedVehicleModelCode: usedVehicleRes.data?.table2.map((m) => ({
                    name: m?.modelCode,
                    total: m?.numberOfVehicles,
                    totalvalue: m?.totalValue
                })),
                totalUsedVehicleCount: usedVehicleRes.data?.table3[0].totalVehicleCount
            }));
        }
    };

    const pullNewVehicleData = async () => {
        let newVehicleRes = await getNewVehicle();
        if (newVehicleRes.success) {
            setState((st) => ({
                ...st,
                newVehicleStatus: newVehicleRes.data?.table.map((m) => ({
                    name: m?.statusDescription,
                    total: m?.vehicleCount
                })),
                newVehicleModel: newVehicleRes.data?.table1.map((m) => ({
                    name: m?.modelAnalysisCode,
                    total: m?.vehicleCount
                })),
                newVehicleModelCode: newVehicleRes.data?.table2.map((m) => ({
                    name: m?.modelDescription,
                    total: m?.vehicleCount
                })),
                totalNewVehicleCount: newVehicleRes.data?.table3[0].totalVehicleCount,
                showLoader: false
            }));
        }
    };

    const pullcontingencyData = async () => {
        let res = await getVehicleContingency();
        if (res.success) {
            setState((st) => ({
                ...st,
                contBranch: res.data?.table.map((m) => ({
                    name: m?.branchShortName,
                    total: m?.total
                })),
                contDepartment: res.data?.table1.map((m) => ({
                    name: m?.departmentDescription,
                    total: m?.total
                })),
                contFranchise: res.data?.table2.map((m) => ({
                    name: m?.franchiseName,
                    total: m?.total
                })),

                contTotalCount: `£${res.data?.table3[0].total}`
            }));
        }
    };

    useEffect(async () => {
        pullUsedVehicleData();
        pullNewVehicleData();
        pullcontingencyData();
        // pullOrderData();
    }, []);

    return (
        <div>
            {state.showLoader ? (
                <div style={{ width: '100%' }}>
                    <div style={{ height: 'calc(100vh - 150px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </div>
                </div>
            ) : (
                <Grid container style={{ padding: 20 }} spacing={0}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} justifyContent="space-between">
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <DashboardTemplets val={state.totalNewVehicleCount} title="New Vehicles " />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <DashboardTemplets val={0} title="Vehicles Consigned" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <DashboardTemplets val={state.totalUsedVehicleCount} title="Used Vehicles" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <DashboardTemplets currency={true} val={state.contTotalCount || '£0.00'} title="Sales Contingency Debt" />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} style={{ border: '2px solid #d3d3d3 ', marginTop: 10 }}>
                        <CustomTab TabScreen1={() => TabScreen1(state)} TabScreen2={() => TabScreen2(state)} TabScreen3={() => TabScreen3(state)} />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default SalesDashboard;
