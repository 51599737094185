import { get, post } from './http-calls';

export const getJobNotes = async (jobId) => {
    let res = await get(`JobNotes/${jobId}`, { useAuthToken: true });
    return res;
};

export const addJobNotes = async (jobId, message) => {
    let res = await post(`JobNotes`, { jobId, message }, { useAuthToken: true });
    return res;
};

export const updateJobNotes = async (message, jobId) => {
    let res = await post(`JobNotes/update`, { jobId, message }, { useAuthToken: true });
    return res;
};

export const getStockJobNotes = async (stockNo, isNew = true) => {
    let res = await get(`JobNotes/StockNotes/${stockNo}?isNew=${isNew}`, { useAuthToken: true });
    return res;
};

export const addStockJobNotes = async (StockId, message, isNew = true) => {
    let res = await post(`JobNotes/StockNotes/add?isNew=${isNew}`, { StockId, message }, { useAuthToken: true });
    return res;
};

export const updateStockJobNotes = async (message, StockId, isNew = true) => {
    let res = await post(`JobNotes/StockNotes/update?isNew=${isNew}`, { StockId, message }, { useAuthToken: true });
    return res;
};
