import React, { useMemo, useState } from 'react';
import ReportGijgoGrid from '../GijgoForReportsScreen';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { Grid, InputLabel } from '@material-ui/core';
import { TextBox } from '../../../../Core/FormInput';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { VehicleModalDetail } from './vehicleDetailsModal';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';

const crumbs = (val, menuPermissionCounter) => {
    let list = [
        { name: 'Home', path: '/' },
        { name: 'Reports', path: '/ReportsDashboard' },
        { name: 'Vehicles', active: true },
        { name: 'Sefety Check Due', active: true }
    ];

    return list;
};

const baseColumns = [
    {
        field: 'reg',
        title: 'Reg Number',
        width: 120,
        filterable: true,
        sortable: true
    },
    {
        title: 'Vehicle Description',
        field: 'vd',
        sortable: true,
        width: 130,

        filterable: true
    },
    {
        field: 'fn',
        sortable: true,
        title: 'Franchise',
        width: 130,

        filterable: true
    },
    {
        field: 'm',
        sortable: true,
        title: 'Model',
        width: 120,

        filterable: true
    },
    {
        field: 'scd',
        title: 'Safety Check',
        width: 120,
        type: 'dateTime',
        renderer: function (value, record) {
            if (record.scd != null) {
                return moment(record.scd).format('DD-MMM-YYYY');
            }
            return '-';
        },
        filterable: true,
        sortable: true
    },
    {
        field: 'sc',
        title: 'Customer',
        width: 120,

        filterable: true,
        sortable: true
    }
    // {
    //     field: 'vn',
    //     title: 'Phone',
    //     width: 120,

    //     filterable: true,
    //     sortable: true
    // }
];

let startDate = moment().startOf('month').format('YYYY-MM-DD');
let endDate = moment().format('YYYY-MM-DD');

const ReportVehiclesSafetyCheckDueComponent = (props) => {
    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        showModal: false,
        selectedData: {}
    });

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    {/* <CustomIconButton onClick={() => HandelSendMessage(record)} toolTipTitle="Add Action Log"> */}
                    <p
                        style={{ color: '#183B68', textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() =>
                            setState((st) => ({
                                ...st,
                                selectedData: record || {},
                                showModal: true
                            }))
                        }
                    >
                        {value}
                    </p>
                    {/* </CustomIconButton> */}
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        let col = [...baseColumns];
        let colRegNo = col.find((m) => m.field === 'reg');
        if (colRegNo) {
            colRegNo.renderer = editButton;
        }
        return col;
    }, []);

    const handleModal = () => {
        setState((st) => ({
            ...st,
            showModal: !st.showModal
        }));
    };

    const baseUrl = `Vehicle/ReportingGetSafetyCheckDue?StartDate=${state.startDate}&EndDate=${state.endDate}`;

    return (
        <div>
            <BreadCrumbs crumbs={crumbs()} />
            <Grid container style={{ padding: '0px 10px' }}>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={6} sm={6} md={3}>
                        <InputLabel className="mandatoryfields" shrink>
                            Start Date
                        </InputLabel>
                        <TextBox
                            placeholder="Start Date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={handleinput}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <InputLabel className="mandatoryfields" shrink>
                            End Date
                        </InputLabel>

                        <TextBox
                            placeholder="End Date"
                            name="endDate"
                            fullWidth
                            type="date"
                            value={state.endDate}
                            onChange={handleinput}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        displayPagination={true}
                    />
                </Grid>
            </Grid>
            {/* {state.showModal ? (
                <DialogComp title={`Vehicle Details`} onClose={handleModal} fullWidth maxWidth="lg">
                    <VehicleModalDetail onClose={handleModal} selectedData={state.selectedData} />
                </DialogComp>
            ) : null} */}
            {state.showModal ? <VehicleModalDetail onClose={handleModal} selectedData={state.selectedData} open={state.showModal} /> : null}
        </div>
    );
};

export default ReportVehiclesSafetyCheckDueComponent;
