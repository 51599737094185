import { get, post } from './http-calls';

export const getDefectByID = async (id, userID) => {
    let res = '';
    if (userID) {
        res = await get(`Defects?Id=${id}&UserId=${userID}`, { useAuthToken: true });
    } else {
        res = await get(`Defects?Id=${id}`, { useAuthToken: true });
    }
    return res;
};
export const defectPostdata = async (data) => {
    let res = await post(`Defects`, data, { useAuthToken: true });
    return res;
};

export const DeleteDocByDocID = async (HeaderRecordID, docID) => {
    let res = await post(`Defects/DeleteDocument?Id=${HeaderRecordID}&DocumentID=${docID}`, {}, { useAuthToken: true });
    return res;
};
