import React from 'react';
import { Grid, Avatar, Typography, Paper, CircularProgress, Card, ButtonGroup, Button, Hidden } from '@material-ui/core';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { DashboardTemplets, SalesBarChart, SalesLineChart, SalesTable, SalePieChart } from './dashboardTemplates';
import ReactSpeedometer from 'react-d3-speedometer';
import { getsalesDashBoard, getUsedVehicle, getNewVehicle, getVehicleContingency, getOrderVehicle } from './../../../../../Core/Service/salesDasboard-service';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import formatters from '../../../../../Core/Grid/inputFormatter';
import './salesDashboardStyle.scss';
// import { CircularProgress } from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 3,
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 120,
        padding: '5px 0px',
        background: '#f5f5f5',
        borderRadius: 10
    },
    rootWithutBackColorNBorder: {
        width: '100%',
        margin: 3,
        // border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 120,
        padding: '5px 0px'
        // background: '#f5f5f5',
        // borderRadius: 10
    }
}));

const SalesList = [
    { id: 'salesperson', name: 'Salesperson' },
    { id: 'byModel', name: 'By Model' },
    { id: 'byStatus', name: 'By Status' },
    { id: 'vehicleType', name: 'Vehicle Type' },
    { id: 'vehicleCount', name: 'Vehicle Count' }
];

const VehicleList = [
    { id: 'vehicleCount', name: 'Vehicle Count' },
    { id: 'vehicleValue', name: 'Vehicle Value' }
];

const SalesDashboard = () => {
    const classes = useStyles();

    const [state, setState] = useState({
        usedVehicleFrenchise: [],
        usedVehicleBranch: [],
        usedVehicleModelCode: [],
        newVehicleStatus: [],
        newVehicleModel: [],
        newVehicleModelCode: [],
        contBranch: [],
        contDepartment: [],
        contFranchise: [],
        orderYear: [],
        orderMonth: [],
        orderCount: [],
        vehicleType_Sold: [],
        salePerson_Sold: [],
        UsedLoader: true,
        NewOrderLoader: true,
        NewLoader: true,
        ContiLoader: true,
        selectedNewVehicleListType: 'salesperson',
        selectedUsedVehicleListType: 'vehicleCount'
    });

    const pullOrderData = async () => {
        let res = await getOrderVehicle();
        if (res.success) {
            setState((st) => ({
                ...st,
                orderYear: res.data?.table.map((m) => ({
                    name: m.orderYear,
                    val: m.sold
                })),
                orderMonth: res.data?.table1.map((m) => ({
                    name: m.orderMonthName,
                    val: m.sold
                })),
                orderCount: res.data?.table3.map((m) => ({
                    name: m.modelDescription,
                    total: m.vehicleCount
                })),
                vehicleType_Sold: res.data?.table2.map((m) => ({
                    name: m.modelAnalysisCode,
                    value: m.vehicleCount
                })),
                salePerson_Sold: res.data?.table4.map((m) => ({
                    name: m.salesperson,
                    value: m.totalSold
                })),

                vehiclesOrdered: res.data?.table5[0]?.sold || 0,
                spedoMeterMaxValue: res.data?.table5[0]?.vehicleTarget,
                spedoMeterActualValue: res.data?.table5[0]?.sold,
                spedoMeterPercentageValue: (res.data?.table5[0]?.sold / res.data?.table5[0]?.vehicleTarget) * 100,
                progress: 100,
                NewOrderLoader: false
            }));
        }
    };

    const pullUsedVehicleData = async () => {
        let usedVehicleRes = await getUsedVehicle();

        if (usedVehicleRes.success) {
            setState((st) => ({
                ...st,
                usedVehicleFrenchise: usedVehicleRes.data?.table.map((m) => ({
                    name: m.franchiseName,
                    total: m.numberOfVehicles,
                    value: m.totalValue
                })),
                usedVehicleBranch: usedVehicleRes.data?.table1.map((m) => ({
                    name: m.branchShortName,
                    total: m.numberOfVehicles,
                    value: m.totalValue
                })),
                usedVehicleModelCode: usedVehicleRes.data?.table2.map((m) => ({
                    name: m.modelCode,
                    value: m.numberOfVehicles,
                    totalvalue: m.totalValue
                })),

                totalUsedVehicleCount: usedVehicleRes.data?.table3[0]?.totalVehicleCount,
                progress: 25,
                UsedLoader: false
            }));
        }
    };

    const pullNewVehicleData = async () => {
        let newVehicleRes = await getNewVehicle();
        if (newVehicleRes.success) {
            setState((st) => ({
                ...st,
                newVehicleStatus: newVehicleRes.data?.table.map((m) => ({
                    name: m.statusDescription,
                    value: m.vehicleCount
                })),
                newVehicleModel: newVehicleRes.data?.table1.map((m) => ({
                    name: m.modelAnalysisCode,
                    value: m.vehicleCount
                })),
                newVehicleModelCode: newVehicleRes.data?.table2.map((m) => ({
                    name: m.modelDescription,
                    value: m.vehicleCount
                })),
                totalNewVehicleCount: newVehicleRes.data?.table3[0]?.totalVehicleCount,
                progress: 50,

                NewLoader: false
            }));
        }
    };

    const pullcontingencyData = async () => {
        let res = await getVehicleContingency();
        if (res.success) {
            setState((st) => ({
                ...st,
                contBranch: res.data?.table.map((m) => ({
                    name: m.branchShortName,
                    total: m.total
                })),
                contDepartment: res.data?.table1.map((m) => ({
                    name: m.departmentDescription,
                    total: m.total
                })),
                contFranchise: res.data?.table2.map((m) => ({
                    name: m.franchiseName,
                    total: m.total
                })),

                contTotalCount: `£${res.data?.table3[0]?.total}`,
                progress: 75,
                ContiLoader: false
            }));
        }
    };
    const history = useHistory();

    useEffect(async () => {
        pullUsedVehicleData();
        pullNewVehicleData();
        pullcontingencyData();
        pullOrderData();
    }, []);
    const GotoNewVehicleOrder = (val) => {
        console.log(val, 'val');
        let searchQuery = `?mc=${val}`;
        history.push({
            pathname: `/sales/VehicleNewOrder`,
            search: searchQuery
        });
    };
    const GoToUsedVehicleOrder = (name) => (val) => {
        let searchQuery = `?${name}=${val}`;
        history.push({
            pathname: `/sales/UsedVehicleStock`,
            search: searchQuery
        });
    };
    const GoToUsedVehicleOrderChartList = (paramValue, name) => (e) => {
        let searchQuery = `?${paramValue}=${name}`;
        history.push({
            pathname: `/sales/UsedVehicleStock`,
            search: searchQuery
        });
    };
    const GoToNewVehicleStockExcludingWIP = (name, url) => (val) => {
        ///sales/SalesContingencyDebt
        let searchQuery = `?${name}=${val}`;
        history.push({
            pathname: `/sales/${url}`,
            search: searchQuery
        });
    };

    const onNewVehicleCardSelectHandle = (name, url, val) => (e) => {
        if (url) {
            let searchQuery = `?${name}=${val}`;
            history.push({
                pathname: `/sales/${url}`,
                search: searchQuery
            });
        }
    };

    const handleBar = (storeStateName, val) => {
        if (storeStateName._reactName === 'onChange') {
            const { name, value } = storeStateName.target;
            setState((st) => ({ ...st, [name]: value }));
        } else {
            setState((st) => ({ ...st, [`${storeStateName}`]: val }));
        }
    };

    console.log(state, 'state');

    let newVehicleChartConfig = {
        salesperson: { paramName: '', url: '' },
        vehicleType: { paramName: '', url: '' },
        byStatus: { paramName: 'pd', url: 'NewVehicleStockExcludingWIP' },
        byModel: { paramName: 'mac', url: 'NewVehicleStockExcludingWIP' },
        vehicleCount: { paramName: 'mc', url: 'NewVehicleStockExcludingWIP' }
    };

    return (
        <div className=" salesBudgetScreen">
            <Grid container style={{ padding: '10px' }}>
                <Grid item xs={12}>
                    <Grid container spacing={3} justifyContent="space-between">
                        <Grid item xs={12} sm={6} md={3}>
                            <DashboardTemplets val={state.totalNewVehicleCount || 0} title="New Vehicles (Stock)" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DashboardTemplets val={state.vehiclesOrdered || 0} title="Vehicles Ordered" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DashboardTemplets val={state.totalUsedVehicleCount || 0} title="Used Vehicles" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DashboardTemplets currency={true} val={state.contTotalCount || '£0.00'} title="Sales Contingency Debt" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className="cardComp">
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={12} style={{ textAlign: 'left', padding: '0px' }}>
                                    <Typography variant="h5" color="secondary" className="cardHeading">
                                        New Vehicle Order
                                    </Typography>
                                </Grid>
                                {state.NewOrderLoader ? (
                                    <div style={{ width: '100%' }}>
                                        <div
                                            style={{
                                                height: 'calc(100vh - 600px)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <CircularProgress />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                            <div style={{ height: 160 }}>
                                                <Typography variant="caption" gutterBottom>
                                                    New Vehicle Order Year
                                                </Typography>
                                                <SalesLineChart data={state.orderYear} color="#fd7f6f" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                            <div style={{ height: 160 }}>
                                                <Typography variant="caption" gutterBottom>
                                                    New Vehicle Order Month
                                                </Typography>
                                                <SalesLineChart data={state.orderMonth} color="#0d88e6" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                            <div style={{ height: 160 }}>
                                                <Typography variant="caption" gutterBottom>
                                                    New Vehicle Order Count
                                                </Typography>
                                                <SalesBarChart data={state.orderCount} color="#5ad45a" onClick={GotoNewVehicleOrder} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                            <ReactSpeedometer
                                                maxValue={state.spedoMeterMaxValue}
                                                width={280}
                                                needleHeightRatio={0.7}
                                                value={state.spedoMeterActualValue}
                                                needleColor="black"
                                                ringWidth={15}
                                                segments={4}
                                                height={200}
                                                segmentColors={['#ff0000', '#ff0000', '#FFBF00', '#00FF00']}
                                                currentValueText={`${Math.round(state.spedoMeterPercentageValue) || 0}% Vehicle Orders`}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: 5, boxSize: 'none' }}>
                                            <Card className={classes.root}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item xs={12} sm={6} style={{ float: 'right' }}>
                                                        <Hidden only="xs">
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    textAlign: 'left',
                                                                    color: '#f50057',
                                                                    fontWeight: 600,
                                                                    margin: '10px 20px'
                                                                }}
                                                            >
                                                                <ButtonGroup color="secondary" aria-label="outlined primary button group" size="small">
                                                                    {SalesList.map((n) => {
                                                                        return (
                                                                            <Button
                                                                                key={n.name}
                                                                                variant={state.selectedNewVehicleListType === n.id ? 'contained' : 'outlined'}
                                                                                onClick={() => handleBar('selectedNewVehicleListType', n.id)}
                                                                                style={{ fontSize: 10 }}
                                                                            >
                                                                                {n.name}
                                                                            </Button>
                                                                        );
                                                                    })}
                                                                </ButtonGroup>
                                                            </div>
                                                        </Hidden>
                                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    textAlign: 'left',
                                                                    color: '#f50057',
                                                                    fontWeight: 600
                                                                }}
                                                            >
                                                                <select
                                                                    value={state.selectedNewVehicleListType}
                                                                    name="selectedNewVehicleListType"
                                                                    onChange={handleBar}
                                                                    style={{ width: '100%', padding: 5 }}
                                                                >
                                                                    {SalesList.map((n) => {
                                                                        return <option value={n.id}>{n.name}</option>;
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </Hidden>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={1} style={{ padding: '0px 20px' }}>
                                                    {false ? (
                                                        <div
                                                        // className={classes.root}
                                                        >
                                                            <div className="pieChartSize" style={{ display: 'flex' }}>
                                                                <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }} />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        [
                                                            ...(state.selectedNewVehicleListType === 'salesperson'
                                                                ? state.salePerson_Sold
                                                                : state.selectedNewVehicleListType === 'vehicleType'
                                                                ? state.vehicleType_Sold
                                                                : state.selectedNewVehicleListType === 'byStatus'
                                                                ? state.newVehicleStatus
                                                                : state.selectedNewVehicleListType === 'byModel'
                                                                ? state.newVehicleModelCode
                                                                : state.selectedNewVehicleListType === 'vehicleCount'
                                                                ? state.newVehicleModel
                                                                : [])
                                                        ].map((g) => {
                                                            return (
                                                                <div>
                                                                    <Grid item style={{ padding: '5px 5px' }}>
                                                                        <div
                                                                            className="newVehicleCardList"
                                                                            style={{
                                                                                cursor: newVehicleChartConfig[state.selectedNewVehicleListType].url
                                                                                    ? 'pointer'
                                                                                    : ''
                                                                            }}
                                                                            onClick={onNewVehicleCardSelectHandle(
                                                                                newVehicleChartConfig[state.selectedNewVehicleListType].paramName,
                                                                                newVehicleChartConfig[state.selectedNewVehicleListType].url,
                                                                                g.name
                                                                            )}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    paddingBottom: 5,
                                                                                    paddingRight: 20,
                                                                                    textAlign: 'left'
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 14,
                                                                                        marginBottom: 6
                                                                                    }}
                                                                                >
                                                                                    {g.name}
                                                                                </span>
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: 30,
                                                                                        fontWeight: 600
                                                                                    }}
                                                                                >
                                                                                    {g.value || 0}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className="cardComp">
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12} style={{ textAlign: 'left', padding: '0px' }}>
                                    <Typography variant="h5" color="secondary" className="cardHeading">
                                        Used Vehicle
                                    </Typography>
                                </Grid>
                                {state.UsedLoader ? (
                                    <div style={{ width: '100%' }}>
                                        <div
                                            style={{
                                                height: 'calc(100vh - 600px)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <CircularProgress />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                            <div style={{ height: 160 }}>
                                                <Typography variant="caption" gutterBottom>
                                                    Vehicle by Franchise
                                                </Typography>
                                                <SalesBarChart data={state.usedVehicleFrenchise} color="#ffb55a" onClick={GoToUsedVehicleOrder('fn')} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                            <div style={{ height: 160 }}>
                                                <Typography variant="caption" gutterBottom>
                                                    Vehicle by Branch
                                                </Typography>
                                                <SalesBarChart data={state.usedVehicleBranch} onClick={GoToUsedVehicleOrder('bn')} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                            <div style={{ height: 160, position: 'relative' }}>
                                                <Typography variant="caption" gutterBottom></Typography>
                                                <SalePieChart data={state.usedVehicleFrenchise} onClick={GoToUsedVehicleOrder('fn')} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                            <div style={{ height: 160, position: 'relative' }}>
                                                <Typography variant="caption" gutterBottom></Typography>
                                                <SalePieChart data={state.usedVehicleBranch} onClick={GoToUsedVehicleOrder('bn')} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: 5, boxSize: 'none' }}>
                                            <Card className={classes.root}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item xs={12} sm={6} style={{ float: 'right' }}>
                                                        <Hidden only={['xs']}>
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    textAlign: 'left',
                                                                    color: '#f50057',
                                                                    fontWeight: 600,
                                                                    margin: '10px 20px'
                                                                }}
                                                            >
                                                                <ButtonGroup color="secondary" ria-label="outlined primary button group" size="small">
                                                                    {VehicleList.map((n) => {
                                                                        return (
                                                                            <Button
                                                                                variant={state.selectedUsedVehicleListType === n.id ? 'contained' : 'outlined'}
                                                                                onClick={() => handleBar('selectedUsedVehicleListType', n.id)}
                                                                                style={{ fontSize: 10 }}
                                                                            >
                                                                                {n.name}
                                                                            </Button>
                                                                        );
                                                                    })}
                                                                </ButtonGroup>
                                                            </div>
                                                        </Hidden>
                                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    textAlign: 'left',
                                                                    color: '#f50057',
                                                                    fontWeight: 600
                                                                }}
                                                            >
                                                                <select
                                                                    value={state.selectedUsedVehicleListType}
                                                                    name="selectedUsedVehicleListType"
                                                                    onChange={handleBar}
                                                                    style={{ width: '100%', padding: 5 }}
                                                                >
                                                                    {VehicleList.map((n) => {
                                                                        return <option value={n.id}>{n.name}</option>;
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </Hidden>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={1} style={{ padding: '0px 20px' }}>
                                                    {false ? (
                                                        <div
                                                        // className={classes.root}
                                                        >
                                                            <div className="pieChartSize" style={{ display: 'flex' }}>
                                                                <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }} />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        [...state.usedVehicleModelCode].map((g) => {
                                                            return (
                                                                <div>
                                                                    <Grid item style={{ padding: '5px 5px' }}>
                                                                        <div
                                                                            className="newVehicleCardList"
                                                                            style={{
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={GoToUsedVehicleOrderChartList('mdc', g.name)}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    paddingBottom: 5,
                                                                                    paddingRight: 20,
                                                                                    textAlign: 'left'
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 14,
                                                                                        marginBottom: 6
                                                                                    }}
                                                                                >
                                                                                    {g.name}
                                                                                </span>
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: 30,
                                                                                        fontWeight: 600
                                                                                    }}
                                                                                >
                                                                                    {state.selectedUsedVehicleListType === 'vehicleCount'
                                                                                        ? g.value
                                                                                        : formatters.CurrencyThousandSeparator(g.totalvalue) || 0}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className="cardComp">
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12} style={{ textAlign: 'left', padding: '0px' }}>
                                    <Typography variant="h5" color="secondary" className="cardHeading">
                                        Sales Contingency Debt
                                    </Typography>
                                </Grid>
                                {state.ContiLoader ? (
                                    <div style={{ width: '100%' }}>
                                        <div
                                            style={{
                                                height: 'calc(100vh - 600px)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <CircularProgress />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                            <div style={{ height: 160 }}>
                                                <Typography variant="caption" gutterBottom>
                                                    Sales branch total
                                                </Typography>
                                                <SalesBarChart
                                                    formatter="CurrencyThousandSeparatorWithoutZero"
                                                    data={state.contBranch}
                                                    color="#50e991"
                                                    onClick={GoToNewVehicleStockExcludingWIP('bn', 'SalesContingencyDebt')}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                            <div style={{ height: 160 }}>
                                                <Typography variant="caption" gutterBottom>
                                                    Sales Departments total
                                                </Typography>
                                                <SalesBarChart
                                                    formatter="CurrencyThousandSeparatorWithoutZero"
                                                    data={state.contDepartment}
                                                    color="#e6d800"
                                                    onClick={GoToNewVehicleStockExcludingWIP('dd', 'SalesContingencyDebt')}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                            <div style={{ height: 160 }}>
                                                <Typography variant="caption" gutterBottom>
                                                    Sales Franchise total
                                                </Typography>
                                                <SalesBarChart
                                                    formatter="CurrencyThousandSeparatorWithoutZero"
                                                    data={state.contFranchise}
                                                    color="#ffa300"
                                                    onClick={GoToNewVehicleStockExcludingWIP('fn', 'SalesContingencyDebt')}
                                                />
                                            </div>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SalesDashboard;
