import React, { useState, useRef, useEffect } from 'react';

import loadingImg from './bullet.svg';
import { getApiData } from '../../../Service/apprisal-service';
import { SecondaryButton, TextBox } from '../../../FormInput';
import { FormHelperText, Grid, InputAdornment } from '@material-ui/core';

let pullTimer;

let AutoSuggest = function ({ config, state, onFieldChange, disabled, autoFocus, pullPatchData }) {
    let controlName = config.screenColumnJsonName;
    let controlValue = state[controlName];
    let minLength = config.screenColumnMinValue || 3;

    let menuRef = useRef();

    let [localState, setLocalState] = useState({
        value: controlValue,
        loadingData: false,
        selectedIndex: 0,
        searched: false,
        items: [],
        inFocus: false
    });

    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current.contains(e.target) || e.key === 'Tab') {
                setLocalState((st) => ({ searched: false }));
            }
        };

        document.addEventListener('mousedown', handler);
        document.addEventListener('keydown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('keydown', handler);
        };
    });

    async function pullAndSync(e) {
        onFieldChange(e);
        let vl = e.target.value && e.target.value.trim();
        pullTimer && clearTimeout(pullTimer);
        if (vl && vl.length >= minLength) {
            setLocalState((st) => ({ ...st, loadingData: true }));
            pullTimer = setTimeout(async () => {
                let res = await getApiData(`${config.screenColumnControlValueApiEndPoint}?searchText=${vl}`);
                if (res.success) {
                    let dt = res.data.list || [];
                    setLocalState((st) => ({ items: dt, searched: true, loadingData: false }));
                } else {
                    setLocalState((st) => ({ items: [], searched: true, loadingData: false }));
                }
            }, 500);
        } else if (vl && vl.length < minLength) {
            setLocalState((st) => ({ ...st, items: [], searched: false, loadingData: false }));
        }
    }
    const handleCodePull = (data) => {
        pullPatchData(controlName, { ...data });
        setLocalState((st) => ({ searched: false }));
    };

    return (
        <Grid item xs={12}>
            <div className="autosuggest-container" style={{ position: 'relative' }} ref={menuRef}>
                <TextBox
                    type="text"
                    disabled={disabled}
                    autoFocus={autoFocus}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">{localState.loadingData && <img style={{ width: 10 }} src={loadingImg} />} </InputAdornment>
                        )
                    }}
                    title={config.tooltip}
                    name={controlName}
                    onChange={pullAndSync}
                    value={controlValue}
                    autoComplete={controlName}
                />
                {localState.searched && (
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="AutoSuggestDiv">
                                <>
                                    {localState.items.length > 0 ? (
                                        localState.items.map((item, i) => (
                                            <div onClick={() => handleCodePull(item)} className="SuggestUi">
                                                {item.firstName && <sapn>{item.firstName}</sapn>} &nbsp;
                                                {item.firstName && item.postCode && <span>{item.postCode}</span>} &nbsp;
                                                {<span>{item.addressLine1}</span>}
                                            </div>
                                        ))
                                    ) : (
                                        <div style={{ margin: 10 }}>
                                            <sapn>No Record Found</sapn>
                                        </div>
                                    )}
                                </>
                            </div>
                        </Grid>
                    </Grid>
                )}{' '}
            </div>
        </Grid>
    );
};

export default AutoSuggest;
