import { CircularProgress, Grid, TableContainer, Typography } from '@material-ui/core';

import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import AppContext from '../../../../../App/AppContext';
import {
    addReportingBudget,
    addReportingVehicleBudget,
    addReportingVehicleInternalBudget,
    getNewUsedVehicleInternalReportingBudget,
    getNewUsedVehicleReportingBudget,
    getReportingBudget
} from '../../../../../Core/Service/ReportScreens-service';
import formatters from '../../../../../Core/Grid/inputFormatter';
import moment from 'moment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import InternalBudgetModal from './salesTargetModal';
import DialogComp from '../../../../../Core/Modal/dialogModal';
import PersonIcon from '@material-ui/icons/Person';
import { Alert } from '@material-ui/lab';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const useStyles = makeStyles({
    table: {
        minWidth: 1750,
        '& .MuiTableCell-root': {
            padding: '10px 5px'
        }
    },
    tableRowStyle: {
        backgroundColor: '#E0E0E0'
    },
    totalsContainerStyle: {
        fontWeight: 600
    }
});

const MonthShortNameList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const NewUsedReportingBudgetTable = (props) => {
    const [state, setState] = useState({
        isRecords: false,
        rows: [],
        isActive: false,
        category: '',
        columnTotal: {},
        rows: [],
        calculatedRows: [],
        isActive: false,
        totals: {},
        changeRecordIds: [],
        isChangeTrig: false,
        showVehicleModal: false,
        vehicleBudgetObj: {},
        showVehicleMessage: false
    });

    const [modelTypeList, setModelTypeList] = useState([]);
    const [saleTarget, setSaleTarget] = useState([]);
    const [salesTargetRes, setSalesTargetRes] = useState([]);
    const [showCircularLoader, setShowCircularLoader] = useState(true);
    const [calculatedExpenseList, setCalculatedExpenseList] = useState([]);
    const { showToast } = useContext(AppContext);
    const classes = useStyles();

    const pullExpenseBudgetGridData = async () => {
        setShowCircularLoader(true);
        let sortedModelList = props.modelList
            .filter((p) => {
                if (props.selectedTargetType.isElectic) {
                    return p.isElectric && p.id == props.selectedCategories.id;
                } else {
                    return p.id == props.selectedCategories.id;
                }
            })
            .map((q) => ({ ...q, data: [] }));

        let res = await getNewUsedVehicleInternalReportingBudget(
            props.vehicleType,
            props.yearCode?.id,
            props.selectedTargetType.id,
            props.selectedCategories.id
        );
        let tempResData = res?.data.targetData || [];
        let salesTargetRes = res?.data.salesTarget || [];
        if (sortedModelList.length > 0) {
            tempResData?.forEach((p) => {
                let objIndex = sortedModelList.findIndex((o) => o.code?.trim() == p.code);
                if (objIndex > -1) {
                    sortedModelList[objIndex].data.push({ ...p, typeID: props.selectedTargetType.id });
                } else {
                    sortedModelList.push({
                        code: p.code,
                        name: p.name,
                        data: [{ ...p }]
                    });
                }
            });

            sortedModelList?.forEach((r) => {
                let list2 = MonthShortNameList.map((m) => {
                    let obj = r.data.find((p) => moment(p.td).format('DD-MMM-YYYY').includes(`${m}`));
                    if (obj) {
                        return obj;
                    } else {
                        return {
                            code: r.code,
                            fc: props.vehicleType,
                            id: null,
                            name: r.name,
                            typeID: props.selectedTargetType.id,
                            td: moment(`01/${m}/${props.yearCode?.id}`).format('DD-MMM-YYYY'),
                            vt: 0,
                            vu: 0
                        };
                    }
                });
                r.setAmountToAll = null;
                r.data = list2;
            });

            setState((st) => {
                return { ...st, isRecords: true, isChangeTrig: false };
            });
        } else {
            setState((st) => {
                return { ...st, isRecords: false };
            });
        }
        setModelTypeList(sortedModelList);
        setShowCircularLoader(false);
        setSalesTargetRes(salesTargetRes);
    };

    useEffect(() => {
        pullExpenseBudgetGridData();
    }, [props.yearCode?.id, props.selectedCategories?.id]);

    useEffect(() => {
        props.handleActionTriggerCheck(state.isChangeTrig);
    }, [state.isChangeTrig]);

    useEffect(() => {
        let tempArr = [...modelTypeList];
        tempArr.forEach((t) => {
            let vtTotal = 0;
            let vuTotal = 0;
            t.data.forEach((q) => {
                vtTotal += +q.vt;
                vuTotal += +q.vu;
            });
            t.allVtAmount = vtTotal;
            t.allVuAmount = vuTotal;
        });

        let colCalculation = {
            modelTypeJanVtTotal: 0,
            modelTypeJanVuTotal: 0,
            modelTypeFebVtTotal: 0,
            modelTypeFebVuTotal: 0,
            modelTypeMarVtTotal: 0,
            modelTypeMarVuTotal: 0,
            modelTypeAprVtTotal: 0,
            modelTypeAprVuTotal: 0,
            modelTypeMayVtTotal: 0,
            modelTypeMayVuTotal: 0,
            modelTypeJunVtTotal: 0,
            modelTypeJunVuTotal: 0,
            modelTypeJulVtTotal: 0,
            modelTypeJulVuTotal: 0,
            modelTypeAugVtTotal: 0,
            modelTypeAugVuTotal: 0,
            modelTypeSepVtTotal: 0,
            modelTypeSepVuTotal: 0,
            modelTypeOctVtTotal: 0,
            modelTypeOctVuTotal: 0,
            modelTypeNovVtTotal: 0,
            modelTypeNovVuTotal: 0,
            modelTypeDecVtTotal: 0,
            modelTypeDecVuTotal: 0,
            modelTypeVtTotalTotal: 0,
            modelTypeVuTotalTotal: 0
        };
        let vtTotals = 0;
        let vuTotals = 0;
        tempArr.forEach((w) => {
            vtTotals += w.allVtAmount;
            vuTotals += w.allVuAmount;
            w.data.forEach((q) => {
                MonthShortNameList.map((m) => {
                    if (moment(q.td).format('DD-MMM-YYYY').includes(`${m}`)) {
                        colCalculation[`modelType${m}VtTotal`] += q.vt;
                        colCalculation[`modelType${m}VuTotal`] += q.vu;
                    }
                });
            });
            colCalculation.modelTypeVtTotalTotal = vtTotals;
            colCalculation.modelTypeVuTotalTotal = vuTotals;
        });
        tempArr.push({ name: 'Total', data: colCalculation });

        setCalculatedExpenseList(tempArr);
    }, [modelTypeList]);

    const gridInputChangeHandler = (index, code) => (e) => {
        let tempList = [...modelTypeList];
        let objIndex = tempList.findIndex((x) => x.code == code);
        tempList[objIndex].data[index] = {
            ...tempList[objIndex].data[index],
            [e.target.name]: +e.target.value,
            isChanged: true
        };

        setModelTypeList(tempList);
        setState((st) => {
            return { ...st, isChangeTrig: true };
        });
    };

    const handleVehicle = (obj) => {
        const isInvalid = obj.unit <= 0 || obj.profit <= 0;

        setState((st) => ({
            ...st,
            showVehicleMessage: isInvalid,
            vehicleBudgetObj: isInvalid ? {} : obj,
            showVehicleModal: !isInvalid
        }));
    };

    const targetSubmit = (list) => {
        let ListArr = list.filter((element) => element.isAdded);

        if (ListArr.length > 0) {
            let PostListArr = ListArr.map((n) => ({
                ...n,
                operatorID: n.operatorID.id,
                isDeleted: n.isDeleted ? true : false
            }));

            setSaleTarget([...PostListArr]);
        }

        setState((st) => ({ ...st, showVehicleModal: false }));
    };

    const InputWrapper = (index, code, TargetUnits, TargetAmount, vehicleName, typeID, id, color) => {
        return (
            <>
                <div style={{ display: 'flex' }}>
                    &nbsp;
                    <input
                        title={`${TargetUnits}`}
                        type="number"
                        name={'vu'}
                        onChange={gridInputChangeHandler(index, code)}
                        value={TargetUnits}
                        className="pa-4"
                        style={{ textAlign: 'right', width: '50%' }}
                    />
                    <span style={{ margin: '3px' }}>
                        <PersonIcon
                            fontSize="small"
                            // color="secondary"
                            style={{ verticalAlign: 'center', cursor: 'pointer', marginTop: 5, color: `${color}` }}
                            onClick={() =>
                                handleVehicle({
                                    name: vehicleName,
                                    unit: TargetUnits,
                                    profit: TargetAmount,
                                    year: `${MonthShortNameList[index]} ${props.yearCode.id}`,
                                    yearCode: props.yearCode.id,
                                    index: index,
                                    targetNewUsed: props.vehicleType,
                                    typeID: typeID,
                                    code: code,
                                    id: id
                                })
                            }
                        />
                    </span>
                    <input
                        title={`${TargetAmount}`}
                        type="number"
                        name={'vt'}
                        onChange={gridInputChangeHandler(index, code)}
                        value={TargetAmount}
                        className="pa-4"
                        style={{ textAlign: 'right' }}
                    />
                </div>
            </>
        );
    };

    const setAmountToAllOnChamge = (index, code) => (e) => {
        let tempList = [...modelTypeList];
        let objIndex = tempList.findIndex((x) => x.code == code);

        tempList[objIndex][e.target.name] = +e.target.value;
        setModelTypeList(tempList);
    };
    const setAmountToAllHandler = (index, code) => {
        let tempList = [...modelTypeList];
        let objIndex = tempList.findIndex((x) => x.code == code);
        tempList[objIndex].data.forEach((o) => {
            o.vt = o.vu * tempList[objIndex].setAmountToAll;
        });
        setModelTypeList(tempList);
    };

    const ModelColumnFormatter = (index, code, name, setAmountToAll) => {
        return (
            <>
                <div>{name}</div>

                <div style={{ display: 'flex' }}>
                    <input
                        type="number"
                        name={'setAmountToAll'}
                        onChange={setAmountToAllOnChamge(index, code)}
                        value={setAmountToAll}
                        className="pa-4"
                        style={{ width: '90%' }}
                    />

                    <DoubleArrowIcon
                        fontSize="small"
                        color="secondary"
                        style={{ verticalAlign: 'center', cursor: 'pointer' }}
                        onClick={() => setAmountToAllHandler(index, code)}
                    />
                </div>
            </>
        );
    };

    const handleSubmit = async () => {
        let tempModelTypeList = [...modelTypeList];
        let salesPostList = [...saleTarget];
        let data = [];
        tempModelTypeList.forEach((f) => {
            let tempData = f.data.map(({ ...q }) => ({
                reportingVehicleInternalTargetID: q.id,
                reportingVehicleInternalTargetTypeID: +q.typeID,
                reportingVehicleInternalTargetCode: q.code,
                reportingVehicleInternalTargetDate: q.td,
                reportingVehicleInternalTargetUnits: q.vu,
                reportingVehicleInternalTargetAmount: q.vt
            }));
            data = [...data, ...tempData];
        });

        let filterPostData = {
            salesTarget: salesPostList,
            vehicleTargets: data,
            TargetType: props.vehicleType
        };

        let res = await addReportingVehicleInternalBudget(filterPostData);
        if (res.success) {
            showToast('Save Successfully');
            pullExpenseBudgetGridData();
            setSaleTarget([]);
        }
    };

    useEffect(() => {
        props.childFunc.current = handleSubmit;
    }, [modelTypeList, saleTarget]);

    return (
        <Grid container spacing={2} style={{ paddingTop: '10px' }} justifyContent="center">
            {showCircularLoader ? (
                <Grid item>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 320px)' }} className="input-Number-remove-spinners">
                        <Table className={classes.table} stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={120}>Model</TableCell>
                                    {MonthShortNameList.map((p) => {
                                        return (
                                            <TableCell align="center">
                                                <div>{p}</div>
                                                <div>Unit&nbsp;/&nbsp;Amount</div>
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell width={100} align="right">
                                        <div> Total</div>
                                        <div>Unit&nbsp;/&nbsp;Amount</div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!state.isRecords ? (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            No Records
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {calculatedExpenseList.map((t, index) => {
                                            return (
                                                <>
                                                    {!t.code ? (
                                                        <TableRow>
                                                            <TableCell component="th" scope="row" className={classes.totalsContainerStyle}>
                                                                {t.name}
                                                            </TableCell>
                                                            {MonthShortNameList.map((p) => {
                                                                return (
                                                                    <TableCell align="center" className={classes.totalsContainerStyle}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div style={{ width: '30%', textAlign: 'right' }}>
                                                                                {formatters.ThousandSeparatorWithoutZero(t.data[`modelType${p}VuTotal`])}
                                                                            </div>
                                                                            <div style={{}}>&nbsp;/&nbsp;</div>
                                                                            <div style={{}}>
                                                                                {formatters.CurrencyThousandSeparatorWithoutZero(
                                                                                    t.data[`modelType${p}VtTotal`]
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                );
                                                            })}

                                                            <TableCell align="right" className={classes.totalsContainerStyle}>
                                                                {formatters.ThousandSeparatorWithoutZero(t.data.modelTypeVuTotalTotal)}
                                                                &nbsp;/&nbsp;
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(t.data.modelTypeVtTotalTotal)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell component="th" scope="row" style={{ borderRight: '1px solid #e0e0e0' }}>
                                                                {ModelColumnFormatter(index, t.code, t.name, t.setAmountToAll)}
                                                            </TableCell>

                                                            {t.data.map((p, i) => {
                                                                return (
                                                                    <TableCell align="center" style={{ borderRight: '1px solid #e0e0e0' }}>
                                                                        {InputWrapper(i, t.code, p.vu, p.vt, p.name, p.typeID, p.id, p.color)}
                                                                    </TableCell>
                                                                );
                                                            })}

                                                            <TableCell
                                                                align="right"
                                                                className={classes.totalsContainerStyle}
                                                                style={{ borderRight: '1px solid #e0e0e0' }}
                                                            >
                                                                {formatters.ThousandSeparatorWithoutZero(t.allVuAmount)}&nbsp;/&nbsp;
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(t.allVtAmount)}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}

            {state.showVehicleModal ? (
                <DialogComp
                    title="Sales Target"
                    onClose={() =>
                        setState((st) => {
                            return { ...st, showVehicleModal: false };
                        })
                    }
                >
                    <InternalBudgetModal
                        vehicleObj={state.vehicleBudgetObj}
                        operaterDetail={props.operaterDetail}
                        targetSubmit={targetSubmit}
                        salesTargetRes={salesTargetRes}
                        saleTarget={saleTarget}
                        selectedTargetType={props.selectedTargetType}
                    />
                </DialogComp>
            ) : null}
            {state.showVehicleMessage ? (
                <DialogComp
                    title="Sales Target"
                    onClose={() =>
                        setState((st) => {
                            return { ...st, showVehicleMessage: false };
                        })
                    }
                >
                    {' '}
                    <Alert severity="warning">Please Fill The Unit/Amount Fields</Alert>
                </DialogComp>
            ) : null}
        </Grid>
    );
};

export default NewUsedReportingBudgetTable;
