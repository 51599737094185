import { get, post } from './http-calls';

export const getErrorFolders = async () => {
    let res = await get(`DocumentsReprocess/ErrorFolders`, { useAuthToken: true });
    return res;
};

export const getBranches = async (errorFolder) => {
    let res = await get(`DocumentsReprocess/Branches/${errorFolder}`, { useAuthToken: true });
    return res;
};

export const getDocumentTypes = async (errorFolder, branch) => {
    let res = await get(`DocumentsReprocess/DocumentTypes/${errorFolder}/${branch}`, { useAuthToken: true });
    return res;
};

export const getFiles = async (errorFolder, branch, documentType) => {
    let res = await get(`DocumentsReprocess/Files/${errorFolder}/${branch}/${documentType}`, { useAuthToken: true });
    return res;
};

export const processDocument = async (data) => {
    let res = await post(`DocumentsReprocess/ReProcessFiles`, data, { useAuthToken: true });
    return res;
};