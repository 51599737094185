import React, { useState, useEffect } from 'react';
import SliderCheckbox from './Child Controls/_Checkbox';
import { DatePicker, DateTimePicker, SelectBox, TextBox } from '../../FormInput';
import { Checkbox, FormControlLabel, FormLabel, Grid, Switch } from '@material-ui/core';
import './Form.scss';
import { CheckBox } from '@material-ui/icons';
import VehFaceComponent from './Child Controls/Veh-faces.component';
import { IosSwitch } from '../../FormInput/AppCheckbox';
import { calculateReadonly, getApiData, getFormGlobalConfig } from '../../Service/apprisal-service';
import DataPuller from './Child Controls/DataPuller';
import VehiclePhotoUploader from './Child Controls/VehiclePhotoUploader';
// import UserSignature from './Child Controls/UserSignature';
import moment from 'moment';
import UserSignature from './Child Controls/UserSignature';
import AutoSuggest from './Child Controls/AutoSuggest';

let FormControl = function ({ config, state, onFieldChange, screenState, autoFocus, pullPatchData, onSignatureUpdate, pullDropDownList }) {
    let controlName = config.screenColumnJsonName;
    let controlValue = state[controlName] || '';
    let min = config.screenColumnMinValue;
    let max = config.screenColumnMaxValue;
    let otherConfig = getFormGlobalConfig();
    let req = config.screenColumnRequired;
    let readOnly = calculateReadonly(config, otherConfig);
    let hidden = otherConfig.editMode ? config.editHidden : config.hidden;
    let title = config.screenColumnTooltip;
    let minMaxString = '';

    if (min || max) {
        minMaxString = '(';
        if (min) {
            minMaxString += `min: ${min}`;
        }
        if (max) {
            minMaxString += minMaxString.length > 1 ? ', ' : '';
            minMaxString += `max: ${max}`;
        }
        minMaxString += ')';
    }

    let [localState, setLocalState] = useState({
        selectOptions: []
    });

    useEffect(async () => {
        if (config.screenColumnControlType.toLowerCase() === 'select') {
            if (config.screenColumnControlValueApiEndPoint) {
                let res = await getApiData(config.screenColumnControlValueApiEndPoint);
                if (res.success) {
                    setLocalState((st) => ({
                        ...st,
                        selectOptions: res?.data?.list
                    }));
                }
                if (controlName === 'colourId' || controlName === 'vehicleTypeId') {
                    pullDropDownList(controlName, res?.data?.list);
                }
                // });
            } else if (config.apiEndPointData) {
                setLocalState((st) => ({ ...st, selectOptions: config.apiEndPointData }));
            }
        }
    }, []);

    let liClass = [`li-${config.screenColumnControlType?.toLowerCase()}`];
    hidden && liClass.push('d-none');
    config.sectionCssClasses && liClass.push(config.sectionCssClasses);
    !hidden && req && liClass.push('child-required');

    function onLocalControlChange(e) {
        onFieldChange(e, config);
    }
    function onsignControl(val) {
        onSignatureUpdate(val, config);
    }

    return (
        <Grid container>
            <Grid item xs>
                <FormLabel component="legend" error={config.screenColumnRequired ? true : false} style={{ textAlign: 'left' }}>
                    {config.screenColumnLabel}
                    {minMaxString && <small className="validation-text">{minMaxString}</small>}
                </FormLabel>
            </Grid>

            {config.screenColumnControlType.toLowerCase() === 'textbox' && (
                <TextBox
                    type="text"
                    autoFocus={autoFocus}
                    style={config.inlineStyle && JSON.parse(config.inlineStyle)}
                    className={`form-control ${config.cssClasses || ''}`}
                    name={controlName}
                    value={controlValue}
                    onChange={onLocalControlChange}
                    disabled={readOnly}
                    autoComplete={controlName}
                />
            )}
            {config.screenColumnControlType.toLowerCase() === 'email' && (
                <TextBox
                    type="email"
                    autoFocus={autoFocus}
                    style={config.inlineStyle && JSON.parse(config.inlineStyle)}
                    className={`form-control ${config.cssClasses || ''}`}
                    name={controlName}
                    minLength={min}
                    maxLength={max}
                    value={controlValue}
                    onChange={onLocalControlChange}
                    disabled={readOnly}
                    autoComplete={controlName}
                />
            )}
            {config.screenColumnControlType.toLowerCase() === 'number' && (
                <TextBox
                    type="number"
                    config={config}
                    state={state}
                    autoFocus={autoFocus}
                    name={controlName}
                    minLength={min}
                    maxLength={max}
                    value={controlValue}
                    onChange={onLocalControlChange}
                    onFieldChange={onLocalControlChange}
                    disabled={readOnly}
                />
            )}
            {config.screenColumnControlType.toLowerCase() === 'money' && (
                <TextBox
                    type="number"
                    allowDecimal={true}
                    config={config}
                    state={state}
                    autoFocus={autoFocus}
                    name={controlName}
                    minLength={min}
                    maxLength={max}
                    value={controlValue}
                    onChange={onLocalControlChange}
                    disabled={readOnly}
                ></TextBox>
            )}
            {config.screenColumnControlType.toLowerCase() === 'password' && (
                <TextBox
                    type="password"
                    autoFocus={autoFocus}
                    style={config.inlineStyle && JSON.parse(config.inlineStyle)}
                    className={`form-control ${config.cssClasses || ''}`}
                    name={controlName}
                    minLength={min}
                    maxLength={max}
                    value={controlValue}
                    onChange={onLocalControlChange}
                    disabled={readOnly}
                />
            )}
            {config.screenColumnControlType.toLowerCase() === 'textarea' && (
                <TextBox
                    type="text"
                    multiline
                    rows={3}
                    autoFocus={autoFocus}
                    style={config.inlineStyle && JSON.parse(config.inlineStyle)}
                    className={`form-control ${config.cssClasses || ''}`}
                    minLength={min}
                    maxLength={max}
                    name={controlName}
                    value={controlValue}
                    onChange={onLocalControlChange}
                    disabled={readOnly}
                />
            )}
            {config.screenColumnControlType.toLowerCase() === 'select' && (
                <SelectBox
                    List={localState.selectOptions}
                    style={{ marginTop: 8, textAlign: 'left' }}
                    name={controlName}
                    value={controlValue}
                    onChange={onLocalControlChange}
                    disabled={readOnly}
                />
            )}
            {config.screenColumnControlType.toLowerCase() === 'datetime' && (
                <DateTimePicker
                    type="datetime-local"
                    autoFocus={autoFocus}
                    name={controlName}
                    value={controlValue}
                    onChange={onLocalControlChange}
                    disabled={readOnly}
                />
            )}

            {config.screenColumnControlType.toLowerCase() === 'date' && (
                <DatePicker
                    autoFocus={autoFocus}
                    config={config}
                    state={state}
                    name={controlName}
                    value={moment(controlValue).format('YYYY-MM-DD')}
                    onChange={onLocalControlChange}
                    disabled={readOnly}
                    style={{ marginTop: 8 }}
                />
            )}
            {config.screenColumnControlType.toLowerCase() === 'checkbox' && (
                <Grid item xs={12} style={{ textAlign: 'left', margin: '5px 8px' }}>
                    <IosSwitch onChange={onLocalControlChange} name={controlName} checked={controlValue} />
                </Grid>
            )}
            {config.screenColumnControlType.toLowerCase() === 'autosuggest' && (
                <AutoSuggest
                    config={config}
                    autoFocus={autoFocus}
                    state={state}
                    onFieldChange={onLocalControlChange}
                    otherConfig={otherConfig}
                    pullPatchData={pullPatchData}
                    disabled={readOnly}
                />
            )}
            {config.screenColumnControlType.toLowerCase() === 'data-puller' && (
                <DataPuller
                    autoFocus={autoFocus}
                    config={config}
                    state={state}
                    onFieldChange={onLocalControlChange}
                    screenState={screenState}
                    pullPatchData={pullPatchData}
                    readOnly={readOnly}
                />
            )}
            {config?.screenColumnControlType?.toLowerCase() === 'damage-faces' && (
                <Grid item xs={12}>
                    <VehFaceComponent
                        autoFocus={autoFocus}
                        screenState={screenState}
                        config={config}
                        state={state}
                        onFieldChange={onLocalControlChange}
                        readOnly={readOnly}
                    />
                </Grid>
            )}
            {config.screenColumnControlType.toLowerCase() === 'photo-uploader' && (
                <Grid item xs={12}>
                    <VehiclePhotoUploader autoFocus={autoFocus} config={config} state={state} onFieldChange={onLocalControlChange} readOnly={readOnly} />
                </Grid>
            )}
            {config.screenColumnControlType.toLowerCase() === 'sign-pad' && (
                <Grid item xs={12}>
                    <UserSignature
                        autoFocus={autoFocus}
                        config={config}
                        state={state}
                        onFieldChange={onsignControl}
                        readOnly={readOnly}
                        // readOnly={false}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default FormControl;
