import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './wipHours.scss';
import { Grid, Typography } from '@material-ui/core';

const GraphCell = withStyles((theme) => ({
    head: {
        fontSize: 12,
        border: '1px solid #f9f9f9',
        padding: 4,
        backgroundColor: '#E31C48',
        textAlign: 'center',
        color: theme.palette.common.white
    },
    body: {
        fontSize: 12,
        border: '1px solid #f9f9f9',
        padding: 4,
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.03)',
        // textAlign: 'center',
        color: theme.palette.common.black
    }
}))(TableCell);

const EmptyGraphCell = withStyles((theme) => ({
    head: {
        fontSize: 14,
        // border: '1px solid #f9f9f9',
        padding: 6,
        backgroundColor: '#f9f9f9',
        textAlign: 'center',
        color: '#f9f9f9'
    },
    body: {
        fontSize: 14,
        border: '1px solid #f9f9f9',
        padding: 6,
        backgroundColor: 'white',
        // textAlign: 'center',
        color: theme.palette.common.black
    }
}))(TableCell);

const useStyles = makeStyles({
    table: {
        width: '100%'
    },
    container: {
        // maxHeight: 200
    }
});

export const WipGraphTable = (props) => {
    const [state, setState] = useState({
        index: 0
    });

    const handleIndex = (index) => {
        setState((st) => ({ ...st, index: index }));
    };
    const temp = [];
    const newtemp = [];

    props.data.forEach((q) => {
        let date = newtemp.findIndex((t) => t.date == q.date);
        if (date > -1) {
            newtemp[date]['monthTotal'] += q.hours;
        } else {
            newtemp.push({
                date: q.date,
                monthTotal: q.hours
            });
        }
    });
    props.data.forEach((q) => {
        let isIDExist = temp.findIndex((t) => t.branchID == q.branchID);
        if (isIDExist > -1) {
            temp[isIDExist].data.push({ date: q.date, hours: q.hours });
        } else {
            temp.push({
                branchID: q.branchID,
                branch: q.branch,
                data: [{ date: q.date, hours: q.hours }]
            });
        }
    });

    const classes = useStyles();

    return (
        <TableContainer className={`custom-scroll ${classes.container}`}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <EmptyGraphCell style={{ visibility: 'hidden' }}>branch</EmptyGraphCell>
                        {temp[0]?.data.map((m, i) => {
                            return (
                                <>
                                    <GraphCell>{m.date}</GraphCell>
                                    {/* <GraphCell width="10%">Hours</GraphCell> */}
                                </>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <GraphCell align="center" style={{ background: '#D3D3D3', fontWeight: 900 }}>
                            Total
                        </GraphCell>
                        {newtemp?.map((m, index) => {
                            return (
                                <>
                                    <GraphCell align="center" style={{ background: '#D3D3D3', fontWeight: 750 }}>
                                        {m.monthTotal}
                                    </GraphCell>
                                </>
                            );
                        })}
                    </TableRow>
                    {temp?.map((m, index) => {
                        return (
                            <TableRow onClick={() => handleIndex(index)}>
                                <GraphCell align="center" style={{ background: index === state.index ? '#E49D93' : 'white' }}>
                                    {m.branch}
                                </GraphCell>

                                {m?.data.map((n, i) => {
                                    return (
                                        <>
                                            {/* <GraphCell width="5%" align="center">
                                                {n.date}
                                            </GraphCell> */}
                                            <GraphCell align="center" style={{ background: index === state.index ? '#E49D93' : 'white' }}>
                                                {n.hours}
                                            </GraphCell>
                                        </>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

// export const WipTiles = (props) => {
//     const temp = [];
//     props.data.forEach((q) => {
//         let isIDExist = temp.findIndex((t) => t.branchID == q.branchID);
//         if (isIDExist > -1) {
//             temp[isIDExist].data.push({ date: q.date, hours: q.hours });
//         } else {
//             temp.push({
//                 branchID: q.branchID,
//                 branch: q.branch,
//                 data: [{ date: q.date, hours: q.hours }]
//             });
//         }
//     });

//     return (
//         <div>
//             {temp.map((m, i) => {
//                 return (
//                     <Grid container spacing={2} alignContent="center" alignItems="center" style={{ border: '1px solid' }}>
//                         <Grid item xs={2} style={{ padding: '10px' }}>
//                             <Typography variant="h6"> {m.branch}</Typography>
//                         </Grid>
//                         <Grid item xs={10} style={{ padding: '10px' }}>
//                             <Grid container spacing={1} justifyContent="space-around" alignContent="center" alignItems="center">
//                                 {m.data.map((n) => {
//                                     return (
//                                         <>
//                                             <Grid item>
//                                                 <Typography variant="h6">{n.date}</Typography>
//                                                 {n.hours}
//                                             </Grid>
//                                         </>
//                                     );
//                                 })}
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 );
//             })}
//         </div>
//     );
// };
