import { useEffect } from 'react';
import { useState } from 'react';
import { FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../../../../Core/FormInput';
import { postJobProgressApprovedByCustomer } from '../../../../../../Core/Service/communicator-service';
import { useContext } from 'react';
import AppContext from '../../../../../../App/AppContext';
import { singleEmailValidation } from '../../../../../../Core/Service/StaticService';
import { CustomeSignPad } from '../../../../../../Core/SignaturePad';

const ApprovedByCustomerModal = (props) => {
    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({
        messaegList: [],
        actionLog: false,
        editActionLog: false,
        selectedStatus: props.selectedStatus,
        orderNumber: '',
        signature: '',
        errors: {}
    });

    useEffect(async () => {
        setState((st) => ({ ...st, logText: props.smsText, isCustomerUpdate: props.sendSMSUpdate, isUpdateSms: props.sendSMSUpdate }));
    }, [props]);

    const handleInput = (e) => {
        const { name, value, type, checked } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const validation = () => {
        let { notes, isCustomerUpdate, isCustomerEmail, isUpdateSms, isPhoneNo, orderNumber, signature } = state;
        let isValid = true;
        let errors = {};
        // if (!notes) {
        //     isValid = false;
        //     errors.notes = 'Notes is required';
        // }
        if (!orderNumber) {
            isValid = false;
            errors.orderNumber = 'Order Number is required';
        }
        if (!signature) {
            isValid = false;
            errors.signature = 'Signature is required';
        }
        if (isCustomerUpdate && !singleEmailValidation(isCustomerEmail)) {
            isValid = false;
            errors.isCustomerEmail = 'Please Add Customer Email';
        }
        if (isUpdateSms && !isPhoneNo) {
            isValid = false;
            errors.isPhoneNo = 'Please Add Customer Email';
        }

        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            let data = {
                headerRecordID: props.headerRecordID,
                notes: '',
                status: +state.selectedStatus,
                logText: state.logText,
                isPhoneNo: state.isPhoneNo,
                isCustomerEmail: state.isCustomerEmail,
                orderNumber: state.orderNumber,
                signature: state.signature
            };
            let res = await postJobProgressApprovedByCustomer(data);
            if (res.success) {
                showToast(`Approved By Customer Status Added Successfully`);
                props.onClose(true);
            } else {
                showToast(`Approved By Customer Status Failed To Add`);
            }
        }
    };

    const GetTechnicianSign = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };
    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            storedSignature: null,
            signature: null
        }));
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography color="secondary">Approval Order Number</Typography>
                    <TextBox
                        placeholder="Approval Order Number"
                        type="text"
                        name="orderNumber"
                        variant="outlined"
                        fullWidth
                        value={state.orderNumber}
                        onChange={handleInput}
                    />
                    <FormHelperText error>{state.errors.orderNumber}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="secondary">Customer Message</Typography>
                    <TextBox
                        placeholder="Message"
                        multiline
                        rows={3}
                        type="text"
                        name="logText"
                        variant="outlined"
                        fullWidth
                        value={state.logText}
                        onChange={handleInput}
                    />
                    <FormHelperText error>{state.errors.logText}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={8}>
                    <InputLabel shrink>
                        <input type="checkbox" style={{ cursor: 'pointer' }} name="isCustomerUpdate" onChange={handleInput} checked={state.isCustomerUpdate} />
                        Customer Email
                    </InputLabel>
                    <TextBox
                        placeholder="Email update to customer"
                        type="email"
                        name="isCustomerEmail"
                        variant="outlined"
                        fullWidth
                        value={state.isCustomerEmail}
                        onChange={handleInput}
                        error={state.isCustomerUpdate}
                    />
                    <FormHelperText error>{state.errors.isCustomerEmail}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel shrink>
                        <input type="checkbox" style={{ cursor: 'pointer' }} onChange={handleInput} name="isUpdateSms" checked={state.isUpdateSms} />
                        Phone Number
                    </InputLabel>
                    <TextBox
                        placeholder="Phone Number"
                        type="tel"
                        name="isPhoneNo"
                        variant="outlined"
                        fullWidth
                        value={state.isPhoneNo}
                        onChange={handleInput}
                        error={state.isUpdateSms}
                    />
                    <FormHelperText error>{state.errors.isPhoneNo}</FormHelperText>
                </Grid>
                {/* <Grid item xs={12}>
                    <InputLabel shrink>Notes</InputLabel>
                    <TextBox type="text" multiline rows={5} name="notes" value={state.notes} variant="outlined" fullWidth onChange={handleInput} />
                    <FormHelperText error>{state.errors.notes}</FormHelperText>
                </Grid> */}
                <Grid item xs={12}>
                    <CustomeSignPad onChange={GetTechnicianSign} content={'Signature'} sign={state.storedSignature} name="signature" clearSig={clearSig} />
                    <FormHelperText error>{state.errors.signature}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton className="Submitbtn" fullWidth onClick={handleSubmit}>
                        Approved By Customer
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default ApprovedByCustomerModal;
