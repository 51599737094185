import { Button, CircularProgress, DialogContent, Grid, Tooltip, FormLabel, IconButton, Box, FormControlLabel } from '@material-ui/core';
import { TextBox, CustomButton, SecondaryButton, AppButtonGroup, UploadWithBadge, SecondaryCheckbox } from '../../../Core/FormInput';
import React, { useEffect, useContext } from 'react';
import AppContext from '../../../App/AppContext';
import { DefectProvider, DefectScreenContext } from './defectProvider';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

import './defect.scss';
import { AppStorage } from '../../../Core/Service/storage-service';
import MultiImageSliderModal from '../workflow-job/DynamicForm/MultiImageSliderModal';
import DialogComp from '../../../Core/Modal/dialogModal';
import formatters from '../../../Core/Grid/inputFormatter';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { some } from 'lodash';
import { fontWeight } from '@material-ui/system';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: 8,
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: 6
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    table: {
        minWidth: '100%'
    },
    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)',
        minWidth: '1000px'
    },

    checkContainer: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0px',
            marginRight: '0px'
        }
    }
}));

const GlobalCss = withStyles({
    '@global': {
        '.MuiInputBase-input.Mui-disabled': {
            color: 'black'
            // fontWeight: 'bold !important'
        }
    }
})(() => null);

const AddDefects = (props) => {
    const {
        fromStep,
        state,
        GetTeamleaderSign,
        handleSubmit,
        handleInputChange,
        inputList,
        handleBtn,
        clearLeaderSign,
        isInternal,
        showModal,
        closeDialog,
        docAction,
        uploadFiles,
        onImageRemove,
        onCloseDocModal,
        handleCheck
    } = useContext(DefectScreenContext);
    const classes = useStyles();

    const { hideTopNavAndMenu } = useContext(AppContext);
    let hideDocCreatedValue = AppStorage.getCanHideDocumentCreation();

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const history = useHistory();

    const [WindowWidths, WindowHeights] = useWindowSize();

    const isDisabled = () => {
        return isInternal ? !AppStorage.getCanAmendToDoList() : true;
    };
    const isReadonly = !some(inputList, (r) => !r.defectActionedUserSignature);

    const showSubmit = state.defects.length > 0 ? true : false;
    return (
        <div style={{ width: '100%' }}>
            <GlobalCss />
            <DialogContent className="mt-11">
                <Grid container spacing={2}>
                    <Grid item xs={9} sm={9} style={{ fontSize: 21, textAlign: 'left' }}>
                        Reg. No:&nbsp; <b> {state.headerRecords.registration} </b>&nbsp; WIP No:&nbsp; <b> {state.headerRecords.wipNumber}</b>
                    </Grid>
                    <Grid item sm={12}></Grid>
                    {state.showCircularLoader ? (
                        <Grid item sm={12}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            {/* {state.technicians.map((m) => (
                                <div className="tech-btn-group">
                                    <Button className={state.activeUser == m.userID ? 'active-btn' : 'all-btn'} onClick={() => TechBtnClick(m.userID)}>
                                        {m.technicianName}
                                    </Button>
                                </div>
                            ))} */}
                            {showSubmit && (
                                <>
                                    <Box width="100%" />
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table" className={classes.paper}>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="left" width="1%">
                                                            {!isReadonly ? (
                                                                <FormControlLabel
                                                                    control={
                                                                        <SecondaryCheckbox
                                                                            checked={state?.selectAll}
                                                                            onChange={(e) => handleCheck(e, 'all')}
                                                                            name="select all"
                                                                        />
                                                                    }
                                                                    label="Sign"
                                                                />
                                                            ) : (
                                                                'Sign'
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" width="23%">
                                                            Defect
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" width="10%">
                                                            Required before MOT test?
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" width="15.3%">
                                                            Serviceable
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" width="5%">
                                                            Service By
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" width="20%">
                                                            Action
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" width="5%"></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {inputList.map((x, i) => {
                                                        return (
                                                            <StyledTableRow>
                                                                <StyledTableCell align="left" className={classes.checkContainer}>
                                                                    {x.defectActionedUserSignature ? (
                                                                        <Tooltip title="View Signature" aria-label="add">
                                                                            <div
                                                                                onClick={() => {
                                                                                    showModal(x, 'Actioned');
                                                                                }}
                                                                                style={{ cursor: 'pointer', marginTop: 10 }}
                                                                            >
                                                                                <img
                                                                                    src={x.defectActionedUserSignature}
                                                                                    width="70px"
                                                                                    style={{ display: 'table-cell', verticalAlign: 'middle' }}
                                                                                />
                                                                            </div>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <SecondaryCheckbox
                                                                                    disabled={x?.defectActionedUserSignature}
                                                                                    checked={x?.checkDefectCreatedUserSignature}
                                                                                    onChange={(e) => handleCheck(e, i)}
                                                                                    value={x?.checkDefectCreatedUserSignature}
                                                                                    name="checkDefectCreatedUserSignature"
                                                                                />
                                                                            }
                                                                        />
                                                                    )}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left" className={classes.textBold}>
                                                                    <TextBox
                                                                        name="defectDescription"
                                                                        value={x.defectDescription}
                                                                        onChange={(e) => handleInputChange(e, i)}
                                                                        disabled={x.defectActionedUserSignature}
                                                                        placeholder="Defect Description"
                                                                    />
                                                                    <span className="mandatoryfields">{state.errors['defectDescription']}</span>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <SecondaryCheckbox
                                                                                checked={x?.isRequiredBeforeMOTTest}
                                                                                // onChange={(e) => handleCheck(e, i)}
                                                                                value={x?.isRequiredBeforeMOTTest}
                                                                                name="isRequiredBeforeMOTTest"
                                                                                disabled={true}
                                                                            />
                                                                        }
                                                                    />
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    {state.defectReportedCategories && (
                                                                        <AppButtonGroup
                                                                            value={x.defectReportedCategoryID}
                                                                            options={state.defectReportedCategories}
                                                                            valuePropertyName="defectReportedCategoryID"
                                                                            textPropertyName="defectReportedCategoryDescription"
                                                                            disabled={true}
                                                                        />
                                                                    )}
                                                                    <span className="mandatoryfields">{x.err_desc}</span>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left" width="5%">
                                                                    <Tooltip title="View Signature" aria-label="add">
                                                                        <div
                                                                            onClick={() => {
                                                                                showModal(x, 'Service');
                                                                            }}
                                                                            style={{ cursor: 'pointer' }}
                                                                        >
                                                                            <img
                                                                                src={x.defectSignature}
                                                                                width="70px"
                                                                                style={{ display: 'table-cell', verticalAlign: 'middle' }}
                                                                            />
                                                                        </div>
                                                                    </Tooltip>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    {state.defectActionedCategories && (
                                                                        <AppButtonGroup
                                                                            name="defectActionedCategoryID"
                                                                            value={x.defectActionedCategoryID}
                                                                            onChange={(e) => handleBtn(e, i)}
                                                                            options={state.defectActionedCategories}
                                                                            valuePropertyName="defectActionedCategoryID"
                                                                            textPropertyName="defectActionedCategoryDescription"
                                                                            disabled={x.defectActionedUserSignature}
                                                                        />
                                                                    )}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    <Tooltip title="Upload Image" aria-label="add">
                                                                        <UploadWithBadge
                                                                            count={x?.documentsCount || 0}
                                                                            onClick={() => docAction(x, i)}
                                                                            toolTipTitle="Upload Documents"
                                                                            style={{ color: 'red', margin: '-8px 0px -8px 0px', padding: 1 }}
                                                                        />
                                                                    </Tooltip>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>

                                    {!isReadonly ? (
                                        <>
                                            <Grid item xs={12} sm={12} lg={12} style={{ position: 'relative' }}>
                                                <CustomeSignPad
                                                    onChange={GetTeamleaderSign}
                                                    content={
                                                        <FormLabel component="legend" className="mandatoryfields">
                                                            Leader Signature
                                                        </FormLabel>
                                                    }
                                                    sign={state.imgteamleadersign}
                                                    clearSig={clearLeaderSign}
                                                    name="teamleadersign"
                                                />
                                                <span className="mandatoryfields">{state.errors['teamleadersign']}</span>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <SecondaryButton fullWidth onClick={() => handleSubmit('save')} disabled={isDisabled()}>
                                                    Save
                                                </SecondaryButton>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <SecondaryButton fullWidth onClick={() => handleSubmit('submit')} disabled={isDisabled()}>
                                                    Sign-Off
                                                </SecondaryButton>
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={12} sm={12}>
                                            <SecondaryButton fullWidth onClick={() => history.goBack()}>
                                                Cancel
                                            </SecondaryButton>
                                        </Grid>
                                    )}
                                </>
                            )}
                            {!showSubmit && (
                                <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '80vh' }}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography color="secondary" variant="h2" component="h2">
                                            No defects reported by technician on this job
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </DialogContent>

            {state.showSignature ? (
                <DialogComp title="Signature" maxWidth="sm" onClose={closeDialog}>
                    <Grid container spacing={1}>
                        <Grid item container justifyContent="flex-end">
                            Signed by &nbsp;
                            {state?.signedBy}
                            {!hideDocCreatedValue && (
                                <>
                                    &nbsp; on &nbsp;
                                    {formatters.DateTimeFormatter(state?.signedDate)}
                                </>
                            )}
                        </Grid>
                        <Grid item>
                            <img src={state?.sign} alt="my signature" style={{ border: '1px solid', height: 178, width: '100%' }} />
                        </Grid>
                    </Grid>
                </DialogComp>
            ) : null}
            {state.showDoc && (
                <MultiImageSliderModal
                    imageList={state.sortedImgList || []}
                    selectedFieldData={state.selectedFieldData || []}
                    onImageUpload={uploadFiles}
                    onImageRemove={onImageRemove}
                    isDelete={!state.selectedDefect?.defectActionedUserSignature}
                    disabled={state.selectedDefect?.defectActionedUserSignature}
                    isLoading={false}
                    isUploading={state.isUploading}
                    onClose={onCloseDocModal}
                />
            )}
        </div>
    );
};

const Screen = (props) => {
    return (
        <DefectProvider {...props}>
            <AddDefects {...props} />
        </DefectProvider>
    );
};
export default Screen;
