import { Button, ButtonGroup, FormControlLabel, FormLabel, Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { SecondaryCheckbox, TextBox } from '../../../../Core/FormInput';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DiagnosticSheetsImageComponent from '../imageSlider';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    table: {
        minWidth: '100%'
    },

    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    },
    paper: {
        padding: '7px 16px 16px 16px',
        // color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)',
        width: '100%'
        // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
    },
    axelTitles: {
        textAlign: 'center',
        color: '#f50057',
        width: '100%'
    },
    headings: {
        color: '#f50057',
        margin: 0
        // marginLeft: -4,
        // marginBottom: 20
    }
}));

const SectionFour = (props) => {
    const { state, handleFieldChange, handleCheckbox, handleYesNo, upload, handleRemoveClick } = props;
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <Grid container xs={12}>
                <h3 className={classes.headings}>Section 4: Electronics, Controls and Audio</h3>
            </Grid>
            <hr></hr>
            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                    <FormLabel>Description of concern</FormLabel>
                    <TextBox
                        multiline
                        rows={5}
                        value={state.diagnosticSheetElectronicsConcern}
                        onChange={handleFieldChange}
                        name="diagnosticSheetElectronicsConcern"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Malfunction:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                {state.malfunction.map((c) => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                            <FormControlLabel
                                                control={
                                                    <SecondaryCheckbox checked={c.checked} onChange={handleCheckbox('malfunction', c.id)} name={`${c.name}`} />
                                                }
                                                label={`${c.name}`}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>{`Other (specify)`}:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextBox
                                        multiline
                                        rows={5}
                                        value={state.diagnosticSheetElectronicsSpecification}
                                        onChange={handleFieldChange}
                                        name="diagnosticSheetElectronicsSpecification"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SectionFour;
