import { Grid, Typography, styled } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'white',
        color: 'black',
        border: 'none',
        padding: '5px !important'
    },
    body: {
        fontSize: 14,
        // padding: '8px'
        padding: '5px !important'
    }
}))(TableCell);

const StyledBlankTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'white',
        color: 'white',
        border: 'none !important',
        padding: '5px !important'
    },
    body: {
        fontSize: 14,
        // padding: '8px'
        padding: '5px !important'
    }
}))(TableCell);

const useStyles = makeStyles({
    table: {
        minWidth: 700
    }
});

const TableColRender = ({ value, array }) => {
    return array.map((row) => (
        <StyledTableCell component="th" scope="row" align="center">
            {row[value]}
        </StyledTableCell>
    ));
};

const InvoicedSaleProjection = ({ data }) => {
    const classes = useStyles();

    return (
        <div>
            {data.length > 0 ? (
                <Grid Container>
                    <Grid item xs={12} style={{ height: 300 }}>
                        <ResponsiveContainer style={{ height: 500 }}>
                            <ComposedChart
                                width={500}
                                data={data}
                                margin={{
                                    top: 20,
                                    right: 80,
                                    bottom: 20,
                                    left: 40
                                }}
                            >
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis dataKey="name" style={{ fontSize: 12 }} />
                                <YAxis
                                    style={{ fontSize: 12 }}
                                    //  label={{ value: 'Invoiced Vehicle Volumes', angle: -90, position: 'left' }}
                                />
                                <Tooltip />

                                <Bar dataKey="Bt" barSize={20} fill="#e1341e" />
                                <Bar dataKey="AWt" barSize={20} fill="#1e4ee1" />
                                <Line type="Awytd" dot={false} dataKey="AWytd" stroke="#1e4ee1" strokeWidth={4} />
                                <Line type="Bytd" dot={false} dataKey="Bytd" stroke="#e1341e" strokeDasharray="5 5" strokeWidth={4} />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledBlankTableCell></StyledBlankTableCell>
                                        {data.map((k, i) => {
                                            return (
                                                <StyledTableCell key={i} align="center">
                                                    {k.name}
                                                </StyledTableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledBlankTableCell width="10%">
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ background: '#e1341e', width: 30 }}>&nbsp;&nbsp;&nbsp;</div>
                                                <div>&nbsp;</div>
                                                <div> Budget Total</div>
                                            </div>
                                        </StyledBlankTableCell>
                                        <TableColRender array={data || []} value={'Bt'} />
                                    </TableRow>
                                    <TableRow>
                                        <StyledBlankTableCell>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ background: '#1e4ee1', width: 30 }}>&nbsp;&nbsp;&nbsp;</div>
                                                <div>&nbsp;</div>
                                                <div> Actual/Wip Total</div>
                                            </div>
                                        </StyledBlankTableCell>
                                        <TableColRender array={data || []} value={'AWt'} />
                                    </TableRow>
                                    <TableRow>
                                        <StyledBlankTableCell>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ borderBottom: '5px  dotted #e1341e', width: 30, marginBottom: 8 }}></div>
                                                <div>&nbsp;</div>
                                                <div> Budget YTD</div>
                                            </div>
                                        </StyledBlankTableCell>
                                        <TableColRender array={data || []} value={'Bytd'} />
                                    </TableRow>
                                    <TableRow>
                                        <StyledBlankTableCell>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ borderBottom: '5px  solid #1e4ee1', width: 30, marginBottom: 8 }}></div>
                                                <div>&nbsp;</div>
                                                <div> Actual/Wip YTD</div>
                                            </div>
                                        </StyledBlankTableCell>
                                        <TableColRender array={data || []} value={'AWytd'} />
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            ) : (
                <Grid Container justifyContent="center">
                    <Grid item>No Record Found</Grid>
                </Grid>
            )}
        </div>
    );
};

export default InvoicedSaleProjection;
