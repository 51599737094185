import React, { useEffect, useContext } from 'react';
import { TyreChecksContaxt, TyreCheckProvider } from './tyreChecksProvider';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { TextBox, SecondaryButton, CustomButton, AppButtonGroup } from '../../../../Core/FormInput';
import AppContext from '../../../../App/AppContext';
import { Button, CircularProgress, DialogContent, Grid, Tooltip, FormLabel, IconButton, InputLabel, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import SignaturePad from 'react-signature-canvas';
import { SingleAxle, CustomAxle, EditAxle } from './tyreAxle';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';

const TyreChecks = () => {
    const {
        state,
        fromStep,
        inputList,
        handleAddClick,
        handleRemoveClick,
        handleInputChange,
        handleSliderChange,
        useDefaultSig,
        signCanvas,
        GetTechnicianSign,
        clear,
        YesNoRadio,
        handleInputs,
        inputChange,
        handleSubmit
    } = useContext(TyreChecksContaxt);
    const { hideModal, showToast, hideTopNavAndMenu } = useContext(AppContext);
    const [WindowWidths, WindowHeights] = useWindowSize();

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);
    const history = useHistory();
    return (
        <div style={{ width: '90%', marginRight: 'auto', marginLeft: 'auto' }}>
            <br />
            <DialogContent className="mt-11">
                <Grid container spacing={1}>
                    <Grid item xs={9} sm={9} style={{ fontSize: 21, textAlign: 'left' }}>
                        <Grid container spacing={1} justifyContent="flex-start">
                            <Grid item>
                                {' '}
                                Registration Number:&nbsp; <b>{state.registration}</b>
                            </Grid>
                            <Grid item>
                                {' '}
                                WIP No:&nbsp; <b>{state.wipNumber}</b>
                            </Grid>
                            <Grid item>
                                Company :&nbsp;
                                <b>{state.customerName}</b>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                        {/* <Typography variant="h6" color="secondary">
                            Size and Type of Tyre
                        </Typography> */}
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <FormLabel component="legend">Size & Type of Tyre (Front)</FormLabel>
                            <TextBox
                                onChange={inputChange}
                                name="tyreConditionFrontTyreSize"
                                inputProps={{ maxLength: 20, tabIndex: '1' }}
                                value={state.tyreConditionFrontTyreSize}
                                placeholder="Front"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <FormLabel component="legend">Size & Type of Tyre (Rear)</FormLabel>
                            <TextBox
                                onChange={inputChange}
                                name="tyreConditionRearTyreSize"
                                inputProps={{ maxLength: 20, tabIndex: '2' }}
                                value={state.tyreConditionRearTyreSize}
                                placeholder="Rear"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <FormLabel component="legend"> Date of Tachograph (Last Callibration)</FormLabel>
                            <TextBox
                                type="date"
                                onChange={inputChange}
                                name="tyreConditionDateOfTachoGraph"
                                // value={moment(state.tyreConditionDateOfTachoGraph).format('yyyy-mm-dd')}
                                value={state.tyreConditionDateOfTachoGraph}
                                inputProps={{ tabIndex: '3' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={5}>
                            <FormLabel component="legend">Windscreen Condition Okay?</FormLabel>
                            <Grid container>
                                <Grid item xs={6} sm={5} md={6} lg={3}>
                                    <YesNoRadio
                                        inputProps={{ tabIndex: '4' }}
                                        onChange={inputChange}
                                        name="tyreConditionWindscreenCondition"
                                        value={state.tyreConditionWindscreenCondition}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={7} md={6} lg={9}>
                                    <TextBox
                                        placeholder="Description"
                                        name="tyreConditionWindscreenConditionDescription"
                                        value={state.tyreConditionWindscreenConditionDescription}
                                        onChange={inputChange}
                                        error={!state.tyreConditionWindscreenCondition ? true : false}
                                        inputProps={{ tabIndex: '5' }}
                                    />
                                    <span className="mandatoryfields">{state.errors?.message}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={4} xs={12} style={{ marginBottom: '5px' }} justify="space-evenly">
                        <SingleAxle onChange={WindowWidths > 1280 ? () => handleInputs(1) : (nameID) => handleSliderChange(1, nameID)} state={state} axNO={1} />
                        <CustomAxle
                            axleTitle={'AXLE 2'}
                            onChange={WindowWidths > 1280 ? () => handleInputs(2) : (nameID) => handleSliderChange(2, nameID)}
                            axNO={2}
                            state={state}
                            tabIndexMultiplyer={20}
                        />
                        <CustomAxle
                            axleTitle={'AXLE 3'}
                            onChange={WindowWidths > 1280 ? () => handleInputs(3) : (nameID) => handleSliderChange(3, nameID)}
                            axNO={3}
                            state={state}
                            tabIndexMultiplyer={30}
                        />
                        <CustomAxle
                            axleTitle={'AXLE 4'}
                            onChange={WindowWidths > 1280 ? () => handleInputs(4) : (nameID) => handleSliderChange(4, nameID)}
                            axNO={4}
                            state={state}
                            tabIndexMultiplyer={40}
                        />
                    </Grid>
                    <Grid item container>
                        {inputList?.map((x, i) => (
                            <Grid container item spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <FormLabel component="legend">Defect</FormLabel>
                                    <TextBox
                                        name="tyreConditionDefectDescription"
                                        placeholder="Defect"
                                        value={x.tyreConditionDefectDescription}
                                        onChange={(e) => handleInputChange(e, i)}
                                        InputProps={{
                                            tabIndex: '60',
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <>
                                                        {inputList.length !== 1 && (
                                                            <RemoveCircleIcon
                                                                style={{ cursor: 'pointer' }}
                                                                color="primary"
                                                                onClick={() => handleRemoveClick(i)}
                                                            />
                                                        )}
                                                        {inputList.length - 1 === i && (
                                                            <AddBoxIcon color="secondary" onClick={handleAddClick} style={{ cursor: 'pointer' }} />
                                                        )}
                                                    </>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormLabel
                            component="legend"
                            style={{
                                marginBottom: '9px',
                                marginTop: '5px'
                            }}
                        >
                            Technician Signature &nbsp;
                            {!state.imgtechniciansign && <CustomButton icon={BorderColorIcon} onClick={useDefaultSig} toolTipTitle="Use default signature" />}
                            &nbsp;
                            {!state.imgtechniciansign && <CustomButton icon={DeleteIcon} onClick={clear} IconColor="gray" toolTipTitle="Remove signature" />}
                        </FormLabel>
                        {state.imgtechniciansign ? (
                            <img
                                src={state.imgtechniciansign}
                                alt="my signature"
                                style={{
                                    display: 'block',
                                    margin: '0 auto',
                                    border: '1px solid black',
                                    width: '100%',
                                    height: '100px'
                                }}
                            />
                        ) : (
                            <>
                                <SignaturePad
                                    ref={signCanvas}
                                    onEnd={() => {
                                        GetTechnicianSign();
                                    }}
                                    canvasProps={{
                                        className: 'signatureCanvas'
                                    }}
                                />
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SecondaryButton fullWidth onClick={handleSubmit}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SecondaryButton fullWidth onClick={() => history.goBack()}>
                            Cancel
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </div>
    );
};

const Screen = (props) => {
    return (
        <TyreCheckProvider {...props}>
            <TyreChecks {...props} />
        </TyreCheckProvider>
    );
};
export default Screen;
