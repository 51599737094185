import React, { useContext, useState, useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import AppContext from "../../../App/AppContext";
import { SecondaryButton, PrimaryButton } from "../../../Core/FormInput";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { DeleteStandardWriteUp } from "../../../Core/Service/techWriteUp-service";
import Typography from "@material-ui/core/Typography";

const DeleteStandardWriteUpModal = (props) => {
    const { hideModal,showToast } = useContext(AppContext);
    const [state, setState] = useState({
        StandardWriteUpName: "",
    });
    const StandardWriteUpID = props.StandardWriteUpID;
    const name = props.StandardWriteUpDetails;
    useEffect(async () => {
        setState((st) => {
            const nst = { ...st, StandardWriteUpName: name };
            return nst;
        });
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const handleDelete = async () => {
        let res = await DeleteStandardWriteUp(StandardWriteUpID);
        if (!res.success) {
            console.error(res);
        } else {
            hideModal();
            props.onFormSubmit(true);
        }
        showToast(res.message);
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitle>
                        Delete Standard Write Up - {state.StandardWriteUpName}
                    </DialogTitle>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent style={{width:'600px',height:'50px'}}>
                <Typography  variant="h6" display="block" gutterBottom>
                Are you sure to delete this standard write-up
                </Typography>
            </DialogContent>
            <DialogActions>
                <SecondaryButton className="Submitbtn" onClick={handleClose}>
                    No
                </SecondaryButton>
                <PrimaryButton className="Submitbtn" onClick={handleDelete}>
                    Yes
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteStandardWriteUpModal;
