import React, { useMemo, useState } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel, IconButton, Dialog } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { AppStorage } from '../../../../../Core/Service/storage-service';

import EditIcon from '@material-ui/icons/Edit';
import DialogComp from '../../../../../Core/Modal/dialogModal';
import LeagueTablePointsModal from './leagueTablePointsModal';
import { SecondaryButton, SquareButton } from '../../../../../Core/FormInput/AppButton';

const baseColumns = [
    { field: 'action', width: 70, sortable: false, title: `Action`, filterable: false },
    { field: 'description', sortable: false, title: `Description`, filterable: true },
    { field: 'points', width: 200, title: `Points`, align: 'center', filterable: true, sortable: true }
];
const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: 'League Table Points', active: true }
];

const LeagueTableScreen = (props) => {
    let menuPermissionCounter = AppStorage.menuPermissionCounter();
    const history = useHistory();
    const [state, setState] = useState({
        showModal: false,
        record: {},
        isReload: ''
    });

    const HandleScreen = (value, record) => {
        console.log(record);
        setState((st) => ({
            ...st,
            showModal: true,
            record: record
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid title={value} onClick={() => HandleScreen(value, record)} style={{ cursor: 'pointer', textAlign: 'center' }}>
                <IconButton size="small" color="primary">
                    <EditIcon />
                </IconButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');

        if (actionCol) {
            actionCol.renderer = editButton;
        }

        return tempCols;
    }, []);

    const baseUrl = `LeagueTablePoints`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(props.MeasureDesc, menuPermissionCounter)} />
            <Grid item container spacing={1} xs={12}>
                <Grid item style={{ marginTop: '5px' }}>
                    <SecondaryButton onClick={HandleScreen}>
                        <b>+</b> &nbsp;Add League Table Points
                    </SecondaryButton>
                </Grid>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res.data?.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isShowTotal={false}
                        hidePagination={false}
                        // hideFilterRow={true}
                        isReload={state.isReload}
                    />
                </Grid>
            </Grid>
            {state.showModal ? (
                <DialogComp
                    title="League Table Points"
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showModal: false,
                            record: {}
                        }))
                    }
                >
                    <LeagueTablePointsModal
                        stockObj={state.record}
                        onClose={() =>
                            setState((st) => ({
                                ...st,
                                record: {},
                                showModal: false,
                                isReload: new Date()
                            }))
                        }
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default LeagueTableScreen;
