import { createContext, useCallback, useEffect, useState, useRef, useContext } from 'react';
import AppContext from '../../../../App/AppContext';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Typography, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { getTyreCheckByID, postTyreCheck } from '../../../../Core/Service/tyreCheckService';
import { AppStorage } from '../../../../Core/Service/storage-service';
import moment from 'moment';
import formatters from '../../../../Core/Grid/inputFormatter';

export const TyreChecksContaxt = createContext();

export const TyreCheckProvider = (props) => {
    const { hideModal, showToast, hideTopNavAndMenu, userRoles } = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const [inputList, setInputList] = useState([{ tyreConditionDefectDescription: '' }]);
    const [state, setState] = useState({
        editTyreAxlesVM: [],
        tyreAxlesVM: [],
        remainingAxle: [],
        tyreConditionHeaderRecordID: props.match.params.jobId || null,
        tyreConditionID: null,
        errors: {}
    });

    const signCanvas = useRef({});

    const GetTechnicianSign = () => {
        setState((st) => {
            const nst = {
                ...st,
                techniciansign: signCanvas.current.toDataURL()
            };
            return nst;
        });
    };

    useEffect(async () => {
        let res = await getTyreCheckByID(state.tyreConditionHeaderRecordID, state.tyreConditionID);
        if (res.success) {
            let data = res.data.details[0];
            let defectsData = res.data.tyreConditionDefects;
            // let getAxle = [];
            // if (res.data.tyreAxles) {
            //     let IsAxel1 = res.data.tyreAxles.find((x) => x.axleNumber === 1);
            //     let IsAxel2 = res.data.tyreAxles.find((x) => x.axleNumber === 2);
            //     let IsAxel3 = res.data.tyreAxles.find((x) => x.axleNumber === 3);
            //     let IsAxel4 = res.data.tyreAxles.find((x) => x.axleNumber === 4);
            //     if (!IsAxel1) {
            //         IsAxel1 = { axleNumber: 1 };
            //     }
            //     if (!IsAxel2) {
            //         IsAxel2 = { axleNumber: 2 };
            //     }
            //     if (!IsAxel3) {
            //         IsAxel3 = { axleNumber: 3 };
            //     }
            //     if (!IsAxel4) {
            //         IsAxel4 = { axleNumber: 4 };
            //     }
            //     getAxle = [IsAxel1, IsAxel2, IsAxel3, IsAxel4];
            // }

            // let remainingAxle = [...getAxle];
            // remainingAxle.shift();

            if (data.tyreConditionID) {
                setState((st) => ({
                    ...st,
                    registration: data.registration,
                    wipNumber: data.wipNumber,
                    customerName: data.customerName,
                    tyreConditionID: data.tyreConditionID,
                    tyreConditionHeaderRecordID: data.tyreConditionHeaderRecordID,
                    tyreConditionFrontTyreSize: data.tyreConditionFrontTyreSize,
                    tyreConditionRearTyreSize: data.tyreConditionRearTyreSize,
                    tyreConditionDateOfTachoGraph: formatters.DateFormatter(data?.tyreConditionDateOfTachoGraph),
                    tyreConditionWindscreenCondition: data.tyreConditionWindscreenCondition,
                    tyreConditionWindscreenConditionDescription: data.tyreConditionWindscreenConditionDescription,
                    techniciansign: data.tyreConditionTechnicianSignature,
                    imgtechniciansign: data.tyreConditionTechnicianSignature,
                    maxTreadDepth: data.maxTreadDepth,
                    minTreadDepth: data.minTreadDepth,
                    treadDepthRed: data.treadDepthRed,
                    maxTyrePressure: data.maxTyrePressure,
                    minTyrePressure: data.minTyrePressure,
                    tyrePressureRed: data.tyrePressureRed,
                    tyreAxlesVM: [...res.data.tyreAxles]
                }));

                setInputList(!defectsData.length ? [{ tyreConditionDefectDescription: '' }] : defectsData);
            } else {
                setState((st) => ({
                    ...st,
                    registration: data.registration,
                    wipNumber: data.wipNumber,
                    customerName: data.customerName,
                    maxTreadDepth: data.maxTreadDepth,
                    minTreadDepth: data.minTreadDepth,
                    treadDepthRed: data.treadDepthRed,
                    maxTyrePressure: data.maxTyrePressure,
                    minTyrePressure: data.minTyrePressure,
                    tyrePressureRed: data.tyrePressureRed
                }));
            }
        }
    }, [state.tyreConditionID]);

    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: null,
                techniciansign: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };

    const useDefaultSig = () => {
        let userSign = AppStorage.getSignature();
        if (userSign) {
            signCanvas.current.fromDataURL(AppStorage.getSignature());
            setState((st) => ({ ...st, techniciansign: AppStorage.getSignature() }));
        } else {
            showToast('User signature not found');
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const handleAddClick = () => {
        setInputList([...inputList, { tyreConditionDefectDescription: '' }]);
    };
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const YesNoRadio = (props) => {
        return (
            <div style={{ display: 'flex', flex: 1 }}>
                <RadioGroup row aria-label={props.name} name={props.name} value={props.value} onChange={props.onChange}>
                    <FormControlLabel checked={props.value === true} value={true} control={<Radio inputProps={props.inputProps} />} label="Yes" />
                    <FormControlLabel checked={props.value === false} value={false} control={<Radio inputProps={props.inputProps} />} label="No" />
                </RadioGroup>
            </div>
        );
    };

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    }, []);

    const handleInputs = (axelNo) => (e) => {
        const { name, value } = e.target;
        setState((st) => {
            let ax = st.tyreAxlesVM.find((a) => a.axleNumber === axelNo);
            if (!ax) {
                ax = { axleNumber: axelNo };
                st.tyreAxlesVM.push(ax);
            }
            ax[name] = value;

            return { ...st, tyreAxlesVM: [...st.tyreAxlesVM] };
        });
    };

    const handleSliderChange = (axelNo, nameID) => (event, newValue) => {
        setState((st) => {
            let ax = st.tyreAxlesVM.find((a) => a.axleNumber === axelNo);
            if (!ax) {
                ax = { axleNumber: axelNo };
                st.tyreAxlesVM.push(ax);
            }
            ax[nameID] = newValue;
            return { ...st, tyreAxlesVM: [...st.tyreAxlesVM] };
        });
    };

    const Validations = () => {
        let fromValid = true;
        let errors = {};
        if (!state.tyreConditionWindscreenCondition) {
            if (!state.tyreConditionWindscreenConditionDescription) {
                errors.message = 'Description is required';
                fromValid = false;
            }
        }

        setState((st) => ({ ...st, errors: errors }));
        return fromValid;
    };

    const handleSubmit = async () => {
        let finalAxle = state.tyreAxlesVM.map((ax) => ({
            axleNumber: +ax.axleNumber || null,
            nsBrakeLineRemaining: +ax.nsBrakeLineRemaining || null,
            osBrakeLineRemaining: +ax.osBrakeLineRemaining || null,
            nsTreadDepth: +ax.nsTreadDepth || null,
            osTreadDepth: +ax.osTreadDepth || null,
            nsFrontTreadDepth: +ax.nsFrontTreadDepth || null,
            osFrontTreadDepth: +ax.osFrontTreadDepth || null,
            nsTyrePressure: +ax.nsTyrePressure || null,
            osTyrePressure: +ax.osTyrePressure || null,
            nsFrontTyrePressure: +ax.nsFrontTyrePressure || null,
            osFrontTyrePressure: +ax.osFrontTyrePressure || null,
            nsBrakeTemperatureAxle: +ax.nsBrakeTemperatureAxle || null,
            osBrakeTemperatureAxle: +ax.osBrakeTemperatureAxle || null
        }));

        if (Validations()) {
            let data = {
                tyreConditionID: state.tyreConditionID || null,
                tyreConditionHeaderRecordID: state.tyreConditionHeaderRecordID || null,
                tyreConditionFrontTyreSize: state.tyreConditionFrontTyreSize || null,
                tyreConditionRearTyreSize: state.tyreConditionRearTyreSize || null,
                tyreConditionDateOfTachoGraph: state.tyreConditionDateOfTachoGraph || null,
                tyreConditionWindscreenCondition: state.tyreConditionWindscreenCondition,
                tyreConditionWindscreenConditionDescription: state.tyreConditionWindscreenConditionDescription || null,
                tyreConditionTechnicianSignature: state.techniciansign || null,
                tyreAxlesVM: finalAxle,
                tyreConditionDefectsVM: inputList
            };

            let res = await postTyreCheck(data);
            if (res.success) {
                showToast(res.message);
                if (fromStep) {
                    setTimeout(() => {
                        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    }, 1000);
                }
            }
            if (res.errors) {
                for (const [key, value] of Object.entries(res?.errors)) {
                    showToast(`${key}: ${value}`);
                }
            } else {
                showToast(res.message);
            }
        }
    };
    return (
        <TyreChecksContaxt.Provider
            value={{
                signCanvas,
                state,
                fromStep,
                inputList,
                handleAddClick,
                handleRemoveClick,
                handleInputChange,
                handleSliderChange,
                useDefaultSig,
                GetTechnicianSign,
                clear,
                YesNoRadio,
                handleInputs,
                inputChange,
                handleSubmit
            }}
        >
            {props.children}
        </TyreChecksContaxt.Provider>
    );
};
