import { useMemo, useState } from 'react';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import { Grid } from '@material-ui/core';
import SendMessageModal from './sendMessageModal';
import moment from 'moment';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import ReactDOM from 'react-dom';
import { CustomIconButton, SquareButton } from '../../../Core/FormInput/AppButton';
import ReportGijgoGrid from '../../internal/ReportsScreens/GijgoForReportsScreen';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { AppStorage } from '../../../Core/Service/storage-service';
import AddEditSiteVehicle from '../../internal/Communicator/SiteVehicles/addEditSiteVehicle';
import UpdateIcon from '@material-ui/icons/Update';
import DocumentListComponent from './AllDocumentsModal';
import { Visibility } from '@material-ui/icons';

const baseColumns = [
    {
        title: 'Action',
        field: 'action',
        width: 70,
        filterable: false
    },
    { field: 'wipNumber', sortable: true, title: `${'WIP No'}`, width: 70 },
    { field: 'registration', sortable: true, title: `${'Vehicle Reg'}`, width: 90 },
    { field: 'st', sortable: true, title: `${'Job Description'}`, width: 170 },
    { field: 'makeModel', sortable: true, title: `${'Model'}`, width: 200 },
    // {
    //     field: 'dateDueIn',
    //     sortable: true,
    //     title: `${'Due Date In'}`,
    //     type: 'dateTime',
    //     width: 135,
    //     renderer: function (value, record) {
    //         if (record.dateDueIn != null) {
    //             return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
    //         }
    //         return '-';
    //     }
    // },
    // {
    //     field: 'dateDueOut',
    //     sortable: true,
    //     title: `${'Date Due Out'}`,
    //     type: 'dateTime',
    //     width: 135,
    //     renderer: function (value, record) {
    //         if (record.dateDueOut != null) {
    //             return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
    //         }
    //         return '-';
    //     }
    // },
    { field: 'branchShortName', sortable: true, title: `${'Branch'}`, width: 120 },
    {
        field: 'status',
        sortable: true,
        title: `${'Status'}`,
        width: 100
    }
];

const screenName = {
    S: 'Site Vehicles',
    V: 'VOR Vehicles',
    W: 'Workshop Vehicles',
    A: 'Jobs Approved',
    VOR: 'VOR Vehicles',
    BOOKED: 'Bookings',
    CHECKIN: 'Checked In',
    WORKSHOP: 'Workshop',
    APARTS: 'Awaiting Parts',
    ATA: 'Awaiting Technical Assistance',
    AUTH: 'Awaiting Authority',
    AFON: 'Awaiting Full Order',
    PE: 'Parts Escalation',
    PA: 'Parts Arrived',
    A: 'Approved by customer',
    SUB: 'Waiting Sub Work',
    WC: 'Work Completed',
    CHECKEDOUT: 'Checked Out',
    QC: 'Awaiting QC',
    ACOLLECTION: 'Awaiting Collection',
    REBOOKING: 'Rebooking',
    COLLECTED: 'Collected'
};

const crumbs = (paramStatus, val, menuPermissionCounter) => {
    let list = [{ name: 'Home', path: '/' }];
    if (menuPermissionCounter !== 1) {
        list.push({ name: 'Communicator', path: '/communicator_dash' });
    }
    if (paramStatus) {
        list.push({ name: `${screenName[paramStatus]}`, active: true });
    } else {
        list.push({ name: screenName[val], active: true });
    }
    return list;
};

const CustSiteVehicles = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamStatus = params.get('status') || '';
    let menuPermissionCounter = AppStorage.menuPermissionCounter();
    const [show, setShow] = useState({
        showSendMessageModal: false
    });

    const HandelSendMessage = (val) => {
        setShow((st) => ({
            ...st,
            showSendMessageModal: true,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" spacing={1} justify="center">
                <Grid item>
                    <CustomIconButton onClick={() => handleDocShow(record)} toolTipTitle="All Document">
                        <Visibility fontSize="medium" style={{ color: record.highPriority ? 'white' : '#183B68' }} />
                    </CustomIconButton>
                </Grid>
                <Grid item>
                    <CustomIconButton onClick={() => handleEdit(record)} toolTipTitle="Vehicle Status">
                        <UpdateIcon fontSize="medium" style={{ color: record.highPriority ? 'white' : '#183B68' }} />
                    </CustomIconButton>
                </Grid>
                <Grid item>
                    <CustomIconButton onClick={() => HandelSendMessage(record)} toolTipTitle="Add Action Log">
                        <ListAltIcon fontSize="medium" style={{ color: record.highPriority ? 'white' : '#183B68' }} />
                    </CustomIconButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');
        if (colAction) {
            colAction.renderer = editButton;
        }

        if (props.status == 'A') {
            col.splice(
                2,
                0,
                { field: 'approvedNo', sortable: true, title: `${'Order No'}`, width: 120 },
                {
                    field: 'approvedDate',
                    sortable: true,
                    title: `${'Approved Date'}`,
                    type: 'dateTime',
                    width: 145,
                    renderer: function (value, record) {
                        if (record.approvedDate != null) {
                            return moment(record.approvedDate).format('DD-MMM-YYYY HH:mm');
                        }
                        return '-';
                    }
                }
            );
        } else {
            col.splice(
                5,
                0,
                {
                    field: 'dateDueIn',
                    sortable: true,
                    title: `${'Date Due In'}`,
                    // type: 'date',
                    type: 'dateTime',
                    width: 135,
                    renderer: function (value, record) {
                        if (record.dateDueIn != null) {
                            return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                        }
                        return '-';
                    }
                },
                {
                    field: 'dateDueOut',
                    sortable: true,
                    title: `${'Date Due Out'}`,
                    // type: 'date',
                    type: 'dateTime',
                    width: 135,
                    renderer: function (value, record) {
                        if (record.dateDueOut != null) {
                            return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
                        }
                        return '-';
                    }
                }
            );
        }
        return col;
    }, []);

    const handleClose = (res) => {
        let newSt = {};
        newSt.showSendMessageModal = false;
        newSt.siteVehicle = false;
        newSt.showAllDocModal = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setShow((st) => ({
            ...st,
            ...newSt
        }));
    };

    const rowDataStyle = (record) => {
        if (record.highPriority) {
            return {
                ['background-color']: '#C4463B'
            };
        }
        return false;
    };
    const colDataStyle = (record) => {
        if (record.highPriority) {
            return {
                ['color']: 'white'
            };
        }
        return false;
    };

    const handleEdit = (val) => {
        setShow((st) => ({ ...st, siteVehicle: true, headerRecordID: val.headerRecordID, reg: val.registration, wip: val.wipNumber }));
    };

    const handleDocShow = (val) => {
        setShow((st) => ({ ...st, showAllDocModal: true, headerRecordID: val.headerRecordID, reg: val.registration, wip: val.wipNumber }));
    };

    const getUrl = `Communicators?ShowJobs=${ParamStatus || props.status}`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(ParamStatus, props.status, menuPermissionCounter)} />
            <Grid container>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={column}
                        getUrl={getUrl}
                        displayPagination={true}
                        isReload={show.isReload}
                        setRowStyleOnCondition={true}
                        setRowStyleFunction={rowDataStyle}
                        setColStyleOnCondition={true}
                        setColStyleFunction={colDataStyle}
                    />
                </Grid>
            </Grid>

            {show.showAllDocModal ? (
                <DialogComp title={`Job Documents`} onClose={() => handleClose(false)} fullWidth fullScreen>
                    <DocumentListComponent onClose={handleClose} headerRecordID={show.headerRecordID} reg={show.reg} wip={show.wip} />
                </DialogComp>
            ) : null}
            {show.showSendMessageModal ? (
                <DialogComp title={`Send Message (WIP : ${show.wip})`} onClose={() => handleClose(false)} fullWidth maxWidth="lg">
                    <SendMessageModal onClose={handleClose} headerRecordID={show.headerRecordID} reg={show.reg} wip={show.wip} />
                </DialogComp>
            ) : null}
            {show.siteVehicle ? (
                // <DialogComp title={addActionLogHeaderComp('Vehicle Status')} onClose={() => handleClose(false)} fullWidth maxWidth="lg">
                <AddEditSiteVehicle onClose={handleClose} headerRecordID={show.headerRecordID} reg={show.reg} wip={show.wip} isShowHistory={true} />
            ) : // </DialogComp>
            null}
        </div>
    );
};
export default CustSiteVehicles;
