import { get, post } from './http-calls';


export const getCashAccountCategories = async () => {
    let res = await get('CashAccount/CashAccountCategories', { useAuthToken: true });
    return res;
};

export const setCashAccountCategories = async (data) => {
    let res = await post(`CashAccount/CashAccountCategories`, data, { useAuthToken: true });
    return res;
};

export const getCashAccountTypes = async () => {
    let res = await get('CashAccount/CashAccountTypes', { useAuthToken: true });
    return res;
};

export const setCashAccountTypes = async (data) => {
    let res = await post(`CashAccount/CashAccountTypes`, data, { useAuthToken: true });
    return res;
};