import { Typography } from '@material-ui/core';
// import { color } from 'html2canvas/dist/types/css/types/color';
import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { WipGraphTable, WipTiles } from './wipTable';

const COLORS = {
    0: '#0074D9',
    1: '#FF4136',
    2: '#2ECC40',
    3: '#FF851B',
    4: '#7FDBFF',
    5: '#B10DC9',
    6: '#FFDC00',
    7: '#C33C9A',
    8: '#39CCCC',
    9: '#01FF70',
    10: '#85144b',
    11: '#F012BE',
    12: '#3D9970',
    13: '#111111',
    14: '#AAAAAA'
};

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, stroke, value } = this.props;

        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                {value}
            </text>
        );
    }
}

export const WIPLineCharts = (props) => {
    return (
        <>
            <Typography variant="h6" color="secondary" style={{ textAlign: 'left', marginLeft: 25 }}>
                {props.label}
            </Typography>
            <br />
            <ResponsiveContainer width="100%" height={props.tempData ? 350 : 230}>
                <LineChart
                    width={500}
                    height={300}
                    data={props.tempData ? props.tempData : props.data}
                    margin={{
                        top: 5,
                        right: 25,
                        left: -10,
                        bottom: 20
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        style={{ fontSize: 10 }}
                        // textAnchor="end"
                        sclaeToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        // angle="-30"
                    />
                    <YAxis style={{ fontSize: 10 }} />
                    <Tooltip
                        contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700, zIndex: 100 }}
                        // position={{ y: -100 }}
                    />
                    {props.tempData && <Legend iconType="square" iconSize={9} />}
                    {props?.keys ? (
                        props.keys.map((m, i) => {
                            return <Line type="monotone" dataKey={m} stroke={COLORS[i]} strokeWidth={2} />;
                        })
                    ) : (
                        <Line type="monotone" dataKey="hours" stroke="#E31C48" strokeWidth={2} label={<CustomizedLabel />} />
                    )}
                </LineChart>
            </ResponsiveContainer>
            {props.showTable ? <WipGraphTable data={props.Tabledata} /> : null}
        </>
    );
};
