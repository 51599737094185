import { Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { SecondaryButton } from '../../../../Core/FormInput';
import { SquareButton } from '../../../../Core/FormInput/AppButton';
import DialogComp from '../../../../Core/Modal/dialogModal';
import { getFranchiseHeaders } from '../../../../Core/Service/qc-service';
import ReportGijgoGrid from '../../../internal/ReportsScreens/GijgoForReportsScreen';
import AddEditItem from './AddEditItem';

const QCItems = () => {
    const [state, setState] = useState({
        franchiseHeaders: [],

        focusedFranchise: {},
        focusedQCItemID: null,
        showAddEdit: false
    });

    const crumbs = () => [
        { name: 'Home', path: '/' },
        { name: 'QC', active: true },
        { name: `Items`, active: true }
    ];

    const baseColumns = [
        {
            field: 'action',
            sortable: false,
            title: `Action`,
            width: '3%',
            filterable: false
        },
        {
            field: 'qcItemID',
            title: `ID`,
            hidden: true
        },
        {
            field: 'qcItemTitle',
            title: `Title`,
            width: '22%'
        },
        {
            field: 'qcResultListDescription',
            title: `List`,
            width: '22%'
        },
        {
            field: 'qcItemSequence',
            title: `Sequence`,
            width: '22%'
        },
        {
            field: 'qcItemActive',
            title: `Active`,
            width: '22%'
        },
    ];

    const pullFranchiseHeaders = async () => {
        let result = await getFranchiseHeaders();
        if (result.success) {
            setState((st) => ({
                ...st,
                franchiseHeaders: result.data,
                focusedFranchise: result.data[0]
            }));
        }
    };

    useEffect(() => {
        pullFranchiseHeaders();
    }, []);

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent='space-evenly'>
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => {
                        handleAddEdit(true)
                        setState((st) => ({
                            ...st,
                            focusedRow: record
                        }))
                    }}
                >
                    <Edit fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');

        if (actionCol) {
            actionCol.renderer = editButton({});
        }

        return tempCols;
    }, []);

    const handleAddEdit = (val) => {
        if (val) {
            setState((st) => ({
                ...st,
                showAddEdit: val,
                isReload: false
            }))
        } else {
            setState((st) => ({
                ...st,
                showAddEdit: val,
                focusedRow: null,
                isReload: true
            }))
        }
    }

    let baseUrl = `qc/Items/${state.focusedFranchise.letterIdentifier}`;

    return (
        <div>
            <Grid item container justify="space-between">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs()} />
                </Grid>
                <Grid item style={{ marginTop: '15px' }}>
                    <SecondaryButton
                        variant="contained"
                        color="secondary"
                        onClick={() => handleAddEdit(true)}
                        className="btnadd"
                    >
                        Add QC Item
                    </SecondaryButton>
                </Grid>
            </Grid>

            <Grid container style={{ marginLeft: '10px' }}>
                {state.franchiseHeaders.map((franchise) => {
                    return (
                        <Grid item style={{ marginLeft: '10px' }}>
                            <SecondaryButton
                                style={{ backgroundColor: franchise.letterIdentifier == state.focusedFranchise.letterIdentifier ? 'green' : 'red' }}
                                className="Submitbtn"
                                onClick={() => setState((st) => ({ ...st, focusedFranchise: franchise }))}
                            >
                                {franchise.franchiseName}
                            </SecondaryButton>
                        </Grid>
                    )
                })}
            </Grid>

            {state.focusedFranchise ?
                <Grid item container spacing={1} xs={12} style={{}}>
                    <Grid item xs={12}>
                        <ReportGijgoGrid
                            dataConverter={(res) => ({
                                records: res?.data,
                                total: res?.data?.total
                            })}
                            columns={columns}
                            getUrl={baseUrl}
                            isReload={state.isReload}
                        />
                    </Grid>
                </Grid>
                : null}

            {state.showAddEdit ? (
                <DialogComp
                    title={
                        state.focusedRow ? `Edit QC Item - ${state.focusedFranchise.franchiseName}` : `Add QC Item - ${state.focusedFranchise.franchiseName}`
                    }
                    maxWidth="sm"
                    onClose={() => handleAddEdit(false)}
                    fullWidth
                >
                    <AddEditItem QCItemVehicleTypeID={state.focusedFranchise.letterIdentifier} focusedRow={state.focusedRow} onClose={() => handleAddEdit(false)} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default QCItems;
