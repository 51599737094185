import { useContext, useEffect, useState } from 'react';
import { CircularProgress, FormLabel, Grid, IconButton, InputLabel, Tooltip } from '@material-ui/core';
import { Backup, Mail, Visibility } from '@material-ui/icons';
import { AppSelect, Dialogs, PrimaryButton, TextBox } from '../../../../Core/FormInput';
import { uploadOcrDoc } from '../../../../Core/Service/workflowJobDocument-service';
import AppContext from '../../../../App/AppContext';
import { useHistory } from 'react-router-dom';
import DynamicAppSelect from './dynamicAppSelect';
import { getWorkflowStepList } from '../../../../Core/Service/workflowList-service';
import { ScreenContext } from './DynamicFormProvider';
import { CustomeSignPad } from '../../../../Core/SignaturePad';
import { useCallback } from 'react';
import doc from '../doc.png';
import '../workflow-job.scss';
// import DialogComp from '../../../../Core/Modal/dialogModal';
import MailDocModal from '../more/MailDoc';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import { makeStyles } from '@material-ui/core/styles';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';

const useStyles = makeStyles({
    inputContainerBackgroundColor: {
        backgroundColor: (props) => `${props.backgroundColor}`
    },
    inputContainerTextColor: {
        color: (props) => `${props.textColor}`
    }
});

const DynamicFormFields = ({ step, jobId, onDropdownChange, dropdownValue, fieldLabel, downloadDocument, SignValue }) => {
    const [WindowWidths, WindowHeights] = useWindowSize();

    const classes = useStyles({ backgroundColor: step.backgroundColor, textColor: step.textColor });
    const [uploading, setUploading] = useState(false);
    const { showToast } = useContext(AppContext);
    const { showMail, state, getListOptionById, setDocumentWithOcrDocId, getDocumentFromOcrDocId, updateState } = useContext(ScreenContext);
    const ocrDoc = getDocumentFromOcrDocId(step.ocrDocumentId);

    const ops = step.listId && getListOptionById(step.listId);

    const GetTechnicianSign = (name, val) => {
        onDropdownChange({ [name]: val[name] });
    };

    const clearSig = (name) => {
        onDropdownChange && onDropdownChange({ [name]: '' });
    };

    const docAction = (actionType) => {
        switch (actionType) {
            case 'VIEW':
                // downloadDocument(step.ocrDocumentId, ocrDoc.docName);
                downloadDocument(jobId, step.fieldId);
                updateState({ showDoc: true, selectedFieldData: { fieldId: step.fieldId, showUpload: step.showUpload, isDeleted: step.isDeleted } });
                break;
            case 'MAIL':
                showMail({ ocrDocumentName: step.ocrDocumentName, docName: ocrDoc.docName || '', ocrDocIdToMail: step.ocrDocumentId });
                break;
            default:
                showToast(`Event Type: ${actionType} is not implement yet.`);
        }
    };

    const getLink = (URL) => {
        if (!URL.includes('youtube.com')) {
            return URL;
        }
        if (URL.includes('youtube.com') && !URL.includes('/embed/')) {
            return 'https://www.youtube.com/embed/' + URL.split('?v=')[1];
        }
        return URL;
    };

    const getDocumentAction = () => {
        if (step.ocrDocumentId) {
            return (
                <>
                    {/* {step.showUpload && (
                        <label title="Upload Document">
                            <IconButton color="secondary" component="span" style={{ padding: '0px 3px' }}>
                                <Backup />
                                <input
                                    type="file"
                                    onChange={(e) => uploadDynamicDoc(e)}
                                    className="upload-btn-input-file"
                                    style={{ display: 'none' }}
                                    accept="image/*,application/pdf"
                                />
                                {uploading && <CircularProgress size={40} className="upload-progress" color="secondary" />}
                            </IconButton>
                        </label>
                    )} */}
                    {step.showView && (
                        <label title="Upload Documents">
                            <IconButton
                                color={state.currentVisibleDocName === ocrDoc?.docName ? 'primary' : 'secondary'}
                                component="span"
                                onClick={() => docAction('VIEW')}
                                style={{ padding: '0px 3px' }}
                            >
                                <Backup />
                            </IconButton>
                        </label>
                    )}
                    {/* {step.showEmail && (
                        <label title="Email Document">
                            <IconButton style={{ padding: '0px 0px 0px 3px' }} color="secondary" component="span" onClick={() => docAction('MAIL')}>
                                <Mail />
                            </IconButton>
                        </label>
                    )} */}
                </>
            );
        }
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            {step.displayType != 7 && (
                <Grid item xs={12} className={classes.inputContainerBackgroundColor}>
                    <Grid container justifyContent="space-between">
                        <FormLabel
                            required={step.isRequired ? true : false}
                            component="legend"
                            className={step.isRequired ? `mandatoryfields ${classes.inputContainerTextColor}` : false}
                        >
                            {fieldLabel}
                        </FormLabel>
                        <FormLabel>{getDocumentAction()}</FormLabel>
                    </Grid>
                    {step.subtitle && (
                        <Grid container style={{ padding: '7px 0px 0px 0px' }}>
                            <Grid item xs={12}>
                                <InputLabel shrink className={classes.inputContainerTextColor}>
                                    {step.subtitle}
                                </InputLabel>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
            {(step.textURL || step.textImage) && (
                <Grid item xs={12} className={classes.inputContainerBackgroundColor}>
                    <div style={{ position: 'relative', width: '100%', paddingTop: WindowWidths < 700 ? '100%' : '50%' }}>
                        {step.textImage && (
                            <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, textAlign: 'center' }}>
                                <img
                                    src={step.textImage}
                                    style={{
                                        height: '100%',
                                        width: '100%'
                                    }}
                                />
                            </div>
                        )}
                        {step.textURL && (
                            <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, textAlign: 'center' }}>
                                <iframe
                                    frameborder="0"
                                    allowFullScreen="true"
                                    webkitallowfullscreen="true"
                                    mozallowfullscreen="true"
                                    width="100%"
                                    height="100%"
                                    src={getLink(step.textURL)}
                                />
                            </div>
                        )}
                    </div>
                </Grid>
            )}
            <Grid item xs={12} className={classes.inputContainerBackgroundColor}>
                {step.displayType == 5 || step.displayType == 6 || step.displayType == 8 || step.displayType == 9 ? (
                    <>
                        <TextBox
                            size="small"
                            type={step.displayType == 8 ? 'datetime-local' : step.displayType == 9 ? 'number' : 'text'}
                            name={step.jsonPropertyName}
                            value={dropdownValue}
                            multiline={step.displayType == 6 ? true : false}
                            rows={step.numberOfRows || 1}
                            color="primary"
                            onChange={(e) => onDropdownChange({ [step.jsonPropertyName]: e.target.value })}
                        />
                    </>
                ) : step.displayType == 1 || step.displayType == 4 || step.displayType == 2 || step.displayType == 3 ? (
                    <DynamicAppSelect
                        displayType={step.displayType}
                        name={step.jsonPropertyName}
                        value={dropdownValue}
                        onChange={onDropdownChange}
                        options={ops}
                        valuePropertyName="listOptionId"
                        textPropertyName="listOptionName"
                    />
                ) : step.displayType == 7 ? (
                    <CustomeSignPad
                        onChange={(val) => GetTechnicianSign(step.jsonPropertyName, val)}
                        content={fieldLabel}
                        value={dropdownValue}
                        name={step.jsonPropertyName}
                        sign={SignValue}
                        clearSig={() => clearSig(step.jsonPropertyName)}
                        isRequired={step.isRequired}
                    />
                ) : null}
            </Grid>
        </Grid>
    );
};

export default DynamicFormFields;
