import { get, post, put } from './http-calls';

export const getPartReqs = async (jobId, CreatedUserID, Mode, technicianJobID) => {
    let res = '';
    if (CreatedUserID) {
        res = await get(`PartReqs/${jobId}?CreatedUserID=${CreatedUserID}&Mode=${Mode}${technicianJobID ? `&TechnicianJobID=${technicianJobID}` : ''}`, {
            useAuthToken: true
        });
    } else {
        res = await get(`PartReqs/${jobId}?Mode=${Mode}`, { useAuthToken: true });
    }
    return res;
};

export const addPartReq = async (jobId, reqParts, technicianJobId) => {
    let res = await post(`PartReqs/${jobId}?TechnicianJobId=${technicianJobId}`, reqParts, { useAuthToken: true });
    return res;
};

export const requestPartReq = async (jobId, reqParts, CreatedUserID, technicianJobId) => {
    let res = await post(
        `PartReqs/RequestPartReqs/${jobId}?CreatedUserID=${CreatedUserID}${technicianJobId ? `&TechnicianJobID=${technicianJobId}` : ''}`,
        reqParts,
        {
            useAuthToken: true
        }
    );
    return res;
};
export const approvePartReq = async (jobId, data, techJobId, CreatedUserID) => {
    let res = await post(`PartReqs/Approve/${jobId}?CreatedUserID=${CreatedUserID}${techJobId ? `&TechnicianJobID=${techJobId}` : ''}`, data, {
        useAuthToken: true
    });
    return res;
};

export const issuePartsQty = async (jobId, data, techJobId, CreatedUserID) => {
    let res = await post(`PartReqs/IssueParts/${jobId}?CreatedUserID=${CreatedUserID}${techJobId ? `&TechnicianJobId=${techJobId}` : ''}`, data, {
        useAuthToken: true
    });
    return res;
};

export const getPartReqStatus = async () => {
    let res = await get(`PartReqs/PartReqsStatus`, { useAuthToken: true });
    return res;
};

export const getPhotosByPartReqID = async (id) => {
    let res = await get(`PartReqs/PartReqPhotos/${id}`, { useAuthToken: true });
    return res;
};

// export const postPhotosByPartReqID = async (data) => {
//     let res = await post(`PartReqs/Photo/PartReqPhotos`, data, { useAuthToken: true });
//     return res;
// };

export const PartReqPhotoDeleteByPhotoId = async (id) => {
    let res = await post(`PartReqs/PartReqPhotoDelete/${id}`, { id: id }, { useAuthToken: true });
    return res;
};

export const postPhotosByPartReqID = async (rows) => {
    const prom = [];
    rows.forEach((m) => {
        if ((m?.partReqId || m.id) && m?.Photos?.length > 0) {
            prom.push(
                post(
                    `PartReqs/Photo/PartReqPhotos`,
                    {
                        partReqPhotoID: null,
                        PartReqPhotoPartReqID: m?.partReqId || m.id,
                        PartReqPhotoFile: m.Photos.map((k) => k.partReqPhotoFile)
                    },
                    {
                        useAuthToken: true
                    }
                )
            );
        }
    });
    return Promise.all(prom)
        .then((ress) => {
            return {
                success: true,
                message: 'Photos saved successfully.'
            };
        })
        .catch((ex) => {
            console.error(ex);
            return {
                success: false,
                message: 'Failed to upload photos.  Please try again.'
            };
        });
};
export const getScreenDropdownPartReqID = async (jobId, CreatedUserID, Mode) => {
    let res = '';
    if (CreatedUserID) {
        res = await get(`PartReqs/ScreenDropdown/${jobId}?CreatedUserID=${CreatedUserID}&Mode=${Mode}`, {
            useAuthToken: true
        });
    } else {
        res = await get(`PartReqs/ScreenDropdown/${jobId}?Mode=${Mode}`, { useAuthToken: true });
    }
    return res;
};

export const getPartListByPartNumber = async (number, desc) => {
    let res = await get(`PartReqs/GetPartByPartNumber?${number ? `PartNumber=${number}` : ''}${desc ? `PartDescription=${desc}` : ''}`, {
        useAuthToken: true
    });
    return res;
};
