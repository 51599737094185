import { Card } from '@material-ui/core';
import { Component, useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/styles';
import { saveSwapUserPreferenceWorkflows } from '../Service/dashbordService';
import AppContext from '../../App/AppContext';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#f5f5f5',
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 110,
        padding: '5px 0px',
        margin: 3,
        borderRadius: 3
    },
    workflowCardContainer: {
        '&:nth-of-type(1)': {
            padding: (props) => (props ? '0px 5px 0px 0px' : '0px 5px 0px 10px')
        },
        padding: '0px 5px 0px 0px'
    }
}));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'inherit',
    display: 'flex',
    padding: '0px 2px 0px 0px'
    // overflow: 'auto'
});

export const WorkFlowDragNDropComponent = (props) => {
    const classes = useStyles(props.isShowScrollArrow);
    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({
        items: []
    });

    useEffect(() => {
        setState((st) => ({
            ...st,
            items: _.sortBy(props.btnList, (o) => o.sequenceNo)
        }));
    }, [props.btnList]);

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(state.items, result.source.index, result.destination.index);
        setState({
            items
        });
        let res = await saveSwapUserPreferenceWorkflows({
            workflowtb: items.map((p, i) => ({
                workflowId: p.workflowID,
                // workflowActive: p.active,
                workflowSequence: i
            }))
        });

        if (res.data.success) {
            // showToast(res.data.message);
        } else {
            console.error(res.message);
        }
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="container native-container"
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {state.items.map((item, index) => (
                                <Draggable key={`${item.workflowID}`} draggableId={`${item.workflowID}`} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            item
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={classes.workflowCardContainer}
                                        >
                                            <Card
                                                className={classes.root}
                                                style={{
                                                    background: state.btnID === item.workflowID ? '#BA4546' : 'white',
                                                    color: state.btnID === item.workflowID ? 'white' : 'black',
                                                    padding: 0
                                                    // padding: props.isShowScrollArrow ? '0px 5px 0px 0px' : `0px 5px 0px ${index === 0 ? '10px' : '0px'}`
                                                }}
                                                onClick={() => props.buttonClicked(item)}
                                            >
                                                <div
                                                    style={{
                                                        textAlign: 'left',
                                                        cursor: 'pointer',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        // color: 'rgb(255, 71, 87)',
                                                        overflow: 'hidden',
                                                        padding: '5px 20px'
                                                    }}
                                                >
                                                    <span className="lableFont"> {item?.workflow}</span>
                                                    <div className="valueFont"> {item?.total}</div>
                                                </div>
                                            </Card>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div item style={{ padding: '0px 5px 0px 0px' }}>
                <Card
                    className={classes.root}
                    style={{
                        background: state.total === 'total' ? '#BA4546' : 'white',
                        color: state.total === 'total' ? 'white' : 'black',
                        padding: 0
                    }}
                    onClick={() => props.buttonClicked({ workflowID: 'All' })}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            cursor: 'pointer',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            paddingBottom: 10,
                            padding: '5px 20px'
                        }}
                    >
                        <span style={{ fontSize: 14, marginBottom: 6 }}> Total</span>
                        <div style={{ fontSize: 30, fontWeight: 600 }}>{props.sumOfAllWorkflows}</div>
                    </div>
                </Card>
            </div>
        </>
    );
};
