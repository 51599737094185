import { Button, ButtonGroup, FormControlLabel, FormLabel, Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { SecondaryCheckbox, TextBox } from '../../../../Core/FormInput';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DiagnosticSheetsImageComponent from '../imageSlider';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    table: {
        minWidth: '100%'
    },

    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    },
    paper: {
        padding: '7px 16px 16px 16px',
        // color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)',
        width: '100%'
        // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
    },
    axelTitles: {
        textAlign: 'center',
        color: '#f50057',
        width: '100%'
    },
    headings: {
        color: '#f50057',
        margin: 0
        // marginLeft: -4,
        // marginBottom: 20
    }
}));

const SectionThree = (props) => {
    const { state, handleFieldChange, handleCheckbox, handleYesNo, upload, handleRemoveClick } = props;
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <Grid container xs={12}>
                <h3 className={classes.headings}>Section 3: Water Leaks</h3>
            </Grid>
            <hr></hr>
            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                    <FormLabel>Description of concern</FormLabel>
                    <TextBox
                        multiline
                        rows={5}
                        value={state.diagnosticSheetWaterLeaksConcern}
                        onChange={handleFieldChange}
                        name="diagnosticSheetWaterLeaksConcern"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <FormLabel>Has the vehicle had any body repairs:</FormLabel>
                                </Grid>
                                <Grid item>
                                    <ButtonGroup disableElevation variant="contained" color="secondary" size="small">
                                        <Button
                                            variant={state.diagnosticSheetWaterLeaksehadAnyBodyRepairs ? 'contained' : 'outlined'}
                                            onClick={() => handleYesNo('diagnosticSheetWaterLeaksehadAnyBodyRepairs', true)}
                                        >
                                            Yes
                                        </Button>

                                        <Button
                                            variant={state.diagnosticSheetWaterLeaksehadAnyBodyRepairs ? 'outlined' : 'contained'}
                                            onClick={() => handleYesNo('diagnosticSheetWaterLeaksehadAnyBodyRepairs', false)}
                                        >
                                            No
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <FormLabel>{`*If Yes, what repair was carried out:`}</FormLabel>
                        </Grid>
                        <Grid item xs={9}>
                            <TextBox
                                multiline
                                rows={5}
                                value={state.diagnosticSheetWaterLeaksBodyRepairsDescription}
                                onChange={handleFieldChange}
                                name="diagnosticSheetWaterLeaksBodyRepairsDescription"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Vehicle position:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                {state.vehiclePosition.map((c) => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                            <FormControlLabel
                                                control={
                                                    <SecondaryCheckbox
                                                        checked={c.checked}
                                                        onChange={handleCheckbox('vehiclePosition', c.id)}
                                                        name={`${c.name}`}
                                                    />
                                                }
                                                label={`${c.name}`}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Occurs:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                {state.occurs.map((c) => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                            <FormControlLabel
                                                control={<SecondaryCheckbox checked={c.checked} onChange={handleCheckbox('occurs', c.id)} name={`${c.name}`} />}
                                                label={`${c.name}`}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Water:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                {state.water.map((c) => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                            <FormControlLabel
                                                control={<SecondaryCheckbox checked={c.checked} onChange={handleCheckbox('water', c.id)} name={`${c.name}`} />}
                                                label={`${c.name}`}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>{`Images`}:</FormLabel>
                        </Grid>

                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Tooltip title="Upload Document">
                                <label>
                                    <span
                                        style={{
                                            padding: '1px 10px 6px 12px',
                                            border: '1px solid #f50057',
                                            borderRadius: '7%',
                                            backgroundColor: '#f50057',
                                            color: 'white',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Add Images
                                    </span>
                                    <input
                                        name="diagnosticSheetWaterLeakImg"
                                        multiple
                                        type="file"
                                        onChange={(e) => upload(e, 'diagnosticSheetWaterLeakImg')}
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                    />
                                </label>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={2} lg={1} xl={1}></Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11} style={{ maxHeight: '230px', overflow: 'auto', width: '100%', padding: '0px 10px' }}>
                            <DiagnosticSheetsImageComponent
                                ImageList={state.diagnosticSheetWaterLeakImg}
                                handleRemoveClick={(i) => handleRemoveClick(i, 'diagnosticSheetWaterLeakImg')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SectionThree;
