import React, { useEffect, useState } from 'react';
import { TextField, Checkbox, withStyles, Chip, Badge, Tooltip, Button, Grid, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useWindowSize } from '../../Core/Controls/ScreenResolution';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        fontWeight: 500,
        display: 'block',
        minWidth: '150px'
    }
}))(Tooltip);

const Multiselect = (props) => {
    return (
        <Autocomplete
            {...props}
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                </React.Fragment>
            )}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField margin="dense" fullWidth label={props.label} {...params} variant="outlined" placeholder={props.placeholder} />}
        />
    );
};
export default Multiselect;

export const MultiselectWithoutCheckbox = (props) => {
    return (
        <Autocomplete
            {...props}
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => <React.Fragment>{option.name}</React.Fragment>}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField margin="dense" fullWidth label={props.label} {...params} variant="outlined" placeholder={props.placeholder} />}
        />
    );
};

export const SingelSelect = (props) => {
    return (
        <Autocomplete
            {...props}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => <React.Fragment>{option.name}</React.Fragment>}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField margin="dense" fullWidth label={props.label} {...params} variant="outlined" placeholder={props.placeholder} />}
        />
    );
};

export const MultipleSelectWithMoreOp = (props) => {
    const [WindowWidth] = useWindowSize();
    const [open, setOpen] = React.useState(false);
    const CustomPaper = (props) => {
        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Paper elevation={100} {...props} />
                </Grid>
                <Grid
                    onClick={() => setOpen(false)}
                    item
                    xs={12}
                    style={{ backgroundColor: '#0e2132', textAlign: 'right', padding: '5px', marginTop: '-20px' }}
                >
                    <Button style={{ backgroundColor: 'white', color: 'black' }}>Done</Button>
                </Grid>
            </Grid>
        );
    };
    const handleOpen = () => {
        setOpen(true);
    };
    return (
        <Autocomplete
            {...props}
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            open={open}
            onOpen={handleOpen}
            onClose={() => setOpen(false)}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                </React.Fragment>
            )}
            ListboxProps={{ style: { maxHeight: 300, overflow: 'auto' } }}
            PaperComponent={CustomPaper}
            getOptionSelected={(option, value) => option.name === value.name}
            renderTags={(value, getTagProps) => {
                let tempList = [];
                let titleList = [];
                value?.forEach((p, i) => {
                    if (i < 1) {
                        tempList.push(<Chip label={`${p.name} `} {...getTagProps({ i })} />);
                    } else {
                        titleList.push(<div style={{ padding: 4 }}>{p.name}</div>);
                    }
                });

                if (value?.length > 1) {
                    tempList.push(
                        <LightTooltip title={titleList}>
                            <Badge color="secondary" badgeContent={value.length - 1}>
                                <Chip label={`more`} />
                            </Badge>
                        </LightTooltip>
                    );
                }
                return <div>{tempList}</div>;
            }}
            renderInput={(params) => <TextField margin="dense" fullWidth label={props.label} {...params} variant="outlined" placeholder={props.placeholder} />}
        />
    );
};
