import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const DailyHoursData = (props) => {
    const [state, setState] = useState({
        dayHoursList: [],
        reasonForXvail: []
    });

    useEffect(() => {
        let dayString = props.dayData.extraAvailability.active.split('');
        let uniqueCodes = [...new Set(dayString)];
        let filterdReasonForXvail = [];
        uniqueCodes.forEach((p) => {
            let obj = props.reasonForXvail.find((q) => q.code == p);
            if (obj) {
                filterdReasonForXvail.push({ ...obj });
            }
        });
        let startTime = moment().clone().startOf('date');
        let temp = dayString.map((o) => {
            return { time: `${startTime.format('HH:mm')} - ${startTime.add('m', 15).format('HH:mm')}`, code: o };
        });

        setState((st) => ({
            ...st,
            dayHoursList: temp,
            reasonForXvail: filterdReasonForXvail
        }));
    }, [props]);

    const getResoneData = (code) => {
        let obj = state.reasonForXvail.find((p) => p.code == code);
        return obj;
    };

    return (
        <div style={{ paddingBottom: '10px' }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {state.reasonForXvail.map((q) => {
                            return (
                                <Grid item xs={12} md={3}>
                                    <div style={{ textAlign: 'center', backgroundColor: q.colour, color: q.textColor, padding: '10px' }}>{q.description}</div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {state.dayHoursList.map((p) => {
                            return (
                                <Grid item xs={4} sm={3} md={2}>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            backgroundColor: getResoneData(p.code).colour,
                                            color: getResoneData(p.code).textColor,
                                            padding: '3px'
                                        }}
                                    >
                                        {p.time}
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default DailyHoursData;
