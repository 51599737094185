import React, { useContext, useMemo } from 'react';
import { SquareButton } from '../../../../Core/FormInput/AppButton';

import { Grid, Typography } from '@material-ui/core';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';

import { useState } from 'react';
import ReportGijgoGrid from '../../../internal/ReportsScreens/GijgoForReportsScreen';
import EditBranchStatusEmail from './editBranchStatusEmail';
import DialogComp from '../../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ConfirmationModal from '../../../../Core/Controls/Dialog/ConfirmationModal';
import { deleteBranchStatusEmailsById } from '../../../../Core/Service/InvoiceScreen-service';
import AppContext from '../../../../App/AppContext';

const baseColumns = [
    { field: 'action', sortable: false, title: ``, width: 90, filterable: false },
    { field: 'branchName', sortable: false, title: `Branch` },
    { field: 'invoiceStatus', sortable: false, title: `Status` },
    { field: 'email', sortable: false, title: `Email` },
    {
        field: 'emailAllow',
        sortable: false,
        title: `isActive`,
        width: 150
    }
];

const crumbs = (path) => [
    { name: 'Home', path: '' },
    { name: 'Invoices', active: true },
    { name: `Branch Status Emails`, active: true }
];

const baseUrl = 'BranchStatusEmails';

const BranchStatusEmail = () => {
    const { showToast, showModal } = useContext(AppContext);

    const [state, setState] = useState({
        showModal: false
    });

    const handleEdit = (val) => {
        setState((st) => ({
            ...st,
            showModal: true,
            branchID: val?.branchID,
            invoiceStatusId: val?.invoiceStatusId
        }));
    };
    const handleDelete = (val) => {
        setState((st) => ({
            ...st,
            showConfirmModal: true,
            branchID: val?.branchID,
            invoiceStatusId: val?.invoiceStatusId
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '25px',
                            height: '25px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => handleEdit(record)}
                        toolTipTitle="Edit Branch Status Emails"
                    >
                        <EditIcon style={{ color: 'white', fontSize: '16px' }} />
                    </SquareButton>
                </Grid>
                <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: '#dc3545',
                            borderRadius: '20%',
                            width: '25px',
                            height: '25px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => handleDelete(record)}
                        toolTipTitle="Edit Branch Status Emails"
                    >
                        <DeleteForeverIcon style={{ color: 'white', fontSize: '16px' }} />
                    </SquareButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');

        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);

    const handleClose = (res) => {
        let newSt = {};
        newSt.showModal = false;
        newSt.branchID = '';
        if (res) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };

    const deleteStatusEmailHandler = async () => {
        let res = await deleteBranchStatusEmailsById(state.invoiceStatusId, state.branchID);
        if (res.success) {
            showToast(`${res.message}`);
            confirmationModalClose(true);
        } else {
            console.error(res.message);
        }
    };
    const confirmationModalClose = (flag) => {
        let newSt = {};
        newSt.showConfirmModal = false;
        newSt.branchID = '';
        if (flag) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };

    return (
        <div className="report-screen-container">
            {state.showModal ? (
                <DialogComp title={`${state.branchID ? 'Update' : 'Add'} Branch Status Email`} maxWidth="sm" onClose={() => handleClose(false)} fullWidth>
                    <EditBranchStatusEmail onClose={handleClose} InvoiceStatusId={state.invoiceStatusId} BranchID={state.branchID} />
                </DialogComp>
            ) : null}
            {state.showConfirmModal ? (
                <DialogComp title="Are you sure to delete record" onClose={() => confirmationModalClose(false)} maxWidth="sm">
                    <ConfirmationModal
                        // message={`Are you sure to delete record`}
                        handleSubmit={deleteStatusEmailHandler}
                        handleCancel={() => confirmationModalClose(false)}
                    />
                </DialogComp>
            ) : null}
            <Grid container justify="space-between">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs()} />
                </Grid>
                <Grid item style={{ marginTop: '5px' }}>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => handleEdit()}
                        toolTipTitle="Add Branch Status Emails"
                    >
                        <AddIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
            </Grid>
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res?.data?.list,
                    total: res?.data?.total
                })}
                columns={column}
                getUrl={baseUrl}
                isReload={state.isReload}
                isShowTotal={false}
                //  hidePagination={true}
                // isReload={state.isReload}
                // hidePagination={true}
                // hideFilterRow={true}
            />
        </div>
    );
};

export default BranchStatusEmail;
