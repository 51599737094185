import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import DOMPurify from "dompurify";

const CustomerNotes = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        {`Name`}
                    </Grid>
                    <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                        {`${props.customerName}`}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'left' }}>
                <br />
                <Typography variant="body2" color="secondary">
                    Customer Notes
                </Typography>
                <hr />
                <div
                    style={{
                        outline: '1px solid #CECECE',
                        marginLeft: '2px',
                        marginRight: '2px',
                        paddingLeft: '10px',
                        paddingTop: '1px',
                        paddingBottom: '10px',
                        minHeight: '300px',
                        maxHeight: '300px',
                        overflowZ: 'hidden',
                        overflowY: 'scroll',
                        borderRadius: '1px'
                    }}>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.customerNote) }} />
                </div>
            </Grid>
        </Grid>
    );
};

export default CustomerNotes;