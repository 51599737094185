import * as Components from './../../../../Components';
import { TypeOfAuth } from './../menu-auth-type';
import HomeIcon from '@material-ui/icons/Home';
import ArchiveIcon from '@material-ui/icons/Archive';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AirplayIcon from '@material-ui/icons/Airplay';
import Dashboard from './../../../../Components/internal/dashboard/dashboard';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ForumIcon from '@material-ui/icons/Forum';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PieChartIcon from '@material-ui/icons/PieChart';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EuroIcon from '@material-ui/icons/Euro';
import DescriptionIcon from '@material-ui/icons/Description';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import SpeedIcon from '@material-ui/icons/Speed';
import WebIcon from '@material-ui/icons/Web';
import BuildIcon from '@material-ui/icons/Build';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import SendIcon from '@material-ui/icons/Send';
import SmsIcon from '@material-ui/icons/Sms';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import WorkIcon from '@material-ui/icons/Work';
import BookIcon from '@material-ui/icons/Book';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PaymentIcon from '@material-ui/icons/Payment';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

export default [
    {
        label: 'Technician App',
        path: '/',
        exact: true,
        component: Components.technicianApp,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Videos',
        path: '/Lists/Videos',
        exact: true,
        component: Components.Videos,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Workflow Job',
        hidden: true,
        exact: true,
        path: '/job/:jobId/:workflowId/:technicianJobId?/:isTechReadonly?',
        component: (props) => <Components.workflowJobDetails {...props} ProcessId={1} />,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Logout',
        path: '/logout',
        hidden: true,
        component: Components.logout,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Authentication',
        path: '/auth',
        hidden: true,
        component: Components.AuthenticationScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Authentication Verification',
        path: '/key',
        hidden: true,
        component: Components.TwoAuthDiasbledScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'User Profile',
        path: '/profile',
        hidden: true,
        component: Components.UserProfile,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Reports',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <BubbleChartIcon />,
        children: [
            {
                label: 'Group Dashboard',
                exact: true,
                path: '/ReportsDashboard',
                component: Components.MiniViewReportingDashboard,
                // component: Components.RepotingTab,
                authType: TypeOfAuth.Auth,
                icon: <SpeedIcon />
            },
            {
                label: 'Detail Dashboard',
                exact: true,
                path: '/RepotingTab',
                component: Components.RepotingTab,
                authType: TypeOfAuth.Auth,
                icon: <SpeedIcon />
            },
            {
                label: 'DOC',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <DescriptionIcon />,
                path: '/Docs/WeeklyFlashReport',
                component: () => <Components.WeeklyFlashReport MeasureDesc="Weekly Flash Report" />
            },
            {
                label: 'Budget Dashboard',
                exact: true,
                path: '/budget',
                component: Components.BudgetScreen,
                authType: TypeOfAuth.Auth,
                icon: <SpeedIcon />
            },
            {
                label: 'Profit & Loss',
                exact: true,
                path: '/profitloss',
                component: Components.ProfitLoss,
                authType: TypeOfAuth.Auth,
                icon: <EuroIcon />
            },
            {
                label: 'Aged Measures',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <DragIndicatorIcon />,
                children: [
                    {
                        label: 'Dashboard',
                        exact: true,
                        path: '/AgedMeasures',
                        component: () => <Components.AgedMeasuresDashBoard MeasureDesc="Dashboard" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Account Queries',
                        exact: true,
                        path: '/AgedMeasures/AccountQueries',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={6} MeasureDesc="Account Queries" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Account Queries By Department',
                        exact: true,
                        path: '/AgedMeasures/AccountQueriesByDepartment',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={21} MeasureDesc="Account Queries By Department" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Aged Debt - Branch',
                        exact: true,
                        path: '/AgedMeasures/AgedDebt-Branch',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={7} MeasureDesc="Aged Debt - Branch" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Aged Debt - Company',
                        exact: true,
                        path: '/AgedMeasures/AgedDebt-Company',
                        component: () => <Components.AgedDebt MeasureDesc="Aged Debt - Company" MeasureID={8} />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Cash',
                        exact: true,
                        path: '/AgedMeasures/Cash',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={5} MeasureDesc="Cash" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Cash By Department',
                        exact: true,
                        path: '/AgedMeasures/CashByDepartment',
                        component: () => <Components.CashByDepartment MeasureDesc="Cash By Department" MeasureID={20} />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Surcharges',
                        exact: true,
                        path: '/AgedMeasures/DirtyUnits',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={2} MeasureDesc="Surcharges" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Fuso Stock',
                        exact: true,
                        path: '/AgedMeasures/NewFusoStock',
                        component: () => <Components.NewStocks MeasureDesc="New Fuso Stock" MeasureID={11} FC="C" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Maxus Vans',
                        exact: true,
                        path: '/AgedMeasures/NewMaxusVans',
                        component: () => <Components.NewStocks MeasureDesc="New Maxus Vans" MeasureID={18} FC="A" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Truck Stock',
                        exact: true,
                        path: '/AgedMeasures/NewTruckStock',
                        component: () => <Components.NewStocks MeasureDesc="New Truck Stock" MeasureID={10} FC="H" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Van Stock',
                        exact: true,
                        path: '/AgedMeasures/NewVanStock',
                        component: () => <Components.NewStocks MeasureDesc="New Van Stock" MeasureID={12} FC="V" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'R&M',
                        exact: true,
                        path: '/AgedMeasures/R&M',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={3} MeasureDesc="R&M" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Fuso Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedFusoStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Fuso Stock" MeasureID={14} FC="C" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Maxus Vans',
                        exact: true,
                        path: '/AgedMeasures/UsedMaxusVans',
                        component: () => <Components.UsedStocks MeasureDesc="Used Maxus Vans" MeasureID={19} FC="A" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Non-franchise Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedNon-franchiseStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Non-franchise Stock" MeasureID={16} FC="Z" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Truck Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedTruckStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Truck Stock" MeasureID={13} FC="H" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Van Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedVanStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Van Stock" MeasureID={15} FC="V" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Vehicle Debt',
                        exact: true,
                        path: '/AgedMeasures/VehicleDebt',
                        component: () => <Components.AgedDebt MeasureDesc="Vehicle Debt" MeasureID={9} />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Warranty',
                        exact: true,
                        path: '/AgedMeasures/Warranty',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={4} MeasureDesc="Warranty" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Work In Progress',
                        exact: true,
                        path: '/AgedMeasures/WorkInProgress',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={1} MeasureDesc="Work In Progress" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Work In Progress by Departement',
                        exact: true,
                        path: '/AgedMeasures/WorkInProgressbyDepartement',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={17} MeasureDesc="Work In Progress by Departement" />,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            // {
            //     label: 'New Services',
            //     exact: true,
            //     authType: TypeOfAuth.Auth,
            //     icon: <DescriptionIcon />,
            //     children: [
            //         {
            //             label: 'Dashboard',
            //             exact: true,
            //             path: '/budget_dashboard',
            //             component: Components.BudgetDashboard,
            //             authType: TypeOfAuth.Auth,
            //             icon: <SpeedIcon />
            //         }
            //     ]
            // },
            {
                label: 'Vehicle',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <DescriptionIcon />,
                children: [
                    {
                        label: 'Service Due',
                        exact: true,
                        path: '/vehicle/serviceDue',
                        component: () => <Components.ReportVehiclesServiceComponent />,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    },
                    {
                        label: 'MOT Due',
                        exact: true,
                        path: '/vehicle/MOTDue',
                        component: () => <Components.ReportVehiclesMOTDueComponent />,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    },
                    {
                        label: 'Safety Check Due',
                        exact: true,
                        path: '/vehicle/safetyCheckDue',
                        component: () => <Components.ReportVehiclesSafetyCheckDueComponent />,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    },
                    {
                        label: 'Vehicle Due Calendar',
                        exact: true,
                        path: '/vehicle/VehicleDueCalendar',
                        component: () => <Components.VehicleDueCheckCalendar />,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    }
                ]
            },

            {
                label: 'Used Stock',
                children: true,
                authType: TypeOfAuth.Auth,
                icon: <EmojiEventsIcon />,
                children: [
                    {
                        label: 'Used Stock Valuation',
                        exact: true,
                        path: '/UsedStockValuation',
                        component: Components.UsedStockValuation,
                        authType: TypeOfAuth.Auth
                        // icon: <UsedStockValuation />
                    },
                    {
                        label: 'Used Vehicle Stock',
                        exact: true,
                        path: '/usedStock',
                        component: Components.UsedStock,
                        authType: TypeOfAuth.Auth
                        // icon: <UsedVehicleStock />
                    },
                    {
                        label: 'Aged Vehicle Stock',
                        exact: true,
                        path: '/agedStocks',
                        component: Components.AgedStocks,
                        authType: TypeOfAuth.Auth
                        // icon: <AgeVehicleSTock />
                    },
                    {
                        label: 'Overage Priority List',
                        exact: true,
                        path: '/overagePriority',
                        component: Components.OveragePriority,
                        authType: TypeOfAuth.Auth
                        // icon: <OveragePriorityIcon />
                    }
                ]
            },
            {
                label: 'Sales Performance',
                children: true,
                authType: TypeOfAuth.Auth,
                icon: <EmojiEventsIcon />,
                children: [
                    {
                        label: 'Sales Dashboard',
                        exact: true,
                        path: '/salesDashboard',
                        component: () => <Components.SalesDashboardSetting />,
                        authType: TypeOfAuth.Auth
                        // icon: <SpeedIcon />
                    },
                    {
                        label: 'Sales Stock',
                        exact: true,
                        path: '/SalesStockDashboard',
                        component: () => <Components.SalesStockDashboard />,
                        authType: TypeOfAuth.Auth
                        // icon: <SpeedIcon />
                    }
                ]
            },
            {
                label: 'Sales',
                children: true,
                authType: TypeOfAuth.Auth,
                icon: <EmojiEventsIcon />,
                children: [
                    {
                        label: 'Sales Dashboard',
                        exact: true,
                        path: '/sales/dashboard',
                        component: Components.SalesDashboard,
                        authType: TypeOfAuth.Auth
                    },
                    // {
                    //     label: 'Sales Dashboard V2',
                    //     exact: true,
                    //     path: '/sales/dashboardtwo',
                    //     component: Components.SalesDashboardTwo,
                    //     authType: TypeOfAuth.Auth
                    // },

                    {
                        label: 'New Vehicle Profit Report',
                        exact: true,
                        path: '/sales/NewVehicleProfitReport',
                        component: Components.NewVehicleProfitReport,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Vehicle Profit Report',
                        exact: true,
                        path: '/sales/UsedVehicleProfitReport',
                        component: Components.UsedVehicleProfitReport,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Account Queries by Salesexec',
                        exact: true,
                        path: '/sales/AccountQueriesbySalesexec',
                        component: Components.AccountQueriesbySalesexec,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Aged Measures Work - Progress',
                        exact: true,
                        path: '/sales/AgedMeasuresWorkingProgress',
                        component: Components.AgedMeasuresWorkingProgress,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Sales Work In Progress',
                        exact: true,
                        path: '/sales/SalesWorkInProgress',
                        component: Components.SalesWorkinProgress,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Registration Reports',
                        exact: true,
                        path: '/sales/RegistrationReports',
                        component: Components.RegistrationReports,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Vehicle Stock (Excluding WIP)',
                        exact: true,
                        path: '/sales/NewVehicleStockExcludingWIP',
                        component: Components.VehicleStocksExWip,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Vehicle Orders',
                        exact: true,
                        path: '/sales/VehicleNewOrder',
                        component: Components.VehicleNewOrder,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Outstanding Invoices',
                        exact: true,
                        path: '/sales/OutstandingInvoices',
                        component: Components.OutstandingInvoices,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Sales Contingency Debt',
                        exact: true,
                        path: '/sales/SalesContingencyDebt',
                        component: Components.SalesContingencyDebt,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Vehicle Stock',
                        exact: true,
                        path: '/sales/UsedVehicleStock',
                        component: Components.UsedVehicleStock,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Vehicle Aged Debt By Salesexec',
                        exact: true,
                        path: '/sales/VehicleAgedDebtBySalesexec',
                        component: Components.VehicleAgedDebtBySalesexec,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },

            {
                label: 'Services',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <WebIcon />,
                children: [
                    {
                        label: 'Dashboard',
                        exact: true,
                        path: '/service/dashboard',
                        component: Components.ServiceDashboard,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Consumables',
                        exact: true,
                        path: '/Services/Consumables',
                        component: () => <Components.Consumables type="C" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Idle Time Analysis',
                        exact: true,
                        path: '/Services/IdleTimeAnalysis',
                        component: () => <Components.IdleTimeAnalysis />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Idle Time Analysis-Reason',
                        exact: true,
                        path: '/Services/IdleTimeAnalysisReason',
                        component: () => <Components.IdleTimeAnalysisReason />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Invoiced Hours',
                        exact: true,
                        path: '/Services/InvoicedHours',
                        component: Components.InvoicedHours,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Credit Hours',
                        exact: true,
                        path: '/Services/CreditsHours',
                        component: Components.CreditsHours,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Mobilovan',
                        exact: true,
                        path: '/Services/Mobilovan',
                        component: () => <Components.Consumables type="M" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'NPW Rectification',
                        exact: true,
                        path: '/Services/NPWRectification',
                        component: () => <Components.Consumables type="R" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Parts Warranty Write Off',
                        exact: true,
                        path: '/Services/PartsWarrantyWriteOff',
                        component: () => <Components.Consumables type="P" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Service Warranty Write Off',
                        exact: true,
                        path: '/Services/ServiceWarrantyWriteOff',
                        component: () => <Components.Consumables type="S" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Technician Efficiency',
                        exact: true,
                        path: '/Services/Efficiency',
                        component: () => <Components.TechnicianEfficiency />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Invoiced Hours Calender',
                        exact: true,
                        path: '/Services/InvoicedhoursCalender',
                        component: () => <Components.InvoiceHoursCalendar />,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            {
                label: 'Parts',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <BuildIcon />,
                children: [
                    // {
                    //     label: 'Dashboard',
                    //     exact: true,
                    //     path: '/parts/dashboard',
                    //     component: Components.HeadLinesDashboard
                    // },
                    {
                        label: 'Parts',
                        exact: true,
                        path: '/parts/PartsSales/P',
                        component: () => <Components.PartSales code="P" desc="Part Sales - Cash Sales (P)" />,
                        authType: TypeOfAuth.Auth
                    },

                    {
                        label: 'Trade Centre',
                        exact: true,
                        path: '/parts/PartsSales/B',
                        component: () => <Components.PartSales code="B" desc="Part Sales - Trade Center (B)" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Workshop',
                        exact: true,
                        path: '/parts/PartsSales/w',
                        component: () => <Components.PartSales code="W" desc="Part Sales - Workshop Center (w)" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Car Express',
                        exact: true,
                        path: '/parts/PartsSales/f',
                        component: () => <Components.PartSales code="F" desc="Part Sales - Workshop Center (w)" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Total',
                        exact: true,
                        path: '/parts/PartsSales/A',
                        component: () => <Components.PartSales code="A" desc="Part Sales - Total" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used',
                        exact: true,
                        path: '/parts/PartsSales/U',
                        component: () => <Components.PartSales code="U" desc="Part Sales - Used (U)" />,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            {
                label: 'Workshop',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <DescriptionIcon />,
                children: [
                    {
                        label: 'Shift Calendar',
                        exact: true,
                        path: '/ShiftCalendar',
                        component: Components.ShiftCalenderComp,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    },
                    {
                        label: 'Resource Calendar',
                        exact: true,
                        path: '/ResourceCalendar',
                        component: Components.ResourceCalenderComp,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    }
                ]
            },
            // {
            //     label: 'WIP Hours',
            //     exact: true,
            //     path: '/wipHours',
            //     component: Components.WipHours,
            //     authType: TypeOfAuth.Auth,
            //     icon: <SpeedIcon />
            // },
            // {
            //     label: 'Sales2',
            //     authType: TypeOfAuth.Auth,
            //     children: [
            //         {
            //             label: 'Sales Dashboard',
            //             exact: true,
            //             path: '/salesDashboard',
            //             component: () => <Components.SalesDashboardSetting />,
            //             authType: TypeOfAuth.Auth,
            //             icon: <SpeedIcon />
            //         },
            //         {
            //             label: 'Stocking Charges',
            //             exact: true,
            //             path: '/stockingCharges',
            //             component: () => <Components.StokingChargesScreen />,
            //             authType: TypeOfAuth.Auth,
            //             icon: <SpeedIcon />
            //         }
            //     ]
            // },
            {
                label: 'Settings',
                authType: TypeOfAuth.Auth,
                children: [
                    {
                        label: 'Branch Settings',
                        path: '/branch_Codes',
                        exact: true,
                        component: Components.Braches,
                        authType: TypeOfAuth.Auth,
                        icon: <BuildIcon />
                    },
                    {
                        label: 'Sales Performance ',
                        authType: TypeOfAuth.Auth,
                        children: [
                            {
                                label: 'New Sales Exec Targets',
                                exact: true,
                                path: '/NewSalesCategoryBudget',
                                component: () => <Components.NewUsedSalesCategoryBudget vehicleType={'N'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Used Sales Exec Targets',
                                exact: true,
                                path: '/UsedVehicleReportingBudget',
                                component: () => <Components.NewUsedSalesCategoryBudget vehicleType={'U'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'New Vehicle Internal Budget',
                                exact: true,
                                path: '/NewVehicleReportingInternalBudget',
                                component: () => <Components.NewUsedReportingInternalBudget vehicleType={'N'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Used Vehicle Internal Budget',
                                exact: true,
                                path: '/UsedVehicleReportingInternalBudget',
                                component: () => <Components.NewUsedReportingInternalBudget vehicleType={'U'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Finance Provider Intrest Rate',
                                expect: true,
                                path: '/FP_IntrestRate_Provider',
                                component: Components.FPIntrestRateProvider,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },

                            {
                                label: 'Finance Penetration Accounts',
                                exact: true,
                                path: '/Supplier',
                                component: () => <Components.FinanceCustomer />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'League Table Points',
                                exact: true,
                                path: '/league_table_points',
                                component: () => <Components.LeagueTableScreen />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Franchises',
                                exact: true,
                                path: '/franchise',
                                component: () => <Components.FranchiseScreen />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Sales Exec',
                                path: '/Lists/SalesExec',
                                exact: true,
                                component: Components.SalesExec,
                                authType: TypeOfAuth.Auth
                            }
                        ]
                    },

                    {
                        label: 'Budget',
                        authType: TypeOfAuth.Auth,
                        children: [
                            {
                                label: 'After Sales Vehicle Budget',
                                exact: true,
                                path: '/ReportingBudget',
                                component: Components.ReportingBudget,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'New Vehicle Budget',
                                exact: true,
                                path: '/NewVehicleReportingBudget',
                                component: () => <Components.NewUsedReportingBudget vehicleType={'N'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Used Vehicle Budget',
                                exact: true,
                                path: '/UsedSalesCategoryBudget',
                                component: () => <Components.NewUsedReportingBudget vehicleType={'U'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            }
                        ]
                    }
                ]
            }
        ]
    }
];
