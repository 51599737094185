import { get, post } from './http-calls';

export const getDiagnosticSheetsDropDown = async (jobId) => {
    let res = await get(`DiagnosticSheets/DropDown`, { useAuthToken: true });
    return res;
};

export const getDiagnosticSheetModelFalutList = async (id) => {
    let res = await get(`DiagnosticSheets/GetModelFalutList?Id=${id}`, { useAuthToken: true });
    return res;
};

export const getDiagnosticSheetHeaderDetails = async (HeaderRecordId) => {
    let res = await get(`DiagnosticSheets/HeaderDetails?Id=${HeaderRecordId}`, { useAuthToken: true });
    return res;
};

export const getDiagnosticSheetDetails = async (sheetId) => {
    let res = await get(`DiagnosticSheets/${sheetId}`, { useAuthToken: true });
    return res;
};

export const saveDiagnosticSheets = async (data) => {
    let res = await post(`DiagnosticSheets `, data, { useAuthToken: true });
    return res;
};
