import { get, post, put } from './http-calls';

export const addUpdateVorType = async (data) => {
    let res = await post(`VOR/VORTypes`, data, { useAuthToken: true });
    return res;
};

export const addUpdateVorReason = async (data) => {
    let res = await post(`VOR/VORReasons`, data, { useAuthToken: true });
    return res;
};