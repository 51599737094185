import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions } from '../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import '../User Management/UserManagement.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { DefaultButton } from '../../../Core/FormInput';
import { addVideo, getVideoCategory } from '../../../Core/Service/video-service';
import { InputLabel } from '@material-ui/core';
import getResizedImage from '../../../Core/CommonFunctions/imageResizer';

const AddVideoModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        videoID: '',
        videoTitle: '',
        videoURL: '',
        videoCategoryID: '',
        videoActive: true,
        errors: {},
        fields: {},
        thumbnail: ''
    });

    const VideoDetails = props.VideoDetails;
    const VideoCategoryDetails = props.VideoCategoryDetails;

    useEffect(async () => {
        if (VideoDetails) {
            setState((st) => ({
                ...st,
                videoID: VideoDetails.videoID,
                videoTitle: VideoDetails.videoTitle,
                videoURL: VideoDetails.videoURL,
                videoCategoryID: VideoCategoryDetails.videoCategoryID,
                videoActive: VideoDetails.videoActive,
                thumbnail: VideoDetails.videoThumbnail
            }));
        }
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            const nst = { ...st, [name]: value, fields: fields };
            return nst;
        });
    };

    const handelSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            if (VideoDetails) {
                let res = await addVideo(
                    state.videoID,
                    state.videoTitle,
                    state.videoURL,
                    VideoCategoryDetails.videoCategoryID,
                    state.videoActive,
                    state.thumbnail
                );
                if (!res.success) {
                    console.error(res);
                } else {
                    hideModal();
                    props.onFormSubmit(true);
                }
            } else {
                let res = await addVideo(null, state.videoTitle, state.videoURL, VideoCategoryDetails.videoCategoryID, state.videoActive, state.thumbnail);
                if (!res.success) {
                    console.error(res);
                } else {
                    hideModal();
                    props.onFormSubmit(true);
                }
            }
        }
    };

    // let handleImage = (bannerLogo) => (event) => {
    //     let reader = new FileReader();
    //     reader.onload = (e) => {
    //         const { result } = e.target;
    //         setState((st) => ({
    //             ...st,
    //             [bannerLogo]: result
    //         }));
    //     };
    //     reader.readAsDataURL(event.target.files[0]);
    // };

    let handleImage = (event) => {
        if (event.target.files[0].type.includes('image')) {
            getResizedImage(
                event.target.files[0],
                (fileUrl) => {
                    setState((state) => {
                        const nst = { ...state, thumbnail: fileUrl };
                        return nst;
                    });
                },
                200,
                300
            );
        } else {
            showToast('Wrong file selected');
        }
    };

    const clearImage = (name) => (e) => {
        setState((st) => ({ ...st, [name]: null }));
    };

    const modelgridStyle = {
        gridContainer: { height: '600px' },
        formatterImageContainer: { width: '100%', textAlign: 'center' },
        formatterImage: { width: 120, maxHeight: 40, backgroundSize: 'cover' },
        formatterEditBtn: { textAlign: 'center' }
    };

    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.videoURL) {
            formIsValid = false;
            errors['videoURL'] = 'URL cannot be empty';
        }
        if (!state.videoTitle) {
            formIsValid = false;
            errors['videoTitle'] = 'Title cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{VideoDetails ? <DialogTitles>Update Video</DialogTitles> : <DialogTitles>Add Video</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll" style={{ overflow: 'auto' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Video Title
                        </FormLabel>
                        <TextBox name="videoTitle" onChange={fieldChange} value={state.videoTitle} autoFocus={true} />
                        <span className="mandatoryfields">{state.errors['videoTitle']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Video URL
                        </FormLabel>
                        <TextBox name="videoURL" onChange={fieldChange} value={state.videoURL} />
                        <span className="mandatoryfields">{state.errors['videoURL']}</span>
                    </Grid>

                    <Grid item xs={12} md={4} sm={6}>
                        <InputLabel shrink>Thumbnail</InputLabel>
                        {state.thumbnail ? (
                            <SecondaryButton onClick={clearImage('thumbnail')}>Remove</SecondaryButton>
                        ) : (
                            <input type="file" name="thumbnail" onChange={handleImage} accept="image/*" />
                        )}
                    </Grid>
                    <Grid item xs={8} style={modelgridStyle.formatterImageContainer}>
                        {state.thumbnail && (
                            <img
                                src={state.thumbnail?.includes('base64') ? state.thumbnail : 'data:image/png;base64,' + state.thumbnail}
                                alt={'thumbnail'}
                                style={modelgridStyle.formatterImage}
                            />
                        )}
                    </Grid>

                    <Grid item xs={2} sm={2}>
                        <FormLabel component="legend" className="btn_add">
                            Active?
                        </FormLabel>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                        <DefaultButton
                            className={state.videoActive ? 'btnActive' : 'btninActive'}
                            value={true}
                            name="videoActive"
                            onClick={() => handleClick('videoActive', true)}
                        >
                            Yes
                        </DefaultButton>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                        <DefaultButton
                            className={state.videoActive ? 'btninActive' : 'btnActiveNo'}
                            value={false}
                            name="videoActive"
                            onClick={() => handleClick('videoActive', false)}
                        >
                            No
                        </DefaultButton>
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddVideoModal;
