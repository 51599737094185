import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import ReportGijgoGrid from './../../../internal/ReportsScreens/GijgoForReportsScreen/index';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { SecondaryButton, SquareButton } from '../../../../Core/FormInput/AppButton';
import { useState } from 'react';
import DialogComp from '../../../../Core/Modal/dialogModal';
import CreateAppraisalModal from './CreateAppraisalModal';

const baseColumns = [
    { field: 'action', sortable: false, title: `Action`, width: 10, filterable: false, align: 'left' },
    {
        field: 'label',
        title: `Label`,
        width: 100
    },
    {
        field: 'positionIndex',
        title: `Index`,
        width: 30
    },
    {
        field: 'controlType',
        title: `Type`,
        width: 30
    },

    {
        field: 'required',
        title: `Is Required?`,
        width: 30
    },
    {
        field: 'readonly',
        title: `Is Readonly?`,
        width: 30
    },
    {
        field: 'hidden',
        title: `Hidden`,
        width: 30
    },
    {
        field: 'editRequired',
        title: `Is Edit Required?`,
        width: 35
    },
    {
        field: 'tooltip',
        title: `Tooltip`,
        width: 50
    },
    {
        field: 'controlValueApiEndPoint',
        title: `End point`,
        width: 60
    }
];

const CreateAppraislColumnsList = (props) => {
    const [state, setState] = useState({ showModal: false, screenColumnID: null });

    const addButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center" style={{ paddingRight: '7px' }}>
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px',
                        marginLeft: '20px'
                    }}
                    onClick={() =>
                        setState((st) => ({
                            ...st,
                            showModal: true,
                            screenColumnID: null
                        }))
                    }
                >
                    <AddIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="space-evenly" alignItems="center">
                <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() =>
                            setState((st) => ({
                                ...st,
                                showModal: true,
                                screenColumnID: record.id
                            }))
                        }
                    >
                        <EditIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let branchCol = tempCols.find((element) => element.field === 'action');
        if (branchCol) {
            branchCol.renderer = editButton({});
        }
        return tempCols;
    }, [props.MeasureID]);

    const handleClose = (res) => {
        let newSt = {};
        newSt.showModal = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };

    const baseUrl = `AppraisalScreenColumns?ScreenID=${props.data.screenId}`;

    return (
        <div className="report-screen-container">
            <Grid item container spacing={1} xs={12} style={{ position: 'relative' }}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isReload={state.isReload}
                        AddBtn={addButton}
                    />
                </Grid>
            </Grid>
            {state.showModal ? (
                <DialogComp
                    title={`${state.screenColumnID ? 'Update' : 'Add'} Appraisal Screen Columns `}
                    maxWidth="lg"
                    onClose={() => handleClose(false)}
                    fullWidth
                >
                    <CreateAppraisalModal onClose={() => handleClose(true)} screenColumnID={state.screenColumnID} screenID={props.data.screenId} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default CreateAppraislColumnsList;
