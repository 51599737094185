import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import User from './imgs/User.svg';
import Vehicle from './imgs/Vehicle.svg';
import Service from './imgs/Service.png';
import Damage from './imgs/Damage.png';
import VehPhotos from './imgs/VehiclePhotos.png';
import AppInfo from './imgs/AppraisalInfo.png';
import SignIcon from './imgs/sign.png';
import AppCustDetails from './AppCustDetails';
import ScreenSteps from '../../../../Core/ScreenSteps/ScreenSteps';
import {
    postAppraisal,
    getAppraisalByRef,
    getAppraisalPhotosByRef,
    getAppraisalStesps,
    setFormGlobalConfig,
    getStockbookList
} from '../../../../Core/Service/apprisal-service';
import AppVehDetails from './AppVehDetails';
import { CompassCalibrationOutlined } from '@material-ui/icons';
import AppContext from '../../../../App/AppContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

let CreateAppraisal = function () {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const VehicleTypeId = params.get('Vehid');
    const appraisalId = params.get('appraisalId');
    const stockIdParam = params.get('stockId');
    let isInEdit = appraisalId ? true : false;
    const { hideModal, showToast } = useContext(AppContext);
    const history = useHistory();

    let [state, setState] = useState({
        customerDetails: {},
        vehicleDetails: {},
        serviceHistory: {},
        damageFaces: {},
        vehiclePhotos: {},
        vehicleAppraisalDetails: {},
        signatures: {},
        VehicleTypeId: VehicleTypeId,
        appraisalRecordId: appraisalId,
        showLoader: false,
        vehicleTypeIdList: [],
        colourIdList: []
    });

    let [formErrors, setFormErrors] = useState([]);

    let steps = [
        {
            label: 'Customer',
            heading: 'Customer Details',
            icon: User,
            component: (
                <AppVehDetails
                    fromName={'customerDetails'}
                    screenNumber={1}
                    screenState={state}
                    setScreenState={setState}
                    heading={'Customer Details'}
                    VehicleTypeId={VehicleTypeId}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    appraisalRecordId={appraisalId}
                />
            )
        },
        {
            label: 'Vehicle',
            heading: 'Vehicle Details',
            icon: Vehicle,
            component: (
                <AppVehDetails
                    fromName={'vehicleDetails'}
                    screenNumber={2}
                    screenState={state}
                    setScreenState={setState}
                    heading={'Vehicle Details'}
                    VehicleTypeId={VehicleTypeId}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                />
            )
        },
        {
            label: 'Service',
            icon: Service,
            heading: 'Service History',
            component: (
                <AppVehDetails
                    fromName={'serviceHistory'}
                    screenNumber={3}
                    screenState={state}
                    setScreenState={setState}
                    heading={'Service History'}
                    VehicleTypeId={VehicleTypeId}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                />
            )
        },
        {
            label: 'Damage',
            heading: 'Vehicle Damage',
            icon: Damage,
            component: (
                <AppVehDetails
                    fromName={'damageFaces'}
                    screenNumber={4}
                    screenState={state}
                    setScreenState={setState}
                    heading={'Vehicle Damage'}
                    VehicleTypeId={VehicleTypeId}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                />
            )
        },
        {
            label: 'Photos',
            heading: 'Vehicle Photos',
            icon: VehPhotos,
            component: (
                <AppVehDetails
                    fromName={'vehiclePhotos'}
                    screenNumber={5}
                    screenState={state}
                    setScreenState={setState}
                    heading={'Vehicle Photos'}
                    VehicleTypeId={VehicleTypeId}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                />
            )
        },
        {
            label: 'Appraisal',
            icon: AppInfo,
            component: (
                <AppVehDetails
                    fromName={'vehicleAppraisalDetails'}
                    screenNumber={6}
                    screenState={state}
                    setScreenState={setState}
                    heading={'Appraisal Details'}
                    VehicleTypeId={VehicleTypeId}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                />
            )
        },
        {
            label: 'Signature',
            heading: 'Signature',
            icon: SignIcon,
            component: (
                <AppVehDetails
                    fromName={'signatures'}
                    screenNumber={7}
                    screenState={state}
                    setScreenState={setState}
                    heading={'Signature'}
                    VehicleTypeId={VehicleTypeId}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                />
            )
        }
    ];

    function autoSuggestItemRender(name, item) {
        if (name === 'firstName') {
            return (
                <span>
                    {item.firstName} {item.lastName} - {item.postCode}
                </span>
            );
        } else if (name === 'postCode') {
            return (
                <span>
                    {item.addressLine1} {item.addressLine2} - {item.postCode}
                </span>
            );
        }
    }

    function onAutocompleteSelection(name, item) {
        let obj = {};
        Object.keys(item).forEach((k) => (obj[k] = item[k] || ''));
        if (name === 'firstName') {
            setState((st) => ({
                ...st,
                customerDetails: {
                    ...st.customerDetails,
                    ...obj
                }
            }));
            if (obj.customerId && obj.customerId > 0) {
                // dispatchShowSnackbar('Selected Customer will be updated.', 3000);
            }
        } else if (name === 'postCode') {
            setState((st) => ({
                ...st,
                customerDetails: {
                    ...st.customerDetails,
                    ...obj
                }
            }));
            if (obj.addressLine1 || obj.addressLine2 || obj.addressLine3 || obj.addressLine4) {
                // dispatchShowSnackbar('Address has been overwritten.', 3000);
            }
        }
    }

    function onDataPullerFetched(name, data) {
        let obj = {};
        Object.keys(data).forEach((k) => (obj[k] = data[k] || ''));

        if (name === 'regNo') {
            if (obj.regNo) {
                setState((st) => ({
                    ...st,
                    vehicleDetails: {
                        ...st.vehicleDetails,
                        ...obj,
                        vehicleTypeId: st.vehicleDetails.vehicleTypeId
                    }
                }));
            }
        } else if (name === 'guaranteedValue') {
            setState((st) => ({
                ...st,
                vehicleAppraisalDetails: {
                    ...st.vehicleAppraisalDetails,
                    ...obj
                }
            }));
        }
    }

    useEffect(async () => {
        if (stockIdParam && !appraisalId) {
            setState((st) => ({
                ...st,
                showLoader: true
            }));
            let res = await getStockbookList(stockIdParam);
            if (res.success) {
                let resCustomerDetails = res.data.appraisalStockVM.customerDetails;
                let resVehicleDetails = {};
                let data = res.data.appraisalStockVM.vehicleDetails;
                resVehicleDetails = {
                    ...data,
                    vehicleTypeId: state.vehicleTypeIdList?.find((o) => o.name == data.vehicleType?.trim())?.id,
                    colourId: state.colourIdList?.find((o) => o.name == data.colour?.trim())?.id
                };
                setState((st) => {
                    return {
                        ...st,
                        customerDetails: state.vehicleTypeIdList.length == 0 && state.colourIdList.length == 0 ? resCustomerDetails : st.customerDetails,
                        vehicleDetails: resVehicleDetails || {},
                        showLoader: false
                    };
                });
            } else {
                setState((st) => {
                    return {
                        ...st,

                        showLoader: false
                    };
                });
            }
        }
    }, [state.vehicleTypeIdList, state.colourIdList]);

    useEffect(async () => {
        setFormGlobalConfig({
            editMode: isInEdit
        });
        if (appraisalId) {
            setState((st) => ({
                ...st,
                showLoader: true
            }));
            let Res = await getAppraisalByRef(appraisalId);
            let photoRes = await getAppraisalPhotosByRef(appraisalId);
            let newSt = Res.data;
            setFormGlobalConfig({ allReadonly: newSt.vehicleAppraisalDetails.appraisalStatusId === 3 }, true);
            let newPhotoRes = photoRes.data;
            let newdamageFaces = {
                images: newPhotoRes.damageFaces.images,
                userNotes: newSt.damageFaces.userNotes
            };
            setState((st) => {
                return {
                    ...st,
                    appraisalRecordId: newSt.appraisalRecordId,
                    customerDetails: newSt.customerDetails || {},
                    vehicleDetails: newSt.vehicleDetails || {},
                    serviceHistory: newSt.serviceHistory || {},
                    damageFaces: newdamageFaces || {},
                    vehiclePhotos: newPhotoRes.vehiclePhotos || {},
                    vehicleAppraisalDetails: newSt.vehicleAppraisalDetails || {},
                    signatures: newPhotoRes.signatures || {},
                    showLoader: false
                };
            });
        }
    }, []);

    async function formSubmit() {
        setState((st) => ({
            ...st,
            showLoader: true
        }));
        let res = await postAppraisal(state);
        if (!res.error.errorCode) {
            if (stockIdParam) {
                history.goBack();
            } else if (!res.error.message) {
                history.push('Appraisal_Dashboard');
            }
        } else {
            showToast(res.error.message);
        }
        setState((st) => ({
            ...st,
            showLoader: false
        }));
    }
    const GlobalCss = withStyles({
        '@global': {
            '.MuiFormLabel-root': {
                color: 'black'
            },
            '.MuiInputBase-input.Mui-disabled': {
                color: 'black'
            },
            '.MuiSelect-select.Mui-disabled': {
                fontWeight: 500
            }
        }
    })(() => null);
    return (
        <>
            {state.showLoader ? (
                <div style={{ background: 'white', height: 'calc(100vh - 110px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <GlobalCss />
                    <ScreenSteps steps={steps} formErrors={formErrors} appraisalId={appraisalId} onFinalSubmit={formSubmit}></ScreenSteps>
                </>
            )}
        </>
    );
};

export default CreateAppraisal;
