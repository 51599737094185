import {get, post } from './http-calls';

export const getDropdowns = async() => {
    let res = await get(`translations/dropdowns`, 
    { useAuthToken: true });
    return res;
}

export const getAllTranslations = async(TranslationFileID, TranslationCategory, TranslationKey) => {
    let res = await get(`translations/all/${TranslationFileID}/${TranslationCategory}/${TranslationKey}`,
    { useAuthToken: true });
    return res;
}

export const getEngTranslations = async() => {
    let res = await get(`translations/english`,
    { useAuthToken: true });
    return res;
}

export const addUpdate = async(data) => {
    let res = await post(`translations`, data,
    { useAuthToken: true });
    return res;
}