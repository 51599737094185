import React from 'react';
import AddEditActionLog from './addEditActionLog';
import { useState } from 'react';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import CommonGijgoGrid from '../../../../Core/Controls/GijgoGrid/index';
import { moment } from 'moment';
import MotHistory from './motHistory';
import EmailModal from './emailPopUp';
import ReportGijgoGrid from '../../ReportsScreens/GijgoForReportsScreen';
import { useMemo } from 'react';
import { Grid, Switch } from '@material-ui/core';
import { CustomIconButton, SquareButton } from '../../../../Core/FormInput/AppButton';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
import EditActionLog from './editActionLog';
import MailIcon from '@material-ui/icons/Mail';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import AddEditSiteVehicle from '../SiteVehicles/addEditSiteVehicle';
import UpdateIcon from '@material-ui/icons/Update';
import formatters from '../../../../Core/Grid/inputFormatter';
import { AppStorage } from '../../../../Core/Service/storage-service';
import { getCommunicatorDropdownList } from '../../../../Core/Service/communicator-service';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import DescriptionIcon from '@material-ui/icons/Description';

const baseColumn = [
    { field: 'action', sortable: true, filterable: false, title: `Action `, width: 150 },
    {
        title: 'Action log',
        field: 'logText'
    },
    {
        title: 'Created By',
        field: 'cb',
        width: 150
    },

    {
        title: 'Action Date and Time',
        field: 'actiontime',
        width: 190,
        // filterable: false,
        renderer: formatters.DateTimeFormatter
    },

    { field: 'wipNumber', sortable: true, title: `WIP No`, width: 120 },
    { field: 'registration', sortable: true, title: 'Reg No.', width: 120 },
    {
        title: 'Customer',
        field: 'customerName',
        width: 150
    },

    {
        title: 'Current Balance',
        field: 'balance',
        width: 160,
        filterable: false
    },

    {
        title: 'Status',
        field: 'status',
        width: 150,
        isDropDown: true,
        listIdFromAPIResponse: 'wipStatus',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        field: 'vorDays',
        sortable: true,
        title: 'VOR Days',
        width: 130
    },
    {
        field: 'branchShortName',
        sortable: true,
        title: `Branch`,
        width: 120
    }
];

const OpenActionLog = (props) => {
    const portalSettings = AppStorage.getPortalSetting();

    const [show, setShow] = useState({
        actionLog: false,
        MotHistory: false,
        EmailPopUp: false,
        list: [],
        showMyLog: false,
        userID: props.location.state || '',
        id: null
    });

    const handleEdit = (val) => {
        setShow((st) => ({
            ...st,
            actionLog: true,
            id: val.id,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber,
            keyNo: val.keyNo
        }));
    };

    const handleUpdateStatus = (val) => {
        setShow((st) => ({ ...st, siteVehicle: true, headerRecordID: val.headerRecordID, reg: val.registration, wip: val.wipNumber }));
    };
    const handleEmail = (val) => {
        setShow((st) => ({
            ...st,
            EmailPopUp: true,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber,
            id: val.id,
            msg: val.logText
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <CustomIconButton onClick={() => handleUpdateStatus(record)} toolTipTitle="Vehicle Status">
                        <UpdateIcon fontSize="medium" style={{ color: '#183B68' }} />
                    </CustomIconButton>
                </Grid>
                <Grid item>
                    <CustomIconButton onClick={() => handleEdit(record)} toolTipTitle="Edit Log">
                        <EditIcon fontSize="medium" style={{ color: '#183B68' }} />
                    </CustomIconButton>
                </Grid>
                <Grid item>
                    <CustomIconButton onClick={() => handleEmail(record)} toolTipTitle="Mail Log">
                        <ScreenShareIcon fontSize="medium" style={{ color: '#183B68' }} />
                    </CustomIconButton>
                </Grid>
                <Grid item>
                    <CustomIconButton onClick={() => {}} toolTipTitle="Documnet">
                        <DescriptionIcon fontSize="medium" style={{ color: '#183B68' }} />
                    </CustomIconButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumn];
        let colAction = col.find((m) => m.field === 'action');

        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);

    const handleClose = (res) => {
        let newSt = {};
        newSt.EmailPopUp = false;
        newSt.MotHistory = false;
        newSt.actionLog = false;

        if (res) {
            newSt.isReload = new Date();
        } else {
            newSt.siteVehicle = false;
        }
        setShow((st) => ({
            ...st,
            ...newSt
        }));
    };

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Communicator', path: '/communicator_dash' },
        { name: 'Action Log', active: true }
    ];

    const handleChange = (event) => {
        setShow((st) => ({ ...st, [event.target.name]: event.target.checked }));
    };

    const rowDataStyle = (record) => {
        if (record.highPriority) {
            return {
                ['background-color']: portalSettings.portalHighPriorityJobColour || '#C4463B'
            };
        }
        return { ['background-color']: '' };
    };
    const colDataStyle = (record) => {
        if (record.highPriority) {
            return {
                ['color']: 'white'
            };
        }
        return {
            ['color']: 'black'
        };
    };
    let getItem = localStorage.getItem('FiltterParams');
    let FilterValues = getItem ? JSON.parse(getItem) : {};
    return (
        <div className="report-screen-container">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <BreadCrumbs crumbs={crumbs} />
                <div style={{ marginTop: 2 }}>
                    My Action Log{' '}
                    <Switch checked={show.showMyLog} onChange={handleChange} name="showMyLog" inputProps={{ 'aria-label': 'secondary checkbox' }} />
                </div>
            </div>
            {show.actionLog ? (
                <EditActionLog onClose={handleClose} headerRecordID={show.headerRecordID} id={show.id} reg={show.reg} wip={show.wip} keyNo={show.keyNo} />
            ) : null}
            {show.EmailPopUp ? (
                <DialogComp title="Make Visible To Customer" onClose={() => handleClose(false)} fullWidth maxWidth="md">
                    <EmailModal onClose={handleClose} reg={show.reg} wip={show.wip} id={show.id} headerRecordID={show.headerRecordID} msg={show.msg} />
                </DialogComp>
            ) : null}
            {show.siteVehicle ? (
                // <DialogComp title="Vehicle Status" onClose={() => handleClose(false)} fullWidth maxWidth="lg">
                <AddEditSiteVehicle onClose={handleClose} headerRecordID={show.headerRecordID} reg={show.reg} wip={show.wip} />
            ) : // </DialogComp>
            null}
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data?.total
                })}
                columns={column}
                getUrl={`Communicators/GetActionLogReminders?ShowJobs=s&ShowMyLog=${show.showMyLog ? show.showMyLog : ''}&CreatedUserID=${
                    show.userID ? show.userID : ''
                }`}
                displayPagination={true}
                isReload={show.isReload}
                setRowStyleOnCondition={true}
                setRowStyleFunction={rowDataStyle}
                setColStyleOnCondition={true}
                setColStyleFunction={colDataStyle}
                downloadName={'New Vehicle Stock'}
                dropDownListProvider={getCommunicatorDropdownList}
                FilterdParamsValues={FilterValues}
            />
        </div>
    );
};
export default OpenActionLog;
