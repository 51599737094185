import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import './WeeklyFlashReport.scss';
import PublicIcon from '@material-ui/icons/Public';
import moment from 'moment';
import { useEffect } from 'react';
import { getWeeklyFlashReport } from '../../../../../Core/Service/ReportScreens-service';
import { useState } from 'react';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';
import { useHistory } from 'react-router-dom';
import { OutstandingContCard, UsedSalesCard, UsedstockCard } from './SalesComm';
import InvoicedSaleProjection from '../InvoicedSaleProjection';

const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: 'Reporting', active: true },
    { name: 'DOC', active: true }
];

let ids = {
    ['MAXUS']: 3,
    ['TRUCK']: 2,
    ['VAN']: 1
};

const setPercentage = (profit, budget) => {
    if (!+profit) {
        return 0;
    } else {
        if (budget == null || budget == 0) {
            return 100;
        } else {
            let value = (+profit / +budget) * 100;

            return Math.round(value);
        }
    }
};

let styleTD = ({ children }) => {
    return (
        <td
            style={{
                fontWeight: '600'
            }}
        >
            {children}
        </td>
    );
};

let NewVanSale = [
    {
        name: 'Jan',
        Bt: 127,
        AWt: 142,
        Bytd: 127,
        AWytd: 142
    },
    {
        name: 'Feb',
        Bt: 139,
        AWt: 175,
        Bytd: 266,
        AWytd: 317
    },
    {
        name: 'Mar',
        Bt: 318,
        AWt: 282,
        Bytd: 584,
        AWytd: 599
    },
    {
        name: 'Apr',
        Bt: 243,
        AWt: 154,
        Bytd: 827,
        AWytd: 753
    },
    {
        name: 'May',
        Bt: 157,
        AWt: 65,
        Bytd: 984,
        AWytd: 818
    },
    {
        name: 'Jun',
        Bt: 192,
        AWt: 63,
        Bytd: 1176,
        AWytd: 881
    },
    {
        name: 'Jul',
        Bt: 192,
        AWt: 63,
        Bytd: 1176,
        AWytd: 881
    },
    {
        name: 'Aug',
        Bt: 105,
        AWt: 38,
        Bytd: 1439,
        AWytd: 994
    },
    {
        name: 'Sep',
        Bt: 332,
        AWt: 37,
        Bytd: 1771,
        AWytd: 1031
    },
    {
        name: 'Oct',
        Bt: 276,
        AWt: 37,
        Bytd: 2047,
        AWytd: 1058
    },
    {
        name: 'Nov',
        Bt: 172,
        AWt: 37,
        Bytd: 2219,
        AWytd: 1105
    },
    {
        name: 'Dec',
        Bt: 281,
        AWt: 463,
        Bytd: 2500,
        AWytd: 1568
    }
];

let NewTruckSale = [
    {
        name: 'Jan',
        Bt: 31,
        AWt: 21,
        Bytd: 33,
        AWytd: 21
    },
    {
        name: 'Feb',
        Bt: 22,
        AWt: 65,
        Bytd: 53,
        AWytd: 86
    },
    {
        name: 'Mar',
        Bt: 65,
        AWt: 56,
        Bytd: 118,
        AWytd: 142
    },
    {
        name: 'Apr',
        Bt: 68,
        AWt: 52,
        Bytd: 186,
        AWytd: 194
    },
    {
        name: 'May',
        Bt: 52,
        AWt: 22,
        Bytd: 236,
        AWytd: 216
    },
    {
        name: 'Jun',
        Bt: 55,
        AWt: 21,
        Bytd: 293,
        AWytd: 237
    },
    {
        name: 'Jul',
        Bt: 51,
        AWt: 29,
        Bytd: 344,
        AWytd: 266
    },
    {
        name: 'Aug',
        Bt: 51,
        AWt: 7,
        Bytd: 395,
        AWytd: 273
    },
    {
        name: 'Sep',
        Bt: 115,
        AWt: 78,
        Bytd: 510,
        AWytd: 351
    },
    {
        name: 'Oct',
        Bt: 60,
        AWt: 76,
        Bytd: 570,
        AWytd: 427
    },
    {
        name: 'Nov',
        Bt: 48,
        AWt: 7,
        Bytd: 619,
        AWytd: 434
    },
    {
        name: 'Dec',
        Bt: 31,
        AWt: 3,
        Bytd: 650,
        AWytd: 437
    }
];

let NewMaxusSale = [
    {
        name: 'Jan',
        Bt: 68,
        AWt: 44,
        Bytd: 68,
        AWytd: 44
    },
    {
        name: 'Feb',
        Bt: 70,
        AWt: 17,
        Bytd: 118,
        AWytd: 68
    },
    {
        name: 'Mar',
        Bt: 125,
        AWt: 30,
        Bytd: 268,
        AWytd: 91
    },
    {
        name: 'Apr',
        Bt: 121,
        AWt: 15,
        Bytd: 384,
        AWytd: 106
    },
    {
        name: 'May',
        Bt: 94,
        AWt: 0,
        Bytd: 478,
        AWytd: 306
    },
    {
        name: 'Jun',
        Bt: 84,
        AWt: 0,
        Bytd: 562,
        AWytd: 106
    },
    {
        name: 'Jul',
        Bt: 92,
        AWt: 0,
        Bytd: 745,
        AWytd: 106
    },
    {
        name: 'Aug',
        Bt: 91,
        AWt: 0,
        Bytd: 745,
        AWytd: 106
    },
    {
        name: 'Sep',
        Bt: 139,
        AWt: 0,
        Bytd: 882,
        AWytd: 106
    },
    {
        name: 'Oct',
        Bt: 115,
        AWt: 0,
        Bytd: 997,
        AWytd: 106
    },
    {
        name: 'Nov',
        Bt: 117,
        AWt: 0,
        Bytd: 1114,
        AWytd: 106
    },
    {
        name: 'Dec',
        Bt: 86,
        AWt: 0,
        Bytd: 1200,
        AWytd: 106
    }
];

let currentYear = moment().year();

const WeeklyFlashReport = (props) => {
    const [state, setState] = useState({ tableData: {}, isLoader: true, PartsData: [], salesData: [], usedSaleData: [] });
    const [width, height] = useWindowSize();
    const history = useHistory();

    const onRedirect = (url, searchQuery) => {
        history.push({
            pathname: url,
            search: searchQuery
        });
    };

    let mtdSaleProfit = [
        {
            redirect: '/sales/NewVehicleProfitReport',
            query: '?CategoryType=2',
            label: 'New Truck',
            Budget: state.salesData[1]?.invoiceMTDBudget,
            Actual: state.salesData[1]?.invoiceMTDProfit,
            Variance: state.salesData[1]?.invoiceMTDProfit - state.salesData[1]?.invoiceMTDBudget
        },
        {
            redirect: '/sales/NewVehicleProfitReport',
            query: '?CategoryType=1',
            label: 'New Van',
            Budget: state.salesData[2]?.invoiceMTDBudget,
            Actual: state.salesData[2]?.invoiceMTDProfit,
            Variance: state.salesData[2]?.invoiceMTDProfit - state.salesData[2]?.invoiceMTDBudget
        },
        {
            redirect: '/sales/UsedVehicleProfitReport',
            query: '?CategoryType=1',
            label: 'Used Van',
            Budget: state.tableData?.mtdSalesGrossMarginUsedVanBudget,
            Actual: state.tableData?.mtdSalesGrossMarginUsedVanActual,
            Variance: state.tableData?.mtdSalesGrossMarginUsedVanVariance
        },
        {
            redirect: '/sales/UsedVehicleProfitReport',
            query: '?CategoryType=2',
            label: 'Used Truck',
            Budget: state.tableData?.mtdSalesGrossMarginUsedTruckBudget,
            Actual: state.tableData?.mtdSalesGrossMarginUsedTruckActual,
            Variance: state.tableData?.mtdSalesGrossMarginUsedTruckVariance
        },
        {
            label: 'Total',
            Budget: state.tableData?.mtdSalesGrossMarginTotalBudget,
            Actual: state.tableData?.mtdSalesGrossMarginTotalActual,
            Variance: state.tableData?.mtdSalesGrossMarginTotalVariance
        }
    ];

    let slabValueConfig = [
        {
            title: 'Vehicle Debtors',
            searchQuery: `?&measuredesc=${'Vehicle Debt'}&MeasureID=${9}`,
            reDirectUrl: '/AgedMeasures/VehicleDebt',
            data: [
                {
                    slabName: 'Current',
                    unit: state.tableData?.vehicleDebtorsCountCurrent,
                    perc: state.tableData?.vehicleDebtorsAmountCurrentPerc,
                    value: state.tableData?.vehicleDebtorsAmountCurrent
                },
                {
                    slabName: '30 Days',
                    unit: state.tableData?.vehicleDebtorsCount30daygreater,
                    perc: state.tableData?.vehicleDebtorsCount30daygreaterPerc,
                    value: state.tableData?.vehicleDebtorsAmount30daygreater
                },
                {
                    slabName: '60 Days',
                    unit: state.tableData?.vehicleDebtorsCount60daygreater,
                    perc: state.tableData?.vehicleDebtorsCount60daygreaterPerc,
                    value: state.tableData?.vehicleDebtorsAmount60daygreater
                },
                {
                    slabName: '90 Days',
                    unit: state.tableData?.vehicleDebtorsCount90daygreater,
                    perc: state.tableData?.vehicleDebtorsCount90daygreaterPerc,
                    value: state.tableData?.vehicleDebtorsAmount90daygreater
                },
                {
                    slabName: 'Total',
                    unit: state.tableData?.vehicleDebtorsTotalCount,
                    perc: state.tableData?.vehicleDebtorsTotalperc,
                    value: state.tableData?.vehicleDebtorsTotalAmount
                }
            ],

            headerClassName: 'g-bg-pink'
        },
        {
            title: 'Aged WIP',
            searchQuery: `?&measuredesc=${'Work In Progress'}&MeasureID=${1}`,
            reDirectUrl: '/AgedMeasures/WorkInProgress',
            data: [
                {
                    slabName: 'Current',
                    unit: state.tableData?.agedWIPCurrentCount,
                    perc: state.tableData?.agedWIPCurrentperc,
                    value: state.tableData?.agedWIPAmountCurrent
                },
                {
                    slabName: '30 Days',
                    unit: state.tableData?.agedWIP30daysgreaterCount,
                    perc: state.tableData?.agedWIP30daysgreaterperc,
                    value: state.tableData?.agedWIP30daysgreaterAmount
                },
                {
                    slabName: '60 Days',
                    unit: state.tableData?.agedWIP60daysgreaterCount,
                    perc: state.tableData?.agedWIP60daysgreaterperc,
                    value: state.tableData?.agedWIP60daysgreaterAmount
                },
                {
                    slabName: '90 Days',
                    unit: state.tableData?.agedWIP90daysgreaterCount,
                    perc: state.tableData?.agedWIP90daysgreaterperc,
                    value: state.tableData?.agedWIP90daysgreaterAmount
                },
                {
                    slabName: 'Total',
                    unit: state.tableData?.agedWIPTotalCount,
                    perc: state.tableData?.agedWIPTotalperc,
                    value: state.tableData?.agedWIPTotalAmount
                }
            ],
            headerClassName: 'g-bg-bluegray'
        },
        {
            title: 'Cash Sales',
            searchQuery: `?&measuredesc=${'Cash'}&MeasureID=${5}`,
            reDirectUrl: '/AgedMeasures/Cash',
            data: [
                {
                    slabName: 'Current',
                    unit: state.tableData?.cashSalesCurrentCount,
                    perc: state.tableData?.cashSalesCurrentperc,
                    value: state.tableData?.cashSalesCurrentAmount
                },
                {
                    slabName: '30 Days',
                    unit: state.tableData?.cashSales30daygreaterCount,
                    perc: state.tableData?.cashSales30daygreaterperc,
                    value: state.tableData?.cashSales30daygreaterAmount
                },
                {
                    slabName: '60 Days',
                    unit: state.tableData?.cashSales60daygreaterCount,
                    perc: state.tableData?.cashSales60daygreaterperc,
                    value: state.tableData?.cashSales60daygreaterAmount
                },
                {
                    slabName: '90 Days',
                    unit: state.tableData?.cashSales90daygreaterCount,
                    perc: state.tableData?.cashSales90daygreaterperc,
                    value: state.tableData?.cashSales90daygreaterAmount
                },
                {
                    slabName: 'Total',
                    unit: state.tableData?.cashSalesTotalCount,
                    perc: state.tableData?.cashSalesTotalperc,
                    value: state.tableData?.cashSalesTotalAmount
                }
            ],
            headerClassName: 'g-bg-skype'
        },
        {
            title: 'Queries',
            searchQuery: `?&measuredesc=${'Account Queries'}&MeasureID=${6}`,
            reDirectUrl: '/AgedMeasures/AccountQueries',
            data: [
                {
                    slabName: 'Current',
                    unit: state.tableData?.queriesCurrentCount,
                    perc: state.tableData?.queriesCurrentperc,
                    value: state.tableData?.queriesCurrentAmount
                },
                {
                    slabName: '30 Days',
                    unit: state.tableData?.queries30daygreaterCount,
                    perc: state.tableData?.queries30daygreaterperc,
                    value: state.tableData?.queries30daygreaterAmount
                },
                {
                    slabName: '60 Days',
                    unit: state.tableData?.queries60daygreaterCount,
                    perc: state.tableData?.queries60daygreaterperc,
                    value: state.tableData?.queries60daygreaterAmount
                },
                {
                    slabName: '90 Days',
                    unit: state.tableData?.queries90daygreaterCount,
                    perc: state.tableData?.queries90daygreaterperc,
                    value: state.tableData?.queries90daygreaterAmount
                },
                {
                    slabName: 'Total',
                    unit: state.tableData?.queriesTotalCount,
                    perc: state.tableData?.queriesTotalperc,
                    value: state.tableData?.queriesTotalAmount
                }
            ],
            headerClassName: 'g-bg-cyan'
        }
    ];

    let TruckUsedSale = [
        {
            label: 'MTD',
            Actual: '',
            Budget: '',
            redirect: '/sales/UsedVehicleProfitReport',
            query: `?CategoryType=2`
        },
        {
            label: 'YTD',
            Budget: '',
            Actual: '',

            redirect: '/sales/UsedVehicleProfitReport',
            query: `?CategoryType=2&start=${moment().startOf('year').format('YYYY-MM-DD')}&end=${moment().endOf('year').format('YYYY-MM-DD')}`
        }
    ];

    let vanUsedSale = [
        {
            label: 'MTD',
            Actual: '',
            Budget: '',
            redirect: '/sales/UsedVehicleProfitReport',
            query: `?CategoryType=1`
        },
        {
            label: 'YTD',
            Actual: '',
            Budget: '',
            redirect: '/sales/UsedVehicleProfitReport',
            query: `?CategoryType=1&start=${moment().startOf('year').format('YYYY-MM-DD')}&end=${moment().endOf('year').format('YYYY-MM-DD')}`
        }
    ];

    let usedTruckStock = [
        {
            Actual: formatters.ThousandSeparatorWithoutZero(''),
            Budget: formatters.ThousandSeparatorWithoutZero('')
        },
        {
            Actual: formatters.CurrencyThousandSeparatorWithoutZero(''),
            Budget: formatters.CurrencyThousandSeparatorWithoutZero('')
        },
        {
            Actual: formatters.CurrencyThousandSeparatorWithoutZero(''),
            Budget: formatters.CurrencyThousandSeparatorWithoutZero('')
        }
    ];

    let usedVanStock = [
        {
            Actual: formatters.ThousandSeparatorWithoutZero(''),
            Budget: formatters.ThousandSeparatorWithoutZero('')
        },
        {
            Actual: formatters.CurrencyThousandSeparatorWithoutZero(''),
            Budget: formatters.CurrencyThousandSeparatorWithoutZero('')
        },
        {
            Actual: formatters.CurrencyThousandSeparatorWithoutZero(''),
            Budget: formatters.CurrencyThousandSeparatorWithoutZero('')
        }
    ];

    const pullTableData = async () => {
        let res = await getWeeklyFlashReport();
        setState((st) => ({
            ...st,
            tableData: res.data?.afterSales[0],
            PartsData: res.data?.partSales,
            totalPart: res.data?.partSales.reduce(
                (total, k) => {
                    total.partsAmount += k.partsAmount;
                    total.mtdBudget += k.mtdBudget;
                    return total;
                },
                { partsAmount: 0, mtdBudget: 0 }
            ),
            salesData: res.data?.sales || [],
            usedSaleData: res.data?.usedSales,
            isLoader: false
        }));
    };

    useEffect(() => {
        pullTableData();
    }, []);

    return (
        <div className="weekly-flash-report-container">
            <BreadCrumbs crumbs={crumbs(props.MeasureDesc)} />
            {state.isLoader ? (
                <Grid container spacing={2} style={{ marginTop: 10, height: 'calc(100vh - 180px)' }} justifyContent="center" alignItems="center">
                    <Grid item>
                        <CircularProgress color="secondary" />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={12}>
                            <div className="component-container g-brd-blue">
                                <div className="header-container g-bg-black">
                                    <span className="icon-container">
                                        <PublicIcon />
                                    </span>
                                    <span>MTD Sales Profit</span>
                                </div>
                                <div className="table-container table-responsive ">
                                    <Grid container justifyContent={width > 1280 ? 'space-around' : 'flex-start'} alignItems="center">
                                        {mtdSaleProfit.map((o, i) => {
                                            return (
                                                <Grid
                                                    className={mtdSaleProfit.length - 1 != i ? 'pointerHover' : ''}
                                                    onClick={() => onRedirect(o.redirect, o.query)}
                                                    item
                                                    container
                                                    xs={12}
                                                    sm={6}
                                                    md={3}
                                                    lg={2}
                                                    xl
                                                    justifyContent={'space-around'}
                                                    alignItems="center"
                                                    key={`saleprofit${i}`}
                                                    style={{
                                                        borderRight: width > 598 && mtdSaleProfit.length - 1 != i && '1px solid rgba(0,0,0,0.1)'
                                                    }}
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography style={{ fontWeight: 600, marginLeft: 30 }}>{o.label}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={4} md={7}>
                                                        <div style={{ textAlign: 'center', marginLeft: 20, height: 110, width: 110 }}>
                                                            <CircularProgressbar
                                                                value={setPercentage(o.Actual, o.Budget)}
                                                                text={`${setPercentage(o.Actual, o.Budget)}%`}
                                                                styles={buildStyles({
                                                                    textColor:
                                                                        setPercentage(o.Actual, o.Budget) < 50
                                                                            ? '#D92641'
                                                                            : setPercentage(o.Actual, o.Budget) >= 50 && setPercentage(o.Actual, o.Budget) < 80
                                                                            ? '#ff9f00'
                                                                            : setPercentage(o.Actual, o.Budget) >= 80
                                                                            ? '#569101'
                                                                            : '#D92641',
                                                                    pathColor:
                                                                        setPercentage(o.Actual, o.Budget) < 50
                                                                            ? '#D92641'
                                                                            : setPercentage(o.Actual, o.Budget) >= 50 && setPercentage(o.Actual, o.Budget) < 80
                                                                            ? '#ff9f00'
                                                                            : setPercentage(o.Actual, o.Budget) >= 80
                                                                            ? '#569101'
                                                                            : '#D92641',
                                                                    trailColor: '#d6d6d6',
                                                                    textSize: '27px'
                                                                })}
                                                            />
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={6} sm={4} md={5}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            <div>
                                                                <Typography style={{ fontSize: 12 }}>Actual</Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontSize: 15,
                                                                        fontWeight: 600,
                                                                        color: o.Actual ? (o.Actual <= o.Budget ? 'red' : 'green') : 'red'
                                                                    }}
                                                                >
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(o.Actual)}
                                                                </Typography>

                                                                <Typography style={{ fontSize: 12 }}>Budget &nbsp;</Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontSize: 15,
                                                                        fontWeight: 600
                                                                        // color: o.actualSale ? (o.actualSale <= state.budget[0].dailyBudget ? 'red' : 'green') : 'red'
                                                                    }}
                                                                >
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(o.Budget)}
                                                                </Typography>
                                                                <Typography style={{ fontSize: 12 }}>Variance &nbsp;</Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontSize: 15,
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(o.Variance)}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={12}>
                            <div className="component-container g-brd-blue">
                                <div className="header-container g-bg-black">
                                    <span className="icon-container">
                                        <PublicIcon />
                                    </span>
                                    <span>New Sales</span>
                                </div>
                                <div className="table-container table-responsive ">
                                    <table className="table table-bordered u-table--v1 mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ background: 'white' }}></th>
                                                <th colspan="2">MTD</th>
                                                <th colspan="2">YTD</th>
                                                <th colspan="2">{currentYear}</th>
                                                <th colspan="3">Orders</th>
                                                {/* <th>{currentYear}</th> */}
                                                {/* <th>% of</th> */}
                                                <th>{currentYear + 1}</th>
                                            </tr>
                                            <tr>
                                                <td> </td>
                                                <td>Invoiced </td>
                                                <td>Budget </td>
                                                <td>Invoiced </td>
                                                <td>Budget</td>
                                                <td>
                                                    Total Volume <small>( % Budget Achieved)</small>
                                                </td>
                                                <td>Budget Volume</td>
                                                <td>MTD </td>
                                                <td>YTD</td>
                                                <td>WIP</td>
                                                {/* <td>Budget Achieved</td> */}
                                                <td>WIP</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.salesData.map((data, index) => {
                                                let catId = ids[data.category];
                                                return (
                                                    <tr style={{ fontWeight: 600 }} key={`sales${index}`}>
                                                        <td>{data.category}</td>
                                                        <td
                                                            style={{
                                                                color: data.invoiceMTD ? (data.invoiceMTD <= data.invoiceMTDBudget ? 'red' : 'green') : 'red'
                                                            }}
                                                            onClick={() => onRedirect('/sales/NewVehicleProfitReport', `?CategoryType=${catId}`)}
                                                            className="pointerHover"
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(data.invoiceMTD)}
                                                        </td>
                                                        <td>{formatters.ThousandSeparatorWithoutZero(data.invoiceMTDBudget)}</td>
                                                        <td
                                                            onClick={() =>
                                                                onRedirect(
                                                                    '/sales/NewVehicleProfitReport',
                                                                    `?CategoryType=${catId}&start=${moment()
                                                                        .startOf('year')
                                                                        .format('YYYY-MM-DD')}&end=${moment().endOf('year').format('YYYY-MM-DD')}`
                                                                )
                                                            }
                                                            style={{
                                                                color: data.invoiceYTD ? (data.invoiceYTD <= data.invoiceYTDBudget ? 'red' : 'green') : 'red'
                                                            }}
                                                            className="pointerHover"
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(data.invoiceYTD)}
                                                        </td>
                                                        <td>{formatters.ThousandSeparatorWithoutZero(data.invoiceYTDBudget)}</td>
                                                        <td
                                                            onClick={() => onRedirect('/sales/VehicleNewOrder', `?CategoryType=${catId}`)}
                                                            className="pointerHover"
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(data.invoiceYear)}{' '}
                                                            <small>({formatters.ThousandSeparatorWithoutZero(data.invoiceBudgetAchieved)})</small>
                                                        </td>
                                                        <td>{formatters.ThousandSeparatorWithoutZero(data.invoiceYearBudget)}</td>
                                                        <td
                                                            onClick={() => onRedirect('/sales/VehicleNewOrder', `?CategoryType=${catId}`)}
                                                            className="pointerHover"
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(data.orderMTD)}
                                                        </td>
                                                        <td
                                                            onClick={() =>
                                                                onRedirect(
                                                                    '/sales/VehicleNewOrder',
                                                                    `?CategoryType=${catId}&startDate=${moment()
                                                                        .startOf('year')
                                                                        .format('YYYY-MM-DD')}&endDate=${moment().endOf('year').format('YYYY-MM-DD')}`
                                                                )
                                                            }
                                                            className="pointerHover"
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(data.orderYTD)}
                                                        </td>
                                                        <td>{formatters.ThousandSeparatorWithoutZero(data.orderWIP)}</td>
                                                        {/* <td
                                                            onClick={() => onRedirect('/sales/VehicleNewOrder', `?CategoryType=${catId}`)}
                                                            className="pointerHover"
                                                            style={{
                                                                color: data.invoiceYearProfit
                                                                    ? data.invoiceYearProfit <= data.invoiceYearBudget
                                                                        ? 'red'
                                                                        : 'green'
                                                                    : 'red'
                                                            }}
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(data.invoiceYearProfit)}{' '}
                                                            <small>({formatters.ThousandSeparatorWithoutZero(data.orderBudgetAchieved)})</small>
                                                        </td> */}
                                                        {/* <td>{formatters.ThousandSeparatorWithoutZero(data.invoiceYearBudget)}</td> */}
                                                        {/* <td>{formatters.ThousandSeparatorWithoutZero(data.orderBudgetAchieved)}</td> */}
                                                        <td>{formatters.ThousandSeparatorWithoutZero(data.orderWIPNextYear)}</td>
                                                    </tr>
                                                );
                                            })}
                                            {/* <tr style={{ fontWeight: 600 }}>
                                                <td style={{ fontWeight: 700 }}>Van</td>
                                                <td onClick={() => onRedirect('/sales/NewVehicleProfitReport', '?CategoryType=')} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/NewVehicleProfitReport',
                                                            `?CategoryType=&start=${moment().startOf('year').format('YYYY-MM-DD')}&end=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', '?CategoryType=')} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/VehicleNewOrder',
                                                            `?CategoryType=&startDate=${moment().startOf('year').format('YYYY-MM-DD')}&endDate=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', '?CategoryType=2')} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                            </tr>
                                            <tr style={{ fontWeight: 600 }}>
                                                <td>Maxus</td>
                                                <td onClick={() => onRedirect('/sales/NewVehicleProfitReport', '?CategoryType=2')} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/NewVehicleProfitReport',
                                                            `?CategoryType=2&start=${moment().startOf('year').format('YYYY-MM-DD')}&end=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', '?CategoryType=2')} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/VehicleNewOrder',
                                                            `?CategoryType=2&startDate=${moment().startOf('year').format('YYYY-MM-DD')}&endDate=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', '?CategoryType=2')} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Grid>

                        <Grid item container spacing={1} xs={12}>
                            {/* <Grid item xs={12}>
                                <div className="component-container g-brd-blue">
                                    <div className="header-container g-bg-black">
                                        <span className="icon-container">
                                            <PublicIcon />
                                        </span>
                                        <span>New Van Sale Projection - Invoiced Sales/ Wip v Budget</span>
                                    </div>
                                    <div>
                                        <InvoicedSaleProjection data={NewVanSale || []} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="component-container g-brd-blue">
                                    <div className="header-container g-bg-black">
                                        <span className="icon-container">
                                            <PublicIcon />
                                        </span>
                                        <span>New Truck Sale Projection - Invoiced Sales/ Wip v Budget</span>
                                    </div>
                                    <div>
                                        <InvoicedSaleProjection data={NewTruckSale || []} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="component-container g-brd-blue">
                                    <div className="header-container g-bg-black">
                                        <span className="icon-container">
                                            <PublicIcon />
                                        </span>
                                        <span>New Maxus Sale Projection - Invoiced Sales/ Wip v Budget</span>
                                    </div>
                                    <div className="newTable">
                                        <InvoicedSaleProjection data={NewMaxusSale || []} label={'New Van Sale Projection - Invoiced Sales/ Wip v Budget '} />
                                    </div>
                                </div>
                            </Grid> */}
                            {/* <Grid item xs={12}>
                            <div className="component-container g-brd-blue">
                                <div className="header-container g-bg-blue">
                                    <span className="icon-container">
                                        <PublicIcon />
                                    </span>
                                    <span>New Van Sales</span>
                                </div>
                                <div className="table-container table-responsive ">
                                    <table className="table table-bordered u-table--v1 mb-0">
                                        <thead>
                                            <tr>
                                                <th colspan="2">MTD</th>
                                                <th colspan="2">YTD</th>
                                                <th colspan="3">Orders</th>
                                                <th>{currentYear}</th>
                                                <th>{currentYear}</th>
                                                <th>% of</th>
                                                <th>{currentYear + 1}</th>
                                            </tr>
                                            <tr>
                                                <td>Invoiced </td>
                                                <td>Budget </td>
                                                <td>Invoiced </td>
                                                <td>Budget</td>
                                                <td>MTD </td>
                                                <td>YTD</td>
                                                <td>WIP</td>
                                                <td>Total Volume</td>
                                                <td>Budget Volume</td>
                                                <td>Budget Achieved</td>
                                                <td>WIP</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ fontWeight: 600 }}>
                                                <td onClick={() => onRedirect('/sales/NewVehicleProfitReport', '?CategoryType=1')} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/NewVehicleProfitReport',
                                                            `?CategoryType=1&start=${moment().startOf('year').format('YYYY-MM-DD')}&end=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', `?CategoryType=1`)} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/VehicleNewOrder',
                                                            `?CategoryType=1&startDate=${moment().startOf('year').format('YYYY-MM-DD')}&endDate=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', `?CategoryType=1`)} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="component-container g-brd-blue">
                                <div className="header-container g-bg-blue">
                                    <span className="icon-container">
                                        <PublicIcon />
                                    </span>
                                    <span>New Maxus Van Sales</span>
                                </div>
                                <div className="table-container table-responsive ">
                                    <table className="table table-bordered u-table--v1 mb-0">
                                        <thead>
                                            <tr>
                                                <th colspan="2">MTD</th>
                                                <th colspan="2">YTD</th>
                                                <th colspan="3">Orders</th>
                                                <th>{currentYear}</th>
                                                <th>{currentYear}</th>
                                                <th>% of</th>
                                                <th>{currentYear + 1}</th>
                                            </tr>
                                            <tr>
                                                <td>Invoiced </td>
                                                <td>Budget </td>
                                                <td>Invoiced </td>
                                                <td>Budget</td>
                                                <td>MTD </td>
                                                <td>YTD</td>
                                                <td>WIP</td>
                                                <td>Total Volume</td>
                                                <td>Budget Volume</td>
                                                <td>Budget Achieved</td>
                                                <td>WIP</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ fontWeight: 600 }}>
                                                <td onClick={() => onRedirect('/sales/NewVehicleProfitReport', '?CategoryType=3')} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/VehicleNewOrder',
                                                            `?CategoryType=3&startDate=${moment().startOf('year').format('YYYY-MM-DD')}&endDate=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', `?CategoryType=3`)} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/VehicleNewOrder',
                                                            `?CategoryType=3&startDate=${moment().startOf('year').format('YYYY-MM-DD')}&endDate=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', `?CategoryType=3`)} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Grid> */}
                        </Grid>
                        {/* <Grid item xs={12}>
                            <div className="component-container g-brd-blue">
                                <div className="header-container g-bg-blue">
                                    <span className="icon-container">
                                        <PublicIcon />
                                    </span>
                                    <span>New Van Sales</span>
                                </div>
                                <div className="table-container table-responsive ">
                                    <table className="table table-bordered u-table--v1 mb-0">
                                        <thead>
                                            <tr>
                                                <th colspan="2">MTD</th>
                                                <th colspan="2">YTD</th>
                                                <th colspan="3">Orders</th>
                                                <th>{currentYear}</th>
                                                <th>{currentYear}</th>
                                                <th>% of</th>
                                                <th>{currentYear + 1}</th>
                                            </tr>
                                            <tr>
                                                <td>Invoiced </td>
                                                <td>Budget </td>
                                                <td>Invoiced </td>
                                                <td>Budget</td>
                                                <td>MTD </td>
                                                <td>YTD</td>
                                                <td>WIP</td>
                                                <td>Total Volume</td>
                                                <td>Budget Volume</td>
                                                <td>Budget Achieved</td>
                                                <td>WIP</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ fontWeight: 600 }}>
                                                <td onClick={() => onRedirect('/sales/NewVehicleProfitReport', '?CategoryType=1')} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/NewVehicleProfitReport',
                                                            `?CategoryType=1&start=${moment().startOf('year').format('YYYY-MM-DD')}&end=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', `?CategoryType=1`)} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/VehicleNewOrder',
                                                            `?CategoryType=1&startDate=${moment().startOf('year').format('YYYY-MM-DD')}&endDate=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', `?CategoryType=1`)} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="component-container g-brd-blue">
                                <div className="header-container g-bg-blue">
                                    <span className="icon-container">
                                        <PublicIcon />
                                    </span>
                                    <span>New Maxus Van Sales</span>
                                </div>
                                <div className="table-container table-responsive ">
                                    <table className="table table-bordered u-table--v1 mb-0">
                                        <thead>
                                            <tr>
                                                <th colspan="2">MTD</th>
                                                <th colspan="2">YTD</th>
                                                <th colspan="3">Orders</th>
                                                <th>{currentYear}</th>
                                                <th>{currentYear}</th>
                                                <th>% of</th>
                                                <th>{currentYear + 1}</th>
                                            </tr>
                                            <tr>
                                                <td>Invoiced </td>
                                                <td>Budget </td>
                                                <td>Invoiced </td>
                                                <td>Budget</td>
                                                <td>MTD </td>
                                                <td>YTD</td>
                                                <td>WIP</td>
                                                <td>Total Volume</td>
                                                <td>Budget Volume</td>
                                                <td>Budget Achieved</td>
                                                <td>WIP</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ fontWeight: 600 }}>
                                                <td onClick={() => onRedirect('/sales/NewVehicleProfitReport', '?CategoryType=3')} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/VehicleNewOrder',
                                                            `?CategoryType=3&startDate=${moment().startOf('year').format('YYYY-MM-DD')}&endDate=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', `?CategoryType=3`)} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td
                                                    onClick={() =>
                                                        onRedirect(
                                                            '/sales/VehicleNewOrder',
                                                            `?CategoryType=3&startDate=${moment().startOf('year').format('YYYY-MM-DD')}&endDate=${moment()
                                                                .endOf('year')
                                                                .format('YYYY-MM-DD')}`
                                                        )
                                                    }
                                                    className="pointerHover"
                                                >
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td onClick={() => onRedirect('/sales/VehicleNewOrder', `?CategoryType=3`)} className="pointerHover">
                                                    {formatters.ThousandSeparatorWithoutZero('')}
                                                </td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                                <td>{formatters.ThousandSeparatorWithoutZero('')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Grid> */}
                        <Grid item xs={12}>
                            <div className="component-container g-brd-blue">
                                <div className="header-container g-bg-black">
                                    <span className="icon-container">
                                        <PublicIcon />
                                    </span>
                                    <span>Used Sales</span>
                                </div>
                                <Grid container>
                                    <Grid item xs={12} md={2}>
                                        <UsedSalesCard mtdProfit={''} mtdBudget={''} label={'Used Van Sales '} ytdProfit={''} ytdBudget={''} id={1} />
                                        {/* <UsedSalesCard mtdProfit={''} mtdBudget={''} label={'Used Truck Sales'} ytdProfit={''} ytdBudget={''} /> */}
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <UsedstockCard label={'Used Van Stock '} />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <UsedSalesCard mtdProfit={''} mtdBudget={''} label={'Used Truck Sales'} ytdProfit={''} ytdBudget={''} id={2} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <UsedstockCard label={'Used Truck Stock '} />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="component-container g-brd-blue">
                                <div className="header-container g-bg-black">
                                    <span className="icon-container">
                                        <PublicIcon />
                                    </span>
                                    <span>Aftersales</span>
                                </div>
                                <Grid container>
                                    <Grid item xs={12} md={3}>
                                        <Grid container justifyContent="center">
                                            <Grid item xs={12}>
                                                <div className="table-container table-responsive ">
                                                    <Grid container justifyContent="space-between">
                                                        <Grid xs={12}>
                                                            <Typography
                                                                style={{
                                                                    fontSize: 16,
                                                                    fontWeight: '600',
                                                                    textAlign: 'left',
                                                                    marginBottom: 5
                                                                }}
                                                            >
                                                                Parts MTD
                                                            </Typography>
                                                        </Grid>

                                                        {state?.PartsData?.map((k, i) => {
                                                            return (
                                                                <Grid item key={i}>
                                                                    <Typography className="mainCardTextPrimary  ml-10">{k.partsDepartment}</Typography>
                                                                    <div
                                                                        style={{
                                                                            marginBottom: 4,
                                                                            marginTop: 5,
                                                                            // display: 'flex',
                                                                            justifyContent: 'space-between'
                                                                        }}
                                                                    >
                                                                        <div className="salesCardTextPrimary  ">Actual</div>
                                                                        <div
                                                                            className="salesCurrencyFont  "
                                                                            style={{
                                                                                textAlign: 'center',
                                                                                color: k.partsAmount ? (k.partsAmount <= k.mtdBudget ? 'red' : 'green') : 'red'
                                                                            }}
                                                                        >
                                                                            {formatters.CurrencyThousandSeparatorWithoutZero(k.partsAmount) || 0}
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            //  display: 'flex',
                                                                            justifyContent: 'space-between'
                                                                        }}
                                                                    >
                                                                        <div className="salesCardTextPrimary  ">Budget</div>
                                                                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                                                            {' '}
                                                                            {formatters.CurrencyThousandSeparatorWithoutZero(k.mtdBudget) || 0}
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            );
                                                        })}

                                                        <Grid item onClick={() => onRedirect('/TradeCentre?desc=TradeCentre=c')}>
                                                            <Typography className="mainCardTextPrimary  ml-10">Total</Typography>
                                                            <div
                                                                style={{
                                                                    marginBottom: 5,
                                                                    marginTop: 10,
                                                                    // display: 'flex',
                                                                    justifyContent: 'space-between'
                                                                }}
                                                            >
                                                                <div className="salesCardTextPrimary  ">Actual</div>
                                                                <div
                                                                    className="salesCurrencyFont  "
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        color: state.totalPart.partsAmount
                                                                            ? state.totalPart.partsAmount <= state.totalPart.mtdBudget
                                                                                ? 'red'
                                                                                : 'green'
                                                                            : 'red'
                                                                    }}
                                                                >
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.totalPart.partsAmount) || 0}
                                                                </div>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    //  display: 'flex',
                                                                    justifyContent: 'space-between'
                                                                }}
                                                            >
                                                                <div className="salesCardTextPrimary  ">Budget</div>
                                                                <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                                                                    {' '}
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.totalPart.mtdBudget) || 0}
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container></Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="table-container table-responsive " style={{ marginTop: 20 }}>
                                                    <table className="table table-bordered u-table--v1 mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Service Productivity</th>
                                                                <td>Opening WIP Hours</td>
                                                                <td>Current WIP Hours</td>
                                                                <td>Hours Sold</td>
                                                                <td>Attended Hours</td>
                                                                <td>Booked Hours</td>
                                                                <td>Productivity %</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th></th>
                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/RepotingTab')}
                                                                    className="pointerHover"
                                                                >
                                                                    {formatters.ThousandSeparatorWithoutZero(state.tableData?.serviceProductivityOpenWIP)}
                                                                </td>
                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/RepotingTab')}
                                                                    className="pointerHover"
                                                                >
                                                                    {formatters.ThousandSeparatorWithoutZero(state.tableData?.serviceProductivityCurrentWIP)}
                                                                </td>
                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/Services/InvoicedHours')}
                                                                    className="pointerHover"
                                                                >
                                                                    {formatters.ThousandSeparatorWithoutZero(state.tableData?.serviceProductivityHoursSold)}
                                                                </td>

                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/Services/InvoicedHours')}
                                                                    className="pointerHover"
                                                                >
                                                                    {' '}
                                                                    {formatters.ThousandSeparatorWithoutZero(state.tableData?.serviceMtdAttendedHr)}
                                                                </td>
                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/Services/InvoicedHours')}
                                                                    className="pointerHover"
                                                                >
                                                                    {' '}
                                                                    {formatters.ThousandSeparatorWithoutZero(state.tableData?.serviceMtdBookedHr)}
                                                                </td>
                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/Services/InvoicedHours')}
                                                                    className="pointerHover"
                                                                >
                                                                    {formatters.ThousandSeparatorWithoutZero(state.tableData?.serviceProductivityProdHours)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{ fontWeight: 600 }}>% of Budget Achieved</th>
                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/RepotingTab')}
                                                                    className="pointerHover"
                                                                >
                                                                    {formatters.ThousandSeparatorWithoutZero(
                                                                        state.tableData?.serviceProductivityOpenWIPAchieved
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/RepotingTab')}
                                                                    className="pointerHover"
                                                                >
                                                                    {formatters.ThousandSeparatorWithoutZero(
                                                                        state.tableData?.serviceProductivityCurrentWIPAchieved
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/Services/InvoicedHours')}
                                                                    className="pointerHover"
                                                                >
                                                                    {formatters.ThousandSeparatorWithoutZero(state.tableData?.serviceHoursSoldBudgetAchieved)}
                                                                </td>
                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/Services/InvoicedHours')}
                                                                    className="pointerHover"
                                                                >
                                                                    {' '}
                                                                    {formatters.ThousandSeparatorWithoutZero(state.tableData?.serviceMtdAttendedHrBudget)}
                                                                </td>
                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/Services/InvoicedHours')}
                                                                    className="pointerHover"
                                                                >
                                                                    {' '}
                                                                    {formatters.ThousandSeparatorWithoutZero(state.tableData?.serviceMtdBookedHrBudget)}
                                                                </td>
                                                                <td
                                                                    style={{ fontWeight: 600 }}
                                                                    onClick={() => onRedirect('/Services/InvoicedHours')}
                                                                    className="pointerHover"
                                                                >
                                                                    {formatters.ThousandSeparatorWithoutZero(
                                                                        state.tableData?.serviceProductivityBudgetAchieved
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="table-container table-responsive " style={{ marginTop: 20 }}>
                                                    <table className="table table-bordered u-table--v1 mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th> Outstanding Cont.</th>
                                                                <th>Current Month</th>
                                                                <th>(Prior Month)</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td>Impacting on cash flow</td>
                                                                <td style={{ fontWeight: 600 }}>
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(
                                                                        state.tableData?.outstandingContCurrentWkImpactingcashflow
                                                                    )}
                                                                </td>
                                                                <td style={{ fontWeight: 600 }}>
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(
                                                                        state.tableData?.outstandingContPriorWeekImpactingcashflow
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pending Registrations</td>
                                                                <td style={{ fontWeight: 600 }}>
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(
                                                                        state.tableData?.outstandingContCurrentWkPendingRegistrations
                                                                    )}
                                                                </td>
                                                                <td style={{ fontWeight: 600 }}>
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(
                                                                        state.tableData?.outstandingContPriorWeekPendingRegistrations
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} xs={12}>
                        \
                    </Grid>

                    <Grid item container spacing={1} xs={12}>
                        {slabValueConfig.map((p) => {
                            return (
                                <Grid item xs={12} md={6} lg={3} xl={3}>
                                    <div className="component-container g-brd-blue">
                                        <div className={`header-container ${p.headerClassName}`}>
                                            <span className="icon-container">
                                                <PublicIcon />
                                            </span>
                                            <span>{p.title}</span>
                                        </div>
                                        <div className="table-container table-responsive ">
                                            <Grid
                                                container
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                className="slabCard-container "
                                                onClick={() => onRedirect(p.reDirectUrl, p.searchQuery)}
                                            >
                                                {p.data.map((q) => {
                                                    return (
                                                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="pointerHover">
                                                            <Grid container direction="column" alignItems="center">
                                                                <Grid item className="slabValue3_Text">
                                                                    {q.slabName}
                                                                    <span>{`(${q.unit ? q.unit : 0})`}</span>
                                                                </Grid>
                                                                <Grid item className="slabValue1_Text">
                                                                    {q.value ? formatters.CurrencyThousandSeparatorWithoutZero(q.value) : 0}
                                                                </Grid>
                                                                <Grid item className="slabValue3_Text" style={{ fontWeight: 'bold' }}>
                                                                    {q.perc ? parseFloat(q.perc).toFixed(2) : 0}%
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default WeeklyFlashReport;
