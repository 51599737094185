import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getNewUsedStockWorkflowMovementHistory } from '../../../../../Core/Service/workflowMovementHistory-service';
import moment from 'moment';

const HistoryTableRow = ({ item }) => {
    return (
        <tr className="row">
            <td>{item.workflowName}</td>
            <td className="name-type">{item.createdBy}</td>
            <td className="time-type">{moment(item.createdAt).format('LLL')}</td>
            <td className="name-type">{item.movedBy}</td>
            <td className="time-type">{item.movedAt && moment(item.movedAt).format('LLL')}</td>
        </tr>
    );
};

const HistoryTable = ({ rec, loading }) => {
    return (
        <>
            <table className="movement-history-header">
                <thead>
                    <tr className="header">
                        <th>Workflow</th>
                        <th className="name-type">Started By</th>
                        <th className="time-type">Started At</th>
                        <th className="name-type">Completed By</th>
                        <th className="time-type">Completed At</th>
                    </tr>
                </thead>
            </table>
            <div className="body-table-container custom-scroll">
                <table className="movement-history-body">
                    <tbody>
                        {rec.map((r, i) => (
                            <HistoryTableRow key={i} item={r} />
                        ))}
                        {rec.length === 0 && (
                            <tr>
                                <td colSpan="5">{loading ? 'Loading...' : 'No record found.'}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};
const MovementHistoryModal = (props) => {
    const [state, setState] = useState({
        loading: false,
        history: []
    });

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    useEffect(() => {
        setState((st) => ({ ...st, loading: true }));
        getNewUsedStockWorkflowMovementHistory(props.stockID, props.isNew)
            .then((res) => {
                if (res.success) {
                    setState((st) => ({ ...st, history: res.data }));
                }
            })
            .finally(() => {
                setState((st) => ({ ...st, loading: false }));
            });
    }, [props.stockID]);

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="md" className="dialog-custom workflow-movement-modal">
            <DialogTitle>
                Movement History
                <div className="title-header-actions">
                    <span className="close-icon" onClick={handleClose}>
                        &times;
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="movement-history">
                    <HistoryTable rec={state.history} loading={state.loading} />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default MovementHistoryModal;
