import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UserSignature from '../Modal/SignatureModal';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import AuthenticationScreen from '../Authenticator';
import PasswordChangeModal from '../Modal/PasswordChange';
import TwoAuthDiasbledScreen from '../Authenticator/authVerificationCode';
import { AppStorage } from '../Service/storage-service';
import AppContext from '../../App/AppContext';
import { useContext } from 'react';
import { postSignature, postSignatureStatus } from '../Service/login-service';
import { useWindowSize } from '../Controls/ScreenResolution';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ flex: 1 }}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        height: 'calc(100vh - 200px)',
        padding: '0px 0px 0px 0px'
        // display: 'flex'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: 250
        // background: '#e9eaea',
        // boxShadow: ' rgba(0, 0, 0, 0.24) 1px 0px 1px 0px'
    },
    panal: {
        padding: '0px'
        // boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px'
    },
    tab: {
        fontSize: 12,
        height: 12
    }
}));

const UserProfile = (props) => {
    const {
        loggedIn,
        userRoles,
        displayName,
        lastLogin,
        showToast,
        portalSettings,
        showModal,
        showTopNavAndMenu,
        hideShowTopNav,
        isTopNav,
        tempMenus,
        reportingMenus
    } = useContext(AppContext);
    const [width, height] = useWindowSize();
    let isAdmin = userRoles.includes('admin');
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const isTwoFA_flag = AppStorage.getTwoFactorEnabled();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const postSignData = async (sign, id, defaultInternalDashboard, appbarLocation) => {
        const data = {
            signature: sign,
            AppbarLocation: appbarLocation,
            defaultInternalDashboard: defaultInternalDashboard
        };
        console.log('from leftbar ');

        let resSign = await postSignature(data);
        let resStatus = id && (await postSignatureStatus(id));
        if (resSign) {
            if (resSign?.success) {
                AppStorage.setSignature(sign);
                AppStorage.setUserGridColumnAppbarLocation(appbarLocation);
                localStorage.setItem('_defaultInternalDashboard_', defaultInternalDashboard);
                props.onClose && props.onClose();
                showToast('User Settings Updated Successfully!');
            } else {
                showToast(resSign?.message || 'Something went wrong!');
            }
        }
    };

    let tabs = [
        ...(!isAdmin
            ? [
                  {
                      label: 'My Settings',
                      body: (
                          <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                              <div
                                  style={{
                                      width: width <= 1280 ? '100%' : '70%',
                                      padding: width >= 1280 ? 10 : 30,
                                      boxShadow: width >= 1280 ? 'none' : ' rgba(0, 0, 0, 0.24) 0px 3px 8px'
                                  }}
                              >
                                  <UserSignature postData={postSignData} />
                              </div>
                          </div>
                      )
                  }
              ]
            : []),

        {
            label: 'Two-factor authentication',
            body: isTwoFA_flag ? <TwoAuthDiasbledScreen /> : <AuthenticationScreen />
        },

        {
            label: 'Reset Password',
            body: (
                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                    <div
                        style={{
                            width: width <= 1280 ? '100%' : '30%',
                            padding: 30,
                            boxShadow: width >= 1280 ? 'none' : ' rgba(0, 0, 0, 0.24) 0px 3px 8px'
                        }}
                    >
                        <PasswordChangeModal />
                    </div>
                </div>
            )
        }
    ];

    return (
        <>
            <div className={classes.root} style={{ display: width >= 1280 ? 'flex' : 'block' }}>
                <Tabs
                    orientation={width >= 1280 ? 'vertical' : 'horizontal'}
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="vertical tabs example"
                    className={classes.tabs}
                    indicatorColor="secondary"
                    textColor="secondary"
                >
                    {tabs.map((n, i) => {
                        return <Tab label={n.label} className={classes.tab} />;
                    })}
                </Tabs>

                {tabs.map((m, i) => {
                    return (
                        <TabPanel className={classes.panal} value={value} index={i}>
                            {m.body}
                        </TabPanel>
                    );
                })}
            </div>
        </>
    );
};

export default UserProfile;
