import { Grid } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { SquareButton } from '../../../../Core/FormInput/AppButton';
import ReportGijgoGrid from '../../../internal/ReportsScreens/GijgoForReportsScreen';

import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
import AddUpdateBookingStatus from './addUpdateBooking';
import DialogComp from './../../../../Core/Modal/dialogModal';

const baseColumns = [
    { field: 'action', sortable: false, title: ``, width: 70, filterable: false },

    { field: 'description', sortable: false, title: `Description` },
    { field: 'subject', sortable: false, title: `Subject`, width: 200 },

    { field: 'body', sortable: false, title: `Body` },
    {
        field: 'email',
        sortable: false,
        title: `Email`,
        width: 150,
        renderer: (val) => {
            return val ? 'Yes' : 'No';
        }
    }
];

const crumbs = (path) => [
    { name: 'Home', path: '' },
    { name: 'Communicator', active: true },
    { name: `Booking Request Status`, active: true }
];

const BookingRequestStatus = () => {
    const [state, setState] = useState({
        showModal: false,
        isReload: ''
    });

    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            showModal: false,
            isReload: res ? new Date() : ''
        }));
    };

    const handleEdit = (val) => {
        setState((st) => ({
            ...st,
            showModal: true,
            reqID: val.id,
            data: val
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => handleEdit(record)}
                >
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');

        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);
    const baseUrl = 'CommunicatorBookingRequest/GetBookingRequestStatus';
    return (
        <div>
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list.table
                })}
                columns={baseColumns}
                getUrl={baseUrl}
                // isReload={state.isReload}
                isShowTotal={false}
                //  hidePagination={true}
                isReload={state.isReload}
                // hidePagination={true}
                // hideFilterRow={true}
            />
            {state.showModal ? (
                <DialogComp title="Update Booking Status" onClose={() => handleClose(false)}>
                    <AddUpdateBookingStatus onClose={() => handleClose(true)} reqID={state.reqID} data={state.data} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default BookingRequestStatus;
