import { get, post } from './http-calls';

export const getSalesNewRegistration = async (ID) => {
    let res = await get(`sales/ReportingGetSalesNewRegistration?CategoryID=${ID}`, { useAuthToken: true });
    return res;
};
export const getSaleUsedInvoice = async (ID) => {
    let res = await get(`sales/ReportingGetSalesUsedInvoicing?CategoryID=${ID}`, { useAuthToken: true });
    return res;
};
export const getSalesOrderIntake = async (ID) => {
    let res = await get(`sales/ReportingGetSalesOrderIntake?CategoryID=${ID}`, { useAuthToken: true });
    return res;
};
export const getSalesEVtarget = async (ID) => {
    let res = await get(`Sales/ReportingGetquarterlyEVData`, { useAuthToken: true });
    return res;
};
