import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { CircularProgress, DialogTitle, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Backup, Mail, Visibility } from '@material-ui/icons';
import { AppSelect, Dialogs, PrimaryButton, TextBox } from '../../../Core/FormInput';
import { ScreenContext } from './WorkflowJob.Provider';
import { uploadOcrDoc } from '../../../Core/Service/workflowJobDocument-service';
import AppContext from '../../../App/AppContext';
import { useHistory } from 'react-router-dom';
import RenderForm from './DynamicForm/renderForm';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import moment from 'moment';
const WorkflowStep = ({ step, jobId, onDropdownChange, dropdownValue, onDocumentAction, technicianJobId, isTechReadonly }) => {
    const [uploading, setUploading] = useState(false);
    const { showToast } = useContext(AppContext);
    const { updateState, state, screenState, getListOptionById, getDocumentFromOcrDocId, setDocumentWithOcrDocId } = useContext(ScreenContext);

    const ops = step.stepListId && getListOptionById(step.stepListId);
    const ocrDoc = getDocumentFromOcrDocId(step.ocrDocumentId);
    const enableViewEmail = ocrDoc && ocrDoc.docName;
    const showDoc = () => {
        updateState({ tabView: false });
        docAction('VIEW');
    };
    const docAction = (actionType) => {
        const ocrDoc = getDocumentFromOcrDocId(step.ocrDocumentId);
        onDocumentAction &&
            onDocumentAction({
                eventType: actionType,
                stepId: step.stepId,
                ocrDocumentId: step.ocrDocumentId,
                doc: ocrDoc,
                step
            });
    };

    const uploadDoc = async (e) => {
        console.log(e.target.files, 'e.target.files');
        // const firstFile = e.target.files[0];
        // if (firstFile) {
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append(e.target.files[i].name, e.target.files[i]);
        }
        // formData.append(firstFile.name, firstFile);
        setUploading(true);
        let res = await uploadOcrDoc(state.jobId, step.ocrDocumentId, formData);
        setDocumentWithOcrDocId(step.ocrDocumentId, res.data.docName);
        setUploading(false);
        if (res.success) {
            showToast('File Uploaded successfully.');
            showDoc('VIEW');
        }
        // }
    };

    const getDocumentAction = () => {
        if (step.ocrDocumentId) {
            return (
                <>
                    {step.ocrDocument_ShowUpload && (
                        <Tooltip title="Upload Document">
                            <label>
                                <IconButton color="secondary" component="span" disabled={screenState.isReadonly}>
                                    <Backup />
                                    <input
                                        disabled={screenState.isReadonly}
                                        type="file"
                                        onChange={uploadDoc}
                                        className="upload-btn-input-file"
                                        accept="video/mp4,video/x-m4v,video/*,image/*,application/pdf"
                                        // accept="image/*,application/pdf"
                                        multiple
                                    />
                                    {uploading && <CircularProgress size={40} className="upload-progress" color="secondary" />}
                                </IconButton>
                            </label>
                        </Tooltip>
                    )}
                    {step.ocrDocument_ShowView && (
                        <Tooltip title="View Document">
                            <IconButton
                                color={state.currentVisibleDocName === ocrDoc?.docName ? 'primary' : 'secondary'}
                                component="span"
                                onClick={() => showDoc('VIEW')}
                                disabled={state.loadingDoc || !enableViewEmail}
                            >
                                {state.currentVisibleDocName == ocrDoc?.docName && state.loadingDoc ? (
                                    <Grid>
                                        <Grid>
                                            <CircularProgress size={20} />
                                            &nbsp;
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <>
                                        <Visibility />
                                    </>
                                )}
                            </IconButton>
                        </Tooltip>
                    )}
                    {step.ocrDocument_ShowEmail && (
                        <Tooltip title="Email Document">
                            <IconButton color="secondary" component="span" onClick={() => docAction('MAIL')} disabled={!enableViewEmail}>
                                <Mail />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            );
        }
    };

    return (
        <div className="one-field-area">
            <div className="step-row">
                <div className="step-label">
                    {step.stepLabel}
                    {step.isRequired && <span className="required-step">*</span>}
                </div>
                <div className="doc-action-buttons">{getDocumentAction()}</div>
            </div>
            <div className="step-controls" style={{ backgound: 'yellow' }}>
                {step.isTextboxType ? (
                    <TextBox
                        size="small"
                        name={step.jsonPropertyName}
                        value={dropdownValue}
                        disabled={!step.isEditable}
                        color="primary"
                        className="step-text-box"
                        onChange={(e) => onDropdownChange({ [step.jsonPropertyName]: e.target.value })}
                    />
                ) : (
                    ops && (
                        <AppSelect
                            name={step.jsonPropertyName}
                            value={dropdownValue}
                            onChange={onDropdownChange}
                            options={ops}
                            disabled={!step.isEditable}
                            valuePropertyName="listOptionId"
                            textPropertyName="listOptionName"
                        />
                    )
                )}
                {step.webFormTypeId && (
                    <StepWebFormButton
                        ops={ops}
                        step={step}
                        jobId={jobId}
                        isTechReadonly={isTechReadonly}
                        technicianJobId={technicianJobId}
                        navigate={step.navigateToDashboardOnSubmit}
                    />
                )}
                {step.dynamicFormID && (
                    <StepDynamicFormButton
                        ops={ops}
                        step={step}
                        jobId={jobId}
                        isTechReadonly={isTechReadonly}
                        technicianJobId={technicianJobId}
                        navigate={step.navigateToDashboardOnSubmit}
                    />
                )}
            </div>
        </div>
    );
};

const StepWebFormButton = ({ ops, step, jobId, navigate, technicianJobId, isTechReadonly }) => {
    const { userRoles } = useContext(AppContext);
    const { workflowData, tryToSaveWorkflowData } = useContext(ScreenContext);
    const [showStepWebFormModal, setShowStepWebFormModal] = useState(false);
    const isInternal = userRoles.includes('internal');

    const openStepWebformModal = () => {
        if (workflowData.hasSomethingToSave) {
            tryToSaveWorkflowData(false); //false: just save data, but dont mark step completed.
        }
        setShowStepWebFormModal(true);
    };

    const closeStepWebformModal = () => setShowStepWebFormModal(false);

    const isTechnician = userRoles.includes('technician');
    const effectiveLabel = step.webFormButtonLabel || step.webFormTypeName;
    const iFrameHeaderName = step.stepLabel || step.webFormButtonLabel || step.webFormTypeName;
    const history = useHistory();
    useEffect(() => {
        const listenMsg = ({ data }) => {
            if (data.type === 'close-modal') {
                closeStepWebformModal();
            } else if (data.type === 'navigate-dashboard') {
                history.push(isInternal ? '/dashgrid' : '/');
            }
        };
        window.addEventListener('message', listenMsg);
        return () => {
            window.removeEventListener('message', listenMsg);
        };
    }, []);

    return (
        <>
            <PrimaryButton
                size="small"
                className="web-form-action-button"
                style={{ float: `${step.isTextboxType || ops ? 'right' : ''}` }}
                onClick={openStepWebformModal}
            >
                {effectiveLabel}
            </PrimaryButton>
            {showStepWebFormModal && (
                <StepWebFormModal
                    step={step}
                    isTechReadonly={isTechReadonly}
                    closeModal={closeStepWebformModal}
                    jobId={jobId}
                    technicianJobId={technicianJobId}
                    navigate={navigate}
                    screenName={iFrameHeaderName}
                    url={step.webFormTypeUrl}
                />
            )}
        </>
    );
};

const IframeWrapperComp = React.memo(
    (props) => {
        return <iframe src={props.src} height="100%" style={{ border: '0px' }} onLoad={() => props.onLoad()} />;
    },
    (p, c) => {
        let res = p.src === c.src && p.onLoad === c.onLoad;

        return res;
    }
);

export const StepWebFormModal = ({ step, closeModal, jobId, screenName, navigate, url, technicianJobId, isTechReadonly }) => {
    const { userRoles } = useContext(AppContext);
    const isTechnician = userRoles.includes('technician');
    const isInternal = userRoles.includes('internal');
    const newUrl = () => {
        const techUrl = isTechnician && ['/technicianwriteup', '/partrequisitions', '/partreq-approve', '/partreq-qty-issue'].includes(url);
        const internalUrl = isInternal && url == '/defectsworkshopcontroller';
        if (techUrl) {
            return `${url}/${jobId}/${technicianJobId}/${isTechReadonly}/?fromStep=true&navigate=${navigate}`;
        }
        if (internalUrl) {
            return `${url}/${jobId}/${!step.isEditable}/?fromStep=true&navigate=${navigate}`;
        }
        return `${url}/${jobId}/?fromStep=true&navigate=${navigate}`;
    };
    const [loading, setLoading] = useState(true);
    const [uniqueTimeStamp, setUniqueTimeStamp] = useState(new Date().getTime());

    let onIFrameLoad = useCallback(() => setLoading(false), []);
    console.log('opopo');
    return (
        <Dialogs open={true} className="dialog-custom step-webform-modal">
            <DialogTitle className="bg-primary" style={{ marginTop: '-12px' }}>
                {screenName}
                <div className="title-header-actions" style={{ marginTop: '-12px' }}>
                    <span className="close-icon" onClick={closeModal}>
                        &times;
                    </span>
                </div>
            </DialogTitle>
            {loading && <label className="loading-label">Loading Web Form - {screenName}</label>}
            <IframeWrapperComp src={`${newUrl()}&time=${uniqueTimeStamp}`} onLoad={onIFrameLoad} />
        </Dialogs>
    );
};
const StepDynamicFormButton = ({ ops, step, jobId, navigate, technicianJobId, isTechReadonly }) => {
    const { workflowData, tryToSaveWorkflowData } = useContext(ScreenContext);
    const [showStepDynamicFormModal, setShowStepDynamicFormModal] = useState(false);

    const openStepDynamicformModal = () => {
        if (workflowData.hasSomethingToSave) {
            tryToSaveWorkflowData(false); //false: just save data, but dont mark step completed.
        }
        setShowStepDynamicFormModal(true);
    };

    const closeStepWebformModal = () => setShowStepDynamicFormModal(false);

    const effectiveLabel = step.dynamicFormButtonLabel;
    const iFrameHeaderName = step.dynamicFormName || step?.dynamicFormButtonLabel;
    const history = useHistory();
    useEffect(() => {
        const listenMsg = ({ data }) => {
            if (data.type === 'close-modal') {
                closeStepWebformModal();
            } else if (data.type === 'navigate-dashboard') {
                history.push('/');
            }
        };
        window.addEventListener('message', listenMsg);
        return () => {
            window.removeEventListener('message', listenMsg);
        };
    }, []);

    return (
        <>
            <PrimaryButton
                size="small"
                className="web-form-action-button"
                style={{ float: `${step.isTextboxType || ops ? 'right' : ''}` }}
                onClick={openStepDynamicformModal}
            >
                {effectiveLabel}
            </PrimaryButton>
            {showStepDynamicFormModal && (
                <StepDynamicFormModal
                    step={step}
                    isTechReadonly={isTechReadonly}
                    closeModal={closeStepWebformModal}
                    jobId={jobId}
                    technicianJobId={technicianJobId}
                    screenName={iFrameHeaderName}
                />
            )}
        </>
    );
};
const StepDynamicFormModal = ({ step, closeModal, jobId, screenName, technicianJobId, isTechReadonly }) => {
    const [WindowWidths] = useWindowSize();
    return (
        <Dialogs open={true} className="dialog-custom step-webform-modal" fullScreen={WindowWidths < 600 ? true : false}>
            <DialogTitle className="bg-primary" style={{ marginTop: '-12px' }}>
                {screenName}
                <div className="title-header-actions" style={{ marginTop: '-12px' }}>
                    <span className="close-icon" onClick={closeModal}>
                        &times;
                    </span>
                </div>
            </DialogTitle>
            <Grid container style={{ padding: '10px', background: `${step.dynamicFormBackgroundColor}`, overflow: 'auto' }}>
                <RenderForm headerRecordId={jobId} dynamicFormID={step.dynamicFormID} closeFormModal={closeModal} />
            </Grid>
        </Dialogs>
    );
};
export default WorkflowStep;
