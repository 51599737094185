import { useContext, useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';
import { FixedImage, MarkingType, ScreenContext } from '../WorkflowJob.Provider';
import { Close } from '@material-ui/icons';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';
import { Dialog, DialogContent, DialogTitle, Grid, FormLabel } from '@material-ui/core';
import { CustomeSignPad } from '../../../../Core/SignaturePad';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import getTxtToImg from '../../../../Core/CommonFunctions/convertTxtToImg';
import getMultipleTxtToImg from '../../../../Core/CommonFunctions/convertMultiTxtToImg';
import moment from 'moment';
import resizeImage from '../../../../Core/CommonFunctions/base64ImgResizer';
import { fabric } from 'fabric';
import AppContext from '../../../../App/AppContext';

const DocStamps = ({ currentPageNo, docContainer, canUseNewStamp }) => {
    const { markingState, stampImages, markingTypeSelection, removeStamp, addNewStamp, updateStampInfo } = useContext(ScreenContext);
    const { displayName } = useContext(AppContext);
    const [state, setState] = useState({
        calculatedStamps: [],
        addingNewStamp: undefined,
        stampSignature: null,
        info: ''
    });

    const windowInnerWidthHeight = useWindowSize();
    const [WindowWidths, WindowHeights] = useWindowSize();

    useEffect(
        () => {
            if (canUseNewStamp && window.fabricCanvas) {
                window.fabricCanvas.clear();
                window.fabricCanvas.loadFromJSON(markingState.fabricCanvasData[currentPageNo]);
            } else if (!canUseNewStamp && docContainer?.current) {
                const { scrollWidth, scrollHeight } = docContainer?.current;
                let allS = markingState.stamps.map((s) => {
                    let { width, height, x, y } = s.info;
                    x *= scrollWidth;
                    y *= scrollHeight;
                    width && (width *= scrollWidth);
                    height && (height *= scrollHeight);
                    return { ...s, info: { x, y, width, height } };
                });

                setState((st) => ({ ...st, calculatedStamps: allS }));
            }
        },
        canUseNewStamp ? [currentPageNo] : [markingState.stamps, windowInnerWidthHeight]
    );

    const addStampOnFabric = (newStamp, heightWidth) => {
        console.log('calling addStampOnFabric', newStamp);
        let { info, imgSrc } = newStamp;
        if (imgSrc) {
            new fabric.Image.fromURL(imgSrc, function (img) {
                window.fabricCanvas.add(
                    img.set({
                        left: info.x,
                        top: info.y
                    })
                );
                //below will make sure to de-select any selected stamp while adding
                //with stylus it happens on adding stamp it gets selected automatically
                setTimeout(() => {
                    window.fabricCanvas.discardActiveObject().renderAll();
                }, 100);
            });
        }
    };

    useEffect(() => {
        const clickHandler = (e) => {
            if (markingState.markingType) {
                let x, y;
                if (e.target.classList.contains(canUseNewStamp ? 'main-canvas' : 'shown-doc')) {
                    const { scrollWidth, scrollHeight } = docContainer?.current;
                    x = (e.target.offsetLeft + e.offsetX) / (canUseNewStamp ? 1 : scrollWidth);
                    y = (e.target.offsetTop + e.offsetY) / (canUseNewStamp ? 1 : scrollHeight);

                    switch (markingState.markingType) {
                        case MarkingType.Stamp:
                        case MarkingType.Sign:
                        case MarkingType.Text:
                            setState((st) => ({ ...st, addingNewStamp: { info: { x, y } } }));
                            break;
                        case MarkingType.Check:
                        case MarkingType.Cross:
                        case MarkingType.NA:
                            const isCheck = markingState.markingType === MarkingType.Check;
                            const isCross = markingState.markingType === MarkingType.Cross;

                            //  let newStamp = { info: { x, y }, imgSrc: isCheck ? FixedImage.Check : FixedImage.Cross };
                            let newStamp = { info: { x, y }, imgSrc: isCheck ? FixedImage.Check : isCross ? FixedImage.Cross : FixedImage.NA };

                            let heightWidth = {};
                            if (docContainer?.current) {
                                let calWidth = isCheck || isCross ? 27 : 26;
                                let calHeight = isCheck || isCross ? 20 : 20;

                                heightWidth.width = parseInt(calWidth) / scrollWidth;
                                heightWidth.height = parseInt(calHeight) / scrollHeight;
                            }
                            (canUseNewStamp ? addStampOnFabric : addNewStamp)(newStamp, heightWidth);
                            break;
                        case MarkingType.Calendar:
                            const date = moment(new Date()).format('DD/MM/YYYY- HH:mm');
                            getTxtToImg(date, (fileUrl) => {
                                let heightWidth = {};
                                heightWidth.width = parseInt(140) / scrollWidth;
                                heightWidth.height = parseInt(33) / scrollHeight;
                                (canUseNewStamp ? addStampOnFabric : addNewStamp)({ info: { x, y }, imgSrc: fileUrl }, heightWidth);
                            });
                            break;
                        case MarkingType.DisplayName:
                            const name = displayName;
                            getTxtToImg(name, (fileUrl) => {
                                let heightWidth = {};
                                heightWidth.width = parseInt(140) / scrollWidth;
                                heightWidth.height = parseInt(33) / scrollHeight;
                                (canUseNewStamp ? addStampOnFabric : addNewStamp)({ info: { x, y }, imgSrc: fileUrl }, heightWidth);
                            });
                            break;
                        default:
                            break;
                    }
                }
            }
        };
        window.addEventListener('pointerdown', clickHandler);
        return () => window.removeEventListener('pointerdown', clickHandler);
    }, [markingState.markingType]);

    useEffect(() => {
        const handleKeydown = (e) => {
            if (e.code === 'Escape') {
                markingTypeSelection();
            }
        };

        window.addEventListener('keydown', handleKeydown);
        return () => window.removeEventListener('keydown', handleKeydown);
    }, []);

    const calculateAndUpdateStampInfo = (id, info) => {
        let { width, height, x, y } = info;
        let newInfo = {};
        if (docContainer?.current) {
            const { scrollWidth, scrollHeight } = docContainer?.current;
            newInfo.x = x / scrollWidth;
            newInfo.y = y / scrollHeight;
            if (width) {
                newInfo.width = parseInt(width) / scrollWidth;
            }
            if (height) {
                newInfo.height = parseInt(height) / scrollHeight;
            }
        }
        updateStampInfo(id, newInfo);
    };

    const handleClose = () => {
        setState((st) => ({ ...st, addingNewStamp: undefined }));
    };

    const setStampImage = (imgSrc) => (e) => {
        function getImageDimensions(file) {
            return new Promise(function (resolved, rejected) {
                var i = new Image();
                i.onload = function () {
                    resolved({ w: i.width, h: i.height });
                };
                i.src = file;
            });
        }

        getImageDimensions(imgSrc).then((wh) => {
            let newStamp = { ...state.addingNewStamp, imgSrc };
            handleClose();
            let heightWidth = {};
            if (docContainer?.current) {
                const { scrollWidth, scrollHeight } = docContainer?.current;
                if (wh.w) {
                    heightWidth.width = parseInt(wh.w) / scrollWidth;
                }
                if (wh.h) {
                    heightWidth.height = parseInt(wh.h) / scrollHeight;
                }
            }

            (canUseNewStamp ? addStampOnFabric : addNewStamp)(newStamp, heightWidth);
        });
    };

    const fieldChange = async (val) => {
        const data = await resizeImage(val.stampSignature);
        setState((st) => ({
            ...st,
            stampSignature: data
        }));
    };

    const inFoChange = (e) => {
        setState((st) => ({
            ...st,
            info: e.target.value
        }));
    };

    const setTextImage = () => {
        let stamp = null;
        const value = state.info;
        getMultipleTxtToImg(value, (fileUrl) => {
            stamp = fileUrl;
        });
        return stamp;
    };

    const clearSig = () => {
        setState((st) => ({
            ...st,
            stampSignature: null
        }));
    };

    return (
        <>
            <Dialog
                open={state.addingNewStamp}
                maxWidth={`${markingState.markingType == 5 ? 'md' : 'sm'}`}
                onClose={handleClose}
                className="dialog-custom"
                fullScreen={markingState.markingType == 5 || WindowWidths < 1260 ? true : false}
            >
                <DialogTitle>
                    {markingState.markingType == 6 ? 'Add Text' : markingState.markingType == 5 ? 'Add Signature' : 'Choose Stamp'}
                    <div className="title-header-actions">
                        <span className="close-icon" onClick={handleClose}>
                            &times;
                        </span>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {stampImages.loading ? (
                        <h3>Loading...</h3>
                    ) : (
                        <Grid container alignItems="flex-start" style={{ height: '100%' }}>
                            {markingState.markingType == 1 &&
                                stampImages.images.map((x) => (
                                    <Grid key={x.id} item xs={3}>
                                        <img onClick={setStampImage(x.src)} className="stamp-sample" alt="stamp" src={x.src} />
                                    </Grid>
                                ))}
                            {markingState.markingType == 5 && (
                                <>
                                    <Grid item xs={12} md={12} lg={12} style={{ width: '80%', height: '80%' }}>
                                        <CustomeSignPad
                                            onChange={fieldChange}
                                            clearSig={clearSig}
                                            content={<span style={{ color: 'gray' }}>Signature</span>}
                                            name="stampSignature"
                                            hideBtn={false}
                                            style={{ width: '100%', height: '100%' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SecondaryButton fullWidth onClick={setStampImage(state.stampSignature)}>
                                            Sign
                                        </SecondaryButton>
                                    </Grid>
                                </>
                            )}
                            {markingState.markingType == 6 && (
                                <>
                                    {' '}
                                    <Grid item xs={12} md={12} lg={12}>
                                        <FormLabel component="legend">Text</FormLabel>
                                        <TextBox name="info" onChange={inFoChange} multiline rows={3} autoFocus={true} />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <SecondaryButton fullWidth onClick={setStampImage(setTextImage())}>
                                            Ok
                                        </SecondaryButton>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>
            {!canUseNewStamp &&
                state.calculatedStamps.map((p) => {
                    if (p.isNew) {
                        return (
                            <Rnd
                                key={p.id}
                                className="stamp in-edit"
                                size={{ width: p.info.width, height: p.info.height }}
                                position={{ x: p.info.x, y: p.info.y }}
                                onDragStop={(e, d) => calculateAndUpdateStampInfo(p.id, { x: d.x, y: d.y })}
                                onResizeStop={(e, direction, ref, delta, position) =>
                                    calculateAndUpdateStampInfo(p.id, {
                                        width: ref.style.width,
                                        height: ref.style.height,
                                        ...position
                                    })
                                }
                            >
                                <>
                                    <span className={`remove-stamp ${p.info.height < 20 || p.info.width < 20 ? 'scale-deci7' : ''}`}>
                                        <Close size="small" onClick={removeStamp(p.id)} />
                                    </span>
                                    <img src={p.imgSrc} alt="Stamp" />
                                </>
                            </Rnd>
                        );
                    } else {
                        return (
                            <Rnd
                                key={p.id}
                                className="stamp"
                                size={{
                                    width: p.info.width || 100,
                                    height: p.info.height || 50
                                }}
                                position={{ x: p.info.x, y: p.info.y }}
                                disableDragging
                                enableResizing={false}
                            >
                                <img src={p.imgSrc} alt="Stamp" />
                            </Rnd>
                        );
                    }
                })}
        </>
    );
};

export default DocStamps;
