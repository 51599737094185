import { Checkbox, FormControlLabel, FormLabel, Grid, TableContainer, TextField } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import TabComponent from '../../../Core/Controls/Tabs';
import { SecondaryButton, TextBox } from '../../../Core/FormInput';
import DataGridComp from '../../../Core/Grid';
import formatters from '../../../Core/Grid/inputFormatter';
import {
    AddExpenseCategory,
    AddOtherIncomeCategory,
    getBudgetDropDown,
    getBudgetExpenses,
    getBudgetOtherIncomes,
    getSalesTargets,
    postBudgetExpenceData,
    postSetOtherIncomes,
    setSalesTargets
} from '../../../Core/Service/branch-screen-service';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../commonStyle.scss';
import AppContext from '../../../App/AppContext';

const useStyles = makeStyles({
    table: {
        minWidth: 1250,

        '& .MuiTableCell-root': {
            padding: '10px'
        }
    },
    tableRowStyle: {
        backgroundColor: '#E0E0E0'
    }
});

const MonthShortNameList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const tempNameList = [
    'salesTargetRetail',
    'salesTargetMotab',
    'salesTarget',
    'salesTargetCost',
    'salesTargetAccessory',
    'salesTargetAccessoryCost',
    'salesTargetPDICost'
];

function isFiniteCheck(str) {
    return isFinite(str) ? str.toFixed(2) : '0.00';
}

const SalesTargetScreen = (props) => {
    const [state, setState] = useState({ rows: {}, isActive: false, totals: {} });
    const { showToast } = useContext(AppContext);
    const classes = useStyles();
    const pullSalesTargetGridData = async () => {
        let res = await getSalesTargets(props.yearCode?.id, props.data.departmentID, props.branchCode);
        let temp = res.data.list.table;

        setState((st) => {
            return { ...st, rows: temp[0] };
        });
    };

    useEffect(() => {
        pullSalesTargetGridData();
    }, [props.yearCode?.id]);

    useEffect(() => {
        let tempArr = { ...state.rows };
        let totals = {};
        tempNameList.forEach((o) => {
            let tempTotal = 0;
            MonthShortNameList.forEach((p) => {
                tempTotal += +tempArr[`${o}${p}`];
            });
            Object.assign(totals, { [`${o}Total`]: tempTotal });
        });
        //Gross profit =  sales - cost of sales
        //Gross profit % =  Gross profit /  sales
        //Gross profit per unit =  Gross profit /  retail unit
        MonthShortNameList.forEach((p) => {
            totals[`vehicleGrossProfit${p}`] = isFiniteCheck(tempArr[`salesTarget${p}`] - tempArr[`salesTargetCost${p}`]);
            totals[`vehicleGrossProfitPercentage${p}`] = isFiniteCheck(totals[`vehicleGrossProfit${p}`] / tempArr[`salesTarget${p}`]);
            totals[`vehicleGrossProfitPerUnit${p}`] = isFiniteCheck(totals[`vehicleGrossProfit${p}`] / tempArr[`salesTargetRetail${p}`]);

            totals[`accessoryGrossProfit${p}`] = isFiniteCheck(tempArr[`salesTargetAccessory${p}`] - tempArr[`salesTargetAccessoryCost${p}`]);
            totals[`accessoryGrossProfitPercentage${p}`] = isFiniteCheck(totals[`accessoryGrossProfit${p}`] / tempArr[`salesTargetAccessory${p}`]);
            totals[`accessoryGrossProfitPerUnit${p}`] = isFiniteCheck(totals[`accessoryGrossProfit${p}`] / tempArr[`salesTargetRetail${p}`]);
        });

        setState((st) => {
            return { ...st, totals: totals };
        });
    }, [state.rows]);

    const gridInputChangeHandler = (e) => {
        let tempObj = { ...state.rows };
        tempObj[e.target.name] = e.target.value;
        setState((st) => {
            return { ...st, rows: tempObj };
        });
    };

    const InputWrapper = (name, value) => {
        return <input type="number" name={name} onChange={gridInputChangeHandler} value={value} className="pa-4" style={{ textAlign: 'right' }} />;
    };

    const handleSubmit = async () => {
        let obj = { ...state.rows };
        let data = {
            salesTargetID: obj.salesTargetID < 0 ? null : obj.salesTargetID,
            salesTargetBranchID: props.branchCode,
            salesTargetDepartmentID: props.data.departmentID,
            salesTargetYear: props.yearCode?.id,
            salesTargetRetailJan: obj.salesTargetRetailJan,
            salesTargetRetailFeb: obj.salesTargetRetailFeb,
            salesTargetRetailMar: obj.salesTargetRetailMar,
            salesTargetRetailApr: obj.salesTargetRetailApr,
            salesTargetRetailMay: obj.salesTargetRetailMay,
            salesTargetRetailJun: obj.salesTargetRetailJun,
            salesTargetRetailJul: obj.salesTargetRetailJul,
            salesTargetRetailAug: obj.salesTargetRetailAug,
            salesTargetRetailSep: obj.salesTargetRetailSep,
            salesTargetRetailOct: obj.salesTargetRetailOct,
            salesTargetRetailNov: obj.salesTargetRetailNov,
            salesTargetRetailDec: obj.salesTargetRetailDec,
            salesTargetMotabJan: obj.salesTargetMotabJan,
            salesTargetMotabFeb: obj.salesTargetMotabFeb,
            salesTargetMotabMar: obj.salesTargetMotabMar,
            salesTargetMotabApr: obj.salesTargetMotabApr,
            salesTargetMotabMay: obj.salesTargetMotabMay,
            salesTargetMotabJun: obj.salesTargetMotabJun,
            salesTargetMotabJul: obj.salesTargetMotabJul,
            salesTargetMotabAug: obj.salesTargetMotabAug,
            salesTargetMotabSep: obj.salesTargetMotabSep,
            salesTargetMotabOct: obj.salesTargetMotabOct,
            salesTargetMotabNov: obj.salesTargetMotabNov,
            salesTargetMotabDec: obj.salesTargetMotabDec,
            salesTargetJan: obj.salesTargetJan,
            salesTargetFeb: obj.salesTargetFeb,
            salesTargetMar: obj.salesTargetMar,
            salesTargetApr: obj.salesTargetApr,
            salesTargetMay: obj.salesTargetMay,
            salesTargetJun: obj.salesTargetJun,
            salesTargetJul: obj.salesTargetJul,
            salesTargetAug: obj.salesTargetAug,
            salesTargetSep: obj.salesTargetSep,
            salesTargetOct: obj.salesTargetOct,
            salesTargetNov: obj.salesTargetNov,
            salesTargetDec: obj.salesTargetDec,
            salesTargetCostJan: obj.salesTargetCostJan,
            salesTargetCostFeb: obj.salesTargetCostFeb,
            salesTargetCostMar: obj.salesTargetCostMar,
            salesTargetCostApr: obj.salesTargetCostApr,
            salesTargetCostMay: obj.salesTargetCostMay,
            salesTargetCostJun: obj.salesTargetCostJun,
            salesTargetCostJul: obj.salesTargetCostJul,
            salesTargetCostAug: obj.salesTargetCostAug,
            salesTargetCostSep: obj.salesTargetCostSep,
            salesTargetCostOct: obj.salesTargetCostOct,
            salesTargetCostNov: obj.salesTargetCostNov,
            salesTargetCostDec: obj.salesTargetCostDec,
            salesTargetAccessoryJan: obj.salesTargetAccessoryJan,
            salesTargetAccessoryFeb: obj.salesTargetAccessoryFeb,
            salesTargetAccessoryMar: obj.salesTargetAccessoryMar,
            salesTargetAccessoryApr: obj.salesTargetAccessoryApr,
            salesTargetAccessoryMay: obj.salesTargetAccessoryMay,
            salesTargetAccessoryJun: obj.salesTargetAccessoryJun,
            salesTargetAccessoryJul: obj.salesTargetAccessoryJul,
            salesTargetAccessoryAug: obj.salesTargetAccessoryAug,
            salesTargetAccessorySep: obj.salesTargetAccessorySep,
            salesTargetAccessoryOct: obj.salesTargetAccessoryOct,
            salesTargetAccessoryNov: obj.salesTargetAccessoryNov,
            salesTargetAccessoryDec: obj.salesTargetAccessoryDec,
            salesTargetAccessoryCostJan: obj.salesTargetAccessoryCostJan,
            salesTargetAccessoryCostFeb: obj.salesTargetAccessoryCostFeb,
            salesTargetAccessoryCostMar: obj.salesTargetAccessoryCostMar,
            salesTargetAccessoryCostApr: obj.salesTargetAccessoryCostApr,
            salesTargetAccessoryCostMay: obj.salesTargetAccessoryCostMay,
            salesTargetAccessoryCostJun: obj.salesTargetAccessoryCostJun,
            salesTargetAccessoryCostJul: obj.salesTargetAccessoryCostJul,
            salesTargetAccessoryCostAug: obj.salesTargetAccessoryCostAug,
            salesTargetAccessoryCostSep: obj.salesTargetAccessoryCostSep,
            salesTargetAccessoryCostOct: obj.salesTargetAccessoryCostOct,
            salesTargetAccessoryCostNov: obj.salesTargetAccessoryCostNov,
            salesTargetAccessoryCostDec: obj.salesTargetAccessoryCostDec,
            salesTargetPDICostApr: obj.salesTargetPDICostApr,
            salesTargetPDICostAug: obj.salesTargetPDICostAug,
            salesTargetPDICostDec: obj.salesTargetPDICostDec,
            salesTargetPDICostFeb: obj.salesTargetPDICostFeb,
            salesTargetPDICostJan: obj.salesTargetPDICostJan,
            salesTargetPDICostJul: obj.salesTargetPDICostJul,
            salesTargetPDICostJun: obj.salesTargetPDICostJun,
            salesTargetPDICostMar: obj.salesTargetPDICostMar,
            salesTargetPDICostMay: obj.salesTargetPDICostMay,
            salesTargetPDICostNov: obj.salesTargetPDICostNov,
            salesTargetPDICostOct: obj.salesTargetPDICostOct,
            salesTargetPDICostSep: obj.salesTargetPDICostSep
        };

        let res = await setSalesTargets(data);
        if (res.success) {
            showToast('Sales Target Save Successfully');
            pullSalesTargetGridData();
        }
    };

    return (
        <Grid container item style={{ paddingTop: '10px' }}>
            <Grid container item spacing={2} justify="space-between">
                <Grid xs={8} item></Grid>
                <Grid xs={4} container item spacing={2} alignItems="center" justify="flex-end">
                    <Grid item xs={4}>
                        <SecondaryButton className="setFullWidth" onClick={handleSubmit}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={4}>
                        <SecondaryButton className="setFullWidth">Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 290px)' }} className="input-Number-remove-spinners">
                    <Table className={classes.table} stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell width={170}></TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell align="right">{p}</TableCell>;
                                })}
                                <TableCell width={100} align="right">
                                    Total
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Retail Units
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell>{InputWrapper(`salesTargetRetail${p}`, state.rows[`salesTargetRetail${p}`])}</TableCell>;
                                })}
                                <TableCell align="right">{state.totals.salesTargetRetailTotal}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Motab Units
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell>{InputWrapper(`salesTargetMotab${p}`, state.rows[`salesTargetMotab${p}`])}</TableCell>;
                                })}
                                <TableCell align="right">{state.totals.salesTargetMotabTotal}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Vehicle Sales
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell>{InputWrapper(`salesTarget${p}`, state.rows[`salesTarget${p}`])}</TableCell>;
                                })}
                                <TableCell align="right">{state.totals.salesTargetTotal}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Vehicle Cost Of Sales
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell>{InputWrapper(`salesTargetCost${p}`, state.rows[`salesTargetCost${p}`])}</TableCell>;
                                })}
                                <TableCell align="right">{state.totals.salesTargetCostTotal}</TableCell>
                            </TableRow>
                            <TableRow className={classes.tableRowStyle}>
                                <TableCell component="th" scope="row">
                                    Vehicle Gross Profit
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell align="right">{state.totals[`vehicleGrossProfit${p}`]}</TableCell>;
                                })}
                            </TableRow>
                            <TableRow className={classes.tableRowStyle}>
                                <TableCell component="th" scope="row">
                                    Vehicle Gross Profit %
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell align="right">{state.totals[`vehicleGrossProfitPercentage${p}`]}</TableCell>;
                                })}
                            </TableRow>
                            <TableRow className={classes.tableRowStyle}>
                                <TableCell component="th" scope="row">
                                    Vehicle Profit Per Unit
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell align="right">{state.totals[`vehicleGrossProfitPerUnit${p}`]}</TableCell>;
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Accessory Sales
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell>{InputWrapper(`salesTargetAccessory${p}`, state.rows[`salesTargetAccessory${p}`])}</TableCell>;
                                })}
                                <TableCell align="right">{state.totals.salesTargetAccessoryTotal}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Accessory Cost Of Sales
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell>{InputWrapper(`salesTargetAccessoryCost${p}`, state.rows[`salesTargetAccessoryCost${p}`])}</TableCell>;
                                })}
                                <TableCell align="right">{state.totals.salesTargetAccessoryCostTotal}</TableCell>
                            </TableRow>
                            <TableRow className={classes.tableRowStyle}>
                                <TableCell component="th" scope="row">
                                    Accessory Gross Profit
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell align="right">{state.totals[`accessoryGrossProfit${p}`]}</TableCell>;
                                })}
                            </TableRow>
                            <TableRow className={classes.tableRowStyle}>
                                <TableCell component="th" scope="row">
                                    Accessory Gross Profit %
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell align="right">{state.totals[`accessoryGrossProfitPercentage${p}`]}</TableCell>;
                                })}
                            </TableRow>
                            <TableRow className={classes.tableRowStyle}>
                                <TableCell component="th" scope="row">
                                    Accessory Profit Per Unit
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell align="right">{state.totals[`accessoryGrossProfitPerUnit${p}`]}</TableCell>;
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    PDI costs
                                </TableCell>
                                {MonthShortNameList.map((p) => {
                                    return <TableCell>{InputWrapper(`salesTargetPDICost${p}`, state.rows[`salesTargetPDICost${p}`])}</TableCell>;
                                })}
                                <TableCell align="right">{state.totals.salesTargetPDICostTotal}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default SalesTargetScreen;
