import React, { useMemo } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../../../Core/Service/storage-service';
let addCommas = (val) => {
    return val ? `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
};
const baseColumns = [
    { field: 'branch', sortable: false, title: `Branch`, width: 180, filterable: false },
    {
        field: 'currentProvision',
        title: `Current Provision`,
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false,
        isCurrency: true
    },
    {
        field: 'provisionRequired',
        sortable: true,
        title: `Provision Required`,
        width: 130,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false,
        isCurrency: true
    },
    {
        field: 'provisionDifference',
        sortable: true,
        title: `Provision Difference`,
        width: 140,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false,
        isCurrency: true
    },
    { field: 'totalAmount', sortable: true, title: `Total`, width: 120, showColumnTotal: true, align: 'right', filterable: false, isCurrency: true },
    { field: 'currentMonthTotal', sortable: true, title: `Current`, width: 120, showColumnTotal: true, align: 'right', filterable: false },
    {
        field: 'oneMonthTotal',
        sortable: true,
        title: `1 Month`,
        width: 150,
        showColumnTotal: true,
        align: 'right',
        filterable: false
    },
    { field: 'twoMonthsTotal', sortable: true, title: `2 Months`, width: 120, showColumnTotal: true, align: 'right', filterable: false },
    { field: 'threeMonthsTotal', sortable: true, title: `3 Months`, width: 120, showColumnTotal: true, align: 'right', filterable: false },
    {
        field: 'fourMonthsAndOverTotal',
        sortable: true,
        title: '4+ Months',
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false
    },
    {
        field: 'unallocatedItemsTotal',
        title: 'Unallocated',
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false
    }
];
// const crumbs = (path) => [
//     { name: 'Home', path: '/' },
//     { name: 'Aged Measures', path: '/AgedMeasures' },
//     { name: `${path}`, active: true }
// ];

const crumbs = (val, menuPermissionCounter) => {
    let list = [{ name: 'Home', path: '/' }];
    if (menuPermissionCounter !== 1) {
        list.push({ name: 'Reports', path: '/ReportsDashboard' });
    }
    if (val) {
        list.push({ name: 'Aged Measures', path: '/AgedMeasures' }, { name: val, active: true });
    }
    return list;
};

const GetAllBranchByAgedMeasures = (props) => {
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let Measuredesc = decodeURIComponent(params.get('measuredesc') || '');
    let MeasureID = params.get('MeasureID') || '';

    const redirectToScreens = (value, record, MeasureID, field) => {
        let searchQuery = `?branchID=${record.branchID}&bn=${
            record.branch
        }&measuredesc=${Measuredesc}&MeasureID=${MeasureID}&fromPage=${'Aged Measures'}&month=${field.month == 0 ? 0 : field.month || ''}&unallocated=${
            field.unallocated || ''
        }`;
        if (MeasureID == '1') {
            history.push({
                pathname: '/sales/AgedMeasuresWorkingProgress',
                search: searchQuery
            });
        } else if (MeasureID == '17') {
            history.push({
                pathname: '/sales/AgedMeasuresWorkingProgress',
                search: `${searchQuery}&DepartmentDes=${record.department}&departmentCode=${record.departmentCode}`
            });
        } else if (MeasureID == '2') {
            history.push({
                pathname: '/AgedMeasures/GetAgedMeasuresDirtyUnits',
                search: searchQuery
            });
        } else if (MeasureID == '6' || MeasureID == '21') {
            history.push({
                pathname: '/AgedMeasures/GetAgedMeasuresRanMWarrantyCashGen',
                search: `${searchQuery}&departmentCode=${record.departmentCode}&isGroupingById=prSentTo`
            });
        } else if (MeasureID == '3' || MeasureID == '4' || MeasureID == '5' || MeasureID == '7') {
            history.push({
                pathname: '/AgedMeasures/GetAgedMeasuresRanMWarrantyCashGen',
                search: `${searchQuery}&departmentCode=${record.departmentCode || ''}`
            });
        } else return false;
    };

    const editButton = (field) => (value, record) => {
        let isClickable = record?.accessAllowed?.trim() === 'Y';
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                title={value}
                onClick={isClickable ? () => redirectToScreens(value, record, MeasureID, field) : ''}
                style={{ cursor: isClickable ? 'pointer' : '' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const monthsActionHandler = (field) => (value, record) => {
        let isClickable = record?.accessAllowed?.trim() === 'Y';
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                title={field.toolTipLabel ? record[field.toolTipLabel] : value}
                onClick={isClickable ? () => redirectToScreens(value, record, MeasureID, field) : ''}
                style={{ cursor: isClickable ? 'pointer' : '' }}
            >
                {addCommas(value)}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    // const totalAmountWrapper = (value, record) => {
    //     const spn = document.createElement('span');
    //     const lnk = (
    //         <Grid>
    //             {addCommas(record.currentMonthTotal + record.oneMonthTotal + record.twoMonthsTotal + record.threeMonthsTotal + record.fourMonthsAndOverTotal)}
    //         </Grid>
    //     );
    //     ReactDOM.render(lnk, spn);
    //     return spn;
    // };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let branchCol = tempCols.find((element) => element.field === 'branch');
        // let totalAmount = tempCols.find((element) => element.field === 'totalAmount');
        let currentMonthTotalCol = tempCols.find((element) => element.field === 'currentMonthTotal');
        let oneMonthTotalCol = tempCols.find((element) => element.field === 'oneMonthTotal');
        let twoMonthTotalCol = tempCols.find((element) => element.field === 'twoMonthsTotal');
        let threeMonthTotalCol = tempCols.find((element) => element.field === 'threeMonthsTotal');
        let fourPlusMonthTotalCol = tempCols.find((element) => element.field === 'fourMonthsAndOverTotal');
        let unallocatedTotalCol = tempCols.find((element) => element.field === 'unallocatedItemsTotal');
        if (branchCol) {
            branchCol.renderer = editButton({});
        }
        // if (totalAmount) {
        //     totalAmount.renderer = totalAmountWrapper;
        // }
        if (currentMonthTotalCol) {
            currentMonthTotalCol.renderer = monthsActionHandler({ month: 0, toolTipLabel: 'currentMonthCount' });
        }
        if (oneMonthTotalCol) {
            oneMonthTotalCol.renderer = monthsActionHandler({ month: 1, toolTipLabel: 'oneMonthCount' });
        }
        if (twoMonthTotalCol) {
            twoMonthTotalCol.renderer = monthsActionHandler({ month: 2, toolTipLabel: 'twoMonthsCount' });
        }
        if (threeMonthTotalCol) {
            threeMonthTotalCol.renderer = monthsActionHandler({ month: 3, toolTipLabel: 'threeMonthsCount' });
        }
        if (fourPlusMonthTotalCol) {
            fourPlusMonthTotalCol.renderer = monthsActionHandler({ month: 4, toolTipLabel: 'fourMonthsAndOverCount' });
        }
        if (unallocatedTotalCol) {
            unallocatedTotalCol.renderer = monthsActionHandler({ unallocated: 1, toolTipLabel: 'unallocatedItemsCount' });
        }
        return tempCols;
    }, [MeasureID]);

    const baseUrl = `AgedMeasures/GetAllBranchByAgedMeasures?MeasureID=${MeasureID}`;
    let menuPermissionCounter = AppStorage.menuPermissionCounter();
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(Measuredesc, menuPermissionCounter)} />
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list.map((p) => {
                                return {
                                    ...p,
                                    totalAmount: p.currentMonthTotal + p.oneMonthTotal + p.twoMonthsTotal + p.threeMonthsTotal + p.fourMonthsAndOverTotal
                                };
                            }),
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isShowTotal={true}
                        // hidePagination={true}
                        hideFilterRow={true}
                        downloadName={Measuredesc || 'Aged Measures'}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default GetAllBranchByAgedMeasures;
