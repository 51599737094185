import { FormLabel, Grid } from '@material-ui/core';
import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import AppContext from '../../../App/AppContext';
import { GreenButton, SecondaryButton, TextBox } from '../../../Core/FormInput';
import { postSetPriority } from '../../../Core/Service/workflow-service';

const JobPriorityModal = (props) => {
    const [state, setState] = useState({
        jobPriority: props.workFlowData.priorityNumber == 999999 ? '' : props.workFlowData.priorityNumber,
        isSetDisabled: props.workFlowData.priorityNumber < 999999 ? false : true
    });
    const { hideTopNavAndMenu, showToast } = useContext(AppContext);

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            nst.isSetDisabled = nst.jobPriority ? false : true;
            return nst;
        });
    };

    const handelSubmit = async (flag) => {
        let data = {
            headerRecordID: props.jobId,
            jobPriority: flag == 'submit' && state.jobPriority < 999999 ? state.jobPriority : 999999
        };
        const res = await postSetPriority(data);
        if (res.success) {
            showToast(res.message);
            props.handleCancel(true);
        }
        if (!res.success) {
            console.error(res);
        }
    };

    return (
        <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
                WIP No:&nbsp; <b> {props.workFlowData.wipNo}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.workFlowData.registration}</b>
            </Grid>
            <br />
            <Grid item xs={8}>
                <FormLabel component="legend">Priority Number</FormLabel>
                <TextBox style={{ marginTop: 15 }} type="number" name="jobPriority" value={state.jobPriority} onChange={fieldChange} />
            </Grid>
            <Grid item xs={4} style={{ paddingBottom: '15px' }}>
                <GreenButton onClick={() => handelSubmit('submit')} disabled={state.isSetDisabled}>
                    Set
                </GreenButton>
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton className="Submitbtn" onClick={() => handelSubmit('reset')}>
                    Reset
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default JobPriorityModal;
