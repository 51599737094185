import React, { useMemo } from 'react';
import { SquareButton } from '../../../Core/FormInput/AppButton';

import { Grid, IconButton } from '@material-ui/core';

import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
// import DiagnosticFaultsModal from '../DiagnosticFaultsModal';
import { useState } from 'react';
import DialogComp from '../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import ReportGijgoGrid from '../../internal/ReportsScreens/GijgoForReportsScreen';
import AddEditDynamicFormsModal from './AddEditDynamicForm';
import DeleteDynamicForm from './deleteDynamicForm';
import { Link, useHistory } from 'react-router-dom';
import BuildIcon from '@material-ui/icons/Build';
import Tooltip from '@material-ui/core/Tooltip';

const baseColumns = [
    { field: 'action', title: 'Action', width: 140, filterable: false, sortable: false },
    { field: 'name', sortable: false, title: `Name` },
    { field: 'buttonLabel', sortable: false, title: `Button Label`, width: 190 },
    { field: 'description', sortable: false, title: `Description` }
];

const crumbs = (path) => [
    { name: 'Home', path: '' },
    { name: 'Dynamic Forms', active: true }
];

const DynamicFormsList = (props) => {
    const [state, setState] = useState({
        showModal: false
    });

    const handleClose = (res) => {
        let newSt = {};
        newSt.showModal = false;
        newSt.deleteModal = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };

    const handleEdit = (val) => {
        setState((st) => ({
            ...st,
            showModal: true,
            id: val?.id
        }));
    };

    const handleDelete = async (val) => {
        setState((st) => ({
            ...st,
            id: val?.id,
            dynamicFormName: val?.name,
            deleteModal: true
        }));
    };
    const history = useHistory();

    const GotoDynamicFormField = (val) => {
        history.push({
            pathname: `/DynamicForm/${val.id}`
        });
    };
    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '25px',
                            height: '25px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => handleEdit(record)}
                    >
                        <EditIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
                <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '25px',
                            height: '25px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => GotoDynamicFormField(record)}
                    >
                        <BuildIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
                <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: 'red',
                            borderRadius: '20%',
                            width: '25px',
                            height: '25px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => handleDelete(record)}
                    >
                        <DeleteIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');
        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);

    const url = 'DynamicForms';

    return (
        <div className="report-screen-container">
            <Grid container justify="space-between">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs()} />
                </Grid>
                <Grid item style={{ paddingTop: '5px' }}>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '0%',
                            // width: '100px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 10px 0px 10px'
                        }}
                        onClick={handleEdit}
                    >
                        <span style={{ color: 'white', fontSize: 12 }}>+ Add Dynamic Form</span>
                    </SquareButton>
                </Grid>
            </Grid>
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res?.data?.total
                })}
                columns={column}
                getUrl={url}
                isReload={state.isReload}
                isShowTotal={false}
            />
            {state.showModal ? <AddEditDynamicFormsModal onClose={handleClose} recordId={state.id} /> : null}
            {state.deleteModal ? <DeleteDynamicForm onClose={handleClose} recordId={state.id} dynamicFormName={state.dynamicFormName} /> : null}
        </div>
    );
};

export default DynamicFormsList;
