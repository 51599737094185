import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Dashbord from '.';
import { getGetUserProcesses } from '../Service/dashbordService';
import Salesdashboard from './salesDashboard';
import PartsDashboardDetailScreen from './PartsDashboard/PartsDashboardDetailScreen';
import { AppStorage } from '../Service/storage-service';
import NewSalesDashboardDetailsScreen from '../../Components/internal/dashboard/NewSalesDashboard/NewSalesDashboardDetails';
import UsedSalesDashboardDetailsScreen from '../../Components/internal/dashboard/UsedSalesDashboard/UsedSalesDashboardDetails';

let compConfigObj = {
    AfterSalesDashboard: Dashbord,
    Salesdashboard: NewSalesDashboardDetailsScreen,
    Usedsalesdashboard: UsedSalesDashboardDetailsScreen,
    Partsdashboard: PartsDashboardDetailScreen
};

const ComponentNotFound = () => {
    return <div>Component Not Found</div>;
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        height: 'calc(100vh - 115px)'
    }
}));

const EWIPDashboardTabComponent = () => {
    let storedProcessTabId = AppStorage.getDashBoardProcessTabId();
    const classes = useStyles();
    const [value, setValue] = React.useState(storedProcessTabId || '2');
    const [tabList, setTabList] = React.useState([]);

    useEffect(async () => {
        let res = await getGetUserProcesses();
        setTabList(res.data);
        setValue(`${storedProcessTabId || res.data[0].id}`);
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        AppStorage.setDashBoardProcessTabId(newValue);
    };

    return (
        <div className={classes.root}>
            {/* <Salesdashboard /> */}
            <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="Dashboard Tabs">
                    {tabList.map((t) => (
                        <Tab label={`${t.name}`} value={`${t.id}`} />
                    ))}
                </TabList>
                {tabList.map((t) => {
                    let TempComp = compConfigObj[t.processComponent];
                    return (
                        <TabPanel value={`${t.id}`} style={{ padding: 0 }}>
                            {TempComp ? <TempComp processID={`${t.id}`} /> : <ComponentNotFound />}
                        </TabPanel>
                    );
                })}
            </TabContext>
        </div>
    );
};

export default EWIPDashboardTabComponent;
