import React, { useEffect, useState, useContext } from 'react';
import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton } from '../../../Core/FormInput/index';
import { DataGrid } from '@material-ui/data-grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { getOcrDocumentsByProcess } from '../../../Core/Service/OcrDocuments-service';
import AppContext from '../../../App/AppContext';
import AddUpdateOcr from './AddUpdateOcr';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DataGridComp from '../../../Core/Grid';

const OcrByProcess = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        totalRec: 0,
        OcrDocumentId: null
    });
    const { showToast } = useContext(AppContext);
    const { process } = props;
    useEffect(async () => {
        pullOcrDocumentsAndUpdateState();
    }, []);

    const handleUpdate = (params) => {
        const OcrDocuments_id = params.value.row.id;
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                OcrDocumentId: OcrDocuments_id
            };
            return nst;
        });
    };
    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update OCR Documents" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Locations To Process" aria-label="add">
                    <Link to={`/ocrdocumentstoprocess/${params.value.row.name}/${params.value.row.id}`}>
                        <IconButton size="small">
                            <FolderOpenIcon color="secondary" fontSize="small" />
                        </IconButton>
                    </Link>
                </Tooltip>
                <Tooltip title="Document Fields" aria-label="add">
                    <Link to={`/ocrdocumentsfield/${params.value.row.id}`}>
                        <IconButton size="small">
                            <AssignmentIcon color="secondary" fontSize="small" />
                        </IconButton>
                    </Link>
                </Tooltip>
            </>
        );
    };
    const column_new = [
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 120,
            renderCell: (params) => <Arrow value={params} />
        },
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            sortable: false,
            hide: true
        },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'processName', headerName: 'Process', flex: 0.8 },
        { field: 'tableName', headerName: 'Table', flex: 0.6 },
        { field: 'columnName', headerName: 'Column', flex: 1 },
        { field: 'ocrApiId', headerName: 'OCR Api', flex: 1, hide: true }
    ];

    const pullOcrDocumentsAndUpdateState = async () => {
        let result = await getOcrDocumentsByProcess(process.processID);
        if (result.success) {
            setState((st) => ({ ...st, rows: result.data.rows }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };
    const closeUpdateUser = (refreshGrid) => {
        setState((st) => ({ ...st, show: false }));
        refreshGrid && pullOcrDocumentsAndUpdateState();
    };

    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                OcrDocumentId: null
            };
        });
    };
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                    Add OCR Document
                </SecondaryButton>
            </div>
            {state.show ? <AddUpdateOcr OcrDocumentId={state.OcrDocumentId} onFormSubmit={closeUpdateUser} processID={process.processID} /> : null}
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={250} />
        </div>
    );
};
export default OcrByProcess;
