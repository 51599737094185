import { Avatar, ButtonGroup } from '@material-ui/core';
import { Button, Collapse, FormLabel, Grid, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { Multiselect, TextBox, DatePicker } from '../../../../../Core/FormInput';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import ShowChartIcon from '@material-ui/icons/ShowChart';

import { getReportsDropdowns, getProfitLossbyID } from '../../../../../Core/Service/reportService';
import { FilterListIcon } from '@material-ui/icons/FilterList';
import { BudgetSendBtn, FilterBtn, LinkedButton, SmallLinkedButton } from '../BudgetCore/budgetButton';
import { AgedStockCard, AgedStocksCardTWO, ProfitLossCards, UsedStocksCard, UsedStocksCardTWO } from '../BudgetCore/budgetCardsUi_v2';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import CallMadeIcon from '@material-ui/icons/CallMade';
import EventIcon from '@material-ui/icons/Event';
import { PandLLineChart } from '../BudgetCore/p&ldetailsChart';
import { LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import UsedStocksTable from '../UsedVehicleStock/usedStockTable';
import { postOveragePriorityGetExcelFile, postOveragePriorityList } from '../../../../../Core/Service/ReportScreens-service';
import formatters from '../../../../../Core/Grid/inputFormatter';
import CircularIndeterminate from '../BudgetCore/loader';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    reportScreenMultiSelect: {
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            paddingRight: '65px',
            height: '50px',
            backgroundColor: '#fff',
            paddingRight: '25px !important'
        }
    }
}));
let buttonsList = [
    { id: 'c', name: 'Combined' },
    { id: 'f', name: 'Franchise' },
    { id: 'n', name: 'Non-Franchise' },
    { id: 'r', name: 'Risk' }
];

const crumbs = (ParamIsTrue) => [
    { name: 'Home', path: '/' },
    { name: 'Used Stock', active: true },
    ...(ParamIsTrue ? [{ name: 'Used Stock Valuation', path: '/UsedStockValuation' }] : []),
    { name: 'Overage Priority List', active: true }
];

const OveragePriority = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamIsTrue = params.get('isTrue') || false;

    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
    const classes = useStyles();

    const [state, setState] = useState({
        branches: [],
        tableRow: [],
        branchList: [],
        franchise: [],
        groupIds: JSON.parse(localStorage.getItem('NicoleFilterData'))?.groupIds || [],
        franchiseIds: [],
        showTable: true,
        type: buttonsList[0].id,
        gpStock: [],
        tableData: [],
        showLoader: true
    });

    const [temp, tempOption] = useState({
        groupVal: []
    });

    const [WindowWidths, WindowHeights] = useWindowSize();

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    useEffect(async () => {
        let DropDownRes = await getReportsDropdowns();
        if (DropDownRes.success) {
            setState((st) => ({
                ...st,
                branchList: DropDownRes.data.branch
            }));
        }
    }, []);

    useEffect(() => {
        if (state.branchList.length > 0) {
            tempOption((st) => ({
                ...st,
                groupVal: state.groupIds.map((w) => {
                    let list = state.branchList.find((m) => w === m.id);
                    return list;
                })
            }));
        }
    }, [state.branchList]);

    useEffect(async () => {
        let data = {
            branchID: state.groupIds,
            type: state.type
        };
        let res = await postOveragePriorityList(data);
        if (res.success) {
            let cardDetails = res.data.gpStock[0];
            setState((st) => ({
                ...st,
                gpStock: [
                    {
                        label: 'COMBINED',
                        totalUnits: cardDetails.franchiseUnitStock + cardDetails.nonFranchiseUnitStock,
                        standInValue: cardDetails.franchiseStandInValues + cardDetails.nonFranchiseStandInValues,
                        totalCAPClean: cardDetails.franchiseCapClean + cardDetails.nonFranchiseCapClean,
                        totalVariance: cardDetails.franchiseVariance + cardDetails.nonFranchiseVariance
                    },
                    {
                        label: 'FRANCHISE (120 DAYS+)',
                        totalUnits: cardDetails.franchiseUnitStock,
                        standInValue: cardDetails.franchiseStandInValues,
                        totalCAPClean: cardDetails.franchiseCapClean,
                        totalVariance: cardDetails.franchiseCapClean - cardDetails.franchiseStandInValues
                    },
                    {
                        label: 'NON-FRANCHISE (90 DAYS+)',
                        totalUnits: cardDetails.nonFranchiseUnitStock,
                        standInValue: cardDetails.nonFranchiseStandInValues,
                        totalCAPClean: cardDetails.nonFranchiseCapClean,
                        totalVariance: cardDetails.nonFranchiseCapClean - cardDetails.nonFranchiseStandInValues
                    }
                ],
                tableData: res.data.tableData,
                showLoader: false
            }));
        }
    }, [state.groupIds, state.type]);

    const handleChanges = (event, value) => {
        tempOption((st) => ({ ...st, groupVal: value }));
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: value.map((c) => c.id) }));
        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handleClick = (val) => {
        setState((st) => ({
            ...st,
            type: val
        }));
    };

    const downloadCSVWrapper = async () => {
        let data = {
            type: state.type,
            branchID: state.groupIds
        };
        await postOveragePriorityGetExcelFile(data, 'Overage_Priority_List');
    };

    return (
        <div className="salesBudgetScreen" style={{ height: 'calc(100vh - 105px)', overflow: 'auto' }}>
            {/* <Grid container spacing={2}> */}
            {state.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={10} sm={8} md={9} lg={10} xl={10}>
                            <BreadCrumbs crumbs={crumbs(ParamIsTrue)} />
                        </Grid>
                        <Grid item container xs={2} sm={4} md={3} lg={2} xl={2} justifyContent="flex-end">
                            <Grid item>
                                <LinkedButton onClick={downloadCSVWrapper}>
                                    <ViewComfyIcon />
                                    {WindowWidths > 1024 ? <> &nbsp;&nbsp;DOWNLOAD CSV</> : ''}
                                </LinkedButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container style={{ textAlign: 'left' }} className="containerPadding">
                        <Grid item xs={12} sm={12} lg={4}>
                            <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                SELECT BRANCH
                            </FormLabel>
                            <Multiselect
                                className={classes.reportScreenMultiSelect}
                                options={state.branchList || []}
                                onChange={handleChanges}
                                value={temp.groupVal}
                            />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between">
                        {state.gpStock.map((p) => {
                            return (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className="containerPadding">
                                        <Grid container alignContent="space-between" alignItems="center" className="OPLGropupStockViewKip_card">
                                            <Grid item container justifyContent="space-between" sm={12}>
                                                <Grid item>
                                                    <div className="OPLsecondaryHeading"> {p.label}</div>
                                                </Grid>
                                            </Grid>

                                            <Grid item container xs={12} sm={6} spacing={1} alignItems="center">
                                                <Grid item>
                                                    <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                        <EventIcon className="AvatarFont" />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <div style={{ width: '100%' }}>
                                                        <div className="OPLtype1_Text alignLeft">{p.totalUnits}</div>
                                                        <div className="OPLtype3_Text alignLeft">Total Units</div>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid item container xs={12} sm={6} spacing={1} alignItems="center">
                                                <Grid item>
                                                    <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                        <EventIcon className="AvatarFont" />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <div style={{ width: '100%' }}>
                                                        <div className="OPLtype1_Text alignLeft">
                                                            {formatters.CurrencyThousandSeparatorWithoutZero(p.standInValue)}
                                                        </div>
                                                        <div className="OPLtype3_Text alignLeft">Stand In Value (SIV)</div>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid item container xs={12} sm={6} spacing={1} alignItems="center">
                                                <Grid item>
                                                    <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                        <EventIcon className="AvatarFont" />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <div style={{ width: '100%' }}>
                                                        <div className="OPLtype1_Text alignLeft">
                                                            {formatters.CurrencyThousandSeparatorWithoutZero(p.totalCAPClean)}
                                                        </div>
                                                        <div className="OPLtype3_Text alignLeft">Total CAP Clean</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={12} sm={6} spacing={1} alignItems="center">
                                                <Grid item>
                                                    <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677', marginTop: 0 }}>
                                                        <EventIcon className="AvatarFont" />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <div style={{ width: '100%' }}>
                                                        <div className="OPLtype1_Text alignLeft" style={{ color: p.totalVariance < 0 ? 'red' : '#569101' }}>
                                                            {formatters.CurrencyThousandSeparatorWithoutZero(Math.abs(p.totalVariance))}
                                                        </div>
                                                        <div className="OPLtype3_Text alignLeft">Total Variance</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>

                    <Grid container justifyContent="flex-start">
                        {buttonsList.map((n) => {
                            return (
                                <Grid item xs={6} sm={3} md={2} lg={2} className="containerPadding">
                                    <FilterBtn active={state.type === n.id} onClick={() => handleClick(n.id)}>
                                        {n.name?.toUpperCase()}
                                    </FilterBtn>
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Grid container spacing={1} justifyContent="space-evenly">
                        <Grid item xs={12}>
                            <div className="kpiCard">
                                <UsedStocksTable list={state.tableData || []} />
                            </div>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default OveragePriority;
