import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

const CustomColorPicker = (props) => {
    const [state, setState] = useState({
        showPicker: false,
        color: {
            r: '225',
            g: '155',
            b: '99',
            a: '2'
        }
    });
    const { onChange, color, height, width } = props;
    const onClick = () => {
        setState({
            showPicker: !state.showPicker
        });
    };

    const onClose = () => {
        setState({
            showPicker: false
        });
    };

    const styles = reactCSS({
        default: {
            color: {
                width: `${width || '100%'}`,
                height: `${height || '30px'}`,
                borderRadius: '3px',
                background: `${color}`
            },
            popover: {
                position: 'absolute',
                zIndex: '3'
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px'
            },
            swatch: {
                width: `${width || '100%'}`, //'100%',
                padding: '6px',
                background: '#ffffff',
                borderRadius: '2px',
                cursor: 'pointer',
                display: 'inline-block',
                boxShadow: '0 0 0 1px rgba(0,0,0,.2)'
            }
        }
    });

    return (
        <div>
            <div style={styles.swatch} onClick={onClick}>
                <div style={styles.color} />
            </div>
            {state.showPicker ? (
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={onClose} />
                    <SketchPicker color={color || '#000000'} onChange={onChange} />
                </div>
            ) : null}
        </div>
    );
};

export default CustomColorPicker;
