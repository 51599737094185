import React from 'react';
import GridFilterModal from '../dashboard/FilterModal';
import { DragNDropWrapperComponent } from '../dashboard/DragNDropSequence';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { saveUserPartGridColumn } from '../../../Core/Service/dashbordService';
import { SecondaryButton } from '../../../Core/FormInput';
const initState = {};

const PartsGridFilterColumnWrapper = (props) => {
    const [state, setState] = useState({
        filterList: [],
        languageList: [],
        languageCode: {},
        WorkflowsList: [],
        fieldValues: {}
    });
    useEffect(() => {
        Object.values([...props.filterList]).forEach((obj) => {
            initState[obj.fieldName] = obj.hidden;
        });

        setState((st) => ({
            ...st,
            fieldValues: { ...initState },
            filterList: [...props.filterList]
        }));
    }, [props.filterList]);

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            let newSt = { ...st };
            newSt.fieldValues[name] = checked;
            return newSt;
        });
    };

    const getGridSequenceList = (list) => {
        setState((st) => ({ ...st, filterList: list }));
    };

    const handleSubmit = async () => {
        let sequenceValues = {};
        state.filterList.forEach((o, i) => (sequenceValues[o.postSequenceFieldName] = i + 1));
        let data = {
            ...state.fieldValues,
            ...sequenceValues
        };
        let res = await saveUserPartGridColumn(data);

        // showToast('Save Successfully');
        props.handleCancel(true);
    };

    return (
        <>
            <Grid container spacing={1} style={{ height: '400px', width: '100%', overflow: 'auto' }}>
                <Grid item xs={12}>
                    <DragNDropWrapperComponent
                        CompList={[...state.filterList] || []}
                        fieldValues={state.fieldValues}
                        onChange={handleCheckbox}
                        getGridSequenceList={getGridSequenceList}
                    />
                </Grid>

                <Grid item xs={6}>
                    <SecondaryButton onClick={handleSubmit} fullWidth className="submit_btn">
                        Submit
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton onClick={props.handleCancel} fullWidth className="submit_btn">
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};

export default PartsGridFilterColumnWrapper;
