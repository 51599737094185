import { get, post } from './http-calls';

export const getLiftCheckList = async (headerId) => {
    let res = await get(`LiftChecks/LiftChecks/${headerId}`, { useAuthToken : true });    
    return res;
}

export const addUpdateLiftCheckList = async (data) =>{
    let res = await post(`LiftChecks/LiftChecks`, data, { useAuthToken : true });    
    return res;
}