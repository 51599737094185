import React, { useMemo } from 'react';
import { SquareButton } from '../../../../Core/FormInput/AppButton';
import ReportGijgoGrid from '../../../internal/ReportsScreens/GijgoForReportsScreen/index';
import { Grid, Typography } from '@material-ui/core';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
// import DiagnosticFaultsModal from '../DiagnosticFaultsModal';
import { useState } from 'react';
import DialogComp from '../../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { useEffect } from 'react';
import DiagnosticCommonModal from './addEditModal';

const baseColumns = [
    { field: 'action', sortable: false, title: ``, width: 70, filterable: false },
    { field: 'name', sortable: false, title: `Name` }
];

const crumbs = (path) => [
    { name: 'Home', path: '' },
    { name: 'Diagnostic Sheets', active: true },
    { name: `${path}`, active: true }
];

const DiagnosticEngineFaults = (props) => {
    const [state, setState] = useState({
        showModal: false
    });

    useEffect(() => {
        let url = '';
        if (props.screenName == 'Diagnostic Engine Faults') {
            url = 'DiagnosticEngineFaults';
        } else if (props.screenName == 'Diagnostic Noise Apparents') {
            url = 'DiagnosticNoiseApparents';
        } else if (props.screenName == 'Diagnostic Locations') {
            url = 'DiagnosticLocations';
        } else {
            url = '';
        }

        setState((st) => ({
            ...st,
            url: url,
            screenSortName: props.screenName.substr(props.screenName.indexOf(' ') + 1)
        }));
    }, [props.screenName]);

    const handleClose = () => {
        setState((st) => ({
            ...st,
            showModal: false,
            isReload: new Date()
        }));
    };

    const handleEdit = (val) => {
        setState((st) => ({
            ...st,
            showModal: true,
            id: val?.id
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => handleEdit(record)}
                >
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');
        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);
    return (
        <div className="report-screen-container">
            <Grid container justify="space-between">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs(state.screenSortName)} />
                </Grid>
                <Grid item style={{ paddingTop: '5px' }}>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '0%',
                            // width: '100px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 10px 0px 10px'
                        }}
                        onClick={handleEdit}
                    >
                        <span style={{ color: 'white', fontSize: 12 }}>+ Add {`${state.screenSortName}`}</span>
                    </SquareButton>
                </Grid>
            </Grid>
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res?.data?.total
                })}
                columns={column}
                getUrl={state.url}
                isReload={state.isReload}
                isShowTotal={false}
            />
            {state.showModal ? (
                <DialogComp
                    title={`${state.id ? `Update` : `Add`} ${state.screenSortName}`}
                    maxWidth="sm"
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showModal: false
                        }))
                    }
                    fullWidth
                >
                    <DiagnosticCommonModal onClose={handleClose} id={state.id} screenName={props.screenName} screenSortName={state.screenSortName} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default DiagnosticEngineFaults;
