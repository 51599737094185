import React, { useEffect, useState } from 'react';
import { Card, FormLabel, Grid, IconButton, MenuItem, Typography } from '@material-ui/core';
import { TextBox } from '../../../Core/FormInput';
import CommonGijgoGrid from '../../../Core/Controls/GijgoGrid/index';
import { getqcJobs } from '../../../Core/Service/qc_jobs-service';
import moment from 'moment';
import ReactDOM from 'react-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useMemo } from 'react';
import DialogComp from '../../../Core/Modal/dialogModal';
import QualityControlSheet from '../workflow-job/QualityControlSheet';
import AirplayIcon from '@material-ui/icons/Airplay';
import _, { forEach, forIn } from 'lodash';
import MapQCCard from './mapQCCard';
import MapQCHeaderUnits from './mapQCHerderUnits';
import { Table, TableBody, TableContainer, TableRow, Paper, TableCell } from '@material-ui/core';

const btnObj = [
    { name: 'By Branch', type: 'branch' },
    { name: 'By Franchise', type: 'franchise' }
];

const baseColumns = [
    { field: 'action', sortable: true, title: `Action`, width: 70 },
    { field: 'w', sortable: true, title: `WIP No`, width: 60 },
    { field: 'r', sortable: true, title: `Registration`, width: 100 },
    { field: 'm', sortable: true, title: `Make/Model`, width: 150 },
    { field: 'st', sortable: true, title: `Job Description`, width: 200 },
    // { field: 'f', sortable: true, title: `Franchise`, width: 200 },
    {
        field: 'di',
        sortable: true,
        title: `Date Due In`,
        width: 80,
        renderer: function (value, record) {
            if (record.di != null) {
                return moment(record.di).format('DD-MMM-YYYY');
            }
            return '-';
        }
    },
    {
        field: 'dout',
        sortable: true,
        title: `Date Due Out`,
        width: 80,
        renderer: function (value, record) {
            if (record.dout != null) {
                return moment(record.dout).format('DD-MMM-YYYY');
            }
            return '-';
        }
    },
    { field: 'c', sortable: true, title: `Customer`, width: 200 },
    { field: 'qco', sortable: true, title: 'Created By', width: 150 },
    {
        title: 'Branch',
        field: 'b',
        width: 90
    },
    // { field: 'oo', sortable: true, title: `Owning Operator`, width: 150 },
    { field: 'wn', sortable: true, title: `Workflow`, width: 130 }
];
function isFiniteCheck(str) {
    return isFinite(str) ? str.toFixed(2) : 0;
}
let startDate = moment().startOf('month').format('YYYY-MM-DD');
let endDate = moment().format('YYYY-MM-DD');
const root = {
    width: '100%',
    border: '2px solid #fbfbfb',
    Opacity: 0.9,
    minWidth: 110,
    padding: '5px 0px', // margin: 3,
    borderRadius: 7,
    background: '#fbfbfb'
};
const QCJobsDashboard = (props) => {
    const [state, setState] = useState({
        qcList: [],
        qcBranch: [],
        finalQcVal: [],
        franchiseList: [],
        startDate: startDate,
        endDate: endDate,
        branch: 'allBranch',
        hourType: 'branch',
        RenderList: [],
        qcHeaderResult: [],
        grpHeaderByFranchise: [],
        groupHeaderByBranch: [],
        headerFrType: '',
        grpByTechnicianUnits: [],
        technicianList: [],
        qcUnitItemGroupResult2: [],
        dropDownFranchiseList: [],
        MOTJob: 'All'
    });

    function groupBy(xs, key) {
        let val = xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, []);
        Object.keys(val).forEach((m) => {
            let totalPassed = 0;
            let totalFailed = 0;
            val[m].forEach((q) => {
                totalPassed += q.qcPassed;
                totalFailed += q.qcFailed;
            });
            val[m].totalPassed = totalPassed;
            val[m].totalFailed = totalFailed;
        });
        return val;
    }

    useEffect(async () => {
        const selectedBranch = state.qcBranch?.find((obj) => obj.branch === state.branch); //get branch obj
        let res = await getqcJobs(state.startDate, state.endDate, selectedBranch?.branchID || '', state.MOTJob == 'All' ? '' : state.MOTJob);
        let qcList = [...res.data?.list];
        let qcBranch = [...res.data?.branch];
        let UnitsItem = [...res.data?.qcUnitsResult];
        let GrpHeaderByBranch = groupBy([...res?.data?.qcHeaderResult], 'branch');
        let GrpHeaderByFranchise = groupBy([...res?.data?.qcHeaderResult], 'franchise');
        let qcUnitsTechnicianResult = groupBy([...res.data?.qcUnitsResult], 'technician');

        let groups = ['qcItem', 'technician'];
        let qcGroupByTechnician = {};

        UnitsItem.forEach(function (a) {
            groups
                .reduce(function (o, g, i) {
                    // take existing object,
                    o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {}); // or generate new obj, or
                    return o[a[g]]; // at last, then an array
                }, qcGroupByTechnician)
                .push(a);
        });

        let qcUnitItemGroupResult2 = Object.keys(qcGroupByTechnician).map((m, i) => {
            let finaltemparray = [];
            Object.keys(qcGroupByTechnician[m]).forEach((s, index) => {
                let temparray = [...qcGroupByTechnician[m][s]].reduce((a, b) => {
                    let index = a.find((m) => m.technician === b.technician);
                    if (index) {
                        index.qcPassed += b.qcPassed;
                        index.qcFailed += b.qcFailed;
                        return a;
                    }
                    a.push({ ...b });
                    return a;
                }, []);
                finaltemparray.push(temparray.pop());
            });
            let totalPassed = 0;
            let totalFailed = 0;
            finaltemparray.forEach(function (item) {
                totalPassed += item.qcPassed;
                totalFailed += item.qcFailed;
            });

            return { qcItem: m, data: finaltemparray, totalPassed, totalFailed };
        });

        let qcUnitItemList = [...new Set(res.data?.qcUnitsResult?.map((item) => item.qcItem))].map((w) => {
            return { type: w, name: w };
        });
        let TechnicianList = [...new Set(res.data?.qcUnitsResult?.map((item) => item.technician))].map((w) => {
            let obj = qcUnitsTechnicianResult[`${w}`];
            let passed = obj?.totalPassed + obj?.totalFailed;
            let perbtn = (obj?.totalPassed / passed) * 100;
            let valuePer = perbtn.toFixed(2);
            return { type: w, name: w, percentage: valuePer };
        });
        let dropDownFranchiseList = [...new Set(res.data?.qcHeaderResult?.map((item) => item.franchise))].map((w) => {
            let obj = GrpHeaderByFranchise[`${w}`];
            let passed = obj?.totalPassed + obj?.totalFailed;
            let perbtn = (obj?.totalPassed / passed) * 100;
            let valuePer = perbtn.toFixed(2);
            return { type: w, name: w, percentage: valuePer };
        });
        let dropDownBranchList = qcBranch.map((w) => {
            let obj = GrpHeaderByBranch[`${w.branch}`];
            let passed = obj?.totalPassed + obj?.totalFailed;
            let perbtn = (obj?.totalPassed / passed) * 100;
            let valuePer = isFiniteCheck(perbtn);
            return { type: w.branch, name: w.branch, percentage: valuePer || 0 };
        });
        setState((st) => ({
            ...st,
            qcList: qcList,
            qcBranch: qcBranch,
            headerBrType: qcBranch[0]?.branch,
            qcHeaderResult: [...res?.data?.qcHeaderResult],
            unitTechType: TechnicianList[0]?.name || '',
            groupHeaderByBranch: GrpHeaderByBranch,
            grpHeaderByFranchise: GrpHeaderByFranchise,
            grpByTechnicianUnits: qcUnitsTechnicianResult,
            technicianList: TechnicianList,
            qcUnitItemGroupResult2: qcUnitItemGroupResult2,
            qcUnitItemList: qcUnitItemList,
            dropDownFranchiseList: dropDownFranchiseList,
            dropDownBranchList: dropDownBranchList,
            headerFrType: dropDownFranchiseList[0]?.name,
            qcGroupByTechnician: qcGroupByTechnician
        }));
    }, [state.startDate, state.endDate, state.branch, state.MOTJob]);

    useEffect(() => {
        let finalQcVal = [...state.qcList].reduce((a, b) => {
            let index = a.find((m) => m.branch === b.branch);
            if (index) {
                index.qcDone += b.qcDone;
                index.totalJobs += b.totalJobs;
                return a;
            }
            a.push({ ...b });
            return a;
        }, []);

        setState((m) => ({ ...m, finalQcVal: finalQcVal }));
    }, [state.qcList, state.startDate, state.endDate, state.branch]);

    useEffect(() => {
        let franchiseList = [...state.qcList].reduce((a, b) => {
            let index = a.find((m) => m.franchise === b.franchise);
            if (index) {
                index.qcDone += b.qcDone;
                index.totalJobs += b.totalJobs;
                return a;
            }
            a.push({ ...b });
            return a;
        }, []);

        setState((m) => ({
            ...m,
            franchiseList: franchiseList
        }));
    }, [state.qcList]);

    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleBtnClick = (val) => {
        props.history.push(`/Job/${val.hrid}/${val.wid}`);
    };
    const handleQualityControlSheetClick = (val) => {
        setState((st) => ({ ...st, showQualityControlSheet: true, jobID: val.hrid }));
    };
    const closeQualityControlSheet = (val) => {
        setState((st) => ({ ...st, showQualityControlSheet: false, jobID: '' }));
    };

    const editButton = (id, val) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="space-around">
                <Grid>
                    <IconButton size="small" onClick={() => handleBtnClick(val)}>
                        <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                </Grid>
                <Grid>
                    <IconButton size="small" onClick={() => handleQualityControlSheetClick(val)}>
                        <AirplayIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');

        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);

    let baseUrl = `QCSheet/GetQCJobs?startDate=${state.startDate}&endDate=${state.endDate}${
        state.branch !== 'allBranch' && state.branch ? `&b=${state.branch}` : ''
    }&MOTJob=${state.MOTJob == 'All' ? '' : state.MOTJob}`;

    const handelTabClick = (name) => (val) => {
        setState((st) => ({ ...st, [name]: val }));
    };

    useEffect(() => {
        let newSt = {};
        if (state.hourType == 'branch') newSt.RenderList = state.finalQcVal;
        if (state.hourType == 'franchise') newSt.RenderList = state.franchiseList;
        setState((st) => ({ ...st, ...newSt }));
    }, [state.hourType, state.finalQcVal, state.franchiseList]);

    const rowDataStyle = (record) => {
        if (record.qp == false) {
            return {
                ['background-color']: '#C4463B'
            };
        }
        return false;
    };
    const colDataStyle = (record) => {
        if (record.qp == false) {
            return {
                ['color']: 'white'
            };
        }
        return false;
    };

    return (
        <div style={{ padding: 20 }}>
            <Grid container spacing={1}>
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12} md={3} lg={3}>
                        <FormLabel component="legend">Start Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <FormLabel component="legend">End Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="endDate"
                            fullWidth
                            value={state.endDate}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} lg={3}>
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">MOT Job</FormLabel>
                            <TextBox select name="MOTJob" value={state.MOTJob} variant="outlined" onChange={fieldChange}>
                                <MenuItem value={'All'}>All</MenuItem>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </TextBox>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2} lg={3}>
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">Branch</FormLabel>
                            <TextBox select name="branch" value={state.branch} variant="outlined" onChange={fieldChange}>
                                <MenuItem key={'all'} value={'allBranch'}>
                                    All Branches
                                </MenuItem>
                                {state.qcBranch.map((option, i) => (
                                    <MenuItem key={i} value={option.branch}>
                                        {option.branch}
                                    </MenuItem>
                                ))}
                            </TextBox>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: 5, boxSize: 'none' }}>
                    <MapQCCard
                        Title="QC Done"
                        handleBar={handelTabClick('hourType')}
                        List={state.RenderList}
                        ButtonList={btnObj}
                        hourType={state.hourType}
                        render={state.hourType === 'branch' ? 'branch' : 'franchise'}
                    />
                </Grid>
                {(state.groupHeaderByBranch?.length > 0 || state.dropDownBranchList?.length > 0) && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: 5, boxSize: 'none' }}>
                        <MapQCHeaderUnits
                            Title="QC Header"
                            handleBar={handelTabClick('headerBrType')}
                            List={state.groupHeaderByBranch || []}
                            ButtonList={state.dropDownBranchList || []}
                            hourType={state.headerBrType}
                            render="franchise"
                        />
                    </Grid>
                )}
                {(state.grpHeaderByFranchise.length > 0 || state.dropDownFranchiseList.length > 0) && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: 5, boxSize: 'none' }}>
                        <MapQCHeaderUnits
                            Title="QC Header"
                            handleBar={handelTabClick('headerFrType')}
                            List={state.grpHeaderByFranchise || []}
                            ButtonList={state.dropDownFranchiseList || []}
                            hourType={state.headerFrType}
                            render="branch"
                        />
                    </Grid>
                )}
                {(state.technicianList.length > 0 || state.grpByTechnicianUnits.length > 0) && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: 5, boxSize: 'none' }}>
                        <MapQCHeaderUnits
                            Title="QC Header"
                            handleBar={handelTabClick('unitTechType')}
                            List={state.grpByTechnicianUnits || []}
                            ButtonList={state.technicianList || []}
                            hourType={state.unitTechType}
                            render="qcItem"
                        />
                    </Grid>
                )}
                {state.qcUnitItemGroupResult2?.length > 0 && (
                    <Grid item xs={12}>
                        <TableContainer style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                            <div style={{ maxHeight: `calc(100vh - 365px)`, overflow: 'auto' }} className="custom-scroll">
                                <Table aria-label="simple table" size="small">
                                    <TableBody>
                                        <TableRow key={'2'} style={{ background: 'rgb(151 133 133 / 6%)' }}>
                                            <TableCell style={{ color: 'inherit', color: 'black' }}>QC Items</TableCell>
                                            <TableCell style={{ color: 'inherit', color: 'black' }}>Passed</TableCell>
                                            <TableCell style={{ color: 'inherit', color: 'black' }}>Failed</TableCell>
                                            <TableCell style={{ color: 'inherit', color: 'black' }}>Breakdown By Technicians</TableCell>
                                        </TableRow>
                                        {state.qcUnitItemGroupResult2.map((m, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell style={{ color: 'inherit' }}>{m.qcItem}</TableCell>
                                                    <TableCell style={{ color: 'inherit' }}>{m.totalPassed}</TableCell>
                                                    <TableCell style={{ color: 'inherit' }}>{m.totalFailed}</TableCell>
                                                    <TableCell style={{ color: 'inherit' }}>
                                                        <TableBody>
                                                            <TableRow style={{ background: 'rgb(151 133 133 / 6%)' }}>
                                                                <TableCell>Technician</TableCell>
                                                                <TableCell> Passed</TableCell>
                                                                <TableCell>Failed</TableCell>
                                                            </TableRow>
                                                            {m?.data.map((k) => (
                                                                <>
                                                                    <TableRow>
                                                                        <TableCell>{k.technician}</TableCell>
                                                                        <TableCell> {k.qcPassed}</TableCell>
                                                                        <TableCell>{k.qcFailed}</TableCell>
                                                                    </TableRow>
                                                                </>
                                                            ))}
                                                        </TableBody>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {state.qcUnitItemGroupResult2?.length <= 0 && (
                                            <TableRow key={1}>
                                                <TableCell align="center" rowSpan={3} style={{ color: 'inherit' }}>
                                                    No Record Found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                        </TableContainer>
                    </Grid>
                )}
                <Grid item container xs={12}>
                    <CommonGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={column}
                        getUrl={baseUrl}
                        displayPagination={true}
                        isReload={state.isReload}
                        downloadBtnName="QC_Jobs"
                        setRowStyleOnCondition={true}
                        setRowStyleFunction={rowDataStyle}
                        setColStyleOnCondition={true}
                        setColStyleFunction={colDataStyle}
                    />
                </Grid>
                {state.showQualityControlSheet ? (
                    <DialogComp title="Quality Control" maxWidth="xl" onClose={() => closeQualityControlSheet(false)} fullWidth overflow={'auto'}>
                        <QualityControlSheet jobID={state.jobID} />
                    </DialogComp>
                ) : null}
            </Grid>
        </div>
    );
};

export default QCJobsDashboard;
