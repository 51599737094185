import { useEffect } from 'react';
import { useState } from 'react';
import { FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Radio, RadioGroup } from '@material-ui/core';
import { SelectBox } from '../../../../../Core/FormInput';
import CollectedStatusModal from './statusModals/collected';
import CustomerInformedStatusModal from './statusModals/customerInformed';

const WorkCompletedStatusModal = (props) => {
    const [state, setState] = useState({
        messaegList: [],
        actionLog: false,
        editActionLog: false,
        selectedStatus: +props.statusList[0].id,
        smsText: '',
        sendSMSUpdate: false,
        errors: {}
    });

    useEffect(async () => {
        if (state.selectedStatus) {
            let tempMessage = props.statusList.find((q) => q.id == state.selectedStatus);
            if (tempMessage) {
                setState((st) => ({ ...st, smsText: tempMessage.smsText, sendSMSUpdate: tempMessage.sendSMSUpdate }));
            }
        }
    }, [state.selectedStatus]);

    const handleInput = (e) => {
        const { name, value, type, checked } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    return (
        <div>
            <Grid container alignContent="space-between">
                <Grid item row xs={6}>
                    {props.statusList.length > 2 ? (
                        <Grid item xs={12} style={{ paddingBottom: '10px' }}>
                            <InputLabel shrink>Select Status</InputLabel>
                            <SelectBox List={props.statusList} name="selectedStatus" value={+state.selectedStatus} onChange={handleInput} insertEmpty={false} />
                        </Grid>
                    ) : (
                        <FormControl component="fieldset">
                            <Grid item xs={12}>
                                <RadioGroup row aria-label="selectedStatus" name="selectedStatus" value={+state.selectedStatus} onChange={handleInput}>
                                    {props.statusList.map((p) => {
                                        return (
                                            <Grid item>
                                                <FormControlLabel value={p.id} control={<Radio />} label={p.name} />
                                            </Grid>
                                        );
                                    })}
                                </RadioGroup>
                            </Grid>
                        </FormControl>
                    )}
                </Grid>
                {/* <Grid item xs={6}>
                    <InputLabel shrink style={{ paddingBottom: 10 }}>
                        Select Status
                    </InputLabel>
                    <SelectBox List={props.statusList} name="selectedStatus" value={+state.selectedStatus} onChange={handleInput} insertEmpty={false} />
                    <FormHelperText error>{state.errors.selectedStatus}</FormHelperText>
                </Grid> */}
                <Grid item xs={12}>
                    {state.selectedStatus == 15 && (
                        <CollectedStatusModal
                            onClose={props.onClose}
                            selectedStatus={state.selectedStatus}
                            headerRecordID={props.headerRecordID}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}
                    {state.selectedStatus == 14 && (
                        <CustomerInformedStatusModal
                            onClose={props.onClose}
                            selectedStatus={state.selectedStatus}
                            headerRecordID={props.headerRecordID}
                            smsText={state.smsText}
                            sendSMSUpdates={state.sendSMSUpdate}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default WorkCompletedStatusModal;
