import { Button, ButtonGroup, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import React from 'react';
import { SecondaryCheckbox, TextBox } from '../../../../Core/FormInput';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DiagnosticSheetsImageComponent from '../imageSlider';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    table: {
        minWidth: '100%'
    },

    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    },
    paper: {
        padding: '7px 16px 16px 16px',
        // color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)',
        width: '100%'
        // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
    },
    axelTitles: {
        textAlign: 'center',
        color: '#f50057',
        width: '100%'
    },
    headings: {
        color: '#f50057',
        margin: 0
        // marginLeft: -4,
        // marginBottom: 20
    }
}));

const GeneralSection = (props) => {
    const { state, handleFieldChange, handleCheckbox, handleYesNo, upload, handleRemoveClick } = props;
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <Grid container xs={12} justify="space-between">
                <Grid item>
                    <h3 className={classes.headings}>General</h3>
                </Grid>
                <Grid item>
                    <div style={{ marginRight: 30, display: 'flex' }}>
                        <span>Model : &nbsp;</span>

                        <span>
                            <select
                                onChange={handleFieldChange}
                                className="selectclass"
                                value={state.diagnosticSheetModelID}
                                name="diagnosticSheetModelID"
                                disabled={state.headerData.diagnosticSheetID ? true : false}
                            >
                                <option value={''}>None</option>
                                {state.models?.map((btn, i) => {
                                    return (
                                        <option key={i} value={btn.id}>
                                            {btn.name}{' '}
                                        </option>
                                    );
                                })}
                            </select>
                        </span>
                    </div>
                </Grid>
            </Grid>
            <hr></hr>
            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                    <FormLabel>Fault codes</FormLabel>
                    <TextBox value={state.diagnosticSheetFaultCodes} onChange={handleFieldChange} name="diagnosticSheetFaultCodes" />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Can the customer resolve the issue in any way, e.g. switching the engine off and on?</FormLabel>
                    <TextBox multiline rows={4} value={state.diagnosticSheetGenerelIssue} onChange={handleFieldChange} name="diagnosticSheetGenerelIssue" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {state.modelItemList?.map((p) => {
                            return (
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<SecondaryCheckbox checked={p.checked} onChange={handleCheckbox('modelItemList', p.id)} name={p.name} />}
                                        label={p.name}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Fault:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                <RadioGroup
                                    row
                                    aria-label="diagnosticSheetGeneralFaultId"
                                    name="diagnosticSheetGeneralFaultId"
                                    value={+state.diagnosticSheetGeneralFaultId}
                                    onChange={handleFieldChange}
                                >
                                    {state.generalFualts?.map((p) => {
                                        return <FormControlLabel value={p.id} control={<Radio />} label={p.name} />;
                                    })}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Engine condition:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                {state.engineCondition?.map((c) => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                            <FormControlLabel
                                                control={
                                                    <SecondaryCheckbox
                                                        checked={c.checked}
                                                        onChange={handleCheckbox('engineCondition', c.id)}
                                                        name={`${c.name}`}
                                                    />
                                                }
                                                label={`${c.name}`}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Weather related:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                {state.weatherRelated?.map((c) => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                            <FormControlLabel
                                                control={
                                                    <SecondaryCheckbox
                                                        checked={c.checked}
                                                        onChange={handleCheckbox('weatherRelated', c.id)}
                                                        name={`${c.name}`}
                                                    />
                                                }
                                                label={`${c.name}`}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Driving condition:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                {state.drivingCondition?.map((c) => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                            <FormControlLabel
                                                control={
                                                    <SecondaryCheckbox
                                                        checked={c.checked}
                                                        onChange={handleCheckbox('drivingCondition', c.id)}
                                                        name={`${c.name}`}
                                                    />
                                                }
                                                label={`${c.name}`}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Road conditions:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                {state.roadCondition?.map((c) => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                            <FormControlLabel
                                                control={
                                                    <SecondaryCheckbox
                                                        checked={c.checked}
                                                        onChange={handleCheckbox('roadCondition', c.id)}
                                                        name={`${c.name}`}
                                                    />
                                                }
                                                label={`${c.name}`}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Speed:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                    <FormLabel>MPH</FormLabel>
                                    <TextBox value={state.diagnosticSheetSpeedMPH} onChange={handleFieldChange} name="diagnosticSheetSpeedMPH" />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                    <FormLabel>RPM</FormLabel>
                                    <TextBox value={state.diagnosticSheetSpeedRPM} onChange={handleFieldChange} name="diagnosticSheetSpeedRPM" />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                    <FormControlLabel
                                        control={
                                            <SecondaryCheckbox
                                                checked={state.diagnosticSheetSpeedAllSpeed}
                                                onChange={handleCheckbox()}
                                                name="diagnosticSheetSpeedAllSpeed"
                                            />
                                        }
                                        label="All speeds"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                    <FormLabel>Gear</FormLabel>
                                    <TextBox value={state.diagnosticSheetSpeedGear} onChange={handleFieldChange} name="diagnosticSheetSpeedGear" />
                                </Grid>

                                <RadioGroup
                                    row
                                    aria-label="diagnosticSheetSpeedGearType"
                                    name="diagnosticSheetSpeedGearType"
                                    value={state.diagnosticSheetSpeedGearType}
                                    onChange={handleFieldChange}
                                >
                                    {state.gear?.map((p) => {
                                        return <FormControlLabel value={p.name} control={<Radio />} label={p.name} />;
                                    })}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default GeneralSection;
