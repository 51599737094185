import { get, post } from './http-calls';

export const getTyreCheckByID = async (headerID, id) => {
    let res;
    if (id) {
        res = await get(`TyreConditions?HeaderRecordID=${headerID}&TyreConditionID=${id}`, { useAuthToken: true });
    } else {
        res = await get(`TyreConditions?HeaderRecordID=${headerID}`, { useAuthToken: true });
    }
    return res;
};

export const postTyreCheck = async (data) => {
    let res = await post(`TyreConditions`, data, { useAuthToken: true });
    return res;
};
