import { get, post, put } from './http-calls';

export const addDefectReportedCategory = async (id, description) => {
    let res = await post(`/api/DefectReportedCategories`, {
        DefectReportedCategoryID: id,
        DefectReportedCategoryDescription: description,
        Mode: "A",
    }, { useAuthToken: true });
    return res;
}

export const updateDefectReportedCategory = async (id, description) => {
    let res = await post(`/api/DefectReportedCategories`, {
        DefectReportedCategoryID: id,
        DefectReportedCategoryDescription: description,
        Mode: "U",
    }, { useAuthToken: true });
    return res;
};

export const deleteDefectReportedCategory = async (id) => {
    let res = await post(`/api/DefectReportedCategories/DeleteDefectReportedCategories`, {
        DefectReportedCategoryID: id,
    }, { useAuthToken: true });
    return res;
}

export const getDefectReportedCategories = async () => {
    let res = await get('/api/DefectReportedCategories', { useAuthToken: true });
    return res;
}