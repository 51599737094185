import React, { useState, useEffect } from 'react';
import FormControl from './FormControl';
import './Form.scss';
import { Grid, Typography } from '@material-ui/core';
import { calculateReadonly, getFormGlobalConfig } from '../../Service/apprisal-service';

function isValueSet(value) {
    return !(value === '' || value === undefined || value === null);
}

function isOutOfRange(config, value) {
    let result = false;
    // reached here mean field is not required. And if value set, any has some min / max set then test
    if (isValueSet(value) && (config.min || config.max)) {
        let min = config.min;
        let max = config.max;
        switch (config.controlType.toLowerCase()) {
            case 'textbox':
            case 'autosuggest':
            case 'email':
            case 'password':
            case 'textarea':
                if (min) {
                    result = result || min > value.length;
                }
                if (max) {
                    result = result || max < value.length;
                }
                break;
            case 'number':
            case 'money':
                if (min) {
                    result = result || +min > +value;
                }
                if (max) {
                    result = result || +max < +value;
                }
                break;
            case 'date':
                value = ''; //formatDateTimeToDate(value);
                if (min) {
                    min = 0; //stringDayToDate(min);
                    result = result || min.startOf('day') > value.startOf('day');
                }
                if (max) {
                    max = 0; //stringDayToDate(max);
                    result = result || max.startOf('day') < value.startOf('day');
                }
                break;
            case 'damage-faces':
            case 'photo-uploader':
                if (min) {
                    result = result || +min > value.length;
                }
                if (max) {
                    result = result || +max < value.length;
                }
                break;
        }
    }
    return result;
}

let FormComponent = function ({ formName, formRef, fieldSet, screenState, setScreenState, heading, formErrors, setFormErrors }) {
    // let [formErrors, setFormErrors] = useState([]);

    function updateStateFromForm(e, config) {
        let fieldValue = e?.target?.value;
        let fieldName = e?.target?.name;
        let fieldCheck = e?.target?.checked;
        let fieldFormName = formName;

        setScreenState((st) => {
            let newSt = { ...st };

            if (config.screenColumnControlType === 'checkbox') {
                newSt[fieldFormName] = { ...newSt[fieldFormName], [fieldName]: fieldCheck };
            } else if (config.screenColumnControlType === 'sign-pad') {
                newSt[fieldFormName] = { ...newSt[fieldFormName], e };
            } else {
                newSt[fieldFormName] = { ...newSt[fieldFormName], [fieldName]: fieldValue };
            }
            return newSt;
        });
    }
    function updateSignatureForm(e, config) {
        let fieldFormName = formName;

        setScreenState((st) => {
            let newSt = { ...st };

            if (config.screenColumnControlType === 'sign-pad') {
                newSt[fieldFormName] = { ...newSt[fieldFormName], ...e };
            }
            return newSt;
        });
    }

    const pullPatchData = (name, data) => {
        let fieldFormName = formName;
        setScreenState((st) => {
            let newSt = { ...st };
            if (name === 'regNo') {
                newSt[fieldFormName] = {
                    ...newSt[fieldFormName],
                    regNo: data.registrationNumber,
                    regDate: data.registrationDate,
                    motDueDate: data.motExpiryDate,
                    make: data.make,
                    model: data.model,
                    vehicleTypeId: screenState.vehicleTypeIdList.find((o) => o.name == data.vehicleType)?.id,
                    colourId: screenState.colourIdList.find((o) => o.name == data.colour)?.id,
                    vehicleBody: data.wheelplan,
                    rflExpiryDate: data.taxDueDate,
                    fueltype: data.fuelType,
                    engine: data.engineCapacity
                    // appraisalDate: '2023-11-24T15:54:28',
                    // estimatedArrivalDate: null,
                    // extras: null,
                    // mileage: 1,
                    // noOfOwners: null,
                    // tailLift: null,
                    // type: null,
                    // variant: null,
                    // vehicleTypeId: 1,
                    // weight: null
                };
            } else {
                newSt[fieldFormName] = {
                    ...newSt[fieldFormName],
                    ...data
                };
            }
            return newSt;
        });
    };

    const pullDropDownList = (name, list) => {
        setScreenState((st) => ({ ...st, [`${name}List`]: list || [] }));
    };

    let getparseFunction = (string) => {
        if (string != null) {
            let a = string;
            let jsonObject = {};

            try {
                jsonObject = JSON.parse(a);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }

            return jsonObject;
        }
    };

    useEffect(() => {
        // const validateForm = () => {
        //return true if its in readonly all mode -> edit page for completed appraisal.
        // if (allReadonly) {
        //     return true;
        // }
        let err = [];
        fieldSet.forEach((config) => {
            let node = screenState[formName];
            let label = config.screenColumnLabel;
            let field = config.screenColumnJsonName;
            let value = node[field];
            let req = config.screenColumnRequired;
            if (req && !value) {
                err.push(`${label}`);
            }
        });
        setFormErrors(err);
    }, [fieldSet, formName, screenState]);

    let focusFieldFound = false;

    let { editMode, allReadonly } = getFormGlobalConfig();

    let shouldBeAutoFocus = (fieldConfig) => {
        if (allReadonly || focusFieldFound) {
            return false;
        }
        if (['hidden', 'divider', 'new-row', 'empty-col'].indexOf(fieldConfig.screenColumnControlType.toLowerCase()) === -1) {
            let otherConfig = getFormGlobalConfig();
            let readonly = calculateReadonly(fieldConfig, otherConfig);
            let hidden = otherConfig.editMode ? fieldConfig.editHidden : fieldConfig.hidden;
            if (!readonly && !hidden) {
                focusFieldFound = true;
                return true;
            }
        }
        return false;
    };
    return (
        <div style={{ padding: 10, height: 'calc(100vh - 265px)', overflow: 'auto' }}>
            <Typography style={{ fontSize: 20, fontWeight: 500 }}>{heading}</Typography>
            <hr style={{ border: '1px solid rgba(0,0,0,0.09)' }} />
            <br />

            <Grid container spacing={2}>
                {fieldSet.map((f) => {
                    let screenSizeHandler = getparseFunction(f.screenColumnFieldSectionCssClasses);

                    return (
                        <>
                            {f.screenColumnControlType !== 'divider' ? (
                                <Grid item {...screenSizeHandler}>
                                    <FormControl
                                        key={f.screenColumnID}
                                        state={screenState[f.formName || formName]}
                                        config={f}
                                        onFieldChange={updateStateFromForm}
                                        pullPatchData={pullPatchData}
                                        screenState={screenState}
                                        onSignatureUpdate={updateSignatureForm}
                                        pullDropDownList={pullDropDownList}
                                        autoFocus={shouldBeAutoFocus(f)}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Typography style={{ textAlign: 'left', fontSize: 20, fontWeight: 600 }}>{f.screenColumnLabel}</Typography>
                                    <hr style={{ border: '1px solid rgba(0,0,0,0.3)' }} />
                                </Grid>
                            )}
                        </>
                    );
                })}
            </Grid>
        </div>
    );
};

export default FormComponent;
