import React, { useState, useRef, useEffect, useReducer } from 'react';

import FormComponent from '../../../../Core/Controls/FormControl/FormComponent';
import { getAppraisalScreen } from '../../../../Core/Service/apprisal-service';
import { CircularProgress, Grid } from '@material-ui/core';
import { PrimaryButton, SecondaryButton } from '../../../../Core/FormInput';
import { initState, reducer } from '../../../Reducer/action';
import AppContext from '../../../../App/AppContext';
import { useContext } from 'react';
// import { dispatchShowSnackbar } from '../../../Core/_services/StaticService';

let AppVehDetails = function ({ screenState, setScreenState, heading, screenNumber, fromName, VehicleTypeId, formErrors, setFormErrors, appraisalRecordId }) {
    const { hideModal, showToast } = useContext(AppContext);
    let formRef = useRef(null);
    let [localState, setLocalState] = useState({
        loaded: true,
        fields: []
    });

    useEffect(() => {
        setLocalState({
            ...localState,
            loaded: true
        });
        getAppraisalScreen(screenNumber, VehicleTypeId).then((res) => {
            if (res.success) {
                setLocalState({
                    ...localState,
                    loaded: false,
                    fields: res.data.list.filter((m) => !m.screenColumnHidden),
                    error: res.data.list.filter((m) => !m.screenColumnHidden)
                });
            } else {
                setLocalState({
                    ...localState,
                    loaded: false
                });
            }
        });
    }, [screenNumber]);

    function insertCustomerAsNew() {
        setScreenState((st) => ({
            ...st,
            customerDetails: {
                ...st.customerDetails,
                customerId: ''
            }
        }));

        showToast('Details will be saved as new Customer');
    }

    return (
        <div style={{ padding: 10 }}>
            {localState.loaded ? (
                <div style={{ background: 'white', height: 'calc(100vh - 110px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            ) : (
                <Grid container spacing={1} alignContent="space-between">
                    <Grid item xs={12}>
                        {localState?.fields && (
                            <FormComponent
                                formName={fromName}
                                formRef={formRef}
                                fieldSet={localState?.fields}
                                screenState={screenState}
                                setScreenState={setScreenState}
                                heading={heading}
                                formErrors={formErrors}
                                setFormErrors={setFormErrors}
                            />
                        )}
                        {!appraisalRecordId &&
                        screenNumber == 1 &&
                        screenState.customerDetails.customerId &&
                        screenState.customerDetails.customerId > 0 &&
                        screenState.vehicleAppraisalDetails.appraisalStatusId !== 3 ? (
                            <Grid item xs={12}>
                                {' '}
                                <PrimaryButton fullWidth onClick={insertCustomerAsNew}>
                                    {' '}
                                    Insert as New
                                </PrimaryButton>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default AppVehDetails;
