import {get, post } from './http-calls';

export const addVideoCategory = async(VideoCategoryID, VideoCategoryDescription, VideoCategoryActive, RoleID) => {
    let res = await post(`VideoCategories`, {
        VideoCategoryID: VideoCategoryID,
        VideoCategoryDescription: VideoCategoryDescription,
        VideoCategoryActive: VideoCategoryActive,
        RoleID: RoleID,
    }, { useAuthToken: true });
    return res;
}

export const getVideoCategories = async () => {
    let res = await get(`VideoCategories`, 
    { useAuthToken: true });
    return res;
};

export const getVideoCategory = async (VideoCategoryID) => {
    let res = await get(`VideoCategories/GetDetails?Id=${VideoCategoryID}`, 
    { useAuthToken: true });
    return res;
};

export const getRoles = async () => {
    let res = await get(`VideoCategories/GetDetails`, 
    { useAuthToken: true });
    return res;
};

export const addVideo = async(VideoID, VideoTitle, VideoURL, VideoCategoryID, VideoActive, VideoThumbnail ) => {
    let res = await post(`Videos`, {
        VideoID: VideoID,
        VideoTitle: VideoTitle,
        VideoURL: VideoURL,
        VideoCategoryID: VideoCategoryID,
        VideoActive: VideoActive,
        VideoThumbnail: VideoThumbnail,
    }, { useAuthToken: true });
    return res;
}

export const getVideos = async () => {
    let res = await get(`Videos?Active=true`, 
    { useAuthToken: true });
    return res;
};
