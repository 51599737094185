import { get, post } from './http-calls';

export const getTechnicianAssignedJobs = async (id, branch) => {
    let res = await get(`TechnicianAssigned?Id=${id}&branchShortName=${branch}`, { useAuthToken: true });
    return res;
};
export const GetTechBranchList = async (id, branch) => {
    let res = await get(`TechnicianAssigned/BranchList`, { useAuthToken: true });
    return res;
};
export const GetTechByBranchID = async (id) => {
    let res = await get(`TechnicianAssigned/GetTechnicianByBranchID?Id=${id}`, { useAuthToken: true });
    return res;
};
