import React, { useEffect, useState, useContext, useMemo } from 'react';
import AppContext from '../../../App/AppContext';
import { DialogContents, SecondaryButton } from '../../../Core/FormInput';
import { TextBox } from '../../../Core/FormInput';
import { Grid, FormLabel, Typography } from '@material-ui/core';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import { getDiagnosticCharge, saveDiagnosticCharge } from '../../../Core/Service/diagnosticCharge';
import parse from 'html-react-parser';

const DiagnosticCharge = (props) => {
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const { hideModal, hideTopNavAndMenu, showToast, portalSettings } = useContext(AppContext);

    const [state, setState] = useState({
        jobId: props.match.params.jobId,
        diagnosticChargePrintName: '',
        diagnosticChargeSignature: null,
        errors: {},
        IssuerSignature: null,
        diagnosticChargeDeclaration: '',
        idDisabled: false,
        diagnosticChargeDateSigned:null,
    });

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const hideBracket = state.diagnosticChargeCreatedBy || state.diagnosticChargeDateSigned;

    useEffect(async () => {
        let res = await getDiagnosticCharge(state.jobId);
        if (res.success) {
            const data = res.data?.details;
            if (data && data.length > 0) {
                setState((st) => ({
                    ...st,
                    diagnosticChargePrintName: data[0]?.diagnosticChargePrintName,
                    diagnosticChargeSignature: data[0]?.diagnosticChargeSignature,
                    IssuerSignature: data[0]?.diagnosticChargeSignature ? data[0]?.diagnosticChargeSignature : false,
                    diagnosticChargeDateSigned: data[0]?.diagnosticChargeDateSigned,
                    diagnosticChargeCreatedBy:data[0]?.diagnosticChargeCreatedBy,
                    jobId: data[0]?.diagnosticChargeHeaderRecordID,
                    idDisabled: true,
                    diagnosticChargeDeclaration: data[0]?.diagnosticChargeDeclaration
                        ? data[0]?.diagnosticChargeDeclaration
                        : portalSettings.portalSettingDiagnosticCharges
                }));
            } else {
                setState((st) => ({
                    ...st,
                    diagnosticChargeDeclaration: portalSettings.portalSettingDiagnosticCharges
                }));
            }
        }
    }, []);

    const validations = () => {
        const { diagnosticChargePrintName, diagnosticChargeSignature } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(diagnosticChargePrintName)) {
            errors.diagnosticChargePrintName = 'Print name is required';
            formIsValid = false;
        }
        if (!diagnosticChargeSignature) {
            errors.diagnosticChargeSignature = 'Signature is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const addDiagnosticCharge = async () => {
        const data = {
            diagnosticChargePrintName: state.diagnosticChargePrintName,
            diagnosticChargeSignature: state.diagnosticChargeSignature,
            diagnosticChargeDeclaration: state.diagnosticChargeDeclaration,
            headerRecordID: state.jobId
        };

        if (validations()) {
            let res = await saveDiagnosticCharge(data);
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
            }
            showToast(res.message);
            if (res.success && fromStep) {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }
        }
    };

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const fieldChange = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };

    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            diagnosticChargeSignature: null
        }));
    };
    return (
        <DialogContents>
            <div style={{ maxWidth: '60%', margin: '10px auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography align="left" style={{ fontSize: '0.87rem' }}>
                            {parse(state.diagnosticChargeDeclaration)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography align="left" style={{ fontSize: '0.87rem', color: 'red' }}>
                            I confirm that I have read the above statement and understand that diagnostic charges may apply.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <CustomeSignPad
                            onChange={fieldChange}
                            content={
                                <>
                                    Signature {hideBracket && <span> ( {`${state.diagnosticChargeCreatedBy || ''} ${state.diagnosticChargeDateSigned}`} )</span>}
                                </>
                            }
                            sign={state.IssuerSignature}
                            clearSig={clearSig}
                            name="diagnosticChargeSignature"
                            hideBtn={false}
                        />
                        <span className="mandatoryfields">{state.errors['diagnosticChargeSignature']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Print Name
                        </FormLabel>
                        <TextBox disabled={state.idDisabled} name="diagnosticChargePrintName" value={state.diagnosticChargePrintName} autoComplete="new-password" onChange={inputChange} />
                        <span className="mandatoryfields">{state.errors['diagnosticChargePrintName']}</span>
                    </Grid>
                    <SecondaryButton disabled={state.idDisabled} fullWidth className="mt-20" onClick={addDiagnosticCharge}>
                        Agree
                    </SecondaryButton>
                </Grid>
            </div>
        </DialogContents>
    );
};

export default DiagnosticCharge;
