import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../../../App/AppContext";
import {
    SecondaryButton,
    DefaultButton,
    TextBox,
    DialogContents,
    DialogTitles,
    Dialogs,
    DialogsActions,
} from "./../../../../Core/FormInput/index";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import "./../../User Management/UserManagement.css";
import {
   addOCRDocToProcess,
} from "./../../../../Core/Service/OcrDocuments-service";
import { getBranches } from "../../../../Core/Service/branches-service";

const AddOCRDocToProcess = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const OCRDocToProcess = props.OCRDocToProcessList;
    const ocrDocId = props.ocrDocId;
    const [state, setState] = useState({
        InputLocation: "",
        OutputLocation: "",
        BranchID:null,
        OCRDocToProcessID: null,
        branches: [],
        IsDeleted: false,
    });
    useEffect(async () => {
        let res1 = await getBranches();
        if (res1.success) {
            const branches = res1.data.map((w) => ({
                id: w.id,
                name: w.branchShortName,
            }));
            setState((st) => ({ ...st, branches }));
        }
        if (OCRDocToProcess && OCRDocToProcess.id) {
            setState((st) => ({
                ...st,
                OCRDocToProcessID: OCRDocToProcess.id,
                InputLocation: OCRDocToProcess.inputLocation,
                OutputLocation: OCRDocToProcess.outputLocation,
                BranchID: OCRDocToProcess.branchID,
                IsDeleted:OCRDocToProcess.IsDeleted,
            }));
        }
    }, []);
    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const handelSubmit = async () => {
        let res = await addOCRDocToProcess(
            ocrDocId,
            state.OCRDocToProcessID,
            state.InputLocation,
            state.OutputLocation,
            state.BranchID,
            state.IsDeleted,
        );
        if (!res.success) {
            console.error(res);
        } else {
            hideModal();
            props.onFormSubmit(true);
        }
        showToast(res.message);
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };
    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    {state.OCRDocToProcessID ? (
                        <DialogTitles>Update OCR Document To Process</DialogTitles>
                    ) : (
                        <DialogTitles>Add OCR Document To Process</DialogTitles>
                    )}
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Input Location</FormLabel>
                        <TextBox
                            autoFocus={true}
                            required
                            name="InputLocation"
                            onChange={fieldChange}
                            value={state.InputLocation}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Output Location</FormLabel>
                        <TextBox
                            required
                            name="OutputLocation"
                            onChange={fieldChange}
                            value={state.OutputLocation}
                        />
                    </Grid> 
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Branch</FormLabel>
                        <TextBox
                            select
                            name="BranchID"
                            value={state.BranchID}
                            onChange={fieldChange}
                            variant="outlined"
                        >
                            {state.branches.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextBox>          
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddOCRDocToProcess;
