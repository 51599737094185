import { get, post } from './http-calls';

export const getDiagnosticFaultByID = async (id) => {
    let res = await get(`DiagnosticFaults/${id}`, { useAuthToken: true });
    return res;
};

export const postDiagnosticFaults = async (data) => {
    let res = await post(`DiagnosticFaults`, data, { useAuthToken: true });
    return res;
};

export const getDiagnosticNoiseApparentsByID = async (id) => {
    let res = await get(`DiagnosticNoiseApparents/${id}`, { useAuthToken: true });
    return res;
};

export const postDiagnosticNoiseApparents = async (data) => {
    let res = await post(`DiagnosticNoiseApparents`, data, { useAuthToken: true });
    return res;
};

export const getDiagnosticLocationsByID = async (id) => {
    let res = await get(`DiagnosticLocations/${id}`, { useAuthToken: true });
    return res;
};

export const postDiagnosticLocations = async (data) => {
    let res = await post(`DiagnosticLocations`, data, { useAuthToken: true });
    return res;
};

export const getDiagnosticEngineFaultsByID = async (id) => {
    let res = await get(`DiagnosticEngineFaults/${id}`, { useAuthToken: true });
    return res;
};

export const postDiagnosticEngineFaults = async (data) => {
    let res = await post(`DiagnosticEngineFaults`, data, { useAuthToken: true });
    return res;
};
