import React, { useContext, useState, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AppContext from '../../../App/AppContext';
import Typography from '@material-ui/core/Typography';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import { TextBox, SecondaryButton, SecondaryCheckbox, DialogContents, CustomButton, Multiselect } from '../../../Core/FormInput';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { getFailureCodes } from '../../../Core/Service/failureCode-service';
import { getWorkAccomplishCodes } from '../../../Core/Service/workaccomplished-service';
import { getTechWriteUp, AddTechWriteUp, getTechWriteUpPdfFile, TechnicianWriteUp_Delete } from '../../../Core/Service/techWriteUp-service';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { IconButton } from '@material-ui/core';
import DisplayGrid from './../../technician/technician-app/DisplayGrid';
import Tooltip from '@material-ui/core/Tooltip';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import '../../technician/technician-app/TechnicianStyle.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import formatters from '../../../Core/Grid/inputFormatter';
import ConfirmationModal from '../../../Core/Controls/Dialog/ConfirmationModal';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import _ from 'lodash';
import { AppStorage } from '../../../Core/Service/storage-service';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Alert } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AssignTech from '../assign-technician/assignTechnModal';

const TechWrite_Up = (props) => {
    const { hideModal, showToast, hideTopNavAndMenu, portalSettings, userRoles } = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const [masterData, setMasterData] = useState({
        failureCodes: [],
        workaccomplishcodes: []
    });
    const [selectedData, setSelectedData] = useState({
        selectedFailureCodes: [],
        selectedWorkAccomplishCodes: []
    });

    const [isDeleteTechWriteUp, setIsDeleteTechWriteUp] = useState({
        show: false,
        selectedData: {}
    });

    const [inputList, setInputList] = useState([
        {
            technicianNotesItemFault: '',
            technicianNotesItemDiagnosis: '',
            technicianNotesItemCause: '',
            isDeleted: false
        }
    ]);

    const signCanvas = useRef({});
    const signteamleaderCanvas = useRef({});

    const clearLeaderSign = () => {
        if (state.imgteamleadersign) {
            setState((st) => ({
                ...st,
                imgteamleadersign: null,
                teamleadersign: null
            }));
        } else {
            signteamleaderCanvas.current.clear();
        }
    };
    const [state, setState] = useState({
        status: null,
        cause: '',
        cure: '',
        complaint: '',
        readingtaken: '',
        failurereason: [],
        timeout: '',
        date: new Date(),
        timein: '',
        mileageout: '',
        mileagein: '',
        workaccomplished: [],
        hide: null,
        hide1: null,
        techniciansign: null,
        imgtechniciansign: null,
        imgteamleadersign: null,
        teamleadersign: null,
        smoketest: false,
        serviceindicator: false,
        radiocode: false,
        wipNumber: '',
        timeclock: false,
        showWarning: false,
        registration: '',
        id: null,
        showCircularLoader: true,
        fields: {},
        errors: {},
        technicians: [],
        showGrid: false,
        GridID: null,
        odometerUnitsVM: [],
        technicianNotesReadingUnit: null,
        technicianNotesJobID: null,
        newJOBId: null,
        technicianName: '',
        technicianDateSigned: '',
        teamLeaderName: '',
        teamLeaderDateSigned: '',
        signedOff: true,
        makeDisbaled: false,
        showTechAssign: false
    });
    const canAmendTechWriteUpFlag = AppStorage.getCanAmendTechWriteUp();
    const canSignOffTechWriteup = AppStorage.getCanSignOffTechWriteup();

    const handleValidation = () => {
        let fields = state.fields;
        const list = [...inputList];
        let errors = {};
        let formIsValid = true;
        if (state.date === 'Invalid date') {
            formIsValid = false;
            errors['date'] = 'Date cannot be empty';
        }
        if (!state.readingtaken) {
            formIsValid = false;
            errors['readingtaken'] = 'Reading taken cannot be empty';
        }
        if (list[0]['technicianNotesItemCause'] === '') {
            formIsValid = false;
            errors['cause'] = 'Cause cannot be empty';
        }
        if (list[0]['technicianNotesItemFault'] === '') {
            formIsValid = false;
            errors['complaint'] = 'Complaint cannot be empty';
        }
        if (list[0]['technicianNotesItemDiagnosis'] === '') {
            formIsValid = false;
            errors['cure'] = 'Cure cannot be empty';
        }
        if (canSignOffTechWriteup && !state.teamleadersign) {
            formIsValid = false;
            errors['teamleadersign'] = 'Workshop controller signature cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    useEffect(() => {
        const selectedFailure = masterData.failureCodes.filter((w) => state.failurereason.indexOf(w.id) > -1);
        const selectedWorkAccomplish = masterData.workaccomplishcodes.filter((w) => state.workaccomplished.indexOf(w.id) > -1);
        setSelectedData((st) => ({
            ...st,
            selectedFailureCodes: selectedFailure,
            selectedWorkAccomplishCodes: selectedWorkAccomplish
        }));
    }, [state.failurereason, state.workaccomplished, masterData.failureCodes, masterData.workaccomplishcodes]);
    const Id = props.match.params.jobId;
    const DisplayGridState = (i) => {
        setState((st) => ({
            ...st,
            showGrid: true,
            GridID: i
        }));
    };
    useEffect(async () => {
        let pros = [];
        pros.push(getWorkAccomplishCodes(), getFailureCodes());
        let responses = await Promise.all(pros);
        if (responses[0].success && responses[1].success) {
            setMasterData((st) => ({
                ...st,
                workaccomplishcodes: responses[0].data.map((w) => ({
                    id: w.workAccomplishedCodeID,
                    name: w.workAccomplishedDescription
                })),
                failureCodes: responses[1].data.map((w) => ({
                    id: w.failureCode,
                    name: w.failureDescription
                }))
            }));
        }
    }, []);

    const hideBracket = state.technicianName || state.technicianDateSigned;
    const hideLeaderBracket = state.teamLeaderName || state.teamLeaderDateSigned;

    const pullTechWriteUp = async (technicianNotesJobID) => {
        if (state.technicianNotesJobID != technicianNotesJobID || technicianNotesJobID == null) {
            let result = await getTechWriteUp(Id, technicianNotesJobID);
            const updatedUser = result.data;
            if (updatedUser) {
                setState((st) => ({
                    ...st,
                    signedOff: canSignOffTechWriteup, //default set to true from workshop techriteup
                    technicianNotesJobID: updatedUser.technicianJobID,
                    status: updatedUser.status,
                    newJOBId: updatedUser.technicianJobID,
                    id: updatedUser.technicianNotesHeaderRecordID,
                    registration: updatedUser.registration,
                    cause: updatedUser.technicianNotesCause,
                    workaccomplished: updatedUser.techWorkAccomplished.map((m) => m.techWorkAccomplishedCodeID),
                    failurereason: updatedUser.techFailureCodes.map((m) => m.techFailureCodeID),
                    cure: updatedUser.technicianNotesActionTaken,
                    date: updatedUser.technicianNotesDate ? formatters.DateFormatter(updatedUser.technicianNotesDate) : moment().format('YYYY-MM-DD'),
                    readingtaken: updatedUser.technicianNotesReadingTaken || '',
                    complaint: updatedUser.technicianNotesFault,
                    mileagein: updatedUser.technicianNotesMileageIn || '',
                    mileageout: updatedUser.technicianNotesMileageOut || '',
                    radiocode: updatedUser.technicianNotesRadioCodeReset,
                    serviceindicator: updatedUser.technicianNotesServiceIndicatorReset,
                    smoketest: updatedUser.technicianNotesSmokeTestPerformed,
                    imgtechniciansign: updatedUser.technicianNotesTechnicianSignature,
                    techniciansign: updatedUser.technicianNotesTechnicianSignature,
                    imgteamleadersign: updatedUser.technicianNotesTeamLeaderSignature,
                    teamleadersign: updatedUser.technicianNotesTeamLeaderSignature,
                    hide: updatedUser.technicianNotesTechnicianSignature,
                    hide1: updatedUser.technicianNotesTeamLeaderSignature,
                    timeclock: updatedUser.technicianNotesTimeClockReset,
                    timein: updatedUser.technicianNotesTimeIn ? pad(updatedUser.technicianNotesTimeIn).toString().replace('.', ':') : '',
                    timeout: updatedUser.technicianNotesTimeOut ? pad(updatedUser.technicianNotesTimeOut).toString().replace('.', ':') : '',
                    wipNumber: updatedUser.wipNumber,
                    technicians: updatedUser.technicians,
                    odometerUnitsVM: updatedUser?.odometerUnitsVM?.map((w) => ({
                        id: w.odometerUnitID,
                        name: w.odometerUnitDescription
                    })),
                    technicianNotesReadingUnit: updatedUser.technicianNotesReadingUnit,
                    showCircularLoader: false,
                    technicianName: updatedUser.technicianNotesTechnicianName,
                    technicianDateSigned: formatters.DateTimeFormatter(updatedUser.technicianNotesTechnicianDateSigned),
                    teamLeaderName: updatedUser.technicianNotesTeamLeaderName,
                    teamLeaderDateSigned: formatters.DateTimeFormatter(updatedUser.technicianNotesTeamLeaderDateSigned),
                    customerName: updatedUser.customerName,
                    isReadonly: new Date() // !canAmendTechWriteUpFlag
                }));
                setInputList(
                    !updatedUser.technicianNotesItem.length
                        ? [
                              {
                                  technicianNotesItemFault: '',
                                  technicianNotesItemDiagnosis: '',
                                  technicianNotesItemCause: '',
                                  isDeleted: false
                              }
                          ]
                        : updatedUser.technicianNotesItem.map((k) => ({
                              ...k,
                              isDeleted: false
                          }))
                );
            }
        }
    };

    useEffect(async () => {
        setState((st) => ({ ...st, showCircularLoader: true }));
        pullTechWriteUp();
    }, []);

    function pad(number) {
        // Check if the number is an integer
        if (Number.isInteger(number)) {
            return number < 10 ? '0' + number : number.toString();
        } else {
            // Convert the number to a string with exactly two decimal places
            var str = number.toFixed(2);
            // Check if the whole part is less than 10 and add a leading zero if necessary
            if (number < 10 && number >= 0) {
                str = '0' + str;
            }
            return str;
        }
    }

    const technician = state.technicians?.map((m) => m.technician);

    const openIsDeleteTechWriteUpModal = (event, data) => {
        event.stopPropagation();
        setIsDeleteTechWriteUp((st) => ({ ...st, show: true, selectedData: data }));
    };

    const deleteTechWriteUpHandle = async () => {
        let res = await TechnicianWriteUp_Delete(isDeleteTechWriteUp.selectedData?.technicianJobID);
        if (res.success) {
            // if (state.technicianNotesJobID != isDeleteTechWriteUp.selectedData?.technicianJobID) {
            pullTechWriteUp();
            // }
            showToast(res.data.message);
        } else {
            showToast(res.message);
        }
        closeIsDeleteTechWriteUpModal();
    };

    const closeIsDeleteTechWriteUpModal = () => {
        setIsDeleteTechWriteUp((st) => ({ ...st, show: false, selectedData: {} }));
    };

    const handleAssignmentBtnClick = (val) => {
        setState((st) => ({
            ...st,
            showTechAssign: true
        }));
    };

    const closeTechAssignDialog = (res) => {
        setState((st) => ({ ...st, showTechAssign: false }));
        res && pullTechWriteUp();
    };

    const handleSubmit = async () => {
        if (handleValidation()) {
            setState((st) => ({ ...st, showWarning: true }));
        }
    };
    const hideConfirmationModal = () => {
        setState((st) => ({ ...st, showWarning: false }));
    };
    const isAuditor = userRoles.includes('auditor');
    const isCustomer = userRoles.includes('customer');
    const submitFunction = async () => {
        if (handleValidation()) {
            setState((st) => ({ ...st, submitLoader: true }));
            let mode = state.teamleadersign && state.signedOff;
            let data = {
                Mode: mode ? 'S' : 'T',
                TechnicianNotesDate: state.date,
                TechnicianNotesHeaderRecordID: state.id,
                technicianNotesItem: inputList,
                TechnicianNotesMileageIn: state.mileagein,
                TechnicianNotesTimeIn: parseFloat(state.timein.replace(':', '.')),
                TechnicianNotesMileageOut: state.mileageout,
                TechnicianNotesTimeOut: parseFloat(state.timeout.replace(':', '.')),
                TechnicianNotesSmokeTestPerformed: state.smoketest,
                TechnicianNotesServiceIndicatorReset: state.serviceindicator,
                TechnicianNotesRadioCodeReset: state.radiocode,
                TechnicianNotesTimeClockReset: state.timeclock,
                TechnicianNotesTechnicianSignature: null, //state.techniciansign, no need to pass tech sifn from workshop
                techWorkAccomplished: state.workaccomplished,
                TechnicianNotesReadingTaken: state.readingtaken,
                techFailureCodes: state.failurereason,
                TechnicianNotesTeamLeaderSignature: state.teamleadersign,
                TechnicianNotesReadingUnit: state.technicianNotesReadingUnit,
                TechnicianNotesJobID: state.newJOBId,
                SignedOff: state.signedOff || false
            };
            let res = await AddTechWriteUp(data);
            if (!res.success) {
                console.error(res);
                setState((st) => ({ ...st, showWarning: false }));
            } else {
                hideModal();
            }
            showToast(res.message);
            if (res.success && fromStep) {
                setTimeout(() => {
                    window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    setState((st) => ({ ...st, submitLoader: false }));
                }, 1000);
            }
        }
    };

    const GetTechnicianSign = (o) => {
        setState((st) => {
            const nst = {
                ...st,
                ...o
            };
            return nst;
        });
    };
    const clearSig = (val) => {
        setState((st) => {
            let newSt = { ...st };
            if (val === 'teamleadersign') {
                newSt.teamleadersign = null;
            }
            if (val === 'techniciansign') {
                newSt.techniciansign = null;
            }
            return newSt;
        });
    };

    // const GetTeamleaderSign = () => {
    //     let fields = state.fields;
    //     fields['teamleadersign'] = signteamleaderCanvas.current.toDataURL();
    //     setState((st) => {
    //         const nst = {
    //             ...st,
    //             teamleadersign: signteamleaderCanvas.current.toDataURL(),
    //             fields: fields
    //         };
    //         return nst;
    //     });
    // };
    const handleCheck = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st, [name]: checked };
            return nst;
        });
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            return { ...st, [name]: value, fields: fields };
        });
    };
    const AutoWorkAccomplish = (event, value) => {
        setSelectedData((st) => ({
            ...st,
            selectedWorkAccomplishCodes: value
        }));
        setState((st) => {
            const nst = { ...st, workaccomplished: value.map((c) => c.id) };
            return nst;
        });
    };
    const TechBtnClick = (id) => {
        if (state.technicianNotesJobID != id) {
            // setState((st) => ({
            //     ...st,
            //     technicianNotesJobID: id
            // }));
            pullTechWriteUp(id);
        }
    };
    const AutoFrChange = (event, value) => {
        setSelectedData((st) => ({ ...st, selectedFailureCodes: value }));
        setState((st) => {
            const nst = { ...st, failurereason: value.map((c) => c.id) };
            return nst;
        });
    };
    const GlobalCss = withStyles({
        '@global': {
            '.MuiOutlinedInput-inputMarginDense': {
                paddingTop: '4.1px',
                paddingBottom: '4.1px',
                color: 'black'
            },
            '.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
                paddingTop: '4.1px',
                paddingBottom: '4.1px',
                color: 'black'
            },
            '.MuiFormLabel-root': {
                color: 'black'
            },
            '.MuiFormControlLabel-label.Mui-disabled': {
                color: 'black'
            }
        }
    })(() => null);
    const pullAndUpdateState = () => {
        setState((st) => ({
            ...st,
            showGrid: false
        }));
    };
    const getStandartdFromChild = (props) => {
        const list = [...inputList];
        list[state.GridID]['technicianNotesItemFault'] = props.complaint;
        list[state.GridID]['technicianNotesItemDiagnosis'] = props.cure;
        list[state.GridID]['technicianNotesItemCause'] = props.cause == null ? '' : props.cause;
        setInputList(list);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    const handleRemoveClick = (index, id) => {
        const list = [...inputList];
        if (id) {
            list[index].isDeleted = true;
            setInputList(list);
        } else {
            list.splice(index, 1);
            setInputList(list);
        }
    };
    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                technicianNotesItemFault: '',
                technicianNotesItemCause: '',
                technicianNotesItemDiagnosis: ''
            }
        ]);
    };

    const printDocument = () => {
        getTechWriteUpPdfFile(Id, 'W');
        // let input = document.getElementById('divToPrint');
        // let calMaxHeight;
        // html2canvas(input, {
        //     onclone: (cloned) => {
        //         let textArea = cloned.getElementsByTagName('textarea');
        //         cloned.getElementById('zommContainer').style.display = 'none';
        //         let getHeight = [];
        //         for (var i = 0; i < textArea.length; i++) {
        //             getHeight.push(textArea[i].scrollHeight);
        //         }
        //         calMaxHeight = _.max(getHeight);
        //         for (var i = 0; i < textArea.length; i++) {
        //             const div = document.createElement('div');
        //             const textProp = textArea[i].getBoundingClientRect();
        //             div.innerText = textArea[i].value;
        //             div.style.padding = '2px -3px';
        //             div.style.height = `${calMaxHeight + textProp.height}px`; //`${textProp.height}px`;
        //             div.style.width = `${textProp.width}px`;
        //             div.style.textAlign = 'left';
        //             div.style.color = 'black';
        //             div.style.fontSize = 8;
        //             textArea[i].style.display = 'none';
        //             textArea[i].parentElement.append(div);
        //         }
        //     }
        // }).then((canvas) => {
        //     const imgData = canvas.toDataURL('image/png');
        //     // const pdf = new jsPDF({
        //     //     orientation: 'landscape'
        //     // });
        //     const pdf = new jsPDF('p', 'px', 'a4');
        //     const imgProps = pdf.getImageProperties(imgData);
        //     const pdfWidth = pdf.internal.pageSize.getWidth();
        //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight + calMaxHeight);
        //     // pdf.save('download.pdf');
        //     pdf.save(`TechnitianWriteUp-WIP${state.wipNumber}-${moment().format('DD-MMM-YYYY-HH:mm')}.pdf`);
        // });
    };
    useEffect(() => {
        let checkReadonly = showSubmit && !state.imgteamleadersign && !isAuditor && !isCustomer && canAmendTechWriteUpFlag;
        setState((st) => ({ ...st, makeDisbaled: !checkReadonly }));
    }, [state.isReadonly]);

    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value);
        showToast('Copied!');
    };

    const showSubmit = ['WS', 'TS', 'TR'].includes(state.status);
    let msg = `Please be aware that technicians ${technician?.toString().replace(/,(?=[^,]*$)/, ' and ')} ${
        technician.length == 1 ? 'is' : 'are'
    } assigned to this job. Please ensure to review and sign write ups of all technicians assigned to this job by clicking technician name, signing the write up and click the Submit button`;
    return (
        <>
            {state.showWarning ? (
                <DialogComp title="Warning" onClose={hideConfirmationModal} maxWidth="sm">
                    <ConfirmationModal message={msg} handleSubmit={submitFunction} handleCancel={hideConfirmationModal} />
                </DialogComp>
            ) : null}
            {state.showGrid ? <DisplayGrid onFormSubmit={pullAndUpdateState} onStandardWriteup={getStandartdFromChild} /> : null}
            <GlobalCss />
            {!fromStep && (
                <Breadcrumbs separator=">>" aria-label="breadcrumb">
                    <Link color="inherit" to={'/'}>
                        Technician App
                    </Link>
                    <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                        Technician Write-up
                    </Typography>
                </Breadcrumbs>
            )}
            <div style={{ background: 'white' }}>
                <DialogContents>
                    {state.showCircularLoader ? (
                        <Grid item sm={12}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            <Grid item sm={3} style={{ position: 'absolute', top: '1px', right: '20px' }} id="zommContainer">
                                <CustomButton
                                    color="secondary"
                                    icon={CloudDownloadIcon}
                                    onClick={printDocument}
                                    toolTipTitle="Download this document"
                                    iconFontSize={28}
                                />
                            </Grid>
                            <Grid container spacing={1}>
                                Registration Number:&nbsp; <b> {state.registration}</b>
                                <Grid item sm={3}>
                                    WIP No:&nbsp; <b> {state.wipNumber}</b>
                                </Grid>
                                <Grid item sm={3}>
                                    Customer:&nbsp; <b> {state.customerName}</b>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                {state.technicians.map((m) => (
                                    <div className="tech-btn-group">
                                        <Button
                                            className={state.technicianNotesJobID == m.technicianJobID ? 'active-btn' : 'all-btn'}
                                            onClick={() => TechBtnClick(m.technicianJobID)}
                                            endIcon={m.canDelete == 'Y' ? <DeleteIcon onClick={(e) => openIsDeleteTechWriteUpModal(e, m)} /> : ''}
                                        >
                                            {m.technician}
                                        </Button>
                                    </div>
                                ))}
                                <div title="Assign Technician" className="tech-btn-group" style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton aria-label="add" style={{ padding: '0px' }}>
                                        <PersonAddIcon onClick={handleAssignmentBtnClick} />
                                    </IconButton>
                                </div>
                                <>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormLabel component="legend" className="mandatoryfields">
                                            Date
                                        </FormLabel>
                                        <TextBox
                                            id="date"
                                            type="date"
                                            name="date"
                                            value={state.date}
                                            autoComplete="new-password"
                                            onChange={fieldChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            disabled={state.makeDisbaled}
                                        />
                                        <span className="mandatoryfields">{state.errors['date']}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormLabel component="legend" className="mandatoryfields">
                                            Odometer Reading
                                        </FormLabel>
                                        <TextBox
                                            type="number"
                                            required
                                            name="readingtaken"
                                            value={state.readingtaken}
                                            onChange={fieldChange}
                                            autoFocus={true}
                                            disabled={state.makeDisbaled}
                                        />
                                        <span className="mandatoryfields">{state.errors['readingtaken']}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormLabel component="legend">Odometer Reading Unit</FormLabel>
                                        <TextBox
                                            disabled={state.makeDisbaled}
                                            select
                                            value={state.technicianNotesReadingUnit}
                                            name="technicianNotesReadingUnit"
                                            onChange={fieldChange}
                                        >
                                            {state.odometerUnitsVM?.length > 0 &&
                                                state.odometerUnitsVM.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                        </TextBox>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {inputList.map((x, i) => {
                                            return (
                                                !x.isDeleted && (
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        style={{
                                                            border: i % 2 ? '1px dashed black' : '1px dashed  #2f38dd',
                                                            background: i % 2 ? '#f5f2c5' : '#ECEFF1',
                                                            marginBottom: 10,
                                                            padding: 5
                                                        }}
                                                    >
                                                        <Grid item xs={12} sm={4}>
                                                            <FormLabel component="legend" className="mandatoryfields">
                                                                Complaint
                                                                {!state.makeDisbaled && (
                                                                    <Tooltip title="Select Standard Write-Up" aria-label="add">
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                DisplayGridState(i);
                                                                            }}
                                                                            style={{
                                                                                padding: '1px',
                                                                                marginTop: '-9px',
                                                                                marginLeft: '10px'
                                                                            }}
                                                                            disabled={state.makeDisbaled}
                                                                        >
                                                                            <NoteAddIcon color="secondary" fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}{' '}
                                                                {x.technicianNotesItemFault && (
                                                                    <FileCopyIcon
                                                                        style={{ fontSize: '15px', cursor: 'pointer' }}
                                                                        onClick={() => copyToClipboard(x.technicianNotesItemFault)}
                                                                    />
                                                                )}
                                                                {` ${
                                                                    x.technicianNotesItemDateCreated
                                                                        ? formatters.DateTimeFormatter(x.technicianNotesItemDateCreated)
                                                                        : ''
                                                                }`}
                                                            </FormLabel>
                                                            <TextBox
                                                                name="technicianNotesItemFault"
                                                                // autoFocus={true}
                                                                value={x.technicianNotesItemFault}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                                multiline
                                                                rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                                                disabled={state.makeDisbaled}
                                                            />
                                                            <span className="mandatoryfields">{state.errors['complaint']}</span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormLabel component="legend" className="mandatoryfields">
                                                                Cause{' '}
                                                                {x.technicianNotesItemCause && (
                                                                    <FileCopyIcon
                                                                        style={{ fontSize: '15px', cursor: 'pointer' }}
                                                                        onClick={() => copyToClipboard(x.technicianNotesItemCause)}
                                                                    />
                                                                )}
                                                            </FormLabel>
                                                            <TextBox
                                                                name="technicianNotesItemCause"
                                                                value={x.technicianNotesItemCause}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                                multiline
                                                                rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                                                disabled={state.makeDisbaled}
                                                            />
                                                            <span className="mandatoryfields">{state.errors['cause']}</span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormLabel component="legend" className="mandatoryfields">
                                                                Cure{' '}
                                                                {x.technicianNotesItemDiagnosis && (
                                                                    <FileCopyIcon
                                                                        style={{ fontSize: '15px', cursor: 'pointer' }}
                                                                        onClick={() => copyToClipboard(x.technicianNotesItemDiagnosis)}
                                                                    />
                                                                )}
                                                            </FormLabel>
                                                            {!state.makeDisbaled && inputList.length !== 1 && (
                                                                <Tooltip title="Remove Standard Write-Up" aria-label="add">
                                                                    <IconButton
                                                                        onClick={() => handleRemoveClick(i, x.technicianNotesItemID)}
                                                                        disabled={state.makeDisbaled}
                                                                        className="rightButton"
                                                                    >
                                                                        <RemoveCircleIcon color="primary" fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                            {!state.makeDisbaled && inputList.length - 1 === i && (
                                                                <Tooltip title="Add Standard Write-Up" aria-label="add">
                                                                    <IconButton
                                                                        onClick={handleAddClick}
                                                                        className="rightButton"
                                                                        style={{
                                                                            marginRight: '5px'
                                                                        }}
                                                                        disabled={state.makeDisbaled}
                                                                    >
                                                                        <AddBoxIcon color="secondary" fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                            <TextBox
                                                                name="technicianNotesItemDiagnosis"
                                                                value={x.technicianNotesItemDiagnosis}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                                multiline
                                                                rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                                                disabled={state.makeDisbaled}
                                                            />
                                                            <span className="mandatoryfields">{state.errors['cure']}</span>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            );
                                        })}
                                    </Grid>
                                    {!portalSettings.workflowStepOptionsAsButtonGroup && (
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <FormLabel component="legend">Work Accomplished</FormLabel>
                                                <Multiselect
                                                    label="Work Accomplished"
                                                    options={masterData.workaccomplishcodes}
                                                    value={selectedData.selectedWorkAccomplishCodes}
                                                    onChange={AutoWorkAccomplish}
                                                    disabled={state.makeDisbaled}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormLabel component="legend">Failure Reasons</FormLabel>
                                                <Multiselect
                                                    label="Failu Rereason"
                                                    options={masterData.failureCodes}
                                                    value={selectedData.selectedFailureCodes}
                                                    onChange={AutoFrChange}
                                                    disabled={state.makeDisbaled}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} className="gridcontainer">
                                        <div>
                                            <Typography style={{ float: 'left' }}>Diagnostic Road Test</Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={3}>
                                                    <FormLabel component="legend">Mileage In</FormLabel>
                                                    <TextBox
                                                        disabled={state.makeDisbaled}
                                                        value={state.mileagein}
                                                        type="number"
                                                        name="mileagein"
                                                        onChange={fieldChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3} className="marginBottom">
                                                    <FormLabel component="legend">Mileage Out</FormLabel>
                                                    <TextBox
                                                        disabled={state.makeDisbaled}
                                                        value={state.mileageout}
                                                        type="number"
                                                        name="mileageout"
                                                        onChange={fieldChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <FormLabel component="legend">Time In</FormLabel>
                                                    <TextBox
                                                        disabled={state.makeDisbaled}
                                                        name="timein"
                                                        value={state.timein}
                                                        type="time"
                                                        onChange={fieldChange}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        inputProps={{
                                                            step: 300 // 5 min
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3} className="marginBottom">
                                                    <FormLabel component="legend">Time Out</FormLabel>
                                                    <TextBox
                                                        disabled={state.makeDisbaled}
                                                        name="timeout"
                                                        type="time"
                                                        value={state.timeout}
                                                        onChange={fieldChange}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        inputProps={{
                                                            step: 300 // 5 min
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormControlLabel
                                            control={
                                                <SecondaryCheckbox
                                                    disabled={state.makeDisbaled}
                                                    checked={state.smoketest}
                                                    onChange={handleCheck}
                                                    value={state.smoketest}
                                                    name="smoketest"
                                                />
                                            }
                                            label="Smoke Test Performed"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormControlLabel
                                            control={
                                                <SecondaryCheckbox
                                                    disabled={state.makeDisbaled}
                                                    checked={state.serviceindicator}
                                                    onChange={handleCheck}
                                                    value={state.serviceindicator}
                                                    name="serviceindicator"
                                                />
                                            }
                                            label="Service Indicator Reset "
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormControlLabel
                                            control={
                                                <SecondaryCheckbox
                                                    disabled={state.makeDisbaled}
                                                    checked={state.radiocode}
                                                    onChange={handleCheck}
                                                    name="radiocode"
                                                    value={state.radiocode}
                                                />
                                            }
                                            label="Radio Code Reset"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormControlLabel
                                            control={
                                                <SecondaryCheckbox
                                                    disabled={state.makeDisbaled}
                                                    checked={state.timeclock}
                                                    onChange={handleCheck}
                                                    name="timeclock"
                                                    value={state.timeclock}
                                                />
                                            }
                                            label="Time Clock Reset"
                                        />
                                    </Grid>
                                    <>
                                        {state.imgtechniciansign && (
                                            <Grid item xs={12} sm={6}>
                                                <CustomeSignPad
                                                    onChange={GetTechnicianSign}
                                                    content={
                                                        <FormLabel
                                                            component="legend"
                                                            className="mandatoryfields"
                                                            style={{
                                                                marginBottom: '9px',
                                                                marginTop: '5px'
                                                            }}
                                                        >
                                                            Technician
                                                            {hideBracket ? (
                                                                <span> ({`${state.technicianName || ''} ${state.technicianDateSigned}`})</span>
                                                            ) : (
                                                                ' Signature'
                                                            )}
                                                        </FormLabel>
                                                    }
                                                    sign={state.imgtechniciansign}
                                                    name="techniciansign"
                                                    hideBtn={true}
                                                    clearSig={() => clearSig('techniciansign')}
                                                />
                                            </Grid>
                                        )}
                                        {(showSubmit || state.imgteamleadersign) && canSignOffTechWriteup && (
                                            <Grid item xs={12} sm={6} style={{ marginTop: state.imgteamleadersign ? 0 : state.imgtechniciansign ? 4 : 11 }}>
                                                <CustomeSignPad
                                                    onChange={GetTechnicianSign}
                                                    content={
                                                        <FormLabel
                                                            component="legend"
                                                            className="mandatoryfields"
                                                            style={{
                                                                marginBottom: '9px',
                                                                marginTop: '5px'
                                                            }}
                                                        >
                                                            Workshop Controller
                                                            {hideLeaderBracket ? (
                                                                <span>({`${state.teamLeaderName || ''} ${state.teamLeaderDateSigned}`})</span>
                                                            ) : (
                                                                ' Signature'
                                                            )}
                                                        </FormLabel>
                                                    }
                                                    sign={state.imgteamleadersign}
                                                    name="teamleadersign"
                                                    hideBtn={state.imgteamleadersign ? true : false}
                                                    clearSig={() => clearSig('teamleadersign')}
                                                />
                                                <span className="mandatoryfields">{state.errors['teamleadersign']}</span>
                                            </Grid>
                                        )}
                                    </>
                                    <Grid item xs={12} sm={4} style={{ marginTop: '25px' }}></Grid>
                                </>
                                {/* {!showSubmit && (
                                    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '90vh' }}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography color="secondary" variant="h2" component="h2">
                                                Technician write up not completed
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )} */}
                            </Grid>
                            {showSubmit && !state.imgteamleadersign && !isAuditor && !isCustomer && (canAmendTechWriteUpFlag || canSignOffTechWriteup) ? (
                                <>
                                    {state.submitLoader ? (
                                        <SecondaryButton fullWidth className="btn_add setFullWidth">
                                            <CircularProgress size={25} />
                                        </SecondaryButton>
                                    ) : (
                                        <SecondaryButton className="btn_add setFullWidth" onClick={technician.length > 1 ? handleSubmit : submitFunction}>
                                            Submit
                                        </SecondaryButton>
                                    )}
                                </>
                            ) : null}
                            {isDeleteTechWriteUp.show ? (
                                <DialogComp title={'Warning'} maxWidth="md" onClose={closeIsDeleteTechWriteUpModal} fullWidth>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Alert severity="warning">
                                                Are you sure you want to delete write up for {isDeleteTechWriteUp.selectedData?.technician}
                                            </Alert>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SecondaryButton
                                                fullWidth
                                                onClick={deleteTechWriteUpHandle}
                                                // disabled={!state.partReturnCollectedSign}
                                                //  isBtnLoader={btnLoader}
                                            >
                                                Yes
                                            </SecondaryButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SecondaryButton fullWidth onClick={closeIsDeleteTechWriteUpModal}>
                                                No
                                            </SecondaryButton>
                                        </Grid>
                                    </Grid>
                                </DialogComp>
                            ) : null}
                            {state.showTechAssign ? (
                                <DialogComp title="Assigned Technician" maxWidth="sm" onClose={() => closeTechAssignDialog(false)} fullWidth>
                                    <AssignTech jobId={Id} handleCancel={closeTechAssignDialog} />
                                </DialogComp>
                            ) : null}
                        </>
                    )}
                </DialogContents>
            </div>
        </>
    );
};
export default TechWrite_Up;
