import {get, post} from './http-calls';

export const getWorkAccomplishCodes = async ()=>{
    let res = await get('/WorkAccomplishedCodes', { useAuthToken: true });
    return res;
}
export const addWorkAccomplishedCodes = async (id, name) => {
    let res = await post(`WorkAccomplishedCodes`, {
        WorkAccomplishedCodeID:id,
        WorkAccomplishedDescription: name,
    }, { useAuthToken: true });
    return res;
}
export const DeleteWorkAccomplishedCodes = async (id) => {
    let res = await post(`WorkAccomplishedCodes/DeleteWorkAccomplishedCode/${id}`, {
        Id: id,
    },{ useAuthToken: true });
    return res;
}