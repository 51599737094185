
import { get, post } from './http-calls';

export const getDiagnosticCharge = async jobId =>{
    let res = await get(`DiagnosticCharges/${jobId}`, { useAuthToken : true });    
    return res;
}

export const saveDiagnosticCharge= async (data) => {
    let res = await post(`DiagnosticCharges`, data,{ useAuthToken: true });
    return res;
}