import { get, post } from './../http-calls';

export let getVORscreenDropdowns = async (id) => {
    let data = await get(`VORVehicle/screenDropdowns`, { useAuthToken: true });
    return data;
};

export const postVORVehicle = async (data) => {
    let res = await post(`VORVehicle`, data, { useAuthToken: true });
    return res;
};
export let getVorByID = async (id) => {
    let data = await get(`VORVehicle/VOR/${id}`, { useAuthToken: true });
    return data;
};
