import { get, post } from './http-calls';

export const getInvoiceBranchByID = async (id) => {
    let res = await get(`/api/Branches/GetBrancheByID/${id}`, { useAuthToken: true });
    return res;
};

export const getReportingAgedMeasuresID = async (id) => {
    let res = await get(`ReportingAgedMeasures/${id}`, { useAuthToken: true });
    return res;
};

export const postInvoiceBranch = async (data) => {
    let res = await post(`Branches/BranchInvoiceUpdate`, data, { useAuthToken: true });
    return res;
};

export const postReportingAgedMeasures = async (data) => {
    let res = await post(`ReportingAgedMeasures`, data, { useAuthToken: true });
    return res;
};
