import { Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { SecondaryButton, TextBox } from '../../../../../Core/FormInput';
import { useSSR } from 'react-i18next';
import { getLeagueTablePointsbyID, postLeagueTablePoints } from '../../../../../Core/Service/SalesPerformance-service';
import AppContext from '../../../../../App/AppContext';

const LeagueTablePointsModal = (props) => {
    const { stockObj } = props;

    const { showToast } = useContext(AppContext);
    const [state, setState] = useState({});

    const handleTextField = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    useEffect(async () => {
        if (stockObj?.id) {
            // let res = await getLeagueTablePointsbyID(stockObj?.id);
            // if (res.success) {
            // let data = res.data.list.find((k) => {
            //     return k.id === stockObj?.id;
            // });
            let data = stockObj;

            setState((st) => ({
                ...st,
                description: data.description,
                id: data.id,
                points: data.points
            }));
        }
    }, []);

    const handleSubmit = async () => {
        let data = {
            id: state.id || null,
            points: state.points,
            description: state.description
        };

        let res = await postLeagueTablePoints(data);
        if (res.success) {
            showToast('Stocking Charges Added Successfully');
            props.onClose();
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputLabel> Points</InputLabel>
                <TextBox type="number" placeholder="Points" name="points" value={state.points} onChange={handleTextField} />
            </Grid>
            <Grid item xs={12}>
                <InputLabel> Description</InputLabel>
                <TextBox type="text" placeholder="Description" name="description" value={state.description} multiline rows={3} onChange={handleTextField} />
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton fullWidth onClick={handleSubmit}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default LeagueTablePointsModal;
