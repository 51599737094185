import React from 'react';
import { Grid, Avatar, Typography, Paper, TableBody, Table, TableCell, TableContainer, TableHead, TableRow, Hidden } from '@material-ui/core';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ComposedChart } from 'recharts';
import { LineChart, Line, PieChart, Pie, Sector, LabelList } from 'recharts';
import { formatMs, makeStyles } from '@material-ui/core/styles';
import formatters from '../../../../../Core/Grid/inputFormatter';

// import { Tooltip } from '@material-ui/core';
const useStyles = makeStyles({
    table: {
        border: '1px solid #d3d3d3',
        minHeight: 250
    }
});

const COLORS = [
    '#0074D9',
    '#FF4136',
    '#2ECC40',
    '#FF851B',
    '#7FDBFF',
    '#B10DC9',
    '#FFDC00',
    '#001f3f',
    '#39CCCC',
    '#01FF70',
    '#85144b',
    '#F012BE',
    '#3D9970',
    '#111111',
    '#AAAAAA'
];

const barColors = {
    0: '#0074D9',
    1: '#FF4136',
    2: '#2ECC40',
    3: '#FF851B',
    4: '#7FDBFF',
    5: '#B10DC9',
    6: '#FFDC00',
    7: '#001f3f',
    8: '#39CCCC',
    9: '#01FF70',
    10: '#85144b',
    11: '#F012BE',
    12: '#3D9970',
    13: '#111111',
    14: '#AAAAAA'
};

const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value, formatter, fill } = props;
    console.log(formatter, 'formatter');
    return (
        <text x={x + width / 2} y={value > 0 ? y - 10 : y + 10} fill={fill} textAnchor="middle" dominantBaseline="middle" fontSize={14}>
            {formatter ? formatters[`${formatter}`](value) : value}
        </text>
    );
};

const CustomTooltip = ({ active, payload, label, formatter }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'white', border: '1px solid black', padding: '10px', textAlign: 'center' }}>
                <div>{label}</div>
                <div style={{ color: '#1ECBE1' }}>
                    {payload[0].name}: &nbsp;
                    {formatter ? formatters[`${formatter}`](+payload[0].value) : +payload[0].value}
                </div>
            </div>
        );
    }

    return null;
};

export const DashboardTemplets = (props) => {
    return (
        <div style={{ width: '100%', borderRadius: 5, padding: 5 }}>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={3}>
                    <Avatar style={{ background: 'skyblue', height: 50, width: 50, marginLeft: 5, marginTop: 5 }}>
                        {!props.currency ? <DriveEtaIcon style={{ fontSize: 25 }} /> : <span style={{ fontSize: 25 }}>£</span>}
                    </Avatar>
                </Grid>
                <Grid item xs={9} style={{ textAlign: 'left' }}>
                    <div className="mainValue">{props.val}</div>
                    <div className="title">{props.title}</div>
                </Grid>
            </Grid>
        </div>
    );
};

export const SalesBarChart = (props) => {
    return (
        <>
            {props.data?.length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={props.data}
                        width={300}
                        margin={{
                            top: 10,
                            right: 5,
                            left: -16,
                            bottom: 5
                        }}
                        contentStyle={{ margingBottom: 10 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="name"
                            style={{ fontSize: 10 }}
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            // angle="-40"
                            // strok    e="#8884d8"
                        />
                        <YAxis style={{ fontSize: 10 }} />
                        <Tooltip
                            contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }}
                            content={<CustomTooltip formatter={props.formatter ? props.formatter : null} />}
                        />

                        {props.totalVal ? (
                            <Bar dataKey="totalvalue" name="Total" fill={'White'} barSize={40} height={10}>
                                {/* <LabelList dataKey="Total" content={renderCustomizedLabel} /> */}

                                {props.data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={barColors[index]}
                                        onClick={() => {
                                            props.onClick && props.onClick(entry.name);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                ))}
                            </Bar>
                        ) : (
                            <Bar dataKey="total" name="Total" fill={'White'} barSize={30}>
                                {' '}
                                {props.data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={barColors[index]}
                                        onClick={() => {
                                            props.onClick && props.onClick(entry.name);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                ))}
                            </Bar>
                        )}
                    </BarChart>
                </ResponsiveContainer>
            ) : (
                <div
                    style={{
                        height: '227px',
                        width: '100%',
                        border: '2px solid #F5F5F5',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    No Record found
                </div>
            )}
        </>
    );
};

export const SalePieChart = (props) => {
    return (
        <>
            {props.data?.length > 0 ? (
                <>
                    <Hidden only="xs">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={props.data}
                                    cx="15%"
                                    cy="50%"
                                    outerRadius={50}
                                    innerRadius={30}
                                    fill="#8884d8"
                                    dataKey="value"
                                    paddingAngle={4}
                                    // labelLine={true}
                                    // label
                                >
                                    {props?.data?.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                            onClick={() => {
                                                props.onClick && props.onClick(entry.name);
                                            }}
                                            style={{ cursor: `${props.onClick ? 'pointer' : ''}` }}
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                            {/* <Tooltip contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }} position={{ x: 30, y: 20 }} /> */}
                        </ResponsiveContainer>
                        <div style={{ position: 'absolute', right: 10, top: 40, maxHeight: 250 }} className="custom-scroll">
                            {props?.data?.map((m, i) => {
                                let color = COLORS[i];
                                return (
                                    <div style={{ padding: 0 }}>
                                        <div style={{ fontSize: 15, width: 250, display: 'flex', justifyContent: 'flex-start' }}>
                                            {' '}
                                            <div style={{ background: color, height: 10, width: 10, marginTop: 4 }}></div>
                                            <div style={{ fontSize: 13 }}>&nbsp;{m.name}</div>
                                            <div style={{ fontSize: 12, fontWeight: 'bold' }}>
                                                &nbsp;({formatters.CurrencyThousandSeparatorWithoutZero(m.value)})
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={400} height={400}>
                                <Pie data={props.data} cx="50%" cy="50%" outerRadius={50} innerRadius={30} fill="#8884d8" dataKey="value" paddingAngle={4}>
                                    {props?.data?.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                            onClick={() => {
                                                props.onClick && props.onClick(entry.name);
                                            }}
                                            style={{ cursor: `${props.onClick ? 'pointer' : ''}` }}
                                        />
                                    ))}
                                </Pie>
                                <Legend />
                            </PieChart>
                            {/* <Tooltip contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }} position={{ x: 30, y: 20 }} /> */}
                        </ResponsiveContainer>
                    </Hidden>
                </>
            ) : (
                <div
                    style={{
                        height: '327px',
                        width: '100%',
                        border: '2px solid #F5F5F5',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 25
                    }}
                >
                    No Record found
                </div>
            )}
        </>
    );
};

export const SalesLineChart = (props) => {
    return (
        <>
            {props.data?.length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={props.data}
                        margin={{
                            top: 20,
                            right: 5,
                            left: -16,
                            bottom: 15
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="name"
                            style={{ fontSize: 10 }}
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            // angle="-40"
                            // stroke="#8884d8"
                        />
                        <YAxis style={{ fontSize: 10 }} />
                        <Tooltip contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }} />
                        {/* <Legend /> */}
                        <Line type="monotone" dataKey="val" stroke={props.color} strokeWidth={4}></Line>
                    </LineChart>
                </ResponsiveContainer>
            ) : (
                <div
                    style={{
                        height: '227px',
                        width: '100%',
                        border: '2px solid #F5F5F5',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    No Record found
                </div>
            )}
        </>
    );
};

export const SalesTable = (props) => {
    const classes = useStyles();
    return (
        <TableContainer
            component={Paper}
            style={{ minHeight: 100, height: 'auto', maxHeight: 262, border: '1px solid rgba(0,0,0,0.2)' }}
            className="custom-scroll"
        >
            <Table className={classes.table} aria-label="caption table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>{props.t1 ? 'VehicleType' : 'Salesperson'}</TableCell>
                        <TableCell>Sold</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{ height: 200, overflow: 'auto' }}>
                    {props?.rows?.length > 0 ? (
                        props?.rows?.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.value}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell align="center">No record found</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
