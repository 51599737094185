import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Grid, FormLabel, IconButton } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { SingelSelect, TextBox } from '../../../../../Core/FormInput';
import { ExpBaseURL } from '../../../../../Core/Service/http-calls';
import { AppStorage } from '../../../../../Core/Service/storage-service';
import formatters from '../../../../../Core/Grid/inputFormatter';
import GetWIPHeader from '../../GetWIPDetails';
import DialogComp from '../../../../../Core/Controls/Dialog/DialogComp';
import { getAgedMeasuresViewDocument } from '../../../../../Core/Service/ReportScreens-service';
import ViewPdfModal from '../../../../../Core/Modal/PdfViweModal';
import ReactDOM from 'react-dom';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { GetBranchAndFranchiesDropdowns, GetExcelFileFromEndpoint } from '../../../../../Core/Service/reportService';
import AppContext from '../../../../../App/AppContext';
import Tooltip from '@material-ui/core/Tooltip';
import { useContext } from 'react';
import { GetBranchDeportmentScreenDropdowns } from '../../../../../Core/Service/reportService';
import { getFinanceList } from '../../../../../Core/Service/SalesPerformance-service';

const baseColumns = [
    { field: 'bn', title: `Branch`, width: 180 },
    { field: 'sold', title: `Sold Hours`, width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'taken', title: `Taken Hours`, width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'eff', title: `Efficiency %`, width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'sale', title: `Sale Value`, width: 150, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    { field: 'cost', title: `Cost Value`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    { field: 'profit', title: `Profit`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    { field: 'marginPerc', title: 'Margin %', width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'recRate', title: 'Rec Rate ', width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.CurrencyThousandSeparator }
];

const wipColumns = [
    { title: 'WIP', field: 'wn', width: 70, sortable: true },
    { title: 'Type', field: 'tc', width: 50, sortable: true },
    { title: 'Description', field: 'rts', width: 100, sortable: true },
    { title: 'Service text', field: 'st', width: 120, sortable: true, align: 'right' },
    { title: 'Reg No', field: 'rn', width: 70, sortable: true, align: 'right' },
    { title: 'Allowed', field: 'sold', width: 60, sortable: true, align: 'right', renderer: formatters.ThousandSeparatorWithoutZero },
    { title: 'Taken', field: 'taken', width: 60, sortable: true, align: 'right' },
    { title: 'Sale', field: 'sale', width: 100, sortable: true, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { title: 'Dep', field: 'dd', width: 70, sortable: true, align: 'right' },
    { title: 'L/S', field: 'ls', width: 40, sortable: true, align: 'right' },
    { title: 'I', field: 'invstatus', width: 30, sortable: true, align: 'right' },
    { title: 'Invoice', field: 'inv', width: 70, sortable: true, align: 'right' },
    { title: 'Account', field: 'ac', width: 70, sortable: true, align: 'right' },
    { title: 'Fran', field: 'fc', width: 40, sortable: true, align: 'right' },
    { title: 'Date In', field: 'di', width: 70, sortable: true, align: 'right', renderer: formatters.DateFormatter }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Services', active: true },
    { name: 'Uninvoiced Hours', active: true }
];
var mainGrid, opGrid;
var $ = window.$;

const UninvoicedHours = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const search = window.location.search;
    const params = new URLSearchParams(search);

    let paramsSelectedDate = params.get('selectedDate');
    let ParamStartDateType = params.get('startDateType') || '';
    let ParamBranchID = params.get('branchID') || '';
    let Paramfn = params.get('fn') || '';

    let dateType = ParamStartDateType == 'ytd' ? 'year' : 'month';
    let startDate = paramsSelectedDate
        ? moment(paramsSelectedDate).format('YYYY-MM-DD')
        : // moment().startOf(`${dateType}`).format('YYYY-MM-DD');
          '';
    let endDate = paramsSelectedDate ? startDate : moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        BranchList: [],
        branchID: null,
        fn: null,
        FranchiseList: []
    });

    useEffect(async () => {
        // let pros = [];
        let responses = await GetBranchAndFranchiesDropdowns();
        // pros.push(GetBranchDeportmentScreenDropdowns(), getFinanceList());
        // let responses = await Promise.all(pros);
        if (responses.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.BranchList = responses.data?.branchList || [];
                if (ParamBranchID) {
                    newSt.branchID = responses.data?.branchList.find((r) => r.id == ParamBranchID);
                }
                if (Paramfn) {
                    let getObj = {};
                    let findArray = responses.data?.franchise.find((r) => r.id == Paramfn);
                    getObj.id = findArray.id;
                    getObj.name = findArray.name;
                    newSt.fn = { ...getObj };
                }

                newSt.FranchiseList =
                    responses.data?.franchise.map((m) => ({
                        id: m.id,
                        name: m.name
                    })) || [];
                return newSt;
            });
        }
    }, []);

    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    let token = AppStorage.getToken();

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                onClick={() => setState((st) => ({ ...st, showWIPDetails: true, wipNumber: record.wipNumber, company: record.company }))}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const documentHandler = async (record) => {
        let res = await getAgedMeasuresViewDocument(record.invoiceFileName);
        if (res) {
            setState((st) => ({ ...st, showPdfModal: true, pdfFileData: res }));
        } else {
            console.error(res.message);
        }
    };

    const docEditButton = (value, record) => {
        const spn = document.createElement('span');
        let isFile = record.invoiceFileName;
        const lnk = (
            <span style={{ cursor: isFile ? 'pointer' : 'text' }} onClick={isFile ? () => documentHandler(record) : ''}>
                {value}
            </span>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    // const NewWipcolumns = useMemo(() => {
    //     const tempCols = [...wipColumns];
    //     let wipNumberCol = tempCols.find((element) => element.field === 'wipNumber');
    //     let ViewDocCol = tempCols.find((element) => element.field === 'invoiceNumber');
    //     if (ViewDocCol) {
    //         ViewDocCol.renderer = docEditButton;
    //     }
    //     if (wipNumberCol) {
    //         wipNumberCol.renderer = editButton({});
    //     }
    //     return tempCols;
    // });
    let getUrl = `${ExpBaseURL}/api/services/ReportingGetUnInvoicedHours?StartDate=${state.startDate}&EndDate=${state.endDate}&branchID=${
        state.branchID?.id || ''
    }&fn=${state.fn?.id || ''}`;

    const setDataSource = (gridInstance, getUrl) => {
        const onSuccessFunc = function (response) {
            gridInstance.render({
                records: response.data.list,
                total: response?.data?.total
            });
        };

        gridInstance.data().dataSource = {
            url: `${getUrl}`,

            // data: { StartDate: state.startDate, EndDate: state.endDate },
            headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
            success: onSuccessFunc
        };
    };

    const pullInvoiceHours = async () => {
        window.$(`#tb_InvoicedHours`).grid('destroy', true, true);

        mainGrid = $('#tb_InvoicedHours').grid({
            primaryKey: 'branchID',
            dataSource: [],
            // dataSource: {
            //     url: getUrl,
            //     headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
            //     success: mainGridOnSuccessFunc
            // },
            detailTemplate: '<div><table/></div>',
            columns: baseColumns,
            pager: { limit: 15 }
        });

        setTimeout(() => {
            setDataSource(mainGrid, getUrl);
            mainGrid.reload();
        });

        mainGrid.on('dataBound', function (e, records, totalRecords) {
            var recordsCount = records.length;
            var sumsold = 0,
                sumtaken = 0,
                sumeff = 0,
                sumsale = 0,
                sumcost = 0,
                sumprofit = 0,
                summarginPerc = 0,
                sumrecRate = 0,
                $tfoot;
            $.each(records, function () {
                sumsold += parseFloat(this.sold);
                sumtaken += parseFloat(this.taken);
                sumeff += parseFloat(this.eff);
                sumsale += parseFloat(this.sale);
                sumcost += parseFloat(this.cost);
                sumprofit += parseFloat(this.profit);
                summarginPerc += parseFloat(this.marginPerc);
                sumrecRate += parseFloat(this.recRate);
            });
            sumeff = parseFloat((sumsold / sumtaken) * 100);
            summarginPerc = parseFloat(((sumsale - sumcost) * 100) / sumsale); //summarginPerc / recordsCount);
            sumrecRate = parseFloat(sumsale / sumsold); //sumrecRate / recordsCount);
            $tfoot = mainGrid.children('tfoot');
            $tfoot.children('tr[data-role="TotalAmount"]').remove();
            $tfoot.children('tr[data-role="pager"]').remove();
            $tfoot.prepend(
                '<tr data-role="TotalAmount"><th></th><th>Total</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumsold) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumtaken) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumeff) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumsale) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumcost) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumprofit) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(summarginPerc) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumrecRate) +
                    '</th></tr>'
            );
        });
        mainGrid.on('detailExpand', function (e, $detailWrapper, branchID) {
            opGrid = $detailWrapper.find('table').grid({
                params: { branchID: branchID, fn: state.fn?.id || '' },
                primaryKey: 'opno',
                dataSource: [],
                columns: wipColumns,
                pager: { limit: 10 }
            });

            setDataSource(
                opGrid,
                `${ExpBaseURL}/api/services/ReportingGetUnInvoicedHours?StartDate=${state.startDate}&EndDate=${state.endDate}&Mode=B&AllData=Y`
            );
            opGrid.reload();

            opGrid.on('detailCollapse', function (e, $detailWrapper, opno) {
                $detailWrapper.find('table').grid('destroy', true, true);
            });
        });
        mainGrid.on('detailCollapse', function (e, $detailWrapper, id) {
            $detailWrapper.find('table').grid('destroy', true, true);
        });
        mainGrid.reload();
    };
    useEffect(() => {
        if (state.BranchList.length > 0) {
            pullInvoiceHours();
        }
    }, [state.startDate, state.endDate, state.branchID, state.BranchList, state.fn]);

    const downloadCSVWrapper = async () => {
        showToast('Collating Data... This may take a while.');
        const data = mainGrid.data().params;
        let downloadURL = `services/ReportingGetUnInvoicedHours?StartDate=${state.startDate}&EndDate=${state.endDate}&fn=${state?.fn?.id}${
            getUrl.includes('?') ? '&' : '?'
        }fileType=excel`;
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                downloadURL += `&${key}=${value}`;
            }
        }
        await GetExcelFileFromEndpoint(downloadURL, 'InvoicedHours');
    };
    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st, [nm]: val };
            return newSt;
        });
    };

    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            {state.showWIPDetails && (
                <DialogComp title="WIP Details" onClose={() => setState((st) => ({ ...st, showWIPDetails: false }))} maxWidth="xl" fullWidth>
                    <GetWIPHeader wipNumber={state.wipNumber} company={state.company} />
                </DialogComp>
            )}
            {state.showPdfModal ? (
                <DialogComp title="View PDF" maxWidth="md" onClose={() => setState((st) => ({ ...st, showPdfModal: false }))} fullWidth>
                    <ViewPdfModal pdfFileData={state.pdfFileData} />
                </DialogComp>
            ) : null}
            <Grid item container spacing={1} xs={12} style={{ padding: '0px 10px' }}>
                <Grid item container spacing={1} xs={12} style={{ textAlign: 'left' }}>
                    <Grid item xs={6} md={2} lg={2}>
                        <FormLabel component="legend">Start Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={6} md={2} lg={2}>
                        <FormLabel component="legend">End Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="endDate"
                            fullWidth
                            value={state.endDate}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { Max: `${moment().format('YYYY-MM-DD')}` } }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormLabel component="legend" style={{ marginTop: -1 }}>
                            Select Branch
                        </FormLabel>
                        <SingelSelect onChange={singleSelectChange('branchID')} options={state.BranchList || []} value={state.branchID} />
                    </Grid>
                    <Grid item xs={6} md={5} lg={3} xl={2}>
                        <FormLabel component="legend" style={{ marginTop: -1 }}>
                            Select Franchise
                        </FormLabel>
                        <SingelSelect onChange={singleSelectChange('fn')} options={state.FranchiseList || []} value={state.fn} />
                    </Grid>
                </Grid>
                <Grid container className="report-screen-gijgo " item xs={12} sm={12} style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }}>
                    <Grid item style={{ bottom: '14px', left: '2%', zIndex: 99, position: 'absolute' }}>
                        <Tooltip title="Download Excel" arrow>
                            <IconButton aria-label="delete" style={{ padding: '0px' }}>
                                <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} style={{ color: 'red' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <table id={`tb_InvoicedHours`}></table>
                </Grid>
            </Grid>
        </div>
    );
};

export default UninvoicedHours;
