import React, { useEffect, useState } from 'react';
import Dashboard from './../../../dashboard/dashboard';

import { ServicePieChart, ServieBarChart, ServiceLineChart } from './serviceChart';
import { useHistory } from 'react-router-dom';
import {
    getWarrantyDashBoard,
    getSoldHoursDashBoard,
    getWIPDashBoard,
    getGetUserFilterRecords,
    getServieDashboardRecored,
    getIDLERecored
} from './../../../../../Core/Service/serviceDashboard_service';
import { Card, CircularProgress, FormLabel, Grid, Typography } from '@material-ui/core';
import { SingelSelect, TextBox } from '../../../../../Core/FormInput';
import moment from 'moment';
import formatters from '../../../../../Core/Grid/inputFormatter';
import './serviceDash.scss';
import { GetBranchAndFranchiesDropdowns, GetBranchDeportmentScreenDropdowns } from '../../../../../Core/Service/reportService';
import { getFinanceList } from '../../../../../Core/Service/SalesPerformance-service';
import { RingVolume } from '@material-ui/icons';

let months = {
    January: 'Jan',
    February: 'Feb',
    March: 'Mar',
    April: 'Apr',
    May: 'May',
    June: 'Jun',
    July: 'Jul',
    August: 'Aug',
    September: 'Sep',
    October: 'Oct',
    November: 'Nov',
    December: 'Dec'
};

const ServiceDashboard = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamStartDateType = params.get('startDateType') || '';
    let ParamBranchID = params.get('branchID') || '';

    let dateType = ParamStartDateType == 'ytd' ? 'year' : 'month';

    let startDate = moment().startOf(`${dateType}`).format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        soldbyMonth: [],
        soldbyBranch: [],
        servicecWarrenty: [],
        partWarrenty: [],
        WipHour: [],
        WipOutStanding: [],
        showLoader: true,
        startDate: startDate,
        endDate: endDate,
        BranchList: [],
        FranchiseList: [],
        idleHours: [],
        techEff: []
    });

    useEffect(async () => {
        let responses = await GetBranchAndFranchiesDropdowns();
        // let pros = [];
        // pros.push(GetBranchDeportmentScreenDropdowns(), getFinanceList());
        // let responses = await Promise.all(pros);
        if (responses.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.BranchList = responses.data?.branchList || [];
                if (ParamBranchID) {
                    newSt.branchID = responses.data?.branchList.find((r) => r.id == ParamBranchID);
                }
                newSt.FranchiseList =
                    responses.data.franchise.map((m) => ({
                        id: m.id,
                        name: m.name
                    })) || [];
                return newSt;
            });
        }
    }, []);

    useEffect(() => {
        pulldata();
    }, [state.startDate, state.endDate, state.branchIDID, state.fnID]);

    let pulldata = async () => {
        let res = await getWarrantyDashBoard(state.startDate, state.endDate, state.branchIDID || '', state.fnID || '');
        let res_1 = await getIDLERecored(state.startDate, state.endDate, state.branchIDID || '', state.fnID || '');
        let res_2 = await getSoldHoursDashBoard(state.startDate, state.endDate, state.branchIDID || '', state.fnID || '');
        let res_3 = await getWIPDashBoard(state.startDate, state.endDate, state.branchIDID || '', state.fnID || '');
        // let res_4 = await getGetUserFilterRecords();

        let soldBranchList = res_2?.data?.table1 || [];
        let idleTimeList = res_1?.data?.idelHours || [];
        let wipHoursList = res_3?.data?.table || [];
        let partList = res_1?.data?.parts || [];

        let filterPartList = partList.reduce((acc, obj) => {
            var index = acc.find((item) => item.branchID === obj.branchID);
            if (index) {
                index.saleValue += obj.saleValue;
                index.grossProfit += obj.grossProfit;
                return acc;
            }
            acc.push({ ...obj });
            return acc;
        }, []);

        console.log(filterPartList, 'filterPartList');

        let totalsoldbyBranch = 0;
        let totalTotalIdle = 0;
        let totalWipHours = 0;

        soldBranchList?.forEach((obj, ind) => {
            totalsoldbyBranch += obj.labourTotal;
        });
        idleTimeList?.forEach((obj, ind) => {
            totalTotalIdle += obj.totalIdleHours;
        });
        wipHoursList?.forEach((obj, ind) => {
            totalWipHours += obj.totalHours;
        });

        setState((st) => ({
            ...st,
            servicecWarrenty:
                res?.data?.table?.map((n) => {
                    return {
                        name: n.branch,
                        total: n.totalAmount
                    };
                }) || [],
            partWarrenty:
                res?.data?.table1?.map((n) => {
                    return {
                        name: n.branch,
                        total: n.totalAmount
                    };
                }) || [],
            idleHours:
                idleTimeList?.map((n) => {
                    return {
                        name: n.id,
                        total: n.totalIdleHours,
                        code: n.ic
                    };
                }) || [],
            techEff:
                res_1?.data?.techEff?.map((n) => {
                    return {
                        name: n.bn,
                        total: n.eff,
                        code: n.bc
                    };
                }) || [],

            soldbyMonth:
                res_2?.data?.table?.map((n) => {
                    return {
                        name: months[n.labourMonth],
                        // name: moment(n.labourMonth).format('MMM'),
                        total: n.labourHours,
                        [n.labourMonth]: n.labourHours
                    };
                }) || [],
            soldbyBranch: soldBranchList?.map((n) => {
                return {
                    name: n.branchShortName,
                    total: n.labourTotal
                };
            }),

            WipHour:
                wipHoursList?.map((n) => {
                    return {
                        name: n.branchShortName,
                        total: n.totalHours
                    };
                }) || [],
            WipOutStanding:
                res_3?.data?.table1?.map((n) => {
                    return {
                        name: n.branchShortName,
                        total: n.totalOutstanding
                    };
                }) || [],
            totalsoldbyBranch: totalsoldbyBranch,
            totalTotalIdle: totalTotalIdle,
            totalWipHours: totalWipHours,
            filterPartList: filterPartList,
            showLoader: false
        }));
    };

    useEffect(async () => {}, []);

    const history = useHistory();

    const GoToWorkInProgress = () => {
        history.push({
            pathname: `/AgedMeasures/WorkInProgress`
        });
    };
    const GoToServiceNominals = () => {
        history.push({
            pathname: `/Services/ServiceWarrantyWriteOff`
        });
    };
    const GoToDailyInvoicedHours = () => {
        history.push({
            pathname: `/Services/InvoicedHours`
        });
    };
    const GoToPartsWarrantyOff = () => {
        history.push({
            pathname: `/Services/PartsWarrantyWriteOff`
        });
    };

    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value, showLoader: true }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st, [nm]: val, [`${nm}ID`]: val?.id || '', showLoader: true };
            return newSt;
        });
    };

    console.log(state, 'state');

    return (
        <div style={{ padding: '10px', marginTop: 10 }}>
            <Grid container spacing={2} justifyContent="space-around">
                <Grid item sm={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={2} lg={2}>
                            <FormLabel component="legend">Start Date</FormLabel>
                            <TextBox
                                id="date"
                                type="date"
                                name="startDate"
                                fullWidth
                                value={state.startDate}
                                onChange={fieldChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{ inputProps: { max: `${state.endDate}` } }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} lg={2}>
                            <FormLabel component="legend">End Date</FormLabel>
                            <TextBox
                                id="date"
                                type="date"
                                name="endDate"
                                fullWidth
                                value={state.endDate}
                                autoComplete="new-password"
                                onChange={fieldChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                            />
                        </Grid>
                        <Grid item xs={6} md={3} lg={2}>
                            <FormLabel component="legend" style={{ marginTop: -1 }}>
                                Select Branch
                            </FormLabel>
                            <SingelSelect onChange={singleSelectChange('branchID')} options={state.BranchList || []} value={state.branchID} />
                        </Grid>
                        <Grid item xs={6} md={5} lg={3} xl={2}>
                            <FormLabel component="legend" style={{ marginTop: -1 }}>
                                Select Franchise
                            </FormLabel>
                            <SingelSelect onChange={singleSelectChange('fn')} options={state.FranchiseList || []} value={state.fn} />
                        </Grid>
                    </Grid>
                </Grid>
                {state.showLoader ? (
                    <div style={{ width: '100%' }}>
                        <div
                            style={{ height: 'calc(100vh - 150px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <CircularProgress />
                        </div>
                    </div>
                ) : (
                    <>
                        <Grid item sm={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography color="secondary" className="mainLabel">
                                        Sold Hours (Branch)
                                    </Typography>
                                </Grid>
                                {state.soldbyBranch.map((k, i) => (
                                    <Grid item className="Cursor">
                                        <Card
                                            className="HoursCard"
                                            // style={{ color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red' }}
                                        >
                                            <div
                                                style={{
                                                    paddingLeft: 10,
                                                    padding: '10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="servicelableFont"> {k.name}</span>
                                                <div className="valueFont">{formatters.ThousandSeparatorWithoutZero(k.total)}</div>
                                            </div>
                                        </Card>
                                    </Grid>
                                ))}
                                <Grid item className="Cursor">
                                    <Card
                                        className="HoursCard"
                                        // style={{ color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red' }}
                                    >
                                        <div
                                            style={{
                                                paddingLeft: 10,
                                                padding: '10px',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <span className="servicelableFont"> Total</span>
                                            <div className="valueFont">{formatters.ThousandSeparatorWithoutZero(state?.totalsoldbyBranch)}</div>
                                        </div>
                                    </Card>
                                </Grid>

                                <Grid item xs={12}>
                                    <Card
                                        className="graphCard"
                                        // style={{ color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red' }}
                                    >
                                        <Typography color="secondary" className="mainLabel" style={{ marginBottom: 5 }}>
                                            Sold Hours (Month)
                                        </Typography>
                                        <ServiceLineChart data={state.soldbyMonth} onClick={GoToDailyInvoicedHours} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12}>
                            <Card
                                className="graphCard"
                                // style={{ color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red' }}
                            >
                                <Grid container spacing={2} justifyContent="space-start" style={{ padding: 10 }}>
                                    <Grid item xs={12}>
                                        <Typography color="secondary" className="mainLabel">
                                            Idle Time ( Total : {formatters.ToFixedSeparator(state.totalTotalIdle)} )
                                            {/* Idle Time ( Total : {Math.round(state.totalTotalIdle)} ) */}
                                        </Typography>
                                    </Grid>
                                    {state.idleHours.map((k, i) => (
                                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1} className="Cursor">
                                            <div
                                                style={{
                                                    paddingLeft: 15,
                                                    padding: '0px 5px',
                                                    textAlign: 'left',
                                                    borderLeft: '2px solid rgba(0,0,0,0.09)'
                                                }}
                                            >
                                                <span className="IdlelableFont"> {k.name}</span>
                                                <div className="valueFont">{formatters.ToFixedSeparator(k.total)}</div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Card>
                        </Grid>

                        <Grid item sm={12}>
                            <Card
                                className="graphCard"
                                // style={{ color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red' }}
                            >
                                <Grid container spacing={2} justifyContent="space-start" style={{ padding: 10 }}>
                                    <Grid item xs={12}>
                                        <Typography color="secondary" className="mainLabel">
                                            Parts
                                            {/* ( Total : {Math.round(state.totalTotalIdle)} ) */}
                                        </Typography>
                                    </Grid>

                                    {state.filterPartList?.length > 0 ? (
                                        state.filterPartList?.map((k, i) => (
                                            <Grid item xs={6} sm={4} md={3} lg={2} className="Cursor">
                                                <div
                                                    style={{
                                                        paddingLeft: 15,
                                                        padding: '0px 5px',
                                                        textAlign: 'left',
                                                        borderLeft: '2px solid rgba(0,0,0,0.09)'
                                                    }}
                                                >
                                                    <span className="servicelableFont"> {k.bn}</span>
                                                    <div className="partValueFont">{formatters.CurrencyThousandSeparatorWithoutZero(k.saleValue)}</div>
                                                    <span className="servicelableFont"> Value</span>
                                                    <div className="partValueFont">{formatters.CurrencyThousandSeparatorWithoutZero(k.grossProfit)}</div>
                                                    <span className="servicelableFont"> Profit</span>
                                                </div>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Grid item xs={12} className="Cursor">
                                            <div
                                                style={{
                                                    paddingLeft: 15,
                                                    padding: '10px 5px',
                                                    textAlign: 'center'
                                                    // borderLeft: '2px solid rgba(0,0,0,0.09)'
                                                }}
                                            >
                                                <div className="labelFont">No Record Found</div>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item lg={12} xl={state.techEff.length >= 6 ? 12 : 6}>
                            <Card
                                className="graphCard"
                                // style={{ color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red' }}
                            >
                                <Grid container spacing={2} justifyContent="space-start" style={{ padding: 10 }}>
                                    <Grid item xs={12}>
                                        <Typography color="secondary" className="mainLabel">
                                            Technician Efficiency
                                            {/* ( Total : {Math.round(state.totalTotalIdle)} ) */}
                                        </Typography>
                                    </Grid>
                                    {state.techEff.length > 0 ? (
                                        state.techEff.map((k, i) => (
                                            <Grid item xs={6} sm={4} md={3} lg={2} xl={state.techEff.length >= 6 ? 1 : 2} className="Cursor">
                                                <div
                                                    style={{
                                                        paddingLeft: 15,
                                                        padding: '0px 5px',
                                                        textAlign: 'left',
                                                        borderLeft: '2px solid rgba(0,0,0,0.09)'
                                                    }}
                                                >
                                                    <span className="servicelableFont"> {k.name}</span>
                                                    <div className="valueFont">{k.total}%</div>
                                                </div>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Grid item xs={12} className="Cursor">
                                            <div
                                                style={{
                                                    paddingLeft: 15,
                                                    padding: '10px 5px',
                                                    textAlign: 'center'
                                                    // borderLeft: '2px solid rgba(0,0,0,0.09)'
                                                }}
                                            >
                                                <div className="labelFont">No Record Found</div>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item lg={12} xl={state.WipHour.length >= 6 ? 12 : 6}>
                            <Card
                                className="graphCard"
                                // style={{ color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red' }}
                            >
                                <Grid container spacing={2} justifyContent="space-start" style={{ padding: 10 }}>
                                    <Grid item xs={12}>
                                        <Typography color="secondary" className="mainLabel">
                                            Work In Progress Clocked Hours ( Total : {formatters.ToFixedSeparator(state.totalWipHours)} )
                                            {/* ( Total : {Math.round(state.totalTotalIdle)} ) */}
                                        </Typography>
                                    </Grid>
                                    {state.WipHour.length > 0 ? (
                                        state.WipHour.map((k, i) => (
                                            <Grid item xs={6} sm={4} md={3} lg={2} xl={state.WipHour.length >= 6 ? 1 : 2} className="Cursor">
                                                <div
                                                    style={{
                                                        paddingLeft: 15,
                                                        padding: '0px 5px',
                                                        textAlign: 'left',
                                                        borderLeft: '2px solid rgba(0,0,0,0.09)'
                                                    }}
                                                >
                                                    <span className="servicelableFont"> {k.name}</span>
                                                    <div className="valueFont">{formatters.ToFixedSeparator(k.total)}</div>
                                                </div>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Grid item xs={12} className="Cursor">
                                            <div
                                                style={{
                                                    paddingLeft: 15,
                                                    padding: '10px 5px',
                                                    textAlign: 'center'
                                                    // borderLeft: '2px solid rgba(0,0,0,0.09)'
                                                }}
                                            >
                                                <div className="labelFont">No Record Found</div>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item lg={12} xl={state?.servicecWarrenty.length >= 6 ? 12 : 6}>
                            <Card
                                className="graphCard"
                                // style={{ color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red' }}
                            >
                                <Grid container spacing={2} justifyContent="space-start" style={{ padding: 10 }}>
                                    <Grid item xs={12}>
                                        <Typography color="secondary" className="mainLabel">
                                            Service Warranty Write off
                                            {/* ( Total : {Math.round(state.totalTotalIdle)} ) */}
                                        </Typography>
                                    </Grid>
                                    {state?.servicecWarrenty.length > 0 ? (
                                        state?.servicecWarrenty?.map((k, i) => (
                                            <Grid item xs={6} sm={4} md={3} lg={2} xl={state?.servicecWarrenty.length >= 6 ? 1 : 2} className="Cursor">
                                                <div
                                                    style={{
                                                        paddingLeft: 15,
                                                        padding: '0px 5px',
                                                        textAlign: 'left',
                                                        borderLeft: '2px solid rgba(0,0,0,0.09)'
                                                    }}
                                                >
                                                    <span className="servicelableFont"> {k.name}</span>
                                                    <div className="valueFont">{formatters.ThousandSeparatorWithoutZero(k.total)}</div>
                                                </div>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Grid item xs={12} className="Cursor">
                                            <div
                                                style={{
                                                    paddingLeft: 15,
                                                    padding: '10px 5px',
                                                    textAlign: 'center'
                                                    // borderLeft: '2px solid rgba(0,0,0,0.09)'
                                                }}
                                            >
                                                <div className="labelFont">No Record Found</div>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item lg={12} xl={state?.partWarrenty?.length >= 6 ? 12 : 6}>
                            <Card
                                className="graphCard"
                                // style={{ color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red' }}
                            >
                                <Grid container spacing={2} justifyContent="space-start" style={{ padding: 10 }}>
                                    <Grid item xs={12}>
                                        <Typography color="secondary" className="mainLabel">
                                            Part Warranty Write off
                                            {/* ( Total : {Math.round(state.totalTotalIdle)} ) */}
                                        </Typography>
                                    </Grid>
                                    {state?.partWarrenty?.length > 0 ? (
                                        state?.partWarrenty?.map((k, i) => (
                                            <Grid item xs={6} sm={4} md={3} lg={2} xl={state?.partWarrenty?.length >= 6 ? 1 : 2} className="Cursor">
                                                <div
                                                    style={{
                                                        paddingLeft: 15,
                                                        padding: '0px 5px',
                                                        textAlign: 'left',
                                                        borderLeft: '2px solid rgba(0,0,0,0.09)'
                                                    }}
                                                >
                                                    <span className="servicelableFont"> {k.name}</span>
                                                    <div className="valueFont">{formatters.ThousandSeparatorWithoutZero(k.total)}</div>
                                                </div>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Grid item xs={12} className="Cursor">
                                            <div
                                                style={{
                                                    paddingLeft: 15,
                                                    padding: '10px 5px',
                                                    textAlign: 'center'
                                                    // borderLeft: '2px solid rgba(0,0,0,0.09)'
                                                }}
                                            >
                                                <div className="labelFont">No Record Found</div>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Card>
                        </Grid>

                        {/* <Grid item sm={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card
                                        className="graphCard"
                                        // style={{ color: dashobj.dayOrdersBudgetProfit <= dashobj.dayOrdersProfit ? 'green' : 'red' }}
                                    >
                                        <Typography color="secondary" className="mainLabel">
                                            Work In Progress Clocked Hours
                                        </Typography>
                                        <ServieBarChart data={state.WipHour} onClick={GoToWorkInProgress} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        {/* <Grid item sm={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div style={{ padding: 10, border: '2px solid lightGrey' }}>
                                        <Typography color="secondary" variant="h6">
                                            Service Warranty Write off
                                        </Typography>
                                        <ServieBarChart data={state.servicecWarrenty} onClick={GoToServiceNominals} />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ padding: 10, border: '2px solid lightGrey' }}>
                                        <Typography color="secondary" variant="h6">
                                            Part Warranty Write off
                                        </Typography>

                                        <ServieBarChart data={state.partWarrenty} onClick={GoToPartsWarrantyOff} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </>
                )}
                {/* <Grid item sm={12} md={12} lg={12} xl={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                                <div style={{ padding: 10, border: '2px solid lightGrey' }}>
                                    <Typography color="secondary" variant="h6">
                                        Sold Hours By Month Total
                                    </Typography>
                                    <ServiceLineChart data={state.soldbyMonth} onClick={GoToDailyInvoicedHours} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                                <div style={{ padding: 10, border: '2px solid lightGrey' }}>
                                    <Typography color="secondary" variant="h6">
                                        Sold Hours By Branch
                                    </Typography>
                                    <ServiceLineChart data={state.soldbyBranch} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid> */}
            </Grid>
        </div>
    );
};

export default ServiceDashboard;
