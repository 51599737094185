import * as Components from "./../../../../Components";
import  PasswordReset from "../../../Modal/PasswordReset"
import { TypeOfAuth } from './../menu-auth-type';

export default [{
    label: "Home",
    path: "/",
    exact: true,
    component: Components.home,
    authType: TypeOfAuth.NonAuth,
}, {
    label: "Login",
    path: "/login",
    hidden: true,
    component: Components.login,
    authType: TypeOfAuth.NonAuth,
}
, {
    label: "Reset Password",
    path: "/ResetPassword/:id",
    hidden: true,
    component: PasswordReset,
    authType: TypeOfAuth.NonAuth,
}
];