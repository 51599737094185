import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Grid, FormLabel, IconButton } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { TextBox } from '../../../../../Core/FormInput';
import { ExpBaseURL } from '../../../../../Core/Service/http-calls';
import { AppStorage } from '../../../../../Core/Service/storage-service';
import formatters from '../../../../../Core/Grid/inputFormatter';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { GetExcelFileFromEndpoint } from '../../../../../Core/Service/reportService';
import AppContext from '../../../../../App/AppContext';
import Tooltip from '@material-ui/core/Tooltip';
import { useContext } from 'react';

const nominalColumns = [
    { title: 'Technician', field: 'rn', width: 100, sortable: true },
    { title: 'Total Idle Hours', field: 'totalIdleHours', width: 100, sortable: true, align: 'right', renderer: formatters.ToFixedSeparator },
    { title: 'Productive Hours', field: 'productiveHours', width: 90, sortable: true, align: 'right', renderer: formatters.ToFixedSeparator },
    { title: 'Non Productive Hours', field: 'nonProductiveHours', width: 100, align: 'right', sortable: true, renderer: formatters.ToFixedSeparator }
];

const techCol = [
    { title: 'Idle Reason', align: 'right', field: 'idle', width: 100 },
    { title: 'Hour Taken', field: 'ht', width: 100, align: 'right', renderer: formatters.ToFixedSeparator },
    { title: 'Date In', field: 'di', width: 90, align: 'right' },
    { title: 'Date Out', field: 'do', width: 90, align: 'right' },
    { title: 'Adj Mode', field: 'adj', width: 100, align: 'right' },
    { title: 'Shift', field: 'sn', width: 100, align: 'right' }
];

const baseColumns = [
    { field: 'bn', title: 'Branch' },
    { field: 'totalIdleHours', title: 'Total Idle Hours', align: 'right', renderer: formatters.ToFixedSeparator },
    { field: 'productiveHours', title: 'Productive Hours', align: 'right', renderer: formatters.ToFixedSeparator },
    { field: 'nonProductiveHours', title: 'Non Productive Hours', align: 'right', renderer: formatters.ToFixedSeparator }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Services', active: true },
    { name: 'Idle Time Analysis', active: true }
];

var $ = window.$;
var mainGrid, opGrid, techGrid;

const IdleTimeAnalysis = (props) => {
    const { hideModal, showToast } = useContext(AppContext);

    let startDate = moment().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate
    });
    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    let token = AppStorage.getToken();
    const onSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        mainGrid.render(obj);
    };

    const onSuccessOperatorFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        opGrid.render(obj);
    };

    const onSuccessTechFun = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        techGrid.render(obj);
    };
    let getUrl = `${ExpBaseURL}/api/services/IdleTimeAnalysis`;

    const setDataSource = (gridInstance, getUrl) => {
        const onSuccessFunc = function (response) {
            gridInstance.render({
                records: response.data.list,
                total: response?.data?.total
            });
        };

        gridInstance.data().dataSource = {
            url: `${getUrl}`,
            data: { StartDate: state.startDate, EndDate: state.endDate },
            headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
            success: onSuccessFunc
        };
    };

    const PullSalesNominalData = async () => {
        window.$(`#IdleTimeAnalysis`).grid('destroy', true, true);
        mainGrid = $('#IdleTimeAnalysis').grid({
            primaryKey: 'branchID',
            dataSource: [],
            // dataSource: {
            //     url: getUrl,
            //     data: { StartDate: state.startDate, EndDate: state.endDate },
            //     headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
            //     success: onSuccessFunc
            // },
            detailTemplate: '<div><table/></div>',
            headerFilter: false,
            columns: baseColumns,
            pager: { limit: 10 }
        });

        setTimeout(() => {
            setDataSource(mainGrid, getUrl);
            mainGrid.reload();
        });

        mainGrid.on('dataBound', function (e, records, totalRecords) {
            var sumIdleHours = 0,
                sumProductiveHours = 0,
                sumNonProductiveHours = 0,
                $tfoot;
            $.each(records, function () {
                sumIdleHours += parseFloat(this.totalIdleHours);
                sumProductiveHours += parseFloat(this.productiveHours);
                sumNonProductiveHours += parseFloat(this.nonProductiveHours);
            });
            $tfoot = mainGrid.children('tfoot');
            $tfoot.children('tr[data-role="TotalAmount"]').remove();
            $tfoot.children('tr[data-role="pager"]').remove();
            $tfoot.prepend(
                '<tr data-role="TotalAmount"><th></th><th>Total</th><th style="text-align:right; " >' +
                    formatters.ToFixedSeparator(sumIdleHours) +
                    '</th><th style="text-align:right; " >' +
                    formatters.ToFixedSeparator(sumProductiveHours) +
                    '</th><th style="text-align:right; " >' +
                    formatters.ToFixedSeparator(sumNonProductiveHours) +
                    '</th></tr>'
            );
        });
        mainGrid.on('detailExpand', function (e, $detailWrapper, branchID) {
            opGrid = $detailWrapper.find('table').grid({
                params: { branchID: branchID },
                headerFilter: false,
                primaryKey: 'rc',
                dataSource: {
                    url: `${ExpBaseURL}/api/services/IdleTimeAnalysis`,
                    headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                    data: { StartDate: state.startDate, EndDate: state.endDate },
                    success: onSuccessOperatorFunc
                },
                columns: nominalColumns,
                detailTemplate: '<div><table/></div>'
            });

            opGrid.on('detailExpand', function (e, $detailWrapper, rc) {
                console.log(rc, 'ressss');
                techGrid = $detailWrapper.find('table').grid({
                    params: { branchID: branchID, res: rc },
                    headerFilter: false,
                    primaryKey: 'rn',
                    dataSource: {
                        url: `${ExpBaseURL}/api/services/IdleTimeAnalysis`,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host
                        },
                        data: { StartDate: state.startDate, EndDate: state.endDate },
                        success: onSuccessTechFun
                    },
                    columns: techCol
                });
            });
            opGrid.on('detailCollapse', function (e, $detailWrapper, id) {
                $detailWrapper.find('table').grid('destroy', true, true);
            });
        });

        mainGrid.on('detailCollapse', function (e, $detailWrapper, id) {
            $detailWrapper.find('table').grid('destroy', true, true);
        });
        mainGrid.reload();
    };
    useEffect(() => {
        PullSalesNominalData();
    }, [state.startDate, state.endDate, props.type]);
    const downloadCSVWrapper = async () => {
        showToast('Collating Data... This may take a while.');
        const data = mainGrid.data().params;
        let downloadURL = `services/IdleTimeAnalysis${getUrl.includes('?') ? '&' : '?'}fileType=excel&StartDate=${state.startDate}&EndDate=${state.endDate}`;
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                downloadURL += `&${key}=${value}`;
            }
        }
        await GetExcelFileFromEndpoint(downloadURL, 'IdelTimeAnalysis');
    };
    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <Grid item container spacing={1} xs={12} style={{ padding: '0px 10px' }}>
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12} md={3} lg={2} style={{ textAlign: 'left' }}>
                        <FormLabel component="legend">Start Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={2} style={{ textAlign: 'left' }}>
                        <FormLabel component="legend">End Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="endDate"
                            fullWidth
                            value={state.endDate}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                </Grid>
                <Grid container className="report-screen-gijgo " item xs={12} sm={12} style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }}>
                    <Grid item style={{ bottom: '14px', left: '21px', zIndex: 99, position: 'absolute' }}>
                        <Tooltip title="Download Excel" arrow>
                            <IconButton aria-label="delete" style={{ padding: '0px' }}>
                                <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} style={{ color: 'red' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <table id={`IdleTimeAnalysis`}></table>
                </Grid>
            </Grid>
        </div>
    );
};
export default IdleTimeAnalysis;
