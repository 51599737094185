import React, { useEffect, useState, useContext } from 'react';
import { getDiagnosticFaultByID, postDiagnosticFaults } from '../../../../Core/Service/diagnosticFaults-service';
import { Grid, InputLabel, Typography, FormLabel } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import AppContext from '../../../../App/AppContext';
import {
    getDiagnosticEngineFaultsByID,
    getDiagnosticLocationsByID,
    getDiagnosticNoiseApparentsByID,
    postDiagnosticEngineFaults,
    postDiagnosticLocations,
    postDiagnosticNoiseApparents
} from '../../../../Core/Service/diagnosticFaults-service';

const DiagnosticCommonModal = (props) => {
    const [state, setState] = useState({
        name: '',
        id: props.id,
        errors: {}
    });

    const { showToast, showModal } = useContext(AppContext);

    useEffect(async () => {
        let res = {};
        if (props.id) {
            if (props.screenName == 'Diagnostic Engine Faults') {
                res = await getDiagnosticEngineFaultsByID(props.id);
            } else if (props.screenName == 'Diagnostic Noise Apparents') {
                res = await getDiagnosticNoiseApparentsByID(props.id);
            } else if (props.screenName == 'Diagnostic Locations') {
                res = await getDiagnosticLocationsByID(props.id);
            } else {
                res = {};
            }
            if (res.success) {
                let data = res.data?.list?.table[0];
                setState((st) => ({
                    ...st,
                    name: data.name,
                    id: data.id
                }));
            }
        }
    }, []);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const validation = () => {
        let errors = {};
        let isValid = true;

        let { name } = state;

        if (!name) {
            isValid = false;
            errors.name = 'Name is required';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));

        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            let res = {};
            let data = {
                id: state.id,
                name: state.name
            };
            if (props.screenName == 'Diagnostic Engine Faults') {
                res = await postDiagnosticEngineFaults(data);
            } else if (props.screenName == 'Diagnostic Noise Apparents') {
                res = await postDiagnosticNoiseApparents(data);
            } else if (props.screenName == 'Diagnostic Locations') {
                res = await postDiagnosticLocations(data);
            } else {
                res = {};
            }
            if (res.success) {
                showToast(`${props.screenSortName} ${state.id ? 'Updated' : 'Added'} Successfully`);
                props.onClose(true);
            } else {
                console.error(res.message);
            }
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <FormLabel component="legend" className="mandatoryfields">
                        Name
                    </FormLabel>
                    <TextBox name="name" onChange={handleInput} value={state.name} autoFocus={true} />
                    <span className="mandatoryfields">{state.errors?.name}</span>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default DiagnosticCommonModal;
