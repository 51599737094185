import moment from 'moment';
import { useEffect } from 'react';
import { getActionLogHistory } from '../../../Core/Service/communicator-service';
import { useState } from 'react';
import DialogComp from '../../../Core/Modal/dialogModal';
import { Avatar, Chip, DialogTitle } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EditIcon from '@material-ui/icons/Edit';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

const RequestBookingsHistoryModal = (props) => {
    const [state, setState] = useState({
        messaegList: [],
        actionLog: false,
        editActionLog: false
    });

    const [WindowWidths, WindowHeights] = useWindowSize();

    useEffect(async () => {}, []);

    return (
        <>
            <DialogComp
                title={
                    <DialogTitle style={{ padding: 0 }}>
                        <span>Booking History</span>
                    </DialogTitle>
                }
                onClose={() => props.onClose(false)}
                fullScreen={WindowWidths < 1260 ? true : false}
                maxWidth="md"
                fullWidth
            >
                <div style={{ paddingBottom: 20 }}>
                    <div>
                        Reg. No:&nbsp; <b> {props.registration}</b> &nbsp;&nbsp; Make/Model:&nbsp;{' '}
                        <b>
                            {props.make}/{props.model}
                        </b>
                    </div>
                    <br />
                    {props.messaegList.length > 0 ? (
                        <div style={{ border: '1px solid #E8E8E8', maxHeight: 500, overflow: 'auto' }} className="custom-scroll">
                            {props.messaegList.map((m) => (
                                <div style={{ borderBottom: '1px solid #E8E8E8', padding: 10 }}>
                                    <div style={{ fontWeight: 500 }}>{m.notes} </div>

                                    <div style={{ paddingBottom: 4 }}>
                                        {/* <span style={{ fontSize: 11, color: 'green' }}>{m.notes}</span>&nbsp; */}
                                        <span style={{ fontSize: 11 }}>
                                            {moment(m.logActionTime).format('LLL')} ( Created by : {m.createdBy} @ {moment(m.logDateCreated).format('LLL')} )
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div style={{ border: '1px solid #E8E8E8' }}>
                            <div style={{ textAlign: 'center', padding: 10 }}>No History Available</div>
                        </div>
                    )}
                </div>
            </DialogComp>
        </>
    );
};

export default RequestBookingsHistoryModal;
