import { Grid } from '@material-ui/core';
import { Edit, List } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { SecondaryButton } from '../../../../Core/FormInput';
import { SquareButton } from '../../../../Core/FormInput/AppButton';
import DialogComp from '../../../../Core/Modal/dialogModal';
import ReportGijgoGrid from '../../../internal/ReportsScreens/GijgoForReportsScreen';
import EditResultList from './editResultList';
import ResultListOptions from './resultListOptions';

const QCResultsList = () => {
    const [state, setState] = useState({
        showAddEdit: false,
        showOptions: false,

        focusedRow: null
    });

    const baseColumns = [
        {
            field: 'action',
            sortable: false,
            title: `Action`,
            width: '10%',
            filterable: false
        },
        {
            field: 'qcResultListID',
            title: `ID`,
            hidden: true
        },
        {
            field: 'qcResultListDescription',
            title: `Description`,
            width: '90%'
        },
    ];

    const crumbs = () => [
        { name: 'Home', path: '/' },
        { name: 'QC', active: true },
        { name: `Results Lists`, active: true }
    ];

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent='space-evenly'>
               <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => {
                            handleAddEdit(true)
                            setState((st) => ({
                                ...st,
                                focusedRow: record
                            }))
                        }}
                    >
                        <Edit fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => {
                            handleOptions()
                            setState((st) => ({
                                ...st,
                                focusedRow: record
                            }))
                        }}
                    >
                        <List fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');

        if (actionCol) {
            actionCol.renderer = editButton({});
        }

        return tempCols;
    }, []);

    const handleAddEdit = (val) => {
        if (val) {
            setState((st) => ({
                ...st,
                showAddEdit: val,
                isReload: false
            }))
        } else {
            setState((st) => ({
                ...st,
                showAddEdit: val,
                focusedRow: null,
                isReload: true
            }))
        }
    }

    const handleOptions = () => {
        setState((st) => ({
            ...st,
            showOptions: true
        }))
    }

    const baseUrl = 'QC/Lists';

    return (
        <div>
            {state.showOptions ?
                <>
                    <ResultListOptions QCResultListID={state.focusedRow?.qcResultListID} />
                </>
                :
                <>
                    <Grid item container justify="space-between">
                        <Grid item>
                            <BreadCrumbs crumbs={crumbs()} />
                        </Grid>
                        <Grid item style={{ marginTop: '15px' }}>
                            <SecondaryButton
                                variant="contained"
                                color="secondary"
                                onClick={() => handleAddEdit(true)}
                                className="btnadd"
                            >
                                Add Results List
                            </SecondaryButton>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={1} xs={12} style={{ marginTop: '-25px' }}>
                        <Grid item xs={12}>
                            <ReportGijgoGrid
                                dataConverter={(res) => ({
                                    records: res?.data,
                                    total: res?.data?.total
                                })}
                                columns={columns}
                                getUrl={baseUrl}
                                isReload={state.isReload}
                            />
                        </Grid>
                    </Grid>

                    {state.showAddEdit ? (
                        <DialogComp title={state.focusedRow ? "Edit QC Results List" : "Add QC Results List"} maxWidth="md" onClose={() => handleAddEdit(false)} fullWidth>
                            <EditResultList focusedRow={state?.focusedRow} onClose={() => handleAddEdit(false)} />
                        </DialogComp>
                    ) : null}
                </>
            }
        </div>
    );
}
export default QCResultsList;
