import { Checkbox, FormControlLabel, FormLabel, Grid, TextField } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import TabComponent from '../../../Core/Controls/Tabs';
import { SecondaryButton, TextBox } from '../../../Core/FormInput';
import DataGridComp from '../../../Core/Grid';
import formatters from '../../../Core/Grid/inputFormatter';
import '../commonStyle.scss';
import {
    AddExpenseCategory,
    AddOtherIncomeCategory,
    getBudgetDropDown,
    getBudgetExpenses,
    getBudgetOtherIncomes,
    getSalesTargets,
    postBudgetExpenceData,
    postSetOtherIncomes
} from '../../../Core/Service/branch-screen-service';
import SalesTargetScreen from './SalesTargetScreen';
import AppContext from '../../../App/AppContext';

function CustomNoRowsOverlay() {
    return (
        <GridOverlay>
            <div>No parts available</div>
        </GridOverlay>
    );
}

const MonthShortNameList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const ExpenseOtherIncomeComp = (props) => {
    const [state, setState] = useState({ rows: [], isActive: false, category: '', columnTotal: {} });
    const { showToast } = useContext(AppContext);

    const pullExpenseBudgetGridData = async () => {
        let res = await getBudgetExpenses(props.yearCode?.id, props.data.departmentID, props.branchCode);
        let temp = res.data.list.table.map((o) => {
            return { ...o, id: o.expenseCategoryID };
        });
        setState((st) => {
            return { ...st, rows: temp || [] };
        });
    };

    const pullOtherIncomesBudgetGridData = async () => {
        let res = await getBudgetOtherIncomes(props.yearCode?.id, props.data.departmentID, props.branchCode);
        let temp = res.data.list.table.map((o) => {
            return { ...o, id: o.otherIncomeCategoryID };
        });
        setState((st) => {
            return { ...st, rows: temp || [] };
        });
    };

    useEffect(() => {
        if (props.fromPage == 'expense') {
            pullExpenseBudgetGridData();
        } else if (props.fromPage == 'otherIncome') {
            pullOtherIncomesBudgetGridData();
        }
    }, [props.yearCode?.id]);

    useEffect(() => {
        if (state.rows.length > 0) {
            let tempRows = [...state.rows];
            let frmpPage = props.fromPage == 'expense' ? 'expense' : props.fromPage == 'otherIncome' ? 'otherIncome' : '';
            let tempObj = {};
            let grandTotal = 0;
            MonthShortNameList.forEach((r) => {
                let total = 0;
                tempRows.forEach((q) => {
                    total += +q[`${frmpPage}${r}`];
                });
                tempObj[`${frmpPage}${r}`] = total;
                grandTotal += tempObj[`${frmpPage}${r}`];
            });
            tempObj.grandTotal = grandTotal;
            setState((st) => {
                return { ...st, columnTotal: tempObj };
            });
        }
    }, [state.rows]);

    const column = [
        {
            field: `${props.fromPage == 'expense' ? 'expense' : props.fromPage == 'otherIncome' ? 'otherIncome' : ''}CategoryName`,
            headerName: 'Category',
            width: 300
        },
        ...MonthShortNameList.map((p) => {
            return {
                field: `${props.fromPage == 'expense' ? 'expense' : props.fromPage == 'otherIncome' ? 'otherIncome' : ''}${p}`,
                headerName: `${p}`,
                align: 'right',
                renderCell: (params) => {
                    if (params.row.id != 'Columntotal') {
                        return (
                            <input
                                type="number"
                                name="desc"
                                id={params.row.id}
                                data-rowid={params.row.id}
                                // onChange={onIssueQtyChange}
                                style={{ textAlign: 'right' }}
                                onChange={changeQantity(
                                    params.row[`${props.fromPage == 'expense' ? 'expense' : props.fromPage == 'otherIncome' ? 'otherIncome' : ''}CategoryID`],
                                    '',
                                    `${props.fromPage == 'expense' ? 'expense' : props.fromPage == 'otherIncome' ? 'otherIncome' : ''}${p}`
                                )}
                                value={params.value}
                                className="pa-4"
                                disabled={params.row.disabled}
                            />
                        );
                    }
                },
                flex: 1
            };
        })
    ];

    const new_columns = useMemo(() => {
        let tempCols = [...column];
        tempCols.push({
            field: ``,
            headerName: 'Total',
            flex: 1,
            align: 'right',
            renderCell: (params) => {
                let frmpPage = props.fromPage == 'expense' ? 'expense' : props.fromPage == 'otherIncome' ? 'otherIncome' : '';
                let total = 0;
                MonthShortNameList.forEach((o) => {
                    total += +params.row[`${frmpPage}${o}`];
                });
                return <>{total}</>;
            }
        });

        return tempCols;
    }, [state.rows]);

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const changeQantity = (id, des, name) => (e) => {
        let changedArray = [...state.rows];
        let objIndex = state.rows.findIndex((x) => x.id === id);
        changedArray[objIndex][name] = e.target.value;
        setState((st) => ({
            ...st,
            rows: changedArray
        }));
    };

    const handleSubmit = async () => {
        let res = {};
        let fromPage = props.fromPage == 'expense' ? 'expense' : props.fromPage == 'otherIncome' ? 'otherIncome' : '';
        let data = {
            branchID: props.branchCode,
            year: props.yearCode?.id,
            expenseVM: state.rows.map((q) => {
                return {
                    [`${fromPage}CategoryID`]: q[`${fromPage}CategoryID`],
                    [`${fromPage}Jan`]: q[`${fromPage}Jan`],
                    [`${fromPage}Feb`]: q[`${fromPage}Feb`],
                    [`${fromPage}Mar`]: q[`${fromPage}Mar`],
                    [`${fromPage}Apr`]: q[`${fromPage}Apr`],
                    [`${fromPage}May`]: q[`${fromPage}May`],
                    [`${fromPage}Jun`]: q[`${fromPage}Jun`],
                    [`${fromPage}Jul`]: q[`${fromPage}Jul`],
                    [`${fromPage}Aug`]: q[`${fromPage}Aug`],
                    [`${fromPage}Sep`]: q[`${fromPage}Sep`],
                    [`${fromPage}Oct`]: q[`${fromPage}Oct`],
                    [`${fromPage}Nov`]: q[`${fromPage}Nov`],
                    [`${fromPage}Dec`]: q[`${fromPage}Dec`]
                };
            })
        };

        if (props.fromPage == 'expense') {
            res = await postBudgetExpenceData(data);
            if (res.success) {
                showToast('Expense Target Save Successfully');
                pullExpenseBudgetGridData();
            }
        } else if (props.fromPage == 'otherIncome') {
            res = await postSetOtherIncomes(data);
            if (res.success) {
                showToast('Other Income Target Save Successfully');
                pullOtherIncomesBudgetGridData();
            }
        }
    };

    const handleCategorySubmit = async () => {
        let res = {};
        let data = {
            branchID: props.branchCode,
            departmentID: props.data.departmentID,
            categoryName: state.category,
            categoryIncludeInProfit: state.isActive
        };

        if (props.fromPage == 'expense') {
            res = await AddExpenseCategory(data);
            if (res.success) {
                showToast('Expense Category Added Successfully');
                pullExpenseBudgetGridData();
            }
        } else if (props.fromPage == 'otherIncome') {
            res = await AddOtherIncomeCategory(data);
            if (res.success) {
                showToast('Other Income Category Added Successfully');
                pullOtherIncomesBudgetGridData();
            }
        }
    };

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = checked;
            return nst;
        });
    };

    const TableFooter = () => {
        let frmpPage = props.fromPage == 'expense' ? 'expense' : props.fromPage == 'otherIncome' ? 'otherIncome' : '';
        return (
            <Grid container style={{ paddingRight: '15px' }}>
                <Grid item style={{ width: 300, fontWeight: 'bold', padding: '4px 10px' }}>
                    Total
                </Grid>
                {state.columnTotal &&
                    MonthShortNameList.map((u) => {
                        return (
                            <Grid item style={{ flex: 1, textAlign: 'right', fontWeight: 'bold' }}>
                                <div style={{ padding: '4px 14px' }}>{state.columnTotal[`${frmpPage}${u}`]}</div>
                            </Grid>
                        );
                    })}
                <Grid item style={{ flex: 1, textAlign: 'right', fontWeight: 'bold' }}>
                    <div style={{ padding: '4px 14px' }}>{state.columnTotal?.grandTotal}</div>
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container item style={{ paddingTop: '10px' }}>
            <Grid container item spacing={2} justify="space-between">
                <Grid xs={12} sm={7} md={8} item>
                    {(props.fromPage == 'expense' || props.fromPage == 'otherIncome') && (
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={6} md={5} style={{ padding: '0px' }}>
                                {/* <FormLabel component="legend">Category</FormLabel> */}
                                <TextBox onChange={fieldChange} value={state.category} name="category" autoFocus={true} placeholder="Category" />
                            </Grid>
                            <Grid item xs={6} sm={3} md={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={state.isActive} onChange={handleCheckbox} name="isActive" />}
                                    label="Include in Profit/Loss?"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3} md={2}>
                                <SecondaryButton onClick={handleCategorySubmit} className="setFullWidth" disabled={!state.category}>
                                    Add
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid xs={12} sm={5} md={4} container item spacing={2} alignItems="center" justify="flex-end">
                    <Grid item xs={6} sm={5} md={4}>
                        <SecondaryButton className="setFullWidth" onClick={handleSubmit}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6} sm={5} md={4}>
                        <SecondaryButton className="setFullWidth">Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className="input-Number-remove-spinners">
                <DataGridComp
                    columns={new_columns}
                    rows={state.rows}
                    headerHeight={40}
                    isRowHovered={true}
                    enableSearch={false}
                    disableColumnMenu={true}
                    offset={360}
                    showFooter={true}
                    hideFooterPagination={true}
                    hideFooterSelectedRowCount={true}
                    FooterComp={() => TableFooter()}
                    minWidth={1250}
                />
            </Grid>
        </Grid>
    );
};

const DepartmentDetailsComp = (props) => {
    const insideTabs = [
        ...(props.data.includeSales
            ? [{ label: 'Sales', body: <SalesTargetScreen yearCode={props.yearCode} branchCode={props.branchCode} data={props.data} fromPage="sales" /> }]
            : []),
        ...(props.data.includeExpenses
            ? [
                  {
                      label: 'Expense',
                      body: <ExpenseOtherIncomeComp yearCode={props.yearCode} branchCode={props.branchCode} data={props.data} fromPage="expense" />
                  }
              ]
            : []),
        ...(props.data.includeExpenses
            ? [
                  {
                      label: 'Other Income',
                      body: <ExpenseOtherIncomeComp yearCode={props.yearCode} branchCode={props.branchCode} data={props.data} fromPage="otherIncome" />
                  }
              ]
            : [])
    ];
    return (
        <Grid container item>
            <Grid item style={{ height: '100%', width: '100%' }}>
                <TabComponent tabList={insideTabs} />
            </Grid>
        </Grid>
    );
};

const BranchTargetScreen = (props) => {
    const [state, setState] = useState({
        filterList: [],
        branchList: [],
        branchCode: props.branchCode || '',
        branchName: props.branchName || '',
        yearCode: { description: `${new Date().getFullYear()}`, id: `${new Date().getFullYear()}` },
        currentMonthDays: [],
        customerList: [],
        customerCode: {},
        filterData: [],
        budgetYear: [],
        salesDepartments: []
    });

    useEffect(async () => {
        let res = await getBudgetDropDown();

        setState((st) => {
            let newSt = { ...st };
            newSt.budgetYear = res.data.budgetYear.map((o) => {
                return { description: `${o.budgetYear}`, id: `${o.budgetYear}` };
            });
            newSt.salesDepartments = res.data.salesDepartments;

            return newSt;
        });
    }, []);

    const handleAutoChange = (fieldName) => (e, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt[fieldName] = val;
            return newSt;
        });
    };

    const tabs = state.salesDepartments.map((q) => {
        return { label: `${q.name}`, body: <DepartmentDetailsComp data={q} yearCode={state.yearCode} branchCode={state.branchCode} /> };
    });

    return (
        <Grid container>
            <Grid container alignItems="center">
                <Grid item>
                    <FormLabel>Select Year&nbsp;&nbsp;</FormLabel>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Autocomplete
                        disableClearable
                        autoSelect
                        options={state.budgetYear}
                        name="Years"
                        value={state.yearCode}
                        onChange={handleAutoChange('yearCode')}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                        renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item style={{ height: '800px', width: '100%' }}>
                    <TabComponent tabList={tabs} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BranchTargetScreen;
