import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import StockTable from './stockTable';
import { getNewStockbyID, getUsedStockabyID } from '../../../../../Core/Service/SalesPerformance-service';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
        // backgroundColor: theme.palette.background.paper
    }
}));

const SalesStockDashboard = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(1);

    const [newStock, setNewStock] = React.useState({ showLoader: true, costEstimate: {}, stock: [] });
    const [usedStock, setUsedStock] = React.useState({ showLoader: true, costEstimate: {}, stock: [] });

    const pullstockData = async (val) => {
        if (val == 1) {
            let newStockRes = await getNewStockbyID(1);
            if (newStockRes.success) {
                setNewStock((st) => ({
                    stock: newStockRes?.data.newStock,
                    costEstimate: newStockRes?.data?.costEstimate[0],
                    showLoader: false
                }));
            }
        }
        if (val == 2) {
            let usedStockRes = await getUsedStockabyID(1);
            if (usedStockRes.success) {
                setUsedStock((st) => ({
                    stock: usedStockRes?.data.usedStock,
                    costEstimate: usedStockRes?.data?.costEstimate[0],
                    showLoader: false
                }));
            }
        }
    };
    useEffect(async () => {
        pullstockData(value);
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <TabContext value={value}>
                {/* <AppBar position="static"> */}
                <TabList onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="NEW" value={1} />
                    <Tab label="USED" value={2} />
                </TabList>
                <TabPanel value={1}>
                    <StockTable list={newStock} id={1} />
                </TabPanel>
                <TabPanel value={2}>
                    <StockTable list={usedStock} id={2} />
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default SalesStockDashboard;
