import { FormControlLabel, Grid, InputLabel, FormHelperText } from '@material-ui/core';
import React, { useState } from 'react';
import { SecondaryButton, TextBox } from '../../../Core/FormInput';
import { getTickerbyId, addTicker } from '../../../Core/Service/KPI_message-service';
import { useEffect, useContext } from 'react';
import AppContext from './../../../App/AppContext';

const AddEditTicker = (props) => {
    const [state, setState] = useState({
        id: null,
        message: ''
    });

    const { hideModal, showToast } = useContext(AppContext);
    useEffect(async () => {
        if (props.id) {
            let res = await getTickerbyId(props.id);
            setState((st) => ({
                ...st,
                message: res?.data.list.table[0].message,
                id: res?.data.list.table[0].id
            }));
        }
    }, []);

    const Validation = () => {
        let messages = '';
        let isValid = true;

        if (!state.message) {
            messages = 'Ticker Message is required';
            isValid = false;
        }

        setState((st) => ({ ...st, messages: messages }));
        return isValid;
    };

    const handlesubmit = async () => {
        if (Validation()) {
            let data = {
                message: state.message,
                id: props.id || null
            };

            let res = await addTicker(data);
            if (res.success) {
                showToast(`Ticker Message ${props.id ? 'Updated' : 'Added'} successfully`);
                props.onClose(true);
            } else {
                showToast(`Ticker Message Failed to ${props.id ? 'Update' : 'Add'}`);
            }
        }
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel shrink error>
                        Ticker Message
                    </InputLabel>
                    <TextBox multiline rows={4} name="message" value={state.message} placeholder="Ticker Message" onChange={handleInput} />
                    <FormHelperText error>{state.messages}</FormHelperText>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <SecondaryButton fullWidth onClick={handlesubmit}>
                        {props.id ? 'Update' : 'Submit'}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddEditTicker;
