import { FormLabel, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { DialogContents, DialogsActions, SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { useEffect } from 'react';
import { setCashAccountCategories } from '../../../../Core/Service/CashAccount-service';
import AppContext from '../../../../App/AppContext';
import { useContext } from 'react';


const AddEditCashAccountCategories = (props) => {

    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({
        cashAccountCategoryDescription: '',
        cashAccountCategoryId: null,
    });

    useEffect(() => {
        setState((st) => {
            return {
                ...st,
                cashAccountCategoryId: props?.focusedRow?.id ? props?.focusedRow?.id : null,
                cashAccountCategoryDescription: props?.focusedRow?.cashAccountCategoryDescription ? props?.focusedRow?.cashAccountCategoryDescription : null
            };
        });
    }, [props.focusedRow])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return {
                ...st,
                [name]: value
            };
        });
    }

    const handleSubmit = async () => {
        const data = {
            cashAccountCategoryDescription: state.cashAccountCategoryDescription,
            cashAccountCategoryId: state.cashAccountCategoryId ? state.cashAccountCategoryId : null
        }
        const res = await setCashAccountCategories(data)

        if (res.success) {
            showToast('Succesfully Updated Cash Account Category')
            props.onClose(true);
        } else {
            showToast('Failed to Update Cash Account Category')
        }
    }

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <FormLabel component="legend" className="mandatoryfields">
                        Description
                    </FormLabel>
                    <TextBox name="cashAccountCategoryDescription" onChange={handleInputChange} value={state.cashAccountCategoryDescription} autoFocus={true} />
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};
export default AddEditCashAccountCategories;
