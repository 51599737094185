import { get, post, put } from './http-calls';

export const getProcesses = async () => {
    let res = await get(`Process`, { useAuthToken: true });
    return res;
};
export const getWorkFlowList = async (workFlow) => {
    let res = await get(`Workflow/WorkflowScreenDropdown?WorkflowId=${workFlow}`, { useAuthToken: true });
    return res;
};
