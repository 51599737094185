import { Grid } from '@material-ui/core';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import AppContext from '../../../../App/AppContext';

const NumberWithPound = (params) => {
    const val = +params;
    return <>{val ? <>£{val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}</>;
};

const HeaderDetailsModal = (props) => {
    const { showToast, canOverrideMandatorySteps, userRoles, isTopNav } = useContext(AppContext);

    const isTechnician = userRoles.includes('technician');
    const isInternal = userRoles.includes('internal');
    const isCustomer = userRoles.includes('customer');
    const isAuditor = userRoles.includes('auditor');

    return (
        <Grid container spacing={2}>
            {props.headerDetailsFlags?.showHeaderRegNumber && (
                <Grid item xs={12} sm={6} md={4}>
                    <b>Reg No</b>:&nbsp;{' '}
                    <span>
                        {props.jobBasicDetail.regNo}
                        {/* <span>( {props.jobBasicDetail.vehicleType} )</span> */}
                    </span>
                </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
                <b>Customer Order No</b>:&nbsp; <span>{props.jobBasicDetail.customerOrderNo}</span>
            </Grid>

            {!isCustomer && props.headerDetailsFlags?.showHeaderCustomer && (
                <Grid item xs={12} sm={6} md={4}>
                    {props.jobBasicDetail.customerInternalNotes ? (
                        <>
                            <b>Customer</b>:&nbsp;
                            <span onClick={() => props.handleCustomerNotes(true)} style={{ color: 'red', cursor: 'pointer' }}>
                                {props.jobBasicDetail.customerName}
                            </span>
                        </>
                    ) : (
                        <>
                            <b>Customer</b>:&nbsp;<span>{props.jobBasicDetail.customerName}</span>
                        </>
                    )}
                </Grid>
            )}
            {!isCustomer && props.headerDetailsFlags?.showHeaderKeyNumber && (
                <Grid item xs={12} sm={6} md={4}>
                    <b>Key Number</b>:&nbsp; <span>{props.jobBasicDetail.keyNumber}</span>
                </Grid>
            )}
            {!isTechnician && !isCustomer && (
                <React.Fragment>
                    {props.headerDetailsFlags?.showHeaderOutstandingPayment && (
                        <Grid item xs={12} sm={6} md={4}>
                            <b>Outstanding Payment</b>:&nbsp; <span>{NumberWithPound(props.jobBasicDetail.outstandingPayment)}</span>
                        </Grid>
                    )}
                    {props.headerDetailsFlags?.showHeaderCreditLimit && (
                        <Grid item xs={12} sm={6} md={4}>
                            <b>Credit Limit</b> :&nbsp; <span>{NumberWithPound(props.jobBasicDetail.creditLimit)}</span>
                        </Grid>
                    )}
                </React.Fragment>
            )}
        </Grid>
    );
};

export default HeaderDetailsModal;
