import React from 'react';

let ByteImage = function (props) {
    let ref = props.imgRef;
    let newProp = {
        ...props,
        className: `${props.className || ''} byte-image`,
        src: props.src && `data:image/png;base64,${props.src}`
    };
    delete newProp.imgRef;

    return <img {...newProp} ref={ref} />;
};

export default ByteImage;
