import { Breadcrumbs, IconButton, Link, Tooltip, Typography } from "@material-ui/core";
import { GridOverlay } from "@material-ui/data-grid";
import EditOutlined from "@material-ui/icons/EditOutlined";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../App/AppContext";
import DataGridComp from "../../../Core/Grid";
import { SecondaryButton } from "../../../Core/FormInput";
import { Grid } from "@material-ui/core";
import { SelectBox } from "../../../Core/FormInput";
import { InputLabel } from "@material-ui/core";
import { getErrorFolders, getBranches, getDocumentTypes, getFiles, processDocument } from "../../../Core/Service/DocumentReprocess-service";
import { DoneAll } from "@material-ui/icons";
import { Clear } from "@material-ui/icons";

const DocumentsReprocess = (props) => {
    const [state, setState] = useState({
        errorFolderList: [],
        errorFolderID: null,

        branchList: [],
        branchID: null,

        documentTypeList: [],
        documentTypeID: null,

        rows: [],

        selectedRows: [],
    });

    const { showToast } = useContext(AppContext);

    const getNameFromListByID = (list, id) => {
        for (const element of list) {
            if (element.id == id) {
                return element.name;
            }
        }
    }

    useEffect(async () => {
        const res = await getErrorFolders();
        if (res.success) {
            setState((st) => ({
                ...st,
                errorFolderList: res.data,
                selectedRows: []
            }))
        }
    }, []);

    useEffect(async () => {
        if (state.errorFolderID) {
            const res = await getBranches(
                getNameFromListByID(state.errorFolderList, state.errorFolderID)
            );
            if (res.success) {
                setState((st) => ({
                    ...st,
                    branchList: res.data,
                    selectedRows: []
                }))
            }
        }
    }, [state.errorFolderID]);

    useEffect(async () => {
        if (state.branchID && state.errorFolderID) {
            const res = await getDocumentTypes(
                getNameFromListByID(state.errorFolderList, state.errorFolderID),
                getNameFromListByID(state.branchList, state.branchID)
            );
            if (res.success) {
                setState((st) => ({
                    ...st,
                    documentTypeList: res.data,
                    selectedRows: []
                }))
            }
        }
    }, [state.branchID]);

    useEffect(async () => {
        pullFilesAndUpdateState();
    }, [state.documentTypeID, state.branchID, state.errorFolderID]);

    const pullFilesAndUpdateState = async () => {
        if (state.documentTypeID && state.branchID && state.errorFolderID) {
            const res = await getFiles(
                getNameFromListByID(state.errorFolderList, state.errorFolderID),
                getNameFromListByID(state.branchList, state.branchID),
                getNameFromListByID(state.documentTypeList, state.documentTypeID)
            );
            if (res.success) {
                setState((st) => ({
                    ...st,
                    rows: res.data,
                    selectedRows: []
                }))
            }
        }
    };

    const ActionRenderer = (params) => {
        return (
            <div style={{ margin: 'auto' }}>
                <Tooltip title={state.selectedRows.includes(params.value.row.location) ? "Cancel" : "Send document for re-processing"} aria-label="add">
                    <IconButton size="small" onClick={() => setSelected(params)}>
                        <DoneAll style={{ color: state.selectedRows.includes(params.value.row.location) ? "green" : "gray" }} fontSize="medium" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const setSelected = (params) => {
        setState((st) => {
            let newSt = { ...st };

            if (newSt.selectedRows.includes(params.value.row.location)) {
                const index = newSt.selectedRows.indexOf(params.value.row.location);
                newSt.selectedRows.splice(index, 1);
            } else {
                newSt.selectedRows.push(params.value.row.location)
            }

            return newSt;
        })
    }

    const handleSubmit = async () => {
        let fileStr = "";

        state.selectedRows?.map((file, i) => {
            fileStr += (file + (state.selectedRows.length == i + 1 ? "" : ","))
        });

        const data = {
            errorFolder: getNameFromListByID(state.errorFolderList, state.errorFolderID),
            branchName: getNameFromListByID(state.branchList, state.branchID),
            documentName: getNameFromListByID(state.documentTypeList, state.documentTypeID),
            files: fileStr
        }

        const res = await processDocument(data)

        if (res.success) {
            showToast(`Successfully sent documents for re-processing`);
        } else {
            showToast(`Error occurred while sending documents for re-processing`);
        }
        pullFilesAndUpdateState();
    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }))
    }

    const RowRenderer = (params) => {
        return (
            <div style={{ color: state.selectedRows.includes(params.value.row.location) ? "green" : "null" }}>
                {params.value.value}
            </div>
        );
    };

    const handleSelectAll = () => {
        let locations = [];
        state.rows.map((document) => {
            locations.push(document.location)
        })

        setState((st) => {
            let newSt = { ...st };
            newSt.selectedRows = locations;
            return newSt;
        })
    }

    const column_new = [
        {
            field: "",
            headerName:
                <div style={{ display: 'flex' }}>
                    <Typography style={{ color: 'black', fontSize: '15px', marginTop: '5px', marginRight: '5px' }}>
                        Action
                    </Typography>
                    <Tooltip title="Select All" aria-label="add">
                        <IconButton size="small" onClick={handleSelectAll}>
                            <DoneAll style={{ color: 'red' }} fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>,
            sortable: false,
            width: 120,
            renderCell: (params) => <ActionRenderer value={params} />,
        },
        {
            field: "id",
            headerName: "id",
            hide: true,
            flex: 1,
        },
        {
            field: "name",
            headerName: "Name",
            flex: 3,
            renderCell: (params) => <RowRenderer value={params} />,
        },
        {
            field: "location",
            headerName: "Location",
            hide: true,
            flex: 1,
        },
        {
            field: "folder",
            headerName: "Folder",
            flex: 1,
            renderCell: (params) => <RowRenderer value={params} />,
        },
        {
            field: "branch",
            headerName: "Branch",
            flex: 1,
            renderCell: (params) => <RowRenderer value={params} />,
        },
        {
            field: "date",
            headerName: "Date",
            flex: 1,
            renderCell: (params) => <RowRenderer value={params} />,
        },
    ];

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Documents to Re-Process Available.</div>
            </GridOverlay>
        );
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Breadcrumbs separator=">>" aria-label="breadcrumb" style={{ marginTop: '25px', marginLeft: '5px' }}>
                        <Link color="inherit" to={"/"}>
                            Home
                        </Link>
                        <Typography color="secondary" style={{ fontSize: "0.85rem" }}>
                            Documents To Re-Process
                        </Typography>
                    </Breadcrumbs>
                </div>
                <div style={{ width: '650px', marginTop: '25px', marginRight: '25px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <InputLabel shrink>
                                Error Type
                            </InputLabel>
                            <SelectBox
                                onChange={handleFieldChange}
                                value={state.errorFolderID}
                                name="errorFolderID"
                                List={state.errorFolderList || []}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel shrink>
                                Branch
                            </InputLabel>
                            <SelectBox
                                onChange={handleFieldChange}
                                value={state.branchID}
                                name="branchID"
                                List={state.branchList || []}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel shrink>
                                Document
                            </InputLabel>
                            <SelectBox
                                onChange={handleFieldChange}
                                value={state.documentTypeID}
                                name="documentTypeID"
                                List={state.documentTypeList || []}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: '-35px' }}>
                            <SecondaryButton className="Submitbtn" onClick={handleSubmit} disabled={state.selectedRows.length == 0}>
                                Re-Process {state.selectedRows.length != 0 && state.selectedRows.length} Document{state.selectedRows.length > 1 && "s"}
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};

export default DocumentsReprocess;
