import { ButtonGroup } from '@material-ui/core';
import { Button, Collapse, FormLabel, Grid, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { Multiselect, TextBox, DatePicker } from '../../../Core/FormInput';
import { ProfitLossCard } from './budgetCard';
import { useState } from 'react';
import { ProfitTable } from './profitlossTable';
import { useEffect } from 'react';
import moment from 'moment';

import { getReportsDropdowns, getProfitLossbyID } from '../../../Core/Service/reportService';
import { FilterListIcon } from '@material-ui/icons/FilterList';

const ProfitLoss = () => {
    let startDate = moment().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        branches: [],
        tableRow: [],
        branchList: [],
        franchise: [],
        groupIds: [],
        franchiseIds: []
    });

    const [temp, tempOption] = useState();

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };
    useEffect(async () => {
        let DropDownRes = await getReportsDropdowns();
        if (DropDownRes.success) {
            setState((st) => ({
                ...st,
                branchList: DropDownRes.data.branch,
                franchise: DropDownRes.data.franchiseCode
            }));
        }
    }, []);
    useEffect(async () => {
        let res = await getProfitLossbyID(state.startDate, state.endDate, state.franchiseIds, state.groupIds);
        setState((st) => ({ ...st, branches: res?.data?.branches, tableRow: res?.data?.list }));
    }, [state.startDate, state.endDate, state.franchiseIds, state.groupIds]);

    const handleChanges = (event, value) => {
        tempOption((st) => ({ ...st, groupVal: value }));
        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handleChangesfranch = (event, value) => {
        tempOption((st) => ({ ...st, franchiseVal: value }));
        setState((st) => {
            const nst = { ...st, franchiseIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    return (
        <div className="budgetScreen">
            <Grid container spacing={2}>
                <Grid item container spacing={2} xs={12} style={{ textAlign: 'left' }}>
                    <Grid item xs={6} md={3} lg={4}>
                        <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 2 }}>
                            Group
                        </FormLabel>
                        <Multiselect options={state.branchList} onChange={handleChanges} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={4}>
                        <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 2 }}>
                            Franchise
                        </FormLabel>
                        <Multiselect options={state.franchise} onChange={handleChangesfranch} />
                    </Grid>
                    <Grid item xs={4} md={3} lg={2}>
                        <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                            Start Date
                        </FormLabel>
                        <DatePicker placeholder="Start Date" name="startDate" fullWidth value={state.startDate} onChange={handleinput} />
                    </Grid>
                    <Grid item xs={4} md={3} lg={2}>
                        <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                            End Date
                        </FormLabel>
                        <DatePicker placeholder="End Date" name="endDate" fullWidth value={state.endDate} onChange={handleinput} />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    {state?.branches?.map((m) => (
                        <Grid item xs={12} sm={6} md={3} lg={4} xl={2}>
                            <ProfitLossCard val={m.branchTotalPercent} label={m.branch} subValue_1={m.branchBudget} subValue_2={m.branchProfit} />
                        </Grid>
                    ))}
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <ProfitTable rows={state.tableRow || []} />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ProfitLoss;
