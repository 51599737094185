import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../../App/AppContext';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions, YesNoButton } from '../../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import '../../User Management/UserManagement.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { updateDepartmentCode, addDepartmentCode, getDepartmentCodeByCode } from '../../../../Core/Service/ReportingCodes-service';

const AddReportingCodesModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        id: '',
        description: '',
        errors: {},
        fields: {},
        code: props.code
    });

    useEffect(async () => {
        if (props.code) {
            let res = await getDepartmentCodeByCode(props.code);
            if (res.success) {
                let ReportingCodeData = res.data?.details[0];
                setState((st) => ({
                    ...st,
                    code: props.code,
                    description: ReportingCodeData.description,
                    partDesc: ReportingCodeData.partDesc,
                    agedQueriesDesc: ReportingCodeData.agedQueriesDesc,
                    seq: ReportingCodeData.seq,
                    inReporting: ReportingCodeData.inReporting
                }));
            }
        }
    }, [props.code]);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: name === 'id' ? value.slice(0, 2) : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            let data = {
                code: state.code || null,
                description: state.description,
                partDesc: state.partDesc,
                agedQueriesDesc: state.agedQueriesDesc,
                seq: state.seq,
                inReporting: state.inReporting,
                Mode: props.code ? 'U' : 'A'
            };
            if (state.code) {
                let res = await updateDepartmentCode(data);
                if (!res.success) {
                    console.error(res);
                } else {
                    hideModal();
                    props.onFormSubmit(true);
                }
            } else {
                let res = await addDepartmentCode(data);

                if (!res.success) {
                    console.error(res);
                } else {
                    hideModal();
                    props.onFormSubmit(true);
                }
                showToast(res.message);
            }
        }
    };

    const handleValidation = () => {
        let { code, description, seq } = state;
        let errors = {};
        let formIsValid = true;

        if (code == null || code == '') {
            formIsValid = false;
            errors['code'] = 'code cannot be empty';
        }

        if (description == null || description == '') {
            formIsValid = false;
            errors['description'] = 'Description cannot be empty';
        }
        if (seq == null || seq == '') {
            formIsValid = false;
            errors['seq'] = 'Sequence cannot be empty';
        }

        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };
    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{props.code ? <DialogTitles>Update Department Code</DialogTitles> : <DialogTitles>Add Department Code</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll" style={{ overflow: 'auto', width: '56vh' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Reporting Code
                        </FormLabel>
                        <TextBox
                            name="code"
                            onChange={fieldChange}
                            value={state.code}
                            disabled={props.code ? true : false}
                            autoFocus={props.code ? false : true}
                        />
                        <span className="mandatoryfields">{state.errors['code']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Description
                        </FormLabel>
                        <TextBox name="description" onChange={fieldChange} value={state.description} autoFocus={props.code ? true : false} />
                        <span className="mandatoryfields">{state.errors['description']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormLabel component="legend">Part Description</FormLabel>
                        <TextBox name="partDesc" onChange={fieldChange} value={state.partDesc} autoFocus={props.code ? true : false} />
                        <span className="mandatoryfields">{state.errors['partDesc']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormLabel component="legend">Aged Queries Description</FormLabel>
                        <TextBox name="agedQueriesDesc" onChange={fieldChange} value={state.agedQueriesDesc} autoFocus={props.code ? true : false} />
                        <span className="mandatoryfields">{state.errors['agedQueriesDesc']}</span>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Sequence
                        </FormLabel>
                        <TextBox name="seq" type="number" onChange={fieldChange} value={state.seq} autoFocus={props.code ? true : false} />
                        <span className="mandatoryfields">{state.errors['seq']}</span>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} container item>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel>In Reporting?</FormLabel>
                        </Grid>
                        <YesNoButton
                            state={state.inReporting}
                            name="inReporting"
                            onYesClick={() => handleClick('inReporting', true)}
                            onNoClick={() => handleClick('inReporting', false)}
                        />
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddReportingCodesModal;
