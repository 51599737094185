import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import langLabel from '../../../Lang.Labels.json';

export const Customi18 = () => {
    const { t, i18n } = useTranslation();
    // const setLanguage = (langCode) => {
    //     i18n.changeLanguage(langCode || 'en');
    // };

    return { t, i18n, langLabel };
};
