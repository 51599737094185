import React from 'react';
import { Grid, Switch } from '@material-ui/core';

const SlideToggle = (props) => {
    const { leftLabel, rightLabel, ...restProps } = props;
    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item>{props.checked ? <strike>{leftLabel}</strike> : leftLabel}</Grid>
            <Grid item>
                <Switch {...restProps} color="primary" />
            </Grid>
            <Grid item>{props.checked ? rightLabel : <strike>{rightLabel}</strike>}</Grid>
        </Grid>
    );
};

export default SlideToggle;
