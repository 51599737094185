import React, { useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import TabComponent from '../../../Core/Controls/Tabs';
import { FormLabel, Grid, Switch, TextField } from '@material-ui/core';
import WorkFlowSettingComponent from './WorkFlowSettingComp';
import GridFilterModal from './FilterModal';
import { GetUserPreferenceWorkflowsList, saveSwapUserPreferenceWorkflows } from '../../../Core/Service/dashbordService';
import { Autocomplete } from '@material-ui/lab';
import { SecondaryButton } from '../../../Core/FormInput';
import AppContext from '../../../App/AppContext';
import { postGridFilterList } from '../../../Core/Service/workflow-service';
import WorkflowHeaderFlags from './WorkflowHeaderFlags';

const initState = {};

const FilterModalMainTabComponent = (props) => {
    const { showToast, setUserPreference } = useContext(AppContext);

    const [state, setState] = useState({
        filterList: [],
        languageList: [],
        languageCode: {},
        WorkflowsList: [],
        fieldValues: {},
        pagination: {
            field: 'pagination',
            title: 'Pagination',
            hidden: false,
            fieldName: 'UserGridColumnDisplayPagination'
        },
        showJobHeader: { ...props.showJobHeader }
    });

    useEffect(() => {
        Object.values([...props.filterList]).forEach((obj) => {
            initState[obj.fieldName] = obj.hidden;
        });

        setState((st) => ({
            ...st,
            fieldValues: { ...initState },
            filterList: [...props.filterList],
            pagination: { ...state.pagination, hidden: props.isPagination }
        }));

        let data;
        Object.values(props?.languageData).forEach((obj) => {
            if (obj.id == props?.languageCode) {
                data = obj;
            }
        });
        setState((st) => ({ ...st, languageCode: data }));
    }, [props.filterList]);

    const handleAutoChange = (e, value, comp) => {
        if (value) {
            if (comp == 'language') {
                setState((st) => {
                    let newSt = { ...st };
                    newSt.languageCode = value;
                    return newSt;
                });
            }
        }
    };

    useEffect(async () => {
        let res = await GetUserPreferenceWorkflowsList();
        setState((st) => ({
            ...st,
            WorkflowsList: [...res?.data?.list]
        }));
    }, []);

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            let newSt = { ...st };
            newSt.fieldValues[name] = checked;
            return newSt;
        });
    };
    const handlePagination = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            let newSt = { ...st };
            newSt.pagination.hidden = checked;
            return newSt;
        });
    };
    const handleHeaderCheckbox = (event) => {
        const { name, checked } = event.target;
        console.log(name, checked, 'name, checked');
        setState((st) => {
            return {
                ...st,
                showJobHeader: {
                    ...st.showJobHeader, // Copy the showJobHeader object
                    [name]: checked // Update the specific field by name
                }
            };
        });
    };

    const getGridSequenceList = (list) => {
        setState((st) => ({ ...st, filterList: list }));
    };

    const getWorkFlowSequenceList = (list) => {
        setState((st) => ({ ...st, WorkflowsList: list }));
    };

    const handelClose = () => {
        props.handleCancel(false);
    };

    const handleSubmit = async () => {
        let sequenceValues = {};
        state.filterList.forEach((o, i) => (sequenceValues[o.postSequenceFieldName] = i + 1));
        let data = {
            ...state.fieldValues,
            ...state.showJobHeader,
            [state.pagination.fieldName]: state.pagination.hidden,
            ...sequenceValues,
            UserGridColumnLanguageCode: state.languageCode?.id
        };

        let pros = [];
        pros.push(
            postGridFilterList(data),
            saveSwapUserPreferenceWorkflows({
                workflowtb: state.WorkflowsList.flat(1).map((p, i) => ({
                    workflowId: p.id,
                    workflowActive: p.active,
                    workflowSequence: i
                }))
            })
        );
        let responses = await Promise.all(pros);
        if (responses[0].success && responses[1].success) {
            await setUserPreference();
            showToast('Save Successfully');
            props.handleCancel(true);
        }
    };

    const tabs = [
        {
            label: `Grid Setting`,
            body: (
                <GridFilterModal
                    languageCode={state.languageCode}
                    filterConfig={props.filterConfig}
                    filterList={[...state.filterList.map((p) => ({ ...p }))]}
                    handleCancel={props.handleCancel}
                    fieldValues={state.fieldValues}
                    languageData={state.languageData}
                    handleCheckbox={handleCheckbox}
                    getGridSequenceList={getGridSequenceList}
                />
            )
        },
        {
            label: `Workflow Setting`,
            body: (
                <WorkFlowSettingComponent
                    handleCancel={props.handleCancel}
                    WorkflowsList={[...state.WorkflowsList]}
                    getWorkFlowSequenceList={getWorkFlowSequenceList}
                />
            )
        },
        {
            label: 'Workflow Header',
            body: <WorkflowHeaderFlags handleCancel={props.handleCancel} showJobHeader={state.showJobHeader} handleCheckbox={handleHeaderCheckbox} />
        }
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TabComponent tabList={tabs} />
            </Grid>
            <Grid item xs={4}>
                <Grid container xs item component="label" className="childSwitchBtnContainer" alignItems="center">
                    <Grid item>Pagination</Grid>
                    <Grid item>
                        <Switch checked={state.pagination.hidden} onChange={handlePagination} value="checked" name={'pagination'} />
                    </Grid>
                </Grid>
                {/* {[...state.filterList].map((j) => {
                    if (j.field === 'pagination') {
                        return (
                            <Grid container xs item component="label" className="childSwitchBtnContainer" alignItems="center">
                                <Grid item>Pagination</Grid>
                                <Grid item>
                                    <Switch checked={state.fieldValues[j.fieldName]} onChange={handleCheckbox} value="checked" name={j.fieldName} />
                                </Grid>
                            </Grid>
                        );
                    }
                })} */}
            </Grid>
            <Grid item xs={4}>
                <FormLabel>Language</FormLabel>
                <Autocomplete
                    autoSelect
                    options={props.languageData}
                    name="language"
                    value={state.languageCode}
                    onChange={(e, value) => handleAutoChange(e, value, 'language')}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <SecondaryButton onClick={handleSubmit} fullWidth className="submit_btn">
                            Submit
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <SecondaryButton onClick={handelClose} fullWidth className="submit_btn">
                            Cancel
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FilterModalMainTabComponent;
