import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../ReportsScreens/GijgoForReportsScreen';
import ReactDOM from 'react-dom';
import MailIcon from '@material-ui/icons/Mail';
import { NoteAdd } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { SecondaryButton, SquareButton } from '../../../../Core/FormInput/AppButton';
import InvoiceCustomersAddScreen from './addScreen';
import { useState } from 'react';
import DialogComp from '../../../../Core/Modal/dialogModal';
import InvoiceCustomersEditScreen from './editScreen';
import InvoiceCustomersNoteScreen from './noteScreen';
import { getCustomerExcelFile } from '../../../../Core/Service/customer-services';
import moment from 'moment/moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Tooltip, IconButton } from '@material-ui/core';

const baseColumns = [
    { field: 'action', sortable: false, title: `Action`, width: 30, filterable: false },
    {
        field: 'name',
        title: `Customer Name`,
        width: 120
    },
    {
        field: 'customerCode',
        title: `Code`,
        width: 40
    },
    {
        field: 'invoiceEmail',
        title: `Invoice Email`,
        width: 120
    },
    {
        field: 'address1',
        title: `Address`,
        width: 120
    },
    {
        field: 'town',
        title: `Town`,
        width: 80
    },
    {
        field: 'contactName',
        title: `Contact Name`,
        width: 100
    }
];
const crumbs = (path) => [
    { name: 'Home', path: '/' },
    { name: 'Invoices', active: true },
    { name: `Customers`, active: true }
];

const InvoiceCustomersListScreen = (props) => {
    const [state, setState] = useState({ showModal: false });

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent='space-evenly' alignItems="center">
                <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: record.invoiceSendEmail ? '#183B68' : '#868e96',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => handleEdit(record)}
                    >
                        <MailIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
                <Grid>
                    <SquareButton
                        style={{
                            backgroundColor: record.hasNote ? '#183B68' : '#868e96',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => handleNote(record)}
                    >
                        <NoteAdd fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let branchCol = tempCols.find((element) => element.field === 'action');

        if (branchCol) {
            branchCol.renderer = editButton({});
        }

        return tempCols;
    }, [props.MeasureID]);

    const handleEdit = (record) => {
        setState((st) => ({
            ...st,
            showEditModal: true,
            custId: record.customerId
        }));
    };

    const handleNote = (record) => {
        setState((st) => ({
            ...st,
            showNoteModal: true,
            custId: record.customerId
        }));
    };

    const handleEditClose = (res) => {
        let newSt = {};
        newSt.showEditModal = false;
        newSt.showNoteModal = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };
    const handleClose = (res) => {
        let newSt = {};
        newSt.showModal = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };

    const downloadCSVWrapper = async () => {
        getCustomerExcelFile();
    };

    const baseUrl = `Customers/CustomerList`;

    return (
        <div className="report-screen-container">
            <Grid item container justify="flex-start">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs(props.MeasureDesc)} />
                </Grid>

                {/* <Grid item style={{ padding: '5px 10px 0px 0px' }}>
                    <SecondaryButton
                        onClick={() =>
                            setState((st) => ({
                                ...st,
                                showModal: true
                            }))
                        }
                        startIcon={<AddIcon />}
                    >
                        Add Customer
                    </SecondaryButton>
                </Grid> */}
            </Grid>

            <Grid item container spacing={1} xs={12} style={{ position: 'relative' }}>
                <Grid item style={{ bottom: '20px', left: '15px', zIndex: 99, position: 'absolute' }}>
                    <Tooltip title={`Download Excel`} arrow>
                        <IconButton aria-label="delete" style={{ padding: '0px' }}>
                            <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            // records: res?.data?.list.map((p) => ({ ...p })),
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isReload={state.isReload}
                    // isShowTotal={true}
                    // hidePagination={true}
                    // hideFilterRow={true}
                    />
                </Grid>
            </Grid>

            {state.showModal ? (
                <DialogComp title="Add Customer" maxWidth="lg" onClose={() => handleClose(false)} fullWidth>
                    <InvoiceCustomersAddScreen onClose={() => handleClose(true)} />
                </DialogComp>
            ) : null}
            {state.showEditModal ? (
                <DialogComp title="Auto Email Invoices" maxWidth="md" onClose={() => handleEditClose(false)} fullWidth>
                    <InvoiceCustomersEditScreen customerId={state.custId} onClose={handleEditClose} />
                </DialogComp>
            ) : null}
            {state.showNoteModal ? (
                <DialogComp title="Customer Notes" maxWidth="md" onClose={() => handleEditClose(false)} fullWidth>
                    <InvoiceCustomersNoteScreen customerId={state.custId} onClose={handleEditClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default InvoiceCustomersListScreen;
