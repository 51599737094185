import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../../../App/AppContext";
import {
    SecondaryButton,
    DefaultButton,
    TextBox,
    DialogContents,
    DialogTitles,
    Dialogs,
    DialogsActions,
} from "./../../../../Core/FormInput/index";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import "./../../User Management/UserManagement.css";
import {
    getOcrDocuments,
    addOCRDocFields,
    getTableColumnsList
} from "./../../../../Core/Service/OcrDocuments-service";

const AddOCRDocFields = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const OCRDocFields = props.OCRDocFields;
    const ocrDocId = props.ocrDocId;
    const [state, setState] = useState({
        OcrDocumnetList: [],
        IsRequired: false,
        IsPrimary: false,
        Label: "",
        ColumnName: "",
        OCRDocFieldsID: null,
        columnNameList:[],
    });
    useEffect(async () => {
        let res2 = await getTableColumnsList("HeaderRecords");
        if (res2.data) {
            setState((st) => ({
                ...st,
                columnNameList: res2.data.rows,
            }));
        }
        let result1 = await getOcrDocuments();
        if (result1.success) {
            setState((st) => ({
                ...st,
                OcrDocumnetList: result1.data.rows,
            }));
        }            

        if (OCRDocFields && OCRDocFields.id) {
            setState((st) => ({
                ...st,
                OCRDocFieldsID: OCRDocFields.id,
                IsRequired: OCRDocFields.isRequired,
                IsPrimary: OCRDocFields.isPrimaryKey,
                Label: OCRDocFields.fieldLabel,
                ColumnName: OCRDocFields.columnName,
            }));
        }
    }, []);
    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const handelSubmit = async () => {
        let res = await addOCRDocFields(
            state.OCRDocFieldsID,
            state.ColumnName,
            state.Label,
            state.IsRequired,
            state.IsPrimary,
            ocrDocId,
        );
        if (!res.success) {
            console.error(res);
        } else {
            hideModal();
            props.onFormSubmit(true);
        }
        showToast(res.message);
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };
    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };
    const HeaderRecords = [
        {
            id: 1,
            name: "WIPNumber",
        },
    ];
    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    {state.OCRDocFieldsID ? (
                        <DialogTitles>Update OCR Document Field</DialogTitles>
                    ) : (
                        <DialogTitles>Add OCR Document Field</DialogTitles>
                    )}
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormLabel component="legend">Label</FormLabel>
                        <TextBox
                            autoFocus={true}
                            required
                            name="Label"
                            onChange={fieldChange}
                            value={state.Label}
                        />
                    </Grid>             
                    <Grid item xs={12} sm={6}>
                        <FormLabel component="legend">Column Name</FormLabel>
                        <TextBox
                            select
                            name="ColumnName"
                            value={state.ColumnName}
                            onChange={fieldChange}
                            variant="outlined"
                        >
                            {state.columnNameList.map((option) => (
                                <MenuItem key={option.columN_NAME} value={option.columN_NAME}>
                                    {option.columN_NAME}
                                </MenuItem>
                            ))}
                        </TextBox>          
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={2} sm={2}>
                                <FormLabel
                                    component="legend"
                                    className="btn_add"
                                >
                                    Is Required?
                                </FormLabel>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={
                                        state.IsRequired
                                            ? "btnActive"
                                            : "btninActive"
                                    }
                                    value={true}
                                    name="IsRequired"
                                    onClick={() =>
                                        handleClick("IsRequired", true)
                                    }
                                >
                                    Yes
                                </DefaultButton>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={
                                        state.IsRequired
                                            ? "btninActive"
                                            : "btnActiveNo"
                                    }
                                    value={false}
                                    name="IsRequired"
                                    onClick={() =>
                                        handleClick("IsRequired", false)
                                    }
                                >
                                    No
                                </DefaultButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={2} sm={2}>
                                <FormLabel
                                    component="legend"
                                    className="btn_add"
                                >
                                    Is Primary?
                                </FormLabel>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={
                                        state.IsPrimary
                                            ? "btnActive"
                                            : "btninActive"
                                    }
                                    value={true}
                                    name="IsPrimary"
                                    onClick={() =>
                                        handleClick("IsPrimary", true)
                                    }
                                >
                                    Yes
                                </DefaultButton>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={
                                        state.IsPrimary
                                            ? "btninActive"
                                            : "btnActiveNo"
                                    }
                                    value={false}
                                    name="IsPrimary"
                                    onClick={() =>
                                        handleClick("IsPrimary", false)
                                    }
                                >
                                    No
                                </DefaultButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddOCRDocFields;
