import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { Grid, IconButton, InputLabel } from '@material-ui/core';
import { FormLabel } from '@material-ui/core';
import { TextBox } from '../../../Core/FormInput';
import { YesNoButton } from '../../../Core/FormInput';
import { SecondaryButton } from '../../../Core/FormInput';
import { addUpdateSpecialEquipment, GetSpecialEquipmentDetails } from '../../../Core/Service/SpecialEquipmentService';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import { getBranches } from '../../../Core/Service/branches-service';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Backup } from '@material-ui/icons';
import getResizedImage from '../../../Core/CommonFunctions/imageResizer';

const modelgridStyle = {
    docImageContainer: { height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
    //  formatterImage: { width: '40%', maxHeight: 60, backgroundSize: 'cover' },
    formatterImage: { objectFit: 'contain', objectPosition: '50% 50%', width: '100%', height: '100%' }
};
const AddEditSpecialEquipment = (props) => {
    const { showToast } = useContext(AppContext);
    const [state, setState] = useState({
        SpecialEquipmentID: null,
        SpecialEquipmentDescription: '',
        SpecialEquipmentQuantity: 0,
        SpecialEquipmentCode: '',
        SpecialEquipmentIsActive: true,
        SpecialEquipmentToolLocation: '',
        selectedBranch: null,
        branches: [],
        errors: {}
    });
    useEffect(async () => {
        if (props.specialEquipmentID) {
            let res = await GetSpecialEquipmentDetails(props.specialEquipmentID);
            let data = res.data[0];
            setState((st) => ({
                ...st,
                SpecialEquipmentID: data?.specialEquipmentID || null,
                SpecialEquipmentDescription: data?.specialEquipmentDescription || '',
                SpecialEquipmentQuantity: data?.specialEquipmentQuantity || 0,
                SpecialEquipmentCode: data?.specialEquipmentCode || '',
                SpecialEquipmentIsActive: data?.specialEquipmentIsActive || true,
                SpecialEquipmentToolLocation: data?.specialEquipmentToolLocation || '',
                SpecialEquipmentBranchID: data?.specialEquipmentBranchID || null,
                SpecialEquipmentPhoto: data.specialEquipmentPhoto || null
            }));
        }
    }, []);
    const pullBranches = async () => {
        const res = await getBranches();

        if (res.success) {
            setState((st) => ({
                ...st,
                branches: res.data
            }));
        }
    };

    useEffect(() => {
        pullBranches();
    }, []);

    useEffect(() => {
        if (props.specialEquipmentID) {
            state.branches?.map((branch) => {
                if (branch.id == state.SpecialEquipmentBranchID) {
                    setState((st) => ({
                        ...st,
                        selectedBranch: branch
                    }));
                }
            });
        }
    }, [state.branches, state.SpecialEquipmentBranchID]);

    const clearImage = (name) => (e) => {
        setState((st) => ({ ...st, [name]: null }));
    };
    let handleImage = (logo) => (event) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            const { result } = e.target;
            setState((st) => ({
                ...st,
                [logo]: result
            }));
        };
        reader.readAsDataURL(event.target.files[0]);
    };
    let handleLogoUpload = (event, name) => {
        if (event.target.files[0].type.includes('image')) {
            getResizedImage(
                event.target.files[0],
                (fileUrl) => {
                    setState((state) => {
                        const nst = { ...state, [name]: fileUrl };
                        return nst;
                    });
                },
                200,
                300
            );
        } else {
            showToast('Wrong file selected');
        }
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handleClick = (name, val) => {
        setState((st) => {
            const nst = { ...st, [name]: val };
            return nst;
        });
    };

    const handleAutoChange = (e, value) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.selectedBranch = value;
            return newSt;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            const data = {
                SpecialEquipmentID: state.SpecialEquipmentID ? state.SpecialEquipmentID : null,
                SpecialEquipmentDescription: state.SpecialEquipmentDescription,
                SpecialEquipmentQuantity: state.SpecialEquipmentQuantity,
                SpecialEquipmentCode: state.SpecialEquipmentCode,
                SpecialEquipmentIsActive: state.SpecialEquipmentIsActive,
                SpecialEquipmentBranchID: state.selectedBranch.id,
                SpecialEquipmentToolLocation: state.SpecialEquipmentToolLocation,
                SpecialEquipmentPhoto: state.SpecialEquipmentPhoto || null
            };

            const res = await addUpdateSpecialEquipment(data);
            if (res.success) {
                showToast(res.data.message);
                props.onClose(true);
            } else {
                showToast(res.message);
            }
        }
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!state.SpecialEquipmentDescription) {
            formIsValid = false;
            errors['SpecialEquipmentDescription'] = 'This field is mandatory';
        }
        if (!state.SpecialEquipmentQuantity) {
            formIsValid = false;
            errors['SpecialEquipmentQuantity'] = 'This field is mandatory';
        }
        if (!state.SpecialEquipmentCode) {
            formIsValid = false;
            errors['SpecialEquipmentCode'] = 'This field is mandatory';
        }
        if (!state.selectedBranch) {
            formIsValid = false;
            errors['selectedBranch'] = 'This field is mandatory';
        }

        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    return (
        <DialogComp
            title={props.specialEquipmentID ? `Edit Special Equipment` : `Add Special Equipment`}
            maxWidth="lg"
            onClose={() => props.onClose(false)}
            fullWidth
        >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                            <FormLabel required component="legend">
                                Code
                            </FormLabel>
                            <TextBox
                                style={{ marginTop: 15 }}
                                name="SpecialEquipmentCode"
                                value={state.SpecialEquipmentCode}
                                onChange={handleFieldChange}
                                disabled={props.specialEquipmentID}
                            />
                            <span className="mandatoryfields">{state.errors['SpecialEquipmentCode']}</span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <FormLabel required component="legend">
                                Description
                            </FormLabel>
                            <TextBox
                                style={{ marginTop: 15 }}
                                name="SpecialEquipmentDescription"
                                value={state.SpecialEquipmentDescription}
                                onChange={handleFieldChange}
                            />
                            <span className="mandatoryfields">{state.errors['SpecialEquipmentDescription']}</span>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <FormLabel component="legend">Tool Location</FormLabel>
                            <TextBox
                                style={{ marginTop: 15 }}
                                name="SpecialEquipmentToolLocation"
                                value={state.SpecialEquipmentToolLocation}
                                onChange={handleFieldChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormLabel required component="legend" style={{ marginBottom: '5px' }}>
                                Branch
                            </FormLabel>
                            <Autocomplete
                                autoSelect
                                options={state.branches}
                                name="selectedBranch"
                                value={state.selectedBranch}
                                onChange={handleAutoChange}
                                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.branchShortName}`)}
                                renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                            />
                            <span className="mandatoryfields">{state.errors['selectedBranch']}</span>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormLabel required component="legend">
                                Quantity
                            </FormLabel>
                            <TextBox
                                style={{ marginTop: 15 }}
                                name="SpecialEquipmentQuantity"
                                value={state.SpecialEquipmentQuantity}
                                onChange={handleFieldChange}
                                type="number"
                            />
                            <span className="mandatoryfields">{state.errors['SpecialEquipmentQuantity']}</span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} container>
                            <Grid item xs={12} style={{ marginBottom: '5px' }}>
                                <FormLabel>Is Active?</FormLabel>
                            </Grid>
                            <YesNoButton
                                state={state.SpecialEquipmentIsActive}
                                name="SpecialEquipmentIsActive"
                                onYesClick={() => handleClick('SpecialEquipmentIsActive', true)}
                                onNoClick={() => handleClick('SpecialEquipmentIsActive', false)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormLabel component="legend" className="mandatoryfields">
                                Equipment Photo
                            </FormLabel>
                            <Grid item xs={12} className="gridcontainer" style={{ height: '95%', width: '100%', position: 'relative' }}>
                                {state.SpecialEquipmentPhoto && (
                                    <span style={{ position: 'absolute', right: 0, top: 0 }}>
                                        <Tooltip title="Remove Photo">
                                            <CloseIcon fontSize="small" onClick={clearImage('SpecialEquipmentPhoto')} />
                                        </Tooltip>
                                    </span>
                                )}
                                <Grid item xs={12} style={{ height: '250px' }}>
                                    {state.SpecialEquipmentPhoto ? (
                                        <img
                                            src={
                                                state.SpecialEquipmentPhoto?.includes('base64')
                                                    ? state.SpecialEquipmentPhoto
                                                    : 'data:image/png;base64,' + state.SpecialEquipmentPhoto
                                            }
                                            alt={'Photo'}
                                            style={modelgridStyle.formatterImage}
                                        />
                                    ) : (
                                        <div style={modelgridStyle.docImageContainer}>
                                            <div>
                                                <Tooltip title="Upload Document">
                                                    <label>
                                                        <IconButton color="secondary" component="span" style={{ padding: '0px' }}>
                                                            <Backup style={{ fontSize: '60px' }} />
                                                            <input
                                                                type="file"
                                                                onChange={(e) => handleLogoUpload(e, 'SpecialEquipmentPhoto')}
                                                                style={{ display: 'none' }}
                                                                accept="image/*"
                                                            />
                                                        </IconButton>
                                                    </label>
                                                </Tooltip>
                                            </div>
                                            <p>No Photo Found</p>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <SecondaryButton className="Submitbtn" onClick={handleSubmit} style={{ marginTop: '15px' }}>
                        {props.specialEquipmentID ? 'EDIT' : 'ADD'}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </DialogComp>
    );
};

export default AddEditSpecialEquipment;
