import { FormLabel, Grid, TextField } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useEffect } from 'react';
import TabComponent from '../../../../../Core/Controls/Tabs';
import { getFPProviderList, getReportingBudgetScreenDropDown } from '../../../../../Core/Service/ReportScreens-service';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { SecondaryButton } from '../../../../../Core/FormInput';
import { useRef } from 'react';
import FPIntrestRateProviderTable from './FPTable';
import DialogComp from '../../../../../Core/Modal/dialogModal';
import AddEdirModelModal from './AddEditModelModal';

const crumbs = (vehicleType) => [
    { name: 'Home', path: '/' },
    { name: 'Reports', active: true },
    { name: 'Finance Provider Intrest Rate', active: true }
];

const FPIntrestRateProvider = (props) => {
    const childFunc = useRef(null);
    const [state, setState] = useState({
        filterList: [],
        branchList: [],
        branchCode: props.branchCode || '',
        branchName: props.branchName || '',
        yearCode: { description: `${new Date().getFullYear()}`, id: `${new Date().getFullYear()}` },
        vehicleTypeCode: {},
        currentMonthDays: [],
        customerList: [],
        customerCode: {},
        filterData: [],
        budgetYear: [],
        targetType: [],
        modelList: [],
        vehicleCategories: [],
        isValueChange: false,
        isRecord: true,
        isReload: null,
        selectedModelId: null
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [yearRes, providerRes] = await Promise.all([getReportingBudgetScreenDropDown(), getFPProviderList()]);

                if (yearRes.data && yearRes.data.year) {
                    setState((prevState) => ({
                        ...prevState,
                        budgetYear: yearRes.data.year.map((o) => ({
                            description: o.name.toString(),
                            id: o.id.toString()
                        }))
                    }));
                }

                if (providerRes.success && providerRes.data) {
                    setState((prevState) => ({
                        ...prevState,
                        modelList: providerRes.data.map((o) => ({
                            name: o.financeProviderName,
                            providerID: o.financeProviderID.toString()
                        })),
                        isRecord: true
                    }));
                }
            } catch (error) {
                // Handle errors here
            }
        };

        fetchData();
    }, [state.yearCode, state.isReload]);

    const handleAutoChange = (fieldName) => (e, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt[fieldName] = val;
            return newSt;
        });
    };

    const handleActionTriggerCheck = (value) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.isValueChange = value;
            return newSt;
        });
    };

    const handleModelOpen = (id) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.openModelModal = true;
            newSt.selectedModelId = id || null;
            return newSt;
        });
    };

    const closeModal = (isUpdateFlag) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.openModelModal = false;
            if (isUpdateFlag) newSt.isReload = new Date();

            return newSt;
        });
    };

    console.log(state);

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(props.vehicleType)} />
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={12} sm={6} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={10}>
                                    <Grid container spacing={2} alignItems="flex-end" style={{ verticalAlign: 'middle' }}>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Select Year&nbsp;</FormLabel>
                                            <Autocomplete
                                                disableClearable
                                                autoSelect
                                                options={state.budgetYear || []}
                                                name="Years"
                                                value={state.yearCode}
                                                onChange={handleAutoChange('yearCode')}
                                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                                renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                                            />
                                        </Grid>
                                        <Grid item style={{ marginBottom: 7 }}>
                                            <SecondaryButton onClick={() => handleModelOpen(null)} className="setFullWidth">
                                                Add Finance Provider
                                            </SecondaryButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <SecondaryButton className="setFullWidth" onClick={() => childFunc.current()} disabled={!state.isValueChange}>
                                Save
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                    {state.isRecord ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <FPIntrestRateProviderTable
                                    childFunc={childFunc}
                                    yearCode={state.yearCode}
                                    modelList={state.modelList}
                                    handleActionTriggerCheck={handleActionTriggerCheck}
                                    handleModelOpen={handleModelOpen}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                Something Went Wrong
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {state.openModelModal ? (
                <DialogComp title={`${state.selectedModelId ? 'Update' : 'Add'} Finance Provider`} onClose={() => closeModal(false)} maxWidth="sm" fullWidth>
                    <AddEdirModelModal selectedModelId={state.selectedModelId} onClose={closeModal} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default FPIntrestRateProvider;
