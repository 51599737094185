import React, { useContext, useState, useEffect, useMemo } from 'react';
import { SecondaryButton, TextBox, DefaultButton, YesNoButton, SelectBox } from '../../../../Core/FormInput/index';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import AppContext from '../../../../App/AppContext';
import { FormHelperText } from '@material-ui/core';
import {
    getAppraisalScreenColumnPermission,
    getAppraisalScreenColumnsByID,
    getAppraisalVehicleTypes,
    postAppraisalScreenColumns
} from '../../../../Core/Service/appraisalScreensColumns-service';
import RoleBasedColumns from './RoleBasedColumns';
import TabView from '../../../../Core//Controls/Tabs';

const ControlType = [
    { id: 'autosuggest', name: 'autosuggest' },
    { id: 'checkbox', name: 'checkbox' },
    { id: 'damage-faces', name: 'damage-faces' },
    { id: 'data-puller', name: 'data-puller' },
    { id: 'date', name: 'date' },
    { id: 'datetime', name: 'datetime' },
    { id: 'divider', name: 'divider' },
    { id: 'email', name: 'email' },
    { id: 'empty-col', name: 'empty-col' },
    { id: 'money', name: 'money' },
    { id: 'new-row', name: 'new-row' },
    { id: 'number', name: 'number' },
    { id: 'photo-uploader', name: 'photo-uploader' },
    { id: 'select', name: 'select' },
    { id: 'sign-pad', name: 'sign-pad' },
    { id: 'textarea', name: 'textarea' },
    { id: 'textbox', name: 'textbox' }
];
const CreateAppraisalModal = (props) => {
    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({
        id: props.screenColumnID || null,
        screenID: props.screenID,
        errors: {},
        vehicleTypeList: [],
        controlTypeList: ControlType,
        controlType: null,
        vehicleTypeID: null
    });
    const [roleScreenColumns, setRoleScreen] = useState([]);

    useEffect(async () => {
        if (props.screenColumnID) {
            pullData();
        }
        let res = await getAppraisalVehicleTypes();
        if (res.success) {
            setState((st) => ({ ...st, vehicleTypeList: res?.data?.list || [] }));
        }
        let res2 = await getAppraisalScreenColumnPermission(props.screenColumnID || null);
        if (res2.success) {
            setRoleScreen(res2?.data?.roleScreenColumns || []);
        }
    }, []);

    const validation = () => {
        let isValid = true;
        let error = {};
        const { positionIndex, label, controlType } = state;
        if (!positionIndex) {
            error.positionIndex = 'Sequence is Required';
            isValid = false;
        }
        if (!label) {
            error.label = 'Label is Required';
            isValid = false;
        }
        if (!controlType) {
            error.controlType = 'Type is Required';
            isValid = false;
        }

        setState((st) => ({
            ...st,
            errors: error
        }));

        return isValid;
    };

    const pullData = async () => {
        let res = await getAppraisalScreenColumnsByID(props.screenColumnID);

        let data = res?.data?.list[0] || [];
        if (res.success) {
            setState((st) => ({
                ...st,
                ...data
            }));
        }
    };

    const handelSubmit = async (flag) => {
        if (validation()) {
            const { errors, vehicleTypeList, controlTypeList, ...rest } = state;
            const filteredArray =
                roleScreenColumns.length > 0
                    ? roleScreenColumns.filter((item) => Object.keys(item).some((key) => key !== 'name' && key !== 'roleId' && item[key] !== null))
                    : [];

            let data = { ...rest, roleScreenColumns: filteredArray };

            let res = await postAppraisalScreenColumns(data);
            if (res.success) {
                showToast(`Appraisal screen columns ${props.screenColumnID ? 'Updated' : 'Added'} Successfully`);
                props.onClose();
            } else {
                showToast(`Appraisal screen columns Failed to add`);
            }
        }
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };
    const validateSpace = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st };

            if (value.includes(' ')) {
                console.log(name, value);
                nst.jsonName = value.replace(/\s/g, '');
            } else {
                nst[name] = value;
            }
            return nst;
        });
    };

    //default state comp
    const DefaultComp = () => {
        return (
            <Grid container spacing={1}>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Required?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.required}
                        name="required"
                        onYesClick={() => handleClick('required', true)}
                        onNoClick={() => handleClick('required', false)}
                    />
                </Grid>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Readonly?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.readonly}
                        name="readonly"
                        onYesClick={() => handleClick('readonly', true)}
                        onNoClick={() => handleClick('readonly', false)}
                    />
                </Grid>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Hidden?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.hidden}
                        name="hidden"
                        onYesClick={() => handleClick('hidden', true)}
                        onNoClick={() => handleClick('hidden', false)}
                    />
                </Grid>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Edit Required?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.editRequired}
                        name="editRequired"
                        onYesClick={() => handleClick('editRequired', true)}
                        onNoClick={() => handleClick('editRequired', false)}
                    />
                </Grid>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Edit Readonly?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.editReadonly}
                        name="editReadonly"
                        onYesClick={() => handleClick('editReadonly', true)}
                        onNoClick={() => handleClick('editReadonly', false)}
                    />
                </Grid>
                <Grid xs={4} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Edit Hidden?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.editHidden}
                        name="editHidden"
                        onYesClick={() => handleClick('editHidden', true)}
                        onNoClick={() => handleClick('editHidden', false)}
                    />
                </Grid>
            </Grid>
        );
    };

    const handleRoleColsClick = (name, value, roleId) => {
        let tempList = [...roleScreenColumns];
        let index = roleScreenColumns.findIndex((m) => m.roleId === roleId);
        tempList[index][name] = value;
        setRoleScreen(tempList);
    };

    const tabs = useMemo(() => {
        let arr = [
            {
                label: 'Default',
                body: <DefaultComp />
            }
        ];

        if (roleScreenColumns.length > 0) {
            roleScreenColumns?.map((s) => {
                const toPush = {
                    label: s.name,
                    body: <RoleBasedColumns data={s} handleRoleColsClick={handleRoleColsClick} />
                };
                arr.push(toPush);
            });
        }

        return arr;
    }, [roleScreenColumns, state]);

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <FormLabel component="legend" error>
                        Label
                    </FormLabel>
                    <TextBox autoFocus={true} name="label" onChange={fieldChange} value={state.label} />
                    <FormHelperText error>{state.errors.label}</FormHelperText>
                </Grid>
                <Grid item xs={4} sm={3} md={1}>
                    <FormLabel component="legend" error>
                        Sequence
                    </FormLabel>
                    <TextBox type="number" name="positionIndex" onChange={fieldChange} value={state.positionIndex} />
                    <FormHelperText error>{state.errors.positionIndex}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={3} md={5}>
                    <FormLabel component="legend" error>
                        Type
                    </FormLabel>
                    <SelectBox
                        style={{ marginTop: '8px' }}
                        onChange={fieldChange}
                        value={state.controlType}
                        name="controlType"
                        List={state.controlTypeList || []}
                    />
                    <FormHelperText error>{state.errors.controlType}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel component="legend" error>
                        JSON Name
                    </FormLabel>
                    <TextBox autoFocus={true} name="jsonName" onChange={validateSpace} value={state.jsonName} />
                    <FormHelperText error>{state.errors.jsonName}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel component="legend">Default Value</FormLabel>
                    <TextBox autoFocus={true} name="defaultValue" onChange={fieldChange} value={state.defaultValue} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel component="legend">Tool tip</FormLabel>
                    <TextBox autoFocus={true} name="tooltip" onChange={fieldChange} value={state.tooltip} />
                </Grid>
                <Grid item xs={4} sm={3} md={1} lg={2}>
                    <FormLabel component="legend">Max Length</FormLabel>
                    <TextBox type="number" name="maxValue" onChange={fieldChange} value={state.maxValue} />
                </Grid>
                <Grid item xs={4} sm={3} md={1} lg={2}>
                    <FormLabel component="legend">Min Length</FormLabel>
                    <TextBox type="number" name="minValue" onChange={fieldChange} value={state.minValue} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel component="legend">API End Point</FormLabel>
                    <TextBox name="controlValueApiEndPoint" onChange={fieldChange} value={state.controlValueApiEndPoint} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel component="legend">Vehicle Type</FormLabel>
                    <SelectBox
                        style={{ marginTop: '8px' }}
                        onChange={fieldChange}
                        value={state.vehicleTypeID}
                        name="vehicleTypeID"
                        List={state.vehicleTypeList || []}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel component="legend">CSS Classes</FormLabel>
                    <TextBox name="cssClasses" onChange={fieldChange} value={state.cssClasses} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel component="legend">Section CSS Classes</FormLabel>
                    <TextBox name="fieldSectionCssClasses" onChange={fieldChange} value={state.fieldSectionCssClasses} />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <FormLabel component="legend">Inline Style</FormLabel>
                    <TextBox name="inlineStyle" onChange={fieldChange} value={state.inlineStyle} />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <FormLabel component="legend">Description</FormLabel>
                    <TextBox name="screenColumnNotes" onChange={fieldChange} value={state.screenColumnNotes} />
                </Grid>
                <TabView tabList={tabs} id="testing_tabs" tabLableSize="1.0rem" tabLableWeight="600" />

                <Grid item xs={12} sm={12}>
                    <SecondaryButton className="Submitbtn" onClick={() => handelSubmit(false)}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};

export default CreateAppraisalModal;
