import React, { useEffect, useState, useContext } from 'react';
import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import { TextBox, SecondaryButton, DatePicker, Multiselect, DateTimePicker, SingelSelect } from '../../../Core/FormInput';
import formatters from '../../../Core/Grid/inputFormatter';
import { postVORVehicle, getVORscreenDropdowns, getVorByID } from '../../../Core/Service/CustUserCommunicator/vor-service';
import moment from 'moment';
import AppContext from '../../../App/AppContext';

const CommunicatorAddVor = (props) => {
    const { showToast, showModal } = useContext(AppContext);

    const [state, setState] = useState({
        errors: {},
        workAccomplishedCodes: [],
        AllvorTypeDetails: [],
        vorTypeList: [],
        vorTypeDetailList: [],
        technicianFailureCodeIDs: [],
        technicianFailureCode: [],
        workAccomplishedCodesId: [],
        showLoader: true,
        vorEstimatedEndDate: null,
        isReadOnly: false,
        faultTypes: [],
        defectFaultTypes: [],
        vorDateStarted: moment().format('YYYY-MM-DDTHH:mm')
    });

    const [masterData, setMasterData] = useState({
        technicianFailureCode: [],
        workAccomplishedCodes: []
    });

    const [selectedData, setSelectedData] = useState({
        technicianFailureCode: [],
        workAccomplishedCodes: [],
        modules: []
    });

    const [tempOptions, setTempOptions] = useState({
        vorTypeIDValue: null,
        vorReasonIDValue: null
    });

    const workAccomplishedChange = (e, value) => {
        setSelectedData((st) => ({ ...st, workAccomplishedCodes: value }));
        setState((st) => {
            const nst = { ...st, workAccomplishedCodesId: value.map((c) => c.id) };
            return nst;
        });
    };
    const technicianFailureChange = (e, value) => {
        setSelectedData((st) => ({ ...st, technicianFailureCode: value }));
        setState((st) => {
            const nst = { ...st, technicianFailureCodeIDs: value.map((c) => c.id) };
            return nst;
        });
    };

    useEffect(() => {
        if (props.vorID) {
            const selectedtechnicianFailureCode = masterData.technicianFailureCode?.filter((w) => state.technicianFailureCodeIDs?.indexOf(w.id) > -1);
            const selectedworkAccomplishedCodes = masterData.workAccomplishedCodes?.filter((w) => state.workAccomplishedCodesId?.indexOf(w.id) > -1);
            setSelectedData((st) => ({
                ...st,
                technicianFailureCode: selectedtechnicianFailureCode,
                workAccomplishedCodes: selectedworkAccomplishedCodes
            }));
        }
    }, [state.technicianFailureCodeIDs, masterData.technicianFailureCode, state.workAccomplishedCodesId, masterData.workAccomplishedCodes]);

    useEffect(async () => {
        let res = await getVORscreenDropdowns(props.VehicleID);
        if (res.success) {
            setState((st) => ({
                ...st,
                vorTypeList: res.data.vorTypes.map((v) => ({ id: v.vorTypeID, name: v.vorTypeDescription })),
                AllvorTypeDetails: res.data.reasons,
                faultTypes: res.data.faults
            }));
            setMasterData((st) => ({
                workAccomplishedCodes: res.data.workAccomplishedCodes,
                technicianFailureCode: res.data.technicianFailureCodes
            }));
        }

        if (props.vorID) {
            let resById = await getVorByID(props.vorID);
            if (resById.success) {
                setState((st) => ({
                    ...st,
                    vorID: resById.data?.vorTypes[0].vorVehicleID,
                    vorVehicleVehicleID: resById.data?.vorTypes[0].vorVehicleVehicleID,
                    vorDateStarted: formatters.DateTimeTHMSFormatter(resById.data?.vorTypes[0].vorDateStarted),
                    vorDateEnded: formatters.DateTimeTHMSFormatter(resById.data?.vorTypes[0].vorDateEnded),
                    vorTypeID: resById.data?.vorTypes[0].vorTypeID,
                    vorReasonID: resById.data?.vorTypes[0].vorReasonID,
                    vorEstimatedEndDate: formatters.DateFormatter(resById.data?.vorTypes[0].vorEstimatedEndDate),
                    technicianFailureCodeIDs: resById.data.technicianFailureCode.map((m) => m.id) || [],
                    workAccomplishedCodesId: resById.data.workAccomplishedCodes.map((m) => +m.id) || [],
                    isReadOnly: resById.data.vorDateEnded ? true : false,
                    defectFaultTypes: resById.data.faultIds.map((m) => m.id) || []
                }));
                setTempOptions((st) => ({
                    ...st,
                    vorTypeIDValue: res.data?.vorTypes
                        .filter((order) => order.vorTypeID === resById.data?.vorTypes[0]?.vorTypeID)
                        .map((v) => ({ id: v.vorTypeID, name: v.vorTypeDescription }))[0],
                    vorReasonIDValue: res.data.reasons.filter((v) => v.id === resById.data.vorTypes[0]?.vorReasonID).map((p) => ({ id: p.id, name: p.name }))[0]
                }));
            }
        }
    }, []);

    useEffect(() => {
        if (state.defectFaultTypes) {
            const selectedFaultTypes = state.faultTypes.filter((w) => state.defectFaultTypes.indexOf(w.id) > -1);
            setSelectedData((st) => ({
                ...st,
                modules: selectedFaultTypes
            }));
        }
    }, [state.defectFaultTypes, state.faultTypes]);

    const ModuleChange = (e, value) => {
        setSelectedData((st) => ({ ...st, modules: value }));
        setState((st) => {
            const nst = { ...st, defectFaultTypes: value.map((c) => c.id) };
            return nst;
        });
    };

    useEffect(() => {
        let filterTypeDetails = [];
        if (tempOptions.vorTypeIDValue) {
            filterTypeDetails = state.AllvorTypeDetails.filter((v) => {
                return v.typeID == tempOptions.vorTypeIDValue?.id;
            }).map((l) => ({ id: l.id, name: l.name }));
        }

        setState((st) => ({ ...st, vorTypeDetailList: filterTypeDetails }));
    }, [tempOptions.vorTypeIDValue, tempOptions.vorReasonIDValue]);

    const validations = () => {
        const { vorVehicleTypeID, vorVehicleTypeDetailID, vorDateStarted, vorEstimatedEndDate, vorVehicleLocation, vorAdditionalComment, vorSubCategoryId } =
            state;

        const { vorTypeIDValue, vorReasonIDValue, vorSubCategoryIdValue } = tempOptions;

        let formIsValid = true;
        let errors = {};
        if (!vorTypeIDValue) {
            errors.vorTypeID = 'VOR Type is required';
            formIsValid = false;
        }
        if (!vorReasonIDValue) {
            errors.vorVehicleTypeDetailID = 'VOR Reason is required';
            formIsValid = false;
        }
        if (!vorDateStarted) {
            errors.vorDateStarted = 'Start Date is required';
            formIsValid = false;
        }

        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        let data = {};
        if (validations()) {
            if (props.vorID) {
                data = {
                    vorID: props.vorID,
                    vORHeaderRecordID: props.headerRecordID,
                    vorDateStarted: state.vorDateStarted,
                    vorDateEnded: state.vorDateEnded,
                    vorTypeID: tempOptions.vorTypeIDValue?.id,
                    vorReasonID: tempOptions.vorReasonIDValue?.id,
                    vorEstimatedEndDate: state.vorEstimatedEndDate,
                    technicianFailureCode: state.technicianFailureCodeIDs,
                    workAccomplishedCodes: state.workAccomplishedCodesId,
                    faultIds: selectedData.modules.map(({ id }) => id)
                };
            } else {
                data = {
                    vORHeaderRecordID: props.headerRecordID,
                    vorTypeID: tempOptions.vorTypeIDValue?.id,
                    vorReasonID: tempOptions.vorReasonIDValue?.id,
                    vorDateStarted: state.vorDateStarted,
                    vorEstimatedEndDate: state.vorEstimatedEndDate,
                    faultIds: selectedData.modules.map(({ id }) => id)
                };
            }
            let res = await postVORVehicle(data);
            if (res.success) {
                showToast(`${props.vorID ? 'VOR Updated Successfully' : 'VOR Added Successfully'}`);
                // props.vorID ? props.onClose(true) : props.onClose(false);
                props.onClose(true);
            } else {
                showToast(res.message);
            }
        }
    };

    const inputChange = (e) => {
        const { name, value, type } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => {
            let newSt = { ...st, [nm]: val };
            if (nm === 'vorTypeIDValue') {
                newSt.vorReasonIDValue = null;
            }
            return newSt;
        });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        VOR Type
                    </InputLabel>
                    <SingelSelect
                        options={state.vorTypeList || []}
                        value={tempOptions.vorTypeIDValue}
                        onChange={singleSelectChange('vorTypeIDValue')}
                        disabled={state.isReadOnly}
                    />
                    <FormHelperText error>{state.errors.vorTypeID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        VOR Reason
                    </InputLabel>
                    <SingelSelect
                        options={state.vorTypeDetailList || []}
                        value={tempOptions.vorReasonIDValue}
                        onChange={singleSelectChange('vorReasonIDValue')}
                        disabled={state.isReadOnly}
                    />
                    <FormHelperText error>{state.errors.vorVehicleTypeDetailID}</FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <InputLabel required shrink style={{ marginBottom: 4 }}>
                        VOR Start Date
                    </InputLabel>
                    <DateTimePicker
                        placeholder="VOR Start Date"
                        value={state.vorDateStarted}
                        onChange={inputChange}
                        name="vorDateStarted"
                        inputProps={{
                            max: new Date().toISOString().slice(0, 16)
                        }}
                        disabled={state.isReadOnly}
                    />
                    <FormHelperText error>{state.errors.vorDateStarted}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <InputLabel shrink>Estimated Completion Date</InputLabel>
                    <DatePicker
                        placeholder="Estimated Completion Date"
                        value={state.vorEstimatedEndDate}
                        onChange={inputChange}
                        name="vorEstimatedEndDate"
                        disabled={state.isReadOnly}
                    />
                    <FormHelperText error>{state.errors.vorEstimatedEndDate}</FormHelperText>
                </Grid>
                {!props.vorID && (
                    <Grid item xs={12}>
                        <InputLabel shrink>Fault Category</InputLabel>
                        <Multiselect options={state.faultTypes || []} value={selectedData.modules} onChange={ModuleChange} style={{ marginTop: -8 }} />
                    </Grid>
                )}
                {props.vorID && (
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel shrink>VOR End Date</InputLabel>
                        <DateTimePicker
                            placeholder="VOR End Date"
                            value={state.vorDateEnded}
                            onChange={inputChange}
                            name="vorDateEnded"
                            InputProps={{ inputProps: { min: `${state.vorDateStarted}` } }}
                            disabled={state.isReadOnly}
                        />
                        <FormHelperText error>{state.errors.vorDateEnded}</FormHelperText>
                    </Grid>
                )}
                {props.vorID && (
                    <Grid item xs={12}>
                        <InputLabel shrink>Fault Category</InputLabel>
                        <Multiselect options={state.faultTypes || []} value={selectedData.modules} onChange={ModuleChange} style={{ marginTop: -8 }} />
                    </Grid>
                )}
                {props.vorID && (
                    <Grid item xs={12} sm={6}>
                        <InputLabel shrink>Technician Failure Code</InputLabel>
                        <Multiselect
                            options={masterData.technicianFailureCode || []}
                            onChange={technicianFailureChange}
                            value={selectedData.technicianFailureCode}
                            disabled={state.isReadOnly}
                        />
                    </Grid>
                )}
                {props.vorID && (
                    <Grid item xs={12} sm={6}>
                        <InputLabel shrink>Work Accomplished Codes</InputLabel>
                        <Multiselect
                            options={masterData.workAccomplishedCodes || []}
                            value={selectedData.workAccomplishedCodes}
                            onChange={workAccomplishedChange}
                            disabled={state.isReadOnly}
                        />
                    </Grid>
                )}
                {props.vorID ? (
                    <Grid item xs={6} className={`${props.vorID ? '' : 'submit_btn'}`}>
                        <SecondaryButton fullWidth onClick={submitHandler} disabled={state.isReadOnly}>
                            Update
                        </SecondaryButton>
                    </Grid>
                ) : (
                    <Grid item xs={6} className={`${props.vorID ? '' : 'submit_btn'}`}>
                        <SecondaryButton fullWidth onClick={submitHandler}>
                            Add
                        </SecondaryButton>
                    </Grid>
                )}
                <Grid item xs={6} className={`${props.vorID ? '' : 'submit_btn'}`}>
                    <SecondaryButton fullWidth forceEnabled={true} onClick={() => props.onClose(false)}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};
export default CommunicatorAddVor;
