import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush, AreaChart, Area, ResponsiveContainer, ComposedChart } from 'recharts';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';
import { Button, ButtonGroup, Grid, Hidden, Typography } from '@material-ui/core';
import formatters from './../../../../Core/Grid/inputFormatter';

const CustomTooltip = ({ active, payload, label, id }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ padding: 3, border: '1px solid', background: 'rgba(0,0,0,0.6)' }}>
                <div style={{ textAlign: 'left', color: 'white', textTransform: 'uppercase' }}>{`${payload[0].payload.name}`}</div>
                <div style={{ textAlign: 'left', color: 'white', textTransform: 'uppercase' }}>{`${payload[0].payload.date}`}</div>
                <div style={{ textAlign: 'left', color: 'white' }}>
                    Budget :
                    {id === 'H' ? (
                        <b style={{ fontWeight: 600 }}> &nbsp;{`${formatters.ToFixedSeparator(payload[0].payload.budget)}`}</b>
                    ) : (
                        <b style={{ fontWeight: 600 }}> &nbsp;{`${formatters.CurrencyThousandSeparator(payload[0].payload.budget)}`}</b>
                    )}
                </div>
                <div style={{ textAlign: 'left', color: 'white' }}>
                    Actual :
                    {id === 'H' ? (
                        <b style={{ fontWeight: 600 }}> &nbsp;{`${formatters.ToFixedSeparator(payload[0].payload.value)}`}</b>
                    ) : (
                        <b style={{ fontWeight: 600 }}> &nbsp;{`${formatters.CurrencyThousandSeparator(payload[0].payload.value)}`}</b>
                    )}
                </div>
                {/* <p className="desc">Anything you want can be displayed here.</p> */}
            </div>
        );
    }

    return null;
};

export const MiniViewGraphChart = (props) => {
    const { list, branchList, getSelectedBranch, branchID, id, handleRedirectCal } = props;

    console.log(branchID, 'branchID');
    const filterList = list.filter((k) => k.id === branchID);
    const graphList = filterList.sort((a, b) => new Date(a.date) - new Date(b.date));
    const [width, height] = useWindowSize();
    const selectedBranch = (val) => {
        try {
            if (val._reactName === 'onChange') {
                getSelectedBranch(id, +val.target.value);
            } else {
                getSelectedBranch(id, val);
            }
        } catch (err) {
            console.warn(err);
        }
    };
    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                        <Grid item>
                            {' '}
                            <Typography className="mainCardTextPrimary">{props.label}</Typography>
                        </Grid>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <Grid item>
                                <div
                                    style={{
                                        margin: '0px 20px',
                                        textAlign: 'left'
                                    }}
                                >
                                    <select onChange={selectedBranch} className="selectclass" value={branchID}>
                                        {branchList.map((btn, i) => {
                                            return (
                                                <option key={i} value={btn.id}>
                                                    {btn.name}{' '}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </Grid>
                        </Hidden>
                        <Hidden only={['xs', 'sm']}>
                            <Grid item>
                                <ButtonGroup color="secondary" aria-label="outlined primary button group" size="small">
                                    {branchList.map((k) => (
                                        <Button key={k.id} onClick={() => selectedBranch(k.id)} variant={branchID === k.id ? 'contained' : 'outlined'}>
                                            {k.name}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ borderBottom: '1px solid lightgrey ' }} onClick={() => handleRedirectCal(`?branch=${branchID}`)}>
                    <ResponsiveContainer width="100%" height={200}>
                        <ComposedChart
                            width={500}
                            height={200}
                            data={graphList}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0
                            }}
                        >
                            {/* <Legend type="cross" /> */}
                            <CartesianGrid strokeDasharray="4 4" />
                            <XAxis dataKey="date" style={{ fontSize: width >= 600 ? 10 : 10 }} />
                            <YAxis style={{ fontSize: width >= 600 ? 10 : 10 }} />
                            <Tooltip content={<CustomTooltip id={id} />} />
                            <Line type="monotone" dataKey="budget" stroke="red" strokeWidth={2} />
                            <Line type="monotone" dataKey="value" stroke="#30cf87" fill={'#30cf87'} strokeWidth={1} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </Grid>
    );
};
