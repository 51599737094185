import { get, post, put } from './http-calls';

export const getBtteryTestDetails = async (id, technicianJobId) => {
    let res = await get(`BatteryTests/${id}`, { useAuthToken: true });
    return res;
};

export const postBtteryTestDetails = async (data) => {
    let res = await post('BatteryTests', data, { useAuthToken: true });
    return res;
};
