import { get, post } from './http-calls';

export const getDynamicFormDetails = async (Id) => {
    let res = await get(`DynamicForms/GetByID/${Id}`, { useAuthToken: true });
    return res;
};

export const saveDynamicForms = async (data) => {
    let res = await post(`DynamicForms `, data, { useAuthToken: true });
    return res;
};

export const deleteDynamicForm = async (id) => {
    let res = await post(`DynamicForms/DeleteDynamicForm/${id}`, {}, { useAuthToken: true });
    return res;
};

export const saveDynamicFormFields = async (data) => {
    let res = await post(`DynamicFormFields `, data, { useAuthToken: true });
    return res;
};

export const getDynamicFormFieldsById = async (Id) => {
    let res = await get(`DynamicFormFields/${Id}`, { useAuthToken: true });
    return res;
};

export const getDynamicFormFieldsByFormID = async (id) => {
    let res = await get(`DynamicFormFields/ByFormID/${id} `, { useAuthToken: true });
    return res;
};

export const getOptionsByDynamicFormId = async (id) => {
    let res = await get(`DynamicFormFields/OptionsByDynamicFormId/${id}`, { useAuthToken: true });
    return res;
};

export const getDynamicFormDataByID = async (headerRecordId, dynamicFormId) => {
    let res = await get(`DynamicForms/DynamicFormData/${headerRecordId}/${dynamicFormId}`, {
        useAuthToken: true
    });
    return res;
};
export const getGetDynamicFormDocuments = async (headerRecordId, documentFieldID) => {
    let res = await get(`DynamicForms/GetDynamicFormDocuments/${headerRecordId}/${documentFieldID}`, {
        useAuthToken: true
    });
    return res;
};

export const saveDynamicFormFieldUploadDoc = async (headerRecordId, dynamicFormFieldId, formData) => {
    let res = await post(`DynamicForms/UploadDoc/${headerRecordId}/${dynamicFormFieldId}`, formData, {
        useAuthToken: true
    });

    return res;
};

export const deleteDocDynamicFormField = async (headerRecordId, documentID, formData) => {
    let res = await post(
        `DynamicForms/DeleteDoc/${headerRecordId}/${documentID}`,
        {},
        {
            useAuthToken: true
        }
    );

    return res;
};
export const saveDynamicFormData = async (headerRecordId, dynamicFormId, data) => {
    let res = await post(`DynamicForms/DynamicFormData/${headerRecordId}/${dynamicFormId}`, data, {
        useAuthToken: true
    });

    return res;
};

export const swapDynamicFormFields = async (DynamicFormID, DynamicFormFieldId1, DynamicFormFieldId2) => {
    let res = await post(`DynamicFormFields/SwapDynamicFormFields`, {
        DynamicFormID: DynamicFormID,
        DynamicFormFieldId1: DynamicFormFieldId1,
        DynamicFormFieldId2: DynamicFormFieldId2,
    }, {
        useAuthToken: true
    });

    return res;
};