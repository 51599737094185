import { Grid } from '@material-ui/core';
import React from 'react';
import AppContext from '../../../App/AppContext';
import GijgoGrid from '../../../Components/internal/dashboard';
import moment from 'moment';
import { useContext } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import AssignTech from '../../../Components/internal/assign-technician/assignTechnModal';
import DialogComp from '../../Modal/dialogModal';

const TechDashboardGrid = (props) => {
    const { portalSettings, getUserPreference, isAppReaload } = useContext(AppContext);
    const [state, setState] = useState({});
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamTid = params.get('tid') || '';
    let ParamBnm = params.get('bnm') || '';

    useEffect(() => {
        return () => {
            console.log('cleaned up');
        };
    }, [ParamTid]);

    const handleBtnClcik = (val) => {
        props.history.push(`/Job/${val.headerRecordID}/${val.workflowID}`);
    };

    const handleAssignmentBtnClick = (val) => {
        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            showTechAssign: true
        }));
    };

    const editButton = (id, val) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container>
                <Grid style={{ marginLeft: '13px' }}>
                    <IconButton size="small" onClick={() => handleBtnClcik(val)}>
                        <ArrowForwardIosIcon
                            style={{
                                color: val.returnReasonTextColourCode
                            }}
                            fontSize="small"
                        />
                    </IconButton>
                </Grid>
                <Grid>
                    {val.workflowID == 8 && (
                        // <Tooltip title={val.technicians} aria-label="add" disableFocusListener>
                        <IconButton title={`${val.userName}`} size="small" onClick={() => handleAssignmentBtnClick(val)}>
                            <AssignmentIndIcon
                                style={{
                                    color: val.returnReasonTextColourCode
                                }}
                                fontSize="small"
                            />
                        </IconButton>
                        // </Tooltip>
                    )}
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const baseColumns = [
        {
            title: 'Action',
            field: '',
            renderer: editButton,
            width: 90,
            filterable: false
        },
        {
            field: 'userName',
            sortable: true,
            title: `${portalSettings.portalSettingGridOwningOperatorLabel || 'Technician'}`,
            width: 150
        },
        { field: 'wipNumber', sortable: true, title: `${portalSettings.portalSettingGridWIPNoLabel || 'WIP No'}`, width: 70 },
        { field: 'registration', sortable: true, title: `${portalSettings.portalSettingGridVehicleRegLabel || 'Vehicle Reg'}`, width: 90 },
        { field: 'customerName', sortable: true, title: `${portalSettings.portalSettingGridCustomerNameLabel || 'Customer Name'}`, width: 200 },
        {
            field: 'dateDueIn',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueInLabel || 'Due Date In'}`,
            // type: 'date',
            type: 'dateTime',
            // flex: 0.6
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueIn != null) {
                    return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        {
            field: 'dateDueOut',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueOutLabel || 'Date Due Out'}`,
            // type: 'date',
            type: 'dateTime',
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueOut != null) {
                    return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        { field: 'returnReason', sortable: true, title: `${portalSettings.portalSettingGridReturnReasonLabel || 'Return Reason'}`, width: 190 },
        // { field: 'chassisNumber', sortable: true,title: 'Chassis Number',  },
        // { field: 'branchShortName', sortable: true, title: `${portalSettings.portalSettingGridBranchLabel || 'Branch'}`, width: 120 },
        { field: 'serviceText', sortable: true, title: `${portalSettings.portalSettingGridJobDescriptionLabel || 'Job Description'}`, width: 170 },
        {
            field: 'assigned',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDateLabel || 'Assigned Date'}`,
            width: 135,
            renderer: function (value, record) {
                if (record.assigned != null) {
                    return moment(record.assigned).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        {
            field: 'assignedDays',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDaysLabel || 'Assigned Days'}`,
            width: 110
        },
        {
            field: 'taken',
            sortable: true,
            title: `${portalSettings.portalSettingGridTimeTakenLabel || 'Time Taken'}`,
            width: 100
        },
        {
            field: 'status',
            sortable: true,
            title: `${portalSettings.portalSettingGridStatusLabel || 'Status'}`,
            width: 135
        }

        // { field: 'makeModel', sortable: true, title: `${portalSettings.portalSettingGridModelLabel || 'Model'}`, width: 200 }
    ];

    let Column = baseColumns.map((c) => {
        let obj = {};
        if (c.title !== 'Action') {
            obj.events = {
                click: function (e) {
                    props.history.push(
                        `/Job/${e.data.record.headerRecordID}/${e.data.record.workflowID}?ProcessId=${e.data.record.pid}&techId=${ParamTid}&branchShortName=${ParamBnm}`
                    );
                }
            };
        }
        return {
            ...c,
            ...obj,
            headerCssClass: 'gridHeader'
        };
    });

    const closeTechAssignDialog = (res) => {
        setState((st) => ({ ...st, showTechAssign: false, isReload: res ? new Date() : null }));
    };

    let getUrl = `TechnicianAssigned?Id=${ParamTid}&branchShortName=${ParamBnm}`;

    return (
        <Grid container>
            <Grid item xs={12}>
                <GijgoGrid columns={Column} getUrl={getUrl} isReload={state.isReload} displayPagination={state.displayPagination} />
            </Grid>
            {state.showTechAssign ? (
                <DialogComp title="Assigned Technician" maxWidth="sm" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <AssignTech jobId={state.jobId} handleCancel={closeTechAssignDialog} />
                </DialogComp>
            ) : null}
        </Grid>
    );
};

export default TechDashboardGrid;
