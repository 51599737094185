import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from './../../../internal/ReportsScreens/GijgoForReportsScreen/index';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
import { NoteAdd } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { SecondaryButton, SquareButton } from '../../../../Core/FormInput/AppButton';
import { useState } from 'react';
import DialogComp from '../../../../Core/Modal/dialogModal';
import AddAgeMeasured from '../addEditAgeMeasured';
import moment from 'moment/moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Tooltip, IconButton } from '@material-ui/core';

const baseColumns = [
    { field: 'action', sortable: false, title: `Action`, width: 20, filterable: false, align: 'left' },
    {
        field: 'menuLabel',
        title: `Menu Label`,
        width: 90
    },
    {
        field: 'description',
        title: `Description`,
        width: 100
    },
    {
        field: 'url',
        title: `Path`,
        width: 200
    },

    {
        field: 'excludeAreaCodes',
        title: `Area Codes`,
        width: 80
    },
    {
        field: 'sequence',
        title: `Sequence`,
        width: 35
    },
    {
        field: 'isActive',
        title: `Is Active`,
        width: 30,
        align: 'center'
    }
];
const crumbs = (path) => [
    { name: 'Home', path: '/' },
    { name: 'Reporting', active: true },
    { name: `Age Measured`, active: true }
];

const AdminReportingAgeMeasured = (props) => {
    const [state, setState] = useState({ showModal: false, addAgeMeasuredID: null });

    const addButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center" style={{ paddingRight: '7px' }}>
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px',
                        marginLeft: '20px'
                    }}
                    onClick={() =>
                        setState((st) => ({
                            ...st,
                            showModal: true,
                            addAgeMeasuredID: null
                        }))
                    }
                >
                    <AddIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="space-evenly" alignItems="center">
                <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() =>
                            setState((st) => ({
                                ...st,
                                showModal: true,
                                addAgeMeasuredID: record.id
                            }))
                        }
                    >
                        <EditIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let branchCol = tempCols.find((element) => element.field === 'action');

        if (branchCol) {
            branchCol.renderer = editButton({});
        }

        return tempCols;
    }, [props.MeasureID]);

    const handleEdit = (record) => {
        setState((st) => ({
            ...st,
            showEditModal: true,
            custId: record.customerId
        }));
    };

    const handleNote = (record) => {
        setState((st) => ({
            ...st,
            showNoteModal: true,
            custId: record.customerId
        }));
    };

    const handleEditClose = (res) => {
        let newSt = {};
        newSt.showEditModal = false;
        newSt.showNoteModal = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };
    const handleClose = (res) => {
        let newSt = {};
        newSt.showModal = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };

    const baseUrl = `ReportingAgedMeasures`;

    return (
        <div className="report-screen-container">
            <Grid item container justify="flex-start">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs(props.MeasureDesc)} />
                </Grid>
            </Grid>
            <Grid item container spacing={1} xs={12} style={{ position: 'relative' }}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.rows,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isReload={state.isReload}
                        AddBtn={addButton}
                        // isShowTotal={true}
                        // hidePagination={true}
                        // hideFilterRow={true}
                    />
                </Grid>
            </Grid>
            {state.showModal ? (
                <DialogComp title={`${state.addAgeMeasuredID ? 'Update' : 'Add'} Age Measured `} maxWidth="md" onClose={() => handleClose(false)} fullWidth>
                    <AddAgeMeasured onClose={() => handleClose(true)} AddAgeMeasuredID={state.addAgeMeasuredID} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default AdminReportingAgeMeasured;
