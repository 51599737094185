import React, { useState, useEffect, useRef } from 'react';
// import ModalPopup from '../../Modal/ModalPopup';
import ByteImage from '../../ByteImage/ByteImage';
import { SignPad } from '../../../SignaturePad/SignPad';
import DialogComp from '../../Dialog/DialogComp';
import { PrimaryButton, SecondaryButton } from '../../../FormInput';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        }
    }
}));

let VehiclePhotoUploader = function ({ config, state, onFieldChange, readOnly, autoFocus }) {
    const classes = useStyles();
    let controlName = config.screenColumnJsonName;
    let title = config.tooltip;
    let fileRef = useRef(null);
    let [localState, setLocalState] = useState([]);
    let [showImageModal, setShowImageModal] = useState(false);

    useEffect(() => {
        state[controlName] && setLocalState(state[controlName].map((c) => c.imgSrc));
    }, [state[controlName]]);

    function updateParentState(newArray) {
        onFieldChange({ target: { name: controlName, value: newArray.map((src, i) => ({ index: i, imgSrc: src })) } });
    }

    function fileSelected() {
        let input = fileRef.current;
        if (input.files && input.files[0]) {
            let allBase64Imgs = [];
            let fileCount = input.files.length;
            let fileReadCount = 0;
            for (let i = 0; i < fileCount; i++) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    fileReadCount += 1;
                    let base64String = e.target.result;
                    allBase64Imgs.push(base64String.split(',')[1]);
                    fileCount === fileReadCount && updateParentState([...localState, ...allBase64Imgs]);
                };
                reader.readAsDataURL(input.files[i]);
            }
            input.value = '';
        }
    }

    function removeImage(index) {
        let newArray = [...localState];
        newArray.splice(index, 1);
        updateParentState(newArray);
    }

    function selectImage(image) {
        setShowImageModal(image);
    }

    function modalClose() {
        setShowImageModal(false);
    }

    return (
        <div style={{ padding: 10 }}>
            {/* <input id="upload-photo" className="d-none" type="file" ref={fileRef} onChange={fileSelected} accept="image/png, image/jpeg" multiple /> */}

            {showImageModal && (
                <DialogComp title="Uploaded Image" onClose={modalClose} maxWidth="md">
                    <div className="drawer">
                        <SignPad
                            penColor="red"
                            resizeCanvasAsImage={true}
                            readOnly={true}
                            bgImage={showImageModal}
                            showSubmitButton={false}
                            showClearButton={false}
                            showClearAllButton={false}
                        ></SignPad>
                    </div>
                </DialogComp>
            )}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'left' }}>
                        <label htmlFor="contained-button-file">
                            <PrimaryButton variant="contained" color="primary" component="span">
                                <input
                                    className={classes.input}
                                    ref={fileRef}
                                    id="upload-photo"
                                    multiple
                                    type="file"
                                    onChange={fileSelected}
                                    accept="image/png, image/jpeg"
                                />
                            </PrimaryButton>
                        </label>
                    </div>
                </Grid>
                {localState.length > 0 &&
                    localState.map((img, i) => (
                        <>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={i}>
                                <ByteImage onClick={() => selectImage(img)} src={img} style={{ backgroudSize: '100%' }} height="250" width="100%" />
                                <SecondaryButton title="Remove Photo" onClick={() => removeImage(i)}>
                                    &times;
                                </SecondaryButton>
                            </Grid>
                        </>
                    ))}
            </Grid>
        </div>
    );
};

export default VehiclePhotoUploader;
