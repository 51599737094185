import React, { useReducer, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, InputLabel } from '@material-ui/core';
import { reducer, initState } from '../../../Reducer/action';
import { SecondaryButton, TextBox, YesNoButton } from '../../../../Core/FormInput/index';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LogHistory from './logHistory';
import { postActionLogEmailLog } from '../../../../Core/Service/communicator-service';
import AppContext from '../../../../App/AppContext';
import { useContext } from 'react';

const useStyles = makeStyles({
    table: {
        minWidth: '100%'
    }
});

const EmailActionLog = (props) => {
    const { showToast, showModal } = useContext(AppContext);
    const classes = useStyles();
    const { onClose } = props;
    // const [state, dispatch] = useReducer(reducer, initState);
    const [state, setState] = useState({
        email: '',
        msg: '',
        createActionLog: false,
        showExternally: false,
        wIPNo: props.wip,
        headerRecordID: props.headerRecordID,
        message: ''
    });

    const validation = () => {
        let isValid = true;
        const emailRegex = /\S+@\S+\.\S+/;
        let message = '';

        if (!state.email) {
            isValid = false;
            message = 'Email is required';
        }
        if (!emailRegex.test(state.email)) {
            isValid = false;
            message = 'Please enter a valid email!';
        }

        setState((st) => ({ ...st, message: message }));

        return isValid;
    };

    const handleEmail = async () => {
        if (validation()) {
            let data = {
                email: state.email,
                msg: state.msg,
                createActionLog: state.createActionLog,
                showExternally: state.showExternally,
                wIPNo: state.wip,
                headerRecordID: state.headerRecordID
            };
            let res = await postActionLogEmailLog(data);
            if (res.success) {
                showToast(`Email Sent Successfully`);
                onClose();
            }
        }
    };

    const fieldChange = (e) => {
        const { name, value, type, checked } = e.target;
        setState((st) => {
            const nst = { ...st };
            if (type === 'checkbox') {
                nst[name] = checked;
            } else {
                nst[name] = value;
            }
            return nst;
        });
    };

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ fontSize: 15, textAlign: 'left' }}>
                    WIP No:&nbsp; <b> {props.wip}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.reg}</b>
                    <span style={{ float: 'right', marginRight: 30 }}>
                        Key No:&nbsp; <b>{props.keyNo} </b>{' '}
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink error>
                        Email
                    </InputLabel>
                    <TextBox placeholder="Email" variant="outlined" name="email" fullWidth onChange={fieldChange} value={state.email} />
                    <InputLabel shrink error>
                        {state.message}
                    </InputLabel>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink error>
                        {' '}
                        Message
                    </InputLabel>
                    <TextBox
                        placeholder="Message"
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        // defaultValue="Default Value"
                        variant="outlined"
                        name="msg"
                        fullWidth
                        onChange={fieldChange}
                        value={state.msg}
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormControlLabel
                        style={{ margin: '0px 16px 0px 0px' }}
                        control={<Checkbox checked={state.createActionLog} onChange={fieldChange} name="createActionLog" />}
                        label="Create Action"
                        labelPlacement="endt"
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        disabled={state.createActionLog ? false : true}
                        style={{ margin: '0px 16px 0px 0px' }}
                        control={<Checkbox checked={state.showExternally} onChange={fieldChange} name="showExternally" />}
                        label="Show Externally"
                        labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton className="Submitbtn" fullWidth onClick={handleEmail}>
                        Send Email
                    </SecondaryButton>
                </Grid>
                <Grid item xs={12}>
                    <LogHistory headerRecordID={props.headerRecordID} />
                </Grid>
            </Grid>
        </div>
    );
};

export default EmailActionLog;
