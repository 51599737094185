import { useEffect } from 'react';
import { useState } from 'react';
import { FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';

import { SecondaryButton, TextBox } from '../../../../../../Core/FormInput';

import { postJobProgressSetVehicleArrivedStatus } from '../../../../../../Core/Service/communicator-service';
import { useContext } from 'react';
import AppContext from '../../../../../../App/AppContext';
import { singleEmailValidation } from '../../../../../../Core/Service/StaticService';
import { CustomeSignPad } from '../../../../../../Core/SignaturePad';
import formatters from '../../../../../../Core/Grid/inputFormatter';

const ArrivalDateModal = (props) => {
    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({
        messaegList: [],
        actionLog: false,
        editActionLog: false,
        selectedStatus: props.selectedStatus,
        errors: {},
        dateArrived: formatters.DateTimeTHMSFormatter(new Date()),
        collectedNotes: ''
    });

    useEffect(async () => {
        setState((st) => ({ ...st, logText: props.smsText, isCustomerUpdate: props.sendSMSUpdate, isUpdateSms: props.sendSMSUpdate }));
    }, [props]);

    const handleInput = (e) => {
        const { name, value, type, checked } = e.target;
        if (type == 'checkbox') {
            setState((st) => {
                let newSt = { ...st };
                newSt[name] = checked;
                if (!newSt.isCustomerUpdate) {
                    newSt.isCustomerEmail = '';
                }
                if (!newSt.isUpdateSms) {
                    newSt.isPhoneNo = '';
                }
                return newSt;
            });
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };

    const validation = () => {
        let { dateArrived, arrivedSignature, isCustomerUpdate, isCustomerEmail, isUpdateSms, isPhoneNo } = state;
        let isValid = true;
        let errors = {};

        if (!dateArrived) {
            isValid = false;
            errors.dateArrived = 'Arrival Date is required';
        }
        if (!arrivedSignature) {
            isValid = false;
            errors.arrivedSignature = 'Signature is required';
        }
        if (isCustomerUpdate && !singleEmailValidation(isCustomerEmail)) {
            isValid = false;
            errors.isCustomerEmail = 'Please Add Customer Email';
        }
        if (isUpdateSms && !isPhoneNo) {
            isValid = false;
            errors.isPhoneNo = 'Please Add Customer Email';
        }

        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            let data = {
                headerRecordID: props.headerRecordID,
                dateArrived: state.dateArrived,
                arrivedSignature: state.arrivedSignature,
                status: +state.selectedStatus,
                logText: state.logText,
                isPhoneNo: state.isPhoneNo,
                isCustomerEmail: state.isCustomerEmail
            };

            let res = await postJobProgressSetVehicleArrivedStatus(data);
            if (res.success) {
                showToast(`Vehicle Arrival Status Added Successfully`);
                props.onClose(true);
            } else {
                showToast(`Vehicle Arrival Status Failed To Add`);
            }
        }
    };

    const GetTechnicianSign = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };
    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            arrivedStoredSignature: null,
            arrivedSignature: null
        }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <InputLabel shrink required className="mandatoryfields">
                    Arrival Date Time
                </InputLabel>
                <TextBox type="datetime-local" name="dateArrived" value={state.dateArrived} variant="outlined" fullWidth onChange={handleInput} />
                <FormHelperText error>{state.errors.dateArrived}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <Typography color="secondary">Customer Message</Typography>
                <TextBox
                    placeholder="Message"
                    multiline
                    rows={3}
                    type="text"
                    name="logText"
                    variant="outlined"
                    fullWidth
                    value={state.logText}
                    onChange={handleInput}
                />
                <FormHelperText error>{state.errors.logText}</FormHelperText>
            </Grid>
            <Grid item xs={12} md={8}>
                <InputLabel shrink>
                    <input type="checkbox" style={{ cursor: 'pointer' }} name="isCustomerUpdate" onChange={handleInput} checked={state.isCustomerUpdate} />
                    Customer Email
                </InputLabel>
                <TextBox
                    placeholder="Email update to customer"
                    type="email"
                    name="isCustomerEmail"
                    variant="outlined"
                    fullWidth
                    value={state.isCustomerEmail}
                    onChange={handleInput}
                    error={state.isCustomerUpdate}
                />
                <FormHelperText error>{state.errors.isCustomerEmail}</FormHelperText>
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel shrink>
                    <input type="checkbox" style={{ cursor: 'pointer' }} onChange={handleInput} name="isUpdateSms" checked={state.isUpdateSms} />
                    Phone Number
                </InputLabel>
                <TextBox
                    placeholder="Phone Number"
                    type="tel"
                    name="isPhoneNo"
                    variant="outlined"
                    fullWidth
                    value={state.isPhoneNo}
                    onChange={handleInput}
                    error={state.isUpdateSms}
                />
                <FormHelperText error>{state.errors.isPhoneNo}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <CustomeSignPad
                    onChange={GetTechnicianSign}
                    content={'Signature'}
                    sign={state.arrivedStoredSignature}
                    name="arrivedSignature"
                    clearSig={clearSig}
                />
                <FormHelperText error>{state.errors.arrivedSignature}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton className="Submitbtn" fullWidth onClick={handleSubmit}>
                    Set Arrived
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default ArrivalDateModal;
