import {get, post, put } from './http-calls';

export const addBatteryResults = async(id, description) => {
    let res = await post(`/api/BatteryTestResults`, {
        BatteryTestResultCode: id,
        BatteryTestResultName: description,
        Mode: "A",
    }, { useAuthToken: true });
    return res;
}

export const updateBatteryResults = async(id, description) => {
    let res = await post(`/api/BatteryTestResults`, {
        BatteryTestResultCode: id,
        BatteryTestResultName: description,
        Mode: "U",
    }, { useAuthToken: true });
    return res;
};

export const getBatteryResults = async() => {
    let res = await get('/api/BatteryTestResults', { useAuthToken: true });
    return res;
}