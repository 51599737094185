import React from 'react';
import { Grid, InputLabel, Checkbox, FormHelperText } from '@material-ui/core';
import { TextBox } from '../../../../Core/FormInput';
import { SecondaryButton } from './../../../../Core/FormInput/AppButton';
import { Alert } from '@material-ui/lab';
import { getsendInvoice } from '../../../../Core/Service/internal_Invoice-service';
import { useEffect } from 'react';
import { useState } from 'react';
import { postMulitpleInvoices } from './../../../../Core/Service/internal_Invoice-service';
import AppContext from './../../../../App/AppContext';
import { useContext } from 'react';
import { AppStorage } from '../../../../Core/Service/storage-service';

const SendInvoices = (props) => {
    const { showToast, showModal } = useContext(AppContext);
    const [state, setState] = useState({
        showAlert: false,
        error: {}
    });

    useEffect(async () => {
        setState((st) => ({
            ...st,
            multiInvoiceFileName: props.multiInvoiceFileName,
            multiInvoiceId: props.multiInvoiceId,
            multiInvoiceNo: props.multiInvoiceNo,
            multiInvoicePDFId: props.multiInvoicePDFId,
            sendInvoiceContent: AppStorage.getSendMultiInovicesemailContent(),
            sendInvoiceEmail: ''
        }));
    }, []);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };
    let Validation = () => {
        let isValid = true;
        let error = {};
        if (!state.sendInvoiceContent) {
            isValid = false;
            error.sendInvoiceContent = 'Email Message is required';
        }
        setState((st) => ({
            ...st,
            error: error
        }));
        return isValid;
    };

    const SubmitMessage = async () => {
        if (Validation()) {
            let data = {
                multiInvoiceId: state.multiInvoiceId,
                multiInvoiceNo: state.multiInvoiceNo,
                sendInvoiceContent: state.sendInvoiceContent,
                sendInvoiceEmail: state.sendInvoiceEmail,
                multiInvoiceFileName: state.multiInvoiceFileName,
                multiInvoicePDFId: state.multiInvoicePDFId
            };
            let res = await postMulitpleInvoices(data);
            if (res.success) {
                showToast(`Invoice Message Send Successfully`);
                props.onClose(true);
            } else {
                console.error();
            }
        }
    };

    return (
        <div>
            {state.showAlert ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert variant="filled" severity="error">
                            Please Select some Invoices No to Send the Invoice.
                        </Alert>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel shrink>Invoice Numbers:</InputLabel>
                        <br />
                        <div
                            style={{
                                width: '100%',
                                color: '#0c5460',
                                background: '#d1ecf1',
                                borderColor: '#bee5eb'
                            }}
                        >
                            <div
                                style={{
                                    width: '99%',
                                    padding: 10,
                                    wordWrap: 'break-word'
                                }}
                            >
                                {state.multiInvoiceNo}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink>Email</InputLabel>
                        <TextBox type="email" name="sendInvoiceEmail" value={state.sendInvoiceEmail} onChange={handleFieldChange} autoFocus={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink>Email Message</InputLabel>
                        <TextBox type="text" multiline rows={6} name="sendInvoiceContent" value={state.sendInvoiceContent} onChange={handleFieldChange} />
                        <FormHelperText error>{state.error.sendInvoiceContent}</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <SecondaryButton fullWidth onClick={SubmitMessage}>
                            send
                        </SecondaryButton>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default SendInvoices;
