import React, { useContext, useEffect, useState, useMemo } from 'react';
import AppContext from '../../../App/AppContext';
import { Grid } from '@material-ui/core';
import formatters from '../../../Core/Grid/inputFormatter';
import ReportGijgoGrid from '../ReportsScreens/GijgoForReportsScreen';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';

const SpecialEquipmentHistoryModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let specialEquipmentCode = params.get('code') || null;
    let specialEquipmentID = params.get('id') || null;
    let activeOnly = params.get('activeOnly') === 'true';
    const history = useHistory();
    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Special Equipments', path: '/Lists/SpecialEquipment/Dash' },
        { name: specialEquipmentCode, active: true },
        { name: activeOnly ? 'In Use' : 'History', active: true }
    ];
    const baseColumns = [
        {
            field: 'specialEquipmentCode',
            title: 'Special Equipment',
            hidden: specialEquipmentCode != null
        },
        {
            field: 'wipNumber',
            title: 'WIP Number'
        },
        {
            field: 'branch',
            title: 'Branch',
            width: '10%'
        },
        {
            field: 'regNumber',
            title: 'Reg Number',
            width: '10%'
        },
        {
            field: 'customerName',
            title: 'Customer Name',
            width: '10%'
        },
        {
            field: 'displayNameAllocatedBy',
            title: 'Allocated By'
        }
    ];

    const allocatedRenderer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <div>
                {record.displayNameAllocatedBy}{' '}
                <span style={{ fontStyle: 'italic' }}>on {formatters.DateTimeFormatter(record.specialEquipmentDateAllocated)}</span>
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const deallocatedRenderer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <div>
                {record.specialEquipmentInUse ? (
                    <>In Use</>
                ) : (
                    <>
                        {record.displayNameDeAllocatedBy}
                        <span style={{ fontStyle: 'italic' }}> on {formatters.DateTimeFormatter(record.specialEquipmentDateUnallocated)}</span>
                    </>
                )}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const codeColRenderer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = <div>{`${record.specialEquipmentCode} - ${record.specialEquipmentDescription}`}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const wipField = (field) => (value, record) => {
        const spn = document.createElement('span');

        const lnk = (
            <div
                style={{ textDecoration: 'none', cursor: 'pointer' }}
                onClick={() => {
                    history.push({
                        pathname: `/Job/${record.specialEquipmentHeaderRecordId}/${record.workflowID}`
                    });
                }}
            >
                <span style={{ color: 'red' }}>{record.wipNumber}</span>
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let codeCol = tempCols.find((element) => element.field === 'specialEquipmentCode');
        let inUseCol = tempCols.find((element) => element.field === 'specialEquipmentInUse');
        let wipColumn = tempCols.find((element) => element.field === 'wipNumber');
        let allocatedByColumn = tempCols.find((element) => element.field === 'displayNameAllocatedBy');
        if (!activeOnly) {
            tempCols.splice(7, 0, {
                field: 'specialEquipmentDateUnallocated',
                title: 'Date De-Allocated',
                renderer: deallocatedRenderer({})
            });
        }
        if (codeCol) {
            codeCol.renderer = codeColRenderer({});
        }
        if (inUseCol) {
            inUseCol.renderer = (val) => (val ? 'Yes' : 'No');
        }
        if (wipColumn) {
            wipColumn.renderer = wipField({});
        }
        if (allocatedByColumn) {
            allocatedByColumn.renderer = allocatedRenderer({});
        }
        return tempCols;
    }, []);

    let BaseUrl = () => {
        if (specialEquipmentID) {
            return `SpecialEquipment/SpecialEquipmentUsed/equipment/${specialEquipmentID}/${activeOnly}`;
        } else {
            return `SpecialEquipment/SpecialEquipmentUsed/all/${activeOnly}`;
        }
    };

    return (
        <div>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs} />
                </Grid>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data.sort((a, b) => {
                                return b.specialEquipmentInUse - a.specialEquipmentInUse;
                            }),
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={BaseUrl()}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default SpecialEquipmentHistoryModal;
