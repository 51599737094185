import { Grid, IconButton } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import SalesExecModal from './SalesExecModal';
import DialogComp from '../../../../../Core/Modal/dialogModal';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import ReactDOM from 'react-dom';
import { SquareButton } from '../../../../../Core/FormInput/AppButton';
import EditIcon from '@material-ui/icons/Edit';

const baseColumns = [
    {
        field: 'action',
        title: 'Action',
        sortable: false,
        width: 100
    },
    {
        field: 'userName',
        title: 'Name',
        width: 200
    },
    {
        field: 'letterIdentifier',
        title: 'Franchise',
        flex: 1,
        sortable: false,
        filterable: false
    },
    {
        field: 'isUsedSalesExec',
        title: 'Used Sales Exec',
        width: 200,
        filterable: false
    },
    {
        field: 'isNewSalesExec',
        title: 'New Sales Exec',
        width: 200,
        filterable: false
    }
];

const SalesExec = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        data: null,
        showDel: false
    });

    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                data: params
            };
            return nst;
        });
    };

    const closeUpdateUser = (refreshGrid) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.data = null;
            newSt.show = null;
            if (refreshGrid) {
                newSt.isReload = new Date();
            }
            return newSt;
        });
    };
    const baseUrl = `UserDetailsFranchise`;

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid title={value} style={{ cursor: 'pointer', textAlign: 'center' }}>
                <Tooltip title="Update" aria-label="add">
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => handleUpdate(record)}
                    >
                        <EditIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Tooltip>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');

        if (actionCol) {
            actionCol.renderer = editButton;
        }
        return tempCols;
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <div>
                {state.show ? (
                    <DialogComp title="Update Sales Exec" onClose={() => closeUpdateUser(false)} maxWidth="sm" height="400px" width="300px">
                        <SalesExecModal onFormSubmit={() => closeUpdateUser(true)} data={state.data} />
                    </DialogComp>
                ) : null}
                <Breadcrumbs separator=">>" aria-label="breadcrumb">
                    <Link color="inherit" to={'/'}>
                        Home
                    </Link>
                    <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                        Sales Exec
                    </Typography>
                </Breadcrumbs>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res.data?.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isShowTotal={false}
                        hidePagination={false}
                        hideFilterRow={false}
                        isReload={state.isReload}
                    />
                </Grid>
            </div>
        </div>
    );
};
export default SalesExec;
