import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { SecondaryButton, TextBox } from '../../../../../Core/FormInput';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { withStyles } from '@material-ui/styles';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { Alert, Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import moment from 'moment/moment';

const useStyles = makeStyles({
    table: {
        minWidth: '100%'
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        border: 'none',
        padding: '3px 3px',
        fontSize: 16
    },
    body: {
        padding: '0px 3px',
        fontSize: 14,
        border: 'none'
    }
}))(TableCell);

const InternalBudgetModal = (props) => {
    const { vehicleObj, operaterDetail, targetSubmit, salesTargetRes } = props;
    const classes = useStyles();

    const [state, setState] = useState([
        {
            id: null,
            operatorID: {},
            amount: 0,
            unit: 0,
            isDeleted: false,
            internalTargetID: vehicleObj.id || null,
            targetNewUsed: vehicleObj.targetNewUsed,
            targetTypeID: vehicleObj.typeID,
            code: vehicleObj.code,
            date: moment().month(vehicleObj.index).startOf('month').year(vehicleObj.yearCode).format('DD-MMM-YYYY')
        }
    ]);

    const [valid, setValid] = useState({
        showError: false,
        errorMessage: ''
    });

    useEffect(() => {
        let fliterResArray = [];
        let resFinalArray = [];
        let filtersalesTarget = [];
        let finalSalesTarget = [];
        fliterResArray = salesTargetRes.filter((k) => k.code === vehicleObj.code && k.internalTargetID === vehicleObj.id);
        resFinalArray = fliterResArray.map((n) => ({
            ...n,
            operatorID: operaterDetail.find((k) => n.operatorID === k.id)
        }));

        filtersalesTarget = props.saleTarget.filter(
            (k) =>
                k.code === vehicleObj.code &&
                moment(k.date).format('DD-MMM-YYYY') === moment().month(vehicleObj.index).startOf('month').year(vehicleObj.yearCode).format('DD-MMM-YYYY')
        );

        finalSalesTarget = filtersalesTarget.map((n) => ({
            ...n,
            operatorID: operaterDetail.find((k) => n.operatorID === k.id)
        }));

        let updateArray = filtersalesTarget.filter((k) => k.id !== null);

        if (updateArray.length > 0) {
            updateArray.forEach((k) => {
                let index = resFinalArray.findIndex((m) => m.id === k.id);
                if (index !== -1) {
                    resFinalArray.splice(index, 1);
                }
            });
        }

        resFinalArray = [...resFinalArray, ...finalSalesTarget];

        setState((st) => {
            let newSt = [...st];
            if (resFinalArray.length > 0) {
                newSt = resFinalArray;
            } else {
                newSt = [
                    {
                        id: null,
                        operatorID: {},
                        amount: 0,
                        unit: 0,
                        isDeleted: false,
                        internalTargetID: vehicleObj.id || null,
                        targetNewUsed: vehicleObj.targetNewUsed,
                        targetTypeID: vehicleObj.typeID,
                        code: vehicleObj.code,
                        date: moment().month(vehicleObj.index).startOf('month').year(vehicleObj.yearCode).format('DD-MMM-YYYY')
                    }
                ];
            }
            return newSt;
        });
    }, [salesTargetRes, operaterDetail, props.saleTarget]);

    const handleAutoChange = (index, fieldName) => (e, val) => {
        setState((st) => {
            let newSt = [...st];
            let findIndex = newSt.find((n, i) => i === index);
            if (findIndex) {
                findIndex[fieldName] = val;
                findIndex['isAdded'] = true;
            }

            return newSt;
        });
    };

    const addTimeRow = () => {
        setState((st) => [
            ...st,
            {
                id: null,
                operatorID: '',
                amount: '',
                unit: '',
                isDeleted: false,
                internalTargetID: vehicleObj.id || null,
                targetNewUsed: vehicleObj.targetNewUsed,
                targetTypeID: vehicleObj.typeID,
                code: vehicleObj.code,
                date: moment().month(vehicleObj.index).startOf('month').year(vehicleObj.yearCode).format('DD-MMM-YYYY')
            }
        ]);
    };

    useEffect(() => {
        let TotalAmount = 0;
        let Totalunit = 0;
        state.map((k) => {
            TotalAmount += +k.amount;
            Totalunit += +k.unit;
        });

        setValid((st) => {
            let newSt = { ...st };
            newSt.showError = false;
            newSt.errorMessage = '';

            if (vehicleObj.unit < Totalunit && !props.selectedTargetType.exceedTarget) {
                newSt.showError = true;
                newSt.errorMessage = 'Calculated Unit should not exceed Target Unit';
            } else if (vehicleObj.profit < TotalAmount && !props.selectedTargetType.exceedTarget) {
                newSt.showError = true;
                newSt.errorMessage = 'Calculated Profit should not exceed Target Profit';
            }

            return newSt;
        });
    }, [state]);

    const removeTimeRow = (indx) => {
        setState((st) => {
            const newSt = [...st];
            let findIndex = newSt.find((n, i) => i === indx);
            if (findIndex) {
                findIndex.isDeleted = true;
            }

            return newSt;
        });
    };

    const handleInput = (index) => (e) => {
        let { name, value } = e.target;
        setState((st) => {
            let newSt = [...st];
            let findIndex = newSt.find((n, i) => i === index);
            if (findIndex) {
                findIndex[name] = value;
                findIndex['isAdded'] = true;
            }

            return newSt;
        });
    };

    const HandleRow = () => {
        if (props.selectedTargetType.exceedTarget) {
            return false;
        } else {
            let totalAmount = 0;
            let totalunit = 0;
            state.forEach((n) => {
                totalAmount += +n.amount;
                totalunit += +n.unit;
            });
            return vehicleObj.unit <= totalunit || vehicleObj.profit <= totalAmount;
        }
    };

    return (
        <div>
            <TableContainer style={{ maxHeight: 300 }}>
                <Table className={classes.table} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell colSpan={2}>
                                <b>{vehicleObj.year}</b>
                            </StyledTableCell>
                            <StyledTableCell colSpan={2}>
                                <b>{vehicleObj.name}</b>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                <b>Target</b>
                            </StyledTableCell>
                            <StyledTableCell>
                                <b>{vehicleObj.unit}</b>
                            </StyledTableCell>
                            <StyledTableCell>
                                <b>{formatters.CurrencyThousandSeparator(vehicleObj.profit)}</b>
                            </StyledTableCell>
                            <StyledTableCell align="right"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Seles Exec</StyledTableCell>
                            <StyledTableCell>Units</StyledTableCell>
                            <StyledTableCell>Profit</StyledTableCell>
                            <StyledTableCell align="right"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.map((row, index) => {
                            return (
                                !row.isDeleted && (
                                    <>
                                        <TableRow key={row.id}>
                                            <StyledTableCell component="th" scope="row" width={'40%'}>
                                                <Autocomplete
                                                    disableClearable
                                                    autoSelect
                                                    fullWidth
                                                    options={operaterDetail}
                                                    name="Years"
                                                    value={row.operatorID}
                                                    onChange={handleAutoChange(index, 'operatorID')}
                                                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                                    renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <TextBox
                                                    type="number"
                                                    placeholder=""
                                                    name="unit"
                                                    value={row.unit}
                                                    autoFocus={index === state.length - 1}
                                                    onChange={handleInput(index)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <TextBox type="number" placeholder="" name="amount" value={row.amount} onChange={handleInput(index)} />
                                            </StyledTableCell>
                                            <StyledTableCell align="right" width={'30px'}>
                                                <div>
                                                    {!HandleRow() && index === 0 && (
                                                        <AddBoxIcon style={{ color: 'green', cursor: 'pointer' }} fontSize="medium" onClick={addTimeRow} />
                                                    )}
                                                </div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </>
                                )
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <StyledTableCell colSpan={4}>
                                <SecondaryButton fullWidth onClick={() => targetSubmit(state)} disabled={valid.showError}>
                                    Submit
                                </SecondaryButton>
                            </StyledTableCell>
                        </TableRow>
                        {valid.showError ? (
                            <TableRow>
                                <StyledTableCell component="th" scope="row" colSpan={4}>
                                    <Alert severity="error" style={{ margin: 10 }}>
                                        {valid.errorMessage}
                                    </Alert>
                                </StyledTableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default InternalBudgetModal;
