import React, { useMemo } from 'react';
// import { SquareButton } from '../../../Core/FormInput/AppButton';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
// import DiagnosticFaultsModal from './DiagnosticFaultsModal';
import { useState } from 'react';
import ReportGijgoGrid from '../../ReportsScreens/GijgoForReportsScreen';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
// import formatters from '../../../Core/Grid/inputFormatter';
import formatters from '../../../../Core/Grid/inputFormatter';
import { BasicButtonGroup } from '../../../../Core/ButtonGroup';
import { getWorkflowsByProcessID } from '../../../../Core/Service/workflow-service';
import { useEffect } from 'react';
import NestedGijgoGrid from './nestedGijgoGrid';
import './usedSalesStyle.scss';
import { SecondaryButton } from '../../../../Core/FormInput';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { getStockbookList } from '../../../../Core/Service/apprisal-service';
import { useHistory } from 'react-router-dom';
const baseColumns = [
    { field: 'action', sortable: false, title: `Action`, width: 50 },
    { field: 'vsb', sortable: false, title: `Stock No`, width: 100 },
    { title: 'Sold By', field: 'sp', width: 200, sortable: true },
    { title: 'Customer', field: 'cust', width: 280, sortable: true },
    { title: 'Branch', field: 'loc', width: 120, sortable: true },
    {
        title: 'Reg Number',
        field: 'reg',
        width: 150,
        sortable: true
    },
    { title: 'Model', field: 'model', width: 90, sortable: true }
    // {
    //     title: 'Invoice Date',
    //     field: 'id',
    //     width: 90,
    //     sortable: true,
    //     type: 'date',
    //     format: 'dd-mmm-yyyy',
    //     cssClass: 'text-center',
    //     renderer: function (value, record) {
    //         if (record.id != null) {
    //             let date = new Date(record.id);
    //             return date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear();
    //         }
    //         return '';
    //     }
    // },
    // {
    //     title: 'Total Sale',
    //     field: 'ts',
    //     width: 90,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.ts + '"  >£' + addCommas(record.ts) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Total Cost',
    //     field: 'tc',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.tc + '"  >£' + addCommas(record.tc) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Gross Profit',
    //     field: 'gp',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.gpp + '"  >£' + addCommas(record.gp) + '</span>';
    //     },
    //     align: 'right'
    // }
];

const detailTemplate = () => {
    return `<div style='width:100%'>
        <table style='width:100%;'>
            <tr style='background-color:#DCDCDC;' >
                
                <th style='font-weight:750; text-align:left;'></th>
                <th style='font-weight:750; text-align:left;'>Order Number</th>
                <th style='font-weight:750; text-align:left;'>Sale Price</th>
                <th style='font-weight:750; text-align:left;'>Cost Price</th>
                <th style='font-weight:750; text-align:left;'>Disc O/A</th>
                <th style='font-weight:750; text-align:left;'>Bonuses</th>
                <th style='font-weight:750; text-align:left;'>Subsidy</th>
                <th style='font-weight:750; text-align:left;'>CPI</th>
                <th style='font-weight:750; text-align:left;'>Finance</th>
                <th style='font-weight:750; text-align:left;'>PDI</th>
                <th style=' font-weight:750; text-align:left;'>DFA Sale</th>
                <th style='text-align:left;font-weight:750;'>DFA Cost</th>
                <th style='text-align:left;font-weight:750;'>Intro Comm</th>
                <th style='width:20%; font-weight:750; text-align:left;'>Finance Company</th>
                <th style='font-weight:750; text-align:left;'>Shipment Number</th>
            </tr>
            <tr>
                
                <td></td>
                <td>{ord}</td>
                <td>£{sale}</td>
                <td>£{cost}</td>
                <td>£{discOval}</td>
                <td>£{bonuses}</td>
                <td>£{subsidy}</td>
                <td>£{cpi}</td>
                <td>£{finance}</td>
                <td>£{pdi}</td>
                <td>£{dfaSale}</td>
                <td>£{dfaCost}</td>
                <td>£{introCom}</td>
                <td>{fcompany}</td>
                <td>{ship}</td>
            </tr>
        </table>
        </div>`;
};

const crumbs = (path) => [
    { name: 'Home', path: '' },
    { name: 'New Sales', active: true }
];

const UsedSalesListView = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const ParamWorkFlowId = params.get('workFlowId');
    const ParamProcessId = params.get('ProcessId');
    const history = useHistory();

    const [state, setState] = useState({
        showModal: false,
        processId: ParamProcessId || props.ProcessId || 2,
        currentWorkflowId: ParamWorkFlowId || props.workFlowID
    });

    const handleClose = () => {
        setState((st) => ({
            ...st,
            showModal: false,
            isReload: new Date()
        }));
    };

    const handleEdit = (val) => {
        setState((st) => ({
            ...st,
            showModal: true,
            id: val.id
        }));
    };

    // const editButton = (value, record) => {
    //     const spn = document.createElement('span');
    //     const lnk = (
    //         <Grid container alignItems="center">
    //             <SquareButton
    //                 style={{
    //                     backgroundColor: '#183B68',
    //                     borderRadius: '20%',
    //                     width: '32px',
    //                     height: '32px',
    //                     textAlign: 'center',
    //                     padding: '0px 0px 0px 0px'
    //                 }}
    //                 onClick={() => handleEdit(record)}
    //             >
    //                 <EditIcon fontSize="small" style={{ color: 'white' }} />
    //             </SquareButton>
    //         </Grid>
    //     );
    //     ReactDOM.render(lnk, spn);
    //     return spn;
    // };

    const RenderIconImage = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                {value ? (
                    <img
                        src={value}
                        style={{
                            height: 30,
                            width: 25
                        }}
                    />
                ) : (
                    'No Icon Found'
                )}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleSearchStock = async (vsb, apprisalId) => {
        // let res = await getStockbookList(vsb);
        const searchQuery = `?appraisalId=${apprisalId ? apprisalId : ''}&Vehid=${''}&stockId=${vsb}`;
        history.push({
            pathname: `/Appraisal_createAppraisal`,
            search: searchQuery
        });
    };

    const randerAction = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="space-around">
                <Button size="small" onClick={() => handleSearchStock(record.vsb, record.appraisalId)}>
                    {record.appraisalId ? <EditOutlinedIcon fontSize="small" color="primary" /> : <AddIcon fontSize="small" color="primary" />}
                </Button>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        col = col.map((p) => {
            if (p.field != 'action') {
                p.events = {
                    click: function (e) {
                        props.history.push(`/UsedJob/${e.data.record.usedStockID}/${state.currentWorkflowId}?ProcessId=${ParamProcessId}`);
                    }
                };
            }
            return { ...p };
        });

        let isActionCol = col.find((element) => element.field === 'action');
        if (isActionCol) {
            isActionCol.renderer = randerAction;
        }

        // let renderImg = col.find((m) => m.field === 'icon');
        // if (renderImg) {
        //     renderImg.renderer = RenderIconImage;
        // }
        return col;
    }, [state.currentWorkflowId]);

    const buttonClicked = async (btn) => {
        setState((st) => {
            const newSt = { ...st };
            if (!newSt.hasQueryValues) {
                newSt.returnReasonID = '';
            } else {
                newSt.hasQueryValues = false;
            }
            newSt.currentWorkflowId = `${btn.id}`;
            newSt.currentWorkflowLabel = btn.label;

            return newSt;
        });
    };

    const pullWorkFlowsButtons = async () => {
        let res = await getWorkflowsByProcessID(state.processId);
        if (res.success) {
            const buttons = res.data.map((w) => ({
                id: w.id,
                label: w.workflowName,
                displayJobPriorityButton: w.displayJobPriorityButton == 'Y' ? true : false,
                displayCustomerRatingButton: w.displayCustomerRatingButton == 'Y' ? true : false
            }));

            setState((st) => ({ ...st, workflowButtons: buttons }));
        }
    };

    useEffect(() => {
        pullWorkFlowsButtons();
    }, []);

    useEffect(() => {
        const baseUrl = `UsedVehicleDashboard/List/${state.currentWorkflowId}`;
        setState((st) => ({ ...st, baseUrl: baseUrl }));
    }, [state.currentWorkflowId]);

    return (
        <div className="useSalesGrid">
            {/* <BreadCrumbs crumbs={crumbs()} /> */}
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs>
                    <BasicButtonGroup
                        margin={6}
                        buttons={state.workflowButtons}
                        onClick={buttonClicked}
                        btnSelectedId={state.currentWorkflowId || null}
                        className="left-align-buttons"
                        // triggerButtonClickOnLoad={!state.hasQueryValues}
                        allGrid={true}
                    />
                </Grid>
            </Grid>
            {/* <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res?.data?.total
                })}
                columns={column}
                getUrl={state.baseUrl}
                isReload={state.isReload}
                isShowTotal={false} 
            /> */}
            <NestedGijgoGrid
                dataConverter={(res) => ({
                    records: res?.data?.list,
                    total: res?.data?.total
                })}
                columns={column}
                getUrl={state.baseUrl}
                displayPagination={true}
                detailTemplate={detailTemplate}
                // downloadName={'Used Vehicle Report'}
            />
        </div>
    );
};

export default UsedSalesListView;
