import { get, post } from './http-calls';

export const getTickers = async () => {
    let res = await get(`Tickers`, { useAuthToken: true });
    return res;
};

export const getTickerbyId = async (id) => {
    let res = await get(`Tickers/${id}`, { useAuthToken: true });
    return res;
};

export const getTickerDelete = async (id, data) => {
    let res = await post(`Tickers/delete/${id}`, data, { useAuthToken: true });
    return res;
};

export const addTicker = async (data) => {
    let res = await post(`Tickers`, data, { useAuthToken: true });
    return res;
};
