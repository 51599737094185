import React from 'react';
import { Grid } from '@material-ui/core';
import { TextBoxShrink } from '../../../../Core/FormInput';
import { get } from 'lodash';

export const BasicFooter = React.memo((props) => {
    const { state, setState } = props;
    const setField = (e) => {
        const { name, type, value, valueAsNumber, checked } = e.target;
        if (type === 'number') {
            setState((st) => ({ ...st, [name]: valueAsNumber }));
        } else if (type === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };
    const getField = (name) => {
        return get(state, name);
    };
    return (
        <Grid container spacing={1} item xs={12}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    type="Date"
                    onChange={setField}
                    value={getField('service24VehicleMobile')}
                    label="Vehicle Mobile Date"
                    name="service24VehicleMobile"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    type="Date"
                    onChange={setField}
                    value={getField('service24CACAdvised')}
                    label="CAC Advised Date"
                    name="service24CACAdvised"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
        </Grid>
    );
});
