import React, { useEffect, useState } from 'react';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { getWorkflowSteps } from '../../../Core/Service/workflow-service';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import AddWorkflowStep from './AddWorkflowSteps';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { SecondaryButton } from '../../../Core/FormInput/index';
import Grid from '@material-ui/core/Grid';
import DataGridComp from '../../../Core/Grid';

const WorkflowSteps = (props) => {
    const [state, setState] = useState({
        rows: [],
        totalRec: 0,
        show: false,
        WorkflowStepDetails: [],
        WorkflowName: ''
    });

    useEffect(async () => {
        pullWorkflowStepsAndUpdateState();
    }, []);
    const getClass = (params) => {
        if (params.row.isDeleted) {
            return 'Deleted-record';
        }
    };
    const column_new = [
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 90,
            renderCell: (params) => <Arrow value={params} />
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 130,
            sortable: false,
            hide: true
        },
        { field: 'ocrDocumentId', headerName: '', flex: 1, hide: true },
        { field: 'stepIndex', headerName: 'Index', flex: 0.5 },
        { field: 'stepLabel', headerName: 'Label', flex: 1.5 },
        { field: 'stepListName', headerName: 'List Name', flex: 1 },
        { field: 'ocrDocumentName', headerName: 'Document Name', flex: 1 },
        {
            field: 'isEditable',
            headerName: 'Editable',
            flex: 0.6,
            valueGetter: (params) => (params.value ? 'Yes' : 'No')
        },
        {
            field: 'isRequired',
            headerName: 'Required',
            flex: 0.6,
            valueGetter: (params) => (params.value ? 'Yes' : 'No')
        },
        {
            field: 'isVisible_Customer',
            headerName: 'Is Visible To Customer',
            flex: 1,
            valueGetter: (params) => (params.value ? 'Yes' : 'No')
        },
        {
            field: 'isVisible_Auditor',
            headerName: 'Is Visible To Auditor',
            flex: 1,
            valueGetter: (params) => (params.value ? 'Yes' : 'No')
        }
    ].map((c) => ({ ...c, cellClassName: getClass }));
    const workflowID = props.match.params.workflowId;
    const pullWorkflowStepsAndUpdateState = async () => {
        getWorkflowSteps(workflowID, '', 'Y').then((res) => {
            if (res.success) {
                const results = res.data.steps.map((row) => ({
                    ...row,
                    id: row.stepId
                }));
                setState({
                    rows: results,
                    WorkflowName: res.data.workflowName
                });
            }
        });
    };
    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                workflowId: workflowID,
                WorkflowStepDetails: params.value.row
            };
            return nst;
        });
    };
    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Workflow" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No record found, for this workflow</div>
            </GridOverlay>
        );
    }
    const handleAdd = (params) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                workflowId: workflowID,
                WorkflowStepDetails: null
            };
        });
    };
    const closeUpdateUser = (refreshGrid) => {
        setState((st) => ({ ...st, show: false }));
        refreshGrid && pullWorkflowStepsAndUpdateState();
    };

    return (
        <>
            {state.show ? (
                <AddWorkflowStep onFormSubmit={closeUpdateUser} WorkflowStepDetails={state.WorkflowStepDetails} workflowId={state.workflowId} />
            ) : null}
            <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                Add Workflow Step
            </SecondaryButton>
            <Breadcrumbs separator=">>" aria-label="breadcrumb" style={{ margin: '14px', fontSize: '0.85rem' }}>
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Link color="inherit" to={'/workflow'}>
                    Workflow
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    {' '}
                    Workflow Steps
                </Typography>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    {state.WorkflowName}
                </Typography>
            </Breadcrumbs>
            <Grid container className="roots">
                <Grid item xs={12} sm={12}>
                    <div
                        style={{
                            height: 'calc(100vh - 180px)',
                            width: '98.9%',
                            margin: '10px',
                            marginTop: '-20px'
                        }}
                    >
                        <DataGridComp
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            rows={state.rows}
                            // headerHeight={30}
                            // rowHeight={30}
                            hideFooter={true}
                            columns={column_new}
                            // pageSize={100}
                            enableSearch={true}
                            offset={300}
                            // disableColumnMenu={true}
                            // showColumnRightBorder={true}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
};
export default WorkflowSteps;
