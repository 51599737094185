import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FormControlLabel, Grid } from '@material-ui/core';
import { SecondaryButton, SecondaryCheckbox } from '../../../Core/FormInput';
import { makeStyles } from '@material-ui/styles';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { GetUserPreferenceWorkflowsList, saveSwapUserPreferenceWorkflows } from '../../../Core/Service/dashbordService';
import AppContext from '../../../App/AppContext';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#f5f5f5',
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 110,
        padding: '5px 0px',
        margin: 3,
        borderRadius: 3
    },
    workflowCardContainer: {
        flex: 1
    }
}));

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    padding: grid,
    // width: 250,
    display: 'flex'
});

function spliceIntoChunks(arr, chunkSize) {
    const res = [];
    while (arr.length > 0) {
        const chunk = arr.splice(0, chunkSize);
        res.push(chunk);
    }
    return res;
}
const arr = [1, 2, 3, 4, 5, 6, 7, 8];

const chunkIntoN = (arr, n) => {
    const size = Math.ceil(arr.length / n);
    return Array.from({ length: n }, (v, i) => arr.slice(i * size, i * size + size));
};

const WorkFlowSettingComponent = (props) => {
    const [WindowWidths, WindowHeights] = useWindowSize();
    const { hideModal, showToast } = useContext(AppContext);

    const classes = useStyles(props.isShowScrollArrow);
    const [state, setState] = useState({
        items: []
    });

    useEffect(async () => {
        let sortedArray = [...spliceIntoChunks([...props.WorkflowsList], 2)];

        setState((st) => ({
            ...st,
            items: sortedArray
        }));
    }, [props.WorkflowsList]);

    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(state.items[sInd], source.index, destination.index);
            const newState = { ...state };
            newState.items[sInd] = items;
            setState(newState);
            props.getWorkFlowSequenceList([...newState.items].flat(1));
        } else {
            const result = move(state.items[sInd], state.items[dInd], source, destination);
            const newState = [...state.items];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];
            let sortedArray = [...spliceIntoChunks([...newState].flat(1), 2)];
            setState({ items: sortedArray.filter((group) => group.length) });
            props.getWorkFlowSequenceList([...newState].filter((group) => group.length).flat(1));
        }
    }

    const handleCheck = (mainInd, ChildIndex) => (e) => {
        const { name, checked } = e.target;
        const tempItems = state.items;
        tempItems[mainInd][ChildIndex].active = checked;
        setState((st) => ({
            ...st,
            items: tempItems
        }));
    };

    const handleSubmit = async () => {
        let res = await saveSwapUserPreferenceWorkflows({
            workflowtb: state.items.flat(1).map((p, i) => ({
                workflowId: p.id,
                workflowActive: p.active,
                workflowSequence: i
            }))
        });

        if (res.data.success) {
            showToast(res.data.message);
            props.handleCancel(true);
        } else {
            console.error(res.message);
        }
    };

    return (
        <Grid container direction="column" style={{ height: '400px', width: '100%', overflow: 'auto' }}>
            <DragDropContext onDragEnd={onDragEnd}>
                {state.items.map((el, ind) => (
                    <Droppable key={ind} droppableId={`${ind}`} direction="horizontal">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                                {el.map((item, index) => (
                                    <Draggable key={`${item.name}-${index}`} draggableId={`${item.name}-${index}`} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={classes.workflowCardContainer}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <SecondaryCheckbox checked={item.active} onChange={handleCheck(ind, index)} name={item.fieldName} />
                                                    }
                                                    label={item.name}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                ))}
            </DragDropContext>
        </Grid>
    );
};

export default WorkFlowSettingComponent;
