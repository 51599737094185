import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import AppContext from '../../../../App/AppContext';
import Typography from '@material-ui/core/Typography';
import SignaturePad from 'react-signature-canvas';
import { TextBox, SecondaryButton, CustomButton, DialogContents } from '../../../../Core/FormInput';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';
import AddBox from '@material-ui/icons/AddBox';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { BrakeDiscPadProvider, BrakeDiscPadScreenContext } from './BrakeDiscPadWearProvider';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CircularProgress, InputAdornment, Tooltip } from '@material-ui/core';
import { Backup, Visibility } from '@material-ui/icons';
import ViewAxel from './ViewAxel';
import { CustomeSignPad } from '../../../../Core/SignaturePad';

const GlobalCss = withStyles({
    '@global': {
        '.MuiOutlinedInput-input': {
            padding: '9.5px 4px'
        },
        '.MuiFormLabel-root': {
            fontSize: '14px',
            color: 'black',
            fontWeight: '50'
        }
    }
})(() => null);

const useStyles = makeStyles((theme) => ({
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    box_container: {
        height: '120px',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        display: 'block',
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
        color: 'red',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    Leadersign: {
        border: '1px solid black',
        width: '100%',
        height: ' 130px'
    }
}));

const StyleInput = (props) => {
    const classes = useStyles();
    return (
        <TextBox
            className={classes.input}
            // placeholder="mm"
            type="number"
            {...props}
            InputProps={{
                endAdornment: <InputAdornment position="start">mm</InputAdornment>
            }}
        />
    );
};

const getStripedStyle = (index) => {
    return { background: index % 2 ? 'rgb(151 133 133 / 6%)' : 'rgb(158 158 158 / 20%)' };
};
const BrakeDiscPadWear = (props) => {
    const {
        state,
        useDefaultSig,
        handleAddClick,
        handleRemoveClick,
        handleInputChange,
        inputList,
        fieldChange,
        handleSubmit,
        handleImage,
        showImage,
        hideImage,
        RemoveImage,
        signChange,
        clearSig,
        btnLoader,
        isLoader
    } = useContext(BrakeDiscPadScreenContext);
    const { hideTopNavAndMenu, userRoles } = useContext(AppContext);
    const isAuditor = userRoles.includes('auditor');
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const fromStep = urlParams.get('fromstep') === 'true';

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);
    const classes = useStyles();

    const [widths] = useWindowSize();
    const tabView = widths < 1350 ? true : false;

    return (
        <div style={{ width: '100%' }}>
            <GlobalCss />
            <DialogContents>
                {isLoader ? (
                    <Grid container item xs={12} sm={12} justifyContent="center" alignItems="center" style={{ height: 'calc(100vh - 108px)' }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid container item xs={12} sm={12} spacing={1}>
                            <Grid item xs={7} sm={7} lg={10} style={{ fontSize: 21, textAlign: 'left' }}>
                                Reg No:&nbsp; <b> {state.headerRecords?.registration}</b>&nbsp; WIP No:&nbsp; <b> {state.headerRecords?.wipNumber}</b>
                            </Grid>
                            <Grid item xs={5} sm={5} md={2}>
                                <FormLabel component="legend" className={`${classes.brakedisc_form} mandatoryfields`}>
                                    Date
                                </FormLabel>
                                <TextBox
                                    id="date"
                                    type="date"
                                    name="brakeReportDate"
                                    value={state.brakeReportDate}
                                    autoComplete="new-password"
                                    onChange={fieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    disabled={state.isTechReadonly}
                                />
                                <span className="mandatoryfields" style={{ fontSize: '14px' }}>
                                    {state.DatendSign['brakeReportDate']}
                                </span>
                            </Grid>
                            {inputList.map((x, i) => (
                                <Grid item xs={12} container justify="space-around" style={{ ...getStripedStyle(i) }}>
                                    <Grid
                                        item
                                        xs={2}
                                        sm={2}
                                        md={1}
                                        container
                                        //  alignItems={`${tabView ? 'flex-start' : 'flex-end'}`}
                                    >
                                        <Grid item container>
                                            <Typography color="secondary">&nbsp;</Typography>
                                        </Grid>
                                        <Grid item container spacing={1} xs={12}>
                                            <FormLabel> Axle Position</FormLabel>
                                            <TextBox
                                                name="brakeReportItemAxlePosition"
                                                autoFocus={i == 0 ? true : false}
                                                value={x.brakeReportItemAxlePosition}
                                                onChange={(e) => handleInputChange(e, i)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={10} sm={10} md={11}>
                                        <Grid item container spacing={1} xs={12} sm={12} md={12} lg={6} justify="space-around">
                                            <Grid item container>
                                                <Typography color="secondary">NEARSIDE</Typography>
                                            </Grid>
                                            <Grid item container spacing={1} xs={12} sm={6}>
                                                <Grid item xs={4} sm={4} lg={4}>
                                                    <FormLabel> Brake Pad Wear</FormLabel>
                                                    <StyleInput
                                                        name="brakeReportItemNearSideBrakePadWear"
                                                        value={x.brakeReportItemNearSideBrakePadWear}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={4} lg={4}>
                                                    <FormLabel>Disc Thickness</FormLabel>
                                                    <StyleInput
                                                        name="brakeReportItemNearSideBrakeDiscThickness"
                                                        value={x.brakeReportItemNearSideBrakeDiscThickness}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={4} lg={4}>
                                                    <FormLabel>Disc Limit</FormLabel>
                                                    <StyleInput
                                                        name="brakeReportItemNearSideBrakeDiscThicknessLimit"
                                                        value={x.brakeReportItemNearSideBrakeDiscThicknessLimit}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item container spacing={1} justify="flex-start" xs={12} sm={6}>
                                                <Grid item xs={11} sm={11} lg={11}>
                                                    <FormLabel> Reason for renewal</FormLabel>
                                                    <TextBox
                                                        name="brakeReportItemNearSideReasonForRenewal"
                                                        value={x.brakeReportItemNearSideReasonForRenewal}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} sm={1} lg={1} style={{ float: 'left', textAlign: 'center', marginTop: 15 }}>
                                                    <FormLabel>&nbsp;</FormLabel>
                                                    {x.brakeReportItemNearSidePhoto ? (
                                                        <Tooltip title="View Document">
                                                            <label>
                                                                <Visibility
                                                                    style={{ fontSize: '25px' }}
                                                                    color="secondary"
                                                                    onClick={(e) => showImage(x.brakeReportItemNearSidePhoto)}
                                                                />
                                                            </label>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Upload Document">
                                                            <label>
                                                                <Backup color="secondary" style={{ fontSize: '25px' }} />
                                                                <input
                                                                    name="brakeReportItemNearSidePhoto"
                                                                    type="file"
                                                                    onChange={(e) => handleImage(e, i)}
                                                                    style={{ display: 'none' }}
                                                                    accept="image/*"
                                                                />
                                                            </label>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item container spacing={1} xs={12} sm={12} md={12} lg={6} justify="space-around">
                                            <Grid item container>
                                                <Typography color="secondary">OFFSIDE</Typography>
                                            </Grid>
                                            <Grid item container spacing={1} xs={12} sm={6}>
                                                <Grid item xs={4} sm={4} lg={4}>
                                                    <FormLabel> Brake Pad Wear</FormLabel>
                                                    <StyleInput
                                                        name="brakeReportItemOffSideBrakePadWear"
                                                        value={x.brakeReportItemOffSideBrakePadWear}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={4} lg={4}>
                                                    <FormLabel>Disc Thickness</FormLabel>
                                                    <StyleInput
                                                        name="brakeReportItemOffSideBrakeDiscThickness"
                                                        value={x.brakeReportItemOffSideBrakeDiscThickness}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={4} lg={4}>
                                                    <FormLabel>Disc Limit</FormLabel>
                                                    <StyleInput
                                                        name="brakeReportItemOffSideBrakeDiscThicknessLimit"
                                                        value={x.brakeReportItemOffSideBrakeDiscThicknessLimit}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                spacing={1}
                                                justify="flex-start"
                                                xs={12}
                                                sm={6}
                                                style={{ marginTop: state.showAdd ? -15 : -5 }}
                                                alignItems={`${tabView ? 'flex-start' : 'center'}`}
                                            >
                                                <Grid item xs={11}>
                                                    <FormLabel style={{ marginTop: 8 }}>Reason for renewal &nbsp;</FormLabel>
                                                    {inputList.length !== 1 && state.showAdd && (
                                                        <CustomButton
                                                            IconColor="#3f51b5"
                                                            icon={RemoveCircle}
                                                            onClick={() => handleRemoveClick(i)}
                                                            toolTipTitle="Remove Row"
                                                        />
                                                    )}
                                                    {inputList.length - 1 === i && state.showAdd && (
                                                        <CustomButton icon={AddBox} onClick={handleAddClick} toolTipTitle="Add Row" />
                                                    )}
                                                    <TextBox
                                                        style={{ marginTop: -2 }}
                                                        name="brakeReportItemOffSideReasonForRenewal"
                                                        value={x.brakeReportItemOffSideReasonForRenewal}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} style={{ textAlign: 'center', marginTop: 25 }}>
                                                    <FormLabel>&nbsp;</FormLabel>
                                                    {x.brakeReportItemOffSidePhoto ? (
                                                        <Tooltip title="View Document">
                                                            <label>
                                                                <Visibility
                                                                    color="secondary"
                                                                    style={{ fontSize: '25px' }}
                                                                    onClick={(e) => showImage(x.brakeReportItemOffSidePhoto)}
                                                                />
                                                            </label>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Upload Document">
                                                            <label>
                                                                <Backup color="secondary" style={{ fontSize: '25px' }} />
                                                                <input
                                                                    name="brakeReportItemOffSidePhoto"
                                                                    type="file"
                                                                    onChange={(e) => handleImage(e, i)}
                                                                    style={{ display: 'none' }}
                                                                    accept="image/*"
                                                                />
                                                            </label>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container style={{ float: 'left', fontSize: '14px' }} justify="flex-start">
                                        {state.errors && i == 0 && <span className="mandatoryfields">{Object.values(state.errors)[0]}</span>}
                                    </Grid>
                                </Grid>
                            ))}

                            <Grid container spacing={2} alignItems="flex-end" style={{ padding: '3px 0px ' }}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <FormLabel>{'\u{2800}'}</FormLabel>
                                    <span style={{ visibility: 'hidden' }}>
                                        <CustomButton icon={BorderColorIcon} toolTipTitle="Use default signature" />
                                    </span>
                                    <div className={classes.box_container}>
                                        <Grid item container spacing={1} style={{ padding: '10px' }}>
                                            <Typography variant="body2">
                                                Warranty / R&M and many other companies require brake pad measurements in MM, please be sure to put readings
                                                down.
                                            </Typography>
                                            <Typography display="block" variant="body2">
                                                Also, the wear level & wear limit of the discs should always be filled in when replacing. This is always in MM.
                                            </Typography>
                                        </Grid>
                                    </div>
                                </Grid>
                                {!isAuditor && (
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomeSignPad
                                            onChange={signChange}
                                            content="Signature"
                                            sign={state.hideSign}
                                            clearSig={clearSig}
                                            name="techniciansign"
                                            hideBtn={false}
                                        />
                                        <span className="mandatoryfields" style={{ fontSize: '14px' }}>
                                            {state.DatendSign['techniciansign']}
                                        </span>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <SecondaryButton isBtnLoader={btnLoader} className="Submitbtn" onClick={handleSubmit} style={{ marginTop: 8 }} disabled={isAuditor}>
                            Save
                        </SecondaryButton>
                    </>
                )}
            </DialogContents>
            {state.showLogo && <ViewAxel onFormSubmit={hideImage} logoValue={state.logoValue} RemoveImage={RemoveImage} />}
        </div>
    );
};
const Screen = (props) => {
    return (
        <BrakeDiscPadProvider {...props}>
            <BrakeDiscPadWear {...props} />
        </BrakeDiscPadProvider>
    );
};
export default Screen;
