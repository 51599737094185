import { createContext, useCallback, useEffect, useState, useRef, useContext } from 'react';
import AppContext from '../../../App/AppContext';
import { getDefectByID, defectPostdata, DeleteDocByDocID } from '../../../Core/Service/defectTech-service';
import { find } from 'lodash';
import _ from 'lodash';
import { AppStorage } from '../../../Core/Service/storage-service';
import { arrayExpression } from '@babel/types';
import getMultiImgResized from '../../../Core/CommonFunctions/multiImgResizer';
import moment from 'moment';

export const DefectScreenContext = createContext();

export const DefectProvider = (props) => {
    const { hideModal, showToast, hideTopNavAndMenu, userRoles } = useContext(AppContext);
    const isTechnician = userRoles.includes('technician');
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const [inputList, setInputList] = useState([
        {
            defectDescription: '',
            defectReportedCategoryID: '',
            defectSignature: null,
            defectActionedCategoryID: '',
            isRequiredBeforeMOTTest: false,
            checkDefectCreatedUserSignature: false
        }
    ]);

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const signCanvas = useRef({});
    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: null,
                techniciansign: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };
    const [state, setState] = useState({
        technicians: [],
        defectActionedCategories: [],
        techniciansign: null,
        imgtechniciansign: null,
        wipNumber: '',
        registration: '',
        id: null,
        errors: {},
        showCircularLoader: true,
        technicianNotesJobID: props.match.params?.technicianJobId,
        defectReportedCategories: [],
        defectHeaderRecordID: props.match.params.jobId || null,
        headerRecords: {},
        userID: null,
        flag: true,
        createdUser: null,
        defectDateCreated: null,
        defectDoctb: [],
        imgDateCreated: null,
        sortedImgList: []
    });

    const handleValidation = (val) => {
        let errors = {};
        const list = [...inputList];
        let formIsValid = true;
        if (val === 'submit') {
            if (list[0]['defectDescription'] === '') {
                formIsValid = false;
                errors['defectDescription'] = 'Description cannot be empty';
            }
            if (!state.techniciansign) {
                formIsValid = false;
                errors.sign = 'Technician Signature is required';
            }
            list.forEach((i) => {
                if (!i.defectReportedCategoryID && i.defectDescription) {
                    i.err_desc = 'Description cannot be empty';
                    formIsValid = false;
                } else {
                    i.err_desc = '';
                }
            });
            setInputList(list);
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const Id = props.match.params.jobId;

    const handleBtn = (val, id, name) => {
        let changedArray = [...inputList];
        changedArray[id][name] = val[name];
        setInputList(changedArray);
    };

    const TechBtnClick = (userID) => {
        setState((newSt) => {
            let st = { ...newSt };
            if (userID === st.logedInuserID) {
                st.flag = true;
            } else st.flag = false;
            if (st.activeUser === userID) {
                st.showCircularLoader = false;
            } else {
                st.showCircularLoader = true;
            }
            st.activeUser = userID;
            st.userID = userID;
            return st;
        });
    };

    const useDefaultSig = () => {
        let userSign = AppStorage.getSignature();
        if (userSign) {
            signCanvas.current.fromDataURL(AppStorage.getSignature());
            setState((st) => ({ ...st, techniciansign: AppStorage.getSignature() }));
        } else {
            showToast('User signature not found');
        }
    };

    useEffect(async () => {
        if (Id) {
            let res = await getDefectByID(Id, state.userID);
            if (res.success) {
                const btnList = res.data.defectReportedCategories;
                if (res.data.defects) {
                    setInputList(
                        !res.data.defects.length
                            ? [
                                  {
                                      defectDescription: '',
                                      defectReportedCategoryID: '',
                                      defectSignature: null,
                                      defectActionedCategoryID: '',
                                      dateCreated: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                                      isRequiredBeforeMOTTest: false,

                                      checkDefectCreatedUserSignature: false
                                  }
                              ]
                            : res.data.defects.map((m) => ({ ...m, checkDefectCreatedUserSignature: false }))
                    );
                }

                let header = res.data.headerRecords[0];
                const rw = find(res.data.technician, (t) => t.userID === header.loginUserId);
                let techArr = [...res.data.technician];
                if (isTechnician) {
                    if (!rw) {
                        techArr.unshift({ userID: header.loginUserId, technicianName: header.loginUserName });
                    }
                    if (header.loginUserId !== techArr[0].userID) {
                        techArr.map((m) => {
                            if (m.userID === techArr[0].userID) {
                                techArr.splice(0, 0, { userID: header.loginUserId, technicianName: header.loginUserName });
                            }
                        });
                    }
                }
                let CurrentUser = techArr[0]?.userID;
                var arrayOfObjAfter = _.uniqBy(techArr, 'userID');

                setState((st) => {
                    let newSt = { ...st };
                    newSt.defectDoctb = res.data?.defectDoctb || [];
                    // if (!newSt.activeUser) {
                    //     newSt.activeUser = CurrentUser;
                    //     newSt.flag = true;
                    // }
                    newSt.defectReportedCategories = btnList;
                    newSt.defectActionedCategories = res.data?.defectActionedCategories;
                    newSt.showCircularLoader = false;
                    // newSt.imgtechniciansign = res.data.defects[0]?.defectSignature || null;
                    // newSt.techniciansign = res.data.defects[0]?.defectSignature || null;
                    newSt.createdUser = res.data.defects[0]?.createdUser || null;
                    newSt.defectDateCreated = res.data.defects[0]?.defectDateCreated || null;
                    newSt.technicians = arrayOfObjAfter;
                    newSt.headerRecords = res.data.headerRecords[0];
                    newSt.logedInuserID = header.loginUserId;
                    return newSt;
                });
            }
        }
    }, [state.userID]);

    const handleSubmit = async (val) => {
        const defectVM = inputList.map((m) => ({
            userSignature: m.checkDefectCreatedUserSignature ? state.techniciansign : m.defectSignature,
            defectID: m.defectID || null,
            description: m.defectDescription,
            reportedCategoryID: m.defectReportedCategoryID,
            actionedCategoryID: m.defectActionedCategoryID,
            dateCreated: m.dateCreated,
            isRequiredBeforeMOTTest: m.isRequiredBeforeMOTTest || false
        }));
        if (handleValidation(val)) {
            let data = {
                defectHeaderRecordID: state.defectHeaderRecordID,
                mode: 'A',
                defectVM: defectVM,
                defectDoctb: state.defectDoctb
                    .filter((p) => {
                        if (p.isNewDoc) {
                            return p.isNewDoc;
                        }
                    })
                    .map((q) => {
                        let { docFile, ...rest } = q;
                        return { ...rest, documentImage: docFile };
                    })
            };

            let res = await defectPostdata(data);
            if (res.success) {
                showToast(res.message);
                if (fromStep) {
                    setTimeout(() => {
                        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    }, 1000);
                }
            } else {
                showToast(res.error);
            }
        }
    };
    const GetTechnicianSign = () => {
        setState((st) => {
            const nst = {
                ...st,
                techniciansign: signCanvas.current.toDataURL()
            };
            return nst;
        });
    };

    const onImageRemove = async (data) => {
        let tempData = [...state.sortedImgList];
        let imgList = [...state.defectDoctb];

        let objIndex = tempData.findIndex((x) => x.documentDateCreated === data.documentDateCreated);

        if (!data.isNewDoc) {
            let res = await DeleteDocByDocID(state.defectHeaderRecordID, data.documentID);
            if (res.success) {
                showToast(res.data.message);
            }
        }

        if (objIndex > -1) {
            tempData.splice(objIndex, 1);
            let tempInputList = [...inputList];
            let defectObjIndex = tempInputList.findIndex((a) => a.dateCreated == state.selectedDefect?.dateCreated);
            tempInputList[defectObjIndex].documentsCount = tempData.length || 0;

            let imgIndex = imgList.findIndex((b) => b.documentDateCreated == state.selectedDefect?.dateCreated);
            imgList.splice(imgIndex, 1);
            setInputList(tempInputList);
        }
        setState((st) => {
            return { ...st, sortedImgList: tempData, defectDoctb: imgList };
        });
    };
    // moment().format('YYYY-MM-DDTHH:mm:ss.SSS')

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (err) => reject(err);
        });

    const uploadFiles = async (e, arrName) => {
        const selectedFileList = await Promise.allSettled(
            Array.from(e.target.files).map(async (imageInput) => {
                if (imageInput) {
                    const docFile = await getBase64(imageInput);
                    return {
                        documentID: null,
                        documentDefectID: state.selectedDefect?.defectID || null,
                        docFile,
                        documentDateCreated: state.selectedDefect.dateCreated,
                        isNewDoc: true
                    };
                }
                return null;
            })
        );
        const successfulFiles = selectedFileList.filter((result) => result.status === 'fulfilled').map((result) => result.value);
        let combineList = [...state.sortedImgList, ...successfulFiles];
        let tempInputList = [...inputList];
        let objIndex = tempInputList.findIndex((a) => a.defectID == state.selectedDefect?.defectID);
        tempInputList[objIndex].documentsCount = combineList.length || 0;

        setInputList(tempInputList);

        setState((prevState) => ({
            ...prevState,
            isUploading: false,
            sortedImgList: combineList,
            defectDoctb: [...state.defectDoctb, ...state.sortedImgList, ...successfulFiles]
        }));
    };

    const docAction = (x, index) => {
        let sortedImgList = [];

        if (x.dateCreated) {
            sortedImgList = state.defectDoctb
                .filter((a) => {
                    if (a.documentDateCreated == x.dateCreated) {
                        return a;
                    }
                })
                .map((p) => ({ ...p, docFile: p.documentImage || p.docFile }));
        }

        setState((prevState) => ({
            ...prevState,
            showDoc: true,
            sortedImgList: sortedImgList || [],
            selectedDefect: x
        }));
    };

    const onCloseDocModal = () => {
        setState((st) => ({
            ...st,
            showDoc: false,
            imageList: []
        }));
        !state.selectedDefect.defectSignature && showToast('Make sure to click the "Save" or "Sign-Off" button to upload images');
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                defectDescription: '',
                defectReportedCategoryID: '',
                dateCreated: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                isRequiredBeforeMOTTest: false,
                checkDefectCreatedUserSignature: false
            }
        ]);
    };

    const handleCheck = (event, index) => {
        const { name, checked } = event.target;
        const list = [...inputList];
        if (index == 'all') {
            let newList = list.map((k) => ({
                ...k,
                checkDefectCreatedUserSignature: k.defectSignedUserID ? false : checked
            }));
            setInputList(newList);
        } else {
            list[index][name] = checked;
            setInputList(list);
        }
    };

    const showModal = (value) => {
        setState((st) => ({
            ...st,
            showSignature: true,
            viewSignObj: value
        }));
    };

    const closeDialog = () => {
        setState((st) => ({
            ...st,
            showSignature: false
        }));
    };
    return (
        <DefectScreenContext.Provider
            value={{
                signCanvas,
                state,
                fromStep,
                inputList,
                clear,
                GetTechnicianSign,
                handleSubmit,
                handleAddClick,
                handleRemoveClick,
                handleInputChange,
                TechBtnClick,
                handleBtn,
                hideModal,
                useDefaultSig,
                isTechnician,
                handleCheck,
                showModal,
                closeDialog,
                docAction,
                uploadFiles,
                onImageRemove,
                onCloseDocModal
            }}
        >
            {props.children}
        </DefectScreenContext.Provider>
    );
};
