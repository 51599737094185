import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import './salesDashboardSetting.scss';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { useEffect } from 'react';
import { getNewStockbyID, getUsedStockabyID } from '../../../../../Core/Service/SalesPerformance-service';
import { ContactlessOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { SelectBox } from '../../../../../Core/FormInput';
import { Card, CircularProgress, Grid, Hidden } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        minWidth: 1280
    },
    table1: {
        minWidth: '100%'
    }
});

const TableList = [
    { id: 1, name: '1-60 Days' },
    { id: 2, name: '61-90 Days' },
    { id: 3, name: '91-120 Days' },
    { id: 4, name: '121-150 Days' },
    { id: 5, name: '150+ Days' },
    { id: 6, name: 'Reserved ' },
    { id: 7, name: 'Unsold ' },
    { id: 8, name: 'Ordered' },
    { id: 9, name: 'Consigned' },
    { id: 10, name: 'Fully Paid' }
];

const stockTableObject = {
    1: {
        profit: 'totalCount0to60',
        unit: 'totalUnit0to60'
    },
    2: {
        profit: 'totalCount61to90',
        unit: 'totalUnit61to90'
    },
    3: {
        profit: 'totalCount91to120',
        unit: 'totalUnit91to120'
    },
    4: {
        profit: 'totalCount121to150',
        unit: 'totalUnit121to150'
    },
    5: {
        profit: 'totalCount150Plus',
        unit: 'totalUnit150Plus'
    },
    6: {
        profit: 'reservedProfit',
        unit: 'reservedUnit'
    },
    7: {
        profit: 'unsoldProfit',
        unit: 'unsoldUnit'
    },
    8: {
        profit: 'orderedProfit',
        unit: 'orderedUnits'
    },
    9: {
        profit: 'consignedProfit',
        unit: 'consignedUnits'
    },
    10: {
        profit: 'finishedProfit',
        unit: 'finishedUnits'
    }
};

const ManiHederCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'grey',
        fontSize: 16,
        borderRight: '1px solid white',
        color: theme.palette.common.white,
        padding: '10px'
    }
}))(TableCell);
const StyleCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#f5f5f5',
        fontSize: 16,
        borderRight: '1px solid rgba(0,0,0,0.1)',
        color: theme.palette.common.black,
        padding: '10px'
    },
    body: {
        fontSize: 14,
        fontWeight: 500,
        borderRight: '1px solid rgba(0,0,0,0.1)',
        padding: '10px 10px'
    }
}))(TableCell);

export default function StockTable(props) {
    const { list } = props;
    const classes = useStyles();
    const history = useHistory();
    const [state, setState] = React.useState({
        setValueForStock: 1,
        selectedProfit: '',
        selectedUnit: '',
        selectedTableLabel: ''
    });

    useEffect(() => {
        if (state.setValueForStock) {
            let label = TableList.find((k) => k.id == state.setValueForStock);
            setState((st) => ({
                ...st,
                selectedProfit: stockTableObject[state.setValueForStock].profit,
                selectedUnit: stockTableObject[state.setValueForStock].unit,
                selectedTableLabel: label.name
            }));
        }
    }, [state.setValueForStock]);

    const handleStock = (val, id, ageRange) => {
        let searchQuery = `${ageRange ? `&ageRange=${ageRange}` : ''}${val ? `&Franchise=${val}` : ''}`;
        switch (id) {
            case 1:
                history.push({
                    pathname: `/salesPefromance/newStock`,
                    search: searchQuery
                });
                break;
            case 2:
                history.push({
                    pathname: `/salesPefromance/usedStock`,
                    search: searchQuery
                });
                break;
        }
    };

    const handleDropDown = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    return (
        <div>
            {list.showLoader ? (
                <Grid container style={{ height: 500 }} justifyContent="center" alignContent="center">
                    <CircularProgress color="secondary" />
                </Grid>
            ) : (
                <Grid container spacing={2} justifyContent="flex-start">
                    <div
                        style={{
                            margin: '5px 10px 0px 10px',
                            textAlign: 'left',
                            fontSize: 20,
                            fontWeight: 500
                        }}
                    >
                        STOCKING COST ESTIMATE
                    </div>

                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent="flex-start">
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card className={classes.root}>
                                    <div
                                        style={{
                                            paddingLeft: 10,
                                            padding: '10px',
                                            textAlign: 'left'
                                        }}
                                    >
                                        <span className="lableFont">Avrage Days in Stock</span>
                                        <div className="valueFont">
                                            <div className="valueFont">{list?.costEstimate?.avgDayInStock} </div>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card className={classes.root}>
                                    <div
                                        style={{
                                            paddingLeft: 10,
                                            padding: '10px',

                                            textAlign: 'left'
                                        }}
                                    >
                                        <span className="lableFont"> Interest incurred (Stock)</span>
                                        <div className="valueFont">{list?.costEstimate?.intrestInacurred_Stock} </div>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card className={classes.root}>
                                    <div
                                        style={{
                                            paddingLeft: 10,
                                            padding: '10px',
                                            textAlign: 'left'
                                        }}
                                    >
                                        <span className="lableFont">Interest incurred (Order Take) </span>
                                        <div className="valueFont">{list?.costEstimate?.intrestInacurred_OrderTAke} </div>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} style={{ height: 'calc(100vh - 320px)' }}>
                            <Hidden only={['sm', 'xs']}>
                                <Table className={classes.table} aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <ManiHederCell colSpan={6}>{props.id === 1 ? 'NEW' : 'USED'} STOCK</ManiHederCell>
                                            <ManiHederCell align="center" colSpan={2}>
                                                PROFIT
                                            </ManiHederCell>
                                            <ManiHederCell align="center" colSpan={3}>
                                                STATUS
                                            </ManiHederCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableHead>
                                        <TableRow>
                                            <StyleCell>Franchise</StyleCell>
                                            <StyleCell align="center">
                                                <span className="lableFont"> 1-60 Days </span>
                                            </StyleCell>
                                            <StyleCell align="center">
                                                <span className="lableFont"> 61-90 Days </span>
                                            </StyleCell>
                                            <StyleCell align="center">
                                                <span className="lableFont"> 91-120 Days </span>
                                            </StyleCell>
                                            <StyleCell align="center">
                                                <span className="lableFont">121-150 Days </span>
                                            </StyleCell>
                                            <StyleCell align="center">
                                                <span className="lableFont"> 150+ Days </span>
                                            </StyleCell>
                                            <StyleCell align="center">
                                                <span className="lableFont"> Reserved </span>
                                            </StyleCell>
                                            <StyleCell align="center">
                                                <span className="lableFont"> Unsold </span>
                                            </StyleCell>
                                            <StyleCell align="center">
                                                <span className="lableFont"> Ordered</span>
                                            </StyleCell>
                                            <StyleCell align="center">
                                                <span className="lableFont"> Consigned</span>
                                            </StyleCell>
                                            <StyleCell align="center">
                                                <span className="lableFont"> Fully Paid </span>
                                            </StyleCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list?.stock.map((k, i) => (
                                            <TableRow key={i} hover>
                                                <StyleCell align="left">
                                                    <div className="">{k.fn}</div>
                                                </StyleCell>
                                                <StyleCell align="center" onClick={() => handleStock(k.franchise, props.id, 1)} className="CursorHov">
                                                    <div>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(k.totalCount0to60)} / &nbsp;
                                                        <small style={{ fontSize: 12 }}>{k.totalUnit0to60}</small>
                                                    </div>
                                                </StyleCell>
                                                <StyleCell align="center" onClick={() => handleStock(k.franchise, props.id, 2)} className="CursorHov">
                                                    <div>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(k.totalCount61to90)} / &nbsp;
                                                        <small style={{ fontSize: 12 }}>{k.totalUnit61to90}</small>
                                                    </div>
                                                </StyleCell>
                                                <StyleCell align="center" onClick={() => handleStock(k.franchise, props.id, 3)} className="CursorHov">
                                                    <div>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(k.totalCount91to120)} / &nbsp;
                                                        <small style={{ fontSize: 12 }}>{k.totalUnit91to120}</small>
                                                    </div>
                                                </StyleCell>
                                                <StyleCell align="center" onClick={() => handleStock(k.franchise, props.id, 4)} className="CursorHov">
                                                    <div>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(k.totalCount121to150)} / &nbsp;
                                                        <small style={{ fontSize: 12 }}>{k.totalUnit121to150}</small>
                                                    </div>
                                                </StyleCell>
                                                <StyleCell align="center" onClick={() => handleStock(k.franchise, props.id, 5)} className="CursorHov">
                                                    <div>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(k.totalCount150Plus)} / &nbsp;
                                                        <small style={{ fontSize: 12 }}>{k.totalUnit150Plus}</small>
                                                    </div>
                                                </StyleCell>
                                                <StyleCell align="center">
                                                    <div className="">
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(k.reservedProfit)} / &nbsp;
                                                        <small style={{ fontSize: 12 }}>{k.reservedUnits}</small>
                                                    </div>
                                                </StyleCell>
                                                <StyleCell align="center">
                                                    <div className="">
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(k.unsoldProfit || 0)} / &nbsp;
                                                        <small style={{ fontSize: 12 }}>{k.unsoldUnit || 0}</small>
                                                    </div>
                                                </StyleCell>
                                                <StyleCell align="center">
                                                    <div className="">
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(k.orderedProfit)} / &nbsp;
                                                        <small style={{ fontSize: 12 }}>{k.orderedUnits || 0}</small>
                                                    </div>
                                                </StyleCell>
                                                <StyleCell align="center">
                                                    {' '}
                                                    <div className="">
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(k.consignedProfit)} /
                                                        <small style={{ fontSize: 12 }}>{k.consignedUnits || 0}</small>
                                                    </div>
                                                </StyleCell>
                                                <StyleCell align="center">
                                                    {' '}
                                                    <div className="">
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(k.finishedProfit)} / &nbsp;
                                                        <small style={{ fontSize: 12 }}>{k.finishedUnits || 0}</small>
                                                    </div>
                                                </StyleCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Hidden>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <Table className={classes.table1} aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyleCell colSpan={2}>
                                                <SelectBox List={TableList} onChange={handleDropDown} name="setValueForStock" value={state.setValueForStock} />
                                            </StyleCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableHead>
                                        <TableRow>
                                            <ManiHederCell>Franchise</ManiHederCell>
                                            <ManiHederCell align="center">
                                                <span className="lableFont"> {state.selectedTableLabel} </span>
                                            </ManiHederCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list?.stock.map((k, i) => (
                                            <TableRow key={i} hover>
                                                <StyleCell align="left">
                                                    <div className="">{k.fn}</div>
                                                </StyleCell>
                                                <StyleCell
                                                    align="center"
                                                    onClick={() => state.setValueForStock <= 5 && handleStock(k.franchise, props.id, state.setValueForStock)}
                                                    className="CursorHov"
                                                >
                                                    <div>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(k[state.selectedProfit])} / &nbsp;
                                                        <small style={{ fontSize: 12 }}>{k.totalUnit0to60}</small>
                                                    </div>
                                                </StyleCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Hidden>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}
