import React, { useEffect, useState } from 'react';
import { GetNewVehicleDashboard } from '../../../../Core/Service/dashbordService';
import { Button, ButtonGroup, Card, CircularProgress, Grid, Hidden } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#f5f5f5',
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 110,
        padding: '5px 0px',
        margin: 3,
        borderRadius: 3
    },
    CardRoot: {
        width: '100%',
        margin: 3,
        border: '2px solid white ',
        Opacity: 0.9,
        minWidth: 120,
        padding: '5px 0px',
        background: 'white',
        borderRadius: 10
    }
}));

const scrollNext = (id) => {
    document.getElementById('WorkFlow-Btn').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
};

const scrollPrev = (id) => {
    document.getElementById('WorkFlow-Btn').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
};

const WipsDate = [
    { id: 2, name: 'Overdue WIPs' },
    { id: 1, name: 'Assigned Date' }
];

function getUniqueObjectsByProperty(arrayOfObjects, property) {
    const uniqueValues = [...new Set(arrayOfObjects.map((obj) => obj[property]))];
    const uniqueObjects = uniqueValues.map((value) => arrayOfObjects.find((obj) => obj[property] === value));
    return uniqueObjects;
}

const SalesRecordCard = ({ workflowData, workflow, total, classes, onClick }) => {
    return (
        <Card className={classes?.root} style={{ background: 'white', color: 'black', padding: 0 }} onClick={onClick ? () => onClick(workflowData) : null}>
            <div style={{ textAlign: 'left', cursor: 'pointer', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', padding: '5px 20px' }}>
                <span style={{ fontSize: 14, marginBottom: 6 }}>{workflow}</span>
                <div style={{ fontSize: 30, fontWeight: 600 }}>{total}</div>
            </div>
        </Card>
    );
};

const processSalesData = (data) => {
    let salesRecordAllTotal = 0;
    let sortedSalesRecordsList = data.map((obj) => ({ ...obj }));
    let sumOFByTimeForALL = [];

    sortedSalesRecordsList = sortedSalesRecordsList.reduce((acc, obj) => {
        const index = acc.find((item) => item.workflowID === obj.workflowID);
        if (index) {
            index.total += obj.total;
            return acc;
        }
        acc.push({ ...obj });
        return acc;
    }, []);

    sumOFByTimeForALL = data.reduce((acc, obj) => {
        const index = acc.find((item) => item.bid === obj.bid);
        if (index) {
            index.lessThan24HoursOld += obj.lessThan24HoursOld;
            index.between1And5DaysOld += obj.between1And5DaysOld;
            index.between5And10DaysOld += obj.between5And10DaysOld;
            index.between10And20DaysOld += obj.between10And20DaysOld;
            index.between21And30DaysOld += obj.between21And30DaysOld;
            index.over30DaysOld += obj.over30DaysOld;
            index.preBooking += obj.preBooking;
            index.between1to10Days += obj.between1to10Days;
            index.between11And20Days += obj.between11And20Days;
            index.between21And30Days += obj.between21And30Days;
            index.overdueOver30DaysOld += obj.overdueOver30DaysOld;
            index.overdueArchived += obj.overdueArchived;
            return acc;
        }
        acc.push({ ...obj });
        return acc;
    }, []);

    salesRecordAllTotal = sortedSalesRecordsList.reduce((total, obj) => total + obj.total, 0);

    return { salesRecordAllTotal, sortedSalesRecordsList, sumOFByTimeForALL };
};

const NewSalesDashboardDetailsScreen = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [state, setState] = useState({ salesRecordList: [], sumOFByTimeForALL: [], uniqueBranches: [], assignedDate: 2 });

    useEffect(() => {
        const fetchData = async () => {
            let res = await GetNewVehicleDashboard(props.processID);
            const uniqueBranches = getUniqueObjectsByProperty([...res.data.salesRecord], 'bid').map((a) => ({ name: a.bn, id: a.bid }));
            const { salesRecordAllTotal, sortedSalesRecordsList, sumOFByTimeForALL } = processSalesData(res.data.salesRecord);

            setState((st) => ({
                ...st,
                salesRecordList: sortedSalesRecordsList,
                salesRecordAllTotal,
                sumOFByTimeForALL,
                uniqueBranches
            }));
        };
        fetchData();
    }, [props.processID]);

    useEffect(() => {
        if (state.sumOFByTimeForALL.length > 0) {
            const btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
            findByTimeValues(btTimeData?.branchShortName || 'Default');
        }
    }, [state.sumOFByTimeForALL]);

    let findByTimeValues = (val) => {
        let indexObj = {};

        if (val === 'Default') {
            if (state.sumOFByTimeForALL) {
                let letNewData = state?.sumOFByTimeForALL?.reduce((acc, obj) => {
                    var index = acc.find((item) => item);
                    if (index) {
                        index.lessThan24HoursOld += obj.lessThan24HoursOld;
                        index.between1And5DaysOld += obj.between1And5DaysOld;
                        index.between5And10DaysOld += obj.between5And10DaysOld;
                        index.between10And20DaysOld += obj.between10And20DaysOld;
                        index.between21And30DaysOld += obj.between21And30DaysOld;
                        index.over30DaysOld += obj.over30DaysOld;
                        index.preBooking += obj.preBooking;
                        index.between1to10Days += obj.between1to10Days;
                        index.between11And20Days += obj.between11And20Days;
                        index.between21And30Days += obj.between21And30Days;
                        index.overdueOver30DaysOld += obj.overdueOver30DaysOld;
                        // index.overdueArchived += obj.overdueArchived;
                        // index.total += obj.total;
                        return acc;
                    }
                    acc.push({ ...obj });
                    return acc;
                }, []);
                indexObj = letNewData.find((item) => item);
                indexObj.bn = '';
                setState((st) => ({ ...st, byTimeObj: indexObj, byTimeBranch: val }));
            }
        } else {
            indexObj = state.sumOFByTimeForALL.find((n) => n.bn === val);
            setState((st) => ({ ...st, byTimeObj: indexObj, byTimeBranch: val }));
        }
    };

    const handleDate = (val) => {
        if (val._reactName === 'onChange') {
            setState((st) => ({ ...st, assignedDate: val.target.value }));
        } else {
            setState((st) => ({ ...st, assignedDate: val }));
        }
    };

    const GoToSales = (val) => {
        console.log(val);
        let searchQuery = `?workFlowId=${val.workflowID}&ProcessId=${props.processID}`;
        history.push({
            pathname: `/NewSalesListView`,
            search: searchQuery
        });
    };

    const DateObj = [
        {
            assignedDateObj: [
                { time: 1, label: 'Less Than 24Hr', BarColor: '5px solid #00F433', value: state.byTimeObj?.lessThan24HoursOld },
                { time: 2, label: '1 to 5 Days', BarColor: '5px solid #88EBA4', value: state.byTimeObj?.between1And5DaysOld },
                { time: 3, label: '5 to 10 Days', BarColor: '5px solid #FFBF00', value: state.byTimeObj?.between5And10DaysOld },
                { time: 4, label: '10 to 20 Days', BarColor: '5px solid #FFE28A', value: state.byTimeObj?.between10And20DaysOld },
                { time: 5, label: '20 to 30 Days', BarColor: '5px solid #bc4443', value: state.byTimeObj?.between21And30DaysOld },
                { time: 6, label: 'Over 30 Days', BarColor: '5px solid #F11C0E', value: state.byTimeObj?.over30DaysOld }
            ],
            OverDateObj: [
                { dueTime: 1, label: 'Pre Booking', BarColor: '5px solid #88EBA4', value: state.byTimeObj?.preBooking },
                { dueTime: 2, label: '1 to 10 Days', BarColor: '5px solid #FFBF00', value: state.byTimeObj?.between1to10Days },
                { dueTime: 3, label: '10 to 20 Days', BarColor: '5px solid #FFE28A', value: state.byTimeObj?.between11And20Days },
                { dueTime: 4, label: '20 to 30 Days', BarColor: '5px solid #bc4443', value: state.byTimeObj?.between21And30Days },
                { dueTime: 5, label: 'Over 30 Days', BarColor: '5px solid #F11C0E', value: state.byTimeObj?.overdueOver30DaysOld }
                // { dueTime: -1, label: 'Archived', BarColor: '5px solid #F11C0E', value: state.byTimeObj?.overdueArchived }
            ]
        }
    ];

    let getDateObj = state.assignedDate == 1 ? DateObj[0].assignedDateObj : DateObj[0].OverDateObj;

    const handleChangeByTime = (e) => {
        const valueByTime = e.target.value;
        const tempData = JSON.parse(localStorage.getItem('_dashboard_')) || {};
        // props.getbranchByOnchange(valueByTime);
        findByTimeValues(valueByTime);

        localStorage.setItem(
            'FiltterParams',
            JSON.stringify({
                branchShortName: valueByTime !== 'Default' ? valueByTime : ''
            })
        );

        if (valueByTime !== 'Default') {
            localStorage.setItem(
                '_dashboard_',
                JSON.stringify({
                    ...tempData,
                    BranchBytime: valueByTime,
                    selectedValueByTech: valueByTime
                })
            );
        }
    };

    return (
        <div
            className="Parts_Dash_Main_Container custom-scroll"
            style={{ padding: '10px', overflow: 'auto', height: 'calc(100vh - 185px)', background: '#f5f5f5' }}
        >
            {state.showLoader ? (
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
            ) : (
                <>
                    {state.salesRecordList.length > 0 && (
                        <Grid container>
                            <div style={{ overflow: 'hidden', display: 'flex', width: '100%' }}>
                                {state.isShowScrollArrow && (
                                    <span onClick={() => scrollPrev()} className="ScrollArrow-Container">
                                        <ChevronLeftIcon />
                                    </span>
                                )}
                                <div style={{ overflow: 'auto', display: 'flex', width: '100%' }} className={`btn-container`} id="WorkFlow-Btn">
                                    {state.salesRecordList.map((m, i) => (
                                        <div item style={{ padding: state.isShowScrollArrow ? '0px 5px 0px 0px' : `0px 5px 0px ${i === 0 ? '10px' : '0px'}` }}>
                                            <SalesRecordCard
                                                workflowData={m}
                                                workflow={m?.workflow}
                                                total={m?.total}
                                                classes={{ root: classes.root }}
                                                onClick={GoToSales}
                                            />
                                        </div>
                                    ))}
                                    <div item style={{ padding: '0px 5px 0px 0px' }}>
                                        <SalesRecordCard workflow="Total" total={state.salesRecordAllTotal} classes={{ root: classes.root }} />
                                    </div>
                                </div>
                                {state.isShowScrollArrow && (
                                    <span onClick={() => scrollNext()} className="ScrollArrow-Container">
                                        <ChevronRightIcon />
                                    </span>
                                )}
                            </div>
                        </Grid>
                    )}
                    <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 5, boxSize: 'none' }}>
                        {state.showHeaderLoader ? (
                            <div className={classes.root}>
                                <div className="pieChartSize" style={{ display: 'flex' }}>
                                    <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }} />
                                </div>
                            </div>
                        ) : (
                            <Card className={classes.root}>
                                <Hidden only={['xs']}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={8}>
                                            <div
                                                style={{
                                                    margin: '10px 10px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <ButtonGroup color="secondary" ria-label="outlined primary button group" size="small">
                                                    {WipsDate.map((n) => {
                                                        return (
                                                            <Button
                                                                variant={state.assignedDate === n.id ? 'contained' : 'outlined'}
                                                                onClick={() => handleDate(n.id)}
                                                                style={{ fontSize: 10 }}
                                                            >
                                                                {n.name}
                                                            </Button>
                                                        );
                                                    })}
                                                </ButtonGroup>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div
                                                style={{
                                                    float: 'right',
                                                    margin: 10
                                                }}
                                            >
                                                <select onChange={handleChangeByTime} className="selectclass" value={state.byTimeBranch}>
                                                    <option value={'Default'}>All Branches</option>
                                                    {state.uniqueBranches.map((btn, i) => {
                                                        return (
                                                            <option key={i} value={btn.name}>
                                                                {btn.name}{' '}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Hidden>
                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    margin: '0px 20px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont">Dates</span>
                                                <select
                                                    color="secondary"
                                                    onChange={handleDate}
                                                    aria-label="outlined primary button group"
                                                    size="small"
                                                    className="ButtonDropDown"
                                                    value={state.assignedDate}
                                                >
                                                    {WipsDate.map((n, i) => {
                                                        return (
                                                            <option key={i} value={n.id}>
                                                                {n.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    margin: '0px 20px',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont">Branch</span>
                                                <select onChange={handleChangeByTime} className="selectclass" value={state.byTimeBranch}>
                                                    <option value={'Default'}>All Branches</option>
                                                    {state.uniqueBranches.map((btn, i) => {
                                                        return (
                                                            <option key={i} value={btn.name}>
                                                                {btn.name}{' '}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Hidden>

                                <Grid item xs={12} style={{ padding: '5px 5px' }}>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            padding: '0px 20px',
                                            fontFamily: ` Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                                            fontWeight: 500
                                        }}
                                    ></div>
                                </Grid>
                                <div style={{ padding: '0px 30px' }}>
                                    <hr
                                        style={{
                                            flexShrink: 0,
                                            borderWidth: '0px 0px thin',
                                            borderStyle: 'solid',
                                            borderColor: 'rgba(58, 53, 65, 0.12)',
                                            margin: '1.1rem 0px'
                                        }}
                                    />
                                </div>
                                <Grid container spacing={1} style={{ padding: '0px 20px' }}>
                                    {getDateObj.map((item, index) => (
                                        <Grid
                                            item
                                            xs={6}
                                            sm={2}
                                            style={{ padding: '5px 5px' }}
                                            // onClick={() =>
                                            //     props.onChartClickHandler(
                                            //         {
                                            //             workflowID: 'All',
                                            //             workflow: state.byTimeObj?.workflow,
                                            //             bn: state.byTimeObj?.bn,
                                            //             time: item.time,
                                            //             dueTime: item.dueTime
                                            //         },
                                            //         true
                                            //     )
                                            // }
                                        >
                                            <div
                                                style={{
                                                    borderLeft: item.BarColor,
                                                    padding: '5px 0px 0px 20px',
                                                    width: '100%',
                                                    cursor: 'pointer'
                                                }}
                                                // onClick={() => buttonClicked(m)}
                                            >
                                                <div
                                                    style={{
                                                        paddingRight: 20,
                                                        paddingBottom: 5,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont">{item.label}</span>
                                                    <div className="valueFont">{item.value}</div>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Card>
                        )}
                    </Grid>
                </>
            )}
        </div>
    );
};

export default NewSalesDashboardDetailsScreen;
