import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAppraisalPageStesps } from '../../../../Core/Service/appraisalScreensColumns-service';
import TabView from '../../../../Core//Controls/Tabs';
import { CircularProgress } from '@material-ui/core';
import CreateAppraislColumnsList from './CreateAppraislColumnsList';
const AppraisalScreensColumns = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        showDel: false,
        Steps: []
    });

    useEffect(async () => {
        let res = await getAppraisalPageStesps('Create Appraisal');
        if (res.success) {
            setState((st) => ({ ...st, Steps: res?.data?.pageSteps || [] }));
        }
    }, []);

    const tabs = useMemo(() => {
        let arr = [];

        state.Steps?.map((s) => {
            const toPush = {
                label: s.screenHeading,
                body: <CreateAppraislColumnsList data={s} />
            };
            arr.push(toPush);
        });

        return arr;
    }, [state.Steps]);

    return (
        <div style={{ position: 'relative' }}>
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Appraisal
                </Typography>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Create Appraisal Screens Columns
                </Typography>
            </Breadcrumbs>
            <TabView tabList={tabs} id="testing_tabs" tabLableSize="1.0rem" tabLableWeight="600" />

            {!state.Steps && (
                <div style={{ display: 'flow', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};
export default AppraisalScreensColumns;
