import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, SecondaryButton, DialogContents, DialogTitles, Dialogs } from '../../../Core/FormInput';
import '../../../Core/Modal/model.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { addWorkflowStepsListOptions } from '../../../Core/Service/workflowList-service';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { getWorkflowListOps } from '../../../Core/Service/workflowList-service';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { SketchPicker } from 'react-color';
// import { ChromePicker } from 'react-color';

import '../commonStyle.scss';

const popover = {
    position: 'absolute',
    zIndex: '2'
};
const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
};

const WorkflowStepsListOptions = (props) => {
    const { showToast, hideModal } = useContext(AppContext);

    const [state, setState] = useState({
        Description: '',
        rows: [],
        listName: '',
        isUpdate: false,
        optionID: null
    });

    const Id = props.WorkflowStepsListDetails;

    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Part" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdateOptions(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Part" aria-label="add">
                    <IconButton size="small" onClick={() => handleDeletePart(params)}>
                        <DeleteOutlineIcon className="SetButtonColor" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const presetColors = ['rgb(255, 255, 0)', 'rgb(0,0,255)', 'rgb(0,255,0)', 'rgb(255,0,0)'];

    const handleUpdateOptions = (params) => {
        const row = params.value.row;
        setState((st) => {
            const nst = {
                ...st,
                id: params.value.row.id,
                Description: row.listOptionName,
                isUpdate: true,
                optionID: params.value.row.id,
                backgroundColour: params.value.row.backgroundColour,
                foregroundColour: params.value.row.foregroundColour
            };
            return nst;
        });
    };
    const handleDeletePart = (params) => {
        const id = params.value.row.id;
        const newList = state.rows.filter((item) => item.id !== id);
        let newPartToSetIsDelete = {
            id: params.value.row.id,
            backgroundColour: params.value.row.backgroundColour,
            foregroundColour: params.value.row.foregroundColour,
            listOptionName: params.value.row.listOptionName,
            listOptionId: params.value.row.listOptionId,
            MarkForDelete: true
        };
        newList.push(newPartToSetIsDelete);
        setState((st) => {
            const nst = {
                ...st,
                rows: newList
            };
            return nst;
        });
    };
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 130,
            sortable: false,
            hide: true
        },
        { field: 'listOptionName', headerName: 'Name', flex: 0.7 },
        { field: 'backgroundColour', headerName: 'Background Color', width: 190 },
        { field: 'foregroundColour', headerName: 'Text Color', width: 130 },
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 100,

            renderCell: (params) => <Arrow value={params} />
        }
    ];
    useEffect(async () => {
        pullWorkflowAndUpdateState();
    }, []);

    const pullWorkflowAndUpdateState = async () => {
        let result = await getWorkflowListOps(Id);
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data.listOptions.map((w) => ({
                    ...w,
                    id: w.listOptionId,
                    MarkForDelete: false,
                    backgroundColour: w.backgroundColour,
                    foregroundColour: w.foregroundColour
                })),
                listName: result.data.listName
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };

    const addOptionsToLocally = () => {
        if (state.Description === '') {
            showToast('Option Name is required');
        } else {
            let newPartToAdd = {
                listOptionId: state.optionID ? state.optionID : 0,
                listOptionName: state.Description,
                MarkForDelete: false,
                backgroundColour: state.backgroundColour,
                foregroundColour: state.foregroundColour
            };
            setState((st) => ({
                ...st,
                rows: state.rows.filter((item) => item.id !== state.optionID)
            }));
            setState((st) => {
                newPartToAdd.id = st.rows.length === 0 ? 1 : Math.max(...st.rows.map((c) => c.id)) + 1;
                return {
                    ...st,
                    rows: [...st.rows, newPartToAdd],
                    Description: '',
                    isUpdate: false,
                    optionID: undefined
                };
            });
        }
    };
    const handelAddOptionToServer = async () => {
        let res = await addWorkflowStepsListOptions(
            Id,
            state.rows.map((c) => ({
                listOptionId: c.listOptionId,
                listOptionName: c.listOptionName,
                MarkForDelete: c.MarkForDelete,
                backgroundColour: c.backgroundColour,
                foregroundColour: c.foregroundColour
            }))
        );
        if (!res.success) {
            console.error(res);
        }
        showToast(res.message);
        props.onFormSubmit(false);
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };
    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No parts available</div>
            </GridOverlay>
        );
    }
    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <div style={{ margin: '10px auto' }}>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <DialogTitles>Workflow Steps List Option</DialogTitles>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <DialogContents>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} style={{ float: 'left', marginTop: '-14px' }}>
                            <Typography color="secondary">List Name - {state.listName}</Typography>
                        </Grid>

                        <Grid item xs={12} md={12} style={{ marginTop: '-12px' }}>
                            <FormLabel component="legend">Option Name</FormLabel>
                            <TextBox onChange={fieldChange} value={state.Description} name="Description" />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <p style={{ marginTop: '-12px' }}>Background Colour</p>
                            <SketchPicker
                                width="241px"
                                presetColors={presetColors}
                                color={state.backgroundColour}
                                onChange={(updatedColor) =>
                                    setState((state) => ({
                                        ...state,
                                        backgroundColour: updatedColor.hex
                                    }))
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <p style={{ marginTop: '-12px' }}>Text Colour</p>
                            <SketchPicker
                                width="241px"
                                presetColors={presetColors}
                                color={state.foregroundColour}
                                onChange={(updatedColor) =>
                                    setState((state) => ({
                                        ...state,
                                        foregroundColour: updatedColor.hex
                                    }))
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SecondaryButton onClick={handelAddOptionToServer} className="setFullWidth">
                                Submit
                            </SecondaryButton>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SecondaryButton onClick={addOptionsToLocally} className="setFullWidth">
                                Update
                            </SecondaryButton>
                        </Grid>
                    </Grid>

                    <div
                        style={{
                            height: 'calc(100vh - 660px)',
                            width: '100%',
                            margin: '15px 0px 0px 0px'
                        }}
                    >
                        <DataGrid
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            headerHeight={50}
                            rowHeight={50}
                            hideFooter={true}
                            columns={columns}
                            rows={state.rows.filter((item) => item.MarkForDelete !== true)}
                            disableColumnMenu={true}
                            showColumnRightBorder={true}
                            pageSize={100}
                        />
                    </div>
                </DialogContents>
            </div>
        </Dialogs>
    );
};

export default WorkflowStepsListOptions;
