import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import SalesDashboardSettingComp from './SalesDashboardComp';
import { getSalesDashboardTabs } from '../../../../../Core/Service/SalesPerformance-service';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
        // backgroundColor: theme.palette.background.paper
    }
}));

const SalesDashboardSetting = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(1);
    const [menuList, setMenuList] = React.useState([]);

    useEffect(async () => {
        let res = await getSalesDashboardTabs();
        if (res.success) {
            setMenuList([...res?.data?.list]);
        }
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <TabContext value={value}>
                {/* <AppBar position="static"> */}
                <TabList onChange={handleChange} aria-label="simple tabs example">
                    {menuList.map((k) => (
                        <Tab label={k.name} value={k.id} />
                    ))}
                </TabList>
                {menuList.map((k) => (
                    <TabPanel value={k.id}>
                        {' '}
                        <SalesDashboardSettingComp ID={k.id} />
                    </TabPanel>
                ))}
            </TabContext>
        </div>
    );
};

export default SalesDashboardSetting;
