import React, { useEffect, useReducer, useState } from 'react';
import { reducer, initState } from '../../Components/Reducer/action';
import './ScreenStep.scss';
import { Button, Grid } from '@material-ui/core';
import { SecondaryButton } from '../FormInput';
import { DragHandle } from '@material-ui/icons';
import DialogComp from '../Controls/Dialog/DialogComp';
import { Alert, AlertTitle } from '@material-ui/lab';

let ScreenSteps = function (props) {
    let [state, setState] = useState({ currentIndex: 0, lastIndex: 0, showErros: false });
    let Comp;

    useEffect(() => {
        setState((st) => ({ ...st, steps: props.steps }));
    }, [props.steps]);

    const nextSubmit = () => {
        if (props.formErrors.length == 0) {
            setState((st) => ({ ...st, currentIndex: st.currentIndex + 1 }));
        } else {
            setState((st) => ({ ...st, showErros: true }));
        }
    };
    const tabsubmit = (index) => {
        if (index < state.currentIndex) {
            setState((st) => ({ ...st, currentIndex: index }));
        }
    };
    const previewSubmit = () => {
        setState((st) => ({ ...st, currentIndex: st.currentIndex - 1 }));
    };

    function closePopupModal() {
        setState((st) => ({ ...st, showErros: false }));
    }

    const handleSubmit = () => {
        if (props.formErrors.length == 0) {
            props.onFinalSubmit();
        } else {
            setState((st) => ({ ...st, showErros: true }));
        }
    };

    // function submitFormIfAny() {
    //     closePopupModal();
    //     props.onFinalSubmit && props.onFinalSubmit();
    // }
    Comp = state.currentIndex > -1 ? props.steps[state?.currentIndex]?.component : <div />;
    return (
        <div className="enquiry-form" style={{ background: 'white' }}>
            {Comp}
            {state.showErros ? (
                <DialogComp onClose={closePopupModal} maxWidth="sm" title={'Error Message'}>
                    {props.formErrors.map((k) => (
                        <Alert severity="warning" style={{ margin: 10 }}>
                            <b>{k} </b>This field is required
                        </Alert>
                    ))}
                </DialogComp>
            ) : null}
            <div className="footer">
                <Grid container spacing={2} style={{ padding: 10 }}>
                    <Grid item xs={6}>
                        <SecondaryButton fullWidth onClick={previewSubmit} disabled={state.currentIndex == 0}>
                            Previous
                        </SecondaryButton>
                    </Grid>
                    {state.currentIndex !== 6 ? (
                        <Grid item xs={6}>
                            <SecondaryButton fullWidth onClick={nextSubmit}>
                                Next
                            </SecondaryButton>
                        </Grid>
                    ) : (
                        <Grid item xs={6}>
                            <SecondaryButton fullWidth onClick={handleSubmit}>
                                Save
                            </SecondaryButton>
                        </Grid>
                    )}
                </Grid>

                <div className="steps btn-group">
                    {state.steps?.map((st, i) => (
                        <span
                            color="primary"
                            title={st.label}
                            className={`btn ${i === state.currentIndex ? 'btn-secondary' : 'btn-light'}`}
                            key={i}
                            onClick={() => tabsubmit(i)}
                        >
                            {st.icon && <img src={st.icon} />}
                            <span className={`step-label ${st.icon ? 'auto-hide' : ''}`}>{st.label || ''}</span>
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ScreenSteps;
