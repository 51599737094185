import React, { useEffect, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AppContext from '../../../App/AppContext';
import { Link } from 'react-router-dom';
import { Avatar, Card, CardContent, Typography, Container } from '@material-ui/core';
import '../../Auditor/Dashboard/dashboard.scss';
import EmailIcon from '@material-ui/icons/Email';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import { getCommunicatorDash } from '../../../Core/Service/CustUserCommunicator/communicator-service';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import TimerIcon from '@material-ui/icons/Timer';
import { RenderTiles } from '../CustCommonDash';
import { AppStorage } from '../../../Core/Service/storage-service';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import BookIcon from '@material-ui/icons/Book';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AssistantIcon from '@material-ui/icons/Assistant';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import WorkIcon from '@material-ui/icons/Work';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import BuildIcon from '@material-ui/icons/Build';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PersonIcon from '@material-ui/icons/Person';
import { SecondaryButton } from '../../../Core/FormInput';
import BookingRequestedModal from '../bookingRequested/bookingRequestedModal';
import DialogComp from '../../../Core/Modal/dialogModal';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import SendIcon from '@material-ui/icons/Send';
import ScheduleIcon from '@material-ui/icons/Schedule';
import NotesIcon from '@material-ui/icons/Notes';
import CommentIcon from '@material-ui/icons/Comment';

const CustCommunicatorDash = (props) => {
    const [state, setState] = useState({
        InvoiceData: [],
        eWIPData: [],
        communicatorData: [],
        communicatorLog: []
    });
    const { showToast, userRoles } = useContext(AppContext);
    const isCustomer = userRoles.includes('customer');
    const isInternal = userRoles.includes('internal');
    useEffect(async () => {
        let communicator = await getCommunicatorDash();
        let communicatorLog = communicator?.data?.communicatorLog || [];
        if (communicator.success) {
            setState((st) => ({
                ...st,
                communicatorData: communicator.data?.list[0],
                communicatorLog: communicatorLog.map((m, i) => ({
                    id: `action${i}`,
                    name: m.cb,
                    color: '#3498db',
                    icon: <CommentIcon />,
                    count: m.count,
                    url: '/Openactionlog',
                    logID: m.id
                }))
            }));
        }
        if (!communicator.success) {
            showToast(communicator.message);
        }
    }, [state.isReload]);

    const CommunicatorMemberSecOne = [
        {
            id: 1,
            name: 'VOR Vehicles',
            color: '#dc3545',
            icon: <LocalShippingIcon />,
            count: state.communicatorData.vorVehiclesCount,
            url: { pathname: '/SiteVehicles', search: `?status=VOR` },
            isShowCustomer: true
        },
        {
            id: 2,
            name: 'Awaiting Approval',
            color: '#dc3545',
            icon: <TimerIcon />,
            count: state.communicatorData.awaitingAuthorityCount,
            url: { pathname: '/SiteVehicles', search: `?status=AUTH` }
        },
        {
            id: 3,
            name: 'Awaiting Parts',
            color: '#dc3545',
            icon: <GroupWorkIcon />,
            count: state.communicatorData.awaitingPartsCount,
            url: { pathname: '/SiteVehicles', search: `?status=APARTS` }
        },
        // {
        //     id: 4,
        //     name: 'Awaiting Full Order',
        //     color: '#dc3545',
        //     icon: <ShoppingCartIcon />,
        //     count: state.communicatorData.awaitingFullOrderNumberCount,
        //     url: { pathname: '/SiteVehicles', search: `?status=AFON` },
        //     isShowCustomer: true
        // },
        // {
        //     id: 5,
        //     name: 'Awaiting QC',
        //     color: '#dc3545',
        //     icon: <EqualizerIcon />,
        //     count: state.communicatorData.awaitingQCCount,
        //     url: { pathname: '/SiteVehicles', search: `?status=QC` }
        // },
        {
            id: 6,
            name: 'Awaiting Technical Assistance',
            color: '#dc3545',
            icon: <AssistantIcon />,
            count: state.communicatorData.awaitingTechnicalAssistanceCount,
            url: { pathname: '/SiteVehicles', search: `?status=ATA` }
        },
        {
            id: 7,
            name: 'Waiting SubWork',
            color: '#dc3545',
            icon: <PersonIcon />,
            count: state.communicatorData.waitingSubWorkCount,
            url: { pathname: '/SiteVehicles', search: `?status=SUB` }
        },
        {
            id: 8,
            name: 'In Query',
            color: '#dc3545',
            icon: <DateRangeIcon />,
            count: state.communicatorData.queryCount,
            url: { pathname: '/SiteVehicles', search: `?status=Q` }
        },
        {
            id: 9,
            name: 'Part Back Order, ETA > 72 hrs',
            color: '#dc3545',
            icon: <GraphicEqIcon />,
            count: state.communicatorData.partsBackOrderCount,
            url: { pathname: '/SiteVehicles', search: `?status=PB` }
        },
        {
            id: 17,
            name: 'NOX',
            color: '#dc3545',
            icon: <EventAvailableIcon />,
            count: state.communicatorData.noxCount,
            url: { pathname: '/SiteVehicles', search: `?status=NOX` }
        }
        // {
        //     id: 8,
        //     name: 'Rebooking',
        //     color: '#dc3545',
        //     icon: <EventAvailableIcon />,
        //     count: state.communicatorData.forRebookCount,
        //     url: { pathname: '/SiteVehicles', search: `?status=REBOOKING` }
        // },
        // {
        //     id: 9,
        //     name: 'Parts Escalation',
        //     color: '#dc3545',
        //     icon: <GraphicEqIcon />,
        //     count: state.communicatorData.partsEscalationCount,
        //     url: { pathname: '/SiteVehicles', search: `?status=PE` }
        // }
    ];

    const CommunicatorMemberSecTwo = [
        {
            id: 11,
            name: 'Checked In',
            color: '#3498db',
            icon: <AddShoppingCartIcon />,
            count: state.communicatorData.checkedInCount,
            url: { pathname: '/SiteVehicles', search: `?status=CHECKIN` }
        },
        {
            id: 12,
            name: 'Check In - WSL not set',
            color: '#3498db',
            icon: <AddShoppingCartIcon />,
            count: state.communicatorData.wslNotSet,
            url: { pathname: '/SiteVehicles', search: `?status=WSLNOTSET` }
        },
        {
            id: 13,
            name: 'Workshop',
            color: '#3498db',
            icon: <BuildIcon />,
            count: state.communicatorData.workshopCount,
            url: { pathname: '/SiteVehicles', search: `?status=WORKSHOP` }
        },
        {
            id: 14,
            name: 'Site Vehicles',
            color: '#3498db',
            icon: <DriveEtaIcon />,
            count: state.communicatorData.siteVehiclesCount,
            url: { pathname: '/SiteVehicles', search: `?status=S` },
            isShowCustomer: true
        }
        // {
        //     id: 20,
        //     name: 'My Action Log',
        //     color: '#3498db',
        //     icon: <NotesIcon />,
        //     count: state.communicatorData.awaitingAuthorityCount,
        //     url: '/Openactionlog'
        // }
    ];

    const ActionLogList = [
        // {
        //     id: 20,
        //     name: ,
        //     color: '#3498db',
        //     icon: <NotesIcon />,
        //     count: state.communicatorData.awaitingAuthorityCount,
        //     url: '/Openactionlog'
        // },
        {
            id: 21,
            name: 'My Action Log',
            color: '#3498db',
            icon: <NotesIcon />,
            count: state.communicatorData.awaitingAuthorityCount,
            url: '/Openactionlog'
        }
    ];

    const CommunicatorMemberSecThree = [
        // {
        //     id: 14,
        //     name: 'Checked Out',
        //     color: '#2ecc71',
        //     icon: <RemoveShoppingCartIcon />,
        //     count: state.communicatorData.checkedOutCount,
        //     url: { pathname: '/SiteVehicles', search: `?status=CHECKEDOUT` }
        // },
        // {
        //     id: 15,
        //     name: 'Parts Arrived',
        //     color: '#2ecc71',
        //     icon: <FlightLandIcon />,
        //     count: state.communicatorData.partsArrivedCount,
        //     url: { pathname: '/SiteVehicles', search: `?status=PA` }
        // },
        {
            id: 16,
            name: 'Jobs Approved',
            color: '#2ecc71',
            icon: <ThumbUpIcon />,
            count: state.communicatorData.jobsApprovedCount,
            url: { pathname: '/SiteVehicles', search: `?status=A` }
        },
        {
            id: 17,
            name: 'Work Completed',
            color: '#2ecc71',
            icon: <LibraryAddCheckIcon />,
            count: state.communicatorData.workCompletedCount,
            url: { pathname: '/SiteVehicles', search: `?status=WC` },
            isShowCustomer: true
        },
        {
            id: 18,
            name: ' Awaiting Collection',
            color: '#2ecc71',
            icon: <DepartureBoardIcon />,
            count: state.communicatorData.awaitingCollectionCount,
            url: { pathname: '/SiteVehicles', search: `?status=ACOLLECTION` },
            isShowCustomer: true
        },
        {
            id: 1,
            name: 'Awaiting Authority',
            color: '#3498db',
            icon: <TimerIcon />,
            count: state.communicatorData.awaitingAuthorityCount,
            url: isInternal ? { pathname: '/SiteVehicles', search: `?status=AUTH` } : '/AwaitingAuthority',
            isShowCustomer: true
        }
    ];

    const CommunicatorBookingsSec = [
        {
            id: 10,
            name: 'Bookings',
            color: '#3498db',
            icon: <EventAvailableIcon />,
            count: state.communicatorData.bookingCount,
            url: { pathname: '/SiteVehicles', search: `?status=BOOKED` },
            isShowCustomer: true
        },
        {
            id: 10,
            name: isInternal ? 'Request Received' : 'Request Sent',
            color: '#3498db',
            icon: <EmailIcon />,
            count: state.communicatorData.bookingRequestCount,
            url: { pathname: '/BookingsRequesteList', search: `?status=1` },
            isShowCustomer: true
        },

        ...(!isInternal
            ? [
                  {
                      id: 10,
                      name: 'Re-Schedule Received',
                      color: '#3498db',
                      icon: <ScheduleIcon />,
                      count: state.communicatorData.bookingReScheduleReceivedSentCount,
                      url: { pathname: '/BookingsRequesteList', search: `?status=2` }
                  },
                  {
                      id: 10,
                      name: 'Re-Schedule Sent',
                      color: '#3498db',
                      icon: <SendIcon />,
                      count: state.communicatorData.bookingReScheduleSentCount,
                      url: { pathname: '/BookingsRequesteList', search: `?status=3` },
                      isShowCustomer: true
                  }
              ]
            : [
                  {
                      id: 10,
                      name: 'Re-Schedule Sent',
                      color: '#3498db',
                      icon: <SendIcon />,
                      count: state.communicatorData.bookingReScheduleSentCount,
                      url: { pathname: '/BookingsRequesteList', search: `?status=2` }
                  },

                  {
                      id: 10,
                      name: 'Re-Schedule Received',
                      color: '#3498db',
                      icon: <ScheduleIcon />,
                      count: state.communicatorData.bookingReScheduleReceivedSentCount,
                      url: { pathname: '/BookingsRequesteList', search: `?status=3` },
                      isShowCustomer: true
                  }
              ]),

        {
            id: 10,
            name: 'Booking Confirmed',
            color: '#2ecc71',
            icon: <EventAvailableIcon />,
            count: state.communicatorData.bookingConfirmedCount,
            url: { pathname: '/BookingsRequesteList', search: `?status=4` },
            isShowCustomer: true
        },
        {
            id: 10,
            name: 'Booking Cancelled',
            color: '#dc3545',
            icon: <CancelPresentationIcon />,
            count: state.communicatorData.bookingCancelCount,
            url: { pathname: '/BookingsRequesteList', search: `?status=5` },
            isShowCustomer: true
        }
    ];

    const bookingReqShowModal = () => {
        setState((st) => ({ ...st, isBookingRequesteModal: true }));
    };

    const closeModal = (val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.isBookingRequesteModal = false;
            if (val) {
                newSt.isReload = new Date();
            }
            return newSt;
        });
    };

    const CommunicatorHeaderComp = () => {
        return (
            <div style={{ position: 'relative', paddingBottom: '10px' }}>
                {/* Bookings */}
                <div style={{ position: 'absolute', left: '0px', bottom: '0px', top: '-10px' }}>
                    <SecondaryButton onClick={bookingReqShowModal}>Request Booking</SecondaryButton>
                </div>
            </div>
        );
    };

    return (
        <div style={{ margin: 10 }}>
            <Grid container spacing={2} direction="row" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                {AppStorage.getCommunicatorAllowed() && (
                    <>
                        {isCustomer ? (
                            <>
                                <Grid item xs={12} style={{ padding: '6px 8px' }}>
                                    <RenderTiles
                                        cards={[...CommunicatorMemberSecOne, ...CommunicatorMemberSecTwo, ...CommunicatorMemberSecThree].filter(
                                            (p) => p.isShowCustomer
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ padding: '6px 8px' }}>
                                    <RenderTiles cards={[...CommunicatorBookingsSec].filter((p) => p.isShowCustomer)} label={CommunicatorHeaderComp()} />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} style={{ padding: '6px 8px' }}>
                                    <RenderTiles cards={CommunicatorBookingsSec} />
                                </Grid>
                                <Grid item xs={12} style={{ padding: '6px 8px' }}>
                                    <RenderTiles cards={CommunicatorMemberSecOne} />
                                </Grid>
                                <Grid item xs={12} xl={6} style={{ padding: '6px 8px' }}>
                                    <RenderTiles cards={CommunicatorMemberSecTwo} split={true} />
                                </Grid>
                                <Grid item xs={12} xl={6} style={{ padding: '6px 8px' }}>
                                    <RenderTiles cards={CommunicatorMemberSecThree} split={true} />
                                </Grid>
                                <Grid item xs={12} style={{ padding: '6px 8px' }}>
                                    <RenderTiles cards={state.communicatorLog} />
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
            {state.isBookingRequesteModal && (
                // <DialogComp title="Bookings" onClose={() => closeModal(false)} maxWidth="md" fullWidth overflow="auto">
                <BookingRequestedModal onClose={closeModal} />
                // </DialogComp>
            )}
        </div>
    );
};
export default CustCommunicatorDash;
