import { DialogTitle, Grid, Tooltip } from '@material-ui/core';
import { Assignment, Edit } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import { SecondaryButton } from '../../../Core/FormInput';
import { SquareButton } from '../../../Core/FormInput/AppButton';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import ReportGijgoGrid from '../../internal/ReportsScreens/GijgoForReportsScreen';
import AddEditSpecialEquipment from './AddEditSpecialEquipment';
import Barcode from 'react-barcode/lib/react-barcode';
import { History } from '@material-ui/icons';
import SpecialEquipmentHistoryModal from './HistoryModal';
import { useHistory } from 'react-router-dom';
import SpecialEquipmentBarcode from './SpecialEquipmentBarcode';
const modelgridStyle = {
    buttonFormtaer: {
        backgroundColor: '#183B68',
        borderRadius: '20%',
        width: '32px',
        height: '32px',
        textAlign: 'center',
        padding: '0px',
        marginBottom: '5px'
    }
};
const SpecialEquipment = (props) => {
    const [state, setState] = useState({
        showAddEdit: false,
        showHistory: false,
        showUsage: false,
        showBarcode: false,
        specialEquipmentID: null
    });

    const crumbs = () => [
        { name: 'Home', path: '/' },
        { name: 'Special Equipments', active: true }
    ];

    const baseColumns = [
        {
            field: 'action',
            sortable: false,
            title: `Action`,
            width: '9%',
            filterable: false
        },
        {
            field: 'specialEquipmentCode',
            title: 'Barcode',
            width: '10%'
        },
        {
            field: 'specialEquipmentDescription',
            title: 'Description',
            flex: 1
        },
        {
            field: 'specialEquipmentToolLocation',
            title: 'Tool Location',
            flex: 1
        },
        {
            field: 'specialEquipmentQuantity',
            title: 'Total Quantity',
            width: '10%'
        },
        {
            field: 'specialEquipmentQuantityAvailable',
            title: 'Quantity Available',
            width: '10%'
        },
        {
            field: 'branch',
            title: 'Branch',
            width: '10%'
        },
        {
            field: 'specialEquipmentCreatedBy',
            title: 'Created By',
            width: '10%'
        },
        {
            field: 'specialEquipmentIsActive',
            title: 'Active?',
            width: '5%'
        }
    ];

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        let val = record.specialEquipmentID;
        const lnk = (
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4}>
                    <SquareButton
                        style={modelgridStyle.buttonFormtaer}
                        onClick={() => {
                            handleModal('showAddEdit', true, val);
                            // setState((st) => ({
                            //     ...st,
                            //     specialEquipmentID: record.specialEquipmentID
                            // }));
                        }}
                    >
                        <Edit fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
                <Grid item xs={4}>
                    <SquareButton
                        style={modelgridStyle.buttonFormtaer}
                        onClick={() => {
                            let searchQuery = `id=${record?.specialEquipmentID}&code=${record?.specialEquipmentCode}&activeOnly=false`;
                            history.push({
                                pathname: `/SpecialEquipmentHistoryModal`,
                                search: searchQuery
                            });
                        }}
                    >
                        <History fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
                <Grid item xs={4}>
                    <SquareButton
                        style={modelgridStyle.buttonFormtaer}
                        onClick={() => {
                            let searchQuery = `id=${record?.specialEquipmentID}&code=${record?.specialEquipmentCode}&activeOnly=true`;
                            history.push({
                                pathname: `/SpecialEquipmentHistoryModal`,
                                search: searchQuery
                            });
                        }}
                    >
                        <Assignment fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleBarcodeClick = (s, record) => {
        handleModal('showBarcode', s, null);
        if (record) {
            setState((st) => ({
                ...st,
                focusedRow: record
            }));
        }
    };

    const barcodeField = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="space-evenly">
                <div style={{ cursor: 'pointer' }} onClick={() => handleBarcodeClick(true, record)}>
                    <Barcode value={record.specialEquipmentCode} />
                </div>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');
        let activeCol = tempCols.find((element) => element.field === 'specialEquipmentIsActive');
        let barcodeCol = tempCols.find((element) => element.field === 'specialEquipmentCode');

        if (actionCol) {
            actionCol.renderer = editButton({});
        }

        if (activeCol) {
            activeCol.renderer = (val) => (val ? 'Yes' : 'No');
        }

        if (barcodeCol) {
            barcodeCol.renderer = barcodeField({});
        }

        return tempCols;
    }, []);

    const handleModal = (modal, val, id) => {
        let newst = {};
        newst[modal] = val;
        newst.specialEquipmentID = id;
        setState((st) => ({
            ...st,
            ...newst
        }));
    };
    const handelClose = (res) => {
        let newst = {};
        newst.showAddEdit = false;
        newst.showBarcode = false;
        if (res) newst.isReload = new Date();
        setState((st) => ({
            ...st,
            ...newst
        }));
    };
    const history = useHistory();
    const params = history.location.state;

    let BaseUrl = () => {
        const branch = params?.branch;
        return `SpecialEquipment/SpecialEquipment/${branch ? branch : ''}`;
    };

    return (
        <div>
            <>
                {state.showBarcode && <SpecialEquipmentBarcode focusedRow={state.focusedRow} onClose={handelClose} />}
                <Grid item container justifyContent="space-between">
                    <Grid item>
                        <BreadCrumbs crumbs={crumbs()} />
                    </Grid>
                    <Grid item style={{ marginTop: '15px' }}>
                        <SecondaryButton variant="contained" color="secondary" onClick={() => handleModal('showAddEdit', true, null)} className="btnadd">
                            Add Special Equipment
                        </SecondaryButton>
                    </Grid>
                </Grid>

                <Grid item container spacing={1} xs={12} style={{}}>
                    <Grid item xs={12}>
                        <ReportGijgoGrid
                            dataConverter={(res) => ({
                                records: res?.data,
                                total: res?.data[0].total
                            })}
                            columns={columns}
                            getUrl={BaseUrl()}
                            isReload={state.isReload}
                        />
                    </Grid>
                </Grid>

                {state.showAddEdit ? (
                    <AddEditSpecialEquipment
                        specialEquipmentID={state.specialEquipmentID || null}
                        onClose={handelClose}
                        onFormSubmit={() => handleModal('showAddEdit', true, null)}
                    />
                ) : null}
            </>
        </div>
    );
};
export default SpecialEquipment;
