import React, { useState } from 'react';

import loadingImg from './bullet.svg';
import lensIcon from './Search.png';
import { get as _get, has as _has } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';

import { Grid, IconButton, Input, InputAdornment } from '@material-ui/core';
import { TextBox } from '../../../FormInput';
import { CustomIconButton } from '../../../FormInput/AppButton';
import { getApiData } from '../../../Service/apprisal-service';

let DataPuller = function ({ config, state, onFieldChange, otherConfig, screenState, autoFocus, pullPatchData, readOnly }) {
    let controlName = config.screenColumnJsonName;
    let controlValue = `${state[controlName] || ''}`;

    let [localState, setLocalState] = useState({
        loadingData: false
    });

    const pullDataFromServer = async () => {
        if (config.screenColumnControlValueApiEndPoint) {
            let actualUrl = config.screenColumnControlValueApiEndPoint;
            var templates = actualUrl.match(/{[\w.]+}/g);
            let toReplace = {};
            templates.forEach((pattern) => {
                pattern = pattern.trim();
                switch (pattern) {
                    case '{controlValue}':
                        toReplace[pattern] = controlValue;
                        break;
                    default:
                        let pth = pattern.match(/[\w.]+/g)[0];
                        if (_has(screenState, pth)) {
                            toReplace[pattern] = _get(screenState, pth);
                        }
                }
            });

            Object.keys(toReplace).forEach((key) => {
                actualUrl = actualUrl.replace(new RegExp(key, 'g'), toReplace[key]);
            });

            let res = await getApiData(actualUrl);
            if (res.success) {
                pullPatchData(controlName, res?.data?.mot);
                // setLocalState((st) => ({ ...st, loadingData: true }));
            } else {
                setLocalState((st) => ({ ...st, loadingData: false }));
            }
        }
    };

    return (
        <Grid item xs={12}>
            <TextBox
                type="text"
                autoFocus={autoFocus}
                onChange={onFieldChange}
                name={controlName}
                value={controlValue}
                disabled={readOnly}
                InputProps={{
                    endAdornment: (
                        <>
                            {localState.loadingData ? (
                                <img style={{ width: 10 }} src={loadingImg} />
                            ) : (
                                <SearchIcon
                                    position="start"
                                    onClick={readOnly ? () => false : pullDataFromServer}
                                    disabled={readOnly}
                                    style={{ cursor: readOnly ? 'none' : 'pointer' }}
                                />
                            )}
                        </>
                    )
                }}
            />
        </Grid>
    );
};

export default DataPuller;
