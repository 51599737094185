import React, { useContext, useState, useEffect, useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, Card, CardContent, Typography, Divider } from '@material-ui/core';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';

const RenderSignName = (props) => {
    const { name, sign, sigDate, label } = props;

    return sign || name ? (
        <Grid item xs={12} md={6} container>
            <Card style={{ minHeight: 260, width: '100%' }}>
                <CardContent>
                    <Typography variant="h6" color="secondary">
                        {label}-{name}
                    </Typography>
                    <Divider />
                    {sign ? (
                        <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', bottom: 10, right: 0 }}>{sigDate}</div>
                            <img src={sign} alt="my signature" style={{ border: '1px solid', height: 158, width: '100%' }} />
                        </div>
                    ) : (
                        <h1 style={{ textAlign: 'center' }}>No Signature found</h1>
                    )}
                </CardContent>
            </Card>
        </Grid>
    ) : null;
};
function PartReqOtherInfo(props) {
    const { requestor, requestorSign, requestorDate, issuer, issuerSign, issuerDate, approver, approverSign, approverDate } = props.data;
    return (
        <>
            <DialogComp title="Information" onClose={() => props.onClose(true)} fullWidth maxWidth="lg">
                <Grid container spacing={2}>
                    <RenderSignName label="Requestor" name={requestor} sign={requestorSign} sigDate={requestorDate} />
                    <RenderSignName label="Approver" name={approver} sign={approverSign} sigDate={approverDate} />
                    <RenderSignName label="Issuer" name={issuer} sign={issuerSign} sigDate={issuerDate} />
                </Grid>
            </DialogComp>
        </>
    );
}
export default PartReqOtherInfo;
