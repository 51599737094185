import { get, post } from './http-calls';

export const UpdateUserDetailsFranchise = async (operatorID, FranchiseVM, isUsedSalesExec, isNewSalesExec) => {
    let res = await post(
        `UserDetailsFranchise`,
        {
            operatorID: operatorID,
            FranchiseVM: FranchiseVM,
            isUsedSalesExec: isUsedSalesExec,
            isNewSalesExec: isNewSalesExec
        },
        { useAuthToken: true }
    );
    return res;
};

export const getUserDetailsFranchise = async (operatorID) => {
    let res = await get(`UserDetailsFranchise/GetDetails?Id=${operatorID}`, { useAuthToken: true });
    return res;
};
