import { get, post } from './http-calls';
import { memoize } from 'lodash';
import ProfitLoss from './../../Components/internal/Budget/profitLoss';
import moment from 'moment';

export const getReportsDropdowns = async () => {
    let res = await get('Reports/ScreenDropdown', { useAuthToken: true });
    return res;
};

export const getReportsDropdownsResolveData = async () => {
    return (await getReportsDropdowns()).data;
};

export const getReportsSalesDropdowns = async () => {
    let res = await get('Sales/ScreenDropdowns', { useAuthToken: true });
    return res;
};

export const getReportsSalesDropdownsResolveData = async () => {
    return (await getReportsSalesDropdowns()).data;
};

export const getRportDashByID = async (start, end, fid, bid) => {
    let FranchiseCode = `FranchiseCode=${fid.join('&FranchiseCode=')}`;
    let BranchID = bid.length > 0 ? `BranchID=${bid.join('&BranchID=')}` : '';
    let res = await get(`Reports/GetDashBoard?StartDate=${start}&EndDate=${end || ''}&${FranchiseCode}&${BranchID}`, {
        useAuthToken: true
    });
    return res;
};

export const getProfitLossbyID = async (start, end, fid, bid) => {
    let FranchiseCode = `FranchiseCode=${fid.join('&FranchiseCode=')}`;
    let BranchID = bid.length > 0 ? `BranchID=${bid.join('&BranchID=')}` : '';
    let res = await get(`Reports/GetProfitLoss?StartDate=${start}&EndDate=${end || ''}&${FranchiseCode}&${BranchID}`, { useAuthToken: true });

    return res;
};

export const GetWIPHeaderByWIPNumber = async (wp, cn) => {
    let res = await get(`AgedMeasures/GetWIPHeader?WIPNumber=${wp}&Company=${cn}`, { useAuthToken: true });
    return res;
};

export const GetExcelFileFromEndpoint = async (URL, FileName) => {
    let res = await get(URL, {
        responseType: 'blob',
        returnOrgRes: true,
        useAuthToken: true
    });
    if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
        link.id = 'tempDownloadPDFLink';
        link.href = url;
        link.setAttribute('download', `${FileName} ${moment().format('DD-MMM-YYYY HH:mm')}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return true;
    }
};

export const GetShiftCalender = async (BranchID, Code, Year) => {
    let res = await get(`ShiftCalender?BranchID=${BranchID}&Code=${Code}&Year=${Year}`, { useAuthToken: true });
    return res;
};

export const GetShiftCalenderScreenDropdowns = async (BranchID) => {
    let res = await get(`ShiftCalender/ScreenDropdowns?BranchID=${BranchID}`, { useAuthToken: true });
    return res;
};

export const GetResourceCalendar = async (BranchID, Code, Year) => {
    let res = await get(`ResourceCalendar?BranchID=${BranchID}&Code=${Code}&Year=${Year}`, { useAuthToken: true });
    return res;
};

export const GetResourceCalendarScreenDropdowns = async (BranchID) => {
    let res = await get(`ResourceCalendar/ScreenDropdowns?BranchID=${BranchID}`, { useAuthToken: true });
    return res;
};
export const GetBranchDeportmentScreenDropdowns = async () => {
    let res = await get(`Parts/BranchDeportmentScreenDropdowns`, {
        useAuthToken: true
    });
    return res;
};

export const GetBranchAndFranchiesDropdowns = async () => {
    let res = await get(`Services/BrancFranchiseScreenDropdowns`, {
        useAuthToken: true
    });
    return res;
};
