import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import DataGridComp from '../../../../Core/Grid';
import formatters from '../../../../Core/Grid/inputFormatter';

const VehicleDueDetailsList = (props) => {
    const [state, setState] = useState({
        rows: props.selectedVehicleDueList.map((p, i) => ({ ...p, id: i })) || []
    });

    const column_new = [
        {
            field: 'id',
            headerName: 'ID',
            width: 130,
            sortable: false,
            hide: true
        },

        { field: 'reg', sortable: false, headerName: 'Vehicle Reg', width: 140, align: 'center' },
        { field: 'dueType', sortable: false, headerName: 'Due Type', width: 120, align: 'center' },
        { field: 'vd', sortable: false, headerName: 'Vehicle Description', width: 250 },
        { field: 'fn', sortable: false, headerName: 'Franchise', width: 250 },
        { field: 'm', sortable: false, headerName: 'Model', width: 250 },
        { field: 'sc', sortable: false, headerName: 'Customer', width: 250 }
    ];

    return (
        <Grid container spacing={3} style={{ height: '78vh' }}>
            <Grid xs={12} sm={12}>
                <div
                    style={{
                        height: 'calc(100vh - 430px)'
                    }}
                >
                    <DataGridComp
                        columns={column_new}
                        rows={state.rows}
                        headerHeight={40}
                        isRowHovered={true}
                        enableSearch={true}
                        disableColumnMenu={true}
                        offset={340}
                        showFooter={true}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default VehicleDueDetailsList;
