import { createContext, useEffect, useState, useRef, useContext } from 'react';
import AppContext from '../../../App/AppContext';
import { getDefectByID, defectPostdata, DeleteDocByDocID } from '../../../Core/Service/defectTech-service';
import { AppStorage } from '../../../Core/Service/storage-service';
import formatters from '../../../Core/Grid/inputFormatter';

export const DefectScreenContext = createContext();

export const DefectProvider = (props) => {
    const { hideModal, showToast, userRoles, hideTopNavAndMenu } = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());

    const isInternal = userRoles.includes('internal');

    const navigateToDashboard = urlParams.get('navigate') === 'true';

    const fromStep = urlParams.get('fromstep') === 'true';

    const [inputList, setInputList] = useState([
        { defectDescription: '', defectActionedCategoryID: '', isRequiredBeforeMOTTest: false, checkDefectCreatedUserSignature: false }
    ]);

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const signteamleaderCanvas = useRef({});

    const clearLeaderSign = () => {
        if (state.imgteamleadersign) {
            setState((st) => ({
                ...st,
                imgteamleadersign: null,
                teamleadersign: null
            }));
        }
    };

    const checkFlag = (val) => {
        return val == 'true' ? true : false;
    };

    const [state, setState] = useState({
        createdUser: null,
        defectDateCreated: null,
        actionCreatedUser: null,
        defectDateActioned: null,
        defects: [],
        technicians: [],
        techniciansign: null,
        imgtechniciansign: null,
        imgteamleadersign: '',
        teamleadersign: '',
        wipNumber: '',
        registration: '',
        id: null,
        errors: {},
        showCircularLoader: true,
        isReadOnly: checkFlag(props.match.params?.isReadOnly),
        defectReportedCategories: [],
        defectActionedCategories: [],
        defectHeaderRecordID: props.match.params.jobId || null,
        headerRecords: {},
        userID: null,
        activeUser: null,
        defectDoctb: [],
        imgDateCreated: null,
        sortedImgList: []
    });

    const Id = props.match.params.jobId;

    const handleBtn = (e, id) => {
        let changedArray = [...inputList];
        changedArray[id].defectActionedCategoryID = e.defectActionedCategoryID;
        setInputList(changedArray);
    };

    const TechBtnClick = (userID) => {
        setState((st) => ({ ...st, userID: userID, activeUser: userID }));
    };

    const handleValidation = (val) => {
        let errors = {};
        const list = [...inputList];
        let formIsValid = true;
        if (val === 'submit') {
            if (list[0]['defectDescription'] === '') {
                formIsValid = false;
                errors['defectDescription'] = 'Description cannot be empty';
            }
            if (!state.teamleadersign) {
                formIsValid = false;
                errors['teamleadersign'] = 'Leader signature cannot be empty';
            }
            list.forEach((i) => {
                if (!i.defectActionedCategoryID && i.defectDescription) {
                    i.err_desc = 'Description cannot be empty';
                    formIsValid = false;
                } else {
                    i.err_desc = '';
                }
            });
            setInputList(list);
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const getDefect = async () => {
        let res = await getDefectByID(Id, state.userID);
        if (res.success) {
            const btnList = res.data?.defectReportedCategories;
            if (res.data) {
                setState((st) => ({
                    ...st,
                    technicians: res.data.technician,
                    defectReportedCategories: btnList,
                    defectActionedCategories: res.data?.defectActionedCategories,
                    showCircularLoader: false,
                    headerRecords: res.data.headerRecords[0],
                    // imgtechniciansign: res.data?.defects[0]?.defectCreatedUserSignature || null,
                    activeUser: res.data?.defects[0]?.defectCreatedUserID,
                    techniciansign: res.data?.defects[0]?.defectCreatedUserSignature || null,
                    // imgteamleadersign: res.data?.defects[0]?.defectActionedUserSignature || null,
                    // teamleadersign: res.data?.defects[0]?.defectActionedUserSignature || null,
                    createdUser: res.data.defects[0]?.createdUser || null,
                    defectDateCreated: res.data.defects[0]?.defectDateCreated || null,
                    actionCreatedUser: res.data.defects[0]?.actionCreatedUser || null,
                    defectDateActioned: formatters.DateTimeFormatter(res.data.defects[0]?.defectDateActioned),
                    defects: res.data?.defects,
                    defectDoctb: res.data?.defectDoctb || []
                }));
            }
            setInputList(
                !res.data?.defects?.length
                    ? [{ defectDescription: '', defectActionedCategoryID: '', isRequiredBeforeMOTTest: false, checkDefectCreatedUserSignature: false }]
                    : res.data?.defects.map((j) => ({ ...j, checkDefectCreatedUserSignature: false }))
            );
        } else {
            setTimeout(() => {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }, 1000);
        }
    };

    useEffect(() => {
        if (Id) {
            getDefect();
        }
    }, [state.userID]);

    const handleSubmit = async (value) => {
        const defectVM = inputList.map((m) => ({
            userSignature: m.checkDefectCreatedUserSignature ? state.teamleadersign : m.defectActionedUserSignature, // state.teamleadersign,
            defectID: m.defectID,
            reportedCategoryID: m.defectReportedCategoryID,
            actionedCategoryID: m.defectActionedCategoryID,
            description: m.defectDescription,
            isRequiredBeforeMOTTest: m.isRequiredBeforeMOTTest || false
        }));

        let data = {
            defectHeaderRecordID: state.defectHeaderRecordID,
            mode: 'U',
            defectVM: defectVM,
            defectDoctb: state.defectDoctb
                .filter((p) => {
                    if (p.isNewDoc) {
                        return p.isNewDoc;
                    }
                })
                .map((q) => {
                    let { docFile, ...rest } = q;
                    return { ...rest, documentImage: docFile };
                })
        };

        if (handleValidation(value)) {
            let res = await defectPostdata(data);
            if (res.success) {
                showToast(res.message);
                if (fromStep) {
                    setTimeout(() => {
                        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    }, 1000);
                }
            } else {
                showToast(res.error || 'Failed to saved defect.  Please try again.');
            }
        }
    };

    const GetTeamleaderSign = (o) => {
        setState((st) => {
            const nst = {
                ...st,
                ...o
            };
            return nst;
        });
    };
    const clearSig = () => {
        setState((st) => {
            let newSt = { ...st };
            newSt.techniciansign = null;
            return newSt;
        });
    };
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    const handleAddClick = () => {
        setInputList([...inputList, { defectDescription: '', defectActionedCategoryID: '', isRequiredBeforeMOTTest: false }]);
    };

    const useDefaultSig = () => {
        let userSign = AppStorage.getSignature();
        if (userSign) {
            signteamleaderCanvas.current.fromDataURL(AppStorage.getSignature());
            setState((st) => ({ ...st, teamleadersign: AppStorage.getSignature() }));
        } else {
            showToast('User signature not found');
        }
    };
    const onImageRemove = async (data) => {
        let tempData = [...state.sortedImgList];
        let imgList = [...state.defectDoctb];

        let objIndex = tempData.findIndex((x) => x.documentDateCreated === data.documentDateCreated);

        if (!data.isNewDoc) {
            let res = await DeleteDocByDocID(state.defectHeaderRecordID, data.documentID);
            if (res.success) {
                showToast(res.data.message);
            }
        }

        if (objIndex > -1) {
            tempData.splice(objIndex, 1);
            let tempInputList = [...inputList];
            let defectObjIndex = tempInputList.findIndex((a) => a.dateCreated == state.selectedDefect?.dateCreated);
            tempInputList[defectObjIndex].documentsCount = tempData.length || 0;

            let imgIndex = imgList.findIndex((b) => b.documentDateCreated == state.selectedDefect?.dateCreated);
            imgList.splice(imgIndex, 1);
            setInputList(tempInputList);
        }
        setState((st) => {
            return { ...st, sortedImgList: tempData, defectDoctb: imgList };
        });
    };
    // moment().format('YYYY-MM-DDTHH:mm:ss.SSS')

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (err) => reject(err);
        });

    const uploadFiles = async (e, arrName) => {
        const selectedFileList = await Promise.allSettled(
            Array.from(e.target.files).map(async (imageInput) => {
                if (imageInput) {
                    const docFile = await getBase64(imageInput);
                    return {
                        documentID: null,
                        documentDefectID: state.selectedDefect?.defectID || null,
                        docFile,
                        documentDateCreated: state.selectedDefect.dateCreated,
                        isNewDoc: true
                    };
                }
                return null;
            })
        );
        const successfulFiles = selectedFileList.filter((result) => result.status === 'fulfilled').map((result) => result.value);
        let combineList = [...state.sortedImgList, ...successfulFiles];
        let tempInputList = [...inputList];
        let objIndex = tempInputList.findIndex((a) => a.defectID == state.selectedDefect?.defectID);
        tempInputList[objIndex].documentsCount = combineList.length || 0;

        setInputList(tempInputList);

        setState((prevState) => ({
            ...prevState,
            isUploading: false,
            sortedImgList: combineList,
            defectDoctb: [...state.defectDoctb, ...state.sortedImgList, ...successfulFiles]
        }));
    };

    const docAction = (x, index) => {
        let sortedImgList = [];

        if (x.dateCreated) {
            sortedImgList = state.defectDoctb
                .filter((a) => {
                    if (a.documentDateCreated == x.dateCreated) {
                        return a;
                    }
                })
                .map((p) => ({ ...p, docFile: p.documentImage || p.docFile }));
        }

        setState((prevState) => ({
            ...prevState,
            showDoc: true,
            sortedImgList: sortedImgList || [],
            selectedDefect: x
        }));
    };

    const onCloseDocModal = () => {
        setState((st) => ({
            ...st,
            showDoc: false,
            imageList: []
        }));
        showToast('Make sure to click the "Save" or "Sign-Off" button to upload images');
    };

    const closeDialog = () => {
        setState((st) => ({
            ...st,
            showSignature: false
        }));
    };

    const showModal = (value, name) => {
        if (name == 'Actioned') {
            setState((st) => ({
                ...st,
                showSignature: true,
                sign: value.defectActionedUserSignature, //.defectCreatedUserSignature
                signedDate: value.defectDateActioned,
                signedBy: value.actionCreatedUser
            }));
        } else {
            setState((st) => ({
                ...st,
                showSignature: true,
                sign: value.defectSignature, //.defectCreatedUserSignature
                signedDate: value.defectSignedDate,
                signedBy: value.signedBy
            }));
        }
    };

    const handleCheck = (event, index) => {
        const { name, checked } = event.target;
        const list = [...inputList];
        if (index == 'all') {
            let newList = list.map((k) => ({
                ...k,
                checkDefectCreatedUserSignature: k.defectActionedUserSignature ? false : checked
            }));

            setInputList(newList);
        } else {
            list[index][name] = checked;
            setInputList(list);
        }
    };

    return (
        <DefectScreenContext.Provider
            value={{
                signteamleaderCanvas,
                state,
                fromStep,
                inputList,
                handleSubmit,
                handleAddClick,
                handleRemoveClick,
                handleInputChange,
                TechBtnClick,
                handleBtn,
                hideModal,
                clearLeaderSign,
                GetTeamleaderSign,
                isInternal,
                useDefaultSig,
                clearSig,
                closeDialog,
                docAction,
                uploadFiles,
                onImageRemove,
                onCloseDocModal,
                showModal,
                handleCheck
            }}
        >
            {props.children}
        </DefectScreenContext.Provider>
    );
};
