import { Grid } from '@material-ui/core';
import { Assignment, Edit } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import { SecondaryButton } from '../../../Core/FormInput';
import { SquareButton } from '../../../Core/FormInput/AppButton';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import ReportGijgoGrid from '../../internal/ReportsScreens/GijgoForReportsScreen';
import AddEditVorType from './AddEditVorType';
import VorReasons from './VorReasons';

const VorTypes = () => {
    const [state, setState] = useState({
        showAddEdit: false,
        showVorReasons: false,
    });

    const crumbs = () => [
        { name: 'Home', path: '/' },
        { name: 'VOR', active: true },
        { name: `VOR Types`, active: true }
    ];

    const baseColumns = [
        {
            field: 'action',
            sortable: false,
            title: `Action`,
            width: '110',
            filterable: false
        },
        {
            field: 'vorTypeID',
            title: `ID`,
            hidden: true
        },
        {
            field: 'vorTypeDescription',
            title: `Description`,
        },
        {
            field: 'vorTypeCode',
            title: `code`,
        },
    ];

    useEffect(() => {

    }, []);

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent='space-evenly'>
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => {
                        handleAddEdit(true)
                        setState((st) => ({
                            ...st,
                            focusedRow: record
                        }))
                    }}
                >
                    <Edit fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            showVorReasons: true,
                            focusedRow: record
                        }))
                    }}
                >
                    <Assignment fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');

        if (actionCol) {
            actionCol.renderer = editButton({});
        }

        return tempCols;
    }, []);

    const handleAddEdit = (val) => {
        if (val) {
            setState((st) => ({
                ...st,
                showAddEdit: val,
                isReload: false
            }))
        } else {
            setState((st) => ({
                ...st,
                showAddEdit: val,
                focusedRow: null,
                isReload: true
            }))
        }
    }

    let baseUrl = `VOR/VORTypes`;

    return (
        <div>
            {state.showVorReasons ?
                <>
                    <VorReasons focusedRow={state.focusedRow} />
                </>
                :
                <>
                    <Grid item container justify="space-between">
                        <Grid item>
                            <BreadCrumbs crumbs={crumbs()} />
                        </Grid>
                        <Grid item style={{ marginTop: '15px' }}>
                            <SecondaryButton
                                variant="contained"
                                color="secondary"
                                onClick={() => handleAddEdit(true)}
                                className="btnadd"
                            >
                                Add Vor Type
                            </SecondaryButton>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={1} xs={12} style={{}}>
                        <Grid item xs={12}>
                            <ReportGijgoGrid
                                dataConverter={(res) => ({
                                    records: res?.data,
                                    total: res?.data?.total
                                })}
                                columns={columns}
                                getUrl={baseUrl}
                                isReload={state.isReload}
                            />
                        </Grid>
                    </Grid>

                    {state.showAddEdit ? (
                        <DialogComp
                            title={
                                state.focusedRow ? `Edit Vor Type` : `Add Vor Type`
                            }
                            maxWidth="sm"
                            onClose={() => handleAddEdit(false)}
                            fullWidth
                        >
                            <AddEditVorType focusedRow={state.focusedRow} onClose={() => handleAddEdit(false)} />
                        </DialogComp>
                    ) : null}
                </>}
        </div>
    );
}
export default VorTypes;
