import React, { useState, useRef, useEffect } from 'react';
import './SignPad.scss';
import SignatureCanvas from 'react-signature-canvas';
import ByteImage from '../ByteImage/ByteImage';
import { PrimaryButton, SecondaryButton } from '../FormInput';
import { AppStorage } from '../Service/storage-service';
import BorderColorIcon from '@material-ui/icons/BorderColor';

function getInitState(props = {}) {
    return {
        showSubmitButton: props.showSubmitButton === undefined ? true : !!props.showSubmitButton,
        showClearButton: props.showClearButton === undefined ? true : !!props.showClearButton,
        showClearAllButton: !!props.showClearAllButton,
        imageFromCanvas: null,
        drawn: props.isDrawnImage,
        anyStroke: false
    };
}

export let SignPad = function (props) {
    let signPad = useRef(null);
    let bgImage = useRef(null);
    let [state, setState] = useState(getInitState(props));

    const useDefaultSig = () => {
        signPad.current.clear();
        let userSign = AppStorage.getSignature();
        if (userSign) {
            let img = signPad.current.fromDataURL(AppStorage.getSignature());
            signPad.current.fromDataURL(AppStorage.getSignature());

            setState((st) => ({
                ...st,
                imageFromCanvas: img
            }));
            if (props.onDone) {
                console.log('on Donmessssssssssssssss');
                props.onDone(userSign.split(',')[1], true);
            } else {
                console.log('Image captured.');
            }
        } else {
            console.error();
        }
    };

    function clearCanvas() {
        signPad.current.clear();
        setState((st) => ({ ...st, anyStroke: false }));
        if (props.dontRemoveBgOnClear) {
            setCanvasBackgroundImage();
        } else {
            setState((st) => ({ ...st, drawn: false }));
        }
        props.onClear && props.onClear();
    }

    function clearAllCanvas() {
        setState((st) => ({ ...st, drawn: false }));
        bgImage.current.src = `data:image/png;base64,${props.orgBgImage}`;
        setTimeout(() => {
            clearCanvas();
        }, 500);
    }

    function setSignImage() {
        let img = signPad.current.toDataURL('image/png');
        console.log(img, 'split col');
        setState((st) => ({
            ...st,
            imageFromCanvas: img
        }));

        if (props.handleclose) {
            props.handleclose();
        }
        if (props.onDone) {
            props.onDone(img.split(',')[1], state.drawn || state.anyStroke);
        } else {
            console.log('Image captured.');
        }
    }

    function setCanvasBackgroundImage() {
        let cnvs = signPad.current.getCanvas();

        if (bgImage.current) {
            if (props.resizeCanvasAsImage) {
                let ratioImg = { h: bgImage.current.height, w: bgImage.current.width };
                let ratioWindow = { h: window.innerHeight, w: Math.min(540, window.innerWidth - 60) };
                let newRatio;
                if (ratioImg.h / ratioImg.w > ratioWindow.h / ratioWindow.w) {
                    newRatio = { h: ratioWindow.h, w: (ratioWindow.h * ratioImg.w) / ratioImg.h };
                } else {
                    newRatio = { h: (ratioImg.h * ratioWindow.w) / ratioImg.w, w: ratioWindow.w };
                }

                cnvs.height = newRatio.h;
                cnvs.width = newRatio.w;
                cnvs.style.height = 'auto';
                cnvs.style.width = 'auto';
                let cntx = cnvs.getContext('2d');
                cntx.drawImage(bgImage.current, 0, 0, cnvs.width, cnvs.height);
            } else {
                signPad.current.fromDataURL(bgImage.current.src);
            }
        }
    }

    useEffect(() => {
        setCanvasBackgroundImage();
    }, [props.bgImage]);

    function start() {
        setState((st) => ({ ...st, anyStroke: true }));
    }

    function endDrawing() {
        props.submitOnDrawingEnd && setSignImage();
    }

    return (
        <div className="sign-pad-container">
            <div style={{ position: 'relative' }}>
                {props.bgImage && <ByteImage style={{ border: '1px dotted black', display: 'none' }} src={props.bgImage} imgRef={bgImage} />}
                <SignatureCanvas
                    clearOnResize={false}
                    ref={signPad}
                    penColor={props.penColor || 'black'}
                    canvasProps={{ className: 'sign-canvas' }}
                    onBegin={start}
                    onEnd={endDrawing}
                ></SignatureCanvas>
                {props.readOnly && <div className="readonly-wrapper"></div>}
                <div className="text-center" style={{ minHeight: '38px' }}>
                    {state.showSubmitButton && (
                        <PrimaryButton style={{ marginRight: 5 }} type="button" id="sig-submitBtn" onClick={setSignImage}>
                            {props.submitButtonText || 'Ok'}
                        </PrimaryButton>
                    )}
                    {state.showClearButton && !props.showCrossClearBtn && (
                        <SecondaryButton style={{ marginRight: 5 }} type="button" id="sig-clearBtn" onClick={clearCanvas}>
                            Clear
                        </SecondaryButton>
                    )}
                    {state.showClearAllButton && (
                        <SecondaryButton style={{ marginRight: 5 }} type="button" id="sig-clearAllBtn" onClick={clearAllCanvas}>
                            Clear All
                        </SecondaryButton>
                    )}
                </div>
                {props.showCrossClearBtn && !props.readOnly && (
                    <span
                        title="Undo"
                        onClick={clearCanvas}
                        style={{
                            position: 'absolute',
                            right: '3px',
                            top: '4px',
                            fontSize: '22px',
                            border: '1px solid lightslategrey',
                            padding: '0px 5px',
                            cursor: 'pointer'
                        }}
                    >
                        &#8630;
                    </span>
                )}
                {props.showDefaultSign && !props.readOnly && (
                    <span
                        title="Use default signature"
                        onClick={useDefaultSig}
                        style={{
                            position: 'absolute',
                            right: '40px',
                            top: '5px',
                            fontSize: 22,
                            border: '1px solid lightslategrey',
                            padding: '0px 8px',
                            cursor: 'pointer'
                        }}
                    >
                        <BorderColorIcon style={{ fontSize: '12px' }} />
                    </span>
                )}
            </div>
            {!props.onDone && <div>{state.imageFromCanvas && <img src={state.imageFromCanvas} />}</div>}
        </div>
    );
};
