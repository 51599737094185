import React from 'react';
import GijgoGrid from '../dashboard';
import { useState } from 'react';
import { useContext } from 'react';
import AppContext from '../../../App/AppContext';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { GetUserPartGridColumn, GetUserPreferenceWorkflowsList } from '../../../Core/Service/dashbordService';
import { useEffect } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import DialogComp from '../../../Core/Modal/dialogModal';
import FilterModalMainTabComponent from '../dashboard/FilterModalMainTabComp';
import PartsGridFilterColumnWrapper from './GridFilterColumnModal';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import { BasicButtonGroup } from '../../../Core/ButtonGroup';
import { getWorkflowsByProcessID } from '../../../Core/Service/workflow-service';
import _ from 'lodash';
import { AppStorage } from '../../../Core/Service/storage-service';
import { SpeakerNotes } from '@material-ui/icons';
import JobNotesModal from '../workflow-job/JobNotes';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Parts Dashboard', active: true },
    { name: 'Detail Lists', active: true }
];

let config = {
    // act: {
    //     from: '',
    //     to: '',
    //     getSequence: 'actionSequence',
    //     postSequence: 'actionSequence'
    // },
    wipNumber: {
        from: 'displayWIPNo',
        to: 'UserPartGridColumnDisplayWIPNo',
        getSequence: 'wipNoSequence',
        postSequence: 'WIPNoSequence'
    },
    registration: {
        from: 'displayVehicleReg',
        to: 'UserPartGridColumnDisplayVehicleReg',
        getSequence: 'vehicleRegSequence',
        postSequence: 'VehicleRegSequence'
    },
    makeModel: {
        from: 'displayModel',
        to: 'UserPartGridColumnDisplayModel',
        getSequence: 'modelSequence',
        postSequence: 'modelSequence'
    },
    customerName: {
        from: 'displayCustomer',
        to: 'UserPartGridColumnDisplayCustomer',
        getSequence: 'customerSequence',
        postSequence: 'customerSequence'
    },
    dateDueIn: {
        from: 'displayDateDueIn',
        to: 'UserPartGridColumnDisplayDateDueIn',
        getSequence: 'dateDueInSequence',
        postSequence: 'dateDueInSequence'
    },
    dateDueOut: {
        from: 'displayDateDueOut',
        to: 'UserPartGridColumnDisplayDateDueOut',
        getSequence: 'dateDueOutSequence',
        postSequence: 'dateDueOutSequence'
    },
    returnReason: {
        from: 'displayReturnReason',
        to: 'UserPartGridColumnDisplayReturnReason',
        getSequence: 'returnReasonSequence',
        postSequence: 'returnReasonSequence'
    },
    branchShortName: {
        from: 'displayBranch',
        to: 'UserPartGridColumnDisplayBranch',
        getSequence: 'branchSequence',
        postSequence: 'branchSequence'
    },
    franchiseName: {
        from: 'displayFranchise',
        to: 'UserPartGridColumnDisplayFranchise',
        getSequence: 'franchiseSequence',
        postSequence: 'franchiseSequence'
    },
    st: {
        from: 'displayJobDescription',
        to: 'UserPartGridColumnDisplayJobDescription',
        getSequence: 'jobDescriptionSequence',
        postSequence: 'jobDescriptionSequence'
    },
    accountNo: {
        from: 'displayAccount',
        to: 'UserPartGridColumnDisplayAccount',
        getSequence: 'accountSequence',
        postSequence: 'accountSequence'
    },
    cb: {
        from: 'displayCreatedBy',
        to: 'UserPartGridColumnDisplayCreatedBy',
        getSequence: 'createdBySequence',
        postSequence: 'createdBySequence'
    },
    assigned: {
        from: 'displayAssignedDate',
        to: 'UserPartGridColumnDisplayAssignedDate',
        getSequence: 'assignedDateSequence',
        postSequence: 'assignedDateSequence'
    },
    taken: {
        from: 'displayTimeTaken',
        to: 'UserPartGridColumnDisplayTimeTaken',
        getSequence: 'timeTakenSequence',
        postSequence: 'timeTakenSequence'
    },
    status: {
        from: 'displayStatus',
        to: 'UserPartGridColumnDisplayStatus',
        getSequence: 'statusSequence',
        postSequence: 'statusSequence'
    },
    assignedDays: {
        from: 'displayAssignedDays',
        to: 'UserPartGridColumnDisplayAssignedDays',
        getSequence: 'assignedDaysSequence',
        postSequence: 'assignedDaysSequence'
    },
    invoicingOperator: {
        from: 'displayInvoicingOperator',
        to: 'UserGridDisplayColumnInvoicingOperator',
        getSequence: 'invoicingOperator',
        postSequence: 'invoicingOperator'
    },
    route: {
        from: 'displayRoute',
        to: 'UserPartGridColumnDisplayRoute',
        getSequence: 'routeSequence',
        postSequence: 'routeSequence'
    },
    jobAssignedUser: {
        from: 'displayJobAssignedUser',
        to: 'UserPartGridColumnDisplayJobAssignedUser',
        getSequence: 'jobAssignedUserSequence',
        postSequence: 'jobAssignedUserSequence'
    },
    chassisNumber: {
        from: 'displayChassisNumber',
        to: 'UserPartGridColumnDisplayChassisNumber',
        getSequence: 'chassisNumberSequence',
        postSequence: 'chassisNumberSequence'
    }
};

const PartsByCreatorListComponent = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const ParamWorkflowID = JSON.parse(localStorage.getItem('FiltterParams'))?.PartsWorkflowID || params.get('workflowID') || '';
    const ParamTime = params.get('Time');
    const ParamBn = params.get('bn');
    const ParamCreatedBy = params.get('cb');
    const ParamProcessId = params.get('ProcessId');
    const [state, setState] = useState({
        tempObj: {},
        filterList: [],
        workflowButtons: [],
        currentWorkflowId: ParamWorkflowID === 'All' ? '' : ParamWorkflowID == 7 ? '' : ParamWorkflowID || '', //not show selected workflow if id is 7 (Means Archived)
        btnSelectedId: ParamWorkflowID === 'All' ? '' : ParamWorkflowID || '',
        hasQueryValues: ParamWorkflowID ? true : false,
        getUrl: undefined,
        dueTime: JSON.parse(localStorage.getItem('FiltterParams'))?.dueTime || ''
    });
    const { portalSettings, isAppReaload } = useContext(AppContext);
    const pullGridFilterList = async () => {
        let res = await GetUserPartGridColumn();
        if (res) {
            setState((st) => ({
                ...st,
                tempObj: res.data?.details[0] || {}
            }));
        }
    };

    const pullWorkFlowsButtons = async () => {
        let res = await getWorkflowsByProcessID(ParamProcessId || 3);
        if (res.success) {
            const buttons = res.data.map((w) => ({
                id: w.id,
                label: w.workflowName,
                displayJobPriorityButton: w.displayJobPriorityButton == 'Y' ? true : false,
                displayCustomerRatingButton: w.displayCustomerRatingButton == 'Y' ? true : false
            }));

            setState((st) => ({ ...st, workflowButtons: buttons }));
        }
    };

    const handleJobNotes = (val, on) => {
        setState((st) => ({
            ...st,
            jobNotes: on,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber
            // isReload: new Date()
        }));
    };

    const editButton = (id, val) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center">
                <Grid style={{ marginLeft: '13px 5px' }}>
                    <Tooltip title="Job Notes">
                        <IconButton size="small" onClick={() => handleJobNotes(val, true)}>
                            <SpeakerNotes style={{ color: val.hasNote ? 'gold' : val.returnReasonTextColourCode }} fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const baseColumns = [
        {
            title: 'Action',
            field: 'act',
            renderer: editButton,
            width: 95,
            filterable: false
        },
        { field: 'wipNumber', sortable: true, title: `${'WIP No'}`, width: 70 },
        { field: 'registration', sortable: true, title: `${'Vehicle Reg'}`, width: 100 },
        { field: 'makeModel', sortable: true, title: `${'Model'}`, width: 200 },
        { field: 'customerName', sortable: true, title: `${'Customer Name'}`, width: 170 },
        {
            field: 'dateDueIn',
            sortable: true,
            title: `${'Due Date In'}`,
            // type: 'date',
            type: 'dateTime',
            // flex: 0.6
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueIn != null) {
                    return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        {
            field: 'dateDueOut',
            sortable: true,
            title: `${'Date Due Out'}`,
            // type: 'date',
            type: 'dateTime',
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueOut != null) {
                    return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        { field: 'returnReason', sortable: true, title: `${'Return Reason'}`, width: 190 },
        { field: 'chassisNumber', sortable: true, title: 'Chassis Number', width: 180 },
        { field: 'branchShortName', sortable: true, title: `${'Branch'}`, width: 120 },
        { field: 'franchiseName', sortable: true, title: `${'Franchise'}`, width: 160 },
        { field: 'st', sortable: true, title: `${'Job Description'}`, width: 170 },
        { field: 'accountNo', sortable: true, title: `${'Account'}`, width: 80 },
        { field: 'cb', sortable: true, title: `${'Created By'}`, width: 100 },
        {
            field: 'assigned',
            sortable: true,
            title: `${'Assigned Date'}`,
            width: 120,
            renderer: function (value, record) {
                if (record.assigned != null) {
                    return moment(record.assigned).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        {
            field: 'taken',
            sortable: true,
            title: `${'Time Taken'}`,
            width: 100
        },
        {
            field: 'status',
            sortable: true,
            title: `${'Status'}`,
            width: 100
        },
        {
            field: 'assignedDays',
            sortable: true,
            title: `${'Assigned Days'}`,
            width: 110
        },
        {
            field: 'invoicingOperator',
            sortable: true,
            title: `${'Invoicing Operator'}`,
            width: 130
        },
        {
            field: 'jobAssignedUser',
            sortable: true,
            title: `${'Assigned User'}`,
            width: 110
        },
        {
            field: 'route',
            sortable: true,
            title: `${'Route'}`,
            width: 110
        }
    ];

    const regNumberRendrer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                onClick={() => setState((st) => ({ ...st, showMotHistory: true, regNo: record?.registration, FranchiseCode: record?.f }))}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    let Column = baseColumns
        .map((c) => {
            let obj = {};
            if (Object.keys(state.tempObj).length > 0 && !c.isShow) {
                obj.sequenceNo = state.tempObj[config[c.field]?.getSequence] || 9999999;
                if (c.title !== 'Action') {
                    obj.hidden = !state.tempObj[config[c.field]?.from];
                }
            } else {
                obj.hidden = false;
            }
            if (c.title !== 'Action' && c.field !== 'registration' && c.field !== 'chassisNumber') {
                obj.events = {
                    click: function (e) {
                        props.history.push(
                            `/Job/${e.data.record.headerRecordID}/${e.data.record.workflowID}?ProcessId=${ParamProcessId}&PartsWorkflowID=${state.currentWorkflowId}`
                        );
                    }
                };
            }
            if (c.field == 'registration') {
                c.renderer = regNumberRendrer({});
            }
            if (c.title == 'Action') {
                obj.sequenceNo = -1;
            }
            return {
                ...c,
                ...obj,
                headerCssClass: 'gridHeader'
            };
        })
        .sort((a, b) => {
            return a.sequenceNo - b.sequenceNo;
        });

    const handleOpen = () => {
        let tempArr = [...Column]
            .map((q) => {
                return {
                    field: q.field,
                    title: q.title,
                    hidden: !q.hidden,
                    fieldName: config[q.field]?.to,
                    isShow: q.isShow ? q.isShow : false,
                    postSequenceFieldName: config[q.field]?.postSequence
                };
            })
            .filter((p) => (!p.isShow && p.title != 'Action' ? p : null));

        setState((st) => ({ ...st, showFilter: true, filterList: tempArr }));
    };

    const closeDialog = (res) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.showFilter = false;
            if (res) newSt.isReload = new Date();
            return newSt;
        });
        res && pullGridFilterList();
        // res && pullWorkFlowsButtons();
    };

    const buttonClicked = async (btn) => {
        setState((st) => {
            const newSt = { ...st };
            if (!newSt.hasQueryValues) {
                newSt.returnReasonID = '';
            } else {
                newSt.hasQueryValues = false;
            }
            newSt.currentWorkflowId = `${btn.id}`;
            newSt.currentWorkflowLabel = btn.label;
            newSt.displayJobPriorityButton = btn.displayJobPriorityButton || false;
            newSt.displayCustomerRatingButton = btn.displayCustomerRatingButton || false;
            return newSt;
        });
    };

    useEffect(() => {
        if (isAppReaload) {
            pullGridFilterList();
            pullWorkFlowsButtons();
        }
    }, [isAppReaload]);

    useEffect(() => {
        let tempUrl = `PartsDashboard/GetPartJobs/${ParamWorkflowID == 7 ? 7 : state.currentWorkflowId}?time=${ParamTime || ''}&DueTime=${state.dueTime || ''}`;
        setState((st) => {
            const newSt = { ...st };
            newSt.getUrl = tempUrl;
            newSt.isReload = new Date();
            return newSt;
        });
    }, [state.currentWorkflowId]);

    const modalSubHeaderTitle = () => {
        return (
            <div>
                <span style={{ fontSize: '15px' }}>
                    WIP No: <b>{state.wip}</b> Reg No: <b>{state.reg}</b>
                </span>
            </div>
        );
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs>
                            <BasicButtonGroup
                                margin={6}
                                buttons={state.workflowButtons}
                                onClick={buttonClicked}
                                btnSelectedId={state.currentWorkflowId || null}
                                className="left-align-buttons"
                                // triggerButtonClickOnLoad={!state.hasQueryValues}
                                allGrid={true}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="filter" onClick={handleOpen} style={{ padding: '0px' }}>
                                <FilterListIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {state.getUrl && !_.isEmpty(state.tempObj) && (
                        <GijgoGrid
                            columns={Column}
                            getUrl={state.getUrl}
                            currentWorkflowLabel={state.currentWorkflowLabel}
                            currentWorkflowId={state.currentWorkflowId}
                            filterList={state.tempObj}
                            displayPagination={true}
                            isReload={state.isReload}
                            isGridColumnReload={state.isGridColumnReload}
                        />
                    )}
                </Grid>
            </Grid>
            {state.showFilter ? (
                <DialogComp title="My Preferences" maxWidth="lg" onClose={() => closeDialog(false)} overflow="auto" fullWidth>
                    <PartsGridFilterColumnWrapper
                        filterConfig={config}
                        filterList={state.filterList}
                        handleCancel={closeDialog}
                        // isPagination={state.displayPagination}
                    />
                </DialogComp>
            ) : null}
            {state.jobNotes ? (
                <JobNotesModal
                    modalSubHeaderTitle={modalSubHeaderTitle}
                    maxWidth="md"
                    jobId={state.headerRecordID}
                    onClose={() => handleJobNotes([], false)}
                    onNotesAdded={() => {
                        setState((st) => {
                            const newSt = { ...st };
                            newSt.isReload = new Date();
                            return newSt;
                        });
                    }}
                />
            ) : null}
        </div>
    );
};

export default PartsByCreatorListComponent;
