import { IconButton } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { GridOverlay } from '@material-ui/data-grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../../App/AppContext';
import { SecondaryButton } from '../../../Core/FormInput/index';
import DataGridComp from '../../../Core/Grid';
import AddBatteryResultsModal from './AddUpdateModal';
import { getBatteryResults } from '../../../Core/Service/BatteryResults-service';

const BatteryResults = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        BatteryResultsDetails: [],
        showDel: false
    });
    const { showToast } = useContext(AppContext);

    useEffect(async () => {
        pullBatteryResultsAndUpdateState();
    }, []);

    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                BatteryResultsDetails: params.value.row
            };
            return nst;
        });
    };

    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Battery Results" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const column_new = [
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 120,
            renderCell: (params) => <Arrow value={params} />
        },
        {
            field: 'id', //batteryTestResultCode
            headerName: 'Battery Result Code',
            width: 200
        },
        {
            field: 'batteryTestResultName',
            headerName: 'Battery Result Name',
            flex: 1
        }
    ];

    const pullBatteryResultsAndUpdateState = async () => {
        let result = await getBatteryResults();

        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data.list.map((w) => ({
                    ...w,
                    id: w.batteryTestResultCode,
                    batteryTestResultName: w.batteryTestResultName
                })),
                show: false,
                showDel: false
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };

    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                BatteryResultsDetails: null
            };
        });
    };

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Battery Results Available</div>
            </GridOverlay>
        );
    }

    const closeUpdateUser = (refreshGrid) => {
        setState((st) => ({ ...st, show: false, showDel: false }));
        refreshGrid && pullBatteryResultsAndUpdateState();
    };

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                    Add Battery Result
                </SecondaryButton>
            </div>
            {state.show ? <AddBatteryResultsModal onFormSubmit={closeUpdateUser} BatteryResultsDetails={state.BatteryResultsDetails} /> : null}
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Battery Results
                </Typography>
            </Breadcrumbs>
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={250} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default BatteryResults;
