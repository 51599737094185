import { get, post } from './http-calls';

export const getJobUserAssigned = async (HeaderRecordId) => {
    let res = await get(`JobUserAssigned?HeaderRecordId=${HeaderRecordId}`, { useAuthToken: true });
    return res;
};

export let uploadJobUserAssigned = async (formData) => {
    let data = await post(`JobUserAssigned`, formData, { useAuthToken: true });
    return data;
};

export const getWorkflowJobs = async (workflowId) => {
    let res = await get(`WorkflowJobs/${workflowId}`, { useAuthToken: true });
    return res;
};

export const getWorkflowDashBoard = async (workflowId) => {
    let res = await get(`DashBoard/Internal/${workflowId}`, { useAuthToken: true });
    return res;
};
export const getWorkflowDashBoardInternalScreenDropdown = async (workflowId) => {
    let res = await get(`Dashboard/InternalScreenDropdown`, { useAuthToken: true });
    return res;
};

export const getWSLStatus = async (bid = null, fn = null, status = null) => {
    let res = await get(`WSLStatus/GetWSLStatusList?${bid ? `BranchID=${bid}` : ''}${fn ? `&fn=${fn}` : ''}${status ? `&status=${status}` : ''}`, {
        useAuthToken: true
    });
    return res;
};
