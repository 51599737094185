import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PrimaryButton, PrimaryCheckbox, SecondaryButton, TextBox } from '../../../../../../Core/FormInput';
import { AttachFile } from '@material-ui/icons';
import { mailDocument, mailNewStockDocument } from '../../../../../../Core/Service/workflowJobDocument-service';
import { CircularProgress, FormControlLabel } from '@material-ui/core';

const MailDocModal = (props) => {
    const registration = props.jobBasicDetail?.registration;
    let fileName = `${props.docInfoToMail.ocrDocumentName}_REGNO_${registration}`;
    const [state, setState] = useState({
        toEmail: '',
        subject: `${props.docInfoToMail.ocrDocumentName} (RegNo - ${registration})`,
        body: '',
        attachmentName: `${fileName}.pdf`,
        ocrDocName: props.docInfoToMail.docName,
        allFilled: false,
        sending: false,
        stockID: props.jobId,
        ocrDocId: props.docInfoToMail.ocrDocIdToMail,
        sendAllVersions: false,
        isDownloadDoc: false,
        isNew: true
    });
    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const sendDoc = async () => {
        setState((st) => ({ ...st, sending: true }));
        // setTimeout(()=>{
        //     setState(st=>({...st, sending: false}));
        // }, 5000);
        let res = await mailNewStockDocument({ ...state });
        if (res.success) {
            props.onClose();
        }
        setState((st) => ({ ...st, sending: false }));
        props.showToast(res.message);
    };

    const updateState = (e) => {
        let { name, value } = e.target;
        setState((st) => {
            let newSt = { ...st };
            newSt[name] = value;
            newSt.allFilled = newSt.toEmail && newSt.subject;
            return newSt;
        });
    };

    const toggleSendAll = (e) => {
        let { checked } = e.target;
        setState((st) => ({ ...st, sendAllVersions: checked }));
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="md" className="dialog-custom">
            <DialogTitle>
                Mail Document
                <div className="title-header-actions">
                    <span className="close-icon" onClick={handleClose}>
                        &times;
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <TextBox
                    label="Email Address"
                    placeholder="user@mail.com"
                    required={true}
                    name="toEmail"
                    onChange={updateState}
                    value={state.toEmail}
                    autoFocus={true}
                />
                <FormControlLabel control={<PrimaryCheckbox checked={state.sendAllVersions} onChange={toggleSendAll} />} label="Send all versions" />
                <span className="primary-red-color">(This will send all the versions as single zip.)</span>
                <TextBox label="Subject" required={true} name="subject" onChange={updateState} value={state.subject} />
                <TextBox multiline rows="8" label="Mail Body" name="body" onChange={updateState} value={state.body} />
                <div className="attachment-name">
                    <AttachFile style={{ fontSize: '18px' }} />
                    <span>
                        {fileName}.{state.sendAllVersions ? 'zip' : 'pdf'}
                    </span>
                    {state.sendAllVersions && <span className="primary-red-color">&emsp;(As link in mail.)</span>}
                </div>
            </DialogContent>
            <DialogActions>
                <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={sendDoc} disabled={!state.allFilled || state.sending}>
                    Send
                    {state.sending && <CircularProgress size={24} className="sending-loader" />}
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

export default MailDocModal;
