import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import DataGridComp from '../../../../Core/Grid';
import formatters from '../../../../Core/Grid/inputFormatter';

const BookingDetailsList = (props) => {
    const [state, setState] = useState({
        rows: props.selectedBookingList || []
    });

    const column_new = [
        {
            field: 'id',
            headerName: 'ID',
            width: 130,
            sortable: false,
            hide: true
        },
        { field: 'wipNumber', sortable: false, headerName: 'WIP No', width: 90 },
        { field: 'registration', sortable: false, headerName: 'Vehicle Reg', width: 110 },
        { field: 'customerName', sortable: false, headerName: 'Customer Name', width: 250 },
        {
            field: 'dateDueIn',
            sortable: false,
            headerName: 'Date Due In',
            width: 145,
            renderCell: (params) => formatters.DateTimeFormatter(params.row.dateDueIn)
        },
        {
            field: 'dateDueOut',
            sortable: false,
            headerName: 'Date Due Out',
            width: 145,
            renderCell: (params) => formatters.DateTimeFormatter(params.row.dateDueOut)
        },
        { field: 'st', sortable: false, headerName: 'Job Description', width: 200 },
        { field: 'status', sortable: false, headerName: 'Status', width: 200 },
        { field: 'cb', sortable: false, headerName: 'Created By', width: 120 },
        { field: 'owningOperator', sortable: false, headerName: 'Owned By', width: 120 },
        { field: 'makeModel', sortable: false, headerName: 'Model', width: 250 }
    ];
    // .map((c) => ({ ...c, cellClassName: getClass }));

    return (
        <Grid container spacing={3} style={{ height: '78vh' }}>
            <Grid xs={12} sm={12}>
                <div
                    style={{
                        height: 'calc(100vh - 430px)'
                        // width: `${windowWidths < 800 ? '700px' : ''}`

                        // marginTop: '-25px'
                    }}
                >
                    <DataGridComp
                        columns={column_new}
                        rows={state.rows}
                        headerHeight={40}
                        isRowHovered={true}
                        enableSearch={true}
                        disableColumnMenu={true}
                        offset={340}
                        showFooter={true}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default BookingDetailsList;
