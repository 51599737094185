import React, { useContext, useMemo, useState } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel, IconButton, Dialog, Switch } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { AppStorage } from '../../../../../Core/Service/storage-service';

import EditIcon from '@material-ui/icons/Edit';
import DialogComp from '../../../../../Core/Modal/dialogModal';
// import StockChargeModal from './stockingChagargeModal';
import { SquareButton } from '../../../../../Core/FormInput/AppButton';
import { postFinanceCustomer, postStockingCharges } from '../../../../../Core/Service/SalesPerformance-service';
import AppContext from '../../../../../App/AppContext';

const baseColumns = [
    { field: 'customerFinancePenetration', title: `Action`, align: 'center', filterable: false, sortable: false, width: 80 },
    { field: 'name', sortable: false, title: `Customer Name`, filterable: true, align: 'left' },
    { field: 'customerCode', sortable: false, title: `Code`, filterable: true, width: 140, align: 'center' },
    { field: 'address', title: `Address`, filterable: true, sortable: false },

    { field: 'contactName', title: `Contact Name`, filterable: true, sortable: false, width: 300 },
    { field: 'phone', title: `Phone No.`, filterable: true, sortable: false, width: 200 }
];
const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },

    { name: 'Finance Penetration Accounts', active: true }
];

const FinanceCustomer = (props) => {
    const { showToast } = useContext(AppContext);
    let menuPermissionCounter = AppStorage.menuPermissionCounter();
    const history = useHistory();
    const [state, setState] = useState({
        showModal: false,
        stockObj: {},
        isReload: ''
    });

    const HandleScreen = (value, record) => async (e) => {
        const { checked } = e.target;
        let data = {
            customerID: record.customerId,
            customerFinancePenetration: checked
        };
        console.log(checked, 'checked', record);
        let res = await postFinanceCustomer(data);
        if (res.success) {
            showToast(
                checked
                    ? `${record?.name} Couriers Services marked as finance penetration Account`
                    : `${record?.name} Couriers Services removed as finance penetration Account`
            );
        }

        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid title={value} style={{ cursor: 'pointer', textAlign: 'center' }}>
                <Switch
                    checked={value == 'YES' ? true : false}
                    onChange={HandleScreen(value, record)}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'customerFinancePenetration');

        if (actionCol) {
            actionCol.renderer = editButton;
        }

        return tempCols;
    }, []);

    const baseUrl = `Customers/ReportingPortal_GetAllCustomers`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(props.MeasureDesc, menuPermissionCounter)} />
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res.data?.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isShowTotal={false}
                        hidePagination={false}
                        hideFilterRow={false}
                        isReload={state.isReload}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default FinanceCustomer;
