import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { blue } from '@material-ui/core/colors';
import { border } from '@material-ui/system';
import { Grid } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#29308C',
        color: theme.palette.common.white,
        padding: 6,
        border: '1px solid black',
        fontSize: 14
        // borderRight: '3px solid white'
    },
    body: {
        fontSize: 11,
        border: '1px solid ',
        padding: 0,
        backgroundColor: '#29308C',
        color: theme.palette.common.white
    }
}))(TableCell);

const SkyblueCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#1ECBE1',
        color: theme.palette.common.white,
        padding: 6,
        border: '1px solid black',
        fontSize: 14,
        textAlign: 'center'
        // borderRight: '3px solid white'
    }
}))(TableCell);

const EmptyCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'white',
        color: theme.palette.common.white,
        padding: 6,
        // border: '1px solid black',
        fontSize: 14,
        textAlign: 'center'
        // borderRight: '3px solid white'
    }
}))(TableCell);

const WipCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#34B5C4',
        color: theme.palette.common.white,
        padding: 6,
        border: '1px solid black',
        fontSize: 14,
        textAlign: 'center'
        // borderRight: '3px solid white'
    }
}))(TableCell);
const EomCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#E54CDF',
        color: theme.palette.common.white,
        padding: 6,
        border: '1px solid black',
        fontSize: 14,
        textAlign: 'center'
        // borderRight: '3px solid white'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    // root: {
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.action.hover
    //     }
    // }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        width: '100%'
    }
});

export default function BudgetTableOne() {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <EmptyCell component="th" scope="row" width="7%"></EmptyCell>
                        <SkyblueCell width="15%">Invoicing (Hrs)</SkyblueCell>
                        <SkyblueCell width="15%">Invoicing (£'s)</SkyblueCell>
                        <SkyblueCell width="15%">Recovery Rate</SkyblueCell>
                        <WipCell>WIP</WipCell>
                        <EomCell width="15%">EOM Forecast (£)</EomCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell align="center">Location</StyledTableCell>
                        <StyledTableCell>
                            <Grid container justifyContent="space-evenly" alignItems="center">
                                <Grid item xs={4} className="brw alignCenter p10">
                                    Inv (MTD)
                                </Grid>
                                <Grid item xs={4} className="brw alignCenter p10">
                                    Re-Fcast (MTD)
                                </Grid>
                                <Grid item xs={4} className=" alignCenter p10">
                                    Variance
                                </Grid>
                            </Grid>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Grid container justifyContent="space-evenly" alignItems="center">
                                <Grid item xs={4} className="brw alignCenter p10">
                                    Inv (MTD)
                                </Grid>
                                <Grid item xs={4} className="brw alignCenter p10">
                                    Re-Fcast (MTD)
                                </Grid>
                                <Grid item xs={4} className=" alignCenter p10">
                                    Variance
                                </Grid>
                            </Grid>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Grid container justifyContent="space-evenly" alignItems="center">
                                <Grid item xs={4} className="brw alignCenter p10">
                                    Recovery Rate
                                </Grid>
                                <Grid item xs={4} className="brw alignCenter p10">
                                    Re-Fcast
                                </Grid>
                                <Grid item xs={4} className=" alignCenter p10">
                                    Variance
                                </Grid>
                            </Grid>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Grid container justifyContent="space-evenly" alignItems="center">
                                <Grid item xs={2} className="brw alignCenter p10">
                                    Pre Books
                                </Grid>
                                <Grid item xs={2} className="brw alignCenter p10">
                                    0 to 30
                                </Grid>
                                <Grid item xs={1} className="brw alignCenter p10">
                                    30+
                                </Grid>
                                <Grid item xs={1} className="brw alignCenter p10">
                                    Wip Days
                                </Grid>
                                <Grid item xs={2} className="brw alignCenter p10">
                                    Hours in WIP
                                </Grid>
                                <Grid item xs={2} className="brw alignCenter p10">
                                    Parts Value in WIP (£)
                                </Grid>
                                <Grid item xs={2} className=" alignCenter p10">
                                    Avg Labour Hours value
                                </Grid>
                            </Grid>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Grid container justifyContent="space-evenly" alignItems="center">
                                <Grid item xs={4} className="brw alignCenter p10">
                                    Invoice Projection
                                </Grid>
                                <Grid item xs={4} className="brw alignCenter p10">
                                    Invoice Re-Fcast
                                </Grid>
                                <Grid item xs={4} className=" alignCenter p10">
                                    Variance
                                </Grid>
                            </Grid>
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
