import React, { useEffect, useContext, useState } from 'react';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions, SecondaryCheckbox, Multiselect } from '../../../Core/FormInput';
import Grid from '@material-ui/core/Grid';
import { getGridFilterList, postGridFilterList } from '../../../Core/Service/workflow-service';
import AppContext from '../../../App/AppContext';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { find, some } from 'lodash';
import Switch from '@material-ui/core/Switch';
import { MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { DragNDropWrapperComponent } from './DragNDropSequence';

const initState = {};
let GridFilterModal = (props) => {
    const { showToast, setUserPreference } = useContext(AppContext);

    const [state, setState] = useState({
        filterList: [],
        languageList: [],
        languageCode: {},
        UserPreferenceWorkflowsList: []
    });

    return (
        <>
            <Grid container style={{ height: '400px', width: '100%', overflow: 'auto' }}>
                <Grid item xs={12}>
                    <DragNDropWrapperComponent
                        CompList={[...props.filterList].filter((o) => o.field !== 'pagination') || []}
                        fieldValues={props.fieldValues}
                        onChange={props.handleCheckbox}
                        getGridSequenceList={props.getGridSequenceList}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default GridFilterModal;
