import React, { useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    FormLabel,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { getTechnicianAssignedJobs, GetTechBranchList, GetTechByBranchID } from '../../Service/technicianAssignedService';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AssignTech from '../../../Components/internal/assign-technician/assignTechnModal';
import DialogComp from '../../Controls/Dialog/DialogComp';

const useStyles = makeStyles({
    table: {
        width: '100%'
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'rgba(0,0,0,0.9)',
        color: theme.palette.common.white,
        fontSize: 15,
        padding: '5px 10px'
    },
    body: {
        fontSize: 12,
        padding: '3px 5px',
        borderBottom: '1px solid rgba(0,0,0,0.1)'
    }
}))(TableCell);

const StyledTableHeaderCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'white',
        color: theme.palette.common.black,
        fontSize: 10,
        padding: '1px 8px',
        borderBottom: '1px solid rgba(0,0,0,0.1)'
        // borderRight: '0.5px solid rgba(0,0,0,0.09) '
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover
        // }
    }
}))(TableRow);

const TechnicianCalender = (props) => {
    const history = useHistory();
    const classes = useStyles();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamTid = params.get('tid') || '';
    let ParamBnm = params.get('bnm') || '';

    const [state, setState] = useState({
        filterData: [],
        branchList: [],
        branchValue: {},
        branchValueID: null,
        branchValueName: '',
        TechnicianList: [],
        filterData: [],
        showTechAssign: false,
        technicianID: '',
        technicianName: '',
        technician: {},
        TechnicianRow: [],
        newFilterData: [],
        showLoader: true
    });

    const handleBtnClcik = (val) => {
        history.push(`/Job/${val.headerRecordID}/${val.workflowID}`);
    };

    const handleAssignmentBtnClick = (val) => {
        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            showTechAssign: true
        }));
    };
    const closeTechAssignDialog = (res) => {
        setState((st) => ({ ...st, showTechAssign: false }));
    };

    useEffect(async () => {
        let res = await GetTechBranchList();
        if (res.success) {
            setState((st) => ({
                ...st,
                branchList: res.data?.map((k) => ({
                    description: k.name,
                    id: k.id
                }))
            }));
        }
    }, []);

    useEffect(async () => {
        if (state.branchValueID) {
            let res = await GetTechByBranchID(state.branchValueID);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    TechnicianList: res.data.map((k) => ({
                        description: k.name,
                        id: k.id
                    })),
                    showLoader: false
                }));
            }
        }
    }, [state.branchValueID]);

    useEffect(async () => {
        if (state.branchValueName) {
            let res = await getTechnicianAssignedJobs(ParamTid, state.branchValueName);
            if (res.success) {
                let TechnecianRows = [...res.data.rows];
                setState((st) => ({
                    ...st,
                    TechnicianRow: TechnecianRows,
                    showLoader: false
                }));
            }
        }
    }, [state.branchValueName]);

    useEffect(async () => {
        if (state.branchList.length > 0) {
            let brachObj = state.branchList.find((k) => k.description == ParamBnm);
            setState((st) => ({
                ...st,
                branchValue: brachObj,
                branchValueID: brachObj.id,
                branchValueName: brachObj.description
            }));
        }
    }, [ParamBnm, state.branchList]);

    useEffect(async () => {
        let filterData = [];

        state?.TechnicianList.forEach((m) => {
            let index = state?.TechnicianRow.filter((n) => m.description == n.userName);
            if (index) {
                filterData.push({ Technician: m.description, data: [...index] });
            } else {
                filterData.push({ Technician: m.description, data: [] });
            }
        });

        setState((st) => ({
            ...st,
            showLoader: false,
            filterData: filterData
        }));
    }, [state.TechnicianRow, state.TechnicianList]);

    useEffect(async () => {
        let newFilterData = [];
        if (state.technicianName) {
            newFilterData = state.filterData.filter((n) => state.technicianName == n.Technician);
        }
        setState((st) => ({
            ...st,
            newFilterData: newFilterData,
            showLoader: false
        }));
    }, [state.technicianName]);

    const handleAutoChange = (fieldName) => (e, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt[fieldName] = val;
            newSt.showLoader = true;
            if (fieldName == 'technician') {
                newSt.technicianID = val.id;
                newSt.technicianName = val.description;
            }
            if (fieldName == 'branchValue') {
                newSt.branchValueID = val.id;
                newSt.branchValueName = val.description;
            }

            return newSt;
        });
    };
    let list = state.technicianName ? state.newFilterData : state.filterData;

    return (
        <div style={{ padding: '10px', position: 'relative' }}>
            <Grid container>
                <Grid container item spacing={2} style={{ marginBottom: '1px' }}>
                    <Grid item xs={3}>
                        <FormLabel>Branch</FormLabel>
                        <Autocomplete
                            disableClearable
                            autoSelect
                            options={state.branchList || []}
                            name="Month"
                            value={state.branchValue}
                            onChange={handleAutoChange('branchValue')}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormLabel>Technician</FormLabel>
                        <Autocomplete
                            disableClearable
                            autoSelect
                            options={state.TechnicianList}
                            name="Month"
                            value={state.technician}
                            onChange={handleAutoChange('technician')}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                        />
                    </Grid>
                </Grid>
                {state.showLoader ? (
                    <Grid item sm={12} container alignItems="center" justifyContent="center" style={{ height: 400 }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid container item spacing={2}>
                        {list.map((k) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={4}>
                                    <div style={{ padding: 10, background: 'white' }}>
                                        <TableContainer
                                            // component={Paper}
                                            style={{
                                                minHeight: 250
                                            }}
                                        >
                                            <Table className={classes.table} aria-label="customized table" stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell colSpan={4}>{k.Technician}</StyledTableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <StyledTableHeaderCell width="10%">Action</StyledTableHeaderCell>
                                                        <StyledTableHeaderCell width="20%">WIP No.</StyledTableHeaderCell>
                                                        <StyledTableHeaderCell width="20%">REG No.</StyledTableHeaderCell>
                                                        <StyledTableHeaderCell width="50%">Job Description</StyledTableHeaderCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {k.data.map((j) => {
                                                        return (
                                                            <StyledTableRow
                                                                style={{ backgroundColor: j.returnReasonColourCode, color: j.returnReasonTextColourCode }}
                                                            >
                                                                <StyledTableCell>
                                                                    <Grid container>
                                                                        <Grid>
                                                                            <IconButton size="small" onClick={() => handleBtnClcik(j)}>
                                                                                <ArrowForwardIosIcon
                                                                                    style={{
                                                                                        color: j.returnReasonTextColourCode,
                                                                                        fontSize: 14
                                                                                    }}
                                                                                    fontSize="small"
                                                                                />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid>
                                                                            {j.workflowID == 8 && (
                                                                                // <Tooltip title={val.technicians} aria-label="add" disableFocusListener>
                                                                                <IconButton
                                                                                    title={`${j.userName}`}
                                                                                    size="small"
                                                                                    onClick={() => handleAssignmentBtnClick(j)}
                                                                                >
                                                                                    <AssignmentIndIcon
                                                                                        style={{
                                                                                            color: j.returnReasonTextColourCode,
                                                                                            fontSize: 14
                                                                                        }}
                                                                                        fontSize="small"
                                                                                    />
                                                                                </IconButton>
                                                                                // </Tooltip>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    style={{
                                                                        color: j.returnReasonTextColourCode
                                                                    }}
                                                                >
                                                                    {j.wipNumber}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    style={{
                                                                        color: j.returnReasonTextColourCode
                                                                    }}
                                                                >
                                                                    {j.registration}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    style={{
                                                                        color: j.returnReasonTextColourCode
                                                                    }}
                                                                >
                                                                    {j.serviceText}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
                {state.showTechAssign ? (
                    <DialogComp title="Assigned Technician" maxWidth="sm" onClose={() => closeTechAssignDialog(false)} fullWidth>
                        <AssignTech jobId={state.jobId} handleCancel={closeTechAssignDialog} />
                    </DialogComp>
                ) : null}
            </Grid>
        </div>
    );
};

export default TechnicianCalender;
