import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useWindowSize } from '../Controls/ScreenResolution';

export const EWIPDashboardScreenContext = createContext();

let setObject = {
    ['<24hr']: '#00F433',
    ['1-5 days']: '#88EBA4',
    ['5-10 days']: '#FFBF00',
    ['10-20 days']: '#FFE28A',
    ['21-30 days']: '#bc4443',
    ['>30 days']: '#F11C0E'
};

let timenumber = {
    ['<24hr']: 1,
    ['1-5 days']: 2,
    ['5-10 days']: 3,
    ['10-20 days']: 4,
    ['21-30 days']: 5,
    ['>30 days']: 6
};

const COLORS = [
    '#0074D9',
    '#FF4136',
    '#2ECC40',
    '#FF851B',
    '#7FDBFF',
    '#B10DC9',
    '#FFDC00',
    '#001f3f',
    '#39CCCC',
    '#01FF70',
    '#85144b',
    '#F012BE',
    '#3D9970',
    '#111111',
    '#AAAAAA'
];

function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = '#' + hex.toString(16);

    return color;
}

export const EWipDashProvider = (props) => {
    const { state } = props;

    const history = useHistory();
    const [array, setArray] = useState({
        workflowReturnReasonArray: [],
        keys: [],
        byTimeObj: {},
        selectRRarr: [],
        showLoader: true,
        selectedFilterTechData: [],
        techJobsTotal: 0,
        hourType: 'remaining',
        assignedDate: 2,
        StatusList: [],
        franchise: 'All',
        statusBranchTotal: 0,
        franchiseArray: [],
        assignedJobs: [],
        selectedUser: [],
        selectedPieChartType: 'A',
        byAssignedUser: state.selectedUser || 'Default',
        assignedJobsTotals: 0
    });

    useEffect(() => {
        setArray((st) => ({ ...st, byAssignedUser: state.selectedUser }));
    }, [state.selectedUser]);

    useEffect(() => {
        if (state?.assignedJobs.length > 0) {
            let allTotal = 0;
            if (state?.assignedJobs.length > 0 && array.byAssignedUser == 'Default') {
                const allAssignedJobsData = state?.assignedJobs.reduce((acc, obj) => {
                    allTotal += obj.total;
                    const index = acc.find((item) => item.workflowID === obj.workflowID);
                    index ? (index.total += obj.total) : acc.push({ ...obj });
                    return acc;
                }, []);

                setArray((st) => ({ ...st, assignedJobs: allAssignedJobsData, assignedJobsTotals: allTotal }));
            } else {
                const allAssignedJobsData = state?.assignedJobs.filter((item) => {
                    if (item.userID === array.byAssignedUser) {
                        allTotal += item.total;
                    }
                    return item.userID === array.byAssignedUser;
                });

                setArray((st) => ({ ...st, assignedJobs: allAssignedJobsData, assignedJobsTotals: allTotal }));
            }
        }
    }, [state?.assignedJobs, array.byAssignedUser, state.selectedUser]);

    const redirectToAssignedJobs = (val, isAll) => {
        let searchQuery = `workflowId=${val?.workflowID || ''}&assignedUserID=${array.byAssignedUser == 'Default' ? '' : array.byAssignedUser}`;
        history.push({
            pathname: '/AssignUserdashgrid',
            search: searchQuery,
            state: {}
        });
    };

    // useEffect(() => {
    //     if (!state?.assignedJobs?.length) return;

    //     let allTotal = 0;
    //     const isDefaultUser = array.byAssignedUser === 'Default';

    //     const allAssignedJobsData = state.assignedJobs.reduce((acc, job) => {
    //         // If filtering by user, skip items that don't match `byAssignedUser`
    //         if (!isDefaultUser && job.userID !== array.byAssignedUser) return acc;

    //         allTotal += job.total;

    //         if (isDefaultUser) {
    //             // Group by workflowID
    //             const existingJob = acc.find((item) => item.workflowID === job.workflowID);
    //             existingJob ? (existingJob.total += job.total) : acc.push({ ...job });
    //         } else {
    //             // Just push when filtering by specific user
    //             acc.push(job);
    //         }

    //         return acc;
    //     }, []);

    //     console.log(allAssignedJobsData, 'allAssignedJobsData');
    //     console.log(allTotal, 'allTotal');
    //     setArray((st) => ({ ...st, assignedJobs: allAssignedJobsData, assignedJobsTotals: allTotal }));
    // }, [state?.assignedJobs, array.byAssignedUser]);

    // useEffect(() => {
    //     if (state?.uniqueFranchises.length > 0) {
    //         setArray((st) => ({ ...st, franchise: state?.uniqueFranchises[0]?.name }));
    //     }
    // }, [state?.uniqueFranchises]);

    useEffect(() => {
        if (array?.StatusList.length > 0) {
            if (array.byTimeBranch && array.byTimeBranch !== 'Default') {
                let total = array?.StatusListtotal.reduce((acc, obj) => {
                    const index = acc.find((item) => item.branchID === obj.branchID);
                    index ? (index.jobs += obj.jobs) : acc.push({ ...obj });
                    return acc;
                }, []);

                let Franchise = [...state?.uniqueBranchFranchises].filter((k) => k.branch === array.byTimeBranch);

                setArray((st) => ({ ...st, statusBranchTotal: total[0].jobs, franchiseArray: Franchise, franchise: Franchise[0].name }));
            } else {
                let total = array?.StatusListtotal.reduce((acc, obj) => {
                    const index = acc.find((item) => item.franchise !== 'all' && obj.franchise !== 'all');
                    index ? (index.jobs += obj.jobs) : acc.push({ ...obj });
                    return acc;
                }, []);

                setArray((st) => ({
                    ...st,
                    statusBranchTotal: total[0].jobs,
                    franchiseArray: state.uniqueFranchises,
                    franchise: 'All' //state.uniqueFranchises[0].name
                }));
            }
        }
    }, [array.StatusList, array.byTimeBranch, state?.uniqueFranchises, state?.uniqueBranchFranchises]);

    const [width, height] = useWindowSize();

    useEffect(() => {
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));

        if (state.franchiseStatus.length > 0) {
            if (btTimeData?.branchShortName) {
                props.getbranchByOnchange(btTimeData?.branchShortName);
                findBranchByMainScreen(btTimeData?.branchShortName);
            } else {
                props.getbranchByOnchange('Default');
                findBranchByMainScreen('Default');
            }
        }
    }, [state.franchiseStatus]);

    useEffect(() => {
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));

        if (btTimeData?.branchShortName) {
            findBranchByMainScreen(btTimeData?.branchShortName);
        } else {
            findBranchByMainScreen('Default');
        }
    }, []);

    useEffect(() => {
        if (state.sumOFByTimeForALL) {
            let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
            if (state.sumOFByTimeForALL) {
                findByTimeValues(btTimeData?.branchShortName || 'Default');
            }
        }
    }, [state.sumOFByTimeForALL]);

    useEffect(() => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_')) || {};
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
        localStorage.setItem('FiltterParams', JSON.stringify({ ...btTimeData, branchTime: '' }));

        if (state) {
            if (state.workFlowDataBarData) {
                pullWorkflowRRdata(tempData.workflowReturnReason || state.workFlowDataBarData[0]?.workflow);
            }

            if (state.chartdata) {
                setArray((st) => ({
                    ...st,
                    showLoader: false,
                    timeByBranch: tempData?.BranchBytime || state?.chartdata[0]?.name
                }));
            }
            if (!tempData) {
                localStorage.setItem(
                    '_dashboard_',
                    JSON.stringify({
                        ...tempData,
                        workflowReturnReason: state.workFlowDataBarData[0]?.workflow
                    })
                );
            }
        }
        if (btTimeData) {
            setArray((st) => ({ ...st, byTimeBranch: btTimeData.branchShortName }));
        }
    }, [state.workFlowDataBarData, state.chartdata]);

    useEffect(() => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_')) || {};
        if (state.technicianJobsData) {
            pullTechnicianJobsData(tempData?.selectedValueByTech || state?.chartdata[0]?.name);
        }

        if (!tempData.workflowReturnReason) {
            localStorage.setItem(
                '_dashboard_',
                JSON.stringify({
                    ...tempData,
                    workflowReturnReason: state.workFlowDataBarData[0]?.workflow || ''
                })
            );
        }
    }, [state.technicianJobsData, state.chartdata]);

    const pullTechnicianJobsData = (val) => {
        if (val != 'Default') {
            let tempLocalData = JSON.parse(localStorage.getItem('_dashboard_'));
            let tempData = [...state.technicianJobsData].find((m) => m.bn == val) || [];
            let techJobsTotal = 0;
            if (tempData?.length > 0) {
                tempData.forEach((q) => {
                    techJobsTotal += q.total;
                });
            }
            setArray((st) => ({
                ...st,
                selectedValueByTech: val,
                selectedFilterTechData: tempData,
                techJobsTotal: techJobsTotal
            }));
            localStorage.setItem('_dashboard_', JSON.stringify({ ...tempLocalData, selectedValueByTech: val, BranchBytime: val }));
        }
    };

    const pullWorkflowRRdata = (value) => {
        let newArray = [];
        let keys = [];
        if (state.workFlowDataBarData) {
            let index = state.workFlowDataBarData.find((n) => n.workflow === value);
            if (index) {
                keys = Object.keys(index);
                newArray.push(index);
            }
            setArray((st) => ({
                ...st,
                workflowReturnReasonArray: newArray,
                keys: keys,
                workFlowRR: value
            }));
        }
    };

    let handleArray = (e) => {
        let workflowValue = e.target.value;
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
        let tempData = JSON.parse(localStorage.getItem('_dashboard_'));
        localStorage.setItem('_dashboard_', JSON.stringify({ ...tempData, workflowReturnReason: workflowValue }));
        pullWorkflowRRdata(workflowValue);
        props.getbranchByOnchange(btTimeData?.branchShortName || 'Default', workflowValue);
    };

    let findByTimeValues = (val) => {
        let indexObj = {};

        if (val === 'Default') {
            if (state.sumOFByTimeForALL) {
                let letNewData = state?.sumOFByTimeForALL?.reduce((acc, obj) => {
                    var index = acc.find((item) => item);
                    if (index) {
                        index.lessThan24HoursOld += obj.lessThan24HoursOld;
                        index.between1And5DaysOld += obj.between1And5DaysOld;
                        index.between5And10DaysOld += obj.between5And10DaysOld;
                        index.between10And20DaysOld += obj.between10And20DaysOld;
                        index.between21And30DaysOld += obj.between21And30DaysOld;
                        index.over30DaysOld += obj.over30DaysOld;
                        index.preBooking += obj.preBooking;
                        index.between1to10Days += obj.between1to10Days;
                        index.between11And20Days += obj.between11And20Days;
                        index.between21And30Days += obj.between21And30Days;
                        index.overdueOver30DaysOld += obj.overdueOver30DaysOld;
                        index.overdueArchived += obj.overdueArchived;
                        // index.total += obj.total;
                        return acc;
                    }
                    acc.push({ ...obj });
                    return acc;
                }, []);
                indexObj = letNewData.find((item) => item);
                indexObj.bn = '';
                setArray((st) => ({ ...st, byTimeObj: indexObj, byTimeBranch: val }));
            }
        } else {
            indexObj = state.sumOFByTimeForALL.find((n) => n.bn === val);
            setArray((st) => ({ ...st, byTimeObj: indexObj, byTimeBranch: val }));
        }
    };

    let findBranchByMainScreen = (branch) => {
        let StatusList = [];
        if (branch === 'Default') {
            StatusList = [...state.franchiseStatus].reduce((acc, obj) => {
                const index = acc.find((item) => item.franchise === obj.franchise && item.status === obj.status);
                index ? (index.jobs += obj.jobs) : acc.push({ ...obj });
                return acc;
            }, []);
        } else {
            StatusList = [...state.franchiseStatus].filter((k) => k.branch === branch);
        }

        let AllStatusList = [...StatusList].reduce((acc, obj) => {
            const index = acc.find((item) => item.status === obj.status);
            if (index) {
                index.jobs += obj.jobs;
                index.all = true;
                index.franchise = 'All';
            } else acc.push({ ...obj });
            return acc;
        }, []);

        setArray((st) => ({
            ...st,
            StatusList: [...AllStatusList, ...StatusList],
            StatusListtotal: [...StatusList]
        }));
    };

    const handleChangeAssignedUser = (e) => {
        setArray((st) => ({
            ...st,
            byAssignedUser: e.target.value
        }));
    };

    const handleChangeByTime = (e) => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_'));
        let valueByTime = e.target.value;
        pullTechnicianJobsData(valueByTime);
        props.getbranchByOnchange(valueByTime);
        findBranchByMainScreen(valueByTime);
        findByTimeValues(valueByTime);
        localStorage.setItem(
            'FiltterParams',
            JSON.stringify({
                branchShortName: valueByTime !== 'Default' ? valueByTime : ''
            })
        );
        if (valueByTime !== 'Default') {
            localStorage.setItem(
                '_dashboard_',
                JSON.stringify({
                    ...tempData,
                    BranchBytime: valueByTime,
                    selectedValueByTech: valueByTime
                })
            );
        }
    };

    const handleTimeBranch = (e) => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_'));
        let valueByTimeBranch = e.target.value;
        setArray((st) => ({ ...st, timeByBranch: valueByTimeBranch }));

        localStorage.setItem('_dashboard_', JSON.stringify({ ...tempData, BranchBytime: valueByTimeBranch }));
    };
    const handleTechSelect = (e) => {
        let valueByTech = e.target.value;
        pullTechnicianJobsData(valueByTech);
    };

    const handleTechOnClick = (techObj) => {
        localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: techObj.bn }));
        let searchQuery = `&tid=${techObj.tid || ''}&bnm=${techObj.bn}&tnm=${techObj.name}&bid=${techObj.bid}`;
        history.push({
            pathname: '/techDashgrid',
            search: searchQuery,
            state: {}
        });
    };
    const handleTechCalender = (techObj) => {
        localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: techObj.bn }));
        let searchQuery = `&tid=${techObj.tid || ''}&bnm=${techObj.bn}&tnm=${techObj.name}&bid=${techObj.bid}`;
        history.push({
            pathname: '/TechnicianCalender',
            search: searchQuery,
            state: {}
        });
    };
    const handleBar = (val) => {
        if (val._reactName === 'onChange') {
            setArray((st) => ({ ...st, hourType: val.target.value }));
        } else {
            setArray((st) => ({ ...st, hourType: val }));
        }
    };
    const handlePieCard = (val) => {
        setArray((st) => ({ ...st, selectedPieChartType: val }));
    };
    const handleStatusFren = (val) => {
        if (val._reactName === 'onChange') {
            setArray((st) => ({ ...st, franchise: val.target.value }));
        } else {
            setArray((st) => ({ ...st, franchise: val }));
        }
    };
    const handleDate = (val) => {
        if (val._reactName === 'onChange') {
            setArray((st) => ({ ...st, assignedDate: val.target.value }));
        } else {
            setArray((st) => ({ ...st, assignedDate: val }));
        }
    };

    const DateObj = [
        {
            assignedDateObj: [
                { time: 1, label: 'Less Than 24Hr', BarColor: '5px solid #00F433', value: array.byTimeObj?.lessThan24HoursOld },
                { time: 2, label: '1 to 5 Days', BarColor: '5px solid #88EBA4', value: array.byTimeObj?.between1And5DaysOld },
                { time: 3, label: '5 to 10 Days', BarColor: '5px solid #FFBF00', value: array.byTimeObj?.between5And10DaysOld },
                { time: 4, label: '10 to 20 Days', BarColor: '5px solid #FFE28A', value: array.byTimeObj?.between10And20DaysOld },
                { time: 5, label: '20 to 30 Days', BarColor: '5px solid #bc4443', value: array.byTimeObj?.between21And30DaysOld },
                { time: 6, label: 'Over 30 Days', BarColor: '5px solid #F11C0E', value: array.byTimeObj?.over30DaysOld }
            ],
            OverDateObj: [
                { dueTime: 1, label: 'Pre Booking', BarColor: '5px solid #88EBA4', value: array.byTimeObj?.preBooking },
                { dueTime: 2, label: '1 to 10 Days', BarColor: '5px solid #FFBF00', value: array.byTimeObj?.between1to10Days },
                { dueTime: 3, label: '10 to 20 Days', BarColor: '5px solid #FFE28A', value: array.byTimeObj?.between11And20Days },
                { dueTime: 4, label: '20 to 30 Days', BarColor: '5px solid #bc4443', value: array.byTimeObj?.between21And30Days },
                { dueTime: 5, label: 'Over 30 Days', BarColor: '5px solid #F11C0E', value: array.byTimeObj?.overdueOver30DaysOld },
                { dueTime: -1, label: 'Archived', BarColor: '5px solid #F11C0E', value: array.byTimeObj?.overdueArchived }
            ]
        }
    ];

    const WipsDate = [
        { id: 2, name: 'Overdue WIPs' },
        { id: 1, name: 'Assigned Date' }
    ];

    const HoursData = [
        { id: 'remaining', name: 'Remaining Hours' },
        { id: 'clocked', name: 'Clocked Hours' },
        { id: 'allowed', name: 'Allowed Hours' }
    ];
    let getDateObj = array.assignedDate == 1 ? DateObj[0].assignedDateObj : DateObj[0].OverDateObj;
    let getHoursObj = array.hourType === 'remaining' ? state.hoursRemaining : state.clockedHours;

    return (
        <EWIPDashboardScreenContext.Provider
            value={{
                WipsDate,
                array,
                handleDate,
                handleChangeByTime,
                getDateObj,
                handleStatusFren,
                HoursData,
                handleBar,
                getHoursObj,
                width,
                handleArray,
                handleTechCalender,
                handleTechOnClick,
                state,
                COLORS,
                setObject,
                timenumber,
                randomColor,
                handlePieCard,
                handleChangeAssignedUser,
                redirectToAssignedJobs
            }}
        >
            {props.children}
        </EWIPDashboardScreenContext.Provider>
    );
};
