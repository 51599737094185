import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, withStyles } from '@material-ui/core';
import React from 'react';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';

import PublicIcon from '@material-ui/icons/Public';
import moment from 'moment';
import { useEffect } from 'react';
// import { getWeeklyFlashReport } from '../../../../../Core/Service/ReportScreens-service';
import { useState } from 'react';
import { AppStorage } from '../../../../../Core/Service/storage-service';

const StyledTableHeaderCell = withStyles((theme) => ({
    head: {
        fontWeight: 700,
        padding: 5,
        border: '2px solid #e9ecef'
    }
}))(TableCell);
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#F5f5f5',
        color: theme.palette.common.black,
        fontWeight: 700,
        padding: 5,
        border: '2px solid #e9ecef'
    },
    body: {
        backgroundColor: '#F5f5f5',
        color: theme.palette.common.black,
        fontWeight: 700,
        padding: 5,
        border: '2px solid #e9ecef'
    }
}))(TableCell);

const StyledWhiteTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'white',
        border: 'none',
        padding: '0px 0px'
    },
    body: {
        backgroundColor: 'white',
        color: theme.palette.common.black,
        // fontWeight: 700,
        padding: 5,
        border: '2px solid #e9ecef'
    }
}))(TableCell);

const BlankTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'white',
        border: 'none',
        padding: '0px 0px'
    },
    body: {
        backgroundColor: 'white',

        padding: 5,
        border: 'none'
    }
}))(TableCell);

const useStyles = makeStyles({
    table: {
        minWidth: '100%'
    }
});

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            // backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'DOC', active: true },
    { name: 'DOC Exec Summery', active: true }
];

let currentYear = moment().year();

const DocExecSummery = (props) => {
    const [state, setState] = useState({ tableData: {} });

    const portalSettings = AppStorage.getPortalSetting();
    // const pullTableData = async () => {
    //     let res = await getDocExecSummery();
    //     setState((st) => ({ ...st, tableData: res.data[0] }));
    // };

    // useEffect(() => {
    //     pullTableData();
    // }, []);
    const classes = useStyles(portalSettings);

    console.log(portalSettings);
    return (
        <div style={{ padding: '0px 10px' }}>
            <BreadCrumbs crumbs={crumbs} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell
                                        colSpan={12}
                                        style={{ backgroundColor: portalSettings.portalHeaderBackgroundColour, color: portalSettings.portalHeaderTextColour }}
                                    >
                                        SALES
                                    </StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={2}>Invoiced Sales</StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        Units MTD
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        Gross Margin MTD
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        Average Margin Per Unit
                                    </StyledTableCell>

                                    <StyledTableCell align="right">2022</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell width="5%"></StyledTableCell>
                                    <StyledTableCell width="5%"></StyledTableCell>
                                    <StyledTableCell align="center">Budget</StyledTableCell>
                                    <StyledTableCell align="center">Actual</StyledTableCell>
                                    <StyledTableCell align="center">Variance</StyledTableCell>
                                    <StyledTableCell align="center">Budget</StyledTableCell>
                                    <StyledTableCell align="center">Actual</StyledTableCell>
                                    <StyledTableCell align="center">Variance</StyledTableCell>
                                    <StyledTableCell align="center">Apr-MTD</StyledTableCell>
                                    <StyledTableCell align="center">Mar-YTD</StyledTableCell>
                                    <StyledTableCell align="center">Variance</StyledTableCell>

                                    <StyledTableCell align="right">Full-Year</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell>Truck</StyledTableCell>
                                    <StyledTableCell>New</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>Used</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell>Van</StyledTableCell>
                                    <StyledTableCell>New</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>Used</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell>Maxus</StyledTableCell>
                                    <StyledTableCell>New</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>Used</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell colSpan={2}>Total</StyledTableCell>
                                    <StyledWhiteTableCell colSpan={3} align="center">
                                        -
                                    </StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell colSpan={3} align="center">
                                        -
                                    </StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            {/* <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>Used</StyledTableCell>
                                </StyledTableRow>
                            </TableBody> */}
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell
                                        colSpan={16}
                                        style={{ backgroundColor: portalSettings.portalHeaderBackgroundColour, color: portalSettings.portalHeaderTextColour }}
                                    >
                                        AFTER SALES
                                    </StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" width="8%">
                                        Parts
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4} align="center">
                                        MTD
                                    </StyledTableCell>
                                    <BlankTableCell></BlankTableCell>
                                    <StyledTableCell align="center" width="8%">
                                        Service
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        MTD
                                    </StyledTableCell>
                                    <BlankTableCell></BlankTableCell>
                                    <StyledTableCell align="center" width="8%">
                                        Forecast
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4} align="center">
                                        MTD
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell width="5%"></StyledTableCell>
                                    <StyledTableCell align="center">Budget</StyledTableCell>
                                    <StyledTableCell align="center">Actual</StyledTableCell>
                                    <StyledTableCell align="center">Variance</StyledTableCell>
                                    <StyledTableCell align="center">% Achieved</StyledTableCell>
                                    <BlankTableCell></BlankTableCell>
                                    <StyledTableCell width="5%"></StyledTableCell>
                                    <StyledTableCell align="center">Budget</StyledTableCell>
                                    <StyledTableCell align="center">Actual</StyledTableCell>
                                    <StyledTableCell align="center">Variance</StyledTableCell>
                                    <BlankTableCell></BlankTableCell>
                                    <StyledTableCell width="5%"></StyledTableCell>
                                    <StyledTableCell align="center">Budget</StyledTableCell>
                                    <StyledTableCell align="center">Actual</StyledTableCell>
                                    <StyledTableCell align="center">Variance</StyledTableCell>
                                    <StyledTableCell align="center">% Achieved</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell>Trade Sales</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <BlankTableCell></BlankTableCell>
                                    <StyledTableCell align="center">Hours Sold</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <BlankTableCell></BlankTableCell>

                                    <StyledTableCell align="center">Labour Revenue</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell>Workshop Sales</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <BlankTableCell></BlankTableCell>
                                    <StyledTableCell align="center">WIP (opening)</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <BlankTableCell></BlankTableCell>

                                    <StyledTableCell align="center">Hours Sold</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell>Total Sales</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <BlankTableCell></BlankTableCell>
                                    <StyledTableCell align="center">Recovery Rate</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <BlankTableCell></BlankTableCell>

                                    <StyledTableCell align="center">Parts Gross Margin</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell
                                        colSpan={8}
                                        style={{ backgroundColor: portalSettings.portalHeaderBackgroundColour, color: portalSettings.portalHeaderTextColour }}
                                    >
                                        ADDITIONS FOR WEEKLY SUMMARY
                                    </StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" width="8%">
                                        Orders
                                    </StyledTableCell>
                                    <StyledTableCell align="center">Week</StyledTableCell>
                                    <StyledTableCell align="center">MTD</StyledTableCell>
                                    <StyledTableCell align="center">YTD</StyledTableCell>
                                    <BlankTableCell></BlankTableCell>
                                    <StyledTableCell align="center" width="8%">
                                        Registrations
                                    </StyledTableCell>
                                    <StyledTableCell align="center">MTD</StyledTableCell>
                                    <StyledTableCell align="center">YTD</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Truck</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <BlankTableCell></BlankTableCell>

                                    <StyledTableCell align="center">Truck</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Van</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <BlankTableCell></BlankTableCell>

                                    <StyledTableCell align="center">Van</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center">MAxus</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <BlankTableCell></BlankTableCell>

                                    <StyledTableCell align="center">MAxus</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell
                                        colSpan={8}
                                        style={{ backgroundColor: portalSettings.portalHeaderBackgroundColour, color: portalSettings.portalHeaderTextColour }}
                                    >
                                        UNSOLD NEW VEHICLE STOCKS
                                    </StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={5}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell
                                        colSpan={8}
                                        style={{ backgroundColor: portalSettings.portalHeaderBackgroundColour, color: portalSettings.portalHeaderTextColour }}
                                    >
                                        TRUCK
                                    </StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" width="8%">
                                        Model
                                    </StyledTableCell>
                                    <StyledTableCell align="center">Physical</StyledTableCell>
                                    <StyledTableCell align="center">On-Order</StyledTableCell>
                                    <StyledTableCell align="center">Total</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Canter</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Total</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={4}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell
                                        colSpan={3}
                                        style={{ backgroundColor: portalSettings.portalHeaderBackgroundColour, color: portalSettings.portalHeaderTextColour }}
                                    >
                                        Updated
                                    </StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" colSpan={3}>
                                        Oldest 3 (Days)
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={3}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">New Stock Value</StyledTableCell>
                                    <StyledTableCell align="center">Value</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">Value</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledWhiteTableCell align="center">Sold Stock Wip</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledWhiteTableCell align="center">Total</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={5}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell
                                        colSpan={8}
                                        style={{ backgroundColor: portalSettings.portalHeaderBackgroundColour, color: portalSettings.portalHeaderTextColour }}
                                    >
                                        MB VAN
                                    </StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" width="8%">
                                        Model
                                    </StyledTableCell>
                                    <StyledTableCell align="center">Physical</StyledTableCell>
                                    <StyledTableCell align="center">On-Order</StyledTableCell>
                                    <StyledTableCell align="center">Total</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Canter</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Total</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={4}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell
                                        colSpan={3}
                                        style={{ backgroundColor: portalSettings.portalHeaderBackgroundColour, color: portalSettings.portalHeaderTextColour }}
                                    >
                                        Updated
                                    </StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" colSpan={3}>
                                        Oldest 3 (Days)
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={3}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">New Stock Value</StyledTableCell>
                                    <StyledTableCell align="center">Value</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">Value</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledWhiteTableCell align="center">Sold Stock Wip</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledWhiteTableCell align="center">Total</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={5}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell
                                        colSpan={8}
                                        style={{ backgroundColor: portalSettings.portalHeaderBackgroundColour, color: portalSettings.portalHeaderTextColour }}
                                    >
                                        MAXUS
                                    </StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" width="8%">
                                        Model
                                    </StyledTableCell>
                                    <StyledTableCell align="center">Physical</StyledTableCell>
                                    <StyledTableCell align="center">On-Order</StyledTableCell>
                                    <StyledTableCell align="center">Total</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Canter</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Total</StyledTableCell>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={4}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell
                                        colSpan={3}
                                        style={{ backgroundColor: portalSettings.portalHeaderBackgroundColour, color: portalSettings.portalHeaderTextColour }}
                                    >
                                        Updated
                                    </StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" colSpan={3}>
                                        Oldest 3 (Days)
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={3}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">New Stock Value</StyledTableCell>
                                    <StyledTableCell align="center">Value</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow>
                                    {/* <StyledTableCell>New</StyledTableCell> */}
                                    <StyledWhiteTableCell align="center">Value</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledWhiteTableCell align="center">Sold Stock Wip</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledWhiteTableCell align="center">Total</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell
                                        colSpan={8}
                                        style={{ backgroundColor: portalSettings.portalHeaderBackgroundColour, color: portalSettings.portalHeaderTextColour }}
                                    >
                                        USED VEHICLE STOCKS
                                    </StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center">No Units</StyledTableCell>
                                    <StyledTableCell align="center">Value</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={5}>
                                        Oldest 5 (Days)
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Truck</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Van</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Maxus</StyledTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                    <StyledWhiteTableCell align="center">-</StyledWhiteTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Total</StyledTableCell>
                                    <StyledTableCell align="center">-</StyledTableCell>
                                    <StyledTableCell align="center">-</StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
};

export default DocExecSummery;
