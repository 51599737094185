import { FormLabel, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import AppContext from '../../../App/AppContext';
import { GreenButton, SecondaryButton, TextBox } from '../../../Core/FormInput';
import { postCustomerRating, getCustomerRating } from '../../../Core/Service/workflow-service';
// import { SelectBox } from '';
import SelectBox from './../../../Core/FormInput/SelectBox';
import StarIcon from '@material-ui/icons/Star';
import { useHistory } from 'react-router-dom';

const CustomerRating = (props) => {
    const [state, setState] = useState({ customerRatingIcon: null });
    const { hideTopNavAndMenu, showToast, portalSettings, userRoles } = useContext(AppContext);
    const history = useHistory();

    const isInternal = userRoles.includes('internal');
    const isTechnician = userRoles.includes('technician');
    useEffect(async () => {
        if (props.jobId) {
            let res = await getCustomerRating(props.jobId);
            if (res?.success) {
                let data = res?.data?.table[0];
                setState((st) => ({
                    ...st,
                    headerRecordID: data.headerRecordID,
                    customerRatingIcon: data.customerRatingIcon || null,
                    customerRatingNotes: data.customerRatingNotes || '',
                    enteredRegNo: data.enteredRegNo || '',
                    s24ChassisNumber: data.s24ChassisNumber || '',
                    registration: data.registration,
                    wipNo: data.wipNumber
                }));
            }
        }
    }, []);
    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            // nst.isSetDisabled = nst.jobPriority ? false : true;
            return nst;
        });
    };

    const ratingList = [
        {
            id: 1,
            name: (
                <>
                    <StarIcon style={{ color: 'gold' }} /> Gold
                </>
            )
        },
        {
            id: 2,
            name: (
                <>
                    <StarIcon style={{ color: '#C0C0C0' }} /> Silver
                </>
            )
        },
        {
            id: 3,
            name: (
                <>
                    <StarIcon style={{ color: '#CD7F32' }} /> Bronze
                </>
            )
        }
    ];

    const handelSubmit = async () => {
        let data = {
            headerRecordID: props.jobId,
            customerRatingIcon: state.customerRatingIcon,
            customerRatingNotes: state.customerRatingNotes,
            enteredRegNo: state.enteredRegNo,
            s24ChassisNumber: state.s24ChassisNumber
        };

        const res = await postCustomerRating(data);
        if (res.success) {
            showToast(res.data.list.table[0].msg);
            // props.handleCancel(true);
            if (isInternal || isTechnician) {
                let path = '';
                if (isTechnician) {
                    path = `Job/${props.workFlowData.headerRecordID}/${props.workFlowData.workflowID}/${props.workFlowData.technicianJobID}/${props.workFlowData.ownJob}`;
                }
                if (isInternal) {
                    path = `Job/${props.workFlowData.headerRecordID}/${props.workFlowData.workflowID}?ProcessId=${props.workFlowData.pid}&isCustomer=1`;
                }
                history.push({
                    pathname: path,
                    state: {
                        isCustRating: true
                    }
                });
            }
        }
        if (!res.success) {
            console.error(res);
        }
    };

    return (
        <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={8}>
                WIP No:&nbsp; <b> {state.wipNo}</b>
            </Grid>
            <Grid item xs={4}>
                Reg No:&nbsp; <b> {state.registration}</b>
            </Grid>
            <br />
            {portalSettings.showRegNumberInCustomerRatingBox && (
                <>
                    <Grid item xs={8}>
                        <FormLabel component="legend">S24 Chassis Number</FormLabel>
                        <TextBox name="s24ChassisNumber" value={state.s24ChassisNumber} onChange={fieldChange} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormLabel component="legend">Reg No</FormLabel>
                        <TextBox name="enteredRegNo" value={state.enteredRegNo} onChange={fieldChange} />
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <FormLabel component="legend" style={{ marginBottom: 10 }}>
                    Customer Rating Icon
                </FormLabel>
                <SelectBox List={ratingList} name="customerRatingIcon" value={state.customerRatingIcon} onChange={fieldChange} />
            </Grid>
            <Grid item xs={12}>
                <FormLabel component="legend">Notes</FormLabel>
                <TextBox rows={4} multiline name="customerRatingNotes" value={state.customerRatingNotes} onChange={fieldChange} />
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton className="Submitbtn" onClick={() => handelSubmit('reset')}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default CustomerRating;
