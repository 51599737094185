import React, { useContext, useEffect, useState, useMemo } from 'react';
import DialogComp from '../../../Core/Modal/dialogModal';
import Barcode from 'react-barcode/lib/react-barcode';
import { IconButton, Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import _ from 'lodash';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { CustomButton } from '../../../Core/FormInput';

const SpecialEquipmentBarcode = (props) => {
    const { focusedRow, onClose } = props;

    const [state, setState] = useState({});

    const printDocument = () => {
        let input = document.getElementById('divToPrint');
        let calMaxHeight;

        html2canvas(input, {
            onclone: (cloned) => {
                let textArea = cloned.getElementsByTagName('textarea');
                cloned.getElementById('zommContainer').style.display = 'none';
                let getHeight = [];
                for (var i = 0; i < textArea.length; i++) {
                    getHeight.push(textArea[i].scrollHeight);
                }
                calMaxHeight = _.max(getHeight);
                for (var i = 0; i < textArea.length; i++) {
                    const div = document.createElement('div');
                    const textProp = textArea[i].getBoundingClientRect();
                    div.innerText = textArea[i].value;
                    div.style.padding = '2px -3px';
                    div.style.height = `${calMaxHeight + textProp.height}px`;
                    div.style.width = `${textProp.width}px`;
                    div.style.textAlign = 'left';
                    div.style.color = 'black';
                    div.style.fontSize = 8;
                    textArea[i].style.display = 'none';
                    textArea[i].parentElement.append(div);
                }
            }
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('l', 'px', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`barcode-${moment().format('DD-MMM-YYYY-HH:mm')}.pdf`);
        });
    };

    return (
        <DialogComp
            onClose={() => props.onClose(false)}
            maxWidth="lg"
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <Typography style={{}}>Special Equipment Details - {focusedRow?.specialEquipmentCode}</Typography>
                    <div id="zommContainer" style={{ marginTop: '-8px', marginLeft: '35px' }}>
                        <CustomButton color="secondary" icon={CloudDownload} onClick={printDocument} toolTipTitle="Download this document" iconFontSize={28} />
                    </div>
                </div>
            }
            overflow="auto"
        >
            <div id="divToPrint" style={{ margin: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Barcode value={focusedRow?.specialEquipmentCode} />
                </div>
                <div>
                    <Typography style={{ marginTop: '20px', textAlign: 'center' }}>{focusedRow?.specialEquipmentDescription}</Typography>
                </div>
            </div>
        </DialogComp>
    );
};

export default SpecialEquipmentBarcode;
