import React, { useEffect, useState, useContext } from 'react';
import { postBookingRequestStatus } from '../../../../Core/Service/communicator-service';
import { Grid, InputLabel, Typography, FormLabel, FormControlLabel, Checkbox } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import AppContext from './../../../../App/AppContext';
import CustomeEditor from '../../../../Core/CkEditor';
import Multiselect from './../../../../Core/FormInput/MultiSelect';
const AddUpdateBookingStatus = (props) => {
    const [state, setState] = useState({
        branchInvoice: [],
        sendEmail: false,
        body: props.data.body,
        iD: null
    });

    const { showToast, showModal } = useContext(AppContext);

    useEffect(async () => {
        // if (props.data) {
        setState((st) => ({
            ...st,
            iD: props.reqID,
            description: props.data.description,
            sendEmail: props.data.email,
            subject: props.data.subjects
            // body: .toString()
        }));
        // }
    }, [props]);

    console.log(state, 'state');

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const handleCkeditorstate = (name) => (event, editor) => {
        const data = editor.getData();
        setState((st) => ({ ...st, [name]: data }));
    };

    const handleSubmit = async () => {
        let data = {
            iD: props.reqID || null,
            description: state.description || '',
            sendEmail: state.sendEmail || false,
            subject: state.subject || '',
            body: state.body || ''
        };

        let res = await postBookingRequestStatus(data);
        if (res.data.success) {
            showToast(`Booking Request Status ${props.reqID ? 'Updated' : 'Added'} successfully`);
            props.onClose(true);
        } else {
            console.error(res.message);
        }
    };
    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = checked;
            return nst;
        });
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel>Description</FormLabel>
                    <TextBox onChange={handleInput} value={state.description} placeholder="Description" name="description" />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Subject</FormLabel>
                    <TextBox onChange={handleInput} value={state.subject} placeholder="Subject" name="subject" />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                    <FormLabel>Body</FormLabel>
                    <br />

                    <CustomeEditor data={state.body} onChange={handleCkeditorstate('body')} placeholder="Add description here" name="body" />
                </Grid>
                <Grid item md={12}>
                    <FormControlLabel control={<Checkbox checked={state.sendEmail} onChange={handleCheckbox} name="sendEmail" />} label="Send Email?" />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddUpdateBookingStatus;
