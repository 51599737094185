import { Dashboard } from '@material-ui/icons';
import { get, post } from './http-calls';

export const getGetUserProcesses = async () => {
    let res = await get('Process/GetUserProcesses', { useAuthToken: true });
    return res;
};

export const getDashboardInfo = async () => {
    let res = await get('DashBoard/GetInternalDashBoardData', { useAuthToken: true });
    return res;
};
export const GetInternalDashHeaderRecords = async () => {
    let res = await get('DashBoard/GetInternalDashHeaderRecords', { useAuthToken: true });
    return res;
};
export const GetInternalDashReturnReasons = async () => {
    let res = await get('DashBoard/GetInternalDashReturnReasons', { useAuthToken: true });
    return res;
};
export const GetInternalDashTechnicianJobs = async () => {
    let res = await get('DashBoard/GetInternalDashTechnicianJobs', { useAuthToken: true });
    return res;
};

export const GetInternalDashClockEntries = async (ProcessID) => {
    let res = await get(`DashBoard/GetInternalDashClockEntries?ProcessID=${ProcessID}`, { useAuthToken: true });
    return res;
};

export const GetUserPreferenceWorkflowsList = async () => {
    let res = await get('UserGridColumn/UserPreferenceWorkflows', { useAuthToken: true });
    return res;
};

export const saveSwapUserPreferenceWorkflows = async (data) => {
    let res = await post(`UserGridColumn/SwapUserPreferenceWorkflows `, data, { useAuthToken: true });
    return res;
};

export const GetInternalReturnReasonData = async (startDate, endDate, bid) => {
    let BranchID = bid.length > 0 ? `BranchID=${bid.join('&BranchID=')}` : '';
    let res = await get(`dashboard/GetInternalReturnReasonsDashboard?startDate=${startDate}&endDate=${endDate}&${BranchID}`, { useAuthToken: true });
    return res;
};

export const GetInternalWorkflowsAvgTimesDashboard = async (startDate, endDate, bid) => {
    let BranchID = bid.length > 0 ? `BranchID=${bid.join('&BranchID=')}` : '';
    let res = await get(`DashBoard/GetInternalWorkflowsAvgTimesDashboard?startDate=${startDate}&endDate=${endDate}&${BranchID}`, { useAuthToken: true });
    return res;
};

export const GetPartsDashboard = async () => {
    let res = await get('PartsDashboard', { useAuthToken: true });
    return res;
};

export const GetUserPartGridColumn = async () => {
    let res = await get('UserPartGridColumn', { useAuthToken: true });
    return res;
};

export const saveUserPartGridColumn = async (data) => {
    let res = await post(`UserPartGridColumn`, data, { useAuthToken: true });
    return res;
};

export const GetNewVehicleDashboard = async (processID) => {
    let res = await get(`NewVehicleDashboard/GetSalesInternalNewVehicleSTock?ProcessID=${processID}`, { useAuthToken: true });
    return res;
};
