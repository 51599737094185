import React, { useContext } from 'react';
import './authStyle.scss';
import { SecondaryButton, TextBox } from '../FormInput';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { useState } from 'react';
import { postAuthenticationDisbaledCode } from '../Service/authenticator-service';
import AppContext from '../../App/AppContext';
import WarningIcon from '@material-ui/icons/Warning';
import { AppStorage } from '../Service/storage-service';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useWindowSize } from '../Controls/ScreenResolution';

const TwoAuthDiasbledScreen = () => {
    const [width, height] = useWindowSize();
    const [state, setState] = useState({
        code: ''
    });

    const { hideModal, showToast } = useContext(AppContext);
    const handleInput = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, code: value }));
    };

    const handleSubmit = async () => {
        let res = await postAuthenticationDisbaledCode(state.code);
        if (res.success) {
            AppStorage.setTwoFlag(false);
            showToast('Your login has been successfully disabled two-factor authentication. ');
        }
    };
    return (
        <>
            <Hidden only={['sm', 'lg', 'md', 'xs']}>
                <div className="authMain">
                    <div className="authModal">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Grid Grid container justifyContent="center">
                                    <Grid item xs={10}>
                                        <div className="authDetails">
                                            <div>
                                                <Typography
                                                    variant="h3"
                                                    style={{ textAlign: 'left', fontWeight: 600, color: '#1f3758', display: 'inline-block' }}
                                                >
                                                    <CheckCircleIcon style={{ fontSize: 50, color: '#17E84B', verticalAlign: 'middle' }} />{' '}
                                                    <span>Two-factor Authentication is On</span>
                                                </Typography>
                                                <br />
                                                <br />
                                                <Typography variant="h6" style={{ textAlign: 'left' }}>
                                                    We'll ask for a verification code via your security method
                                                </Typography>
                                                <br />
                                                <Typography variant="h6" style={{ textAlign: 'left' }}>
                                                    Before you turn off two-factor authentication, it’s important to understand how this changes your login
                                                    process. Without two-factor authentication, only a username and password are needed to access your account.
                                                    You will not receive a verification code to access your account.
                                                </Typography>
                                                <br />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid item xs={5}>
                                <Grid Grid container justifyContent="center">
                                    <Grid item xs={10}>
                                        <div className="authDetails">
                                            <div>
                                                <div className="notch">-</div>

                                                <div className="MobileView">
                                                    <div>
                                                        <div className="qrScanner">
                                                            <Typography
                                                                variant="body1"
                                                                style={{ textAlign: 'center', fontSize: 15, fontWeight: 600, marginBottom: 10 }}
                                                            >
                                                                Disable Two-Factor Authentication
                                                            </Typography>{' '}
                                                            <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 600, color: '#1f3758' }}>
                                                                <WarningIcon style={{ fontSize: 180, color: '#E4421B' }} />
                                                            </Typography>{' '}
                                                            {/* <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 600, color: '#1f3758' }}>
                                                        Disable Two-Factor Authentication
                                                    </Typography>{' '} */}
                                                            <TextBox placeholder="Authentication Code" className="Textbox" onChange={handleInput} />
                                                            <SecondaryButton fullWidth onClick={handleSubmit}>
                                                                Submit
                                                            </SecondaryButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Hidden>
            <Hidden only={['xl']}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <div className="authDetails">
                                    <div>
                                        <div variant="h3" style={{ textAlign: 'left', fontWeight: 600, color: '#1f3758', display: 'inline-block' }}>
                                            <CheckCircleIcon className="icon" style={{ color: '#17E84B', verticalAlign: 'middle' }} />{' '}
                                            <span className="mainHeading">Two-factor Authentication is On</span>
                                        </div>

                                        <div className="subText" style={{ textAlign: 'left' }}>
                                            We'll ask for a verification code via your security method
                                        </div>

                                        <div className="subText" style={{ textAlign: 'left' }}>
                                            Before you turn off two-factor authentication, it’s important to understand how this changes your login process.
                                            Without two-factor authentication, only a username and password are needed to access your account. You will not
                                            receive a verification code to access your account.
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid item xs={12} sm={12} md={5}>
                        <Grid Grid container justifyContent="center">
                            <Grid item xs={10}>
                                <div className="authDetails">
                                    <div>
                                        <div className="notch">-</div>

                                        <div className="MobileView">
                                            <div>
                                                <div className="qrScanner">
                                                    <Typography
                                                        variant="body1"
                                                        style={{ textAlign: 'center', fontSize: 15, fontWeight: 600, marginBottom: 10 }}
                                                    >
                                                        Disable Two-Factor Authentication
                                                    </Typography>{' '}
                                                    <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 600, color: '#1f3758' }}>
                                                        <WarningIcon style={{ fontSize: 180, color: '#E4421B' }} />
                                                    </Typography>{' '}
                                                    {/* <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 600, color: '#1f3758' }}>
                                                        Disable Two-Factor Authentication
                                                    </Typography>{' '} */}
                                                    <TextBox placeholder="Authentication Code" className="Textbox" onChange={handleInput} />
                                                    <SecondaryButton fullWidth onClick={handleSubmit}>
                                                        Submit
                                                    </SecondaryButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
        </>
    );
};

export default TwoAuthDiasbledScreen;
