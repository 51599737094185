import { get, post } from './../http-calls';

export let getMessageGridList = async (id) => {
    let data = await get(`CustUserCommunicator/GetWIPLogHistory_External?HeaderId=${id}`, { useAuthToken: true });
    return data;
};

export const postSendMessage = async (data) => {
    let res = await post(`CustUserCommunicator/SendMessage`, data, { useAuthToken: true });
    return res;
};
export const postApprovedJob = async (data) => {
    let res = await post(`CustUserCommunicator/ApproveJob`, data, { useAuthToken: true });
    return res;
};
export let getCommunicatorDash = async (id) => {
    let data = await get(`CustUserCommunicator/dashboard`, { useAuthToken: true });
    return data;
};
