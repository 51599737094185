import React from 'react';
import { getTickers } from './../Service/KPI_message-service';
import { useEffect } from 'react';
import { useState } from 'react';
import { useWindowSize } from '../Controls/ScreenResolution';

export const TickerDisplayMessage = () => {
    const [screenWidth, screenHeight] = useWindowSize();
    const [message, setMessage] = useState('');
    useEffect(async () => {
        let res = await getTickers();
        if (res.success) {
            let array = [];
            res.data.list.forEach((m) => {
                array.push(`${m.message}`);
                array.push();
            });

            let newstring = array.join(` 💠 `);
            setMessage(newstring);
        }
    }, []);

    return (
        <div
            style={{
                width: screenWidth > 1050 ? 390 : screenWidth > 960 ? 300 : screenWidth > 895 ? 220 : screenWidth > 850 ? 170 : 100,
                display: screenWidth > 775 ? 'block' : 'none',
                marginTop: 8,
                font: 'message-box',
                fontSize: '15px'
            }}
        >
            {message && (
                <marquee direction="left" behavior="scroll" Scrollamount="8">
                    {' '}
                    💠&nbsp;{message}
                </marquee>
            )}
        </div>
    );
};
