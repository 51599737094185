import React, { useContext, useEffect, useState } from 'react';
import { addupdateFinanceProvidersModel, getFPProviderModelById } from '../../../../../Core/Service/ReportScreens-service';
import { FormLabel, Grid } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../../../Core/FormInput';
import AppContext from '../../../../../App/AppContext';

const AddEdirModelModal = (props) => {
    const [state, setState] = useState({ errors: {} });
    const { hideModal, showToast } = useContext(AppContext);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const pullData = async () => {
        let result = await getFPProviderModelById(props.selectedModelId);

        if (result.success) {
            setState((st) => ({
                ...st,
                name: result.data[0].name,
                freeDays: result.data[0].freeDays
            }));
        }
    };

    useEffect(() => {
        if (props.selectedModelId) {
            pullData();
        }
    }, []);

    const validations = () => {
        const { name } = state;
        let formIsValid = true;
        let errors = {};

        if (name == null || name == '') {
            errors.name = 'Name is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            let data = {
                name: state.name,
                freeDays: state.freeDays,
                ID: props.selectedModelId
            };
            let res = await addupdateFinanceProvidersModel(data);
            if (res.success) {
                showToast(`Model added successfully`);
                props.onClose(true);
            } else {
                showToast(`${res.message}`);
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormLabel>Name</FormLabel>
                <TextBox value={state.name} placeholder="Name" onChange={handleFieldChange} name="name" />
                <span className="mandatoryfields">{state.errors['name']}</span>
            </Grid>
            <Grid item xs={12}>
                <FormLabel>Free Days</FormLabel>
                <TextBox value={state.freeDays} placeholder="Free Days" onChange={handleFieldChange} name="freeDays" />
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton className="setFullWidth" onClick={handleSubmit} fullWidth>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default AddEdirModelModal;
