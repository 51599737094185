import React, { useMemo } from 'react';
import { SquareButton } from '../../../Core/FormInput/AppButton';
import ReportGijgoGrid from './../../internal/ReportsScreens/GijgoForReportsScreen/index';
import { Grid, Typography } from '@material-ui/core';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
import EditBranchScreen from './editBranch';
import { useState } from 'react';
import DialogComp from './../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';

const baseColumns = [
    { field: 'action', sortable: false, title: ``, width: 70, filterable: false },
    { field: 'shortName', sortable: false, title: `Short Name`, width: 150 },

    { field: 'name', sortable: false, title: `Branch` },
    { field: 'address1', sortable: false, title: `Address` },
    {
        field: 'town',
        sortable: false,
        title: `Town`,
        width: 150
    },

    { field: 'country', sortable: false, title: `County`, width: 120 }
];

const baseUrl = 'Branches/GetAllBranches';

const crumbs = (path) => [
    { name: 'Home', path: '' },
    { name: 'Invoices', active: true },
    { name: `Branch`, active: true }
];

const BranchesScreen = () => {
    const [state, setState] = useState({
        showModal: false
    });

    const handleClose = () => {
        setState((st) => ({
            ...st,
            showModal: false
        }));
    };

    const handleEdit = (val) => {
        setState((st) => ({
            ...st,
            showModal: true,
            branchID: val.branchID
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => handleEdit(record)}
                >
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const randerSortCol = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                {record.shortName}&nbsp;&nbsp;({record.branchCode})
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');

        let colSortName = col.find((m) => m.field === 'shortName');
        if (colAction) {
            colAction.renderer = editButton;
        }
        if (colSortName) {
            colSortName.renderer = randerSortCol;
        }

        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);
    return (
        <div className="report-screen-container">
            {state.showModal ? (
                <DialogComp title="Branch Invoice Responses" maxWidth="md" onClose={handleClose} fullWidth>
                    <EditBranchScreen onClose={handleClose} branchID={state.branchID} />
                </DialogComp>
            ) : null}
            <BreadCrumbs crumbs={crumbs()} />
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    records: res?.data?.list.map((p) => ({ ...p }))
                })}
                columns={column}
                getUrl={baseUrl}
                isReload={state.isReload}
                isShowTotal={false}
                //  hidePagination={true}
                // isReload={state.isReload}
                // hidePagination={true}
                // hideFilterRow={true}
            />
        </div>
    );
};

export default BranchesScreen;
