import { Grid, Typography } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const setPercentage = (profit, budget) => {
    if (!profit) {
        return 0;
    } else {
        if (budget == null || budget == 0) {
            return 100;
        } else {
            let value = (+profit / +budget) * 100;

            return Math.round(value) <= 100 ? Math.round(value) : 100;
        }
    }
};

export const UsedSalesCard = (props) => {
    const history = useHistory();

    const onRedirect = (url, searchQuery) => {
        history.push({
            pathname: url,
            search: searchQuery
        });
    };
    const { mtdProfit, mtdBudget, label, ytdProfit, ytdBudget, id } = props;

    return (
        <Grid container style={{ textAlign: 'justify', padding: '0px 5px ' }} justifyContent="space-around" alignItems="center">
            <Grid item xs={12} className="p-100">
                <Typography className="mainCardTextPrimary  ml-10">{label}</Typography>
                <hr />
            </Grid>
            <Grid
                item
                container
                xs={6}
                spacing={2}
                className="pointerHover"
                justifyContent="center"
                onClick={() => onRedirect('/sales/UsedVehicleProfitReport', `?CategoryType=${id}`)}
            >
                <Grid item xs={12} className="p-100">
                    <Typography className="mainCardTextPrimary  ml-10">MTD</Typography>
                </Grid>
                <Grid item>
                    <div className="vehicleRing">
                        <CircularProgressbar
                            value={setPercentage(mtdProfit, mtdBudget)}
                            text={`${setPercentage(mtdProfit, mtdBudget) || 0}%`}
                            styles={buildStyles({
                                textColor:
                                    setPercentage(mtdProfit, mtdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(mtdProfit, mtdBudget) >= 50 && setPercentage(mtdProfit, mtdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(mtdProfit, mtdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                pathColor:
                                    setPercentage(mtdProfit, mtdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(mtdProfit, mtdBudget) >= 50 && setPercentage(mtdProfit, mtdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(mtdProfit, mtdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                trailColor: 'lightgrey',
                                // strokeLinecap: '',
                                textSize: '27px'
                            })}
                            strokeWidth={8}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div
                        style={{
                            marginBottom: 5,
                            // display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">ACTUAL</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(mtdProfit) || 0}
                        </div>
                    </div>

                    <div
                        style={{
                            //  display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">BUDGET</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {' '}
                            {formatters.CurrencyThousandSeparatorWithoutZero(mtdBudget) || 0}
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={6}
                spacing={2}
                className="pointerHover"
                justifyContent="center"
                onClick={() =>
                    onRedirect(
                        '/sales/UsedVehicleProfitReport',
                        `?CategoryType=${id}&start=${moment().startOf('year').format('YYYY-MM-DD')}&end=${moment().endOf('year').format('YYYY-MM-DD')}`
                    )
                }
            >
                <Grid item xs={12} className="p-100">
                    <Typography className="mainCardTextPrimary  ml-10">YTD</Typography>
                </Grid>
                <Grid item>
                    <div className="vehicleRing">
                        <CircularProgressbar
                            value={setPercentage(ytdProfit, ytdBudget)}
                            text={`${setPercentage(ytdProfit, ytdBudget) || 0}%`}
                            styles={buildStyles({
                                textColor:
                                    setPercentage(ytdProfit, ytdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdProfit, ytdBudget) >= 50 && setPercentage(ytdProfit, ytdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdProfit, ytdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                pathColor:
                                    setPercentage(ytdProfit, ytdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdProfit, ytdBudget) >= 50 && setPercentage(ytdProfit, ytdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdProfit, ytdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                trailColor: 'lightgrey',
                                // strokeLinecap: '',
                                textSize: '27px'
                            })}
                            strokeWidth={8}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            marginBottom: 5,
                            // display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">ACTUAL</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(ytdProfit) || 0}
                        </div>
                    </div>

                    <div
                        style={{
                            //  display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">BUDGET</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {' '}
                            {formatters.CurrencyThousandSeparatorWithoutZero(ytdBudget) || 0}
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <div
                    style={{
                        marginBottom: 20
                    }}
                ></div>
            </Grid>
        </Grid>
    );
};
export const UsedstockCard = (props) => {
    const { mtdProfit, mtdBudget, label, ytdProfit, ytdBudget } = props;

    return (
        <Grid container style={{ textAlign: 'justify', padding: '0px 5px ' }} justifyContent="space-around" alignItems="center">
            <Grid item xs={12} className="p-100">
                <Typography className="mainCardTextPrimary  ml-10">{label}</Typography>
                <hr />
            </Grid>
            <Grid
                item
                container
                xs={4}
                spacing={2}
                // className="pointerHover"
                justifyContent="center"
            >
                <Grid item xs={12} className="p-100">
                    <Typography className="mainCardTextPrimary  ml-10">Used Stock Units</Typography>
                </Grid>
                <Grid item>
                    <div className="vehicleRing">
                        <CircularProgressbar
                            value={setPercentage(mtdProfit, mtdBudget)}
                            text={`${setPercentage(mtdProfit, mtdBudget) || 0}%`}
                            styles={buildStyles({
                                textColor:
                                    setPercentage(mtdProfit, mtdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(mtdProfit, mtdBudget) >= 50 && setPercentage(mtdProfit, mtdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(mtdProfit, mtdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                pathColor:
                                    setPercentage(mtdProfit, mtdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(mtdProfit, mtdBudget) >= 50 && setPercentage(mtdProfit, mtdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(mtdProfit, mtdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                trailColor: 'lightgrey',
                                // strokeLinecap: '',
                                textSize: '27px'
                            })}
                            strokeWidth={8}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div
                        style={{
                            marginBottom: 5,
                            // display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">Current Month</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(mtdProfit) || 0}
                        </div>
                    </div>

                    <div
                        style={{
                            //  display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">(Prior Month)</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {' '}
                            {formatters.CurrencyThousandSeparatorWithoutZero(mtdBudget) || 0}
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={4}
                spacing={2}
                // className="pointerHover"
                justifyContent="center"
            >
                <Grid item xs={12} className="p-100">
                    <Typography className="mainCardTextPrimary  ml-10">Used Stock Value</Typography>
                </Grid>
                <Grid item>
                    <div className="vehicleRing">
                        <CircularProgressbar
                            value={setPercentage(ytdProfit, ytdBudget)}
                            text={`${setPercentage(ytdProfit, ytdBudget) || 0}%`}
                            styles={buildStyles({
                                textColor:
                                    setPercentage(ytdProfit, ytdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdProfit, ytdBudget) >= 50 && setPercentage(ytdProfit, ytdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdProfit, ytdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                pathColor:
                                    setPercentage(ytdProfit, ytdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdProfit, ytdBudget) >= 50 && setPercentage(ytdProfit, ytdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdProfit, ytdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                trailColor: 'lightgrey',
                                // strokeLinecap: '',
                                textSize: '27px'
                            })}
                            strokeWidth={8}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            marginBottom: 5,
                            // display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">Current Month</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(ytdProfit) || 0}
                        </div>
                    </div>

                    <div
                        style={{
                            //  display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">(Prior Month)</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {' '}
                            {formatters.CurrencyThousandSeparatorWithoutZero(ytdBudget) || 0}
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={4}
                spacing={2}
                // className="pointerHover"
                justifyContent="center"
            >
                <Grid item xs={12} className="p-100">
                    <Typography className="mainCardTextPrimary  ml-10">Avg / vehicle</Typography>
                </Grid>
                <Grid item>
                    <div className="vehicleRing">
                        <CircularProgressbar
                            value={setPercentage(ytdProfit, ytdBudget)}
                            text={`${setPercentage(ytdProfit, ytdBudget) || 0}%`}
                            styles={buildStyles({
                                textColor:
                                    setPercentage(ytdProfit, ytdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdProfit, ytdBudget) >= 50 && setPercentage(ytdProfit, ytdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdProfit, ytdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                pathColor:
                                    setPercentage(ytdProfit, ytdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdProfit, ytdBudget) >= 50 && setPercentage(ytdProfit, ytdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdProfit, ytdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                trailColor: 'lightgrey',
                                // strokeLinecap: '',
                                textSize: '27px'
                            })}
                            strokeWidth={8}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            marginBottom: 5,
                            // display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">Current Month</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(ytdProfit) || 0}
                        </div>
                    </div>

                    <div
                        style={{
                            //  display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">(Prior Month)</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {' '}
                            {formatters.CurrencyThousandSeparatorWithoutZero(ytdBudget) || 0}
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <div
                    style={{
                        marginBottom: 20
                    }}
                ></div>
            </Grid>
        </Grid>
    );
};
export const OutstandingContCard = (props) => {
    const { mtdProfit, mtdBudget, label, ytdProfit, ytdBudget } = props;

    return (
        <Grid container style={{ textAlign: 'justify', padding: '0px 5px ' }} justifyContent="space-around" alignItems="center">
            <Grid item xs={12} className="p-100">
                <Typography className="mainCardTextPrimary  ml-10">{label}</Typography>
                <hr />
            </Grid>
            <Grid item container xs={4} spacing={2} justifyContent="center">
                <Grid item xs={12} className="p-100">
                    <Typography className="mainCardTextPrimary  ml-10">Used Stock Units</Typography>
                </Grid>
                <Grid item>
                    <div className="vehicleRing">
                        <CircularProgressbar
                            value={setPercentage(mtdProfit, mtdBudget)}
                            text={`${setPercentage(mtdProfit, mtdBudget) || 0}%`}
                            styles={buildStyles({
                                textColor:
                                    setPercentage(mtdProfit, mtdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(mtdProfit, mtdBudget) >= 50 && setPercentage(mtdProfit, mtdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(mtdProfit, mtdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                pathColor:
                                    setPercentage(mtdProfit, mtdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(mtdProfit, mtdBudget) >= 50 && setPercentage(mtdProfit, mtdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(mtdProfit, mtdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                trailColor: 'lightgrey',
                                // strokeLinecap: '',
                                textSize: '27px'
                            })}
                            strokeWidth={8}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div
                        style={{
                            marginBottom: 5,
                            // display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">Current Month</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(mtdProfit) || 0}
                        </div>
                    </div>

                    <div
                        style={{
                            //  display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">(Prior Month)</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {' '}
                            {formatters.CurrencyThousandSeparatorWithoutZero(mtdBudget) || 0}
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid item container xs={4} spacing={2} justifyContent="center">
                <Grid item xs={12} className="p-100">
                    <Typography className="mainCardTextPrimary  ml-10">Used Stock Value</Typography>
                </Grid>
                <Grid item>
                    <div className="vehicleRing">
                        <CircularProgressbar
                            value={setPercentage(ytdProfit, ytdBudget)}
                            text={`${setPercentage(ytdProfit, ytdBudget) || 0}%`}
                            styles={buildStyles({
                                textColor:
                                    setPercentage(ytdProfit, ytdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdProfit, ytdBudget) >= 50 && setPercentage(ytdProfit, ytdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdProfit, ytdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                pathColor:
                                    setPercentage(ytdProfit, ytdBudget) < 50
                                        ? '#D92641'
                                        : setPercentage(ytdProfit, ytdBudget) >= 50 && setPercentage(ytdProfit, ytdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(ytdProfit, ytdBudget) >= 75
                                        ? '#569101'
                                        : '#D92641',
                                trailColor: 'lightgrey',
                                // strokeLinecap: '',
                                textSize: '27px'
                            })}
                            strokeWidth={8}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            marginBottom: 5,
                            // display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">Current Month</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(ytdProfit) || 0}
                        </div>
                    </div>

                    <div
                        style={{
                            //  display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="salesCardTextPrimary  ">(Prior Month)</div>
                        <div className="salesCurrencyFont  " style={{ textAlign: 'center' }}>
                            {' '}
                            {formatters.CurrencyThousandSeparatorWithoutZero(ytdBudget) || 0}
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <div
                    style={{
                        marginBottom: 20
                    }}
                ></div>
            </Grid>
        </Grid>
    );
};
