import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { ArrowLeft, ArrowRight, History, Image } from '@material-ui/icons';
import moment from 'moment/moment';
import { React, useContext, useEffect, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { SecondaryButton } from '../../../Core/FormInput';
import DialogComp from '../../../Core/Modal/dialogModal';
import { getDocumentByName, getOcrDocumentHistoryForJob } from '../../../Core/Service/workflowJobDocument-service';
import OcrDocHistory from '../../internal/workflow-job/WorkflowOcrDocHistoryModal';
import doc from '../../internal/workflow-job/doc.png';
import './viewer.scss';

const WIPDocumentsViewer = (props) => {
    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({
        imagesFromUrl: [],
        documentHistory: [],
        wipNumber: props.focusedRow.wipNumber,
        documentName: props.focusedRow.document,
        ocrDocumentName: props.focusedRow.ocrDocumentName,
        headerId: props.focusedRow.headerRecordID,
        ocrDocumentID: props.focusedRow.ocrDocumentID,

        showOcrDocHistory: false
    });

    const [currentPageNo, setPageNo] = useState(0);

    const [historyDisabled, setHistoryDisabled] = useState(true);

    useEffect(async () => {
        pullDocumentsAndUpdateState(1);
    }, [state.documentName]);

    const pullDocumentsAndUpdateState = async (docCount) => {
        setHistoryDisabled(true);

        const res = await getDocumentByName(state.documentName, docCount);
        let docHasNext = res.hasMorePage;
        setState((st) => {
            const imagesFromUrl = [...st.imagesFromUrl];
            imagesFromUrl.push(res.imgBase64Src);
            return {
                ...st,
                imagesFromUrl
            };
        });

        if (docHasNext) {
            pullDocumentsAndUpdateState(docCount + 1);
        } else {
            setHistoryDisabled(false);
        }
    };

    useEffect(async () => {
        pullDocumentHistoryAndUpdateState();
    }, [props.focusedRow.headerRecordID]);

    const pullDocumentHistoryAndUpdateState = async () => {
        const res = await getOcrDocumentHistoryForJob(state.headerId, state.ocrDocumentID);
        if (res.success) {
            setState((st) => {
                let newSt = { ...st, documentHistory: res.data };
                return newSt;
            });
        }
    };

    const hasPrev = (pageNo) => {
        return pageNo > 0;
    };

    const hasNext = (pageNo) => {
        let total = state.imagesFromUrl.length;
        return pageNo < total - 1;
    };

    const handleChangeDocument = (docName) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.imagesFromUrl = [];
            newSt.documentName = docName;
            newSt.showOcrDocHistory = false;
            return newSt;
        });
        setPageNo(0);
    };

    const updateState = (update) => {
        setState((st) => ({
            ...st,
            ...update
        }));
    };

    const handleOcrDocHistoryModal = (val) => {
        setState((st) => ({
            ...st,
            showOcrDocHistory: val
        }));
    };

    const getDocumentHistory = (jobId, ocrDocId) => {
        return getOcrDocumentHistoryForJob(jobId, ocrDocId);
    };

    return (
        <DialogComp
            className="dialog-section"
            maxWidth="xl"
            fullWidth
            title={`WIP Document Viewer ${state.wipNumber}-(${state.ocrDocumentName})`}
            onClose={props.handleClose}
        >
            {state.showOcrDocHistory && (
                <OcrDocHistory
                    jobId={state.headerId}
                    ocrDocId={state.ocrDocumentID}
                    onClose={() => handleOcrDocHistoryModal(false)}
                    showToast={showToast}
                    updateState={updateState}
                    showDoc={(docID, docName) => handleChangeDocument(docName)}
                    setDocumentWithOcrDocId={() => {}}
                    getDocumentHistory={getDocumentHistory}
                    adminView
                />
            )}

            <div>
                <Grid container spacing={1}>
                    {state.documentHistory?.map((m) => {
                        return (
                            <Grid item>
                                <SecondaryButton
                                    className="historydoclist"
                                    title={moment(m.createdAt).format('LLL')}
                                    variant={m.docName == state.documentName ? 'contained' : 'outlined'}
                                    onClick={() => handleChangeDocument(m.docName)}
                                    disabled={m.docName === state.documentName || historyDisabled}
                                >
                                    <Image />
                                </SecondaryButton>
                            </Grid>
                        );
                    })}
                    <Grid item xs={12}>
                        Page: {historyDisabled ? `Loading...` : `${currentPageNo + 1}/${state.imagesFromUrl.length}`}
                    </Grid>
                </Grid>
            </div>

            <div className="image-container">
                {state.imagesFromUrl.length > 0 ? <img style={{}} src={state.imagesFromUrl[currentPageNo]} /> : <img src={doc} />}
            </div>

            {state.imagesFromUrl.length > 0 && !historyDisabled && (
                <>
                    {hasPrev(currentPageNo) && (
                        <div className="doc-page-move go-prev">
                            <IconButton component="span" color="default" onClick={() => setPageNo((curr) => curr - 1)}>
                                <ArrowLeft />
                            </IconButton>
                        </div>
                    )}
                    {hasNext(currentPageNo) && (
                        <div className="doc-page-move go-next">
                            <IconButton component="span" color="default" onClick={() => setPageNo((curr) => curr + 1)}>
                                <ArrowRight />
                            </IconButton>
                        </div>
                    )}
                    <div className="doc-page-move history">
                        <Tooltip title="History of the Document">
                            <IconButton component="span" onClick={() => handleOcrDocHistoryModal(true)}>
                                <History />
                            </IconButton>
                        </Tooltip>
                    </div>
                </>
            )}
        </DialogComp>
    );
};

export default WIPDocumentsViewer;
