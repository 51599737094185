import { FormLabel, Grid, TextField } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useEffect } from 'react';
import TabComponent from '../../../../../Core/Controls/Tabs';
import ReportingBudgetTable from './budgetTable';
import { getNewUsedVehicleReportingBudgetScreenDropDown, getReportingBudgetScreenDropDown } from '../../../../../Core/Service/ReportScreens-service';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { SecondaryButton } from '../../../../../Core/FormInput';
import { useRef } from 'react';
import NewUsedReportingBudgetTable from './budgetTable';

const crumbs = (vehicleType) => [
    { name: 'Home', path: '/' },
    { name: vehicleType == 'N' ? 'New Vehicle Budget' : 'Used Vehicle Budget', active: true }
];

const NewUsedReportingBudget = (props) => {
    const childFunc = useRef(null);
    const [state, setState] = useState({
        filterList: [],
        branchList: [],
        branchCode: props.branchCode || '',
        branchName: props.branchName || '',
        yearCode: { description: `${new Date().getFullYear()}`, id: `${new Date().getFullYear()}` },
        vehicleTypeCode: {},
        currentMonthDays: [],
        customerList: [],
        customerCode: {},
        filterData: [],
        budgetYear: [],
        targetType: [],
        modelList: [],
        vehicleCategories: [],
        isValueChange: false,
        isRecord: true
    });

    useEffect(async () => {
        let res = await getNewUsedVehicleReportingBudgetScreenDropDown(props.vehicleType);
        if (res.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.budgetYear =
                    res.data.year.map((o) => {
                        return { description: `${o.name}`, id: `${o.id}` };
                    }) || [];
                newSt.vehicleCategories =
                    res.data.vehicleCategories.map((o) => {
                        return { description: `${o.name}`, id: `${o.id}` };
                    }) || [];
                newSt.vehicleTypeCode = newSt.vehicleCategories[0] || {};
                newSt.targetType = res.data.targetType || [];
                newSt.modelList = res.data.model || [];
                newSt.isRecord = true;
                return newSt;
            });
        } else {
            setState((st) => {
                let newSt = { ...st };
                newSt.isRecord = false;
                return newSt;
            });
        }
    }, [state.yearCode]);

    const handleAutoChange = (fieldName) => (e, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt[fieldName] = val;
            return newSt;
        });
    };

    const handleActionTriggerCheck = (value) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.isValueChange = value;
            return newSt;
        });
    };

    const tabs = state.targetType.map((q) => {
        return {
            label: `${q.name}`,
            body: (
                <NewUsedReportingBudgetTable
                    childFunc={childFunc}
                    selectedTargetType={q}
                    selectedCategories={state.vehicleTypeCode}
                    yearCode={state.yearCode}
                    modelList={state.modelList}
                    vehicleType={props.vehicleType}
                    handleActionTriggerCheck={handleActionTriggerCheck}
                />
            )
        };
    });

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(props.vehicleType)} />
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={12} sm={6} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Grid item>
                                                <FormLabel>Select Year&nbsp;</FormLabel>
                                            </Grid>
                                            <Grid item xs>
                                                <Autocomplete
                                                    disableClearable
                                                    autoSelect
                                                    options={state.budgetYear}
                                                    name="Years"
                                                    value={state.yearCode}
                                                    onChange={handleAutoChange('yearCode')}
                                                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                                    renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid item>
                                                <FormLabel>Vehicle Type&nbsp;</FormLabel>
                                            </Grid>
                                            <Grid item xs>
                                                <Autocomplete
                                                    disableClearable
                                                    autoSelect
                                                    options={state.vehicleCategories || []}
                                                    name="vehicleTypeCode"
                                                    value={state.vehicleTypeCode}
                                                    onChange={handleAutoChange('vehicleTypeCode')}
                                                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                                    renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <SecondaryButton className="setFullWidth" onClick={() => childFunc.current()} disabled={!state.isValueChange}>
                                Save
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                    {state.isRecord ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <TabComponent tabList={tabs} showWarning={state.isValueChange} />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                Something Went Wrong
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default NewUsedReportingBudget;
