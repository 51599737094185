import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions } from '../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import '../User Management/UserManagement.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { addWorkAccomplishedCodes } from '../../../Core/Service/workaccomplished-service';
const AddWorkAccomplishedCodeModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        WorkAccompCodeId: null,
        Description: '',
        errors: {},
        fields: {}
    });
    const WorkAccomplishedCodesDetails = props.WorkAccomplishedCodeDetails;

    useEffect(async () => {
        if (WorkAccomplishedCodesDetails) {
            setState((st) => ({
                ...st,
                Description: WorkAccomplishedCodesDetails.workAccomplishedDescription,
                WorkAccompCodeId: WorkAccomplishedCodesDetails.id
            }));
        }
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            const nst = { ...st, [name]: value, fields: fields };
            return nst;
        });
    };

    const handelSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            let res = await addWorkAccomplishedCodes(state.WorkAccompCodeId, state.Description);
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
                props.onFormSubmit(false);
            }
            showToast(res.message);
        }
    };
    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.Description) {
            formIsValid = false;
            errors['Description'] = 'Description cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    {state.WorkAccompCodeId ? (
                        <DialogTitles>Update Work Accomplished Code</DialogTitles>
                    ) : (
                        <DialogTitles>Add Work Accomplished Code</DialogTitles>
                    )}
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll" style={{ overflow: 'auto', height: '12vh', width: '56vh' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Description
                        </FormLabel>
                        <TextBox name="Description" onChange={fieldChange} value={state.Description} autoFocus={true} />
                        <span className="mandatoryfields">{state.errors['Description']}</span>
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddWorkAccomplishedCodeModal;
