import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import './salesDashboardSetting.scss';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { useEffect } from 'react';
import { getFinanceList, getLeagueTableByData, getNewStockbyID, getUsedStockabyID } from '../../../../../Core/Service/SalesPerformance-service';
import { CircularProgress, Grid, InputLabel, Tab, Typography } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import moment from 'moment';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { SelectBox } from '../../../../../Core/FormInput';
import { react } from '@babel/types';
import { forEach } from 'lodash';
import { getSaleUsedInvoice, getSalesNewRegistration } from '../../../../../Core/Service/groupDashboard-services';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const useStyles = makeStyles({
    table: {
        minWidth: 400,
        background: 'white',
        border: '2px solid white'
    },
    root: {
        flexGrow: 1
        // backgroundColor: theme.palette.background.paper
    }
});

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ background: 'rgba(0,0,0,0.8)', fontSize: 10, padding: 10 }}>
                <p style={{ color: 'white' }}>
                    <b>{`${label}`}</b>
                </p>
                {/* <p className="intro">{getIntroOfPage(label)}</p> */}
                {payload.map((n, i) => {
                    return (
                        <p key={i} style={{ color: COLORS[i] }}>
                            {n.name}&nbsp;:&nbsp;&nbsp;<b>{formatters.CurrencyThousandSeparatorWithoutZero(n.value)}</b>
                        </p>
                    );
                })}
            </div>
        );
    }

    return null;
};

const COLORS = {
    0: '#0074D9',
    1: '#FF4136',
    2: '#2ECC40',
    3: '#FF851B',
    4: '#7FDBFF',
    5: '#B10DC9',
    6: '#FFDC00',
    7: '#C33C9A',
    8: '#39CCCC',
    9: '#01FF70',
    10: '#85144b',
    11: '#F012BE',
    12: '#3D9970',
    13: '#111111',
    14: '#AAAAAA'
};

let DateList = [
    { name: 'Jan-23', val: 'jan' },
    { name: 'Feb-23', val: 'feb' },
    { name: 'Mar-23', val: 'mar' },
    { name: 'Apr-23', val: 'apr' },
    { name: 'May-23', val: 'may' },
    { name: 'Jun-23', val: 'jun' },
    { name: 'Jul-23', val: 'jul' },
    { name: 'Aug-23', val: 'aug' },
    { name: 'Sep-23', val: 'sep' },
    { name: 'Oct-23', val: 'oct' },
    { name: 'Nov-23', val: 'nov' },
    { name: 'Dec-23', val: 'dec' }
];

const ManiHederCell = withStyles((theme) => ({
    head: {
        backgroundColor: (props) => (props.getMonth === props.id ? 'green' : 'grey'),
        fontSize: 14,
        borderRight: '1px solid White',
        color: theme.palette.common.white,
        // color: theme.palette.common.white,
        padding: '5px'
    },
    body: {
        backgroundColor: 'lightgrey',
        fontSize: 14,
        borderRight: '1px solid white',
        color: theme.palette.common.black,
        padding: '5px'
    }
}))(TableCell);

const BodyCell = withStyles((theme) => ({
    body: {
        fontSize: 12,
        fontWeight: 500,
        borderRight: '1px solid rgba(0,0,0,0.3)',
        padding: '3px 5px',
        fontWeight: 900
    }
}))(TableCell);
const BlankCell = withStyles((theme) => ({
    body: {
        border: 'none',
        borderRight: '1px solid rgba(0,0,0,0.3)',
        padding: '3px 5px'
    }
}))(TableCell);

const TotalCell = withStyles((theme) => ({
    body: {
        background: 'black',
        color: 'white',
        fontSize: 12,
        fontWeight: 500,
        borderRight: '1px solid white',
        padding: '5px 5px',
        fontWeight: 900
    }
}))(TableCell);

const RenderMonth = (props) => {
    let { list, profit, unit } = props;
    let TotalMonth = list.reduce((a, b) => {
        var index = a.find((item) => item);
        if (index) {
            index[profit] += b[profit];
            index[unit] += b[unit];

            return a;
        }
        a.push({ ...b });
        return a;
    }, []);

    const getMonth = moment().month();
    const classes = useStyles();
    let Header = ['', 'Profit', 'Units'];

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <ManiHederCell align="center" colSpan={6} getMonth={getMonth} id={props.id}>
                            {props.name}
                        </ManiHederCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {Header?.map((m) => (
                            <ManiHederCell align="center" getMonth={getMonth} id={props.id}>
                                {m}
                            </ManiHederCell>
                        ))}
                    </TableRow>
                    {list?.length == 0 ? (
                        <TableRow>
                            <BodyCell align="center" colSpan={6} height={400}>
                                No Record Found
                            </BodyCell>
                        </TableRow>
                    ) : (
                        list?.map((o, i) => (
                            <TableRow>
                                <BodyCell align="left">{o.ad}</BodyCell>
                                <BodyCell align="center">{formatters.CurrencyThousandSeparatorWithoutZero(o[profit])}</BodyCell>
                                <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(o[unit])}</BodyCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
                <TableBody>
                    {TotalMonth.map((t) => (
                        <TableRow>
                            <TotalCell align="left">Total</TotalCell>
                            <TotalCell align="center">{formatters.CurrencyThousandSeparatorWithoutZero(t[profit]) || 0}</TotalCell>
                            <TotalCell align="center">{formatters.ThousandSeparatorWithoutZero(t[unit]) || 0}</TotalCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
const RenderQuater = (props) => {
    const { list, m1, m2, m3 } = props;
    let Arr = [];
    let TotalList = list.forEach((q) => {
        Arr.push({
            totalProfit: q[`${m1}Profit`] + q[`${m2}Profit`] + q[`${m3}Profit`],
            totalUnit: q[`${m1}Unit`] + q[`${m2}Unit`] + q[`${m3}Unit`],
            ad: q.ad
        });
    });

    let TotalMonth = Arr.reduce((a, b) => {
        var index = a.find((item) => item);
        if (index) {
            index.totalProfit += b.totalProfit;
            index.totalUnit += b.totalUnit;

            return a;
        }
        a.push({ ...b });
        return a;
    }, []);

    const getMonth = moment().month();
    const classes = useStyles();
    let Header = ['', 'Profit', 'Unit'];

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <ManiHederCell align="center" colSpan={6} getMonth={getMonth} id={props.id}>
                            {props.name}
                        </ManiHederCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {Header?.map((m) => (
                            <ManiHederCell align="center" getMonth={getMonth} id={props.id}>
                                {m}
                            </ManiHederCell>
                        ))}
                    </TableRow>
                    {list?.lenght > 0 ? (
                        <TableRow>
                            <BodyCell align="left" colSpan={6}>
                                No Record Found
                            </BodyCell>
                        </TableRow>
                    ) : (
                        Arr?.map((o, i) => (
                            <TableRow>
                                <BodyCell align="left">{o.ad}</BodyCell>
                                <BodyCell align="center">{formatters.CurrencyThousandSeparatorWithoutZero(o.totalProfit) || 0}</BodyCell>
                                <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(o.totalUnit) || 0}</BodyCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
                <TableBody>
                    {list?.length > 0 &&
                        TotalMonth?.map((t) => (
                            <TableRow>
                                <TotalCell align="left">Total</TotalCell>
                                <TotalCell align="left">{formatters.CurrencyThousandSeparatorWithoutZero(t.totalProfit)}</TotalCell>
                                <TotalCell align="center">{formatters.ThousandSeparatorWithoutZero(t.totalUnit)}</TotalCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default function InvoiceQuaterTable(props) {
    const [width, height] = useWindowSize();
    // const currentYear = moment().year();
    // // const getCourter = `Q${moment().quarter()}`;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let Qtr = params.get('Qtr') || '';

    const { list } = props;
    const classes = useStyles();

    const [value, setValue] = React.useState(Qtr ? Qtr : 'Q1');
    const [state, setState] = React.useState({
        sortedList: [],
        isLoader: true,
        // year: currentYear,
        FranchiseList: [],
        fn: null,
        GrapgData: []
    });

    useEffect(async () => {
        let res = await getSaleUsedInvoice(1);
        if (res.success) {
            let sortedList = res?.data?.list.reduce((acc, obj) => {
                var index = acc.find((item) => item.ad === obj.ad);
                if (index) {
                    index.janProfit += obj.janProfit;
                    index.febProfit += obj.febProfit;
                    index.marProfit += obj.marProfit;
                    index.aprProfit += obj.aprProfit;
                    index.mayProfit += obj.mayProfit;
                    index.junProfit += obj.junProfit;
                    index.julProfit += obj.julProfit;
                    index.augProfit += obj.augProfit;
                    index.sepProfit += obj.sepProfit;
                    index.octProfit += obj.octProfit;
                    index.novProfit += obj.novProfit;
                    index.decProfit += obj.decProfit;
                    index.janUnit += obj.janUnit;
                    index.febUnit += obj.febUnit;
                    index.marUnit += obj.marUnit;
                    index.aprUnit += obj.aprUnit;
                    index.mayUnit += obj.mayUnit;
                    index.junUnit += obj.junUnit;
                    index.julUnit += obj.julUnit;
                    index.augUnit += obj.augUnit;
                    index.sepUnit += obj.sepUnit;
                    index.octUnit += obj.octUnit;
                    index.novUnit += obj.novUnit;
                    index.decUnit += obj.decUnit;
                    return acc;
                }
                acc.push({ ...obj });
                return acc;
            }, []);

            setState((st) => ({ ...st, sortedList: sortedList, isLoader: false }));
        }
    }, []);

    useEffect(() => {
        let GrapgData = [];
        DateList.forEach((n) => {
            state.sortedList.forEach((k) => {
                let index = GrapgData.findIndex((m) => n.name === m.name);
                if (index > -1) {
                    GrapgData[index][k.ad] = k[`${n.val}Profit`];
                } else {
                    GrapgData.push({
                        name: n.name,
                        [k.ad]: k[`${n.val}Profit`]
                    });
                }
            });
        });

        setState((st) => ({ ...st, GrapgData: GrapgData || [] }));
    }, [state.sortedList]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Grid item xs={12}>
                <Typography
                    variant="h5"
                    style={{
                        textAlign: 'left',
                        marginLeft: 10,
                        marginBottom: width >= 600 ? 20 : 0,
                        fontWeight: 600,
                        fontSize: width >= 600 ? 24 : 18
                    }}
                >
                    {'INVOICED'}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                        width={500}
                        height={300}
                        data={state.GrapgData}
                        margin={{
                            top: 5,
                            right: 20,
                            left: width >= 600 ? 10 : -10,
                            bottom: 5
                        }}
                        // margin={{
                        //     top: 5,
                        //     right: 20,
                        //     left: 10,
                        //     bottom: 5
                        // }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" style={{ fontSize: width >= 600 ? 12 : 10 }} />
                        <YAxis style={{ fontSize: width >= 600 ? 12 : 10 }} />
                        <Tooltip content={<CustomTooltip />} />
                        {/* <Tooltip /> */}
                        <Legend iconType="square" style={{ fontSize: 8 }} />
                        {state.sortedList.map((n, i) => {
                            return <Line type="monotone" dataKey={n.ad} stroke={COLORS[i]} strokeWidth={2} />;
                        })}
                    </LineChart>
                </ResponsiveContainer>
            </Grid>
            {/* <Grid item>
                    <BreadCrumbs crumbs={crumbs} />{' '}
                </Grid> */}
            {/* <Grid item xs={2}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                        <div> Year:&nbsp;</div>
                        <div style={{ width: '80%' }}>
                            {' '}
                            <SelectBox List={YearList} value={state.year} name="year" onChange={handleSelect} />
                        </div>
                    </div>
                </Grid> */}
            {/* <Grid item xs={2}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                        <div> Franchise:&nbsp;</div>
                        <div style={{ width: '80%' }}>
                            <SelectBox List={state.FranchiseList || []} value={state.fn} name="fn" onChange={handleSelect} />
                        </div>
                    </div>
                </Grid> */}

            <TabContext value={value}>
                {/* <AppBar position="static"> */}
                <TabList onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Quarter-1" value={'Q1'} />
                    <Tab label="Quarter-2" value={'Q2'} />
                    <Tab label="Quarter-3" value={'Q3'} />
                    <Tab label="Quarter-4" value={'Q4'} />
                    {/* <Tab label="YTD" value={'ytd'} /> */}
                </TabList>
                <>
                    {state.isLoader ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <TabPanel value={'Q1'}>
                                {' '}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="January" id={0} list={state.sortedList} profit={'janProfit'} unit={'janUnit'} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="February" id={1} list={state.sortedList} profit={'febProfit'} unit={'febUnit'} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="March" id={2} list={state.sortedList} profit={'marchProfit'} unit={'marchUnit'} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderQuater name="Quarter 1" list={state.sortedList} m1={'jan'} m2={'feb'} m3={'mar'} />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={'Q2'}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="April" id={3} list={state.sortedList} profit={'aprProfit'} unit={'aprUnit'} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="May" id={4} list={state.sortedList} profit={'mayProfit'} unit={'mayUnit'} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="June" id={5} list={state.sortedList} profit={'juneProfit'} unit={'juneUnit'} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderQuater name="Quarter 2" id={0} list={state.sortedList} m1={'apr'} m2={'may'} m3={'june'} />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={'Q3'}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="July" id={6} list={state.sortedList} profit={'julProfit'} unit={'julUnit'} />{' '}
                                    </Grid>{' '}
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="August" id={7} list={state.sortedList} profit={'augProfit'} unit={'augUnit'} />{' '}
                                    </Grid>{' '}
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="September" id={8} list={state.sortedList} profit={'sepProfit'} unit={'sepUnit'} />{' '}
                                    </Grid>{' '}
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderQuater name="Quarter 3" id={0} list={state.sortedList} m1={'jul'} m2={'aug'} m3={'sep'} />{' '}
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={'Q4'}>
                                {' '}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="October" id={9} list={state.sortedList} profit={'octProfit'} unit={'octUnit'} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="November" id={10} list={state.sortedList} profit={'novProfit'} unit={'novUnit'} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth name="December" id={11} list={state.sortedList} profit={'decProfit'} unit={'decUnit'} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderQuater name="Quarter 4" id={0} list={state.sortedList} m1={'oct'} m2={'nov'} m3={'dec'} />
                                    </Grid>
                                </Grid>{' '}
                            </TabPanel>

                            {/* <TabPanel value={'ytd'}>
                                {' '}
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <RenderMonth
                                            name="YTD"
                                            id={0}
                                            list={state.sortedList}
                                            profit={'ytdProfit'}
                                            unit={'ytdUnit'}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel> */}
                        </>
                    )}
                </>
            </TabContext>
        </div>
    );
}
