import { get, post } from './http-calls';

export const addUpdateResultList = async (data) => {
    let res = await post(`qc/lists`, data, { useAuthToken: true });
    return res;
};

export const addUpdateResultListOption = async (data) => {
    let res = await post(`qc/options`, data, { useAuthToken: true });
    return res;
};

export const addUpdateQCItem = async (data) => {
    let res = await post(`qc/Items`, data, { useAuthToken: true });
    return res;
};

export let getFranchiseHeaders = async () => {
    let data = await get(`qc/FranchiseHeaders`, { useAuthToken: true });
    return data;
};

export let getDropdown = async () => {
    let data = await get(`qc/ResultListDropdown`, { useAuthToken: true });
    return data;
};
