import { get, post, put } from './http-calls';

export const getOcrDocuments = async () => {
    let res = await get('OCRDocument/OcrDocumentsList', { useAuthToken: true });
    return res;
};
export const getOcrDocumentsByProcess = async (Id) => {
    let res = await get(`OCRDocument/OcrDocumentsList/ByProcessID?Id=${Id}`, { useAuthToken: true });
    return res;
};
export const getOcrDocument = async (OcrDocumentId) => {
    let res = await get(`OCRDocument/GetOcrDocumentsDetails/${OcrDocumentId}`, { useAuthToken: true });
    return res;
};
export const addOcrDocument = async (id, name, ocrName = 1, tableName, columnName, OCRDocumentIgnoreDepartment, processID) => {
    let res = await post(
        `OCRDocument/AddUpdateOcrDocuments`,
        {
            Id: id,
            Name: name,
            OcrApiId: 1,
            TableName: tableName,
            ColumnName: columnName,
            OCRDocumentIgnoreDepartment: OCRDocumentIgnoreDepartment,
            processID: processID
        },
        { useAuthToken: true }
    );
    return res;
};

export const getTableList = async () => {
    let res = await get('OCRDocument/GetTableList', { useAuthToken: true });
    return res;
};
export const getTableColumnsList = async (tablename) => {
    let res = await get(`OCRDocument/GetTableColumnsList?TableName=${tablename}`, { useAuthToken: true });
    return res;
};

export const getOcrApiList = async () => {
    let res = await get('OCRDocument/GetOcrApiList', { useAuthToken: true });
    return res;
};
export const getOcrDocumentToProcess = async (ocrDocId) => {
    let res = await get(`OcrDocumentToProcess?OcrDocumentId=${ocrDocId}`, { useAuthToken: true });
    return res;
};
export const getOcrDocumentField = async (ocrDocId) => {
    let res = await get(`OcrDocumentFields?OcrDocumentId=${ocrDocId}`, { useAuthToken: true });
    return res;
};
export const addOCRDocFields = async (id, columnName, label, isRequired, isPrimary, OCRDocId) => {
    let res = await post(
        `OcrDocumentFields`,
        {
            Id: id,
            FieldLabel: label,
            OcrDocumentId: OCRDocId,
            IsRequired: isRequired,
            ColumnName: columnName,
            IsPrimaryKey: isPrimary
        },
        { useAuthToken: true }
    );
    return res;
};
export const addOCRDocToProcess = async (ocrDocId, id, InputLocation, OutputLocation, BranchID, isDeleted) => {
    let res = await post(
        `OcrDocumentToProcess`,
        {
            Id: id,
            InputLocation: InputLocation,
            OcrDocumentId: ocrDocId,
            OutputLocation: OutputLocation,
            BranchID: BranchID,
            IsDeleted: isDeleted
        },
        { useAuthToken: true }
    );
    return res;
};

export const getOCRDocumentFieldToProcess = async (ocrDocId) => {
    let res = await get(`OcrDocumentToProcess/DocumentFieldsToProcess/${ocrDocId}`, { useAuthToken: true });
    return res;
};

export const getOCRDocumentFieldToProcessDropdown = async (ocrDocId) => {
    let res = await get(`OcrDocumentToProcess/DocumentFieldsToProcessDropdown/${ocrDocId}`, { useAuthToken: true });
    return res;
};

export const addUpdateDocumentFieldToProcess = async (data) => {
    let res = await post('OcrDocumentToProcess/DocumentFieldsToProcess', data, { useAuthToken: true });
    return res;
};
