import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import temp from '/locales/en/common.json';

let lngs = ['en', 'pl'];
// array will have exact name of files inside public/locales/[lang code]
let localesFiles = ['common', 'navbar'];

// resource will be populated by above arrays - lang and locales files.
const resources = {};

lngs.forEach((lng) => {
    resources[lng] = {};
    Promise.all(
        localesFiles.map((fileName) => {
            return fetch(`/locales/${lng}/${fileName}.json`)
                .then((resp) => resp.json())
                .then((resJson) => [fileName, resJson]);
        })
    ).then((fileNameJsonList) => {
        fileNameJsonList.forEach((nmRes) => {
            let resJson = nmRes[1];
            let tempObj = {};
            Object.keys(resJson).forEach((k) => {
                tempObj[k.replace(/\s+/, '')] = resJson[k];
            });
            resources[lng][nmRes[0]] = tempObj;
        });
    });
});

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,

        // Set default namespace
        // defaultNS: 'common',

        lng: 'en',
        // debug: true,
        // whitelist: Languages,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;
