import React, { useEffect, useState, useRef } from 'react';
import ByteImage from '../../ByteImage/ByteImage';
import { SignPad } from '../../../SignaturePad/SignPad';
import { getDamageImages } from '../../../Service/apprisal-service';
import { SecondaryButton } from '../../../FormInput';
import DialogComp from '../../Dialog/DialogComp';
import { Grid } from '@material-ui/core';

let VehFaceComponent = function ({ config, state, onFieldChange, readOnly, screenState }) {
    let controlName = config.screenColumnJsonName;
    let controlValue = state[controlName];
    let vehicleID = screenState.vehicleDetails.vehicleTypeId;

    let readonly = false;

    let [localState, setLocalState] = useState({
        loaded: false,
        // images: screenState.damageFaces.images || [],
        images: [],
        showModal: false
    });

    useEffect(async () => {
        // if (localState.images.length === 0) {
        // if (screenState.VehicleTypeId) {
        let res = await getDamageImages(`${config.screenColumnControlValueApiEndPoint}?vehTypeId=${vehicleID}`);
        let images = res.data.map((o) => {
            let imgFromSt = controlValue?.find((i) => i.faceName === o.faceName);
            let imgSrc = undefined;
            if (imgFromSt) {
                imgSrc = imgFromSt.imgSrc;
            }
            return {
                faceName: o.faceName,
                orgImgSrc: o.imgSrc,
                imgSrc: imgSrc
            };
        });
        setLocalState({
            ...localState,
            loaded: true,
            images: images
        });
        // }
        // }
    }, []);

    function modalClose() {
        setLocalState((st) => ({ ...st, selectedImage: undefined, showModal: false }));
    }

    function handleclose() {
        setLocalState((st) => ({ ...st, showModal: false }));
    }

    function selectImage(image) {
        setLocalState((st) => ({ ...st, selectedImage: image, showModal: true }));
    }

    function undoChanges(imageIndex) {
        setLocalState((st) => {
            st.images[imageIndex].imgSrc = undefined;
            updateParentStatus(st);
            return {
                ...st
            };
        });
    }

    function updateParentStatus(st) {
        let imagesWithModification = st.images
            .filter((i) => i.imgSrc)
            .map((i) => {
                return {
                    faceName: i.faceName,
                    imgSrc: i.imgSrc
                };
            });

        onFieldChange({ target: { name: controlName, value: imagesWithModification } });
    }

    function storeModifiedImage(mImgSrc, anythingDrawn) {
        setLocalState((st) => {
            st.selectedImage && (st.selectedImage.imgSrc = anythingDrawn ? mImgSrc : undefined);
            st.selectedImage = undefined;
            updateParentStatus(st);
            return {
                ...st
            };
        });
    }

    return (
        <div>
            {localState.showModal ? (
                <DialogComp
                    isVisible={localState.selectedImage}
                    title={localState.selectedImage && `Vehicle Face - ${localState.selectedImage.faceName}`}
                    onClose={modalClose}
                >
                    {localState.selectedImage && (
                        <div className="drawer">
                            <SignPad
                                penColor="red"
                                resizeCanvasAsImage={true}
                                onDone={storeModifiedImage}
                                readOnly={readOnly}
                                bgImage={localState.selectedImage.imgSrc || localState.selectedImage.orgImgSrc}
                                isDrawnImage={!!localState.selectedImage.imgSrc}
                                showSubmitButton={!readOnly}
                                showClearButton={!readOnly}
                                showClearAllButton={!readOnly}
                                orgBgImage={localState.selectedImage.orgImgSrc}
                                dontRemoveBgOnClear
                                handleclose={handleclose}
                            ></SignPad>
                        </div>
                    )}
                </DialogComp>
            ) : null}
            <Grid container>
                {localState.images.map((img, i) => (
                    <Grid item>
                        <div
                            style={{
                                height: 300,
                                width: 400,
                                margin: 10,
                                border: '1px dotted rgba(0,0,0,0.4) ',
                                cursor: 'pointer',
                                position: 'relative'
                            }}
                            key={img.faceName}
                        >
                            <ByteImage
                                style={{ height: '100%', width: '100%' }}
                                onClick={() => selectImage(img)}
                                src={img.imgSrc || img.orgImgSrc}
                                title={img.faceName}
                            />
                            {img.imgSrc && (
                                <span
                                    title="Undo"
                                    onClick={() => undoChanges(i)}
                                    style={{
                                        position: 'absolute',
                                        right: '3px',
                                        top: '4px',
                                        fontSize: '22px',
                                        border: '1px solid lightslategrey',
                                        padding: '0px 5px'
                                    }}
                                >
                                    &#8630;
                                </span>
                                // <SecondaryButton title="Undo" onClick={() => undoChanges(i)} >
                                //     &#8630;
                                // </SecondaryButton>
                            )}
                        </div>
                    </Grid>
                ))}
            </Grid>
            {/* {localState.images.map((img, i) => (
                <div key={img.faceName}>
                    <ByteImage
                        onClick={() => selectImage(img)}
                        src={img.imgSrc || img.orgImgSrc}
                        title={img.faceName}
                        style={{
                            height: 250,
                            width: 350,
                            margin: 10,
                            border: '1px dotted rgba(0,0,0,0.4) '
                        }}
                    />
                    {img.imgSrc && (
                        <SecondaryButton title="Undo" onClick={() => undoChanges(i)}>
                            &#8630;
                        </SecondaryButton>
                    )}
                </div>
            ))} */}
        </div>
    );
};

export default VehFaceComponent;
