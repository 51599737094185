import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { blue } from '@material-ui/core/colors';
import { border } from '@material-ui/system';
import { Grid } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#29308C',
        color: theme.palette.common.white,
        padding: 6,
        border: '1px solid black',
        fontSize: 14,
        textAlign: 'center'
        // borderRight: '3px solid white'
    },
    body: {
        fontSize: 11,
        border: '1px solid ',
        padding: 0,
        backgroundColor: '#29308C',
        color: theme.palette.common.white
    }
}))(TableCell);

const LightGreyCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#1ECBE1',
        color: theme.palette.common.black,
        padding: 6,
        border: '1px solid black',
        fontSize: 14,
        textAlign: 'center'
        // borderRight: '3px solid white'
    },
    body: {
        fontSize: 11,
        border: '1px solid ',
        padding: 0,
        backgroundColor: '#d3d3d3',
        textAlign: 'center',
        color: theme.palette.common.black
    }
}))(TableCell);

const SkyblueCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#1ECBE1',
        color: theme.palette.common.white,
        padding: 6,
        border: '1px solid black',
        fontSize: 14,
        textAlign: 'center'
        // borderRight: '3px solid white'
    }
}))(TableCell);

const EmptyCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'white',
        color: theme.palette.common.white,
        padding: 6,
        borderBottom: '1px solid black',
        fontSize: 14,
        textAlign: 'center'
        // borderRight: '3px solid white'
    },
    body: {
        fontSize: 11,
        border: '1px solid',
        padding: 6,
        backgroundColor: 'white',
        textAlign: 'center',
        color: theme.palette.common.black
    }
}))(TableCell);

const CellWithoutPadding = withStyles((theme) => ({
    body: {
        fontSize: 11,
        border: '1px solid black',
        padding: 0,
        backgroundColor: 'white',
        textAlign: 'center',
        color: theme.palette.common.black
    }
}))(TableCell);

const LightSkyBlue = withStyles((theme) => ({
    head: {
        backgroundColor: '#95D9FA',
        color: theme.palette.common.black,
        padding: 6,
        border: '1px solid black',
        fontSize: 14,
        textAlign: 'center'
        // borderRight: '3px solid white'
    }
}))(TableCell);
const LightYellowCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#CDE272',
        color: theme.palette.common.black,
        padding: 6,
        border: '1px solid black',
        fontSize: 14,
        textAlign: 'center'
        // borderRight: '3px solid white'
    }
}))(TableCell);

const GraphCell = withStyles((theme) => ({
    head: {
        fontSize: 11,
        border: '1px solid black',
        padding: 0,
        backgroundColor: 'white',
        textAlign: 'center',
        color: theme.palette.common.black
    },
    body: {
        fontSize: 11,
        border: '1px solid black',
        padding: '2px 6px',
        backgroundColor: 'white',
        // textAlign: 'center',
        color: theme.palette.common.black
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    // root: {
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.action.hover
    //     }
    // }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        width: '100%'
    }
});

export const TargetTableOne = () => {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <LightSkyBlue width="45%">EOM Productive Hours Forecast</LightSkyBlue>
                        <EmptyCell width="10%"></EmptyCell>
                        <LightYellowCell width="45%">Revised Invoicing Target to reduce WIP</LightYellowCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell>
                            <Grid container justifyContent="space-evenly" alignItems="center">
                                <Grid item xs={3} className="brw alignCenter p10">
                                    Daily Productive Hrs Actual
                                </Grid>
                                <Grid item xs={3} className="brw alignCenter p10">
                                    Projected ME Hours (Actual)
                                </Grid>
                                <Grid item xs={3} className="brw alignCenter p10">
                                    Efficiency
                                </Grid>

                                <Grid item xs={3} className=" alignCenter p10">
                                    Live Forecast Target
                                </Grid>
                            </Grid>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Grid container justifyContent="space-evenly" alignItems="center">
                                <Grid item xs={12} className="brw alignCenter p10">
                                    EOM Re-Fcast Budget 5% Stretch
                                </Grid>
                            </Grid>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Grid container justifyContent="space-evenly" alignItems="center">
                                <Grid item xs={4} className="brw alignCenter p10">
                                    WIP Reduction
                                </Grid>
                                <Grid item xs={4} className="brw alignCenter p10">
                                    Revised Monthly Invoicing Target
                                </Grid>
                                <Grid item xs={4} className=" alignCenter p10">
                                    Revised Daily Invoicing Targets
                                </Grid>
                            </Grid>
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export const TargetTableTwo = () => {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Location</StyledTableCell>
                        <StyledTableCell>Mon</StyledTableCell>
                        <StyledTableCell>Tue</StyledTableCell>
                        <StyledTableCell>Wed</StyledTableCell>
                        <StyledTableCell>Thu</StyledTableCell>
                        <StyledTableCell>Fri</StyledTableCell>
                        <StyledTableCell>Graphs</StyledTableCell>
                        <StyledTableCell>Ide</StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                    </TableRow>
                    <TableRow>
                        <LightGreyCell>Totals</LightGreyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                    </TableRow>
                    <TableRow>
                        <LightGreyCell>Previous Week</LightGreyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export const TargetTableThree = () => {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Location</StyledTableCell>
                        <StyledTableCell>Mon</StyledTableCell>
                        <StyledTableCell>Tue</StyledTableCell>
                        <StyledTableCell>Wed</StyledTableCell>
                        <StyledTableCell>Thu</StyledTableCell>
                        <StyledTableCell>Fri</StyledTableCell>
                        <StyledTableCell>Graphs</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                    </TableRow>
                    <TableRow>
                        <EmptyCell>Group (MTD)</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                    </TableRow>
                    <TableRow>
                        <LightGreyCell>Daily Invoicing</LightGreyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export const TargetTableFour = () => {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <EmptyCell width="10%"></EmptyCell>
                        <StyledTableCell width="90%">To Do</StyledTableCell>
                        {/* <StyledTableCell></StyledTableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <LightGreyCell>Actual</LightGreyCell>
                        <CellWithoutPadding>
                            <Grid container>
                                <Grid item xs={3} className="p10 alignCenter brb">
                                    0
                                </Grid>
                                <Grid item xs={3} className="p10 alignCenter brb">
                                    0
                                </Grid>
                                <Grid item xs={3} className="p10 alignCenter brb">
                                    0
                                </Grid>
                                <Grid item xs={3} className="p10 alignCenter ">
                                    0
                                </Grid>
                            </Grid>
                        </CellWithoutPadding>
                    </TableRow>
                    {/* <TableRow>
                        <LightGreyCell>Actual</LightGreyCell>
                        <EmptyCell></EmptyCell>
                    </TableRow> */}
                    <TableRow>
                        <LightGreyCell>Budget</LightGreyCell>
                        <CellWithoutPadding>
                            <Grid container>
                                <Grid item xs={3} className="p10 alignCenter brb">
                                    0
                                </Grid>
                                <Grid item xs={3} className="p10 alignCenter brb">
                                    0
                                </Grid>
                                <Grid item xs={3} className="p10 alignCenter brb">
                                    0
                                </Grid>
                                <Grid item xs={3} className="p10 alignCenter ">
                                    0
                                </Grid>
                            </Grid>
                        </CellWithoutPadding>
                    </TableRow>
                    <TableRow>
                        <LightGreyCell>Variance</LightGreyCell>
                        <CellWithoutPadding>
                            <Grid container>
                                <Grid item xs={3} className="p10 alignCenter brb">
                                    0
                                </Grid>
                                <Grid item xs={3} className="p10 alignCenter brb">
                                    0
                                </Grid>
                                <Grid item xs={3} className="p10 alignCenter brb">
                                    0
                                </Grid>
                                <Grid item xs={3} className="p10 alignCenter ">
                                    0
                                </Grid>
                            </Grid>
                        </CellWithoutPadding>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export const TargetTableFive = () => {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Inv Variance</StyledTableCell>
                        <StyledTableCell>Mon</StyledTableCell>
                        <StyledTableCell>Tue</StyledTableCell>
                        <StyledTableCell>Wed</StyledTableCell>
                        <StyledTableCell>Thu</StyledTableCell>
                        <StyledTableCell>Fri</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <EmptyCell>Revised Target</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                    </TableRow>
                    <TableRow>
                        <EmptyCell>Budget</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                        <EmptyCell>-</EmptyCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export const GraphTable = () => {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <EmptyCell width="5%"></EmptyCell>
                        <GraphCell width="5%">Mon</GraphCell>
                        <GraphCell width="5%">Tue</GraphCell>
                        <GraphCell width="5%">Wed</GraphCell>
                        <GraphCell width="5%">Thu</GraphCell>
                        <GraphCell width="5%">Fri</GraphCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <GraphCell align="left">
                            <span style={{ heigth: '10px', width: '20px', background: '#CA354D' }}>&nbsp;&nbsp;&nbsp;</span>&nbsp; 30+
                        </GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                    </TableRow>
                    <TableRow>
                        <GraphCell align="left">
                            <span style={{ heigth: '10px', width: '20px', background: '#4F61B0' }}>&nbsp;&nbsp;&nbsp;</span>&nbsp; 0 to 30
                        </GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                    </TableRow>
                    <TableRow>
                        <GraphCell align="left">
                            {' '}
                            <span style={{ heigth: '10px', width: '20px', background: '#93A0A1' }}>&nbsp;&nbsp;&nbsp;</span>&nbsp; Pre Books
                        </GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                        <GraphCell>-</GraphCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
