import React, { useContext, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Paper, TableContainer, Table, TableBody, TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import { postImportParts } from '../../../Core/Service/admin-service';
import { SecondaryButton } from '../../../Core/FormInput';
import { InputLabel, Typography } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import downloadFile from '../../../Template/PartsImportSample.xlsx';
import DataGridComp from '../../../Core/Grid';
import AppContext from './../../../App/AppContext';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '8px 20px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '8px 20px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '25px',
        color: '#f50057',
        fontWeight: 600,
        paddingLeft: 9
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    }
}));

const ImportParts = (props) => {
    const [state, setState] = useState({
        errors: {},
        file: '',
        rows: [],
        showTable: false
    });

    const classes = useStyles();

    const { showToast } = useContext(AppContext);

    const column_new = [
        {
            field: 'id',
            headerName: 'ID',
            width: 150,
            sortable: false,
            hide: true
        },

        {
            field: 'partNumber',
            headerName: 'Part Number',
            flex: 1
        },
        { field: 'description', headerName: 'Description', flex: 2 },
        { field: 'result', headerName: 'Result', flex: 1 }
    ];

    const onFileSelect = (e) => {
        const { files } = e.target;
        if (files.length) {
            setState((st) => ({ ...st, fileName: files[0].name, selectedFile: files[0] }));
        }
    };

    const handleSubmit = async () => {
        if (state.fileName && state.selectedFile) {
            setState((st) => ({ ...st, uploading: true }));
            const data = new FormData();
            data.append(state.fileName, state.selectedFile);
            let res = await postImportParts(data);
            if (res.success) {
                setState((st) => ({ ...st, rows: res.data.res }));
            } else {
                showToast(res.message);
            }
        }
    };

    return (
        <Grid container spacing={2} className="screen mt_10">
            <TableContainer component={Paper} style={{ border: '1px solid rgba(0,0,0,0.2)' }} className="custom-scroll">
                <Table stickyHeader aria-label="sticky table" className={classes.paper}>
                    <TableBody style={{ height: '100%', overflow: 'auto' }}>
                        <StyledTableRow>
                            <StyledTableCell component="th">
                                <Typography color="secondary">
                                    <b>Upload Parts Data File</b>
                                </Typography>
                            </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell align="left">
                                <Typography>
                                    To import parts into your account, please{' '}
                                    <a href={downloadFile} download style={{ textDecoration: 'none' }}>
                                        download and complete the template spreadsheet
                                    </a>
                                    . Data MUST be in this format to be imported. Please note:
                                    <ul>
                                        <li>
                                            The first row in the spreadsheet contains column headings and will be ignored - all other rows will considered as
                                            data to be imported.
                                        </li>
                                        <li>
                                            There is no option to undo an import-any mistakes or incorrect records will need to be corrected/deleted manually
                                        </li>
                                    </ul>
                                </Typography>
                            </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell align="left">
                                <InputLabel shrink required>
                                    Data file
                                </InputLabel>
                                <input type="file" ID="fileSelect" accept=".xlsx, .xls, .csv" name="file" onChange={onFileSelect} />
                                <FormHelperText error>{state.errors.file}</FormHelperText>
                            </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell align="left">
                                <Typography>
                                    <b>Please check your spreadsheet has been completed correctly before continuing</b>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <SecondaryButton disabled={!state.selectedFile} onClick={handleSubmit} fullWidth className="submit_btn">
                                                Import Parts
                                            </SecondaryButton>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <SecondaryButton onClick={() => props.onClose(state.rows.length > 0)} fullWidth className="submit_btn">
                                                Cancel
                                            </SecondaryButton>
                                        </Grid>
                                    </Grid>
                                    To avoid errors, please press button ONCE and wait for confirmation. The import process may take several minutes to complete
                                </Typography>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid item xs={12} style={{ paddingBottom: '10px' }}>
                {state.rows && state.rows.length > 0 ? (
                    <DataGridComp
                        columns={column_new}
                        rows={state.rows}
                        headerHeight={40}
                        isRowHovered={true}
                        enableSearch={false}
                        disableColumnMenu={true}
                        offset={340}
                        showFooter={true}
                    />
                ) : null}
            </Grid>
        </Grid>
    );
};

export default ImportParts;
