import {get, post, put } from './http-calls';

export const addDefectActionedCategory = async(id, description) => {
    let res = await post(`/api/DefectActionedCategories`, {
        DefectActionedCategoryID: id,
        DefectActionedCategoryDescription: description,
        Mode: "A",
    }, { useAuthToken: true });
    return res;
}

export const updateDefectActionedCategory = async(id, description) => {
    let res = await post(`/api/DefectActionedCategories`, {
        DefectActionedCategoryID: id,
        DefectActionedCategoryDescription: description,
        Mode: "U",
    }, { useAuthToken: true });
    return res;
};

export const getDefectActionedCategories = async() => {
    let res = await get('/api/DefectActionedCategories', { useAuthToken: true });
    return res;
}