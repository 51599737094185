import { Grid, FormHelperText, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TabView from '../../../../Core/Controls/Tabs';
import { GetWIPHeaderByWIPNumber } from '../../../../Core/Service/reportService';
import formatters from '../../../../Core/Grid/inputFormatter';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';

const columns = [
    { field: 'lineNumber', headerName: 'Line', width: 98 },
    { field: 'partNumber', headerName: 'Part', width: 200 },
    { field: 'partDescription', headerName: 'Description', flex: 1 },
    { field: 'quantity', headerName: 'Qty', width: 100 },
    {
        field: 'unitPrice',
        headerName: 'Unit Price',
        width: 169,
        renderCell: function (value) {
            return formatters.CurrencyThousandSeparator(value.row.unitPrice);
        },
        align: 'right'
    },
    { field: 'discountPercent', headerName: 'Disc(%)', width: 169 },
    {
        field: 'netTotal',
        headerName: 'Net Total',
        width: 170,
        renderCell: function (value) {
            return formatters.CurrencyThousandSeparator(value.row.netTotal);
        },
        align: 'right'
    },
    { field: 'invoiceStatus', headerName: 'Invoice Status', width: 200 }
];

const LabourColumns = [
    { field: 'lineNumber', headerName: 'Line', width: 98 },
    { field: 'rtsCode', headerName: 'RTS Code', width: 200 },
    { field: 'labourDescription', headerName: 'Description', flex: 1 },
    { field: 'quantity', headerName: 'Qty', width: 100 },
    {
        field: 'ratePerHour',
        headerName: 'Unit Price',
        width: 169,
        renderCell: function (value) {
            return formatters.CurrencyThousandSeparator(value.row.ratePerHour);
        },
        align: 'right'
    },
    { field: 'discountPercent', headerName: 'Disc(%)', width: 169, align: 'right' },
    {
        field: 'netTotal',
        headerName: 'Net Total',
        width: 170,
        renderCell: function (value) {
            return formatters.CurrencyThousandSeparator(value.row.netTotal);
        },
        align: 'right'
    },
    { field: 'invoiceStatus', headerName: 'Invoice Status', width: 200 },
    { field: 'loadStatus', headerName: 'Load Status', width: 169 }
];
export default function GetWIPHeader(props) {
    const [state, setState] = useState({
        wipHeaderDetail: [],
        wipPartLine: [],
        wipLabourLine: []
    });

    useEffect(async () => {
        let res = await GetWIPHeaderByWIPNumber(props.wipNumber, props.company);
        let data = res.data;
        if (res.success) {
            setState((st) => ({
                ...st,
                wipHeaderDetail: data?.wipHeaderDetail[0],
                wipPartLine: data?.wipPartLine.map((w) => ({ ...w, id: w.lineNumber })),
                wipLabourLine: data?.wipLabourLine.map((w) => ({ ...w, id: w.lineNumber }))
            }));
        }
    }, [props.wipNumber]);

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No parts available</div>
            </GridOverlay>
        );
    }
    const tabs = [
        {
            label: 'Part Line',
            body: (
                <div
                    style={{
                        height: 'calc(100vh - 330px)',
                        width: '100%',
                        marginBottom: 8
                    }}
                >
                    <DataGrid
                        className="data-grid-container"
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        headerHeight={50}
                        rowHeight={50}
                        hideFooter={true}
                        columns={columns}
                        rows={state.wipPartLine || []}
                        disableColumnMenu={true}
                        showColumnRightBorder={true}
                        pageSize={10}
                    />
                </div>
            )
        },
        {
            label: 'Labour Line',
            body: (
                <div
                    style={{
                        height: 'calc(100vh - 330px)',
                        marginBottom: 8
                    }}
                >
                    <DataGrid
                        className="data-grid-container"
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        headerHeight={50}
                        rowHeight={50}
                        hideFooter={true}
                        columns={LabourColumns}
                        rows={state.wipLabourLine || []}
                        disableColumnMenu={true}
                        showColumnRightBorder={true}
                        pageSize={10}
                    />
                </div>
            )
        }
    ];

    return (
        <div className="screen">
            <Grid container spacing={1}>
                <Grid item xs={6} sm={4} lg={4}>
                    <FormHelperText shrink>WIP Number:</FormHelperText>
                    <Typography variant="subtitle2">{state.wipHeaderDetail?.wipNumber || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} lg={4}>
                    <FormHelperText shrink>Reg No:</FormHelperText>
                    <Typography variant="subtitle2">{state.wipHeaderDetail?.registration || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} lg={4}>
                    <FormHelperText shrink>Model:</FormHelperText>
                    <Typography variant="subtitle2">{state.wipHeaderDetail?.makeModel || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} lg={4}>
                    <FormHelperText shrink>Due Date:</FormHelperText>
                    <Typography variant="subtitle2">{formatters.MonthShortFormatter(state.wipHeaderDetail?.dateDueIn) || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} lg={4}>
                    <FormHelperText shrink>Status:</FormHelperText>
                    <Typography variant="subtitle2">{state.wipHeaderDetail?.bookingStatus || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} lg={4}>
                    <FormHelperText shrink>Customer:</FormHelperText>
                    <Typography variant="subtitle2">{state.wipHeaderDetail?.customerName || 'N/A'}</Typography>
                </Grid>
            </Grid>
            <TabView tabList={tabs} id="testing_tabs" />
        </div>
    );
}
