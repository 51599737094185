import React, { useContext, useEffect, useState } from 'react';
import { FormControlLabel, Grid } from '@material-ui/core';
import { SecondaryCheckbox } from '../../../Core/FormInput';

const WorkflowHeaderFlags = (props) => {
    const [state, setState] = useState({
        showJobHeader: { ...props.showJobHeader }
    });

    useEffect(() => {
        setState((st) => ({ ...st, showJobHeader: { ...props.showJobHeader } }));
    }, [props.showJobHeader]);

    const handleCheckboxChange = (e, fieldName) => {
        setState((prevState) => ({
            ...prevState,
            showJobHeader: {
                ...prevState.showJobHeader,
                [fieldName]: e.target.checked
            }
        }));
        props.handleCheckbox(e, fieldName);
    };

    const labelMapping = {
        showHeaderWIPNumber: 'WIP Number',
        showHeaderRegNumber: 'Reg Number',
        showHeaderCustomer: 'Customer',
        showHeaderKeyNumber: 'Key Number',
        showHeaderChassisNumber: 'Chassis Number',
        showHeaderOutstandingPayment: 'Outstanding Payment',
        showHeaderCreditLimit: 'Credit Limit',
        showHeaderWorkDetails: 'Work Details',
        showHeaderCashMessage: 'Cash Message'
    };

    return (
        <Grid container direction="column" style={{ height: '400px', width: '100%', overflow: 'auto' }}>
            {Object.entries(state.showJobHeader).map(([key, value]) => (
                <Grid item xs={6} md={3} key={key}>
                    <FormControlLabel
                        control={<SecondaryCheckbox checked={value} onChange={(e) => handleCheckboxChange(e, key)} name={key} />}
                        label={labelMapping[key] || key}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default WorkflowHeaderFlags;
