import { get, post } from './http-calls';
export const addWebForm = async (data) => {
    let res = await post(`WebForms`, data, { useAuthToken: true });
    return res;
};
export const DeleteWebForm = async (id) => {
    let res = await post(
        `WebForms/DeleteWebForms/${id}`,
        {
            Id: id
        },
        { useAuthToken: true }
    );
    return res;
};

export const getWebFormsById = async (id) => {
    let res = await get(`WebForms/${id}`, { useAuthToken: true });
    return res;
};
