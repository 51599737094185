import {
    Button,
    ButtonGroup,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    Paper,
    Radio,
    RadioGroup,
    Tooltip,
    Typography
} from '@material-ui/core';
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { CustomButton, SecondaryButton, SecondaryCheckbox, SelectBox, TextBox } from '../../../Core/FormInput';
import { useEffect } from 'react';
import {
    getDiagnosticSheetsDropDown,
    getDiagnosticSheetModelFalutList,
    getDiagnosticSheetHeaderDetails,
    getDiagnosticSheetDetails,
    saveDiagnosticSheets
} from '../../../Core/Service/diagnosticSheets';
import AppContext from '../../../App/AppContext';
import { useContext } from 'react';
import { some } from 'lodash';
import DiagnosticSheetsImageComponent from './imageSlider';
import { Backup } from '@material-ui/icons';
import getMultiImgResized from '../../../Core/CommonFunctions/multiImgResizer';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import SectionOne from './SectionsScreens/sectionOne';
import SectionTwo from './SectionsScreens/sectionTwo';
import SectionThree from './SectionsScreens/sectionThree';
import SectionFour from './SectionsScreens/sectionFour';
import GeneralSection from './SectionsScreens/generalSection';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    table: {
        minWidth: '100%'
    },

    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    },
    paper: {
        padding: '7px 16px 16px 16px',
        // color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)',
        width: '100%'
        // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
    },
    axelTitles: {
        textAlign: 'center',
        color: '#f50057',
        width: '100%'
    },
    headings: {
        color: '#f50057',
        margin: 0
        // marginLeft: -4,
        // marginBottom: 20
    }
}));

let config = [
    { arrName: 'enginefault', ids: 'enginefaultResultsIDs' },
    { arrName: 'noiseApparent', ids: 'noiseApparentResultsIDs' },
    { arrName: 'location', ids: 'locationResultsIDs' },
    { arrName: 'typeOfNoise', ids: 'typeOfNoiseResultsIDs' },
    { arrName: 'vehiclePosition', ids: 'vehiclePositionResultsIDs' },
    { arrName: 'occurs', ids: 'occurResultsIDs' },
    { arrName: 'water', ids: 'waterResultsIDs' },
    { arrName: 'malfunction', ids: 'malfunctionResultsIDs' },
    { arrName: 'engineCondition', ids: 'engineConditionResultsIDs' },
    { arrName: 'weatherRelated', ids: 'weatherResultsIDs' },
    { arrName: 'drivingCondition', ids: 'drivingConditionResultsIDs' },
    { arrName: 'roadCondition', ids: 'roadConditionResultsIDs' }
];

const DiagnosticSheets = (props) => {
    const classes = useStyles();
    const { hideModal, hideTopNavAndMenu, showToast, portalSettings } = useContext(AppContext);
    const [state, setState] = useState({
        modelDropDown: [],
        modelItemList: [],
        enginefault: [],
        noiseApparent: [],
        location: [],
        typeOfNoise: [],
        vehiclePosition: [],
        occurs: [],
        water: [],
        malfunction: [],
        engineCondition: [],
        weatherRelated: [],
        drivingCondition: [],
        roadCondition: [],
        gear: [],
        models: [],
        modelFaultIDs: [],
        generalFualts: [],
        diagnosticSheetNoiseVibrationImg: [],
        diagnosticSheetWaterLeakImg: [],
        headerData: {},
        diagnosticSheetModelID: '',
        diagnosticSheetElectronicsConcern: '',
        diagnosticSheetElectronicsSpecification: '',
        diagnosticSheetEngineTransmissionConcern: '',
        diagnosticSheetEngineTransmissionWarningLight: false,
        diagnosticSheetFaultCodes: '',
        diagnosticSheetGenerelIssue: '',
        diagnosticSheetHeaderRecordID: '',
        diagnosticSheetID: '',
        diagnosticSheetIsNoiseApparent: false,
        diagnosticSheetNoiseVibrationConcern: '',
        diagnosticSheetNoiseVibrationOther: '',
        diagnosticSheetNoiseVibrationPhoto: '',
        diagnosticSheetSpeedAllSpeed: false,
        diagnosticSheetSpeedGear: '',
        diagnosticSheetSpeedGearType: '',
        diagnosticSheetSpeedMPH: '',
        diagnosticSheetSpeedRPM: '',
        diagnosticSheetWarningLightDescription: '',
        diagnosticSheetWaterLeaksBodyRepairsDescription: '',
        diagnosticSheetWaterLeaksConcern: '',
        diagnosticSheetWaterLeaksPhoto: '',
        diagnosticSheetWaterLeaksehadAnyBodyRepairs: false,
        drivingConditionResultsIDs: '',
        diagnosticSheetGeneralFaultId: ''
    });
    const [btnLoader, setBtnLoader] = useState(false);

    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const headerId = props?.match?.params?.jobId;
    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    useEffect(async () => {
        let headerRes = await getDiagnosticSheetHeaderDetails(headerId);
        let dropRes = await getDiagnosticSheetsDropDown();
        setState((st) => ({
            ...st,
            enginefault: dropRes.data.enginefault?.map((p) => {
                return { ...p, checked: false };
            }),
            noiseApparent: dropRes.data.noiseApparent?.map((p) => {
                return { ...p, checked: false };
            }),
            location: dropRes.data.location?.map((p) => {
                return { ...p, checked: false };
            }),
            typeOfNoise: dropRes.data.typeOfNoise?.map((p) => {
                return { ...p, checked: false };
            }),
            vehiclePosition: dropRes.data.vehiclePosition?.map((p) => {
                return { ...p, checked: false };
            }),
            occurs: dropRes.data.occurs?.map((p) => {
                return { ...p, checked: false };
            }),
            water: dropRes.data.water?.map((p) => {
                return { ...p, checked: false };
            }),
            malfunction: dropRes.data.malfunction?.map((p) => {
                return { ...p, checked: false };
            }),
            engineCondition: dropRes.data.engineCondition.map((p) => {
                return { ...p, checked: false };
            }),
            weatherRelated: dropRes.data.weatherRelated?.map((p) => {
                return { ...p, checked: false };
            }),
            drivingCondition: dropRes.data.drivingCondition?.map((p) => {
                return { ...p, checked: false };
            }),
            roadCondition: dropRes.data.roadCondition?.map((p) => {
                return { ...p, checked: false };
            }),
            generalFualts: dropRes.data.generalFualts?.map((p) => {
                return { ...p, checked: false };
            }),
            gear: dropRes.data.gear,
            models: dropRes.data.models,
            headerData: headerRes?.data?.headerDetails[0] || {}
        }));
    }, []);

    useEffect(async () => {
        if (state.diagnosticSheetModelID) {
            let res = await getDiagnosticSheetModelFalutList(state.diagnosticSheetModelID);
            if (state.modelFaultIDs?.length > 0) {
                setState((st) => {
                    const nst = { ...st };
                    nst.modelItemList = res.data.models?.map((p) => {
                        nst.modelFaultIDs.forEach((o) => {
                            if (p.id == o) {
                                p.checked = true;
                            }
                        });
                        return p;
                    });
                    return nst;
                });
            } else {
                setState((st) => ({ ...st, modelItemList: res.data.models }));
            }
        } else {
            setState((st) => ({ ...st, modelItemList: [] }));
        }
    }, [state.diagnosticSheetModelID]);

    useEffect(async () => {
        if (state.headerData.diagnosticSheetID) {
            let res = await getDiagnosticSheetDetails(state.headerData.diagnosticSheetID);
            setState((st) => {
                const nst = { ...st };
                nst.diagnosticSheetHeaderRecordID = res.data.details[0].diagnosticSheetHeaderRecordID;
                nst.diagnosticSheetFaultCodes = res.data.details[0].diagnosticSheetFaultCodes;
                nst.diagnosticSheetEngineTransmissionConcern = res.data.details[0].diagnosticSheetEngineTransmissionConcern;
                nst.diagnosticSheetWarningLightDescription = res.data.details[0].diagnosticSheetWarningLightDescription;
                nst.diagnosticSheetEngineTransmissionWarningLight = res.data.details[0].diagnosticSheetEngineTransmissionWarningLight;
                nst.diagnosticSheetNoiseVibrationConcern = res.data.details[0].diagnosticSheetNoiseVibrationConcern;
                nst.diagnosticSheetNoiseVibrationOther = res.data.details[0].diagnosticSheetNoiseVibrationOther;
                nst.diagnosticSheetIsNoiseApparent = res.data.details[0].diagnosticSheetIsNoiseApparent;
                nst.diagnosticSheetWaterLeaksConcern = res.data.details[0].diagnosticSheetWaterLeaksConcern;
                nst.diagnosticSheetWaterLeaksehadAnyBodyRepairs = res.data.details[0].diagnosticSheetWaterLeaksehadAnyBodyRepairs;
                nst.diagnosticSheetWaterLeaksBodyRepairsDescription = res.data.details[0].diagnosticSheetWaterLeaksBodyRepairsDescription;
                nst.diagnosticSheetElectronicsConcern = res.data.details[0].diagnosticSheetElectronicsConcern;
                nst.diagnosticSheetElectronicsSpecification = res.data.details[0].diagnosticSheetElectronicsSpecification;
                nst.diagnosticSheetGenerelIssue = res.data.details[0].diagnosticSheetGenerelIssue;
                nst.diagnosticSheetNoiseVibrationPhoto = res.data.details[0].diagnosticSheetNoiseVibrationPhoto;
                nst.diagnosticSheetWaterLeaksPhoto = res.data.details[0].diagnosticSheetWaterLeaksPhoto;
                nst.diagnosticSheetModelID = res.data.details[0].diagnosticSheetModelID;
                nst.reportedTakenBy = res.data.details[0].reportedTakenBy;
                nst.diagnosticSheetSpeedMPH = res.data.details[0].diagnosticSheetSpeedMPH;
                nst.diagnosticSheetSpeedRPM = res.data.details[0].diagnosticSheetSpeedRPM;
                nst.diagnosticSheetSpeedGear = res.data.details[0].diagnosticSheetSpeedGear;
                nst.diagnosticSheetSpeedAllSpeed = res.data.details[0].diagnosticSheetSpeedAllSpeed;
                nst.diagnosticSheetSpeedGearType = res.data.details[0].diagnosticSheetSpeedGearType;
                nst.diagnosticSheetGeneralFaultId = res.data.details[0].diagnosticSheetGeneralFaultId;
                nst.modelFaultIDs = res.data.modelFaultIDs || [];
                nst.diagnosticSheetNoiseVibrationImg = res.data.diagnosticSheetNoiseVibrationImg || [];
                nst.diagnosticSheetWaterLeakImg = res.data.diagnosticSheetWaterLeakImg || [];
                config.forEach((o) => {
                    nst[o.arrName].forEach((p) => {
                        res.data[o.ids].forEach((idv) => {
                            if (p.id == idv) {
                                p.checked = true;
                            }
                        });
                    });
                });
                return nst;
            });
        }
    }, [state.headerData.diagnosticSheetID]);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleCheckbox = (arrName, id) => (event) => {
        const { name, checked } = event.target;

        setState((st) => {
            const nst = { ...st };
            if (arrName) {
                let index = nst[`${arrName}`].findIndex((p) => p.id == id);
                if (index > -1) {
                    nst[arrName][index] = { ...nst[arrName][index], checked };
                }
            } else {
                nst[name] = checked;
            }
            return nst;
        });
    };

    const handleYesNo = (name, val) => {
        setState((st) => ({ ...st, [name]: val }));
    };
    const handleRemoveClick = (index, arrName) => {
        const list = [...state[`${arrName}`]];
        list.splice(index, 1);
        setState((st) => ({ ...st, [`${arrName}`]: list }));
    };

    const upload = async (e, arrName) => {
        getMultiImgResized(e.target.files, (fileUrl) => {
            let data = fileUrl?.map((m, i) => {
                return { diagnosticSheetImgID: null, diagnosticSheetImgImage: m };
            });

            setState((st) => ({ ...st, [`${arrName}`]: [...state[`${arrName}`], ...data] }));
        });
    };

    const handelSubmit = async () => {
        setBtnLoader(true);
        let data = {
            diagnosticSheetID: state.headerData?.diagnosticSheetID,
            diagnosticSheetHeaderRecordID: headerId,
            diagnosticSheetFaultCodes: state.diagnosticSheetFaultCodes,
            diagnosticSheetEngineTransmissionConcern: state.diagnosticSheetEngineTransmissionConcern,
            diagnosticSheetWarningLightDescription: state.diagnosticSheetWarningLightDescription,
            diagnosticSheetEngineTransmissionWarningLight: state.diagnosticSheetEngineTransmissionWarningLight,
            diagnosticSheetNoiseVibrationConcern: state.diagnosticSheetNoiseVibrationConcern,
            diagnosticSheetNoiseVibrationOther: state.diagnosticSheetNoiseVibrationOther,
            diagnosticSheetIsNoiseApparent: state.diagnosticSheetIsNoiseApparent,
            diagnosticSheetWaterLeaksConcern: state.diagnosticSheetWaterLeaksConcern,
            diagnosticSheetWaterLeaksehadAnyBodyRepairs: state.diagnosticSheetWaterLeaksehadAnyBodyRepairs,
            diagnosticSheetWaterLeaksBodyRepairsDescription: state.diagnosticSheetWaterLeaksBodyRepairsDescription,
            diagnosticSheetGenerelIssue: state.diagnosticSheetGenerelIssue,
            diagnosticSheetElectronicsConcern: state.diagnosticSheetElectronicsConcern,
            diagnosticSheetElectronicsSpecification: state.diagnosticSheetElectronicsSpecification,
            diagnosticSheetNoiseVibrationPhoto: state.diagnosticSheetNoiseVibrationPhoto,
            diagnosticSheetWaterLeaksPhoto: state.diagnosticSheetWaterLeaksPhoto,
            diagnosticSheetModelID: state.diagnosticSheetModelID,
            diagnosticSheetSpeedAllSpeed: state.diagnosticSheetSpeedAllSpeed,
            diagnosticSheetSpeedMPH: state.diagnosticSheetSpeedMPH,
            diagnosticSheetSpeedRPM: state.diagnosticSheetSpeedRPM,
            diagnosticSheetSpeedGear: state.diagnosticSheetSpeedGear,
            diagnosticSheetSpeedGearType: state.diagnosticSheetSpeedGearType,
            diagnosticSheetGeneralFaultId: state.diagnosticSheetGeneralFaultId,

            diagnosticSheetNoiseVibrationImg: state.diagnosticSheetNoiseVibrationImg,
            diagnosticSheetWaterLeakImg: state.diagnosticSheetWaterLeakImg,

            enginefaultResultsIDs: state.enginefault.filter((m) => m.checked)?.map((p) => p.id),
            noiseApparentResultsIDs: state.noiseApparent.filter((m) => m.checked)?.map((p) => p.id),
            locationResultsIDs: state.location.filter((m) => m.checked)?.map((p) => p.id),
            typeOfNoiseResultsIDs: state.typeOfNoise.filter((m) => m.checked)?.map((p) => p.id),
            vehiclePositionResultsIDs: state.vehiclePosition.filter((m) => m.checked)?.map((p) => p.id),
            occurResultsIDs: state.occurs.filter((m) => m.checked)?.map((p) => p.id),
            waterResultsIDs: state.water.filter((m) => m.checked)?.map((p) => p.id),
            malfunctionResultsIDs: state.malfunction.filter((m) => m.checked)?.map((p) => p.id),
            engineConditionResultsIDs: state.engineCondition.filter((m) => m.checked)?.map((p) => p.id),
            weatherResultsIDs: state.weatherRelated.filter((m) => m.checked)?.map((p) => p.id),
            drivingConditionResultsIDs: state.drivingCondition.filter((m) => m.checked)?.map((p) => p.id),
            roadConditionResultsIDs: state.roadCondition.filter((m) => m.checked)?.map((p) => p.id),
            modelFaultIDs: state.modelItemList.filter((m) => m.checked)?.map((p) => p.id)
        };
        let res = await saveDiagnosticSheets(data);
        if (!res.success) {
            console.error(res);
        } else {
            hideModal();
        }
        showToast(res.message);
        setBtnLoader(false);
        if (res.success && fromStep) {
            window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
        }
    };

    return (
        <div style={{ padding: '10px', textAlign: 'left' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item container xs={12}>
                            {/* <div className={classes.paper}> */}
                            <Grid container xs={12}>
                                <h3 className={classes.headings}>Customer Details</h3>
                            </Grid>
                            {/* <hr></hr> */}
                            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                                <Grid item xs={12} sm={4}>
                                    <FormLabel component="legend">Cutomer Name</FormLabel>
                                    <br></br>
                                    <Typography variant="button" style={{ fontWeight: 'bold' }}>
                                        {state.headerData.customerName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormLabel component="legend">Wip number</FormLabel>
                                    <br></br>
                                    <Typography variant="button" style={{ fontWeight: 'bold' }}>
                                        {state.headerData.wipNumber}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormLabel component="legend">Registration number</FormLabel>
                                    <br></br>
                                    <Typography variant="button" style={{ fontWeight: 'bold' }}>
                                        {state.headerData.registration}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormLabel component="legend">Repeat repair</FormLabel>
                                    <br></br>
                                    <Typography variant="button" style={{ fontWeight: 'bold' }}>
                                        {state.headerData.repeatRepair == 'N' ? 'No' : 'Yes'}
                                    </Typography>
                                </Grid>
                                {state.reportedTakenBy && (
                                    <Grid item xs={12} sm={4}>
                                        <FormLabel component="legend">Report taken by</FormLabel>
                                        <br></br>
                                        <Typography variant="button" style={{ fontWeight: 'bold' }}>
                                            {state.reportedTakenBy}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            {/* </div> */}
                        </Grid>
                        <Grid item container xs={12}>
                            <GeneralSection state={state} handleFieldChange={handleFieldChange} handleCheckbox={handleCheckbox} handleYesNo={handleYesNo} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item container xs={12}>
                            <SectionOne state={state} handleFieldChange={handleFieldChange} handleCheckbox={handleCheckbox} handleYesNo={handleYesNo} />
                        </Grid>
                        <Grid item container xs={12}>
                            <SectionTwo
                                state={state}
                                handleFieldChange={handleFieldChange}
                                handleCheckbox={handleCheckbox}
                                handleYesNo={handleYesNo}
                                upload={upload}
                                handleRemoveClick={handleRemoveClick}
                            />
                        </Grid>
                        <Grid></Grid>
                        <Grid item container xs={12}>
                            <SectionThree
                                state={state}
                                handleFieldChange={handleFieldChange}
                                handleCheckbox={handleCheckbox}
                                handleYesNo={handleYesNo}
                                upload={upload}
                                handleRemoveClick={handleRemoveClick}
                            />
                        </Grid>
                        <Grid item container xs={12}>
                            <SectionFour state={state} handleFieldChange={handleFieldChange} handleCheckbox={handleCheckbox} handleYesNo={handleYesNo} />
                        </Grid>
                    </Grid>
                </Grid>
                <SecondaryButton isBtnLoader={btnLoader} className="Submitbtn" onClick={handelSubmit}>
                    {btnLoader ? 'Saving' : 'Submit'}
                </SecondaryButton>
            </Grid>
        </div>
    );
};

export default DiagnosticSheets;
