import { Card, Grid, Typography, Box } from '@material-ui/core';
import React, { useEffect, useState, useLayoutEffect } from 'react';
import TreeImg from '../../Core/Header/tree.png';
import { useWindowSize } from '../../Core/Controls/ScreenResolution';

const TreesGrid = (props) => {
    const [state, setState] = useState({
        val: null,
        styleObj: { float: 'left', padding: 10 }
    });

    const Card = () => {
        return (
            <Grid item>
                <img src={TreeImg} style={state.styleObj} />
            </Grid>
        );
    };
    const [widths, heights] = useWindowSize();
    let resolution = 947 - heights;
    let screen = 750 - resolution;

    useEffect(() => {
        setState((st) => ({
            ...st,
            val: props.match?.params.count || null
        }));

        setState((st) => {
            let newSt = { ...st };
            let obj = { float: 'right', padding: 5 };
            let count = props.match?.params.count;
            if (count <= 30) {
                obj.width = 180;
                obj.height = 230;
            }
            if (count > 30 && count <= 100) {
                obj.width = 100;
                obj.height = 120;
            }
            if (count >= 100 && count <= 200) {
                obj.width = 77;
                obj.height = 84;
            }
            if (count >= 200 && count <= 300) {
                obj.width = 58;
                obj.height = 60;
            }
            if (count >= 300 && count <= 475) {
                obj.width = 45;
                obj.height = 50;
            }
            if (count >= 475 && count <= 880) {
                obj.width = 35;
                obj.height = 30;
            }
            if (count > 880) {
                obj.width = 20;
                obj.height = 30;
            }
            newSt.styleObj = obj;
            return newSt;
        });
    }, [props.match?.params.count]);

    var arr = [];
    var elements = [];
    for (var i = 0; i < state.val; i++) {
        elements.push(<Card value={arr[i]} key={i} />);
    }

    const count = state.val <= 1 ? 'tree' : 'trees';

    return (
        <div>
            {elements.length !== 0 ? (
                <>
                    <Grid
                        container
                        direction="row"
                        style={{
                            height: screen,
                            display: 'flex',
                            alignItems: 'baseline',
                            justifyContent: 'space-evenly',
                            alignContent: 'flex-end'
                        }}
                    >
                        {elements}
                    </Grid>

                    <Typography variant="h5" style={{ color: 'green', width: '100%' }}>
                        <br />
                        You have saved {state.val} {count}
                    </Typography>
                </>
            ) : (
                <div>
                    <img src={TreeImg} style={{ marginTop: 100, height: '530px' }} />
                    <Typography variant="h5" component="h6" style={{ marginTop: 20, color: 'green' }}>
                        You are on your way to help environment by reducing printed papers
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default TreesGrid;
