import React, { useContext, useState, useRef, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';
import { CustomButton } from '../FormInput';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DeleteIcon from '@material-ui/icons/Delete';
import { AppStorage } from '../Service/storage-service';
import AppContext from '../../App/AppContext';
import './signature.scss';
import { InputLabel } from '@material-ui/core';
export const CustomeSignPad = (props) => {
    const { showToast } = useContext(AppContext);

    const signCanvas = useRef({});
    const [state, setState] = useState({ isSignExist: false });

    useEffect(() => {
        signCanvas.current.clear();
        signCanvas.current.on();
        setState((st) => ({ ...st, isSignExist: false }));
        if (props.sign || props.disabled) {
            signCanvas.current.fromDataURL(props.sign);
            // props.sign &&
            signCanvas.current.off();
            setState((st) => ({ ...st, isSignExist: true }));
        }
    }, [props.sign]);

    const useDefaultSig = () => {
        let userSign = AppStorage.getSignature();
        if (userSign) {
            signCanvas.current.fromDataURL(AppStorage.getSignature());
            signCanvas.current.off();
            setState((st) => ({ ...st, teamleadersign: AppStorage.getSignature() }));
            props.onChange && props.onChange({ [props.name]: AppStorage.getSignature() });
        } else {
            showToast('User signature not found');
        }
    };

    const clearLeaderSign = () => {
        signCanvas.current.clear();
        signCanvas.current.on();
        props.clearSig();
    };

    const GetTeamleaderSign = () => {
        setState((st) => {
            const nst = {
                ...st,
                teamleadersign: signCanvas.current.toDataURL()
            };
            return nst;
        });
        props.onChange && props.onChange({ [props.name]: signCanvas.current.getTrimmedCanvas().toDataURL() });
    };

    return (
        <Grid item xs={12} className="customeSign" style={{ ...props.style }}>
            <FormLabel
                component="legend"
                className={props.isRequired ? 'mandatoryfields' : false}
                style={{ marginTop: props.hideBtn || state.isSignExist ? '6px' : '7px' }}
                required={props.isRequired ? true : false}
            >
                {props.content}
            </FormLabel>
            <FormLabel shrink>
                &nbsp;
                {!state.isSignExist && !props.hideBtn && <CustomButton icon={BorderColorIcon} onClick={useDefaultSig} toolTipTitle="Use default signature" />}
                {!state.isSignExist && !props.hideBtn && (
                    <CustomButton icon={DeleteIcon} onClick={clearLeaderSign} IconColor="gray" toolTipTitle="Remove signature" />
                )}
            </FormLabel>

            <SignaturePad
                // style={{marginTop:`${state.isSignExist?'14px':'0px'}`}}
                ref={signCanvas}
                onEnd={() => {
                    GetTeamleaderSign();
                }}
                canvasProps={{
                    className: `${state.isSignExist || props.hideBtn ? 'existSignature' : 'signatureClass'}`
                }}
            />
        </Grid>
    );
};
