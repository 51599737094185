import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import './Controls.scss';

const AppButtonGroup = (props) => {
    const ConvertOps = (ops) => {
        return ops.map((op) => {
            return {
                value: `${op[props.valuePropertyName || 'value']}`,
                text: `${op[props.textPropertyName || 'text']}`
            };
        });
    };

    const [state, setState] = useState({
        value: `${props.value}`,
        options: ConvertOps(props.options) || []
    });

    useEffect(() => {
        setState((st) => ({ ...st, value: props.value }));
    }, [props.value]);

    useEffect(() => {
        props.options && setState((st) => ({ ...st, options: ConvertOps(props.options) }));
    }, [props.options]);

    const clickBtnGrup = (e, val) => {
        if (!props.disabled) {
            setState((st) => ({ ...st, value: val }));
            props.onChange && props.onChange({ [props.name]: val });
        }
    };

    return (
        <span style={{ display: 'flex' }} className="app-btn-group">
            <ToggleButtonGroup disabled={props.disabled} className="app-select-btn-grp" size="small" value={state.value} onChange={clickBtnGrup} exclusive>
                {state.options.map((op, i) => (
                    <ToggleButton key={i} value={op.value}>
                        {op.text}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </span>
    );
};

export default AppButtonGroup;
