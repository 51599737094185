import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '500px',
        '& > * + *': {
            marginBottom: '8px'
        }
    }
}));

export default function WarningMsg(props) {
    const classes = useStyles();
    const handleClose = () => {
        props.onClose && props.onClose();
    };
    return (
        <Dialog open={true} onClose={handleClose} maxWidth="md" className="dialog-custom">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitle>Warning</DialogTitle>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent>
                <div className={classes.root}>
                    <Alert severity="error" className="Submitbtn">
                        Customer is currently on stop!
                    </Alert>
                </div>
            </DialogContent>
        </Dialog>
    );
}
