import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
}));

export const CustomTab = (props) => {
    const { TabScreen1, TabScreen2, TabScreen3 } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <TabContext value={value}>
                <AppBar position="static" color="transparent">
                    <TabList onChange={handleChange}>
                        <Tab label="New Vehicle" value="1" />
                        <Tab label="Used Vehicle" value="2" />
                        <Tab label="Sales Contingency Debt" value="3" />
                    </TabList>
                </AppBar>

                <TabPanel value="1">
                    <TabScreen1 />
                </TabPanel>
                <TabPanel value="2">
                    {' '}
                    <TabScreen2 />
                </TabPanel>
                <TabPanel value="3">
                    {' '}
                    <TabScreen3 />
                </TabPanel>
            </TabContext>
        </div>
    );
};
