import React, { useEffect, useState } from 'react';
import { GetInternalReturnReasonData } from '../../../../Core/Service/dashbordService';
import { DatePicker, MultipleSelectWithMoreOp } from '../../../../Core/FormInput';
import { Card, CircularProgress, FormLabel, Grid, InputLabel, makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import moment from 'moment';
import { TopTenTable } from './ReturnReasonTable';
import ReaturnReasonTabs from './returnReasonTab';
import { GetBranchDeportmentScreenDropdowns } from '../../../../Core/Service/reportService';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';

const useStyles = makeStyles((theme) => ({}));

const crumbs = () => {
    let list = [
        { name: 'Home', path: '/' },
        { name: 'Reports', active: true },
        { name: 'Return Reason Dashboard', active: true }
    ];
    return list;
};

const ReturnReasonDashboard = () => {
    let startDate = moment().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const classes = useStyles();

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        top10ReturnReasons: [],
        top10Users: [],
        top10Workflows: [],
        workflowReturnReasons: [],
        branchReturnReasons: [],
        allReturnReasons: [],
        branchList: [],
        groupIds: [],
        showLoader: true,
        ShowMessage: false
    });

    const [temp, tempOption] = useState({
        groupVal: []
    });

    const GetWorkflowsAvgTimes = async () => {
        if ((state.startDate, state.endDate)) {
            let res = await GetInternalReturnReasonData(state.startDate, state.endDate, state.groupIds);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    top10ReturnReasons: res?.data?.top10ReturnReasons?.map((n) => ({
                        name: n.column1,
                        value: n.total,
                        ...n
                    })),
                    top10Users: res?.data?.top10Users?.map((n) => ({
                        name: n.displayName,
                        value: n.total,
                        ...n
                    })),
                    top10Workflows: res?.data?.top10Workflows?.map((n) => ({
                        name: n.workflow,
                        value: n.total,
                        ...n
                    })),
                    workflowReturnReasons: res?.data?.workflowReturnReasons,
                    branchReturnReasons: res?.data?.branchReturnReasons,
                    allReturnReasons: res?.data?.allReturnReasons,
                    showLoader: false
                }));
            } else {
                setState((st) => ({
                    ...st,
                    ShowMessage: true
                }));
            }
        }
    };

    useEffect(async () => {
        let res = await GetBranchDeportmentScreenDropdowns();
        if (res.success) {
            setState((st) => ({
                ...st,
                branchList: res.data?.branchList || []
            }));
        }
    }, []);

    useEffect(() => {
        setState((st) => ({
            ...st,
            showLoader: true
        }));
        GetWorkflowsAvgTimes();
    }, [state.startDate, state.endDate, state.groupIds]);

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const handleChanges = (event, value) => {
        tempOption((st) => ({ ...st, groupVal: value }));

        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };

    return (
        <>
            {!state.showLoader ? (
                <div className="report-screen-container">
                    <BreadCrumbs crumbs={crumbs()} />
                    <div style={{ padding: '10px 0px', background: '#f5f5f5' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={4} md={3} lg={2}>
                                <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                    Start Date
                                </FormLabel>
                                <DatePicker placeholder="Start Date" name="startDate" fullWidth value={state.startDate} onChange={handleinput} />
                            </Grid>
                            <Grid item xs={4} md={3} lg={2}>
                                <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                    End Date
                                </FormLabel>
                                <DatePicker placeholder="End Date" name="endDate" fullWidth value={state.endDate} onChange={handleinput} />
                            </Grid>
                            <Grid item xs={12} md={4} lg={30}>
                                <Grid item xs={12}>
                                    <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 2 }}>
                                        Branch
                                    </FormLabel>
                                    <MultipleSelectWithMoreOp options={state.branchList || []} onChange={handleChanges} value={temp.groupVal} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <TopTenTable
                                    rows={state.top10ReturnReasons}
                                    col={['Return Reasons', 'Count']}
                                    label="Return Reason"
                                    start={state.startDate}
                                    end={state.endDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <TopTenTable rows={state.top10Users} col={['Users', 'Count']} label="Users" start={state.startDate} end={state.endDate} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <TopTenTable
                                    rows={state.top10Workflows}
                                    col={['WorkFlows', 'Count']}
                                    label="WorkFlows"
                                    start={state.startDate}
                                    end={state.endDate}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ReaturnReasonTabs
                                    workflowReturnReasons={state.workflowReturnReasons}
                                    branchReturnReasons={state.branchReturnReasons}
                                    allReturnReasons={state.allReturnReasons}
                                    start={state.startDate}
                                    end={state.endDate}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ) : (
                <Grid
                    container
                    style={{
                        // padding: '10px 0px',
                        height: 'calc(100vh - 105px)',
                        overflow: 'auto'
                    }}
                    justifyContent="center"
                    className="salesBudgetScreen"
                    alignItems="center"
                >
                    {state.ShowMessage ? 'No Data Found ' : <CircularProgress color="secondary" />}
                </Grid>
            )}
        </>
    );
};
export default ReturnReasonDashboard;
