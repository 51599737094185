import { CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import formatters from '../../../../Core/Grid/inputFormatter';
import { getReportingInternalHighLevelDashboard } from '../../../../Core/Service/ReportScreens-service';
import './miniStyle.scss';
import { useHistory } from 'react-router-dom';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { VehicleCard, VehicleCardNew } from './ministyleComp';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';
import InfoIcon from '@material-ui/icons/Info';
import { MiniViewGraphChart } from './miniViewGraph';

const StyledTableCell = withStyles((theme) => ({
    head: {
        padding: '0px 10px 0px 0px',
        border: 'none',
        textAlign: 'center'
    },
    body: {
        border: 'none',
        padding: '10px 0px',
        textAlign: 'center'
    }
}))(TableCell);

const MiniViewReportingDashboard = (props) => {
    const [state, setState] = useState({ data: {}, parts: [], isLoader: true, sales: {} });
    const [WindowWidths, WindowHeights] = useWindowSize();
    const history = useHistory();
    const handleRedirect = () => {
        let link = '/RepotingTab';
        history.push({
            pathname: link
        });
    };
    const handleRedirectCal = () => {
        let link = '/Services/InvoicedhoursCalender';
        history.push({
            pathname: link
        });
    };
    const handleRedirectPart = () => {
        let link = '/Services/Parts_Calender';
        history.push({
            pathname: link
        });
    };

    useEffect(async () => {
        let res = await getReportingInternalHighLevelDashboard();
        setState((st) => ({
            ...st,
            data: res.data.data[0],
            sales: res.data.sales[0],
            parts: res.data.parts || [],
            isLoader: false
        }));
    }, []);

    const redirectTOVehicleDetails = (label, type) => {
        let searchQuery = `?&label=${label}&type=${type}`;
        let link = '/sales_newRegistration';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };
    const redirectTOVehicleOrderIntek = (label, type) => {
        let searchQuery = `?&label=${label}&type=${type}`;
        let link = '/sales_orderIntek';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };
    const redirectTOVehicleUsedInvoice = (label, type) => {
        let searchQuery = `?&label=${label}&type=${type}`;
        let link = '/sales_usedInvoice';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };
    const redirectTOVehiclEVTarget = (label, type) => {
        let searchQuery = `?&label=${label}`;
        let link = '/sales_EvTarget';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };

    return (
        <div style={{ padding: 10 }}>
            <Grid
                container
                // style={{
                //     height: 'calc(100vh - 105px)',
                //     overflow: 'auto'
                // }}
                justifyContent="center"
                className="salesBudgetScreen"
                alignItems="center"
            >
                {state.isLoader ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <Grid
                        item
                        xs={11}
                        sm={11}
                        md={11}
                        lg={WindowWidths < 1660 ? 11 : 'auto'}
                        xl={11}
                        // style={{ padding: WindowWidths > 1910 ? 0 : WindowWidths < 1280 ? 0 : 10 }}
                    >
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={12}>
                                <Typography className="MainHeading">After Sales (MTD)</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={0}
                                    justifyContent={WindowWidths >= 1280 ? 'space-between' : 'flext-start'}
                                    // style={{ padding: '15px 0px 15px 0px' }}
                                    className={WindowWidths > 1280 ? 'detailDashmainCard1' : ''}
                                >
                                    {/* <Grid item xs={12} className="detailDashmainCardGraphsubMenu">
                                        <MiniViewGraphChart list={data} label={'SOLD HOURS - MTD'} />
                                    </Grid>
                                    <Grid item xs={12} className="detailDashmainCardGraphsubMenu">
                                        <MiniViewGraphChart list={data2} label={'SOLD £ - MTD'} />
                                    </Grid> */}
                                    <Grid item xs={12} sm={6} md={6} lg={2} xl={2} className="detailDashmainCardsubMenu" onClick={() => handleRedirect()}>
                                        <Grid container justifyContent="space-around" alignItems="center" spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography className="mainCardTextPrimary">SOLD (TODAY)</Typography>
                                            </Grid>
                                            <Grid item>
                                                <div className="vehicleRing" style={{ textAlign: 'center', marginBottom: 10 }}>
                                                    <CircularProgressbar
                                                        value={state.data.todaySoldHoursProgress}
                                                        text={`${state.data.todaySoldHoursProgress || 0}%`}
                                                        styles={buildStyles({
                                                            textColor:
                                                                state.data.todaySoldHoursProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.todaySoldHoursProgress >= 50 &&
                                                                      state.data.todaySoldHoursProgress < state.data.todaySoldHoursProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.todaySoldHoursProgress >= state.data.todaySoldHoursProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            pathColor:
                                                                state.data.todaySoldHoursProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.todaySoldHoursProgress >= 50 &&
                                                                      state.data.todaySoldHoursProgress < state.data.todaySoldHoursProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.todaySoldHoursProgress >= state.data.todaySoldHoursProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            trailColor: '#d6d6d6',
                                                            textSize: '27px'
                                                        })}
                                                    />
                                                </div>
                                                <div>
                                                    <Typography className="salesCurrencyFont">
                                                        <span style={{ color: state.data?.todaySoldHoursColour ? state.data?.todaySoldHoursColour : 'black' }}>
                                                            {state.data.todaySoldHours
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.todaySoldHours)
                                                                : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                    <span className="salesCardTextPrimary">Hours</span>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className="vehicleRing" style={{ textAlign: 'justify', marginBottom: 10 }}>
                                                    <CircularProgressbar
                                                        value={state.data.todaySoldAmountProgress}
                                                        text={`${state.data.todaySoldAmountProgress || 0}%`}
                                                        styles={buildStyles({
                                                            textColor:
                                                                state.data.todaySoldAmountProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.todaySoldAmountProgress >= 50 &&
                                                                      state.data.todaySoldAmountProgress < state.data.todaySoldAmountProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.todaySoldAmountProgress >= state.data.todaySoldAmountProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            pathColor:
                                                                state.data.todaySoldAmountProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.todaySoldAmountProgress >= 50 &&
                                                                      state.data.todaySoldAmountProgress < state.data.todaySoldAmountProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.todaySoldAmountProgress >= state.data.todaySoldAmountProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            trailColor: '#d6d6d6',
                                                            textSize: '27px'
                                                        })}
                                                    />
                                                </div>
                                                <div>
                                                    <Typography className="salesCurrencyFont">
                                                        <span
                                                            style={{ color: state.data?.todaySoldAmountColour ? state.data?.todaySoldAmountColour : 'black' }}
                                                        >
                                                            {state.data.todaySoldAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.todaySoldAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                    <span className="salesCardTextPrimary">Value</span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={2}
                                        xl={2}
                                        className="detailDashmainCardsubMenu"
                                        // onClick={() => handleRedirect()}
                                    >
                                        <Grid container justifyContent="space-around" alignItems="center" spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    className="mainCardTextPrimary"
                                                    style={{ display: 'block', alignContent: 'center', justifyContent: 'center' }}
                                                >
                                                    <span> SOLD (MTD)</span>
                                                    <InfoIcon
                                                        color="secondary"
                                                        style={{ cursor: 'pointer', float: 'right' }}
                                                        onClick={() => handleRedirectCal()}
                                                    />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <div className="vehicleRing" style={{ textAlign: 'center', marginBottom: 10 }}>
                                                    <CircularProgressbar
                                                        value={state.data.soldHoursProgress}
                                                        text={`${state.data.soldHoursProgress || 0}%`}
                                                        styles={buildStyles({
                                                            textColor:
                                                                state.data.soldHoursProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.soldHoursProgress >= 50 &&
                                                                      state.data.soldHoursProgress < state.data.soldHoursProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.soldHoursProgress >= state.data.soldHoursProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            pathColor:
                                                                state.data.soldHoursProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.soldHoursProgress >= 50 &&
                                                                      state.data.soldHoursProgress < state.data.soldHoursProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.soldHoursProgress >= state.data.soldHoursProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            trailColor: '#d6d6d6',
                                                            textSize: '27px'
                                                        })}
                                                    />
                                                </div>
                                                <div>
                                                    <Typography className="salesCurrencyFont">
                                                        <span style={{ color: state.data?.soldHoursColour ? state.data?.soldHoursColour : 'black' }}>
                                                            {state.data.soldHours ? formatters.ThousandSeparatorWithoutZero(state.data?.soldHours) : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                    <span className="salesCardTextPrimary">Hours</span>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className="vehicleRing" style={{ textAlign: 'justify', marginBottom: 10 }}>
                                                    <CircularProgressbar
                                                        value={state.data.soldAmountProgress}
                                                        text={`${state.data.soldAmountProgress || 0}%`}
                                                        styles={buildStyles({
                                                            textColor:
                                                                state.data.soldAmountProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.soldAmountProgress >= 50 &&
                                                                      state.data.soldAmountProgress < state.data.soldAmountProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.soldAmountProgress >= state.data.soldAmountProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            pathColor:
                                                                state.data.soldAmountProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.soldAmountProgress >= 50 &&
                                                                      state.data.soldAmountProgress < state.data.soldAmountProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.soldAmountProgress >= state.data.soldAmountProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            trailColor: '#d6d6d6',
                                                            textSize: '27px'
                                                        })}
                                                    />
                                                </div>
                                                <div>
                                                    <Typography className="salesCurrencyFont">
                                                        <span style={{ color: state.data?.soldAmountColour ? state.data?.soldAmountColour : 'black' }}>
                                                            {state.data.soldAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.soldAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                    <span className="salesCardTextPrimary">Value</span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2} xl={2} className="detailDashmainCardsubMenu" onClick={() => handleRedirect()}>
                                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography className="mainCardTextPrimary">LABOUR</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{ marginTop: 10 }}>
                                                    <div className="salesCurrencyFont ">
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.warrantyHours
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.warrantyHours)
                                                                : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.warrantyAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.warrantyAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>

                                                    <span className="salesCardTextPrimary primaryColor">
                                                        <b> Warranty</b>
                                                        <span style={{ marginLeft: '5px' }}>(Hours/Value)</span>
                                                    </span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{ marginTop: 5 }}>
                                                    <div className="salesCurrencyFont">
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.rmHours ? formatters.ThousandSeparatorWithoutZero(state.data?.rmHours) : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.rmAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.rmAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>
                                                    <span className="salesCardTextPrimary primaryColor">
                                                        {' '}
                                                        <b>R&M</b>
                                                        <span style={{ marginLeft: '5px' }}>(Hours/Value)</span>
                                                    </span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2} xl={2} className="detailDashmainCardsubMenu">
                                        <Grid container justifyContent="space-around" alignItems="center" spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography className="mainCardTextPrimary" style={{ display: 'block' }}>
                                                    <span> PARTS </span>
                                                    {/* <InfoIcon
                                                    color="secondary"
                                                    style={{ cursor: 'pointer', float: 'right' }}
                                                    // onClick={() => handleRedirectPart()}
                                                /> */}
                                                </Typography>
                                            </Grid>
                                            {state.parts == null || state.parts.length <= 0 ? (
                                                <Grid container justifyContent="space-around" alignItems="center">
                                                    No Records Found
                                                </Grid>
                                            ) : (
                                                state.parts.map((item) => {
                                                    return (
                                                        <Grid item lg={2} xl={12}>
                                                            <div>
                                                                <div className="mainText">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(item.partsAmount)}
                                                                </div>
                                                                <span className="salesCardTextPrimary">{item.partsDepartment}</span>
                                                            </div>
                                                        </Grid>
                                                    );
                                                })
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2} xl={2} className="detailDashmainCardsubMenu" onClick={() => handleRedirect()}>
                                        <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography className="mainCardTextPrimary">WIP</Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div>
                                                    <div className="salesCurrencyFont">
                                                        <span style={{ color: state.data?.wipHoursColour ? state.data?.wipHoursColour : 'black' }}>
                                                            {state.data.wipHours ? formatters.ThousandSeparatorWithoutZero(state.data?.wipHours) : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: state.data?.wipAmountColour ? state.data?.wipAmountColour : 'black' }}>
                                                            {state.data.wipAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.wipAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography className="salesCardTextPrimary primaryColor">
                                                    Surcharges<span style={{ marginLeft: '5px' }}>(Quantity/Value)</span>
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div>
                                                    <div className="salesCurrencyFont">
                                                        {' '}
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.surchargeQuantity
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.surchargeQuantity)
                                                                : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.surchargeAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.surchargeAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2} xl={2} className="detailDashmainCardsubMenu" onClick={() => handleRedirect()}>
                                        <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={2}>
                                            <Grid item xs={12}>
                                                <div className="mainCardTextPrimary">CUSTOMER THROUGHPUT</div>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div>
                                                    <div className="salesCurrencyFont">
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.uniqueCustomers
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.uniqueCustomers)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className="mainCardTextPrimary">NO. OF TECHNICIANS</Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div>
                                                    <div className="salesCurrencyFont">
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.technicianBudget
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.technicianBudget)
                                                                : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: state.data?.technicianBudget > state.data?.technicianWorking ? 'red' : 'green' }}>
                                                            {state.data.technicianWorking
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.technicianWorking)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        {/* <br /> */}
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={12}>
                                <Typography className="MainHeading">Vehicle Sales</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid
                                    container
                                    spacing={0}
                                    justifyContent="center"
                                    //  className={WindowWidths > 1280 ? 'detailDashmainCard1' : ''}
                                >
                                    {/* <Grid item xs={12}>
                                    <Typography className="MainHeading" color="secondary" style={{ textAlign: 'center', marginTop: 5, marginBottom: 5 }}>
                                        MTD
                                    </Typography>
                                </Grid> */}
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            // spacing={WindowWidths > 1280 ? 0 : 1}
                                            spacing={1}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={4}
                                                lg={WindowWidths > 1660 ? 2 : 4}
                                                xl={2}
                                                onClick={() => redirectTOVehicleDetails('VAN REGISTRATIONS', 1)}
                                                // onClick={() => redirectTOVehicleDetails('VAN REGISTRATIONS / PROFIT', 1)}
                                            >
                                                <VehicleCardNew
                                                    // label={'VAN REGISTRATIONS / PROFIT'}
                                                    label={'VAN REGISTRATIONS '}
                                                    // hideProfit={true}
                                                    // mtdProfit={state.sales.vanRegistrationsUnits}
                                                    // mtdBudget={state.sales.vanRegistrationsBudgetUnits}
                                                    // ytdProfit={state.sales.ytdVanRegistrationsUnits}
                                                    // ytdBudget={state.sales.ytdVanRegistrationsBudgetUnits}
                                                    //With Profit
                                                    mtdProfit={state.sales.vanRegistrationsProfit}
                                                    mtdBudget={state.sales.vanRegistrationsBudgetProfit}
                                                    mtdUnit={state.sales.vanRegistrationsUnits}
                                                    mtdBudgetunit={state.sales.vanRegistrationsBudgetUnits}
                                                    ytdProfit={state.sales.ytdVanRegistrationsProfit}
                                                    ytdBudget={state.sales.ytdVanRegistrationsBudgetProfit}
                                                    ytdUnit={state.sales.ytdVanRegistrationsUnits}
                                                    ytdBudgetunit={state.sales.ytdVanRegistrationsBudgetUnits}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={4}
                                                lg={WindowWidths > 1660 ? 2 : 4}
                                                xl={2}
                                                onClick={() => redirectTOVehicleUsedInvoice('VAN USED INVOICE', 1)}
                                            >
                                                <VehicleCard
                                                    label="VAN USED INVOICE"
                                                    mtdProfit={state.sales.usedInvocingProfit}
                                                    mtdBudget={state.sales.usedInvocingBudgetProfit}
                                                    mtdUnit={state.sales.usedInvocingUnits}
                                                    mtdBudgetunit={state.sales.usedInvocingBudgetUnits}
                                                    ytdProfit={state.sales.ytdUsedInvocingProfit}
                                                    ytdBudget={state.sales.ytdUsedInvocingBudgetProfit}
                                                    ytdUnit={state.sales.ytdUsedInvocingUnits}
                                                    ytdBudgetunit={state.sales.ytdUsedInvocingBudgetUnits}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={WindowWidths > 1660 ? 2 : 4} xl={2}>
                                                <Grid container justifyContent="center" alignItems="center" className="detailDashmainCard">
                                                    <Grid item xs={12} className="p-100">
                                                        <Typography className="mainCardTextPrimary  ml-10">ORDER INTAKE</Typography>
                                                    </Grid>
                                                    {/* <Grid item xs={12}>
                                                    <Typography style={{ margin: WindowWidths > 1280 ? 50 : 35 }}></Typography>
                                                </Grid> */}

                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">MTD</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">YTD</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{ margin: WindowWidths > 1280 ? 45 : 35 }}></Typography>
                                                    </Grid>

                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">VAN</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">VAN</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={6} onClick={() => redirectTOVehicleOrderIntek('VAN ORDER INTAKE', 1)}>
                                                            <Typography className="salesCurrencyFont ">{state?.sales?.vanOrdersUnits || 0}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} onClick={() => redirectTOVehicleOrderIntek('VAN ORDER INTAKE', 1)}>
                                                            <Typography className="salesCurrencyFont ">{state?.sales?.ytdVanOrdersUnits || 0}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{ margin: 25 }}></Typography>
                                                    </Grid>

                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">TRUCK</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">TRUCK</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={6} onClick={() => redirectTOVehicleOrderIntek('TRUCK ORDER INTAKE', 2)}>
                                                            <Typography className="salesCurrencyFont ">{state?.sales?.truckOrdersUnits || 0}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} onClick={() => redirectTOVehicleOrderIntek('TRUCK ORDER INTAKE', 2)}>
                                                            <Typography className="salesCurrencyFont ">{state?.sales?.ytdTruckOrdersUnits || 0}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{ margin: WindowWidths > 1280 ? 45 : 35 }}></Typography>
                                                    </Grid>

                                                    {/* <Grid item xs={12}>
                                                    <TableContainer>
                                                        <Table style={{ marginTop: -1 }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell>
                                                                        {' '}
                                                                        <div className="mainCardTextPrimary  ">MTD</div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <div className="mainCardTextPrimary  ">YTD</div>
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <StyledTableCell></StyledTableCell>
                                                                    <StyledTableCell></StyledTableCell>
                                                                    <StyledTableCell></StyledTableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                            <TableBody>
                                                                <TableRow onClick={() => redirectTOVehicleOrderIntek('VAN ORDER INTAKE', 1)}>
                                                                    <StyledTableCell>
                                                                        <div className="salesCardTextPrimary  ">Van</div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <Typography className="salesCurrencyFont ">
                                                                            {state?.sales?.vanOrdersUnits || 0}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <Typography className="salesCurrencyFont ">
                                                                            {state?.sales?.ytdVanOrdersUnits || 0}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                                <TableRow onClick={() => redirectTOVehicleOrderIntek('TRUCK ORDER INTAKE', 2)}>
                                                                    <StyledTableCell>
                                                                        <div className="salesCardTextPrimary  ">Truck</div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <Typography className="salesCurrencyFont ">
                                                                            {state?.sales?.truckOrdersUnits || 0}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        {' '}
                                                                        <Typography className="salesCurrencyFont ">
                                                                            {state?.sales?.ytdTruckOrdersUnits || 0}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid> */}

                                                    {/* <Grid item container xs={12} sm={4} md={12}>
                                                    <Grid item xs={12}>
                                                        <Typography className="mainCardTextPrimary ">MTD</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className="p-10"></Grid>
                                                    <Grid container item onClick={() => redirectTOVehicleOrderIntek('VAN ORDER INTAKE', 1)}>
                                                        <Grid item xs={12}>
                                                            <Typography className="mainCardTextPrimary ">VAN ORDER INTAKE</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography className="salesCurrencyFont ">
                                                                {state?.sales?.vanOrdersUnits || 0}
                                                                
                                                
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item onClick={() => redirectTOVehicleOrderIntek('TRUCK ORDER INTAKE', 2)}>
                                                        <Grid item xs={12}>
                                                            <Typography className="mainCardTextPrimary ">TRUCK ORDER INTAKE</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography className="salesCurrencyFont ">
                                                                {state?.sales?.truckOrdersUnits || 0}
                                                                
                                                  
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                               
                                                <Grid item container xs={12} sm={4} md={12}>
                                                    <Grid item xs={12}>
                                                        <Typography className="mainCardTextPrimary ">YTD</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className="p-10"></Grid>
                                                    <Grid container item onClick={() => redirectTOVehicleOrderIntek('VAN ORDER INTAKE', 1)}>
                                                        <Grid item xs={12}>
                                                            <Typography className="mainCardTextPrimary ">VAN ORDER INTAKE</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography className="salesCurrencyFont ">
                                                                {state?.sales?.ytdVanOrdersUnits || 0}
                                                                
                                                  
                                                            </Typography>
                                                        </Grid>
                                                       
                                                    </Grid>
                                                    <Grid container item onClick={() => redirectTOVehicleOrderIntek('TRUCK ORDER INTAKE', 2)}>
                                                        <Grid item xs={12}>
                                                            <Typography className="mainCardTextPrimary ">TRUCK ORDER INTAKE</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography className="salesCurrencyFont ">
                                                                {state?.sales?.ytdTruckOrdersUnits || 0}
                                                             
                                                  
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> */}
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={4}
                                                lg={WindowWidths > 1660 ? 2 : 4}
                                                xl={2}
                                                // onClick={() => redirectTOVehicleDetails('TRUCK REGISTRATIONS / PROFIT', 2)}
                                                onClick={() => redirectTOVehicleDetails('TRUCK REGISTRATIONS', 2)}
                                            >
                                                <VehicleCardNew
                                                    label="TRUCK REGISTRATIONS "
                                                    // mtdProfit={state.sales.truckRegistrationsUnits}
                                                    // mtdBudget={state.sales.truckRegistrationsBudgetUnits}
                                                    // ytdProfit={state.sales.ytdTruckRegistrationsUnits}
                                                    // ytdBudget={state.sales.ytdTruckRegistrationsBudgetUnits}
                                                    // hideProfit={true}

                                                    // label="TRUCK REGISTRATIONS / PROFIT"
                                                    mtdProfit={state.sales.truckRegistrationsProfit}
                                                    mtdBudget={state.sales.truckRegistrationsBudgetProfit}
                                                    mtdUnit={state.sales.truckRegistrationsUnits}
                                                    mtdBudgetunit={state.sales.truckRegistrationsBudgetUnits}
                                                    ytdProfit={state.sales.ytdTruckRegistrationsProfit}
                                                    ytdBudget={state.sales.ytdTruckRegistrationsBudgetProfit}
                                                    ytdUnit={state.sales.ytdTruckRegistrationsUnits}
                                                    ytdBudgetunit={state.sales.ytdTruckRegistrationsBudgetUnits}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={4}
                                                lg={WindowWidths > 1660 ? 2 : 4}
                                                xl={2}
                                                onClick={() => redirectTOVehicleUsedInvoice('TRUCK USED INVOICE', 2)}
                                            >
                                                <VehicleCard
                                                    label="TRUCK USED INVOICE"
                                                    mtdProfit={state.sales.truckUsedInvocingProfit}
                                                    mtdBudget={state.sales.truckUsedInvocingBudgetProfit}
                                                    mtdUnit={state.sales.truckUsedInvocingUnits}
                                                    mtdBudgetunit={state.sales.truckUsedInvocingBudgetUnits}
                                                    ytdProfit={state.sales.ytdTruckUsedInvocingProfit}
                                                    ytdBudget={state.sales.ytdTruckUsedInvocingBudgetProfit}
                                                    ytdUnit={state.sales.ytdTruckUsedInvocingUnits}
                                                    ytdBudgetunit={state.sales.ytdTruckUsedInvocingBudgetUnits}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={4}
                                                lg={WindowWidths > 1660 ? 2 : 4}
                                                xl={2}
                                                onClick={() => redirectTOVehiclEVTarget('EV TARGET')}
                                            >
                                                <VehicleCard
                                                    isElectric={true}
                                                    label="EV TARGET"
                                                    mtdProfit={state.sales.evUnits}
                                                    mtdBudget={state.sales.evBudgetUnits}
                                                    ytdProfit={state.sales.ytdevUnits}
                                                    ytdBudget={state.sales.ytdevBudgetUnits}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} sm={6} md={6} lg={12} xl={12}>
                            <Grid container spacing={0} justifyContent="center" className={WindowWidths > 1280 ? 'detailDashmainCard1' : ''}>
                                <Grid item xs={12}>
                                    <Typography className="MainHeading" color="secondary" style={{ textAlign: 'center', marginTop: 5, marginBottom: 5 }}>
                                        YTD
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={WindowWidths > 1280 ? 0 : 1}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={WindowWidths > 1660 ? 2 : 4}
                                            xl={2}
                                            onClick={() => redirectTOVehicleDetails('VAN REGISTRATIONS / PROFIT', 1)}
                                        >
                                            <VehicleCard
                                                label={'VAN REGISTRATIONS / PROFIT'}
                                                mtdProfit={state.sales.ytdVanRegistrationsProfit}
                                                mtdBudget={state.sales.ytdVanRegistrationsBudgetProfit}
                                                mtdUnit={state.sales.ytdVanRegistrationsUnits}
                                                mtdBudgetunit={state.sales.ytdVanRegistrationsBudgetUnits}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={WindowWidths > 1660 ? 2 : 4}
                                            xl={2}
                                            onClick={() => redirectTOVehicleUsedInvoice('VAN USED INVOICE', 1)}
                                        >
                                            <VehicleCard
                                                label="VAN USED INVOICE"
                                                profit={state.sales.ytdUsedInvocingProfit}
                                                budget={state.sales.ytdUsedInvocingBudgetProfit}
                                                unit={state.sales.ytdUsedInvocingUnits}
                                                budgetunit={state.sales.ytdUsedInvocingBudgetUnits}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={WindowWidths > 1660 ? 2 : 4} xl={2}>
                                            <Grid container justifyContent="center" alignItems="center" className="detailDashmainCard" style={{ padding: 16 }}>
                                                <Grid container item onClick={() => redirectTOVehicleOrderIntek('VAN ORDER INTAKE', 1)}>
                                                    <Grid item xs={12}>
                                                        <Typography className="mainCardTextPrimary ">VAN ORDER INTAKE</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className="salesCurrencyFont ">{state?.sales?.ytdVanOrdersUnits || 0}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className="p-10"></Grid>
                                                </Grid>
                                                <Grid container item onClick={() => redirectTOVehicleOrderIntek('TRUCK ORDER INTAKE', 2)}>
                                                    <Grid item xs={12}>
                                                        <Typography className="mainCardTextPrimary ">TRUCK ORDER INTAKE</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className="salesCurrencyFont ">{state?.sales?.ytdTruckOrdersUnits || 0}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={WindowWidths > 1660 ? 2 : 4}
                                            xl={2}
                                            onClick={() => redirectTOVehicleDetails('TRUCK REGISTRATIONS / PROFIT', 2)}
                                        >
                                            <VehicleCard
                                                label="TRUCK REGISTRATIONS / PROFIT"
                                                profit={state.sales.ytdTruckRegistrationsProfit}
                                                budget={state.sales.ytdTruckRegistrationsBudgetProfit}
                                                unit={state.sales.ytdTruckRegistrationsUnits}
                                                budgetunit={state.sales.ytdTruckRegistrationsBudgetUnits}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={WindowWidths > 1660 ? 2 : 4}
                                            xl={2}
                                            onClick={() => redirectTOVehicleUsedInvoice('TRUCK USED INVOICE', 2)}
                                        >
                                            <VehicleCard
                                                label="TRUCK USED INVOICE"
                                                profit={state.sales.ytdTruckUsedInvocingProfit}
                                                budget={state.sales.ytdTruckUsedInvocingBudgetProfit}
                                                unit={state.sales.ytdTruckUsedInvocingUnits}
                                                budgetunit={state.sales.ytdTruckUsedInvocingBudgetUnits}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={WindowWidths > 1660 ? 2 : 4}
                                            xl={2}
                                            onClick={() => redirectTOVehiclEVTarget('EV TARGET')}
                                        >
                                            <VehicleCard
                                                isElectric={true}
                                                label="EV TARGET"
                                                profit={state.sales.ytdevUnits}
                                                budget={state.sales.ytdevBudgetUnits}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default MiniViewReportingDashboard;
