import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import AppContext from '../../../App/AppContext';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../Core/FormInput/index';
import AddUpdateModal from './AddUpdateModal';
import { getStandardWriteUp } from '../../../Core/Service/techWriteUp-service';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteStandardWriteUpModal from './DeleteStandardWriteUp';
import DataGridComp from '../../../Core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { some } from 'lodash';

let searchTimer;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    // paper: {
    //     width: '100%',
    //     marginBottom: theme.spacing(2)
    // },
    table: {
        border: '1px solid rgba(0,0,0,0.05)',
        // minWidth: 1450,
        '& .MuiTableCell-root': {
            padding: '5px 7px 5px 0px'
        }

        // '& .MuiTableRow-root:hover': {
        //     backgroundColor: 'orange'
        // }
        // '&$hover:hover': {
        //     backgroundColor: 'red'
        // }
    },
    tbody: {
        padding: '0px !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    textError: {
        color: 'white'
    },
    textSucc: {
        color: 'black'
    }
}));

const StandardTechWriteUp = (props) => {
    const classes = useStyles();

    const [state, setState] = useState({
        rows: [],
        show: false,
        branches: [],
        showDel: false,
        StandardWriteUpID: null,
        filterText: '',
        filterTableData: []
    });
    const { showToast } = useContext(AppContext);

    useEffect(async () => {
        pullStandardWriteUpAndUpdateState();
    }, []);
    const getClass = (params) => {
        if (!params.row.isActive) {
            return 'Deleted-record';
        }
    };
    const column_new = [
        {
            field: 'id',
            headerName: 'ID',
            width: 130,
            sortable: false,
            hide: true
        },
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 100,
            renderCell: (params) => <Arrow value={params} />
        },
        { field: 'title', headerName: 'Title', flex: 1.5 },
        { field: 'complaint', headerName: 'Complaint', flex: 1.7 },
        {
            field: 'cause',
            headerName: 'Cause',
            flex: 3
        },
        {
            field: 'cure',
            headerName: 'Cure',
            flex: 4
        }
    ].map((c) => ({ ...c, cellClassName: getClass }));

    const handleDeleteStandardWriteUp = (params) => {
        const StandardWriteUp_id = params.id;
        setState((st) => {
            const nst = {
                ...st,
                showDel: true,
                StandardWriteUpID: StandardWriteUp_id,
                StandardWriteUpDetails: params.title
            };
            return nst;
        });
    };

    const Arrow = ({ params }) => {
        return (
            <Grid container justifyContent="center">
                <Tooltip title="Update Standard Write Up" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Standard Write Up" aria-label="add">
                    <IconButton size="small" onClick={() => handleDeleteStandardWriteUp(params)}>
                        <DeleteOutlineIcon className="SetButtonColor" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };
    const pullStandardWriteUpAndUpdateState = async () => {
        let result = await getStandardWriteUp('Y');
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data.list
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };
    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                StandardWriteDataList: params
            };
            return nst;
        });
    };
    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                [props]: true,
                StandardWriteDataList: []
            };
        });
    };

    const modalClosed = (refreshGrid) => {
        setState((st) => ({ ...st, show: false, showDel: false }));
        refreshGrid && pullStandardWriteUpAndUpdateState();
    };

    const setFilterText = (e) => {
        const vl = e.target.value;
        setState((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setState((st) => {
            const newSt = { ...st };
            const { filterText, rows } = newSt;

            let filterd = [];
            filterd = [...rows];
            if (filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }

            newSt.filterTableData = filterd;
            return newSt;
        });
    };
    useEffect(() => {
        setFilteredRow();
    }, [state.rows]);

    return (
        <div style={{ margin: '0px 10px 0px 5px' }}>
            {/* <div style={{ position: 'absolute', right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={() => handleAdd('show')} className="btnadd">
                    Add Standard Technician Write Up
                </SecondaryButton>
            </div> */}

            {/* <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Standard Technician Write-Up
                </Typography>
            </Breadcrumbs> */}
            {/* <Grid item xs={12} sm={12}> */}
            {/* <div
                    style={{
                        height: 'calc(100vh - 260px)',
                        width: '99.8%'
                    }}
                >
                    <DataGridComp
                        headerHeight={30}
                        rowHeight={30}
                        rows={state.rows}
                        columns={column_new}
                        enableSearch={true}
                        offset={244}
                        CustomNoRowsOverlay={CustomNoRowsOverlay}
                    />
                </div> */}
            {/* </Grid> */}
            {/* <Grid container className="roots">
                <Grid item xs={12} sm={12}>
                    <div
                        style={{
                            height: 'calc(100vh - 160px)',
                            width: '99%',
                            margin: '5px',
                            marginTop: '-20px'
                        }}
                    >
                        <DataGridComp
                            headerHeight={30}
                            rowHeight={30}
                            rows={state.rows}
                            columns={column_new}
                            enableSearch={true}
                            offset={244}
                            CustomNoRowsOverlay={CustomNoRowsOverlay}
                        />
                        <DataGrid
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            rows={state.rows}
                            headerHeight={30}
                            rowHeight={30}
                            hideFooter={true}
                            columns={column_new}
                            pageSize={100}
                            disableColumnMenu={true}
                            showColumnRightBorder={true}
                        />
                    </div>
                </Grid>
            </Grid> */}
            {/* <Grid container style={{ margin: '5px' }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <TableContainer style={{ height: 'calc(100vh - 180px)', width: '99%' }}>
                        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
                            <TableHead>
                                <TableRow hover>
                                    <TableCell width={'5%'}>Action</TableCell>
                                    <TableCell width={'10%'}>Title</TableCell>
                                    <TableCell width={'10%'}>Complaint</TableCell>
                                    <TableCell width={'10%'}>Cause</TableCell>
                                    <TableCell width={'65%'}>Cure</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody className={classes.tbody}>
                                {state.rows.map((row, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            <TableCell>{<Arrow params={row} />}</TableCell>
                                            <TableCell>{row.title}</TableCell>
                                            <TableCell>{row.complaint}</TableCell>
                                            <TableCell>{row.cause}</TableCell>
                                            <TableCell>{row.cure}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid> */}

            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Breadcrumbs separator=">>" aria-label="breadcrumb">
                        <Link color="inherit" to={'/'}>
                            Home
                        </Link>
                        <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                            Standard Technician Write-Up
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <SecondaryButton variant="contained" color="secondary" onClick={() => handleAdd('show')} style={{}}>
                        Add Standard Technician Write Up
                    </SecondaryButton>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={7}>
                    <TextBox
                        // margin="normal"
                        required
                        name="Search"
                        label="Search"
                        onChange={setFilterText}
                        value={state.filterText}
                        // style={{ paddingTop: '14px', paddingBottom: '5px' }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <TableContainer style={{ height: 'calc(100vh - 210px)' }}>
                        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
                            <TableHead>
                                <TableRow hover>
                                    <TableCell width={'5%'} align="center">
                                        Action
                                    </TableCell>
                                    <TableCell width={'10%'}>Title</TableCell>
                                    <TableCell width={'10%'}>Complaint</TableCell>
                                    <TableCell width={'10%'}>Cause</TableCell>
                                    <TableCell width={'65%'}>Cure</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody className={classes.tbody}>
                                {state.filterTableData.map((row, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            <TableCell>{<Arrow params={row} />}</TableCell>
                                            <TableCell>{row.title}</TableCell>
                                            <TableCell>{row.complaint}</TableCell>
                                            <TableCell>{row.cause}</TableCell>
                                            <TableCell>{row.cure}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {state.show ? <AddUpdateModal onFormSubmit={modalClosed} StandardWriteDataList={state.StandardWriteDataList} /> : null}
            {state.showDel ? (
                <DeleteStandardWriteUpModal
                    StandardWriteUpID={state.StandardWriteUpID}
                    onFormSubmit={modalClosed}
                    StandardWriteUpDetails={state.StandardWriteUpDetails}
                />
            ) : null}
        </div>
    );
};
export default StandardTechWriteUp;
