import { Breadcrumbs, IconButton, Link, Tooltip, Typography } from "@material-ui/core";
import { GridOverlay } from "@material-ui/data-grid";
import { Image } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import DataGridComp from '../../../Core/Grid';
import WIPDocumentsViewer from "./viewer";
import GijgoGrid from "../../internal/dashboard";
import { Grid } from "@material-ui/core";
import ReportGijgoGrid from "../../internal/ReportsScreens/GijgoForReportsScreen";
import { SquareButton } from "../../../Core/FormInput/AppButton";
import ReactDOM from 'react-dom';
import { useMemo } from "react";
import formatters from "../../../Core/Grid/inputFormatter";

const DocumentViewer = (props) => {
    const [state, setState] = useState({
        showViewer: false,
        focusedRow: null,
    });

    const handleView = (show, row) => {
        setState((st) => ({
            ...st,
            showViewer: show,
            focusedRow: row,
        }))
    }

    const baseColumns = [
        {
            field: 'action',
            title: `Action`,
            width: '5%',
            filterable: false,
            sortable: false,
        },
        {
            field: 'wipNumber',
            title: `WIP`,
            filterable: true,
            sortable: true,
        },
        {
            field: 'ocrDocumentName',
            title: `Document Type`,
        },
        {
            field: 'headerRecordID',
            title: `Header ID`,
            hidden: true,
        },
        {
            field: 'ocrDocumentID',
            title: `ocrDocumentID`,
            hidden: true,
        },
        {
            field: 'dateDueIn',
            title: `Date Due In`,
        },
        {
            field: 'documentID',
            title: `Document ID`,
            hidden: true,
        },
        {
            field: 'createdAt',
            title: `Created At`,
        },
        {
            field: 'createdBy',
            title: `Created By`,
        },
        {
            field: 'document',
            title: `Document Name`,
            hidden: true,
        },
        {
            field: 'customerName',
            title: `Customer`,
        },
        {
            field: 'branchShortName',
            title: `Branch`,
        },
        {
            field: 'franchiseName',
            title: `Franchise`,
        },
    ];

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent='space-evenly'>
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => handleView(true, record)}
                >
                    <Image fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');
        let dateDueInCol = tempCols.find((element) => element.field === 'dateDueIn');
        let createdAtCol = tempCols.find((element) => element.field === 'createdAt');

        if (actionCol) {
            actionCol.renderer = editButton({});
        }

        if (dateDueInCol) {
            dateDueInCol.renderer = (val) => formatters.DateFormatter(val)
        }

        if (createdAtCol) {
            createdAtCol.renderer = (val) => formatters.DateTimeFormatter(val)
        }

        return tempCols;
    }, []);

    const baseUrl = 'DocumentViewer/GetAllDocuments';

    return (
        <div>
            {state.showViewer && (
                <WIPDocumentsViewer focusedRow={state.focusedRow} handleClose={() => handleView(false, null)} />
            )}

            <div>
                <Breadcrumbs separator=">>" aria-label="breadcrumb" style={{ marginTop: '25px', marginLeft: '5px' }}>
                    <Link color="inherit" to={"/"}>
                        Home
                    </Link>
                    <Typography color="secondary" style={{ fontSize: "0.85rem" }}>
                        WIP Documents Viewer
                    </Typography>
                </Breadcrumbs>

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ReportGijgoGrid
                            dataConverter={(res) => ({
                                records: res?.data?.rows,
                                total: res?.data?.total
                            })}
                            columns={columns}
                            getUrl={baseUrl}
                            isReload={state.isReload}
                        />
                    </Grid>
                </Grid>
            </div>
        </div >
    );
};

export default DocumentViewer;
