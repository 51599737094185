import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox } from '@material-ui/core';
import moment from 'moment';
import { PrimaryButton, SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { searchJobByText } from '../../../../Core/Service/workflowJobDocument-service';

const MatchingJobRow = ({ job, onJobSelect }) => {
    return <tr className={`row${job.isSelected ? ' is-selected-job' : ''}`}>
        <td className="is-selected">
            <Checkbox
                checked={job.isSelected}
                color={job.isSelected ? "primary" : ""}
                onChange={e => onJobSelect(e.target.checked, job.headerId)}
            />
        </td>    
        <td className="col-wip">{job.wipNumber}</td>
        <td className="col-reg">{job.registration}</td>
        <td className="col-cust">{job.customerName}</td>
        <td className="col-due-in">{job.dateDueIn ? moment(job.dateDueIn).format("LLL") : ' - '}</td>
    </tr>
}

const MatchingJobsTable = ({ jobs, loading, onJobSelect }) => {
    return <>
        <table className="job-search-header">
            <thead>
                <tr className="header">
                    <th className="is-selected"></th>
                    <th className="col-wip">WIP</th>
                    <th className="col-reg">Reg.</th>
                    <th className="col-cust">Customer</th>
                    <th className="col-due-in">Due In</th>
                </tr>
            </thead>
        </table>
        <div className="body-table-container custom-scroll">
            <table className="job-search-body">
                <tbody>
                    {jobs.map((j, i) => <MatchingJobRow key={i} job={j} onJobSelect={onJobSelect} />)}
                    {jobs.length === 0 && <tr><td colSpan="4">{loading ? 'Loading...' : 'No record found.'}</td></tr>}
                </tbody>
            </table>
        </div>
    </>
}

let timer;

const OtherJobSelectionToMoveDoc = props => {
    const [state, setState] = useState({
        headerId: props.headerId,
        loading: false,
        selectedHeaderId: undefined,
        searchText:'',
        matchedJob: []
    });

    const setSearchText = e=> {
        const searchText = e.target.value;
        timer && clearTimeout(timer);
        setState(st=>({...st, searchText, selectedHeaderId: undefined}));
        timer = setTimeout(()=>{
            if(searchText.length > 2){
                setState(st=>({...st, loading: true}));
                searchJobByText(state.headerId, searchText)
                .then(res=>{
                    if(res.success){
                        setState(st=>({...st, matchedJob: res.data.map(j=>({...j, isSelected: false}))}));
                    }else{
                        props.showToast(res.message);
                    }
                })
                .finally(()=>{
                    setState(st=>({...st, loading: false}));
                })
            }
        }, 500);
    }
    
    const onJobSelect = (checked, jobHeaderId) => {
        setState(st => {
            let newSt = { ...st };
            let job = newSt.matchedJob.find(d => d.headerId === jobHeaderId);
            if (job) {
                newSt.matchedJob.forEach(d => d.isSelected = false);
                job.isSelected = checked; 
                newSt.selectedHeaderId = checked ? jobHeaderId : undefined;               
                newSt.matchedJob = [...newSt.matchedJob]
            }
            return newSt;
        });                
    }

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const processDocMovement = ()=> {
        props.onProcess && props.onProcess(state.selectedHeaderId);
    }

    return <Dialog open={true} onClose={handleClose} maxWidth="md" className="dialog-custom job-search-modal">
        <DialogTitle>Search Job
            <div className="title-header-actions">
                <span className="close-icon" onClick={handleClose}>&times;</span>
            </div>
        </DialogTitle>
        <DialogContent>            
            <TextBox label="Search WIP No" required={false} onChange={setSearchText} value={state.searchText} autoFocus={true} />
            <div className="job-search">
                <MatchingJobsTable jobs={state.matchedJob} loading={state.loading} onJobSelect={onJobSelect} />
            </div>
        </DialogContent>
        <DialogActions>
            <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
            <PrimaryButton onClick={processDocMovement} disabled={!state.selectedHeaderId}>Move Document</PrimaryButton>
        </DialogActions>
    </Dialog>
}

export default OtherJobSelectionToMoveDoc;