import React, { useEffect, useState, useContext } from 'react';
import { getDiagnosticFaultByID, postDiagnosticFaults } from '../../../Core/Service/diagnosticFaults-service';
import { Grid, InputLabel, Typography, FormLabel } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../Core/FormInput';
import AppContext from './../../../App/AppContext';
import getResizedImage from '../../../Core/CommonFunctions/imageResizer';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const modelgridStyle = {
    formatterImageContainer: { width: '100%', textAlign: 'left' },
    //  formatterImage: { width: '40%', maxHeight: 60, backgroundSize: 'cover' },
    formatterImage: { width: 120, maxHeight: 60, backgroundSize: 'cover' }
};
const EditBranchScreen = (props) => {
    const [state, setState] = useState({
        name: '',
        color: '',
        icon: '',
        id: props.id,
        errors: {}
    });

    const { showToast, showModal } = useContext(AppContext);

    useEffect(async () => {
        if (props.id) {
            let res = await getDiagnosticFaultByID(props.id);
            if (res.success) {
                let data = res.data?.list?.table[0];
                setState((st) => ({
                    ...st,
                    name: data.name,
                    id: data.id,
                    icon: data.icon,
                    color: data.color
                }));
            }
        }
    }, []);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };
    const clearImage = (name) => (e) => {
        setState((st) => ({ ...st, [name]: null }));
    };
    let handleImage = (event) => {
        if (event.target.files[0].type.includes('image')) {
            getResizedImage(
                event.target.files[0],
                (fileUrl) => {
                    setState((state) => {
                        const nst = { ...state, icon: fileUrl };
                        return nst;
                    });
                },
                200,
                300
            );
        } else {
            showToast('Wrong file selected');
        }
    };
    const handleSubmit = async () => {
        let data = {
            id: state.id,
            name: state.name,
            color: state.color,
            icon: state.icon
        };
        let res = await postDiagnosticFaults(data);
        if (res.success) {
            showToast(`Branches Updated Successfully`);
            props.onClose(true);
        } else {
            console.error(res.message);
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <FormLabel component="legend" className="mandatoryfields">
                        Name
                    </FormLabel>
                    <TextBox name="name" onChange={handleInput} value={state.name} autoFocus={true} />
                    <span className="mandatoryfields">{state.errors?.name}</span>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormLabel component="legend" className="mandatoryfields">
                        Color
                    </FormLabel>
                    <TextBox name="color" onChange={handleInput} value={state.color} />
                    <span className="mandatoryfields">{state.errors?.color}</span>
                </Grid>
                <Grid item xs={12} md={4} sm={6} lg={3} style={{ position: 'relative' }}>
                    <InputLabel shrink reuired>
                        Icon
                    </InputLabel>
                    <Grid container>
                        {state.icon ? (
                            <span
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 0
                                }}
                            >
                                <Tooltip title="Remove Logo">
                                    <CloseIcon fontSize="small" onClick={clearImage('icon')} />
                                </Tooltip>
                            </span>
                        ) : (
                            <Grid item xs={6} style={{ marginTop: '8px' }}>
                                <input type="file" name="image_value" onChange={handleImage} accept="image/*" />
                            </Grid>
                        )}

                        <Grid item xs={6} style={modelgridStyle.formatterImageContainer}>
                            <img
                                src={state.icon?.includes('base64') ? state.icon : 'data:image/png;base64,' + state.icon}
                                alt={''}
                                style={modelgridStyle.formatterImage}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default EditBranchScreen;
