import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions } from '../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import '../User Management/UserManagement.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { addReportingCodes, updateReportingCodes } from '../../../Core/Service/ReportingCodes-service';

const AddReportingCodesModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        id: '',
        reportCodeDescription: '',
        errors: {},
        fields: {}
    });

    const ReportingCodeDetails = props.ReportingCodesDetails;

    useEffect(async () => {
        const ReportingCodeData = ReportingCodeDetails;
        if (ReportingCodeDetails) {
            setState((st) => ({
                ...st,
                id: ReportingCodeData.id,
                reportCodeDescription: ReportingCodeData.reportCodeDescription
            }));
        }
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: name === 'id' ? value.slice(0, 2) : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            if (ReportingCodeDetails) {
                let res = await updateReportingCodes(state.id, state.reportCodeDescription);
                if (!res.success) {
                    console.error(res);
                } else {
                    hideModal();
                    props.onFormSubmit(true);
                }
            } else {
                let res = await addReportingCodes(state.id, state.reportCodeDescription);
                if (!res.success) {
                    console.error(res);
                } else {
                    hideModal();
                    props.onFormSubmit(true);
                }
                showToast(res.message);
            }
        }
    };

    const handleValidation = () => {
        let { id, reportCodeDescription } = state;
        let errors = {};
        let formIsValid = true;

        if(id == null || id == '') {
            formIsValid = false;
            errors["id"] = "code cannot be empty";
        }

        if(reportCodeDescription == null || reportCodeDescription == '') {
            formIsValid = false;
            errors["reportCodeDescription"] = "description cannot be empty";
        }

        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    {ReportingCodeDetails ? <DialogTitles>Update Reporting Code</DialogTitles> : <DialogTitles>Add Reporting Code</DialogTitles>}
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll" style={{ overflow: 'auto', width: '56vh' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Reporting Code
                        </FormLabel>
                        <TextBox
                            name="id"
                            onChange={fieldChange}
                            value={state.id}
                            disabled={ReportingCodeDetails ? true : false}
                            autoFocus={ReportingCodeDetails ? false : true}
                        />
                        <span className="mandatoryfields">{state.errors['id']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Report Code Description
                        </FormLabel>
                        <TextBox name="reportCodeDescription" onChange={fieldChange} value={state.reportCodeDescription} autoFocus={ReportingCodeDetails ? true : false} />
                        <span className="mandatoryfields">{state.errors['reportCodeDescription']}</span>
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    )
}

export default AddReportingCodesModal;