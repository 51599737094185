import { get, post } from './http-calls';

export const getHandOverDetails = async (ID) => {
    let res = await get(`handoversheet/${ID}`, { useAuthToken: true });
    return res;
};

export const saveHandOverSheets = async (ID, data) => {
    let res = await post(`handoversheet/${ID}`, data, { useAuthToken: true });
    return res;
};
