import { Button, ButtonGroup, FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import React from 'react';
import { Multiselect, SecondaryButton, SelectBox, TextBox } from '../../../../Core/FormInput';
import { getAdminDropDown, getNotificationGroupsById, postNotificationGroups } from '../../../../Core/Service/communicator-service';
import { useEffect } from 'react';
import { useState } from 'react';
import { MultiselectWithoutCheckbox } from '../../../../Core/FormInput/MultiSelect';
import { useContext } from 'react';
import AppContext from './../../../../App/AppContext';
import { FormatColorResetRounded } from '@material-ui/icons';

const AddCustomerGroups = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        customerList: [],
        customerGroupSiteVehiclesOnly: false,
        sendSMSUpdates: false,
        sendEmailNotifaction: false,
        customerGroupList: [],
        frequencyList: [],
        frequency: '',
        errors: {},
        ocrDocumentsList: [],
        ocrDocumentSelectIDs: []
    });

    const [selectedData, setSelectedData] = useState({
        customerGroups: [],
        ocrDocuments: []
    });

    useEffect(async () => {
        let res = await getAdminDropDown();
        if (res.success) {
            setState((st) => ({
                ...st,
                frequencyList: res.data.frequency,
                customerGroupList: res.data.customerGroupList,
                ocrDocumentsList: res.data?.ocrDocuments
            }));
        }
    }, []);

    useEffect(() => {
        if (props.customerGroupID) {
            pullDataById(props.customerGroupID);
        }
    }, []);

    const pullDataById = async (val) => {
        let res = await getNotificationGroupsById(val);
        if (res.success) {
            let data = res.data.list[0];
            let customerSelectIDs = res.data.customerSelectIDs;
            let ocrDocumentSelectIDs = res.data.ocrDocumentSelectIDs;
            setState((st) => ({
                ...st,
                customerGroupEmail: data.customerGroupEmail,
                customerGroupID: data.customerGroupID,
                customerGroupName: data.customerGroupName,
                customerGroupSiteVehiclesOnly: data.customerGroupSiteVehiclesOnly,
                feedbackEmailAddress: data.feedbackEmailAddress,
                frequency: data.frequency,
                sendEmailNotifaction: data.sendEmailNotifaction,
                sendSMSUpdates: data.sendSMSUpdates,
                sMSNumber: data.smsNumber,
                vORTimer: data.vorTimer,
                customerSelectIDs: customerSelectIDs,
                ocrDocumentSelectIDs: ocrDocumentSelectIDs
            }));
        }
    };

    useEffect(() => {
        if (state.customerSelectIDs && state.customerGroupList.length > 0) {
            setSelectedData((st) => ({
                ...st,
                customerGroups: state.customerGroupList.filter((w) => state.customerSelectIDs.indexOf(w.id) > -1)
            }));
        }
    }, [state.customerSelectIDs, state.customerGroupList]);

    useEffect(() => {
        if (state.ocrDocumentSelectIDs && state.ocrDocumentsList.length > 0) {
            setSelectedData((st) => ({
                ...st,
                ocrDocuments: state.ocrDocumentsList.filter((w) => state.ocrDocumentSelectIDs.indexOf(w.id) > -1)
            }));
        }
    }, [state.ocrDocumentSelectIDs, state.ocrDocumentsList]);

    const handleYesNo = (name, val) => {
        setState((st) => ({ ...st, [name]: val }));
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const AutoWorkAccomplish = (event, value) => {
        setSelectedData((st) => ({
            ...st,
            customerGroups: value
        }));
        setState((st) => {
            const nst = { ...st, customerSelectIDs: value.map((c) => c.id) };
            return nst;
        });
    };

    const AutoOcrDocuments = (event, value) => {
        setSelectedData((st) => ({
            ...st,
            ocrDocuments: value
        }));
        setState((st) => {
            const nst = { ...st, ocrDocumentSelectIDs: value.map((c) => c.id) };
            return nst;
        });
    };
    const validation = () => {
        let errors = {};
        let isValid = true;

        let { customerGroupName, customerGroupEmail } = state;

        if (!customerGroupName) {
            isValid = false;
            errors.name = 'Notification Group is required';
        }
        if (!customerGroupEmail) {
            isValid = false;
            errors.email = 'Emails is required';
        }
        if (selectedData.customerGroups.length == 0) {
            isValid = false;
            errors.customer = 'Customers is required';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));

        return isValid;
    };

    const handlesubmit = async () => {
        if (validation()) {
            let data = {
                customerGroupID: props.customerGroupID || null,
                customerGroupName: state.customerGroupName || '',
                customerGroupEmail: state.customerGroupEmail || '',
                customerGroupSiteVehiclesOnly: state.customerGroupSiteVehiclesOnly || false,
                sendEmailNotifaction: state.sendEmailNotifaction || false,
                vORTimer: state.vORTimer || '',
                frequency: state.frequency || '',
                feedbackEmailAddress: state.feedbackEmailAddress || '',
                sendSMSUpdates: state.sendSMSUpdates || false,
                sMSNumber: state.sMSNumber || '',
                customerSelectIDs: state.customerSelectIDs || [],
                ocrDocumentSelectIDs: state.ocrDocumentSelectIDs || []
            };

            let res = await postNotificationGroups(data);
            if (res.success) {
                showToast(`Customer Group ${props.customerGroupID ? 'Updated' : 'Added'} successfully`);
                props.onClose(true);
            } else {
                showToast(`Customer Group Failed to ${props.customerGroupID ? 'Update' : 'Add'}`);
            }
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel shrink error>
                        Notification Group
                    </InputLabel>
                    <TextBox name="customerGroupName" value={state.customerGroupName} onChange={handleInput} />
                    <FormHelperText error>{state.errors.name}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" color="secondary" style={{ fontWeight: 600, textAlign: 'left', fontSize: '18px' }}>
                        Email Updates
                    </Typography>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={2}>
                            <InputLabel shrink>Send Notification?</InputLabel>
                            <br />
                            <ButtonGroup disableElevation size="small" variant="contained" color="secondary">
                                <Button
                                    variant={state.sendEmailNotifaction ? 'contained' : 'outlined'}
                                    onClick={() => handleYesNo('sendEmailNotifaction', true)}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant={state.sendEmailNotifaction ? 'outlined' : 'contained'}
                                    onClick={() => handleYesNo('sendEmailNotifaction', false)}
                                >
                                    No
                                </Button>
                            </ButtonGroup>
                        </Grid>

                        <Grid item xs={2}>
                            <InputLabel shrink>Site Vehicles Only?</InputLabel>
                            <br />
                            <ButtonGroup disableElevation size="small" variant="contained" color="secondary">
                                <Button
                                    variant={state.customerGroupSiteVehiclesOnly ? 'contained' : 'outlined'}
                                    onClick={() => handleYesNo('customerGroupSiteVehiclesOnly', true)}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant={state.customerGroupSiteVehiclesOnly ? 'outlined' : 'contained'}
                                    onClick={() => handleYesNo('customerGroupSiteVehiclesOnly', false)}
                                >
                                    No
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel shrink>Frequency</InputLabel>
                            <SelectBox List={state.frequencyList} name="frequency" value={+state.frequency} onChange={handleInput} />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel shrink>VOR Alert (Hours)</InputLabel>
                            <TextBox name="vORTimer" value={state.vORTimer} onChange={handleInput} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink error>
                                Emails
                            </InputLabel>
                            <TextBox name="customerGroupEmail" value={state.customerGroupEmail} onChange={handleInput} />
                            <FormHelperText error>{state.errors.email}</FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink>Feedback Email </InputLabel>
                            <TextBox name="feedbackEmailAddress" value={state.feedbackEmailAddress} onChange={handleInput} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" color="secondary" style={{ fontWeight: 600, textAlign: 'left', fontSize: '18px' }}>
                        SMS Updates
                    </Typography>

                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={2}>
                            <InputLabel shrink>Send SMS Updates?</InputLabel>
                            <br />
                            <ButtonGroup disableElevation size="small" variant="contained" color="secondary">
                                <Button variant={state.sendSMSUpdates ? 'contained' : 'outlined'} onClick={() => handleYesNo('sendSMSUpdates', true)}>
                                    Yes
                                </Button>
                                <Button variant={state.sendSMSUpdates ? 'outlined' : 'contained'} onClick={() => handleYesNo('sendSMSUpdates', false)}>
                                    No
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={5}>
                            <InputLabel shrink>Mobile</InputLabel>
                            <TextBox name="sMSNumber" value={state.sMSNumber} onChange={handleInput} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink error>
                                Customers
                            </InputLabel>
                            <MultiselectWithoutCheckbox options={state.customerGroupList} value={selectedData.customerGroups} onChange={AutoWorkAccomplish} />
                            <FormHelperText error>{state.errors.customer}</FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink error>
                                Documents
                            </InputLabel>
                            <MultiselectWithoutCheckbox options={state.ocrDocumentsList} value={selectedData.ocrDocuments} onChange={AutoOcrDocuments} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                    <SecondaryButton fullWidth onClick={handlesubmit}>
                        {props.customerGroupID ? 'Update' : 'Submit'}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddCustomerGroups;
