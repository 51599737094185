import React, { useEffect, useState, useContext } from "react";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { SecondaryButton } from "../../../Core/FormInput/index";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import AppContext from "../../../App/AppContext";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AddFailureNoteModal from "./AddUpdateModal";
import { getFailureCodes } from "../../../Core/Service/failureCode-service";
import DeleteFailureNotesModal from "./DeleteFailureNotes";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DataGridComp from "../../../Core/Grid";

const FailureNotes = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        showDel: false,
        FailureNotesDetails: [],
    });
    const { showToast } = useContext(AppContext);

    useEffect(async () => {
        pullFailureNotesAndUpdateState();
    }, []);

    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                FailureNotesDetails: params.value.row,
            };
            return nst;
        });
    };
    const handleDeleteWebForm = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                showDel: true,
                FailureNotesDetails: params.value.row,
            };
            return nst;
        });
    };

    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Failure Note" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Failure Note" aria-label="add">
                    <IconButton size="small" onClick={() => handleDeleteWebForm(params)}>
                        <DeleteOutlineIcon className="SetButtonColor" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const column_new = [
        {
            field: "id",
            headerName: "ID",
            width: 3,
            sortable: false,
            hide: true,
        },
        {
            field: "",
            headerName: "Action",
            sortable: false,
            width: 110,

            renderCell: (params) => <Arrow value={params} />,
        },
        { field: "name", headerName: "Description", flex: 2 },
    ];

    const pullFailureNotesAndUpdateState = async () => {
        let result = await getFailureCodes();
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data.map((w) => ({
                    id: w.failureCode,
                    name: w.failureDescription,
                })),
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };
    const modalClosed = (refreshGrid) => {
        setState((st) => ({ ...st, show: false, showDel: false }));
        refreshGrid && pullFailureNotesAndUpdateState();
    };
    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                FailureNotesDetails: null,
            };
        });
    };
    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Failure Notes available</div>
            </GridOverlay>
        );
    }
    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                    Add Failure Notes
                </SecondaryButton>
            </div>
            {state.show ? <AddFailureNoteModal onFormSubmit={modalClosed} FailureNotesDetails={state.FailureNotesDetails} /> : null}
            {state.showDel ? <DeleteFailureNotesModal onFormSubmit={modalClosed} FailureNotesDetails={state.FailureNotesDetails} /> : null}
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={"/"}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: "0.85rem" }}>
                    Failure Notes
                </Typography>
            </Breadcrumbs>
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default FailureNotes;
