import { FormLabel, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { DialogContents, DialogsActions, SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { useEffect } from 'react';
import { getCashAccountCategories, setCashAccountCategories, setCashAccountTypes } from '../../../../Core/Service/CashAccount-service';
import AppContext from '../../../../App/AppContext';
import { useContext } from 'react';
import { SelectBox } from '../../../../Core/FormInput';

const AddEditCashAccountTypes = (props) => {

    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({
        cashAccountTypeID: null,
        cashAccountTypeCategoryID: null,
        cashAccountTypeDescription: '',
        cashAccountTypeAccountCodes: '',
        cashAccountTypeSortOrder: '',

        categories: []
    });

    const handlePageLoad = async () => {
        setState((st) => {
            return {
                ...st,
                cashAccountTypeID: props?.focusedRow?.id ? props?.focusedRow?.id : null,
                cashAccountTypeCategoryID: props?.focusedRow?.cashAccountTypeCategoryID ? props?.focusedRow?.cashAccountTypeCategoryID : null,
                cashAccountTypeDescription: props?.focusedRow?.cashAccountTypeDescription ? props?.focusedRow?.cashAccountTypeDescription : null,
                cashAccountTypeAccountCodes: props?.focusedRow?.cashAccountTypeAccountCodes ? props?.focusedRow?.cashAccountTypeAccountCodes : null,
                cashAccountTypeSortOrder: props?.focusedRow?.cashAccountTypeSortOrder ? props?.focusedRow?.cashAccountTypeSortOrder : null
            };
        });

        const res = await getCashAccountCategories()
        const categories = res.data.map((e) => {
            return {
                id: e.cashAccountCategoryID,
                name: e.cashAccountCategoryDescription
            }
        });
        if (res.success) {
            setState((st) => {
                return {
                    ...st,
                    categories: categories
                };
            });
        }
    }

    useEffect(() => {
        handlePageLoad()
    }, [props.focusedRow])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return {
                ...st,
                [name]: value
            };
        });
    }

    const handleSubmit = async () => {
        const data = {
            cashAccountTypeID: state.cashAccountTypeID ? state.cashAccountTypeID : null,
            cashAccountTypeCategoryID: state.cashAccountTypeCategoryID,
            cashAccountTypeDescription: state.cashAccountTypeDescription,
            cashAccountTypeAccountCodes: state.cashAccountTypeAccountCodes,
            cashAccountTypeSortOrder: state.cashAccountTypeSortOrder
        }
        const res = await setCashAccountTypes(data)

        if (res.success) {
            showToast('Succesfully Updated Cash Account Type')
            props.onClose(true);
        } else {
            showToast('Failed to Update Cash Account Type')
        }
    }

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} >
                    <FormLabel shrink style={{ marginBottom: '10px' }}>Category</FormLabel>
                    <SelectBox
                        onChange={handleInputChange}
                        value={state.cashAccountTypeCategoryID}
                        name="cashAccountTypeCategoryID"
                        List={state.categories || []}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel component="legend">
                        Description
                    </FormLabel>
                    <TextBox name="cashAccountTypeDescription" onChange={handleInputChange} value={state.cashAccountTypeDescription} autoFocus={true} />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel component="legend">
                        Account Codes
                    </FormLabel>
                    <TextBox name="cashAccountTypeAccountCodes" onChange={handleInputChange} value={state.cashAccountTypeAccountCodes} />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel component="legend">
                        Sort Order
                    </FormLabel>
                    <TextBox name="cashAccountTypeSortOrder" onChange={handleInputChange} value={state.cashAccountTypeSortOrder} />
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};
export default AddEditCashAccountTypes;
