import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import moment from 'moment';
import { PrimaryButton, TextBox } from '../../../../Core/FormInput';
import { addReportingWIPLogs, getReportingWIPLogs } from '../../../../Core/Service/ReportScreens-service';
import '../ReportingNotesScreen/reportingNotes.scss';

const JobSingleNote = (props) => {
    return (
        <div className="single-note">
            <div className="msg">{props.note.notes}</div>
            <div className="footer">
                {props.note.userName} @ {moment(props.note.created).format('LLL')}
            </div>
        </div>
    );
};

const ReportingWipLogsModal = (props) => {
    const [state, setState] = useState({
        loading: false,
        notes: [],
        newNote: ''
    });

    const notesContainer = useRef(null);
    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const setNewNote = (e) => {
        const newNote = e.target.value;
        setState((st) => ({ ...st, newNote }));
    };

    const addNotesToServer = () => {
        let data = {
            reportingWIPLogHeaderID: props.hid,
            notes: state.newNote
        };

        addReportingWIPLogs(data).then((res) => {
            if (res.success) {
                setState((st) => ({ ...st, newNote: '' }));
                getJobNotesAndSaveToState();
                props.onClose(true);
            }
        });
    };

    const getJobNotesAndSaveToState = async () => {
        setState((st) => ({ ...st, loading: true }));
        getReportingWIPLogs(props.hid)
            .then((res) => {
                if (res.success) {
                    setState((st) => ({ ...st, notes: res.data.list }));
                }
                notesContainer.current && (notesContainer.current.scrollTop = 0);
            })
            .finally(() => {
                setState((st) => ({ ...st, loading: false }));
            });
    };

    useEffect(() => {
        getJobNotesAndSaveToState();
    }, [props.hid]);

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="md" className="dialog-custom reporting-notes-modal">
            <DialogTitle>
                Reporting Log ({props.wipNo})
                <div className="title-header-actions">
                    <PrimaryButton onClick={addNotesToServer} disabled={state.newNote.length === 0}>
                        Add Notes
                    </PrimaryButton>
                    <span className="close-icon" onClick={handleClose}>
                        &times;
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <TextBox label="New Note" required={false} onChange={setNewNote} value={state.newNote} autoFocus={true} />
                <div className="notes-container custom-scroll" ref={notesContainer}>
                    {state.loading && <label>Loading...</label>}
                    {state.notes.map((n, i) => (
                        <JobSingleNote key={i} note={n} />
                    ))}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ReportingWipLogsModal;
