import { useMemo, useState } from 'react';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import { Grid } from '@material-ui/core';
import SendMessageModal from './sendMessageModal';
import ApproveJobModal from './approveJobModal';
import moment from 'moment';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import ReactDOM from 'react-dom';
import { CustomIconButton, SquareButton } from '../../../Core/FormInput/AppButton';
import ReportGijgoGrid from '../../internal/ReportsScreens/GijgoForReportsScreen';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DoneIcon from '@material-ui/icons/Done';
import { AppStorage } from '../../../Core/Service/storage-service';

const baseColumns = [
    {
        title: 'Action',
        field: 'action',
        width: 70,
        filterable: false
    },
    { field: 'wipNumber', sortable: true, title: `${'WIP No'}`, width: 70 },
    { field: 'registration', sortable: true, title: `${'Vehicle Reg'}`, width: 90 },
    { field: 'st', sortable: true, title: `${'Job Description'}`, width: 170 },
    { field: 'makeModel', sortable: true, title: `${'Model'}`, width: 200 },
    {
        field: 'dateDueIn',
        sortable: true,
        title: `${'Due Date In'}`,
        type: 'dateTime',
        width: 135,
        renderer: function (value, record) {
            if (record.dateDueIn != null) {
                return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
            }
            return '-';
        }
    },
    {
        field: 'dateDueOut',
        sortable: true,
        title: `${'Date Due Out'}`,
        type: 'dateTime',
        width: 135,
        renderer: function (value, record) {
            if (record.dateDueOut != null) {
                return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
            }
            return '-';
        }
    },
    { field: 'branchShortName', sortable: true, title: `${'Branch'}`, width: 120 },
    {
        field: 'status',
        sortable: true,
        title: `${'Status'}`,
        width: 100
    }
];

const crumbs = (menuPermissionCounter) => {
    let list = [{ name: 'Home', path: '/' }];

    if (menuPermissionCounter !== 1) {
        list.push({ name: 'Communicator', path: '/communicator_dash' });
    }

    list.push({ name: 'Awaiting Authority', active: true });

    return list;
};

const CustAwaitingAuthority = (props) => {
    let menuPermissionCounter = AppStorage.menuPermissionCounter();

    const [show, setShow] = useState({
        showSendMessageModal: false,
        awaitingAuthModal: false
    });

    const HandelSendMessage = (name, val) => {
        setShow((st) => ({
            ...st,
            [name]: true,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" spacing={1} justify="center">
                <Grid item>
                    <CustomIconButton onClick={() => HandelSendMessage('showSendMessageModal', record)} toolTipTitle="Send Message">
                        <ListAltIcon fontSize="medium" style={{ color: '#183B68' }} />
                    </CustomIconButton>
                </Grid>
                <Grid item>
                    <CustomIconButton onClick={() => HandelSendMessage('awaitingAuthModal', record)} toolTipTitle="Approve Job">
                        <DoneIcon fontSize="medium" style={{ color: '#183B68' }} />
                    </CustomIconButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');
        if (colAction) {
            colAction.renderer = editButton;
        }
        return col;
    }, []);

    const handleClose = (res) => {
        let newSt = {};
        newSt.showSendMessageModal = false;
        newSt.awaitingAuthModal = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setShow((st) => ({
            ...st,
            ...newSt
        }));
    };

    const getUrl = `CustUserCommunicator/AwaitingAuthority`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(menuPermissionCounter)} />
            <Grid container>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={column}
                        getUrl={getUrl}
                        displayPagination={true}
                        isReload={show.isReload}
                    />
                </Grid>
            </Grid>

            {show.showSendMessageModal ? (
                <DialogComp title={`Send Message (WIP : ${show.wip})`} onClose={() => handleClose(false)} fullWidth maxWidth="lg">
                    <SendMessageModal onClose={handleClose} headerRecordID={show.headerRecordID} reg={show.reg} wip={show.wip} />
                </DialogComp>
            ) : null}
            {show.awaitingAuthModal ? (
                <DialogComp title={`Approved Job (WIP : ${show.wip})`} onClose={() => handleClose(false)} fullWidth maxWidth="sm">
                    <ApproveJobModal onClose={handleClose} headerRecordID={show.headerRecordID} reg={show.reg} wip={show.wip} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default CustAwaitingAuthority;
