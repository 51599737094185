import { CircularProgress, Grid, Typography, makeStyles, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getSalesNewRegistration } from '../../../../Core/Service/groupDashboard-services';
import formatters from '../../../../Core/Grid/inputFormatter';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';

const COLORS = {
    0: '#0074D9',
    1: '#FF4136',
    2: '#2ECC40',
    3: '#FF851B',
    4: '#7FDBFF',
    5: '#B10DC9',
    6: '#FFDC00',
    7: '#C33C9A',
    8: '#39CCCC',
    9: '#01FF70',
    10: '#85144b',
    11: '#F012BE',
    12: '#3D9970',
    13: '#111111',
    14: '#AAAAAA'
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ background: 'rgba(0,0,0,0.8)', fontSize: 10, padding: 10 }}>
                <p style={{ color: 'white' }}>
                    <b>{`${label}`}</b>
                </p>
                {/* <p className="intro">{getIntroOfPage(label)}</p> */}
                {payload.map((n, i) => {
                    return (
                        <p key={i} style={{ color: COLORS[i] }}>
                            {n.name}&nbsp;:&nbsp;&nbsp;<b>{formatters.CurrencyThousandSeparatorWithoutZero(n.value)}</b>
                        </p>
                    );
                })}
            </div>
        );
    }

    return null;
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#5c5c5c',
        color: 'white',
        padding: 9,
        border: 'none',
        fontWeight: 600
    },
    body: {
        fontSize: 14,
        border: 'none',
        padding: 9,
        borderBottom: '1px solid rgba(0,0,0,0.009)'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0,0,0,0.009)'
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white'
        }
    }
}))(TableRow);

const StyledTableRowGrey = withStyles((theme) => ({
    root: {
        backgroundColor: 'lightGrey'
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        overflow: 'auto',
        minWidth: '1400px',
        border: '1px solid rgba(0,0,0,0.002)'
    },
    surChargetable: {
        overflow: 'auto',
        minWidth: '100%',
        border: '1px solid rgba(0,0,0,0.002)'
    }
});
let DateList = [
    { name: 'Jan-23', val: 'jan' },
    { name: 'Feb-23', val: 'feb' },
    { name: 'Mar-23', val: 'mar' },
    { name: 'Apr-23', val: 'apr' },
    { name: 'May-23', val: 'may' },
    { name: 'Jun-23', val: 'jun' },
    { name: 'Jul-23', val: 'jul' },
    { name: 'Aug-23', val: 'aug' },
    { name: 'Sep-23', val: 'sep' },
    { name: 'Oct-23', val: 'oct' },
    { name: 'Nov-23', val: 'nov' },
    { name: 'Dec-23', val: 'dec' }
];

const SalesNewRegistration = (props) => {
    const [width, height] = useWindowSize();
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const lable = queryParameters.get('label');
    const type = queryParameters.get('type');
    const history = useHistory();

    const handleRedirect = (MonthID, model) => {
        let start = '';
        let end = '';
        if (MonthID === 'year') {
            start = moment().startOf('year').format('YYYY-MM-DD');
            end = moment().endOf('year').format('YYYY-MM-DD');
        } else {
            start = moment().month(MonthID).startOf('month').format('YYYY-MM-DD');
            end = moment().month(MonthID).endOf('month').format('YYYY-MM-DD');
        }
        let searchQuery = `?startDate=${start}&endDate=${end}&type=${type}&model=${model}`;
        history.push({
            pathname: `/sales/RegistrationReports`,
            search: searchQuery
        });
    };

    const [state, setState] = useState({
        ScreenLabel: lable,
        typeId: type,
        ResList: [],
        fllteredList: [],
        totalList: [],
        graphData: [],
        GrapgData: [],
        showLoader: true
    });

    useEffect(async () => {
        let res = await getSalesNewRegistration(type);
        if (res.success) {
            setState((st) => ({ ...st, showLoader: false, ResList: res?.data?.list.map((m) => ({ ...m, ytdProfit: 0, ytdUnit: 0 })) }));
        }
    }, [type]);

    useEffect(async () => {
        let List = [];
        List = state?.ResList?.reduce((acc, obj) => {
            var index = acc.find((item) => item.ad === obj.ad);
            if (index) {
                index.janProfit += obj.janProfit;
                index.febProfit += obj.febProfit;
                index.marProfit += obj.marProfit;
                index.aprProfit += obj.aprProfit;
                index.mayProfit += obj.mayProfit;
                index.junProfit += obj.junProfit;
                index.julProfit += obj.julProfit;
                index.augProfit += obj.augProfit;
                index.sepProfit += obj.sepProfit;
                index.octProfit += obj.octProfit;
                index.novProfit += obj.novProfit;
                index.decProfit += obj.decProfit;

                index.janUnit += obj.janUnit;
                index.febUnit += obj.febUnit;
                index.marUnit += obj.marUnit;
                index.aprUnit += obj.aprUnit;
                index.mayUnit += obj.mayUnit;
                index.junUnit += obj.junUnit;
                index.julUnit += obj.julUnit;
                index.augUnit += obj.augUnit;
                index.sepUnit += obj.sepUnit;
                index.octUnit += obj.octUnit;
                index.novUnit += obj.novUnit;
                index.decUnit += obj.decUnit;
                index.ytdProfit =
                    index.janProfit +
                    index.febProfit +
                    index.marProfit +
                    index.aprProfit +
                    index.mayProfit +
                    index.junProfit +
                    index.julProfit +
                    index.augProfit +
                    index.sepProfit +
                    index.octProfit +
                    index.novProfit +
                    index.decProfit;

                index.ytdUnit =
                    index.janUnit +
                    index.febUnit +
                    index.marUnit +
                    index.aprUnit +
                    index.mayUnit +
                    index.junUnit +
                    index.julUnit +
                    index.augUnit +
                    index.sepUnit +
                    index.octUnit +
                    index.novUnit +
                    index.decUnit;

                return acc;
            }
            acc.push({ ...obj });
            obj.ytdProfit =
                obj.janProfit +
                obj.febProfit +
                obj.marProfit +
                obj.aprProfit +
                obj.mayProfit +
                obj.junProfit +
                obj.julProfit +
                obj.augProfit +
                obj.sepProfit +
                obj.octProfit +
                obj.novProfit +
                obj.decProfit;
            obj.ytdUnit =
                obj.janUnit +
                obj.febUnit +
                obj.marUnit +
                obj.aprUnit +
                obj.mayUnit +
                obj.junUnit +
                obj.julUnit +
                obj.augUnit +
                obj.sepUnit +
                obj.octUnit +
                obj.novUnit +
                obj.decUnit;
            return acc;
        }, []);
        let totalList = List?.reduce((acc, obj) => {
            var index = acc.find((item) => item);
            if (index) {
                index.janProfit += obj.janProfit;
                index.febProfit += obj.febProfit;
                index.marProfit += obj.marProfit;
                index.aprProfit += obj.aprProfit;
                index.mayProfit += obj.mayProfit;
                index.junProfit += obj.junProfit;
                index.julProfit += obj.julProfit;
                index.augProfit += obj.augProfit;
                index.sepProfit += obj.sepProfit;
                index.octProfit += obj.octProfit;
                index.novProfit += obj.novProfit;
                index.decProfit += obj.decProfit;

                index.janUnit += obj.janUnit;
                index.febUnit += obj.febUnit;
                index.marUnit += obj.marUnit;
                index.aprUnit += obj.aprUnit;
                index.mayUnit += obj.mayUnit;
                index.junUnit += obj.junUnit;
                index.julUnit += obj.julUnit;
                index.augUnit += obj.augUnit;
                index.sepUnit += obj.sepUnit;
                index.octUnit += obj.octUnit;
                index.novUnit += obj.novUnit;
                index.decUnit += obj.decUnit;
                index.ytdProfit =
                    index.janProfit +
                    index.febProfit +
                    index.marProfit +
                    index.aprProfit +
                    index.mayProfit +
                    index.junProfit +
                    index.julProfit +
                    index.augProfit +
                    index.sepProfit +
                    index.octProfit +
                    index.novProfit +
                    index.decProfit;

                index.ytdUnit =
                    index.janUnit +
                    index.febUnit +
                    index.marUnit +
                    index.aprUnit +
                    index.mayUnit +
                    index.junUnit +
                    index.julUnit +
                    index.augUnit +
                    index.sepUnit +
                    index.octUnit +
                    index.novUnit +
                    index.decUnit;
                return acc;
            }
            acc.push({ ...obj });
            obj.ytdProfit =
                obj.janProfit +
                obj.febProfit +
                obj.marProfit +
                obj.aprProfit +
                obj.mayProfit +
                obj.junProfit +
                obj.julProfit +
                obj.augProfit +
                obj.sepProfit +
                obj.octProfit +
                obj.novProfit +
                obj.decProfit;
            obj.ytdUnit =
                obj.janUnit +
                obj.febUnit +
                obj.marUnit +
                obj.aprUnit +
                obj.mayUnit +
                obj.junUnit +
                obj.julUnit +
                obj.augUnit +
                obj.sepUnit +
                obj.octUnit +
                obj.novUnit +
                obj.decUnit;
            return acc;
        }, []);

        setState((st) => ({ ...st, fllteredList: List || [], totalList: totalList || [] }));
    }, [state.ResList]);

    useEffect(() => {
        let GrapgData = [];
        DateList.forEach((n) => {
            state.fllteredList.forEach((k) => {
                let index = GrapgData.findIndex((m) => n.name === m.name);
                if (index > -1) {
                    GrapgData[index][k.ad] = k[`${n.val}Profit`];
                } else {
                    GrapgData.push({
                        name: n.name,
                        [k.ad]: k[`${n.val}Profit`]
                    });
                }
            });
        });

        setState((st) => ({ ...st, GrapgData: GrapgData || [] }));
    }, [state.fllteredList]);

    const classes = useStyles();
    return (
        <div style={{ padding: 10 }}>
            {state.showLoader ? (
                <Grid
                    container
                    style={{
                        // padding: '10px 0px',
                        height: 'calc(100vh - 105px)',
                        overflow: 'auto'
                    }}
                    justifyContent="center"
                    className="salesBudgetScreen"
                    alignItems="center"
                >
                    <CircularProgress color="secondary" />
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: 'left',
                                marginLeft: 10,
                                marginBottom: width >= 600 ? 20 : 0,
                                fontWeight: 600,
                                fontSize: width >= 600 ? 24 : 18
                            }}
                        >
                            {state.ScreenLabel}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart
                                width={500}
                                height={300}
                                data={state.GrapgData}
                                margin={{
                                    top: 5,
                                    right: 20,
                                    left: width >= 600 ? 10 : -10,
                                    bottom: 5
                                }}
                                // margin={{
                                //     top: 5,
                                //     right: 20,
                                //     left: 10,
                                //     bottom: 5
                                // }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" style={{ fontSize: width >= 600 ? 12 : 10 }} />
                                <YAxis style={{ fontSize: width >= 600 ? 12 : 10 }} />
                                <Tooltip content={<CustomTooltip />} />
                                {/* <Tooltip /> */}
                                <Legend iconType="square" style={{ fontSize: 8 }} />
                                {state.fllteredList.map((n, i) => {
                                    return <Line type="monotone" dataKey={n.ad} stroke={COLORS[i]} strokeWidth={2} />;
                                })}
                            </LineChart>
                        </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table className={classes.surChargetable} stickyHeader aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">
                                            <b></b>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">Jan-23</StyledTableCell>
                                        <StyledTableCell align="right">Feb-23</StyledTableCell>
                                        <StyledTableCell align="right">Mar-23</StyledTableCell>
                                        <StyledTableCell align="right">Apr-23</StyledTableCell>
                                        <StyledTableCell align="right">May-23</StyledTableCell>
                                        <StyledTableCell align="right">Jun-23</StyledTableCell>
                                        <StyledTableCell align="right">Jul-23</StyledTableCell>
                                        <StyledTableCell align="right">Aug-23</StyledTableCell>
                                        <StyledTableCell align="right">Sep-23</StyledTableCell>
                                        <StyledTableCell align="right">Oct-23</StyledTableCell>
                                        <StyledTableCell align="right">Nov-23</StyledTableCell>
                                        <StyledTableCell align="right">Dec-23</StyledTableCell>
                                        <StyledTableCell align="right">YTD-23</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.fllteredList.length > 0 ? (
                                        state.fllteredList.map((m, i) => {
                                            return (
                                                <StyledTableRow key={i} style={{ cursor: 'pointer' }}>
                                                    <StyledTableCell onClick={() => handleRedirect('year', m.ac)}>
                                                        <b>{m.ad}</b>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() < 0 ? 'disabled' : 'pointer',
                                                            background: moment().month() < 0 ? 'grey' : '',
                                                            color: moment().month() < 0 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(0, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.janProfit)} / (<small>{m.janUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() < 1 ? 'disabled' : 'pointer',
                                                            background: moment().month() < 1 ? 'grey' : '',
                                                            color: moment().month() < 1 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(1, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.febProfit)} / (<small>{m.febUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() < 2 ? 'disabled' : 'pointer',
                                                            background: moment().month() < 2 ? 'grey' : '',
                                                            color: moment().month() < 2 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(2, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.marProfit)} / (<small>{m.marUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() < 3 ? 'disabled' : 'pointer',
                                                            background: moment().month() < 3 ? 'grey' : '',
                                                            color: moment().month() < 3 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(3, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.aprProfit)} / (<small>{m.aprUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() < 4 ? 'disabled' : 'pointer',
                                                            background: moment().month() < 4 ? 'grey' : '',
                                                            color: moment().month() < 4 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(4, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.mayProfit)} / (<small>{m.mayUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() < 5 ? 'disabled' : 'pointer',
                                                            background: moment().month() < 5 ? 'grey' : '',
                                                            color: moment().month() < 5 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(5, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.junProfit)} / (<small>{m.junUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() < 6 ? 'disabled' : 'pointer',
                                                            background: moment().month() < 6 ? 'grey' : '',
                                                            color: moment().month() < 6 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(6, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.julProfit)} / (<small>{m.julUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() < 7 ? 'disabled' : 'pointer',
                                                            background: moment().month() < 7 ? 'grey' : '',
                                                            color: moment().month() < 7 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(7, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.augProfit)} / (<small>{m.augUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() < 8 ? 'disabled' : 'pointer',
                                                            background: moment().month() < 8 ? 'grey' : '',
                                                            color: moment().month() < 8 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(8, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.sepProfit)} / (<small>{m.sepUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() < 9 ? 'disabled' : 'pointer',
                                                            background: moment().month() < 9 ? 'grey' : '',
                                                            color: moment().month() < 9 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(9, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.octProfit)} / (<small>{m.octUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() < 10 ? 'disabled' : 'pointer',
                                                            background: moment().month() < 10 ? 'grey' : '',
                                                            color: moment().month() < 10 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(10, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.novProfit)} / (<small>{m.novUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        style={{
                                                            cursor: moment().month() <= 11 ? 'disabled' : 'pointer',
                                                            background: moment().month() <= 11 ? 'grey' : '',
                                                            color: moment().month() <= 11 ? 'white' : ''
                                                        }}
                                                        onClick={() => handleRedirect(11, m.ac)}
                                                    >
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.decProfit)} / (<small>{m.decUnit}</small>)
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right" onClick={() => handleRedirect(11, m.ac)}>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.ytdProfit)} / (<small>{m.ytdUnit}</small>)
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })
                                    ) : (
                                        <StyledTableRow key={'test-used'}>
                                            <StyledTableCell align="center" colSpan={13} style={{ color: 'red' }}>
                                                No Records Found
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}

                                    {state.totalList?.map((m, i) => {
                                        return (
                                            <StyledTableRowGrey>
                                                <StyledTableCell align="left">
                                                    <div>
                                                        <b>Total</b>
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.janProfit)} / (<small>{m.janUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.febProfit)} / (<small>{m.febUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.marProfit)} / (<small>{m.marUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.aprProfit)} / (<small>{m.aprUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.mayProfit)} / (<small>{m.mayUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.junProfit)} / (<small>{m.junUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.julProfit)} / (<small>{m.julUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.augProfit)} / (<small>{m.augUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.sepProfit)} / (<small>{m.sepUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.octProfit)} / (<small>{m.octUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.novProfit)} / (<small>{m.novUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.decProfit)} / (<small>{m.decUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <b>
                                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.ytdProfit)} / (<small>{m.ytdUnit}</small>)
                                                    </b>
                                                </StyledTableCell>
                                            </StyledTableRowGrey>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default SalesNewRegistration;
