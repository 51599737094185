import { useContext } from 'react';
import AppContext from '../../App/AppContext';
import PasswordChange from './PasswordChange';
import DeleteModal from './DeleteModal';
import ServiceAdvCust from './ServiceAdvCust';
import ForgotPassword from './ForgotPassword';
import ExpiryResetPasswordModal from './ExpiryPasswordReset';

function getModalToShow(visibleModalId) {
    switch (visibleModalId) {
        case 'PasswordChange':
            return PasswordChange;
        case 'DeleteModal':
            return DeleteModal;
        case 'ServiceAdvCust':
            return ServiceAdvCust;
        case 'ForgotPassword':
            return ForgotPassword;
    }
}
const ProfileModal = (props) => {
    const { visibleModalId } = useContext(AppContext);
    const ModalToShow = getModalToShow(visibleModalId);
    return <>{ModalToShow && <ModalToShow />}</>;
};
export { ProfileModal };
