import { get, post } from './http-calls';
import { memoize } from 'lodash';
import moment from 'moment';

export const getCustomer = memoize(async () => {
    let res = await get('/Customers', { useAuthToken: true });
    return res;
});

export const getCustomerDashboard = memoize(async () => {
    let res = await get('CustomerJobs/Dashboard', { useAuthToken: true });
    return res;
});

const downloadFileWithExt = async (res, fileExt) => {
    if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
        link.id = 'tempDownloadPDFLink';
        link.href = url;
        link.setAttribute('download', `Customers ${moment().format('DD-MMM-YYYY HH:mm')}.${fileExt}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return true;
    }
};

export const getCustomerExcelFile = async (params) => {
    let res = await get(`Customers/GetExcelDownload`, {
        responseType: 'blob',
        returnOrgRes: true,
        useAuthToken: true
    });
    downloadFileWithExt(res, 'xlsx');
};
