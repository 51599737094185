import React, { useMemo } from 'react';
import { SquareButton } from '../../../Core/FormInput/AppButton';
import ReportGijgoGrid from './../../internal/ReportsScreens/GijgoForReportsScreen/index';
import { Grid, Typography } from '@material-ui/core';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
import DiagnosticFaultsModal from './DiagnosticFaultsModal';
import { useState } from 'react';
import DialogComp from './../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';

const baseColumns = [
    { field: 'action', sortable: false, title: ``, width: 70, filterable: false },

    { field: 'name', sortable: false, title: `Name` },
    { field: 'color', sortable: false, title: `Color` },
    {
        field: 'icon',
        sortable: false,
        title: `Icon`,
        width: 150
    }
];

const baseUrl = 'DiagnosticFaults';

const crumbs = (path) => [
    { name: 'Home', path: '' },
    { name: 'Diagnostic Sheets', active: true },
    { name: 'Faults', active: true }
];

const DiagnosticFault = () => {
    const [state, setState] = useState({
        showModal: false
    });

    const handleClose = () => {
        setState((st) => ({
            ...st,
            showModal: false,
            isReload: new Date()
        }));
    };

    const handleEdit = (val) => {
        setState((st) => ({
            ...st,
            showModal: true,
            id: val.id
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => handleEdit(record)}
                >
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const RenderIconImage = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                {value ? (
                    <img
                        src={value}
                        style={{
                            height: 30,
                            width: 25
                        }}
                    />
                ) : (
                    'No Icon Found'
                )}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');

        let renderImg = col.find((m) => m.field === 'icon');
        if (colAction) {
            colAction.renderer = editButton;
        }
        if (renderImg) {
            renderImg.renderer = RenderIconImage;
        }
        return col;
    }, []);
    return (
        <div className="report-screen-container">
            {state.showModal ? (
                <DialogComp
                    title={`${state.id ? 'Update Diagnostic Faults' : 'Add Diagnostic Faults'}`}
                    maxWidth="sm"
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showModal: false
                        }))
                    }
                    fullWidth
                >
                    <DiagnosticFaultsModal onClose={handleClose} id={state.id} />
                </DialogComp>
            ) : null}
            <BreadCrumbs crumbs={crumbs()} />
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res?.data?.total
                })}
                columns={column}
                getUrl={baseUrl}
                isReload={state.isReload}
                isShowTotal={false}
            />
        </div>
    );
};

export default DiagnosticFault;
