import { FormLabel, Grid, MenuItem } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox } from '../../../Core/FormInput';
import { DefaultButton } from '../../../Core/FormInput';
import { SecondaryButton } from '../../../Core/FormInput';
import { addUpdateVorReason, addUpdateVorType } from '../../../Core/Service/vor-service';

const AddEditVorReason = (props) => {
    const [state, setState] = useState({
        errors: {},

        vorReasonDescription: props.focusedRow?.vorReasonDescription ? props.focusedRow.vorReasonDescription : '',
        vorReasonID: props.focusedRow?.vorReasonID ? props.focusedRow.vorReasonID : null
    });

    const { showToast } = useContext(AppContext);

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        setState((st) => ({
            ...st,
            [name]: val
        }))
    }

    const validations = () => {
        const {
            vorReasonDescription
        } = state;
        let formIsValid = true;
        let errors = {};

        if (vorReasonDescription == null || vorReasonDescription == '') {
            errors.vorReasonDescription = 'Description field is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            const data = {
                vorReasonDescription: state.vorReasonDescription,
                VORReasonTypeID: props.VORReasonTypeID,
                vorReasonID: state.vorReasonID,
            }
            let res = await addUpdateVorReason(data);
            if (!res.success) {
                console.error(res);
                showToast("Error occured.")
            } else {
                showToast("Vor reason updated successfully")
                props.onClose()
            }
        }
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel required component="legend">Description</FormLabel>
                    <TextBox value={state.vorReasonDescription} name="vorReasonDescription" onChange={handleFieldChange} />
                    <span className="mandatoryfields">{state.errors['vorReasonDescription']}</span>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
}
export default AddEditVorReason;
