import { get, post, put } from './http-calls';

export const getAppraisalDropdown = async () => {
    let res = await get(`Appraisal/ScreenDropdown`, { useAuthToken: true });
    return res;
};

export const postAppraisal = async (data) => {
    let resData = {
        appraisalRecordId: data.appraisalRecordId || null,
        customerDetails: data.customerDetails || {},
        vehicleDetails: data.vehicleDetails || {},
        serviceHistory: data.serviceHistory || {},
        damageFaces: data.damageFaces || {},
        vehiclePhotos: data.vehiclePhotos || {},
        vehicleAppraisalDetails: data.vehicleAppraisalDetails || {},
        signatures: data.signatures || {}
    };
    let res = await post(`Appraisal`, resData, { useAuthToken: true });
    return res;
};

export const getAppraisalByRef = async (id) => {
    let res = await get(`Appraisal/GetAppraisalDataByRefNo?Id=${id}`, { useAuthToken: true });
    return res;
};

export const getAppraisalPhotosByRef = async (id) => {
    let res = await get(`Appraisal/GetAppraisalPhotosByRefNo?Id=${id}`, { useAuthToken: true });
    return res;
};

export const getAppraisalDropdownsResolveData = async () => {
    return (await getAppraisalDropdown()).data;
};

export const getAppraisalScreen = async (ScreenId, vehTypeI) => {
    let res = await get(`AppraisalScreen/GetColumns?ScreenId=${ScreenId}&vehTypeI=${vehTypeI}`, { useAuthToken: true });
    return res;
};

export const getAppraisalDashboard = async () => {
    let res = await get(`Appraisal/Dashboard`, { useAuthToken: true });
    return res;
};

export const getAppraisalStesps = async (pageName) => {
    let res = await get(`AppraisalScreen/GetPageSteps?pageName=${pageName}`, { useAuthToken: true });
    return res;
};
export const getStockbookList = async (stock) => {
    let res = await get(`AppraisalMasterData/SearchUsedStockVehicle/${stock}`, { useAuthToken: true });
    return res;
};
export const getDamageImages = async (Url) => {
    let res = await get(`${Url}`, { useAuthToken: true });
    return res;
};
export const getApiData = async (Url) => {
    let res = await get(`${Url}`, { useAuthToken: true });
    return res;
};

let otherConfig = {};
export let setFormGlobalConfig = (obj, append = false) => {
    if (append) {
        otherConfig = { ...otherConfig, ...obj };
    } else {
        otherConfig = { ...obj };
    }
};

export let getFormGlobalConfig = () => otherConfig;

export let calculateReadonly = (fieldConfig, formConfig) => {
    return formConfig.allReadonly ? true : formConfig.editMode ? fieldConfig.screenColumnEditReadonly : fieldConfig.screenColumnReadonly;
};
