import { Checkbox, FormControlLabel, FormLabel, Grid, InputLabel, TextField } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import AppContext from '../../../../App/AppContext';
import { SecondaryButton, SelectBox, TextBox } from '../../../../Core/FormInput';
import {
    addBranchStatusEmails,
    editBranchStatusEmails,
    getBranchInvoiceStatusDropdown,
    getBranchStatusEmailsById,
    getBranchStatusEmailsDropdown
} from '../../../../Core/Service/InvoiceScreen-service';
import { singleEmailValidation } from '../../../../Core/Service/StaticService';

const EditBranchStatusEmail = (props) => {
    const [state, setState] = useState({
        branchInvoice: [],
        branchID: props.branchID,
        errors: {},
        resData: {},
        branchName: '',
        invoiceStatus: '',
        email: '',
        emailContext: '',
        isActive: false,
        branchList: [],
        invoiceStatusList: []
    });

    const { showToast, showModal } = useContext(AppContext);

    const pullData = async () => {
        let res = await getBranchStatusEmailsById(props.InvoiceStatusId, props.BranchID);
        setState((st) => ({
            ...st,
            branchName: res.data?.list?.table[0]?.branchName,
            invoiceStatus: res.data?.list?.table[0]?.invoiceStatus,
            email: res.data?.list?.table[0]?.email,
            emailContext: res.data?.list?.table[0]?.emailContext,
            isActive: res.data?.list?.table[0]?.isActive || false
        }));
    };

    useEffect(async () => {
        let dropDownRes = await getBranchStatusEmailsDropdown();
        setState((st) => ({
            ...st,
            branchList: dropDownRes.data.branchList,
            invoiceStatusList: dropDownRes.data.invoiceStatusList
        }));
        if (props.InvoiceStatusId && props.BranchID) {
            pullData();
        }
    }, [props.InvoiceStatusId, props.BranchID]);

    // useEffect(async () => {
    //     if (state.branchID) {
    //         let dropDownRes = await getBranchInvoiceStatusDropdown(state.branchID);
    //         console.log(dropDownRes, 'dropDownRes');
    //     }
    // }, [state.branchID]);

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            let newSt = { ...st };
            newSt[name] = value;
            return newSt;
        });
    };

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = checked;
            return nst;
        });
    };

    const validations = () => {
        const { email } = state;
        let formIsValid = true;
        let errors = {};

        if (!singleEmailValidation(email)) {
            errors.email = 'Invalid Email Id';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            let data = {
                branchID: props.BranchID || state.branchID,
                invoiceStatusId: props.InvoiceStatusId || state.invoiceStatusId,
                isActive: state.isActive,
                email: state.email,
                emailContext: state.emailContext
            };
            if (props.InvoiceStatusId && props.BranchID) {
                let res = await editBranchStatusEmails(data);
                if (res.success) {
                    showToast(`${res.message}`);
                    props.onClose(true);
                } else {
                    console.error(res.message);
                }
            } else {
                let res = await addBranchStatusEmails(data);
                if (res.success) {
                    showToast(`${res.message}`);
                    props.onClose(true);
                } else {
                    console.error(res.message);
                }
            }
        }
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                {props.InvoiceStatusId && props.BranchID ? (
                    <Grid container>
                        <Grid item xs={12}>
                            {`Branch Name`}
                        </Grid>
                        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                            {`${state.branchName}`}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel shrink>Branch</FormLabel>
                        </Grid>
                        <SelectBox onChange={handleFieldChange} value={state.branchID} name="branchID" List={state.branchList || []} />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={6}>
                {props.InvoiceStatusId && props.BranchID ? (
                    <Grid container>
                        <Grid item xs={12}>
                            {`Invoice Status`}
                        </Grid>
                        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                            {`${state.invoiceStatus}`}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel shrink>Status</FormLabel>
                        </Grid>
                        <SelectBox onChange={handleFieldChange} value={state.invoiceStatusId} name="invoiceStatusId" List={state.invoiceStatusList || []} />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                Email
                <TextBox autoFocus name="email" onChange={fieldChange} value={state.email} />
                <span className="mandatoryfields">{state.errors['email']}</span>
            </Grid>
            <Grid item xs={12}>
                Email Body
                <TextBox rows={4} multiline autoFocus name="emailContext" onChange={fieldChange} value={state.emailContext} />
                <span className="mandatoryfields">{state.errors['emailContext']}</span>
            </Grid>
            {props.InvoiceStatusId && props.BranchID && (
                <Grid xs={12} className="mandatoryfields">
                    <FormControlLabel control={<Checkbox checked={state.isActive} onChange={handleCheckbox} name="isActive" />} label="Active?" />
                </Grid>
            )}
            <Grid item xs={12}>
                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                    Save
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default EditBranchStatusEmail;
