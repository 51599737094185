import { Checkbox, FormControlLabel, FormLabel, Grid, InputLabel } from '@material-ui/core';
import React, { useContext } from 'react';
import { useState } from 'react';
import AppContext from '../../../../App/AppContext';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { addInvoiceCustomer } from '../../../../Core/Service/InvoiceScreen-service';

const InvoiceCustomersAddScreen = () => {
    const { showToast, showModal } = useContext(AppContext);

    const [state, setState] = useState({
        errors: {},
        customerCode: '',
        name: '',
        address1: '',
        address2: '',
        address3: '',
        postcode: '',
        contactName: '',
        contactPhone: '',
        invoiceEmail: '',
        sendInvoiceByEmail: false,
        shortName: '',
        address4: '',
        address5: ''
    });

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            let newSt = { ...st };

            newSt[name] = value;

            return newSt;
        });
    };
    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = checked;
            return nst;
        });
    };

    const validations = (mode) => {
        const { name } = state;
        let formIsValid = true;
        let errors = {};

        if (!name) {
            errors.name = 'Customer Name is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            let data = {
                Id: state.id,
                customerCode: state.customerCode,
                name: state.name,
                address1: state.address1,
                address2: state.address2,
                address3: state.address3,
                postcode: state.postcode,
                contactName: state.contactName,
                contactPhone: state.contactPhone,
                invoiceEmail: state.invoiceEmail,
                sendInvoiceByEmail: state.sendInvoiceByEmail,
                shortName: state.shortName,
                address4: state.address4,
                address5: state.address5
            };
            let res = await addInvoiceCustomer(data);
            if (res.success) {
                showToast(`Customer added with Id ${res.customerId}`);
            } else {
                console.error(res.message);
            }
        }
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Short Name</InputLabel>
                <TextBox autoFocus name="shortName" onChange={fieldChange} value={state.shortName} />
                <span className="mandatoryfields">{state.errors['shortName']}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Account Code</InputLabel>
                <TextBox autoFocus name="customerCode" onChange={fieldChange} value={state.customerCode} />
                <span className="mandatoryfields">{state.errors['customerCode']}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Name</InputLabel>
                <TextBox autoFocus name="name" onChange={fieldChange} value={state.name} />
                <span className="mandatoryfields">{state.errors['name']}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Address 1</InputLabel>
                <TextBox autoFocus name="address1" onChange={fieldChange} value={state.address1} />
                <span className="mandatoryfields">{state.errors['address1']}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Address 2</InputLabel>
                <TextBox autoFocus name="address2" onChange={fieldChange} value={state.address2} />
                <span className="mandatoryfields">{state.errors['address2']}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Address 3</InputLabel>
                <TextBox autoFocus name="address3" onChange={fieldChange} value={state.address3} />
                <span className="mandatoryfields">{state.errors['address3']}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Address 4</InputLabel>
                <TextBox autoFocus name="address4" onChange={fieldChange} value={state.address4} />
                <span className="mandatoryfields">{state.errors['address4']}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Address 5</InputLabel>
                <TextBox autoFocus name="address5" onChange={fieldChange} value={state.address5} />
                <span className="mandatoryfields">{state.errors['address5']}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Postcode</InputLabel>
                <TextBox autoFocus name="postcode" onChange={fieldChange} value={state.postcode} />
                <span className="mandatoryfields">{state.errors['postcode']}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Phone</InputLabel>
                <TextBox autoFocus name="Reason" onChange={fieldChange} value={state.Reason} />
                <span className="mandatoryfields">{state.errors['Reason']}</span>
            </Grid>

            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Contact Name</InputLabel>
                <TextBox autoFocus name="contactName" onChange={fieldChange} value={state.contactName} />
                <span className="mandatoryfields">{state.errors['contactName']}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Contact Phone</InputLabel>
                <TextBox autoFocus name="contactPhone" onChange={fieldChange} value={state.contactPhone} />
                <span className="mandatoryfields">{state.errors['contactPhone']}</span>
            </Grid>

            <Grid item xs={12} sm={6} md={4} className="mandatoryfields">
                <InputLabel shrink>Invoice Email</InputLabel>
                <TextBox autoFocus name="invoiceEmail" onChange={fieldChange} value={state.invoiceEmail} />
                <span className="mandatoryfields">{state.errors['invoiceEmail']}</span>
            </Grid>
            <Grid xs={12} sm={6} md={4} className="mandatoryfields" style={{ paddingTop: '20px' }}>
                <FormControlLabel
                    control={<Checkbox checked={state.sendInvoiceByEmail} onChange={handleCheckbox} name="sendInvoiceByEmail" />}
                    label="Send Invoice by email?"
                />
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                    Add
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default InvoiceCustomersAddScreen;
