import { Grid } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { SecondaryButton } from '../../../Core/FormInput';

const DiagnosticSheetsImageComponent = (props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={2} style={{ height: '100%' }}>
                    {props.ImageList.map((item, i) => (
                        <Grid xs={12} sm={6} md={4} lg={2} xl={2} item key={i} className="image-item" style={{ marginTop: 10 }}>
                            <img src={item.diagnosticSheetImgImage} alt="No images" width="100%" height="150px" />
                            <div className="image-item__btn-wrapper">
                                <SecondaryButton fullWidth onClick={() => props.handleRemoveClick(i)}>
                                    Remove
                                </SecondaryButton>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DiagnosticSheetsImageComponent;
