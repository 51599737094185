import {
    Box,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    Tooltip
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import AppContext from '../../../../App/AppContext';
import { DefaultButton, Dialogs, PrimaryButton, SecondaryButton, SelectBox, TextBox, YesNoButton } from '../../../../Core/FormInput';
import { getDynamicFormFieldsById, saveDynamicFormFields } from '../../../../Core/Service/dynamic-forms-service';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { getWorkflowStepList, getWebForms, getWorkflowDataScreenDropdown } from '../../../../Core/Service/workflowList-service';
import { FieldWrappper } from '../../../../Core/FieldWrapper';
import { SketchPicker } from 'react-color';
import { Backup } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import _uniqueId from 'lodash/uniqueId';
import moment from 'moment';

const useStyles = makeStyles({
    btnContainer: {
        margin: '9px 0px 0px 0px !important',
        height: '38px !important'
    }
});

const AddEditDynamicFormFields = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const classes = useStyles();
    const [state, setState] = useState({
        SteplList: [],
        stepListId: '',
        OcrDocumnetList: [],
        documentName: '',
        WebFormList: [],
        webFormId: null,
        IsEditable: false,
        IsRequired: false,
        IsVisibleAuditor: false,
        IsVisibleCutomer: false,
        IsActive: true,
        Label: '',
        dynamicFormFieldJsonPropertyName: _uniqueId('DyamicForm_'),
        sequence: '',
        stepID: null,
        isTextboxType: false,
        noOption: false,
        issteplist: true,
        errors: {},
        isListType: true,
        isTextboxType: false,
        dynamicFormFieldDisplayType: '',
        dynamicFormFieldIndex: _uniqueId(),
        dynamicFormFieldListId: '',
        dynamicFormFieldLabel: '',
        dynamicFormFieldOcrDocumentId: '',
        dynamicFormFieldType: 1,
        dynamicFormFieldShowUpload: false,
        dynamicFormFieldShowView: false,
        dynamicFormFieldShowEmail: false,
        dynamicFormFieldIsDeleted: false,
        dynamicFormFieldIsRequired: false,
        dynamicFormFieldBackgroundColor: '#FFFFFF',
        dynamicFormFieldTextColor: '#000000',
        dynamicFormFieldTextImage: '',
        dynamicFormFieldTextURL: '',
        dynamicFormFieldSubtitle: ''
    });
    console.log(state.dynamicFormFieldJsonPropertyName, state.dynamicFormFieldIndex);
    useEffect(async () => {
        let res = await getWorkflowDataScreenDropdown();
        if (res.success) {
            setState((st) => ({
                ...st,
                SteplList: res?.data?.workflowSteps,
                OcrDocumnetList: res?.data?.ocrDoc,
                WebFormList: res?.data.webForm,
                dynamicFormList: res?.data?.dynamicForm
            }));
        }

        if (props.dynamicFormFieldId) {
            let res = await getDynamicFormFieldsById(props.dynamicFormFieldId);
            let data = res.data.list.table[0];
            setState((st) => ({
                ...st,
                dynamicFormID: props.DynamicFormID,
                dynamicFormFieldId: props.dynamicFormFieldId || null,
                dynamicFormFieldLabel: data.dynamicFormFieldLabel,
                dynamicFormFieldIndex: data.dynamicFormFieldIndex,
                dynamicFormFieldListId: data.dynamicFormFieldListId,
                dynamicFormFieldIsRequired: data.dynamicFormFieldIsRequired,
                dynamicFormFieldJsonPropertyName: data.dynamicFormFieldJsonPropertyName,
                dynamicFormFieldShowUpload: data.dynamicFormFieldShowUpload,
                dynamicFormFieldShowView: data.dynamicFormFieldShowView,
                dynamicFormFieldShowEmail: data.dynamicFormFieldShowEmail,
                dynamicFormFieldType: data.dynamicFormFieldType,
                isListType: data.dynamicFormFieldType == 1,
                isTextboxType: data.dynamicFormFieldType == 2,
                dynamicFormFieldDisplayType: data.dynamicFormFieldDisplayType,
                dynamicFormFieldIsDeleted: data.dynamicFormFieldIsDeleted,
                dynamicFormFieldNumberOfRows: data.dynamicFormFieldNumberOfRows,
                dynamicFormFieldBackgroundColor: data.dynamicFormFieldBackgroundColor || '#FFFFFF',
                dynamicFormFieldTextColor: data.dynamicFormFieldTextColor || '#000000',
                dynamicFormFieldTextImage: data.dynamicFormFieldTextImage,
                dynamicFormFieldTextURL: data.dynamicFormFieldTextURL,
                dynamicFormFieldSubtitle: data.dynamicFormFieldSubtitle
            }));
        }
    }, []);

    const fieldChange = (e) => {
        const { name, value, type, checked } = e.target;
        setState((st) => {
            const nst = { ...st };
            if (type === 'checkbox') {
                nst[name] = checked;
            } else {
                nst[name] = value;
            }
            return nst;
        });
    };

    const handleClick = (name, value) => {
        console.log(name, value);
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const validateSpace = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st };
            if (value.includes(' ')) {
                nst.dynamicFormFieldJsonPropertyName = value.replace(/\s/g, '');
            }
            nst[name] = value;
            return nst;
        });
    };
    const handleKeyDown = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const handleWorkflowType = (name) => {
        setState((st) => {
            const nst = { ...st };
            if (name == 'isListType') {
                nst.isListType = true;
                nst.isTextboxType = false;
                nst.dynamicFormFieldType = 1;
                nst.dynamicFormFieldNumberOfRows = '';
            } else {
                nst.isListType = false;
                nst.isTextboxType = true;
                nst.dynamicFormFieldType = 2;
                nst.dynamicFormFieldListId = '';
            }
            nst.dynamicFormFieldDisplayType = null;
            return nst;
        });
    };

    const validation = () => {
        let errors = {};
        let isValid = true;
        let { dynamicFormFieldLabel, dynamicFormFieldJsonPropertyName, dynamicFormFieldIndex, dynamicFormFieldDisplayType, dynamicFormFieldListId } = state;
        if (!dynamicFormFieldJsonPropertyName) {
            isValid = false;
            errors.dynamicFormFieldJsonPropertyName = 'Field ID is required';
        }
        if (!dynamicFormFieldLabel) {
            isValid = false;
            errors.dynamicFormFieldLabel = 'Label is required';
        }
        if (!dynamicFormFieldIndex) {
            isValid = false;
            errors.dynamicFormFieldIndex = 'Sequance is required';
        }
        if (state.dynamicFormFieldIsRequired && !dynamicFormFieldDisplayType) {
            isValid = false;
            errors.dynamicFormFieldDisplayType = 'Display Type is required';
        }
        if (dynamicFormFieldDisplayType && state.isListType && !dynamicFormFieldListId) {
            isValid = false;
            errors.dynamicFormFieldListId = 'Please select List';
        }

        setState((st) => ({
            ...st,
            errors: errors
        }));

        return isValid;
    };

    const handelSubmit = async () => {
        if (validation()) {
            let data = {
                dynamicFormID: props.DynamicFormID,
                dynamicFormFieldId: props.dynamicFormFieldId || null,
                dynamicFormFieldLabel: state.dynamicFormFieldLabel,
                dynamicFormFieldIndex: state.dynamicFormFieldIndex,
                dynamicFormFieldListId: state.dynamicFormFieldListId,
                dynamicFormFieldOcrDocumentId: '',
                dynamicFormFieldIsRequired: state.dynamicFormFieldIsRequired,
                dynamicFormFieldJsonPropertyName: state.dynamicFormFieldJsonPropertyName,
                dynamicFormFieldShowUpload: state.dynamicFormFieldShowUpload,
                dynamicFormFieldShowView: state.dynamicFormFieldShowView,
                dynamicFormFieldShowEmail: state.dynamicFormFieldShowEmail,
                dynamicFormFieldType: state.dynamicFormFieldType,
                dynamicFormFieldDisplayType: state.dynamicFormFieldDisplayType,
                dynamicFormFieldIsDeleted: state.dynamicFormFieldIsDeleted,
                dynamicFormFieldNumberOfRows: state.dynamicFormFieldNumberOfRows,

                dynamicFormFieldBackgroundColor: state.dynamicFormFieldBackgroundColor,
                dynamicFormFieldTextColor: state.dynamicFormFieldTextColor,
                dynamicFormFieldTextImage: state.dynamicFormFieldTextImage,
                dynamicFormFieldTextURL: state.dynamicFormFieldTextURL,
                dynamicFormFieldSubtitle: state.dynamicFormFieldSubtitle
            };

            let res = await saveDynamicFormFields(data);
            if (!res.success) {
                console.error(res);
            } else {
                props.onClose(true);
            }
            showToast(res.message);
        }
    };

    let handleImage = (imageField) => (event) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            const { result } = e.target;
            setState((st) => ({
                ...st,
                [imageField]: result
            }));
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    const clearImage = (e) => {
        setState((st) => ({ ...st, dynamicFormFieldTextImage: '', dynamicFormFieldTextURL: '' }));
    };

    const videoLinkModalHandler = (e) => {
        setState((st) => ({ ...st, showVideoLinkModal: true }));
    };
    const closeVideoLinkModalHandler = (e) => {
        setState((st) => ({ ...st, showVideoLinkModal: false }));
    };

    const getLink = (URL) => {
        if (!URL.includes('youtube.com')) {
            return URL;
        }
        if (URL.includes('youtube.com') && !URL.includes('/embed/')) {
            return 'https://www.youtube.com/embed/' + URL.split('?v=')[1];
        }
        return URL;
    };

    return (
        <Grid container spacing={1}>
            {/* <Grid item xs={12} sm={6} md={4}>
                <FormLabel required component="legend" className="mandatoryfields">
                    Field ID
                </FormLabel>
                <InputLabel shrink>&nbsp;(enter a unique name for the Field ID)</InputLabel>
                <TextBox
                    required
                    name="dynamicFormFieldJsonPropertyName"
                    onChange={validateSpace}
                    onKeyDown={handleKeyDown}
                    value={state.dynamicFormFieldJsonPropertyName}
                />
                <span className="mandatoryfields">{state.errors['dynamicFormFieldJsonPropertyName']}</span>
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormLabel required component="legend" className="mandatoryfields">
                    Sequence
                </FormLabel>
                <TextBox type="number" name="dynamicFormFieldIndex" value={state.dynamicFormFieldIndex} onChange={fieldChange} />
                <span className="mandatoryfields">{state.errors['dynamicFormFieldIndex']}</span>
            </Grid> */}
            <Grid item xs={12} sm={12}>
                <FormLabel required component="legend" className="mandatoryfields">
                    Label
                </FormLabel>
                <TextBox autoFocus={true} required name="dynamicFormFieldLabel" onChange={fieldChange} value={state.dynamicFormFieldLabel} />
                <span className="mandatoryfields">{state.errors['dynamicFormFieldLabel']}</span>
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormLabel component="legend">Subtitle</FormLabel>
                <TextBox autoFocus={true} required name="dynamicFormFieldSubtitle" onChange={fieldChange} value={state.dynamicFormFieldSubtitle} />
                <span className="mandatoryfields">{state.errors['dynamicFormFieldSubtitle']}</span>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container spacing={1} direction="column">
                    <Grid item>
                        <FormLabel component="legend">Field Type</FormLabel>
                    </Grid>
                    <Grid item>
                        <DefaultButton
                            className={state.isListType ? `btnActive ${classes.btnContainer}` : `btninActive ${classes.btnContainer}`}
                            value={state.isListType}
                            name="isListType"
                            onClick={() => handleWorkflowType('isListType')}
                        >
                            List
                        </DefaultButton>
                    </Grid>
                    <Grid item>
                        <DefaultButton
                            className={state.isTextboxType ? `btnActive ${classes.btnContainer}` : `btninActive ${classes.btnContainer}`}
                            value={state.isTextboxType}
                            name="isTextboxType"
                            onClick={() => handleWorkflowType('isTextboxType')}
                        >
                            Text Box
                        </DefaultButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Grid container spacing={1} direction="column">
                    <Grid item>
                        <FormLabel component="legend">Display Type</FormLabel>
                    </Grid>
                    <Grid item>
                        <SelectBox
                            style={{ marginTop: '8px' }}
                            onChange={fieldChange}
                            value={state.dynamicFormFieldDisplayType}
                            name="dynamicFormFieldDisplayType"
                            List={[
                                { name: 'Button', id: 1 },
                                { name: 'Checkbox', id: 2 },
                                { name: 'Radio', id: 3 },
                                { name: 'DropDown', id: 4 }
                            ]}
                            disabled={!state.isListType}
                        />
                    </Grid>
                    <Grid item>
                        <SelectBox
                            style={{ marginTop: '8px' }}
                            onChange={fieldChange}
                            value={state.dynamicFormFieldDisplayType}
                            name="dynamicFormFieldDisplayType"
                            List={[
                                { name: 'Single', id: 5 },
                                { name: 'Multilne', id: 6 },
                                { name: 'Signature Box', id: 7 },
                                { name: 'Date/Time', id: 8 },
                                { name: 'Number', id: 9 }
                            ]}
                            disabled={!state.isTextboxType}
                        />
                    </Grid>
                    <span className="mandatoryfields">{state.errors['dynamicFormFieldDisplayType']}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container spacing={1} direction="column">
                    <Grid item>
                        <FormLabel component="legend">List</FormLabel>
                    </Grid>
                    <Grid item>
                        <SelectBox
                            style={{ marginTop: '8px' }}
                            onChange={fieldChange}
                            value={state.dynamicFormFieldListId}
                            name="dynamicFormFieldListId"
                            List={state.SteplList || []}
                            disabled={!state.isListType}
                        />
                    </Grid>
                    <Grid item>
                        <TextBox
                            required
                            name="dynamicFormFieldNumberOfRows"
                            onChange={validateSpace}
                            onKeyDown={handleKeyDown}
                            placeholder="Rows"
                            value={state.dynamicFormFieldNumberOfRows}
                            disabled={!state.isTextboxType}
                        />
                    </Grid>
                    <span className="mandatoryfields">{state.errors['dynamicFormFieldListId']}</span>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FieldWrappper heading="Field Themes" isAccordion={true} style={{ margin: '0px' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">
                                        <b>Field Background</b>
                                    </FormLabel>
                                </Grid>
                                <Grid item>
                                    <SketchPicker
                                        color={state.dynamicFormFieldBackgroundColor}
                                        onChange={(updatedColor) =>
                                            setState((state) => ({
                                                ...state,
                                                dynamicFormFieldBackgroundColor: updatedColor.hex
                                            }))
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">
                                        <b>Field Text</b>
                                    </FormLabel>
                                </Grid>
                                <Grid item>
                                    <SketchPicker
                                        color={state.dynamicFormFieldTextColor}
                                        onChange={(updatedColor) =>
                                            setState((state) => ({
                                                ...state,
                                                dynamicFormFieldTextColor: updatedColor.hex
                                            }))
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} style={{ height: '100%' }}>
                            <Grid container style={{ height: '100%' }} justifyContent="space-between">
                                <Grid item>
                                    <FormLabel component="legend">
                                        <b>Choose Media</b>
                                    </FormLabel>
                                </Grid>
                                <Grid item>
                                    {/* <Grid container> */}
                                    {state.dynamicFormFieldTextURL || state.dynamicFormFieldTextImage ? (
                                        <Grid item>
                                            <Tooltip title="Remove Document">
                                                <label>
                                                    <IconButton color="secondary" component="span" style={{ padding: '0px 10px' }}>
                                                        <RemoveCircleOutlineIcon onClick={clearImage} />
                                                    </IconButton>
                                                </label>
                                            </Tooltip>
                                        </Grid>
                                    ) : (
                                        <Grid item>
                                            <>
                                                <label title="Upload Document">
                                                    <IconButton color="secondary" component="span" style={{ padding: '0px 10px' }}>
                                                        <Backup />
                                                        <input
                                                            // disabled={screenState.isReadonly}
                                                            type="file"
                                                            onChange={handleImage('dynamicFormFieldTextImage')}
                                                            // className="upload-btn-input-file"
                                                            style={{ display: 'none' }}
                                                            accept="image/*,application/pdf"
                                                        />
                                                    </IconButton>
                                                </label>

                                                <label title="Upload Media">
                                                    <IconButton color="secondary" component="span" style={{ padding: '0px 10px' }}>
                                                        <LinkIcon onClick={videoLinkModalHandler} />
                                                    </IconButton>
                                                </label>
                                            </>
                                        </Grid>
                                    )}
                                    {/* </Grid> */}
                                </Grid>
                                <Grid item xs={12} style={{ height: '100%' }}>
                                    {state.dynamicFormFieldTextURL && (
                                        <div id="video-player" style={{ height: '95%' }}>
                                            <iframe
                                                frameborder="0"
                                                allowFullScreen="true"
                                                webkitallowfullscreen="true"
                                                mozallowfullscreen="true"
                                                width="100%"
                                                height="100%"
                                                src={getLink(state.dynamicFormFieldTextURL)}
                                            />
                                        </div>
                                    )}
                                    {state.dynamicFormFieldTextImage && (
                                        <div style={{ height: '95%' }}>
                                            <img src={state.dynamicFormFieldTextImage} width="100%" height="100%" />
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FieldWrappper>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    {/* <Grid item xs={12} sm={4}>
                        <FormLabel component="legend">Attached Document</FormLabel>
                        <SelectBox
                            style={{ marginTop: '8px' }}
                            onChange={fieldChange}
                            value={state.dynamicFormFieldOcrDocumentId}
                            name="dynamicFormFieldOcrDocumentId"
                            List={state.OcrDocumnetList || []}
                        />
                    </Grid> */}
                    <Grid item>
                        <FormControlLabel
                            style={{ margin: '0px 16px 0px 0px' }}
                            control={<Checkbox checked={state.dynamicFormFieldShowUpload} onChange={fieldChange} name="dynamicFormFieldShowUpload" />}
                            label="Can Upload?"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            style={{ margin: '0px 16px 0px 0px' }}
                            control={<Checkbox checked={state.dynamicFormFieldShowView} onChange={fieldChange} name="dynamicFormFieldShowView" />}
                            label="Can View?"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            style={{ margin: '0px 16px 0px 0px' }}
                            control={<Checkbox checked={state.dynamicFormFieldShowEmail} onChange={fieldChange} name="dynamicFormFieldShowEmail" />}
                            label="Can Email?"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            style={{ margin: '0px 16px 0px 0px' }}
                            control={<Checkbox checked={state.dynamicFormFieldIsDeleted} onChange={fieldChange} name="dynamicFormFieldIsDeleted" />}
                            label="Can Delete?"
                            labelPlacement="start"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid container style={{ textAlign: 'left' }} spacing={2}>
                    <Grid xs={12} container item>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel>Is Required?</FormLabel>
                        </Grid>
                        <YesNoButton
                            state={state.dynamicFormFieldIsRequired}
                            name="dynamicFormFieldIsRequired"
                            onYesClick={() => handleClick('dynamicFormFieldIsRequired', true)}
                            onNoClick={() => handleClick('dynamicFormFieldIsRequired', false)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </Grid>
            {state.showVideoLinkModal && <GetVideoLink handleClose={closeVideoLinkModalHandler} fieldChange={fieldChange} />}
        </Grid>
    );
};

export default AddEditDynamicFormFields;

const GetVideoLink = (props) => {
    const { userRoles } = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    return (
        <Dialog open={true} onClose={props.handleClose} maxWidth="md" fullWidth>
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitle>Video URL</DialogTitle>
                </Box>
                <Box>
                    <IconButton onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent>
                <Grid item xs={12}>
                    <FormLabel required component="legend" className="mandatoryfields">
                        URL
                    </FormLabel>
                    <TextBox
                        autoFocus={true}
                        required
                        name="dynamicFormFieldTextURL"
                        onChange={props.fieldChange}
                        // value={state.dynamicFormFieldTextURL}
                    />
                    {/* <span className="mandatoryfields">{state.errors['dynamicFormFieldTextURL']}</span> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <PrimaryButton className="Submitbtn" onClick={props.handleClose}>
                    Submit
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
