import React, { useContext, useState, useEffect } from 'react';
import { SecondaryButton, TextBox, DefaultButton } from '../../../Core/FormInput/index';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { postReportingAgedMeasures, getReportingAgedMeasuresID } from '../../../Core/Service/admin-Invoice_Branch-service';
import AppContext from '../../../App/AppContext';
import { FormHelperText } from '@material-ui/core';

const AddAgeMeasured = (props) => {
    const { showToast } = useContext(AppContext);
    const [state, setState] = useState({
        id: props.AddAgeMeasuredID || null,
        description: '',
        excludeAreaCodes: '',
        url: '',
        isActive: false,
        menuLabel: '',
        sequence: null,
        includeAreaCodes: '',
        errors: {}
    });

    useEffect(() => {
        if (props.AddAgeMeasuredID) {
            pullData();
        }
    }, []);

    const validation = () => {
        let isValid = true;
        let error = {};
        const { sequence } = state;
        if (!sequence) {
            error.sequence = 'Sequence is Required';
            isValid = false;
        }

        setState((st) => ({
            ...st,
            errors: error
        }));

        return isValid;
    };

    const pullData = async () => {
        let res = await getReportingAgedMeasuresID(props.AddAgeMeasuredID);

        let data = res.data.table[0];
        if (res.success) {
            setState((st) => ({
                ...st,
                id: data.id,
                description: data.description || '',
                excludeAreaCodes: data.excludeAreaCodes || '',
                uRL: data.url || '',
                isActive: data.isActive || false,
                menuLabel: data.menuLabel || '',
                sequence: data.sequence || null,
                includeAreaCodes: data.includeAreaCodes || ''
            }));
        }
    };

    const handelSubmit = async (flag) => {
        if (validation()) {
            let data = {
                id: state.id,
                description: state.description,
                excludeAreaCodes: state.excludeAreaCodes || '',
                url: state.uRL,
                isActive: state.isActive,
                menuLabel: state.menuLabel,
                sequence: state.sequence,
                includeAreaCodes: state.includeAreaCodes || ''
            };
            let res = await postReportingAgedMeasures(data);
            if (res.success) {
                showToast(`Reporting Aged Measures ${props.AddAgeMeasuredID ? 'Updated' : 'Added'} Successfully`);
                props.onClose();
            } else {
                showToast(`Reporting Aged Measures Failed to add`);
            }
        }
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <FormLabel component="legend">MenuLabel</FormLabel>
                    <TextBox autoFocus={true} name="menuLabel" onChange={fieldChange} value={state.menuLabel} />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormLabel component="legend">Description</FormLabel>
                    <TextBox autoFocus={true} name="description" onChange={fieldChange} value={state.description} />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormLabel component="legend">URL</FormLabel>
                    <TextBox autoFocus={true} name="uRL" onChange={fieldChange} value={state.uRL} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel component="legend">Exclude Area Codes</FormLabel>
                    <TextBox name="excludeAreaCodes" onChange={fieldChange} value={state.excludeAreaCodes} multiline rows={2} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel component="legend">Include Area Codes</FormLabel>
                    <TextBox name="includeAreaCodes" onChange={fieldChange} value={state.includeAreaCodes} multiline rows={2} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid container spacing={3} style={{ marginTop: -1 }}>
                        <Grid item xs={2} sm={4}>
                            <FormLabel component="legend" className="btn_add">
                                Is Active?
                            </FormLabel>
                        </Grid>
                        <Grid item xs={5} sm={4}>
                            <DefaultButton
                                className={state.isActive ? 'btnActive' : 'btninActive'}
                                value={true}
                                name="isActive"
                                onClick={() => handleClick('isActive', true)}
                            >
                                Yes
                            </DefaultButton>
                        </Grid>
                        <Grid item xs={5} sm={4}>
                            <DefaultButton
                                className={state.isActive ? 'btninActive' : 'btnActiveNo'}
                                value={false}
                                name="isActive"
                                onClick={() => handleClick('isActive', false)}
                            >
                                No
                            </DefaultButton>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormLabel component="legend" error>
                        Sequence
                    </FormLabel>
                    <TextBox type="number" name="sequence" onChange={fieldChange} value={state.sequence} />
                    <FormHelperText error>{state.errors.sequence}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <SecondaryButton className="Submitbtn" onClick={() => handelSubmit(false)}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};

export default AddAgeMeasured;
