let imageSizeLimit = { height: 600, width: 800 };

const getResizedImage = (selectedFile, cb, imgh, imgw) => {
    if (imgh) {
        imageSizeLimit = { height: imgh, width: imgw };
    }

    let dataUrl = '';
    const can = document.createElement('canvas');
    document.body.append(can);
    can.style.display = 'none';
    var ctx = can.getContext('2d');
    var img = new Image();
    img.onload = function () {
        let ch = img.height;
        let cw = img.width;
        if (ch > imageSizeLimit.height || cw > imageSizeLimit.width) {
            if (ch > cw) {
                const ratio = imageSizeLimit.height / ch;
                cw = cw * ratio;
                ch = imageSizeLimit.height;
            } else {
                const ratio = imageSizeLimit.width / cw;
                ch = ch * ratio;
                cw = imageSizeLimit.width;
            }
        }
        if (ch > imageSizeLimit.height) {
            const ratio = imageSizeLimit.height / ch;
            cw = cw * ratio;
            ch = imageSizeLimit.height;
        }
        if (cw > imageSizeLimit.width) {
            const ratio = imageSizeLimit.width / cw;
            ch = ch * ratio;
            cw = imageSizeLimit.width;
        }
        can.height = ch;
        can.width = cw;
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, cw, ch);
        dataUrl = can.toDataURL('image/jpeg', 1);
        document.body.removeChild(can);
        cb(dataUrl);
    };
    img.src = URL.createObjectURL(selectedFile);
};

export default getResizedImage;
