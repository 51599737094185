import React, { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { DialogContents, Dialogs, SecondaryButton, DialogTitles } from '../../../../Core/FormInput';
import './viewAxel.scss';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Backup } from '@material-ui/icons';
import getResizedImage from '../../../../Core/CommonFunctions/imageResizer';

const useStyles = makeStyles(() => ({
    paper: { minWidth: '500px', maxWidth: '500px' }
}));
const ViewAxel = (props) => {
    // let handleImage = (event, i) => {
    //     const { name } = event.target;
    //     let reader = new FileReader();
    //     reader.onload = (e) => {
    //         const { result } = e.target;
    //         props.UploadNewImage(result);
    //     };
    //     reader.readAsDataURL(event.target.files[0]);
    // };

    let handleImage = (event) => {
        if (event.target.files[0].type.includes('image')) {
            getResizedImage(event.target.files[0], (fileUrl) => {
                props.UploadNewImage(fileUrl);
            });
        }
    };

    const handleClose = () => {
        props.onFormSubmit();
    };
    const classes = useStyles();

    return (
        <div>
            <Dialogs open={true} onClose={handleClose} maxWidth="md" classes={{ paper: classes.paper }}>
                <Box display="flex" alignItems="center" style={{ maxWidth: '100%', minWidth: '100%' }}>
                    <Box flexGrow={1}>
                        <DialogTitles>Axel: {props.title}</DialogTitles>
                    </Box>
                    <Box>
                        {props.logoValue ? (
                            <Tooltip title="Remove">
                                <IconButton onClick={props.RemoveImage}>
                                    <DeleteIcon color="secondary" />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Upload">
                                <IconButton>
                                    <label>
                                        <Backup color="secondary" style={{ cursor: 'pointer' }} />
                                        <input
                                            name="brakeReportItemOffSidePhoto"
                                            type="file"
                                            onChange={(e) => handleImage(e)}
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                        />
                                    </label>
                                </IconButton>
                            </Tooltip>
                        )}
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <DialogContents className="vehicle-detail">
                    <div className="vehicle-detail-body">
                        <div className="car-detail-column-left">
                            <div className="list-card">
                                <div className="avatar">
                                    <div className="image-card">{props.logoValue && <img className="avtar" src={props.logoValue} alt="wheel" />}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContents>
            </Dialogs>
        </div>
    );
};
export default ViewAxel;
