import { Grid, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../../App/AppContext';
import CustomeEditor from '../../../../Core/CkEditor';
import { SecondaryButton } from '../../../../Core/FormInput';
import { getInvoiceCustomerById, editInvoiceCustomerNote } from '../../../../Core/Service/InvoiceScreen-service';
import './style.scss'

const InvoiceCustomersNoteScreen = (props) => {
    const [state, setState] = useState({ errors: {}, custData: {}, customerInternalNotes: '' });
    const { showToast, showModal } = useContext(AppContext);

    useEffect(async () => {
        let res = await getInvoiceCustomerById(props.customerId);
        setState((st) => {
            const nst = { ...st };
            nst.custData = res.data.table[0];
            nst.customerInternalNotes = res.data.table[0].customerInternalNotes ? res.data.table[0].customerInternalNotes : "";
            return nst;
        });
    }, []);

    const handleSubmit = async () => {
        let data = {
            id: props.customerId,
            customerInternalNotes: state.customerInternalNotes
        };

        let res = await editInvoiceCustomerNote(data);
        if (res.success) {
            showToast(`Customer Updated Successfully`);
            props.onClose(true);
        } else {
            console.error(res.message);
        }
    };

    const handleCkeditorstate = (name) => (event, editor) => {
        const data = editor.getData();
        setState((st) => ({ ...st, [name]: data }));
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        {`Name`}
                    </Grid>
                    <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                        {`${state.custData.customerSName}(${state.custData.customerCode})`}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12}>
                        Address
                    </Grid>
                    <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                        {`${state.custData.addressLine1} ${state.custData.addressLine2} ${state.custData.addressLine3} ${state.custData.addressLine4} ${state.custData.addressLine5}`}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'left' }}>
                <br />
                <Typography variant="body2" color="secondary">
                    Customer Notes
                </Typography>
                <hr />
                <CustomeEditor
                    data={state.customerInternalNotes}
                    onChange={handleCkeditorstate('customerInternalNotes')}
                    placeholder="Add notes here"
                    name="customerInternalNotes"
                />
            </Grid>

            <Grid item xs={12}>
                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                    Save
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default InvoiceCustomersNoteScreen;