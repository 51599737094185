import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import BudgetTableOne from './dashTableOne';
import './budgetStyle.scss';
import { BudgetGraph, WipGraph, WipGraphtwo } from './budgetGraph';
import BudgetTableTwo from './dasbordTabletwo';
import { TargetTableOne, TargetTableTwo, TargetTableThree, TargetTableFour, TargetTableFive } from './targetTabels';
import { lightGreen } from '@material-ui/core/colors';

const BudgetDashboard = () => {
    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={1} className="backgroundBlue">
                    <div className="headingText">Dec</div>
                </Grid>
                <Grid item xs={10} className="backgroundBlue">
                    <div className="headingText">Current position Report</div>
                </Grid>
                <Grid item xs={1} className="backgroundBlue">
                    <div className="headingText">12/13/22</div>
                </Grid>
                <Grid item xs={12}>
                    {/* <div className="headingText">Current position Report</div> */}
                    <BudgetTableOne />
                </Grid>
                <Grid item xs={12}>
                    <BudgetTableTwo />
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6} className="backgroundBlue">
                    <div className="headingText ">Variance on Budget Graph</div>
                </Grid>
                <Grid item xs={3} className="backgroundBlue">
                    <div className="headingText">Wip</div>
                </Grid>
                <Grid item xs={3} className="backgroundBlue">
                    <div className="headingText">Forecast</div>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid xs={4}>
                            <BudgetGraph name={'Invoicing(Hrs)'} />
                        </Grid>
                        <Grid xs={4}>
                            <BudgetGraph name={`Invoicing (£'s)`} />
                        </Grid>
                        <Grid xs={4}>
                            <BudgetGraph name="Recovery rate" />
                        </Grid>
                        <Grid xs={4}>
                            <BudgetGraph name="Efficiency (Sold/Taken hrs)" />
                        </Grid>
                        <Grid xs={4}>
                            <BudgetGraph name="Utilisation (Productive/Attended)" />
                        </Grid>
                        <Grid xs={4}>
                            <BudgetGraph name="Prodectivity (Sold/ Attended)" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={3}>
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <WipGraph name="WIP" />
                        </Grid>
                        <Grid xs={12}>
                            <WipGraphtwo name="Parts WIP" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <BudgetGraph name="EOM Forecast (hrs)" />
                        </Grid>
                        <Grid xs={12}>
                            <BudgetGraph name="EOM Forecast (£)" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12} className="backgroundBlue">
                    <div className="headingText">Target Calculations</div>
                </Grid>
                <Grid item xs={12}>
                    <TargetTableOne />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" className="alignLeft">
                        Hours in WIP This Week
                    </Typography>
                    <TargetTableTwo />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" className="alignLeft">
                        Invoicing
                    </Typography>
                    <TargetTableThree />
                </Grid>
                <Grid item xs={12}>
                    <TargetTableFour />
                </Grid>
                <Grid item xs={12}>
                    <TargetTableFive />
                </Grid>
            </Grid>
        </div>
    );
};

export default BudgetDashboard;
