import moment from 'moment';
import { useEffect } from 'react';
import { getActionLogHistory } from '../../../../Core/Service/communicator-service';
import { useState } from 'react';
import DialogComp from '../../../../Core/Modal/dialogModal';
import { Avatar, Chip, DialogTitle } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EditIcon from '@material-ui/icons/Edit';
import AddEditActionLog from './addEditActionLog';
import EditActionLog from './editActionLog';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';

const LogHistory = (props) => {
    const [state, setState] = useState({
        messaegList: [],
        actionLog: false,
        editActionLog: false
    });

    const [WindowWidths, WindowHeights] = useWindowSize();

    useEffect(async () => {
        let res = await getActionLogHistory(props.headerRecordID);
        if (res.success) {
            setState((st) => ({ ...st, messaegList: res?.data?.list }));
        }
    }, []);

    const handleActionLog = () => {
        setState((st) => ({
            ...st,
            actionLog: !st.actionLog
        }));
    };

    const handleEditActionLog = (val) => {
        setState((st) => ({
            ...st,
            editActionLog: true,
            headerRecordID: props.headerRecordID,
            id: val.logID,
            closeId: val.logID
        }));
        // props.onClose();
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            editActionLog: false
        }));
    };

    return (
        <>
            {props.showDialog ? (
                <DialogComp
                    title={
                        <DialogTitle style={{ padding: 0 }}>
                            <span>
                                Action Log History
                                {!props.hideAddActionBtn && (
                                    <Chip
                                        style={{ marginLeft: 10, cursor: 'pointer' }}
                                        avatar={
                                            <Avatar>
                                                <ListAltIcon />
                                            </Avatar>
                                        }
                                        label="Add New Log"
                                        onClick={handleActionLog}
                                    />
                                )}
                            </span>
                        </DialogTitle>
                    }
                    onClose={() => props.onClose(false)}
                    fullScreen={WindowWidths < 1260 ? true : false}
                    maxWidth="md"
                    fullWidth
                >
                    <div style={{ paddingBottom: 20 }}>
                        {/* <div>
                    WIP No:&nbsp; <b> {props.wip}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.reg}</b>
                </div>
                <br /> */}
                        {state.messaegList.length > 0 ? (
                            <div style={{ border: '1px solid #E8E8E8', maxHeight: 250, overflow: 'auto' }} className="custom-scroll">
                                {state.messaegList.map((m) => (
                                    <div style={{ borderBottom: '1px solid #E8E8E8', padding: 10 }}>
                                        <div style={{ fontWeight: 500 }}>
                                            {m.logText}{' '}
                                            {m.logActioned ? null : (
                                                <Chip
                                                    style={{ marginLeft: 10, cursor: 'pointer' }}
                                                    avatar={
                                                        <Avatar>
                                                            <EditIcon />
                                                        </Avatar>
                                                    }
                                                    size="small"
                                                    label="Close"
                                                    onClose={() => props.onClose(false)}
                                                    onClick={() => handleEditActionLog(m)}
                                                />
                                            )}
                                        </div>

                                        <div style={{ paddingBottom: 4 }}>
                                            <span style={{ fontSize: 11, color: 'green' }}>{m.logResultText}</span>&nbsp;
                                            <span style={{ fontSize: 11 }}>
                                                {moment(m.logActionTime).format('LLL')} ( Created by : {m.createdBy} @ {moment(m.logDateCreated).format('LLL')}{' '}
                                                )
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div style={{ border: '1px solid #E8E8E8' }}>
                                <div style={{ textAlign: 'center', padding: 10 }}>No Log Available</div>
                            </div>
                        )}
                    </div>
                </DialogComp>
            ) : (
                <div style={{ paddingBottom: 20 }}>
                    {/* <div>
                    WIP No:&nbsp; <b> {props.wip}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.reg}</b>
                </div>
                <br /> */}
                    {state.messaegList.length > 0 ? (
                        <div style={{ border: '1px solid #E8E8E8', maxHeight: 250, overflow: 'auto' }} className="custom-scroll">
                            {state.messaegList.map((m) => (
                                <div style={{ borderBottom: '1px solid #E8E8E8', padding: 10 }}>
                                    <div style={{ fontWeight: 500 }}>
                                        {m.logText}{' '}
                                        {props.hideClose && (
                                            <>
                                                {m.logActioned ? null : (
                                                    <Chip
                                                        style={{ marginLeft: 10, cursor: 'pointer' }}
                                                        avatar={
                                                            <Avatar>
                                                                <EditIcon />
                                                            </Avatar>
                                                        }
                                                        size="small"
                                                        label="Close"
                                                        onClose={() => props.onClose(false)}
                                                        onClick={() => handleEditActionLog(m)}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>

                                    <div style={{ paddingBottom: 4 }}>
                                        <span style={{ fontSize: 11, color: 'green' }}>{m.logResultText}</span>&nbsp;
                                        <span style={{ fontSize: 11 }}>
                                            {moment(m.logActionTime).format('LLL')} ( Created by : {m.createdBy} @ {moment(m.logDateCreated).format('LLL')} )
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div style={{ border: '1px solid #E8E8E8' }}>
                            <div style={{ textAlign: 'center', padding: 10 }}>No Log Available</div>
                        </div>
                    )}
                </div>
            )}

            {state.editActionLog ? (
                <EditActionLog
                    headerRecordID={state.headerRecordID}
                    id={state.id}
                    reg={props.reg}
                    wip={props.wip}
                    onClose={handleClose}
                    closeId={state.closeId}
                />
            ) : null}

            {state.actionLog ? (
                <DialogComp title="Add Action Log" fullWidth maxWidth="lg" onClose={handleActionLog}>
                    <AddEditActionLog headerRecordID={props.headerRecordID} reg={props.reg} wip={props.wip} onClose={handleActionLog} />
                </DialogComp>
            ) : null}
        </>
    );
};

export default LogHistory;
