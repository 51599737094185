import { get, post, put } from './http-calls';
import { memoize } from 'lodash';

export const getAppraisalPageStesps = async (pageName) => {
    let res = await get(`AppraisalScreen/GetPageSteps?pageName=${pageName}`, { useAuthToken: true });
    return res;
};
export const getAppraisalScreenColumnsByID = async (id) => {
    let res = await get(`AppraisalScreenColumns/GetDetails/${id}`, { useAuthToken: true });
    return res;
};
export const postAppraisalScreenColumns = async (data) => {
    let res = await post(`AppraisalScreenColumns`, data, { useAuthToken: true });
    return res;
};
export const getAppraisalVehicleTypes = memoize(async () => {
    let res = await get(`AppraisalMasterData/GetActiveVehicleType`, { useAuthToken: true });
    return res;
});
export const getAppraisalScreenColumnPermission = async (id) => {
    let res = await get(`AppraisalScreenColumns/ScreenColumnPermisson${id ? `?id=${id}` : ''}`, { useAuthToken: true });
    return res;
};
