import { Breadcrumbs, IconButton, Tooltip, Typography } from '@material-ui/core';
import { GridOverlay } from '@material-ui/data-grid';
import { Edit, Link, Add, Info } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { SecondaryButton, SquareButton } from '../../../Core/FormInput/AppButton';
import { Grid } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { useMemo } from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import ReportGijgoGrid from '../ReportsScreens/GijgoForReportsScreen';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { useHistory } from 'react-router-dom';
import SearchWipsByBranch from './SearchWipByBranch';
import AppContext from '../../../App/AppContext';
import { postLinkWips } from '../../../Core/Service/service24-service';
import DialogComp from '../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import EditIcon from '@material-ui/icons/Edit';
let styleObj = {
    backgroundColor: '#183B68',
    borderRadius: '20%',
    width: '32px',
    height: '32px',
    textAlign: 'center',
    padding: '0px 0px 0px 0px'
};

const crumbs = (IsUnallocated) => {
    let list = [
        { name: 'Home', path: '/' },
        { name: 'Service 24 Jobs', active: true }
    ];
    IsUnallocated ? list.push({ name: 'Unallocated jobs', active: true }) : list.push({ name: 'All jobs', active: true });
    return list;
};

const RenderBranchNotExist = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                Please assign branch to the job by clicking the Update button
            </Grid>
            <Grid item xs={6}>
                <SecondaryButton fullWidth onClick={props.onUpdate}>
                    Update
                </SecondaryButton>
            </Grid>
            <Grid item xs={6}>
                <SecondaryButton fullWidth onClick={() => props.handleClose}>
                    Close
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

const Service24Jobs = (props) => {
    const history = useHistory();

    const [state, setState] = useState({
        showSearchModal: false,
        focusedRow: null,
        selectedBranchID: null,
        headerRecordID: null,
        data: {},
        showS24AddUpdateModal: false
    });
    const { showToast, showModal } = useContext(AppContext);

    const gotoWorkflowSteps = (row) => {
        history.push(`/Job/${row.headerRecordID}/${row.workflowID}?ProcessId=${row.pid}&froms24=true&IsUnallocated=${props.IsUnallocated}`);
    };

    const handelAddUpdate = (row) => {
        let searchQuery = `?s24Grid=true&IsUnallocated=${props.IsUnallocated}`;
        history.push({
            pathname: `/addupdate/service24/null/${state.service24JobID || null}`,
            search: searchQuery
        });
    };

    const goToJobDetails = (row) => {
        let searchQuery = `?s24Grid=true&IsUnallocated=${props.IsUnallocated}`;
        history.push({
            pathname: `/addupdate/service24/${row?.headerRecordID}/${row.jobID || null}`,
            search: searchQuery
        });
    };
    const handelSearchWIPModal = (isShow, row) => {
        setState((st) => ({
            ...st,
            showSearchModal: isShow,
            selectedBranchID: row?.branchID || null,
            service24JobID: row?.jobID || null,
            headerRecordID: row?.headerRecordID || null,
            data: row
        }));
    };

    const baseColumns = [
        {
            field: 'action',
            title: `Action`,
            width: '5%',
            filterable: false,
            sortable: false
        },

        {
            field: 'wipNumber',
            title: `WIP No`,
            filterable: true,
            sortable: true,
            width: '5%'
        },
        {
            field: 'regNo',
            title: `Reg No`,
            filterable: true,
            sortable: true,
            width: '6%'
        },
        {
            field: 'caseNo',
            title: `Case No`,
            filterable: true,
            sortable: true,
            width: '8%'
        },
        {
            field: 'bn',
            title: `Branch`,
            filterable: true,
            sortable: true,
            width: '8%'
        },
        {
            field: 'defectDesc',
            title: `Defect Reported`,
            filterable: true,
            sortable: true
        },

        {
            field: 'repairDetails',
            title: `Repair Details`,
            filterable: true,
            sortable: true
        },

        {
            field: 'breakdownLoc',
            title: `Breakdown Location`,
            filterable: true,
            sortable: true
        },
        {
            field: 'cn',
            title: `Customer`,
            filterable: true,
            sortable: true
        },
        {
            field: 'chassisNo',
            title: `Chassis No`,
            filterable: true,
            sortable: true
        }
    ];
    const addButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center" style={{ paddingRight: '7px' }}>
                <SquareButton
                    toolTipTitle="Add Service 24 hour job"
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px',
                        marginLeft: '20px'
                    }}
                    onClick={() => handelAddUpdate({})}
                >
                    <Add fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        console.log(record, 'record');
        const lnk = (
            <Grid container justifyContent="space-evenly">
                {record.headerRecordID != null ? (
                    <>
                        <SquareButton toolTipTitle="WIP details" style={styleObj} onClick={() => gotoWorkflowSteps(record)}>
                            <Info fontSize="small" style={{ color: 'white' }} />
                        </SquareButton>
                    </>
                ) : (
                    <>
                        <SquareButton toolTipTitle="Link Service 24 job to a WIP" style={styleObj} onClick={() => handelSearchWIPModal(true, record)}>
                            <Link fontSize="small" style={{ color: 'white' }} />
                        </SquareButton>
                    </>
                )}
                <SquareButton toolTipTitle="Service 24 Hour job details" style={styleObj} onClick={() => goToJobDetails(record)}>
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');
        let dateDueInCol = tempCols.find((element) => element.field === 'dateDueIn');
        let createdAtCol = tempCols.find((element) => element.field === 'createdAt');

        if (actionCol) {
            actionCol.renderer = editButton({});
        }

        if (dateDueInCol) {
            dateDueInCol.renderer = (val) => formatters.DateFormatter(val);
        }

        if (createdAtCol) {
            createdAtCol.renderer = (val) => formatters.DateTimeFormatter(val);
        }

        return tempCols;
    }, []);

    const baseUrl = `Service24Jobs/GetAllService24Jobs?${props.IsUnallocated ? `IsUnallocated=${props.IsUnallocated}` : ''}`;

    const LinkJobWithWIPs = async (HeaderRecordID, workflowID) => {
        setState((st) => ({ ...st, loading: true, showDocToOtherModal: false }));
        const res = await postLinkWips({ headerRecordID: HeaderRecordID, service24JobID: state.service24JobID, workflowID: workflowID });
        if (res.success) {
            setState((st) => ({ ...st, showSearchModal: false, selectedBranchID: null, isReload: new Date() }));
            showToast(res.data?.message);
        }
    };

    return (
        <div>
            {state.showSearchModal ? (
                <>
                    {state.selectedBranchID != null ? (
                        <SearchWipsByBranch
                            selectedBranchID={state.selectedBranchID}
                            showToast={showToast}
                            onClose={() => handelSearchWIPModal(false, {})}
                            onClickingLink={LinkJobWithWIPs}
                            data={state.data}
                        />
                    ) : (
                        <DialogComp title="Branch Not Found" onClose={() => handelSearchWIPModal(false, {})}>
                            <RenderBranchNotExist handleClose={() => handelSearchWIPModal(false, {})} onUpdate={() => handelAddUpdate()} />
                        </DialogComp>
                    )}
                </>
            ) : null}
            <div className="report-screen-container">
                <BreadCrumbs crumbs={crumbs(props.IsUnallocated)} />
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <ReportGijgoGrid
                            dataConverter={(res) => ({
                                records: res?.data?.list,
                                total: res?.data?.total
                            })}
                            columns={columns}
                            getUrl={baseUrl}
                            isReload={state.isReload}
                            AddBtn={addButton}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Service24Jobs;
