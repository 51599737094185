import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ButtonGroup, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
});

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

export const ProfitTable = (props) => {
    const [state, setState] = useState({
        btn: 1,
        rows: props.rows ? props.rows : []
    });
    const classes = useStyles();
    useEffect(() => {
        setState((st) => ({ ...st, btn: 2, rows: [...props.rows].filter((m) => m.vehicleType === 'New') }));
    }, [props.rows]);

    useEffect(async () => {
        let newRows = [];
        if (state.btn === 1) {
            newRows = [...props.rows].reduce((acc, obj) => {
                var index = acc.find((item) => item.branch === obj.branch);
                if (index) {
                    index.motabilityVolume += obj.motabilityVolume;
                    index.retailVolume += obj.retailVolume;
                    index.tradeVolume += obj.tradeVolume;
                    return acc;
                }
                acc.push({ ...obj });
                return acc;
            }, []);
        }

        setState((st) => {
            let NewSt = { ...st };
            switch (st.btn) {
                case 1:
                    NewSt.rows = newRows;
                    break;
                case 2:
                    NewSt.rows = [...props.rows].filter((m) => m.vehicleType === 'New');
                    break;
                case 3:
                    NewSt.rows = [...props.rows].filter((m) => m.vehicleType === 'Used');
                    break;
            }
            return NewSt;
        });
    }, [state.btn]);

    const handleTable = (value) => {
        setState((st) => ({ ...st, btn: value }));
    };

    return (
        <TableContainer style={{ border: '2px solid lightgrey' }}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell width="20%">
                            {' '}
                            <ButtonGroup size="large" color="secondary" aria-label="small outlined button group">
                                <Button
                                    variant={state.btn === 1 ? 'contained' : 'outlined'}
                                    color="secondary"
                                    onClick={() => {
                                        handleTable(1);
                                        // setBtn(1);
                                    }}
                                >
                                    COMBINED
                                </Button>
                                <Button
                                    variant={state.btn === 2 ? 'contained' : 'outlined'}
                                    color="secondary"
                                    onClick={() => {
                                        handleTable(2);
                                        // setBtn(2);
                                    }}
                                >
                                    NEW
                                </Button>
                                <Button
                                    variant={state.btn === 3 ? 'contained' : 'outlined'}
                                    color="secondary"
                                    onClick={() => {
                                        // setBtn(3);
                                        handleTable(3);
                                    }}
                                >
                                    USED
                                </Button>
                            </ButtonGroup>
                        </TableCell>
                        <TableCell align="right" width="10%">
                            Retail Volume
                        </TableCell>
                        <TableCell align="right" width="10%">
                            Retail Profit
                        </TableCell>
                        <TableCell align="right" width="10%">
                            Retail PPU
                        </TableCell>
                        <TableCell align="right" width="10%">
                            {state.btn === 1 ? 'Total Volume' : state.btn === 2 ? 'Motability Profit' : state.btn === 3 ? 'Trade Profit' : 'Total Volume'}
                        </TableCell>
                        <TableCell align="right" width="10%">
                            Total Profit
                        </TableCell>
                        <TableCell align="right" width="10%">
                            Budget
                        </TableCell>
                        <TableCell align="right" width="20%">
                            Difference (Profit:Budget)
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state?.rows?.map((row, i) => {
                        let Difference = row.totalProfit - row.budget;
                        let volumeTotal = row.motabilityVolume + row.retailVolume + row.tradeVolume;
                        return (
                            <StyledTableRow key={i}>
                                <TableCell component="th" scope="row">
                                    <b>{row.branch}</b>
                                </TableCell>
                                <TableCell align="right">{row.retailVolume}</TableCell>
                                <TableCell align="right">
                                    <b>{row.retailProfit ? <>£{row.retailProfit.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}</b>
                                </TableCell>
                                <TableCell align="right">
                                    <b>{row.retailPPU ? <>£{row.retailPPU.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}</b>
                                </TableCell>
                                <TableCell align="right">
                                    {state.btn === 1 ? (
                                        volumeTotal
                                    ) : state.btn === 2 ? (
                                        <b>
                                            {row.motabilityProfit ? (
                                                <>£{row.motabilityProfit.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</>
                                            ) : (
                                                <>£0.00</>
                                            )}
                                        </b>
                                    ) : state.btn === 3 ? (
                                        <b>{row.tradeProfit ? <>£{row.tradeProfit.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}</b>
                                    ) : (
                                        'N/A'
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    <b>{row.totalProfit ? <>£{row.totalProfit.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}</b>
                                </TableCell>
                                <TableCell align="right">
                                    <b>{row.budget ? <>£{row.budget.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}</b>
                                </TableCell>
                                <TableCell align="right">
                                    {Difference ? <>£{Difference.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
                                </TableCell>
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
