import React, { useState } from 'react';
import { Grid, InputLabel, Checkbox, FormHelperText } from '@material-ui/core';
import { TextBox } from '../../../../Core/FormInput';
import { SecondaryButton } from './../../../../Core/FormInput/AppButton';
import DataGridComp from '../../../../Core/Grid';
import { useEffect } from 'react';
import { getMessageGridList, postInvoiceMessage } from '../../../../Core/Service/internal_Invoice-service';
import AppContext from '../../../../App/AppContext';
import { useContext } from 'react';
import moment from 'moment';

// const columns = [
//     {
//         field: 'invoiceMessageDateSent',
//         headerName: 'Date Sent',
//         renderCell: (params) => (params.value ? moment(params.value).format('DD-MMM-YYYY') : '-'),
//         width: 150
//     },
//     {
//         field: 'invoiceMessageText',
//         headerName: 'Message',
//         renderCell: (params) => (params.value ? params.value : '-'),
//         width: 250
//     },

//     {
//         field: 'invoiceMessageFromUser',
//         headerName: 'From User',
//         renderCell: (params) => (params.value ? params.value : '-'),
//         width: 200
//     },
//     {
//         field: 'invoiceMessageDateReplied',
//         headerName: 'Date Replied',
//         width: 150,
//         renderCell: (params) => (params.value ? moment(params.value).format('DD-MMM-YYYY') : '-')
//     },
//     {
//         field: 'invoiceMessageReplyUser',
//         headerName: 'Reply User',
//         renderCell: (params) => (params.value ? params.value : '-'),
//         width: 150
//     }
// ];

const InvoiceMessage = (props) => {
    const { showToast, showModal } = useContext(AppContext);
    const [state, setState] = useState({
        error: {},
        messageList: [],
        list: []
    });

    useEffect(async () => {
        if (props.invoice) {
            let res = await getMessageGridList(props.invoice.invoicePDFID);
            let dataList = res?.data?.list || [];
            let newList = [];
            if (dataList.length > 0) {
                newList = dataList.map((n, i) => {
                    return { ...n, id: i };
                });
            }
            if (res.success) {
                setState((st) => ({
                    ...st,
                    messageList: newList
                }));
            }
        }
    }, []);

    let Validation = () => {
        let isValid = true;
        let error = {};
        if (!state.email && state.sendEmail) {
            isValid = false;
            error.email = 'Invoice Email is required';
        }
        if (!state.invoiceMessageText) {
            isValid = false;
            error.invoiceMessageText = 'Invoice Message is required';
        }
        setState((st) => ({
            ...st,
            error: error
        }));
        return isValid;
    };
    const SubmitMessage = async (status) => {
        if (Validation()) {
            let data = {
                id: null,
                invoiceMessageInvoiceID: props.invoice.invoiceId,
                invoiceMessageText: state.invoiceMessageText,
                invoiceMessageClosed: status,
                sendEmail: state.sendEmail || false,
                email: state.email,
                invoiceNumber: props.invoice.invoiceCreditNo001,
                invoiceMessagePDFID: props.invoice.invoicePDFID
            };

            let res = await postInvoiceMessage(data);
            if (res.success) {
                showToast(`Invoice Message ${status ? 'Closed' : 'Save'} Successfully`);
                props.onClose(true);
            } else {
                showToast(res.message);
            }
        }
    };

    const handleFieldChange = (e) => {
        const { name, value, checked, type } = e.target;

        if (type == 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                    <InputLabel>
                        <input type="checkbox" onChange={handleFieldChange} name="sendEmail" checked={state.sendEmail} /> &nbsp; Send Email
                    </InputLabel>
                </Grid> */}
                <Grid item xs={12} className="mandatoryfields">
                    <InputLabel style={{ color: state.sendEmail ? 'red' : 'black' }}>
                        <span>
                            <input type="checkbox" onChange={handleFieldChange} name="sendEmail" checked={state.sendEmail} /> Send Email
                        </span>
                    </InputLabel>
                    <TextBox type="email" onChange={handleFieldChange} name="email" value={state.email} />
                    <FormHelperText error>{state.error.email}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    {/* <InputLabel shrink>Email</InputLabel> */}
                    <TextBox type="text" multiline rows={6} onChange={handleFieldChange} name="invoiceMessageText" value={state.invoiceMessageText} />
                    <FormHelperText error>{state.error.invoiceMessageText}</FormHelperText>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton fullWidth onClick={() => SubmitMessage(false)}>
                        send
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton fullWidth onClick={() => SubmitMessage(true)}>
                        Close Message
                    </SecondaryButton>
                </Grid>
                <Grid item xs={12}>
                    {state.messageList.length > 0 ? (
                        <div style={{ border: '1px solid #E8E8E8', maxHeight: 500, overflow: 'auto' }} className="custom-scroll">
                            {state.messageList.map((m) => (
                                <div style={{ borderBottom: '1px solid #E8E8E8', padding: 10 }}>
                                    <div style={{ fontWeight: 500 }}>{m.invoiceMessageText} </div>

                                    <div style={{ paddingBottom: 4 }}>
                                        <span style={{ fontSize: 11 }}>
                                            From User: {m.invoiceMessageFromUser} @ {moment(m.invoiceMessageDateSent).format('LLL')} &nbsp;{' '}
                                            {m.invoiceMessageReplyUser
                                                ? `( Reply by :
                                            ${m.invoiceMessageReplyUser ? m.invoiceMessageReplyUser : ''} @
                                            ${m.invoiceMessageDateReplied ? moment(m.invoiceMessageDateReplied).format('LLL') : ''} )`
                                                : ''}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div style={{ border: '1px solid #E8E8E8' }}>
                            <div style={{ textAlign: 'center', padding: 10 }}>No Messages Available</div>
                        </div>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default InvoiceMessage;
