import {get, post} from './http-calls';
import {memoize} from 'lodash'

export const getBranches = memoize(async () => {
    let res = await get('/Branches', { useAuthToken: true });
    return res;
});

export const getBranchDepartments = async(branch) => {
    let res = await get(`branchdepartments/${branch}`,
    { useAuthToken: true });
    return res;
}

export const addUpdateDepartment = async(data) => {
    let res = await post(`branchdepartments`, data,
    { useAuthToken: true });
    return res;
}

export const deleteDepartment = async(branch) => {
    let res = await post(`branchdepartments/delete/${branch}`, null,
    { useAuthToken: true });
    return res;
}