import { useMemo, useState } from 'react';
import DialogComp from './../../../../Core/Controls/Dialog/DialogComp';
import { DialogTitle, Fab, Grid, Button, Avatar, Chip, IconButton } from '@material-ui/core';
import CommonGijgoGrid from '../../../../Core/Controls/GijgoGrid';
import moment from 'moment';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { EditOutlined } from '@material-ui/icons/EditOutlined';
import ReactDOM from 'react-dom';
import { CustomIconButton, SquareButton } from '../../../../Core/FormInput/AppButton';
import EditIcon from '@material-ui/icons/Edit';
import ReportGijgoGrid from '../../ReportsScreens/GijgoForReportsScreen';
import ListAltIcon from '@material-ui/icons/ListAlt';
import JobAllDocs from '../../workflow-job/more/AllDocModal';
import AddEditActionLog from '../OpenActionLogs/addEditActionLog';
import EditActionLog from '../OpenActionLogs/editActionLog';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import UpdateIcon from '@material-ui/icons/Update';
import BuildIcon from '@material-ui/icons/Build';

import CommunicatorAddVor from '../addUpdateVOR';
import LogHistory from '../OpenActionLogs/logHistory';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import CancelIcon from '@material-ui/icons/Cancel';
import BookingRequestedModal from '../../../customer/bookingRequested/bookingRequestedModal';
import AppContext from '../../../../App/AppContext';
import { useContext } from 'react';
import RequestBookingsHistoryModal from '../../../customer/bookingRequested/RequestBookingsHistory';

const baseColumns = [
    {
        title: 'Action',
        field: 'action',
        width: 60,
        filterable: false
    },
    { field: 'registration', sortable: true, title: `${'Vehicle Reg'}`, width: 120 },
    { field: 'dateRequested', sortable: true, title: `${'Requested Date'}`, width: 200 },
    { field: 'make', sortable: true, title: `${'Make'}`, width: 200 },
    { field: 'model', sortable: true, title: `${'Model'}`, width: 200 },
    { field: 'branchName', sortable: true, title: `${'Branch'}`, width: 120 }
];

const crumbs = (paramStatus, val, objList) => {
    let tempCrum = [
        { name: 'Home', path: '/' },
        { name: 'Communicator', path: '/communicator_dash' }
    ];
    if (paramStatus) {
        tempCrum.push({ name: `${objList[paramStatus]}`, active: true });
    } else {
        tempCrum.push({ name: objList[val], active: true });
    }
    return tempCrum;
};

const RequestedBookingsScreen = (props) => {
    const { showToast, userRoles } = useContext(AppContext);
    const isCustomer = userRoles.includes('customer');
    const isInternal = userRoles.includes('internal');
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamStatus = params.get('status') || '';
    const screenName = {
        1: isInternal ? 'Request Received' : 'Request Sent',
        2: isCustomer ? 'Re-Schedule Received' : 'Re-Schedule Sent',
        3: isCustomer ? 'Re-Schedule Sent' : 'Re-Schedule Received',
        4: 'Booking Confirmed',
        5: 'Booking Cancelled'
    };
    const [show, setShow] = useState({
        siteVehicle: false,
        actionLog: false,
        showVORModal: false
    });
    const [state, setState] = useState({});

    const handleEditModal = (val) => {
        setShow((st) => ({ ...st, isBookingRequesteModal: true, selectedRecordData: val }));
    };

    const closeModal = (val) => {
        setShow((st) => {
            let newSt = { ...st };
            newSt.isBookingAcceptModal = false;
            newSt.isBookingReScheduleModal = false;
            newSt.isBookingCancelModal = false;
            newSt.isBookingRequesteModal = false;
            if (val) {
                newSt.isReload = new Date();
            }
            return newSt;
        });
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" spacing={1} justify="center">
                <Grid item>
                    <CustomIconButton onClick={() => handleEditModal(record)} toolTipTitle="Edit">
                        <AssignmentTurnedInIcon fontSize="medium" style={{ color: record.highPriority ? 'white' : '#183B68' }} />
                    </CustomIconButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');

        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);

    const getUrl = `CommunicatorBookingRequest/GetBookingRequestList?StatusID=${ParamStatus || props.status}`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(ParamStatus, props.status, screenName)} />
            <Grid container>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={column}
                        getUrl={getUrl}
                        displayPagination={true}
                        isReload={show.isReload}
                    />
                </Grid>
            </Grid>

            {show.isBookingRequesteModal && (
                // <DialogComp
                //     title={`Bookings ${show.selectedRecordData.status ? `(${show.selectedRecordData.status})` : ''}`}
                //     onClose={() => closeModal(false)}
                //     maxWidth="md"
                //     fullWidth
                //     overflow="auto"
                // >
                <BookingRequestedModal onClose={closeModal} selectedRecordData={show.selectedRecordData} isShowHistory={true} />
                // </DialogComp>
            )}
        </div>
    );
};
export default RequestedBookingsScreen;
