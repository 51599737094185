import { nicoleDownloadFileWithExt } from './ReportScreens-service';
import { get, post } from './http-calls';

export let getDocumentByName = async (docName, pageNo, scrollWidth) => {
    let data = await get(`WorkflowDocument/${docName}${pageNo ? `/${pageNo}` : ''}?${scrollWidth ? `maxSizeCap=${scrollWidth}` : ''}`, { useAuthToken: true });
    // let data = await get(`WorkflowDocument/${docName}${pageNo ? `/${pageNo}` : ''}`, { useAuthToken: true });
    return data;
};

export let getDocumentVideoByName = async (docName) => {
    let data = await get(`WorkflowDocument/GetVideo/${docName}`, { responseType: 'blob', returnOrgRes: true, useAuthToken: true });
    return data;
};

export let uploadOcrDoc = async (jobId, ocrDocId, formData) => {
    let data = await post(`WorkflowDocument/${jobId}/${ocrDocId}`, formData, { useAuthToken: true });
    return data;
};

export const getOcrDocumentHistoryForJob = async (jobId, ocrDocId) => {
    let res = await get(`WorkflowDocument/history/${jobId}/${ocrDocId}`, { useAuthToken: true });
    return res;
};

export const deleteJobOcrDocument = async (jobId, ocrDocumentId, jobDocumentId, deletingCurrent) => {
    let res = await post(
        `WorkflowDocument/delete`,
        {
            jobId,
            ocrDocumentId,
            jobDocumentId,
            deletingCurrent
        },
        { useAuthToken: true }
    );
    return res;
};

export const setAsCurrentDocument = async (jobId, ocrDocumentId, jobDocumentId) => {
    let res = await post(
        `WorkflowDocument/MoveToSameJob`,
        {
            jobId,
            ocrDocumentId,
            jobDocumentId
        },
        { useAuthToken: true }
    );
    return res;
};

export const searchJobByText = async (jobId, searchText) => {
    let res = await get(`WorkflowDocument/SearchWip/${jobId}/${searchText}`, { useAuthToken: true });
    return res;
};

export const moveDocToOtherJob = async (jobId, nextJobId, ocrDocumentId, jobDocumentId, isCurrent) => {
    let res = await post(
        `WorkflowDocument/MoveToOtherJob`,
        {
            jobId,
            nextJobId,
            ocrDocumentId,
            jobDocumentId,
            isCurrent
        },
        { useAuthToken: true }
    );
    return res;
};

export const mailDocument = async (data) => {
    let res = await post(`WorkflowDocument/SendMail`, data, { useAuthToken: true });
    return res;
};

export const mailNewStockDocument = async (data) => {
    let res = await post(`NewStockDocument/SendMail`, data, { useAuthToken: true });
    return res;
};

export const mailUsedStockDocument = async (data) => {
    let res = await post(`UsedStockDocument/SendMail`, data, { useAuthToken: true });
    return res;
};

export const downloadDocuments = async (params, fileName) => {
    let res = await post(`WorkflowDocument/DownloadOcrDoc`, params, {
        ...(params.SendAllVersions ? {} : { responseType: 'blob', returnOrgRes: true }),
        useAuthToken: true
    });

    return params.SendAllVersions ? res : nicoleDownloadFileWithExt(res, 'pdf', fileName, true);
};

export const getNewStockDocumentOcrDocumentHistoryForJob = async (StockID, ocrDocId) => {
    let res = await get(`NewStockDocument/history/${StockID}/${ocrDocId}`, { useAuthToken: true });
    return res;
};

export const getUsedStockDocumentOcrDocumentHistoryForJob = async (StockID, ocrDocId) => {
    let res = await get(`UsedStockDocument/history/${StockID}/${ocrDocId}`, { useAuthToken: true });
    return res;
};

export let getNewStockDocumentByName = async (docName, pageNo) => {
    let data = await get(`NewStockDocument/${docName}${pageNo ? `/${pageNo}` : ''}`, { useAuthToken: true });
    return data;
};

export let getUsedStockDocumentByName = async (docName, pageNo) => {
    let data = await get(`UsedStockDocument/${docName}${pageNo ? `/${pageNo}` : ''}`, { useAuthToken: true });
    return data;
};

export let uploadNewStockDocumentOcrDoc = async (jobId, ocrDocId, formData) => {
    let data = await post(`NewStockDocument/${jobId}/${ocrDocId}`, formData, { useAuthToken: true });
    return data;
};

export let uploadUsedStockDocumentOcrDoc = async (jobId, ocrDocId, formData) => {
    let data = await post(`UsedStockDocument/${jobId}/${ocrDocId}`, formData, { useAuthToken: true });
    return data;
};

export const NewMoveDocToOtherJob = async (StockID, nextStockID, ocrDocumentId, jobDocumentId, isCurrent) => {
    let res = await post(
        `NewStockDocument/MoveToOtherJob`,
        {
            StockID,
            nextStockID,
            ocrDocumentId,
            jobDocumentId,
            isCurrent
        },
        { useAuthToken: true }
    );
    return res;
};

export const UsedMoveDocToOtherJob = async (StockID, nextStockID, ocrDocumentId, jobDocumentId, isCurrent) => {
    let res = await post(
        `UsedStockDocument/MoveToOtherJob`,
        {
            StockID,
            nextStockID,
            ocrDocumentId,
            jobDocumentId,
            isCurrent
        },
        { useAuthToken: true }
    );
    return res;
};

export const NewsetAsCurrentDocument = async (StockID, ocrDocumentId, jobDocumentId) => {
    let res = await post(
        `NewStockDocument/MoveToSameJob`,
        {
            StockID,
            ocrDocumentId,
            jobDocumentId
        },
        { useAuthToken: true }
    );
    return res;
};

export const UsedsetAsCurrentDocument = async (StockID, ocrDocumentId, jobDocumentId) => {
    let res = await post(
        `UsedStockDocument/MoveToSameJob`,
        {
            StockID,
            ocrDocumentId,
            jobDocumentId
        },
        { useAuthToken: true }
    );
    return res;
};

export const NewDeleteJobOcrDocument = async (StockID, ocrDocumentId, jobDocumentId, deletingCurrent) => {
    let res = await post(
        `NewStockDocument/delete`,
        {
            StockID,
            ocrDocumentId,
            jobDocumentId,
            deletingCurrent
        },
        { useAuthToken: true }
    );
    return res;
};

export const UsedDeleteJobOcrDocument = async (StockID, ocrDocumentId, jobDocumentId, deletingCurrent) => {
    let res = await post(
        `UsedStockDocument/delete`,
        {
            StockID,
            ocrDocumentId,
            jobDocumentId,
            deletingCurrent
        },
        { useAuthToken: true }
    );
    return res;
};

export const NewStockSearchJobByText = async (StockID, searchText) => {
    let res = await get(`NewStockDocument/SearchWip/${StockID}/${searchText}`, { useAuthToken: true });
    return res;
};

export const UsedStockSearchJobByText = async (StockID, searchText) => {
    let res = await get(`UsedStockDocument/SearchWip/${StockID}/${searchText}`, { useAuthToken: true });
    return res;
};
