import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import AppContext from '../../../../App/AppContext';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { getOcrDocumentToProcess, getOcrDocuments } from './../../../../Core/Service/OcrDocuments-service';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { SecondaryButton } from '../../../../Core/FormInput/index';
import AddOCRDocToProcess from './AddUpdateModal';
import DataGridComp from '../../../../Core/Grid';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DocumentFieldToProcess from './DocumentFieldModal';

const OcrDocumentToProcess = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        showDocumentFieldModal: false,
        branches: [],
        OCRName: ''
    });
    const { showToast } = useContext(AppContext);
    const ocrDocId = props.match.params.ocrDocId;
    const name = props.match.params.name;

    useEffect(async () => {
        pullOCRDocToprocessAndUpdateState();
    }, []);

    const column_new = [
        {
            field: 'id',
            headerName: 'ID',
            width: 130,
            sortable: false,
            hide: true
        },
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 100,
            renderCell: (params) => <Arrow value={params} />
        },
        {
            field: 'inputLocation',
            headerName: 'Input Location',
            flex: 1
        },
        {
            field: 'outputLocation',
            headerName: 'Output Location',
            flex: 1
        },
        {
            field: 'branch',
            headerName: 'Branch',
            flex: 0.6
        }
    ];

    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update OCR Document To Process" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Update Document Field" aria-label="add">
                    <IconButton size="small" onClick={() => handleDocumentFieldModal(params)}>
                        <AssignmentIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const pullOCRDocToprocessAndUpdateState = async () => {
        let result = await getOcrDocumentToProcess(ocrDocId);
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data,
                show: false
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };
    const handleUpdate = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                OCRDocToProcessList: params.value.row
            };
            return nst;
        });
    };

    const handleDocumentFieldModal = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                showDocumentFieldModal: true,
                OCRDocToProcessList: params.value.row
            };
            return nst;
        });
    };

    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                OCRDocToProcessList: []
            };
        });
    };

    const modalClosed = (refreshGrid) => {
        setState((st) => ({ ...st, show: false, showDocumentFieldModal: false }));
        refreshGrid && pullOCRDocToprocessAndUpdateState();
    };

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No OCR Document To process available</div>
            </GridOverlay>
        );
    }
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                    Add OCR Document Process
                </SecondaryButton>
            </div>

            {state.show ? <AddOCRDocToProcess onFormSubmit={modalClosed} OCRDocToProcessList={state.OCRDocToProcessList} ocrDocId={ocrDocId} /> : null}
            {state.showDocumentFieldModal ? <DocumentFieldToProcess onFormSubmit={modalClosed} OCRDocToProcessList={state.OCRDocToProcessList} ocrDocId={ocrDocId} /> : null}

            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Link color="inherit" to={'/ocrdocuments'}>
                    OCR Documents
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    OCR Document To Process
                </Typography>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    {name}
                </Typography>
            </Breadcrumbs>
            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default OcrDocumentToProcess;
