import React, { useState } from 'react';
import { SignPad } from '../../../SignaturePad/SignPad';
import { CustomeSignPad } from '../../../SignaturePad';
import { SecondaryButton } from '../../../FormInput';
// import { calculateReadonly } from '../../_services/StaticService';

let UserSignature = function ({ config, state, onFieldChange, readOnly }) {
    let controlName = config.screenColumnJsonName;
    let controlValue = state[controlName];

    //let req = otherConfig.editMode ? config.editRequired : config.required;

    let hidden = false;

    let [localState, setLocalState] = useState({
        imgSrc: controlValue,
        signed: !!controlValue
    });

    const GetTechnicianSign = (val) => {
        onFieldChange(val);
    };

    const clearSig = (val) => {
        onFieldChange(null);
    };

    function signedDone(mImgSrc, anyChange) {
        setLocalState({ imgSrc: anyChange ? mImgSrc : '', signed: anyChange });
        onFieldChange({ [controlName]: anyChange ? mImgSrc : '' });
    }

    function signCleared() {
        signedDone(undefined, false);
    }

    return (
        <div className="sign-box">
            {!hidden && (
                <SignPad
                    onClear={signCleared}
                    onDone={signedDone}
                    isDrawnImage={localState.signed}
                    readOnly={readOnly}
                    showSubmitButton={false}
                    showClearButton={!readOnly}
                    bgImage={controlValue}
                    submitOnDrawingEnd={true}
                    submitButtonText="Sign"
                    showCrossClearBtn={true}
                    showDefaultSign={true}
                ></SignPad>
            )}
        </div>
        // <div className="sign-box">
        //     {!hidden && <CustomeSignPad onChange={GetTechnicianSign} sign={controlValue} name={controlName} clearSig={clearSig} />}
        //     {/* {localState.signed && (
        //         <SecondaryButton title="Signed" clearSig={clearSig}>
        //             Clear
        //         </SecondaryButton>
        //     )} */}
        // </div>
    );
};

export default UserSignature;
