import { FormHelperText, FormLabel, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { SecondaryButton, SelectBox } from '../../../Core/FormInput';
import { getGetOldJobs_Workflows, postMakeJobActive } from '../../../Core/Service/archivedJobs-service';
import AppContext from '../../../App/AppContext';

const SendToWorkflowModal = (props) => {
    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({ errors: {}, worflowsList: [], workflowID: '' });

    useEffect(() => {
        const fetchDropDown = async () => {
            let res = await getGetOldJobs_Workflows();
            if (res.success) {
                let filteredValue = res.data.worflows.find((q) => q.isEntryPoint);
                setState((st) => {
                    const nst = { ...st, worflowsList: res.data.worflows, workflowID: filteredValue ? filteredValue.id : '' };
                    return nst;
                });
            } else {
            }
        };
        fetchDropDown();
    }, []);

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handelSubmit = async () => {
        let res = await postMakeJobActive(props.jobDetails.headerRecordID, state.workflowID);
        if (res.success) {
            showToast(res.data.message);
            props.onClose(true);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={4} md={3}>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <FormLabel style={{ fontWeight: 'bold' }}>Wip No</FormLabel>
                    </Grid>
                    <Grid item>
                        <FormLabel>{props.jobDetails.wipNumber}</FormLabel>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} md={3}>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <FormLabel style={{ fontWeight: 'bold' }}>Reg No</FormLabel>
                    </Grid>
                    <Grid item>
                        <FormLabel>{props.jobDetails.registration}</FormLabel>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} md={6}>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <FormLabel style={{ fontWeight: 'bold' }}>Customer</FormLabel>
                    </Grid>
                    <Grid item>
                        <FormLabel>{props.jobDetails.customerName}</FormLabel>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FormLabel component="legend" error>
                    Send To Workflow
                </FormLabel>
                <SelectBox style={{ marginTop: '8px' }} onChange={fieldChange} value={state.workflowID} name="workflowID" List={state.worflowsList || []} />
                <FormHelperText error>{state.errors.workflowID}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton className="Submitbtn" onClick={() => handelSubmit(false)} disabled={!state.workflowID}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default SendToWorkflowModal;
