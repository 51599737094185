import React, { useEffect, useState, useContext } from 'react';
import { getInvoiceBranchByID, postInvoiceBranch } from '../../../Core/Service/admin-Invoice_Branch-service';
import { Grid, InputLabel, Typography, FormLabel } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../Core/FormInput';
import AppContext from './../../../App/AppContext';
const EditBranchScreen = (props) => {
    const [state, setState] = useState({
        branchInvoice: [],
        branchID: props.branchID
    });

    const { showToast, showModal } = useContext(AppContext);

    useEffect(async () => {
        if (props.branchID) {
            let res = await getInvoiceBranchByID(props.branchID);
            if (res.success) {
                let data = res.data.branches.table[0];

                setState((st) => ({
                    ...st,
                    branchInvoice: res.data.branchInvoice.table,
                    shortName: data.branchShortName,
                    branchCode: data.branchCode,
                    name: data.branchName,
                    address1: data.branchAddressLine1,
                    address2: data.branchAddressLine2,
                    address3: data.branchAddressLine3,
                    town: data.branchTown,
                    county: data.branchCounty,
                    country: data.branchCountry,
                    postcode: data.branchPostCode,
                    phone: data.branchPhone,
                    branchID: data.branchID
                }));
            }
        }
    }, []);

    const handleInput = (val) => (e) => {
        const { name, value } = e.target;
        let newBranchInvoice = [...state.branchInvoice];
        let index = state.branchInvoice.findIndex((m) => m.departmentCode == val);
        if (index) {
            newBranchInvoice[index].branchInvoiceResponseEmail = value;
        }
        setState((st) => ({
            ...st,
            branchInvoice: newBranchInvoice
        }));
    };

    const handleSubmit = async () => {
        let branchInvoiceList = state.branchInvoice.map((m) => ({
            BranchID: props.branchID,
            departmentCode: m.departmentCode,
            departmentDescription: m.departmentDescription,
            branchInvoiceResponseID: m.branchInvoiceResponseID,
            branchInvoiceResponseEmail: m.branchInvoiceResponseEmail || ''
        }));

        let data = {
            branchInvoiceResponses: branchInvoiceList
        };

        let res = await postInvoiceBranch(data);
        if (res[0].success) {
            showToast(`Branches Updated Successfully`);
            props.onClose();
        } else {
            console.error(res.message);
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                {state.branchInvoice.map((m) => {
                    return (
                        <>
                            <Grid item xs={4}>
                                <Typography variant="button">{m.departmentDescription}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <InputLabel shrink component="legend">
                                    Invoice Response Email
                                </InputLabel>
                                <TextBox value={m.branchInvoiceResponseEmail} name="branchInvoiceResponseEmail" onChange={handleInput(m.departmentCode)} />
                            </Grid>
                        </>
                    );
                })}
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default EditBranchScreen;
