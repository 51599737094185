import {get, post} from './http-calls';
import {memoize} from 'lodash'


export const getauditorDashboard = memoize(async () => {
    let res = await get('AuditorJobs/Dashboard', { useAuthToken: true });
    return res;
});

export const getAuditorList = memoize(async()=>{
    let res = await get('Auditor/Search', { useAuthToken: true });
    return res;
});

export const assignAuditorsToJob = async(jobId, isUpdate, auditors)=>{
    let res = await post('Auditor/AssignJobs',{
        jobId,
        isUpdate,
        auditors
    }, { useAuthToken: true });
    return res;
};

export const getAuditorsByJob = async jobId=>{
    let res = await get(`Auditor/GetByJob/${jobId}`, { useAuthToken: true });
    return res;
};