import React from 'react';
import { FormHelperText, Grid } from '@material-ui/core';
import { SelectBox, SingelSelect, TextBoxShrink } from '../../../../Core/FormInput';
import { get } from 'lodash';

export const CustDetails = React.memo((props) => {
    const { state, setState } = props;
    const setField = (e) => {
        const { name, type, value, valueAsNumber, checked } = e.target;
        if (type === 'number') {
            setState((st) => ({ ...st, [name]: valueAsNumber }));
        } else if (type === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };
    const getField = (name) => {
        return get(state, name);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={3} className="mandatoryfields">
                <TextBoxShrink
                    inputProps={{ maxLength: 64 }}
                    onChange={setField}
                    value={getField('service24CustomerName')}
                    label="Name"
                    name="service24CustomerName"
                />
                <FormHelperText error>{state.errors.service24CustomerName}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 50 }}
                    onChange={setField}
                    value={getField('service24CustomerAddress1')}
                    label="Address Line 1"
                    name="service24CustomerAddress1"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 50 }}
                    onChange={setField}
                    value={getField('service24CustomerAddress2')}
                    label="Address Line 2"
                    name="service24CustomerAddress2"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 50 }}
                    onChange={setField}
                    value={getField('service24CustomerCity')}
                    label="City"
                    name="service24CustomerCity"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 10 }}
                    onChange={setField}
                    value={getField('service24CustomerPostCode')}
                    label="Postcode"
                    name="service24CustomerPostCode"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 20 }}
                    onChange={setField}
                    value={getField('service24CustomerPhone')}
                    label="Tel No"
                    name="service24CustomerPhone"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 50 }}
                    onChange={setField}
                    value={getField('service24CustomerContactName')}
                    label="Contact Name"
                    name="service24CustomerContactName"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 20 }}
                    onChange={setField}
                    value={getField('service24CustomerOrderNo')}
                    label="Order No"
                    name="service24CustomerOrderNo"
                />
            </Grid>
        </Grid>
    );
});
