import { FormLabel, Grid, InputLabel } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { SketchPicker } from 'react-color';
import AppContext from '../../../../App/AppContext';
import { CustomColorPicker, DefaultButton, SecondaryButton, TextBox, YesNoButton } from '../../../../Core/FormInput';
import { addUpdateResultListOption } from '../../../../Core/Service/qc-service';

const EditResultOption = (props) => {
    const [state, setState] = useState({
        QCResultOptionID: props?.focusedRow?.qcResultOptionID ? props?.focusedRow?.qcResultOptionID : null,
        QCResultOptionDescription: props?.focusedRow?.qcResultOptionDescription ? props?.focusedRow?.qcResultOptionDescription : '',
        QCResultOptionActive: props?.focusedRow?.qcResultOptionActive ? props?.focusedRow?.qcResultOptionActive : true,
        QCResultOptionBackgroundColour: props?.focusedRow?.qcResultOptionBackgroundColour ? props?.focusedRow?.qcResultOptionBackgroundColour : '#ff0000',
        QCResultOptionForegroundColour: props?.focusedRow?.qcResultOptionForegroundColour ? props?.focusedRow?.qcResultOptionForegroundColour : '#ff0000',
        QCResultOptionDefectRequired: props.focusedRow?.qcResultOptionDefectRequired || false,
        QCResultOptionIsFailed: props.focusedRow?.qcResultOptionIsFailed || false,

        errors: []
    });
    const { showToast } = useContext(AppContext);

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        setState((st) => ({
            ...st,
            [name]: val
        }));
    };

    const onColorChange = (updatedColor, name) => {
        if (updatedColor) {
            setState((state) => ({
                ...state,
                [name]: updatedColor?.hex
            }));
        }
    };

    const validations = () => {
        const { QCResultOptionDescription } = state;
        let formIsValid = true;
        let errors = {};

        if (QCResultOptionDescription == null || QCResultOptionDescription == '') {
            errors.QCResultOptionDescription = 'Description field is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            const data = {
                QCResultOptionID: state.QCResultOptionID ? state.QCResultOptionID : null,
                QCResultOptionDescription: state.QCResultOptionDescription,
                QCResultOptionActive: state.QCResultOptionActive ? state.QCResultOptionActive : true,
                QCResultOptionListID: props.QCResultListID,
                QCResultOptionBackgroundColour: state.QCResultOptionBackgroundColour ? state.QCResultOptionBackgroundColour : '',
                QCResultOptionForegroundColour: state.QCResultOptionForegroundColour ? state.QCResultOptionForegroundColour : '',
                QCResultOptionDefectRequired: state.QCResultOptionDefectRequired,
                QCResultOptionIsFailed: state.QCResultOptionIsFailed
            };
            let res = await addUpdateResultListOption(data);
            if (!res.success) {
                console.error(res);
                props.onClose(false);
                showToast('Error occured.');
            } else {
                showToast('QC Results List option updated successfully');
                props.onClose(true);
            }
        }
    };

    const handleClick = (compon, val) => {
        setState((st) => ({
            ...st,
            [compon]: val
        }));
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        Results List Option Description
                    </FormLabel>
                    <TextBox value={state.QCResultOptionDescription} name="QCResultOptionDescription" onChange={handleFieldChange} />
                    <span className="mandatoryfields">{state.errors['QCResultOptionDescription']}</span>
                </Grid>

                <Grid item xs={6} md={6} sm={6} lg={6} style={{ marginTop: -5 }}>
                    <FormLabel style={{ marginBottom: 7 }}>Background Colour</FormLabel>
                    <CustomColorPicker
                        style={{ paddingLeft: 10 }}
                        height="15px"
                        width="250px"
                        name="QCResultOptionBackgroundColour"
                        id="QCResultOptionBackgroundColour"
                        color={state.QCResultOptionBackgroundColour}
                        onChange={(m) => onColorChange(m, 'QCResultOptionBackgroundColour')}
                    />
                </Grid>
                <Grid item xs={6} md={6} sm={6} lg={6} style={{ marginTop: -5 }}>
                    <FormLabel style={{ marginBottom: 7 }}>Foreground Colour</FormLabel>
                    <CustomColorPicker
                        style={{ paddingLeft: 10 }}
                        height="15px"
                        width="250px"
                        name="QCResultOptionForegroundColour"
                        id="QCResultOptionForegroundColour"
                        color={state.QCResultOptionForegroundColour}
                        onChange={(m) => onColorChange(m, 'QCResultOptionForegroundColour')}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid xs={12} container item>
                        <Grid item xs={2} sm={2}>
                            <FormLabel className="btn_add"> Is Required?</FormLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.QCResultOptionDefectRequired}
                                name="QCResultOptionDefectRequired"
                                onYesClick={() => handleClick('QCResultOptionDefectRequired', true)}
                                onNoClick={() => handleClick('QCResultOptionDefectRequired', false)}
                            />
                        </Grid>
                    </Grid>
                    <Grid xs={12} container item>
                        <Grid item xs={2} sm={2}>
                            <FormLabel className="btn_add"> Is Failed?</FormLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.QCResultOptionIsFailed}
                                name="QCResultOptionIsFailed"
                                onYesClick={() => handleClick('QCResultOptionIsFailed', true)}
                                onNoClick={() => handleClick('QCResultOptionIsFailed', false)}
                            />
                        </Grid>
                    </Grid>
                    <Grid xs={12} container item>
                        <Grid item xs={2} sm={2}>
                            <FormLabel className="btn_add"> Is Active?</FormLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.QCResultOptionActive}
                                name="QCResultOptionActive"
                                onYesClick={() => handleClick('QCResultOptionActive', true)}
                                onNoClick={() => handleClick('QCResultOptionActive', false)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};
export default EditResultOption;
