import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
// import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import { useHistory } from 'react-router-dom';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#f5f5f5',
        color: 'black',
        padding: 8,
        fontWeight: 700
    },
    body: {
        fontSize: 14,
        padding: 8
    }
}))(TableCell);
const HeaderTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        padding: 8
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9)
];

const useStyles = makeStyles({
    table: {
        minWidth: '100%'
    }
});

export const TopTenTable = (props) => {
    const classes = useStyles();

    const { label, col, rows } = props;

    const reDirectTO = (label, row) => {
        let searchQuery = `?rrId=${row.rrId || ''}&rrName=${row.column1 || ''}&startDate=${props.start || ''}&endDate=${props.end || ''}&cwWorkflowId=${
            row.workflowId || ''
        }&cwWorkflowName=${row.workflow || ''}&SelectedUserId=${row.userId || ''}&SelectedUserName=${row.displayName || ''}`;
        const win = window.open(`/internal/dashboard/ReturnReasonList${searchQuery}`, '_blank');
        win.focus();
    };

    return (
        <TableContainer style={{ maxHeight: 500 }}>
            <Table className={classes.table} aria-label="customized table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <HeaderTableCell colSpan={2} align="center">
                            TOP 10 {label}
                        </HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableHead>
                    <TableRow>
                        {col.map((n) => {
                            return <StyledTableCell key={n}>{n}</StyledTableCell>;
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.name} onClick={() => reDirectTO(label, row)} style={{ cursor: 'pointer' }}>
                            <StyledTableCell component="th" scope="row">
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell>{row.value}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export const ByWorkFlow = (props) => {
    const classes = useStyles();

    const reDirectTO = (row) => {
        let searchQuery = `?rrId=${row.rrId || ''}&startDate=${props.start || ''}&endDate=${props.end || ''}&cwWorkflowId=${
            row.workflowId || ''
        }&SelectedUserId=${row.userId || ''}&rrName=${row.reason || ''}&cwWorkflowName=${row.workflow || ''}&SelectedUserName=${row.name || ''}&BranchID=${
            row.branchID || ''
        }&BranchName=${row.branch || ''}`;
        const win = window.open(`/internal/dashboard/ReturnReasonList${searchQuery}`, '_blank');
        win.focus();
    };

    return (
        <TableContainer style={{ maxHeight: 430 }}>
            <Table className={classes.table} aria-label="customized table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <HeaderTableCell>WorkFlow</HeaderTableCell>
                        <HeaderTableCell>Return reason </HeaderTableCell>
                        <HeaderTableCell>count</HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.workflowReturnReasons.map((row, i) => (
                        <StyledTableRow key={row.workflow} onClick={() => reDirectTO(row)} style={{ cursor: 'pointer' }}>
                            <StyledTableCell component="th" scope="row">
                                {row.workflow}
                            </StyledTableCell>
                            <StyledTableCell>{row.reason}</StyledTableCell>
                            <StyledTableCell>{row.total}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export const AllReaturnReason = (props) => {
    const classes = useStyles();

    const redirectTo = (data) => {
        const win = window.open(`/Job/${data.hid}/${data.wid}`, '_blank');
        win.focus();
    };

    return (
        <TableContainer style={{ maxHeight: 430 }}>
            <Table className={classes.table} aria-label="customized table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <HeaderTableCell>Branch</HeaderTableCell>
                        <HeaderTableCell>Return Reasons</HeaderTableCell>
                        <HeaderTableCell width={'10%'}>User </HeaderTableCell>
                        <HeaderTableCell>From Workflow</HeaderTableCell>
                        <HeaderTableCell>To Workflow</HeaderTableCell>
                        <HeaderTableCell>Customer</HeaderTableCell>
                        <HeaderTableCell width={'10%'}>Wip No</HeaderTableCell>
                        <HeaderTableCell>Date Due</HeaderTableCell>
                        <HeaderTableCell>Reg no</HeaderTableCell>
                        <HeaderTableCell>Make/Model </HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.allReturnReasons.map((row, i) => (
                        <StyledTableRow key={row.br} onClick={() => redirectTo(row)} style={{ cursor: 'pointer' }}>
                            <StyledTableCell component="th" scope="row">
                                {row.br}
                            </StyledTableCell>
                            <StyledTableCell>{row.rr}</StyledTableCell>
                            <StyledTableCell>{row.u}</StyledTableCell>
                            <StyledTableCell>{row.cw}</StyledTableCell>
                            <StyledTableCell>{row.nw}</StyledTableCell>
                            <StyledTableCell>{row.c}</StyledTableCell>
                            <StyledTableCell>{row.wn}</StyledTableCell>
                            <StyledTableCell>{moment(row.dd).format('DD/MM/YYYY')}</StyledTableCell>
                            <StyledTableCell>{row.reg}</StyledTableCell>
                            <StyledTableCell>{row.mm}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export const ByBranch = (props) => {
    const classes = useStyles();

    const reDirectTO = (row) => {
        let searchQuery = `?rrId=${row.rrId || ''}&startDate=${props.start || ''}&endDate=${props.end || ''}&cwWorkflowId=${
            row.workflowId || ''
        }&SelectedUserId=${row.userId || ''}&rrName=${row.reason || ''}&cwWorkflowName=${row.workflow || ''}&SelectedUserName=${row.name || ''}&BranchID=${
            row.branchID || ''
        }&BranchName=${row.branch || ''}`;
        const win = window.open(`/internal/dashboard/ReturnReasonList${searchQuery}`, '_blank');
        win.focus();
    };

    return (
        <TableContainer style={{ maxHeight: 400 }}>
            <Table className={classes.table} aria-label="customized table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <HeaderTableCell>Branch</HeaderTableCell>
                        <HeaderTableCell>User </HeaderTableCell>
                        <HeaderTableCell>Reason</HeaderTableCell>
                        <HeaderTableCell>Total</HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.branchReturnReasons.map((row, i) => (
                        <StyledTableRow key={row.branch} onClick={() => reDirectTO(row)} style={{ cursor: 'pointer' }}>
                            <StyledTableCell component="th" scope="row">
                                {row.branch}
                            </StyledTableCell>
                            <StyledTableCell>{row.name}</StyledTableCell>
                            <StyledTableCell>{row.reason}</StyledTableCell>
                            <StyledTableCell>{row.total}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
