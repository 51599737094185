import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { SecondaryButton, PrimaryButton, DefaultButton, SelectBox } from '../../../Core/FormInput';
import { getWorkflowStepList, getWebForms, getWorkflowDataScreenDropdown } from '../../../Core/Service/workflowList-service';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, InputLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import '../User Management/UserManagement.css';
import { TextBox, PrimaryCheckbox, DialogContents, DialogTitles, Dialogs, DialogsActions } from '../../../Core/FormInput';
import { getOcrDocuments } from '../../../Core/Service/OcrDocuments-service';
import { addWorkflowSteps } from '../../../Core/Service/workflowList-service';

const AddWorkflowStep = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const workflowId = props.workflowId;
    const WorkflowStepDetails = props.WorkflowStepDetails;
    const [workflowType, setWorkflowType] = useState({
        isNoOption: true,
        isListType: false,
        isTextboxType: false
    });
    const [state, setState] = useState({
        SteplList: [],
        stepListId: '',
        OcrDocumnetList: [],
        documentName: '',
        WebFormList: [],
        webFormId: null,
        dynamicFormID: null,
        IsEditable: false,
        IsRequired: false,
        IsVisibleAuditor: false,
        IsVisibleCutomer: false,
        IsActive: true,
        Label: '',
        jsonProperty: '',
        sequence: '',
        stepID: null,
        isTextboxType: false,
        noOption: false,
        issteplist: true,
        errors: {}
    });

    useEffect(async () => {
        let res = await getWorkflowDataScreenDropdown(workflowId);
        if (res.success) {
            setState((st) => ({
                ...st,
                SteplList: res?.data?.workflowSteps,
                OcrDocumnetList: res?.data?.ocrDoc,
                WebFormList: res?.data.webForm,
                dynamicFormList: res?.data?.dynamicForm
            }));
        }

        if (WorkflowStepDetails && WorkflowStepDetails.id) {
            setState((st) => ({
                ...st,
                stepID: WorkflowStepDetails.id,
                IsEditable: WorkflowStepDetails.isEditable,
                IsVisibleAuditor: WorkflowStepDetails.isVisible_Auditor,
                IsVisibleCutomer: WorkflowStepDetails.isVisible_Customer,
                IsRequired: WorkflowStepDetails.isRequired,
                documentName: WorkflowStepDetails.ocrDocumentId,
                sequence: WorkflowStepDetails.stepIndex,
                Label: WorkflowStepDetails.stepLabel,
                stepListId: WorkflowStepDetails.stepListId,
                webFormId: WorkflowStepDetails.webFormTypeId,
                dynamicFormID: WorkflowStepDetails?.dynamicFormID,
                jsonProperty: WorkflowStepDetails.jsonPropertyName,
                IsActive: WorkflowStepDetails.isDeleted ? false : true,
                issteplist: WorkflowStepDetails.stepListId == null || WorkflowStepDetails.stepListId == 0 ? false : true
            }));
            setWorkflowType((wt) => {
                let newSt = { ...wt };
                newSt.isTextboxType = WorkflowStepDetails.isTextboxType;
                newSt.isListType = WorkflowStepDetails.stepListId;
                if (WorkflowStepDetails.isTextboxType || WorkflowStepDetails.stepListId) {
                    newSt.isNoOption = false;
                }
                return newSt;
            });
        }
    }, []);
    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (!state.Label) {
            formIsValid = false;
            errors['Label'] = 'Label cannot be empty';
        }
        if (!state.jsonProperty) {
            formIsValid = false;
            errors['jsonProperty'] = 'jsonProperty cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    const handelSubmit = async () => {
        if (handleValidation()) {
            if (workflowType.isListType) {
                state.isTextboxType = false;
            } else {
                state.stepListId = null;
            }
            let res = await addWorkflowSteps(workflowId, state.stepID, state, workflowType);
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
                props.onFormSubmit(true);
            }
            showToast(res.message);
        }
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };
    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };

            return nst;
        });
    };
    const validateSpace = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st };
            if (value.includes(' ')) {
                nst.jsonProperty = value.replace(/\s/g, '');
            }
            nst[name] = value;
            return nst;
        });
    };
    const handleKeyDown = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };
    const handleWorkflowType = (name) => {
        setWorkflowType((st) => {
            const nst = { ...st };
            Object.keys(nst).forEach((v) => {
                nst[v] = false;
            });
            nst[name] = true;
            return nst;
        });
        if (name == 'isNoOption' || name === 'isTextboxType') {
            setState((st) => ({ ...st, stepListId: null }));
        }
    };
    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{state.stepID ? <DialogTitles>Update Workflow Step</DialogTitles> : <DialogTitles>Add Workflow Step</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents style={{ overflow: 'auto', height: '80vh' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <FormLabel required component="legend" className="mandatoryfields">
                            Label
                        </FormLabel>
                        <TextBox autoFocus={true} required name="Label" onChange={fieldChange} value={state.Label} />
                        <span className="mandatoryfields">{state.errors['Label']}</span>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormLabel component="legend">Sequence</FormLabel>
                        <TextBox type="number" name="sequence" value={state.sequence} onChange={fieldChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={2} sm={2}>
                                <FormLabel component="legend" className="btn_add">
                                    Step Type
                                </FormLabel>
                            </Grid>
                            <Grid item xs={10} container spacing={1}>
                                <Grid item xs={12} sm={3}>
                                    <DefaultButton
                                        className={workflowType.isNoOption ? 'btnActive' : 'btninActive'}
                                        value={workflowType.isNoOption}
                                        name="isNoOption"
                                        onClick={() => handleWorkflowType('isNoOption')}
                                    >
                                        No Option
                                    </DefaultButton>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <DefaultButton
                                        className={workflowType.isListType ? 'btnActive' : 'btninActive'}
                                        value={workflowType.isListType}
                                        name="isListType"
                                        onClick={() => handleWorkflowType('isListType')}
                                    >
                                        List
                                    </DefaultButton>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <DefaultButton
                                        className={workflowType.isTextboxType ? 'btnActive' : 'btninActive'}
                                        value={workflowType.isTextboxType}
                                        name="isTextboxType"
                                        onClick={() => handleWorkflowType('isTextboxType')}
                                    >
                                        Text Box
                                    </DefaultButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Step List</FormLabel>
                        <SelectBox
                            style={{ marginTop: '8px' }}
                            onChange={fieldChange}
                            value={state.stepListId}
                            name="stepListId"
                            List={state.SteplList || []}
                            InputProps={{
                                readOnly: !workflowType.isListType
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Web Form Name</FormLabel>
                        <SelectBox
                            style={{ marginTop: '8px' }}
                            onChange={fieldChange}
                            value={state.webFormId}
                            name="webFormId"
                            List={state.WebFormList || []}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Dynamic Form Name</FormLabel>
                        <SelectBox
                            style={{ marginTop: '8px' }}
                            onChange={fieldChange}
                            value={state.dynamicFormID}
                            name="dynamicFormID"
                            List={state.dynamicFormList || []}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormLabel component="legend">Document Name</FormLabel>
                        <SelectBox
                            style={{ marginTop: '8px' }}
                            onChange={fieldChange}
                            value={state.documentName}
                            name="documentName"
                            List={state.OcrDocumnetList || []}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormLabel required component="legend" className="mandatoryfields">
                            JSON Property
                        </FormLabel>
                        <InputLabel shrink>&nbsp;(enter a unique name for the JSON property)</InputLabel>
                        <TextBox required name="jsonProperty" onChange={validateSpace} onKeyDown={handleKeyDown} value={state.jsonProperty} />
                        <span className="mandatoryfields">{state.errors['jsonProperty']}</span>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={2} sm={2}>
                                <FormLabel component="legend" className="btn_add">
                                    Is Editable
                                </FormLabel>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={state.IsEditable ? 'btnActive' : 'btninActive'}
                                    value={true}
                                    name="IsEditable"
                                    onClick={() => handleClick('IsEditable', true)}
                                >
                                    Yes
                                </DefaultButton>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={state.IsEditable ? 'btninActive' : 'btnActiveNo'}
                                    value={false}
                                    name="IsEditable"
                                    onClick={() => handleClick('IsEditable', false)}
                                >
                                    No
                                </DefaultButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={2} sm={2}>
                                <FormLabel component="legend" className="btn_add">
                                    Is Required
                                </FormLabel>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={state.IsRequired ? 'btnActive' : 'btninActive'}
                                    value={true}
                                    name="IsRequired"
                                    onClick={() => handleClick('IsRequired', true)}
                                >
                                    Yes
                                </DefaultButton>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={state.IsRequired ? 'btninActive' : 'btnActiveNo'}
                                    value={false}
                                    name="IsRequired"
                                    onClick={() => handleClick('IsRequired', false)}
                                >
                                    No
                                </DefaultButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={2} sm={2}>
                                <FormLabel component="legend" className="btn_add">
                                    Visible To Cutomer
                                </FormLabel>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={state.IsVisibleCutomer ? 'btnActive' : 'btninActive'}
                                    value={true}
                                    name="IsVisibleCutomer"
                                    onClick={() => handleClick('IsVisibleCutomer', true)}
                                >
                                    Yes
                                </DefaultButton>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={state.IsVisibleCutomer ? 'btninActive' : 'btnActiveNo'}
                                    value={false}
                                    name="IsVisibleCutomer"
                                    onClick={() => handleClick('IsVisibleCutomer', false)}
                                >
                                    No
                                </DefaultButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={2} sm={2}>
                                <FormLabel component="legend" className="btn_add">
                                    Visible To Auditor
                                </FormLabel>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={state.IsVisibleAuditor ? 'btnActive' : 'btninActive'}
                                    value={true}
                                    name="IsVisibleAuditor"
                                    onClick={() => handleClick('IsVisibleAuditor', true)}
                                >
                                    Yes
                                </DefaultButton>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={state.IsVisibleAuditor ? 'btninActive' : 'btnActiveNo'}
                                    value={false}
                                    name="IsVisibleAuditor"
                                    onClick={() => handleClick('IsVisibleAuditor', false)}
                                >
                                    No
                                </DefaultButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={2} sm={2}>
                                <FormLabel component="legend" className="btn_add">
                                    Is Active?
                                </FormLabel>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={state.IsActive ? 'btnActive' : 'btninActive'}
                                    value={true}
                                    name="IsActive"
                                    onClick={() => handleClick('IsActive', true)}
                                >
                                    Yes
                                </DefaultButton>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <DefaultButton
                                    className={state.IsActive ? 'btninActive' : 'btnActiveNo'}
                                    value={false}
                                    name="IsActive"
                                    onClick={() => handleClick('IsActive', false)}
                                >
                                    No
                                </DefaultButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddWorkflowStep;
