import { FormLabel, Grid, MenuItem, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import AppContext from '../../../../App/AppContext';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import { SquareButton } from '../../../../Core/FormInput/AppButton';
import ReportGijgoGrid from '../../ReportsScreens/GijgoForReportsScreen';
import { getSpecialEquipment, addUpdateSpecialEquipmentUsed, getSpecialEquipmentAssignlist } from '../../../../Core/Service/SpecialEquipmentService';
import formatters from '../../../../Core/Grid/inputFormatter';
import { NotInterested } from '@material-ui/icons';
import { FormControlLabel } from '@material-ui/core';
import { PrimaryCheckbox } from '../../../../Core/FormInput';
import { SingelSelect } from '../../../../Core/FormInput';
import { InputLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const SpecialEquipmentWebForm = (props) => {
    const { hideModal, hideTopNavAndMenu, showToast, portalSettings } = useContext(AppContext);

    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const headerId = props?.match?.params?.jobId;

    const [state, setState] = useState({
        specialEquipmentSelected: null,
        specialEquipment: [],

        ShowDeleteModal: false,
        isReload: false,
        errors: {}
    });

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const pullSpecialEquipmentDropdown = async () => {
        const res = await getSpecialEquipmentAssignlist(headerId);
        if (res.success) {
            setState((st) => ({
                ...st,
                specialEquipment: res.data
            }));
        }
    };

    useEffect(async () => {
        pullSpecialEquipmentDropdown();
    }, [headerId]);

    const handleValidation = () => {
        let formIsValid = true;
        let errors = {};

        const { specialEquipmentSelected } = state;

        if (!specialEquipmentSelected) {
            errors['specialEquipmentSelected'] = 'Select Equipment';
            formIsValid = false;
        }

        setState((st) => ({
            ...st,
            errors: errors
        }));

        return formIsValid;
    };

    const handleAdd = async () => {
        if (handleValidation()) {
            const data = {
                SpecialEquipmentUsedID: null,
                SpecialEquipmentID: state.specialEquipmentSelected.specialEquipmentID,
                SpecialEquipmentHeaderRecordId: headerId,
                SpecialEquipmentInUse: true
            };

            const res = await addUpdateSpecialEquipmentUsed(data);
            if (res.success) {
                showToast(res.data.message);
            } else {
                showToast(res.message);
            }

            setState((st) => ({
                ...st,
                isReload: !state.isReload
            }));
        }
    };

    const handleShowDelete = (val) => {
        setState((st) => ({
            ...st,
            ShowDeleteModal: val
        }));
    };

    const handleDelete = async (row) => {
        const data = {
            SpecialEquipmentUsedID: row.specialEquipmentUsedID,
            SpecialEquipmentID: row.specialEquipmentID,
            SpecialEquipmentHeaderRecordId: headerId,
            SpecialEquipmentInUse: false
        };

        const res = await addUpdateSpecialEquipmentUsed(data);
        if (res.success) {
            showToast(res.data.message);
            handleShowDelete(false);
        } else {
            showToast(res.message);
        }

        setState((st) => ({
            ...st,
            isReload: !state.isReload
        }));
    };

    const baseColumns = [
        {
            field: 'action',
            sortable: false,
            title: `Action`,
            width: '5%',
            filterable: false
        },
        {
            field: 'specialEquipmentUsedID',
            title: 'UID',
            flex: 1,
            hidden: true,
            sortable: false,
            filterable: false
        },
        {
            field: 'specialEquipmentID',
            title: 'ID',
            flex: 1,
            hidden: true,
            sortable: false,
            filterable: false
        },
        {
            field: 'specialEquipmentCode',
            title: 'Code',
            width: '5%',
            sortable: false,
            filterable: false
        },
        {
            field: 'specialEquipmentDescription',
            title: 'Description',
            flex: 1,
            sortable: false,
            filterable: false
        },
        {
            field: 'specialEquipmentToolLocation',
            title: 'Tool Location',
            width: '15%',
            sortable: false,
            filterable: false
        },
        {
            field: 'specialEquipmentDateAllocated',
            title: 'Allocated By',
            width: '20%',
            sortable: false,
            filterable: false
        },
        {
            field: 'specialEquipmentDateUnallocated',
            title: 'De-allocated By',
            width: '20%',
            sortable: false,
            filterable: false
        }
        // {
        //     field: 'specialEquipmentAllocatedBy',
        //     title: 'Allocated By',
        //     width: '15%'
        // }
    ];

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="space-evenly">
                <SquareButton
                    disabled={!record.specialEquipmentInUse}
                    style={{
                        backgroundColor: '#183B68',
                        opacity: record.specialEquipmentInUse ? '1' : '0.4',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => {
                        handleShowDelete(true);
                        setState((st) => ({
                            ...st,
                            focusedRow: record
                        }));
                    }}
                >
                    <NotInterested fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const deallocatedRenderer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <div>
                {record.specialEquipmentInUse ? (
                    <>In Use</>
                ) : (
                    <>
                        {record.specialEquipmentDeAllocatedBy}
                        <span style={{ fontStyle: 'italic' }}> on {formatters.DateTimeFormatter(record.specialEquipmentDateUnallocated)}</span>
                    </>
                )}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const allocatedRenderer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <div>
                {record.specialEquipmentAllocatedBy}{' '}
                <span style={{ fontStyle: 'italic' }}>on {formatters.DateTimeFormatter(record.specialEquipmentDateAllocated)}</span>
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');
        let inUseCol = tempCols.find((element) => element.field === 'specialEquipmentInUse');
        let dateCol = tempCols.find((element) => element.field === 'specialEquipmentDateAllocated');
        let dateCol2 = tempCols.find((element) => element.field === 'specialEquipmentDateUnallocated');

        if (actionCol) {
            actionCol.renderer = editButton({});
        }

        if (inUseCol) {
            inUseCol.renderer = (val) => (val ? 'Yes' : 'No');
        }

        if (dateCol) {
            dateCol.renderer = allocatedRenderer({});
        }

        if (dateCol2) {
            dateCol2.renderer = deallocatedRenderer({});
        }

        return tempCols;
    }, []);

    const baseUrl = `specialequipment/SpecialEquipmentUsed/${headerId}`;

    const handleAutoChange = (e, value) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.specialEquipmentSelected = value;
            return newSt;
        });
    };

    return (
        <div style={{ paddingLeft: '25px', paddingRight: '25px', paddingTop: '30px', paddingBottom: '30px', textAlign: 'left' }}>
            <Grid container spacing={1} style={{}}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormLabel>Equipment</FormLabel>
                    <Autocomplete
                        autoSelect
                        options={state.specialEquipment}
                        name="specialEquipmentSelected"
                        value={state.specialEquipmentSelected}
                        onChange={handleAutoChange}
                        getOptionLabel={(option) =>
                            typeof option === 'string'
                                ? option
                                : `${option.specialEquipmentCode} - ${option.specialEquipmentDescription} - ${option.specialEquipmentToolLocation}`
                        }
                        renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                    />
                    <span className="mandatoryfields">{state.errors['specialEquipmentSelected']}</span>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} style={{ marginTop: '16px' }}>
                    <SecondaryButton className="Submitbtn" onClick={handleAdd}>
                        Allocate Equipment
                    </SecondaryButton>
                </Grid>

                <Grid item container spacing={1} xs={12} style={{}}>
                    <Grid item xs={12}>
                        <ReportGijgoGrid
                            dataConverter={(res) => ({
                                records: res?.data.sort((a, b) => {
                                    return b.specialEquipmentInUse - a.specialEquipmentInUse;
                                }),
                                total: res?.data?.total
                            })}
                            columns={columns}
                            getUrl={baseUrl}
                            isReload={state.isReload}
                            hideHeaderFilter={true}
                            hidePagination={true}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {state.ShowDeleteModal && (
                <DialogComp title={'Deallocate Equipment'} onClose={() => handleShowDelete(false)} fullWidth maxWidth="md">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Are you sure you want to deallocate this equipment?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{ fontWeight: 'bold' }}>Equipment: </span>
                            {state.focusedRow.specialEquipmentCode} - {state.focusedRow.specialEquipmentDescription}
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{ fontWeight: 'bold' }}>Date Allocated: </span>
                            {formatters.DateFormatter(state.focusedRow.specialEquipmentDateAllocated)}
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{ fontWeight: 'bold' }}>Allocated By: </span>
                            {state.focusedRow.specialEquipmentAllocatedBy}
                        </Grid>
                        <Grid item xs={12}>
                            <SecondaryButton fullWidth onClick={() => handleDelete(state.focusedRow)} style={{ marginTop: '15px' }}>
                                Deallocate
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </DialogComp>
            )}
        </div>
    );
};

export default SpecialEquipmentWebForm;
