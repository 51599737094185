import { CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import formatters from '../../../../Core/Grid/inputFormatter';
import { getReportingInternalHighLevelDashboard, ReportingInternalHighLevelDashboardBellTruck } from '../../../../Core/Service/ReportScreens-service';
import './miniStyle.scss';
import { useHistory } from 'react-router-dom';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { VehicleCard, VehicleCardNew } from './ministyleComp';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';
import InfoIcon from '@material-ui/icons/Info';
import { MiniViewGraphChart } from './miniViewGraph';

const StyledTableCell = withStyles((theme) => ({
    head: {
        padding: '0px 10px 0px 0px',
        border: 'none',
        textAlign: 'center'
    },
    body: {
        border: 'none',
        padding: '10px 0px',
        textAlign: 'center'
    }
}))(TableCell);

const data = [
    {
        name: 'Page A',
        uv: 40,
        pv: 2400,
        amt: 2400,
        amt2: 24
    },
    {
        name: 'Page B',
        uv: 30,
        pv: 1398,
        amt: 2210,
        amt2: 22
    },
    {
        name: 'Page C',
        uv: 20,
        pv: 9800,
        amt: 2290,
        amt2: 22
    },
    {
        name: 'Page D',
        uv: 27,
        pv: 3908,
        amt: 2000,
        amt2: 20
    },
    {
        name: 'Page E',
        uv: 18,
        pv: 4800,
        amt: 2181,
        amt2: 21
    },
    {
        name: 'Page F',
        uv: 23,
        pv: 3800,
        amt: 2500,
        amt2: 25
    },
    {
        name: 'Page G',
        uv: 34,
        pv: 4300,
        amt: 2100,
        amt2: 21
    }
];

const data2 = [
    {
        name: 'Page A',
        pv: 40,
        amt: 24
    },
    {
        name: 'Page B',
        pv: 30,
        amt: 22
    },
    {
        name: 'Page C',
        pv: 20,
        amt: 22
    },
    {
        name: 'Page D',
        pv: 27,
        amt: 20
    },
    {
        name: 'Page E',
        pv: 18,
        amt: 21
    },
    {
        name: 'Page F',
        pv: 23,
        amt: 25
    },
    {
        name: 'Page G',
        pv: 34,
        amt: 21
    }
];

let mtd = [
    {
        branchID: 16,
        branch: 'Derby',
        soldDate: '2024-10-24T00:00:00',
        soldHours: 3.4,
        soldValue: 191.24,
        dailyHoursBudget: 9.0,
        dailyBudget: 652.0
    },
    {
        branchID: 18,
        branch: 'London',
        soldDate: '2024-10-19T00:00:00',
        soldHours: 8.0,
        soldValue: 565.42,
        dailyHoursBudget: 22.0,
        dailyBudget: 1087.0
    },
    {
        branchID: 16,
        branch: 'Derby',
        soldDate: '2024-10-26T00:00:00',
        soldHours: 1.2,
        soldValue: 45.6,
        dailyHoursBudget: 9.0,
        dailyBudget: 652.0
    },
    {
        branchID: 20,
        branch: 'Birmingham',
        soldDate: '2024-10-12T00:00:00',
        soldHours: 6.2,
        soldValue: 397.07,
        dailyHoursBudget: 9.0,
        dailyBudget: 261.0
    },
    {
        branchID: 20,
        branch: 'Birmingham',
        soldDate: '2024-10-28T00:00:00',
        soldHours: 4.2,
        soldValue: 267.4,
        dailyHoursBudget: 9.0,
        dailyBudget: 261.0
    },
    {
        branchID: 20,
        branch: 'Birmingham',
        soldDate: '2024-10-21T00:00:00',
        soldHours: 3.9,
        soldValue: 343.65,
        dailyHoursBudget: 9.0,
        dailyBudget: 261.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-15T00:00:00',
        soldHours: 26.4,
        soldValue: 1714.68,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 17,
        branch: 'Cardiff',
        soldDate: '2024-10-27T00:00:00',
        soldHours: 24.2,
        soldValue: 1671.92,
        dailyHoursBudget: 20.0,
        dailyBudget: 522.0
    },
    {
        branchID: 19,
        branch: 'Hull',
        soldDate: '2024-10-19T00:00:00',
        soldHours: 29.5,
        soldValue: 1848.05,
        dailyHoursBudget: 21.0,
        dailyBudget: 522.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-22T00:00:00',
        soldHours: 8.7,
        soldValue: 526.27,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 18,
        branch: 'London',
        soldDate: '2024-10-26T00:00:00',
        soldHours: 7.7,
        soldValue: 500.11,
        dailyHoursBudget: 22.0,
        dailyBudget: 1087.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-20T00:00:00',
        soldHours: 26.1,
        soldValue: 1733.82,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 17,
        branch: 'Cardiff',
        soldDate: '2024-10-11T00:00:00',
        soldHours: 1.8,
        soldValue: 129.9,
        dailyHoursBudget: 20.0,
        dailyBudget: 522.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-13T00:00:00',
        soldHours: 7.9,
        soldValue: 524.79,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 17,
        branch: 'Cardiff',
        soldDate: '2024-10-21T00:00:00',
        soldHours: 24.4,
        soldValue: 1636.87,
        dailyHoursBudget: 20.0,
        dailyBudget: 522.0
    },
    {
        branchID: 20,
        branch: 'Birmingham',
        soldDate: '2024-10-19T00:00:00',
        soldHours: 17.4,
        soldValue: 1130.13,
        dailyHoursBudget: 9.0,
        dailyBudget: 261.0
    },
    {
        branchID: 18,
        branch: 'London',
        soldDate: '2024-10-12T00:00:00',
        soldHours: 3.0,
        soldValue: 151.08,
        dailyHoursBudget: 22.0,
        dailyBudget: 1087.0
    },
    {
        branchID: 15,
        branch: 'Preston',
        soldDate: '2024-10-27T00:00:00',
        soldHours: 7.2,
        soldValue: 469.12,
        dailyHoursBudget: 15.0,
        dailyBudget: 304.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-28T00:00:00',
        soldHours: 16.5,
        soldValue: 1194.15,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-26T00:00:00',
        soldHours: 20.8,
        soldValue: 1883.95,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 20,
        branch: 'Birmingham',
        soldDate: '2024-10-16T00:00:00',
        soldHours: 14.2,
        soldValue: 1082.83,
        dailyHoursBudget: 9.0,
        dailyBudget: 261.0
    },
    {
        branchID: 17,
        branch: 'Cardiff',
        soldDate: '2024-10-05T00:00:00',
        soldHours: 11.9,
        soldValue: 810.78,
        dailyHoursBudget: 20.0,
        dailyBudget: 522.0
    },
    {
        branchID: 17,
        branch: 'Cardiff',
        soldDate: '2024-10-22T00:00:00',
        soldHours: 11.3,
        soldValue: 732.19,
        dailyHoursBudget: 20.0,
        dailyBudget: 522.0
    },
    {
        branchID: 18,
        branch: 'London',
        soldDate: '2024-10-13T00:00:00',
        soldHours: 14.5,
        soldValue: 1292.96,
        dailyHoursBudget: 22.0,
        dailyBudget: 1087.0
    },
    {
        branchID: 16,
        branch: 'Derby',
        soldDate: '2024-10-13T00:00:00',
        soldHours: 3.4,
        soldValue: 319.22,
        dailyHoursBudget: 9.0,
        dailyBudget: 652.0
    },
    {
        branchID: 15,
        branch: 'Preston',
        soldDate: '2024-10-09T00:00:00',
        soldHours: 1.8,
        soldValue: 116.91,
        dailyHoursBudget: 15.0,
        dailyBudget: 304.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-27T00:00:00',
        soldHours: 7.1,
        soldValue: 461.14,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 17,
        branch: 'Cardiff',
        soldDate: '2024-10-23T00:00:00',
        soldHours: 4.7,
        soldValue: 335.58,
        dailyHoursBudget: 20.0,
        dailyBudget: 522.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-08T00:00:00',
        soldHours: 3.1,
        soldValue: 204.3,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-25T00:00:00',
        soldHours: 12.7,
        soldValue: 758.1,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-23T00:00:00',
        soldHours: 4.0,
        soldValue: 249.76,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 16,
        branch: 'Derby',
        soldDate: '2024-10-07T00:00:00',
        soldHours: 6.3,
        soldValue: 591.5,
        dailyHoursBudget: 9.0,
        dailyBudget: 652.0
    },
    {
        branchID: 17,
        branch: 'Cardiff',
        soldDate: '2024-10-19T00:00:00',
        soldHours: 2.5,
        soldValue: 178.5,
        dailyHoursBudget: 20.0,
        dailyBudget: 522.0
    },
    {
        branchID: 15,
        branch: 'Preston',
        soldDate: '2024-10-12T00:00:00',
        soldHours: 5.5,
        soldValue: 363.82,
        dailyHoursBudget: 15.0,
        dailyBudget: 304.0
    },
    {
        branchID: 16,
        branch: 'Derby',
        soldDate: '2024-10-18T00:00:00',
        soldHours: 13.2,
        soldValue: 704.58,
        dailyHoursBudget: 9.0,
        dailyBudget: 652.0
    },
    {
        branchID: 18,
        branch: 'London',
        soldDate: '2024-10-25T00:00:00',
        soldHours: 10.3,
        soldValue: 312.78,
        dailyHoursBudget: 22.0,
        dailyBudget: 1087.0
    },
    {
        branchID: 20,
        branch: 'Birmingham',
        soldDate: '2024-10-13T00:00:00',
        soldHours: 3.05,
        soldValue: 211.47,
        dailyHoursBudget: 9.0,
        dailyBudget: 261.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-05T00:00:00',
        soldHours: 0.0,
        soldValue: -152.52,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 17,
        branch: 'Cardiff',
        soldDate: '2024-10-08T00:00:00',
        soldHours: 3.0,
        soldValue: 211.71,
        dailyHoursBudget: 20.0,
        dailyBudget: 522.0
    },
    {
        branchID: 20,
        branch: 'Birmingham',
        soldDate: '2024-10-06T00:00:00',
        soldHours: 1.0,
        soldValue: 65.0,
        dailyHoursBudget: 9.0,
        dailyBudget: 261.0
    },
    {
        branchID: 16,
        branch: 'Derby',
        soldDate: '2024-10-25T00:00:00',
        soldHours: 0.1,
        soldValue: 10.31,
        dailyHoursBudget: 9.0,
        dailyBudget: 652.0
    },
    {
        branchID: 20,
        branch: 'Birmingham',
        soldDate: '2024-10-20T00:00:00',
        soldHours: 0.2,
        soldValue: -115.54,
        dailyHoursBudget: 9.0,
        dailyBudget: 261.0
    },
    {
        branchID: 16,
        branch: 'Derby',
        soldDate: '2024-10-16T00:00:00',
        soldHours: 4.4,
        soldValue: 267.17,
        dailyHoursBudget: 9.0,
        dailyBudget: 652.0
    },
    {
        branchID: 18,
        branch: 'London',
        soldDate: '2024-10-02T00:00:00',
        soldHours: 13.3,
        soldValue: 844.66,
        dailyHoursBudget: 22.0,
        dailyBudget: 1087.0
    },
    {
        branchID: 15,
        branch: 'Preston',
        soldDate: '2024-10-01T00:00:00',
        soldHours: 4.0,
        soldValue: 260.0,
        dailyHoursBudget: 15.0,
        dailyBudget: 304.0
    },
    {
        branchID: 16,
        branch: 'Derby',
        soldDate: '2024-10-05T00:00:00',
        soldHours: 1.0,
        soldValue: 125.0,
        dailyHoursBudget: 9.0,
        dailyBudget: 652.0
    },
    {
        branchID: 18,
        branch: 'London',
        soldDate: '2024-10-20T00:00:00',
        soldHours: 37.6,
        soldValue: 2256.0,
        dailyHoursBudget: 22.0,
        dailyBudget: 1087.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-19T00:00:00',
        soldHours: 6.4,
        soldValue: 418.24,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 20,
        branch: 'Birmingham',
        soldDate: '2024-10-09T00:00:00',
        soldHours: 8.5,
        soldValue: 552.07,
        dailyHoursBudget: 9.0,
        dailyBudget: 261.0
    },
    {
        branchID: 19,
        branch: 'Hull',
        soldDate: '2024-10-23T00:00:00',
        soldHours: 0.1,
        soldValue: 9.38,
        dailyHoursBudget: 21.0,
        dailyBudget: 522.0
    },
    {
        branchID: 16,
        branch: 'Derby',
        soldDate: '2024-10-22T00:00:00',
        soldHours: 38.8,
        soldValue: 2526.98,
        dailyHoursBudget: 9.0,
        dailyBudget: 652.0
    },
    {
        branchID: 19,
        branch: 'Hull',
        soldDate: '2024-10-07T00:00:00',
        soldHours: 9.7,
        soldValue: 692.58,
        dailyHoursBudget: 21.0,
        dailyBudget: 522.0
    },
    {
        branchID: 18,
        branch: 'London',
        soldDate: '2024-10-23T00:00:00',
        soldHours: 3.0,
        soldValue: 256.36,
        dailyHoursBudget: 22.0,
        dailyBudget: 1087.0
    },
    {
        branchID: 15,
        branch: 'Preston',
        soldDate: '2024-10-02T00:00:00',
        soldHours: 5.0,
        soldValue: 325.0,
        dailyHoursBudget: 15.0,
        dailyBudget: 304.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-17T00:00:00',
        soldHours: 4.0,
        soldValue: 233.84,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 19,
        branch: 'Hull',
        soldDate: '2024-10-21T00:00:00',
        soldHours: 3.2,
        soldValue: 212.57,
        dailyHoursBudget: 21.0,
        dailyBudget: 522.0
    },
    {
        branchID: 14,
        branch: 'BCB',
        soldDate: '2024-10-03T00:00:00',
        soldHours: 0.0,
        soldValue: 0.0,
        dailyHoursBudget: 20.0,
        dailyBudget: 435.0
    },
    {
        branchID: 20,
        branch: 'Birmingham',
        soldDate: '2024-10-08T00:00:00',
        soldHours: 0.8,
        soldValue: 53.14,
        dailyHoursBudget: 9.0,
        dailyBudget: 261.0
    },
    {
        branchID: 19,
        branch: 'Hull',
        soldDate: '2024-10-22T00:00:00',
        soldHours: 12.8,
        soldValue: 866.98,
        dailyHoursBudget: 21.0,
        dailyBudget: 522.0
    },
    {
        branchID: 20,
        branch: 'Birmingham',
        soldDate: '2024-10-27T00:00:00',
        soldHours: 18.2,
        soldValue: 1221.19,
        dailyHoursBudget: 9.0,
        dailyBudget: 261.0
    }
];

const BellTruckDashboard = (props) => {
    const [state, setState] = useState({
        data: {},
        parts: [],
        mtd: [],
        isLoader: true,
        sales: {}
    });
    const [grapData, setGrapgData] = useState({
        branchList: [],
        hours: [],
        amount: [],
        hoursBranchID: null,
        amountBranchID: null
    });
    const [WindowWidths, WindowHeights] = useWindowSize();
    const history = useHistory();
    const handleRedirect = () => {
        let link = '/RepotingTab';
        history.push({
            pathname: link
        });
    };
    const handleRedirectCal = (search) => {
        let link = '/Services/InvoicedhoursCalender';
        history.push({
            pathname: link,
            search: search
        });
    };
    const handleRedirectPart = () => {
        let link = '/Services/Parts_Calender';
        history.push({
            pathname: link
        });
    };

    useEffect(async () => {
        let res = await ReportingInternalHighLevelDashboardBellTruck();
        setState((st) => ({
            ...st,
            data: res.data.data[0],
            sales: res.data.sales[0],
            parts: res.data.parts || [],
            mtd: res.data.mtd || [],
            isLoader: false
        }));
    }, []);

    useEffect(() => {
        if (state.mtd.length > 0) {
            const branchList = [...new Map(state.mtd.map((k) => [k.branchID, { name: k.branch, id: k.branchID }])).values()];
            const hoursListMTD = state.mtd.map((k) => ({
                name: k.branch,
                id: k.branchID,
                value: k.soldHours,
                budget: k.dailyHoursBudget,
                date: formatters.MonthShortFormatter(k.soldDate)
            }));
            const amountListMTD = state.mtd.map((k) => ({
                name: k.branch,
                id: k.branchID,
                value: k.soldValue,
                budget: k.dailyBudget,
                date: formatters.MonthShortFormatter(k.soldDate)
            }));

            let hoursBranchID = branchList[0]?.id;
            let amountBranchID = branchList[0]?.id;

            setGrapgData((st) => ({ ...st, branchList, hoursListMTD, amountListMTD, hoursBranchID, amountBranchID }));
        }
    }, [state.mtd]);

    const getSelectedBranch = (id, val) => {
        if (id == 'H') {
            setGrapgData((st) => ({ ...st, hoursBranchID: val }));
        } else {
            setGrapgData((st) => ({ ...st, amountBranchID: val }));
        }
    };

    const redirectTOVehicleDetails = (label, type) => {
        let searchQuery = `?&label=${label}&type=${type}`;
        let link = '/sales_newRegistration';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };
    const redirectTOVehicleOrderIntek = (label, type) => {
        let searchQuery = `?&label=${label}&type=${type}`;
        let link = '/sales_orderIntek';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };
    const redirectTOVehicleUsedInvoice = (label, type) => {
        let searchQuery = `?&label=${label}&type=${type}`;
        let link = '/sales_usedInvoice';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };
    const redirectTOVehiclEVTarget = (label, type) => {
        let searchQuery = `?&label=${label}`;
        let link = '/sales_EvTarget';
        history.push({
            pathname: link,
            search: searchQuery
        });
    };

    return (
        <div style={{ padding: 10 }}>
            <Grid
                container
                // style={{
                //     height: 'calc(100vh - 105px)',
                //     overflow: 'auto'
                // }}
                justifyContent="center"
                className="salesBudgetScreen"
                alignItems="center"
            >
                {state.isLoader ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <Grid
                        item
                        xs={11}
                        sm={11}
                        md={11}
                        lg={WindowWidths < 1660 ? 11 : 'auto'}
                        xl={11}
                        // style={{ padding: WindowWidths > 1910 ? 0 : WindowWidths < 1280 ? 0 : 10 }}
                    >
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={12}>
                                <Typography className="MainHeading">After Sales (MTD)</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={0}
                                    justifyContent={WindowWidths >= 1280 ? 'space-between' : 'flext-start'}
                                    className={WindowWidths > 1280 ? 'detailDashmainCard1' : ''}
                                >
                                    <Grid item xs={12} className="detailDashmainCardGraphsubMenu">
                                        <MiniViewGraphChart
                                            list={grapData.hoursListMTD || []}
                                            label={'SOLD HOURS - MTD'}
                                            branchList={grapData.branchList}
                                            getSelectedBranch={getSelectedBranch}
                                            branchID={grapData.hoursBranchID}
                                            id="H"
                                            handleRedirectCal={handleRedirectCal}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="detailDashmainCardGraphsubMenu">
                                        <MiniViewGraphChart
                                            list={grapData.amountListMTD || []}
                                            label={'SOLD £ - MTD'}
                                            branchList={grapData.branchList}
                                            getSelectedBranch={getSelectedBranch}
                                            branchID={grapData.amountBranchID}
                                            id="A"
                                            handleRedirectCal={handleRedirectCal}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2} xl={2} className="detailDashmainCardsubMenu" onClick={() => handleRedirect()}>
                                        <Grid container justifyContent="space-around" alignItems="center" spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography className="mainCardTextPrimary">SOLD (TODAY)</Typography>
                                            </Grid>
                                            <Grid item>
                                                <div className="vehicleRing" style={{ textAlign: 'center', marginBottom: 10 }}>
                                                    <CircularProgressbar
                                                        value={state.data.todaySoldHoursProgress}
                                                        text={`${state.data.todaySoldHoursProgress || 0}%`}
                                                        styles={buildStyles({
                                                            textColor:
                                                                state.data.todaySoldHoursProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.todaySoldHoursProgress >= 50 &&
                                                                      state.data.todaySoldHoursProgress < state.data.todaySoldHoursProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.todaySoldHoursProgress >= state.data.todaySoldHoursProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            pathColor:
                                                                state.data.todaySoldHoursProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.todaySoldHoursProgress >= 50 &&
                                                                      state.data.todaySoldHoursProgress < state.data.todaySoldHoursProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.todaySoldHoursProgress >= state.data.todaySoldHoursProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            trailColor: '#d6d6d6',
                                                            textSize: '27px'
                                                        })}
                                                    />
                                                </div>
                                                <div>
                                                    <Typography className="salesCurrencyFont">
                                                        <span style={{ color: state.data?.todaySoldHoursColour ? state.data?.todaySoldHoursColour : 'black' }}>
                                                            {state.data.todaySoldHours
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.todaySoldHours)
                                                                : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                    <span className="salesCardTextPrimary">Hours</span>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className="vehicleRing" style={{ textAlign: 'justify', marginBottom: 10 }}>
                                                    <CircularProgressbar
                                                        value={state.data.todaySoldAmountProgress}
                                                        text={`${state.data.todaySoldAmountProgress || 0}%`}
                                                        styles={buildStyles({
                                                            textColor:
                                                                state.data.todaySoldAmountProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.todaySoldAmountProgress >= 50 &&
                                                                      state.data.todaySoldAmountProgress < state.data.todaySoldAmountProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.todaySoldAmountProgress >= state.data.todaySoldAmountProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            pathColor:
                                                                state.data.todaySoldAmountProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.todaySoldAmountProgress >= 50 &&
                                                                      state.data.todaySoldAmountProgress < state.data.todaySoldAmountProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.todaySoldAmountProgress >= state.data.todaySoldAmountProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            trailColor: '#d6d6d6',
                                                            textSize: '27px'
                                                        })}
                                                    />
                                                </div>
                                                <div>
                                                    <Typography className="salesCurrencyFont">
                                                        <span
                                                            style={{ color: state.data?.todaySoldAmountColour ? state.data?.todaySoldAmountColour : 'black' }}
                                                        >
                                                            {state.data.todaySoldAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.todaySoldAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                    <span className="salesCardTextPrimary">Value</span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={2}
                                        xl={2}
                                        className="detailDashmainCardsubMenu"
                                        // onClick={() => handleRedirect()}
                                    >
                                        <Grid container justifyContent="space-around" alignItems="center" spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    className="mainCardTextPrimary"
                                                    style={{ display: 'block', alignContent: 'center', justifyContent: 'center' }}
                                                >
                                                    <span> SOLD (MTD)</span>
                                                    <InfoIcon
                                                        color="secondary"
                                                        style={{ cursor: 'pointer', float: 'right' }}
                                                        onClick={() => handleRedirectCal()}
                                                    />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <div className="vehicleRing" style={{ textAlign: 'center', marginBottom: 10 }}>
                                                    <CircularProgressbar
                                                        value={state.data.soldHoursProgress}
                                                        text={`${state.data.soldHoursProgress || 0}%`}
                                                        styles={buildStyles({
                                                            textColor:
                                                                state.data.soldHoursProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.soldHoursProgress >= 50 &&
                                                                      state.data.soldHoursProgress < state.data.soldHoursProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.soldHoursProgress >= state.data.soldHoursProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            pathColor:
                                                                state.data.soldHoursProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.soldHoursProgress >= 50 &&
                                                                      state.data.soldHoursProgress < state.data.soldHoursProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.soldHoursProgress >= state.data.soldHoursProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            trailColor: '#d6d6d6',
                                                            textSize: '27px'
                                                        })}
                                                    />
                                                </div>
                                                <div>
                                                    <Typography className="salesCurrencyFont">
                                                        <span style={{ color: state.data?.soldHoursColour ? state.data?.soldHoursColour : 'black' }}>
                                                            {state.data.soldHours ? formatters.ThousandSeparatorWithoutZero(state.data?.soldHours) : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                    <span className="salesCardTextPrimary">Hours</span>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className="vehicleRing" style={{ textAlign: 'justify', marginBottom: 10 }}>
                                                    <CircularProgressbar
                                                        value={state.data.soldAmountProgress}
                                                        text={`${state.data.soldAmountProgress || 0}%`}
                                                        styles={buildStyles({
                                                            textColor:
                                                                state.data.soldAmountProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.soldAmountProgress >= 50 &&
                                                                      state.data.soldAmountProgress < state.data.soldAmountProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.soldAmountProgress >= state.data.soldAmountProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            pathColor:
                                                                state.data.soldAmountProgress < 50
                                                                    ? '#D92641'
                                                                    : state.data.soldAmountProgress >= 50 &&
                                                                      state.data.soldAmountProgress < state.data.soldAmountProgress
                                                                    ? '#ff9f00'
                                                                    : state.data.soldAmountProgress >= state.data.soldAmountProgress
                                                                    ? '#569101'
                                                                    : '#D92641',
                                                            trailColor: '#d6d6d6',
                                                            textSize: '27px'
                                                        })}
                                                    />
                                                </div>
                                                <div>
                                                    <Typography className="salesCurrencyFont">
                                                        <span style={{ color: state.data?.soldAmountColour ? state.data?.soldAmountColour : 'black' }}>
                                                            {state.data.soldAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.soldAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                    <span className="salesCardTextPrimary">Value</span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2} xl={2} className="detailDashmainCardsubMenu" onClick={() => handleRedirect()}>
                                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography className="mainCardTextPrimary">LABOUR</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{ marginTop: 10 }}>
                                                    <div className="salesCurrencyFont ">
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.warrantyHours
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.warrantyHours)
                                                                : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.warrantyAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.warrantyAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>

                                                    <span className="salesCardTextPrimary primaryColor">
                                                        <b> Warranty</b>
                                                        <span style={{ marginLeft: '5px' }}>(Hours/Value)</span>
                                                    </span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{ marginTop: 5 }}>
                                                    <div className="salesCurrencyFont">
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.rmHours ? formatters.ThousandSeparatorWithoutZero(state.data?.rmHours) : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.rmAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.rmAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>
                                                    <span className="salesCardTextPrimary primaryColor">
                                                        {' '}
                                                        <b>R&M</b>
                                                        <span style={{ marginLeft: '5px' }}>(Hours/Value)</span>
                                                    </span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2} xl={2} className="detailDashmainCardsubMenu">
                                        <Grid container justifyContent="space-around" alignItems="center" spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography className="mainCardTextPrimary" style={{ display: 'block' }}>
                                                    <span> PARTS </span>
                                                    {/* <InfoIcon
                                                    color="secondary"
                                                    style={{ cursor: 'pointer', float: 'right' }}
                                                    // onClick={() => handleRedirectPart()}
                                                /> */}
                                                </Typography>
                                            </Grid>
                                            {state.parts == null || state.parts.length <= 0 ? (
                                                <Grid container justifyContent="space-around" alignItems="center">
                                                    No Records Found
                                                </Grid>
                                            ) : (
                                                state.parts.map((item) => {
                                                    return (
                                                        <Grid item lg={2} xl={12}>
                                                            <div>
                                                                <div className="mainText">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(item.partsAmount)}
                                                                </div>
                                                                <span className="salesCardTextPrimary">{item.partsDepartment}</span>
                                                            </div>
                                                        </Grid>
                                                    );
                                                })
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2} xl={2} className="detailDashmainCardsubMenu" onClick={() => handleRedirect()}>
                                        <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography className="mainCardTextPrimary">WIP</Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div>
                                                    <div className="salesCurrencyFont">
                                                        <span style={{ color: state.data?.wipHoursColour ? state.data?.wipHoursColour : 'black' }}>
                                                            {state.data.wipHours ? formatters.ThousandSeparatorWithoutZero(state.data?.wipHours) : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: state.data?.wipAmountColour ? state.data?.wipAmountColour : 'black' }}>
                                                            {state.data.wipAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.wipAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography className="salesCardTextPrimary primaryColor">
                                                    Surcharges<span style={{ marginLeft: '5px' }}>(Quantity/Value)</span>
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div>
                                                    <div className="salesCurrencyFont">
                                                        {' '}
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.surchargeQuantity
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.surchargeQuantity)
                                                                : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.surchargeAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.surchargeAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2} xl={2} className="detailDashmainCardsubMenu" onClick={() => handleRedirect()}>
                                        <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={2}>
                                            <Grid item xs={12}>
                                                <div className="mainCardTextPrimary">CUSTOMER THROUGHPUT</div>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div>
                                                    <div className="salesCurrencyFont">
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.uniqueCustomers
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.uniqueCustomers)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className="mainCardTextPrimary">NO. OF TECHNICIANS</Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div>
                                                    <div className="salesCurrencyFont">
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.technicianBudget
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.technicianBudget)
                                                                : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: state.data?.technicianBudget > state.data?.technicianWorking ? 'red' : 'green' }}>
                                                            {state.data.technicianWorking
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.technicianWorking)
                                                                : 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={12}>
                                <Typography className="MainHeading">Vehicle Sales</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container spacing={0} justifyContent="center">
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={4}
                                                lg={WindowWidths > 1660 ? 2 : 4}
                                                xl={2}
                                                onClick={() => redirectTOVehicleDetails('VAN REGISTRATIONS', 1)}
                                                // onClick={() => redirectTOVehicleDetails('VAN REGISTRATIONS / PROFIT', 1)}
                                            >
                                                <VehicleCardNew
                                                    // label={'VAN REGISTRATIONS / PROFIT'}
                                                    label={'VAN REGISTRATIONS '}
                                                    // hideProfit={true}
                                                    // mtdProfit={state.sales.vanRegistrationsUnits}
                                                    // mtdBudget={state.sales.vanRegistrationsBudgetUnits}
                                                    // ytdProfit={state.sales.ytdVanRegistrationsUnits}
                                                    // ytdBudget={state.sales.ytdVanRegistrationsBudgetUnits}
                                                    //With Profit
                                                    mtdProfit={state.sales.vanRegistrationsProfit}
                                                    mtdBudget={state.sales.vanRegistrationsBudgetProfit}
                                                    mtdUnit={state.sales.vanRegistrationsUnits}
                                                    mtdBudgetunit={state.sales.vanRegistrationsBudgetUnits}
                                                    ytdProfit={state.sales.ytdVanRegistrationsProfit}
                                                    ytdBudget={state.sales.ytdVanRegistrationsBudgetProfit}
                                                    ytdUnit={state.sales.ytdVanRegistrationsUnits}
                                                    ytdBudgetunit={state.sales.ytdVanRegistrationsBudgetUnits}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={4}
                                                lg={WindowWidths > 1660 ? 2 : 4}
                                                xl={2}
                                                onClick={() => redirectTOVehicleUsedInvoice('VAN USED INVOICE', 1)}
                                            >
                                                <VehicleCard
                                                    label="VAN USED INVOICE"
                                                    mtdProfit={state.sales.usedInvocingProfit}
                                                    mtdBudget={state.sales.usedInvocingBudgetProfit}
                                                    mtdUnit={state.sales.usedInvocingUnits}
                                                    mtdBudgetunit={state.sales.usedInvocingBudgetUnits}
                                                    ytdProfit={state.sales.ytdUsedInvocingProfit}
                                                    ytdBudget={state.sales.ytdUsedInvocingBudgetProfit}
                                                    ytdUnit={state.sales.ytdUsedInvocingUnits}
                                                    ytdBudgetunit={state.sales.ytdUsedInvocingBudgetUnits}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={WindowWidths > 1660 ? 2 : 4} xl={2}>
                                                <Grid container justifyContent="center" alignItems="center" className="detailDashmainCard">
                                                    <Grid item xs={12} className="p-100">
                                                        <Typography className="mainCardTextPrimary  ml-10">ORDER INTAKE</Typography>
                                                    </Grid>
                                                    {/* <Grid item xs={12}>
                                                    <Typography style={{ margin: WindowWidths > 1280 ? 50 : 35 }}></Typography>
                                                </Grid> */}

                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">MTD</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">YTD</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{ margin: WindowWidths > 1280 ? 45 : 35 }}></Typography>
                                                    </Grid>

                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">VAN</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">VAN</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={6} onClick={() => redirectTOVehicleOrderIntek('VAN ORDER INTAKE', 1)}>
                                                            <Typography className="salesCurrencyFont ">{state?.sales?.vanOrdersUnits || 0}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} onClick={() => redirectTOVehicleOrderIntek('VAN ORDER INTAKE', 1)}>
                                                            <Typography className="salesCurrencyFont ">{state?.sales?.ytdVanOrdersUnits || 0}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{ margin: 25 }}></Typography>
                                                    </Grid>

                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">TRUCK</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography className="mainCardTextPrimary ">TRUCK</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={6} onClick={() => redirectTOVehicleOrderIntek('TRUCK ORDER INTAKE', 2)}>
                                                            <Typography className="salesCurrencyFont ">{state?.sales?.truckOrdersUnits || 0}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} onClick={() => redirectTOVehicleOrderIntek('TRUCK ORDER INTAKE', 2)}>
                                                            <Typography className="salesCurrencyFont ">{state?.sales?.ytdTruckOrdersUnits || 0}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{ margin: WindowWidths > 1280 ? 45 : 35 }}></Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={4}
                                                lg={WindowWidths > 1660 ? 2 : 4}
                                                xl={2}
                                                onClick={() => redirectTOVehicleDetails('TRUCK REGISTRATIONS', 2)}
                                            >
                                                <VehicleCardNew
                                                    label="TRUCK REGISTRATIONS "
                                                    mtdProfit={state.sales.truckRegistrationsProfit}
                                                    mtdBudget={state.sales.truckRegistrationsBudgetProfit}
                                                    mtdUnit={state.sales.truckRegistrationsUnits}
                                                    mtdBudgetunit={state.sales.truckRegistrationsBudgetUnits}
                                                    ytdProfit={state.sales.ytdTruckRegistrationsProfit}
                                                    ytdBudget={state.sales.ytdTruckRegistrationsBudgetProfit}
                                                    ytdUnit={state.sales.ytdTruckRegistrationsUnits}
                                                    ytdBudgetunit={state.sales.ytdTruckRegistrationsBudgetUnits}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={4}
                                                lg={WindowWidths > 1660 ? 2 : 4}
                                                xl={2}
                                                onClick={() => redirectTOVehicleUsedInvoice('TRUCK USED INVOICE', 2)}
                                            >
                                                <VehicleCard
                                                    label="TRUCK USED INVOICE"
                                                    mtdProfit={state.sales.truckUsedInvocingProfit}
                                                    mtdBudget={state.sales.truckUsedInvocingBudgetProfit}
                                                    mtdUnit={state.sales.truckUsedInvocingUnits}
                                                    mtdBudgetunit={state.sales.truckUsedInvocingBudgetUnits}
                                                    ytdProfit={state.sales.ytdTruckUsedInvocingProfit}
                                                    ytdBudget={state.sales.ytdTruckUsedInvocingBudgetProfit}
                                                    ytdUnit={state.sales.ytdTruckUsedInvocingUnits}
                                                    ytdBudgetunit={state.sales.ytdTruckUsedInvocingBudgetUnits}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={4}
                                                lg={WindowWidths > 1660 ? 2 : 4}
                                                xl={2}
                                                onClick={() => redirectTOVehiclEVTarget('EV TARGET')}
                                            >
                                                <VehicleCard
                                                    isElectric={true}
                                                    label="EV TARGET"
                                                    mtdProfit={state.sales.evUnits}
                                                    mtdBudget={state.sales.evBudgetUnits}
                                                    ytdProfit={state.sales.ytdevUnits}
                                                    ytdBudget={state.sales.ytdevBudgetUnits}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default BellTruckDashboard;
