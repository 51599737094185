import React, { useContext } from 'react';
import DataGridComp from '../../../Core/Grid';
import { getTickers, getTickerDelete } from '../../../Core/Service/KPI_message-service';
import { useState } from 'react';
import { useEffect } from 'react';
import DialogComp from '../../../Core/Modal/dialogModal';
import AddEditTicker from './addEditTicker';
import { Grid, Tooltip } from '@material-ui/core';
import { SquareButton } from '../../../Core/FormInput/AppButton';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import AppContext from '../../../App/AppContext';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';

const KipMessages = () => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        tickerList: [],
        showAddUpdate: false
    });

    const handleAddUpdate = (val) => {
        setState((st) => ({ ...st, showAddUpdate: true, id: val.id }));
    };

    const closeAddUpdate = (res) => {
        setState((st) => ({ ...st, showAddUpdate: false, id: '' }));
        res && pulldata();
    };

    const handledelete = async (val) => {
        let data = { id: val.id };
        let res = await getTickerDelete(val.id, data);
        if (res.success) {
            showToast(`Ticker Message Deleted successfully`);
            pulldata();
        } else {
            showToast(`Ticker Message Failed to Delete`);
        }
    };

    const handleEdit = (val) => {
        return (
            <Grid container spacing={1}>
                <Grid item style={{ cursor: 'pointer' }}>
                    <Tooltip title="Update">
                        <EditIcon fontSize="small" style={{ color: 'black' }} onClick={() => handleAddUpdate(val.row)} />
                    </Tooltip>
                </Grid>
                <Grid item style={{ cursor: 'pointer' }}>
                    <Tooltip title="Delete">
                        <ClearIcon fontSize="small" style={{ color: 'black' }} onClick={() => handledelete(val.row)} />
                    </Tooltip>
                </Grid>
            </Grid>
        );
    };

    const column_new = [
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 120,
            renderCell: handleEdit
        },
        {
            field: 'id',
            headerName: 'TICKER ID',
            width: 120,
            sortable: false,
            renderCell: (params) => {
                <div>{params}</div>;
            }
        },
        {
            field: 'message',
            headerName: 'TICKER MESSAGE',
            flex: 1,
            renderCell: (params) => {
                <div>{params}</div>;
            }
        }
    ];
    const pulldata = async () => {
        let res = await getTickers();
        if (res.success) {
            setState((st) => ({ ...st, tickerList: res.data.list }));
        }
    };
    useEffect(() => {
        pulldata();
    }, []);

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Ticker Messages', active: true }
    ];

    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <div style={{ margin: '0px 10px' }}>
                <Grid container>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '0%',
                            // width: '100px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 10px 0px 10px'
                        }}
                        onClick={handleAddUpdate}
                    >
                        <span style={{ color: 'white', fontSize: 12 }}>+ Add Ticker Message</span>
                    </SquareButton>
                </Grid>
                <DataGridComp rows={state.tickerList} columns={column_new} offset={244} rowHeight={40} headerHeight={40} />
            </div>
            {state.showAddUpdate ? (
                <DialogComp onClose={() => closeAddUpdate(false)} title={!state.id ? `Add Ticker Message` : 'Update Ticker Message'} fullWidth="lg">
                    <AddEditTicker onClose={closeAddUpdate} id={state.id} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default KipMessages;
