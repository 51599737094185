export let validatePostCode = (postCode) => {
    // const pattern = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i;
    const pattern =
        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/gm;
    return pattern.test(postCode);
};

export let emailValidation = (emailValidation) => {
    const pattern =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailValidation || emailValidation == null) return false;

    if (emailValidation.includes(',')) {
        let multiEmailTest = true;
        emailValidation.split(',').map((email) => {
            if (!pattern.test(email)) multiEmailTest = false;
        });
        return multiEmailTest;
    } else {
        return pattern.test(emailValidation);
    }
};

export let singleEmailValidation = (emailValidation) => {
    const pattern =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(emailValidation);
};
