import { get, post } from './http-calls';
import moment from 'moment';

const downloadFileWithExt = async (res, fileExt) => {
    if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
        link.id = 'tempDownloadPDFLink';
        link.href = url;
        link.setAttribute('download', `ArchivedJobs${moment().format('DD-MMM-YYYY HH:mm')}.${fileExt}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return true;
    }
};

export const getArchivedJobs = async (page, Size, Wipno, CustName) => {
    let res = await get(
        `ArchivedJobs?page=${page}&limit=${Size}&sortBy=&direction=&WipNo=${Wipno}&CustomerName=${CustName}&Registration=&ChassisNumber=&BranchShortName=&FranchiseName=&AccountNo=&MakeModel=`,
        { useAuthToken: true }
    );
    return res;
};

export const getGetOldJobs_Workflows = async (page, Size, Wipno, CustName) => {
    let res = await get(`ArchivedJobs/GetOldJobs_Workflows`, { useAuthToken: true });
    return res;
};

export const postMakeJobActive = async (HeaderRecordID, WorkflowID) => {
    let res = await post(`ArchivedJobs/MakeJobActive?HeaderRecordID=${HeaderRecordID}&WorkflowID=${WorkflowID}`, {}, { useAuthToken: true });
    return res;
};

export const GetArchivedJobsExcelFile = async (params) => {
    let res = await get(`ArchivedJobs/GetExcelFile`, {
        responseType: 'blob',
        returnOrgRes: true,
        useAuthToken: true
    });
    downloadFileWithExt(res, 'xlsx');
};
