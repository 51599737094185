import { FormLabel, Grid } from '@material-ui/core';
import React from 'react';
import formatters from '../../../../Core/Grid/inputFormatter';

const BookingDetailmodal = (props) => {
    const selectedBookingData = props.selectedBookingData;
    return (
        <Grid container spacing={2} style={{ paddingBottom: '10px' }}>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <FormLabel>WIP No</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <b>{selectedBookingData.wipNumber}</b>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <FormLabel>Customer</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <b>{selectedBookingData.customerName}</b>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <FormLabel>Reg No</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <b>{selectedBookingData.registration}</b>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <FormLabel>Make/Model</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <b>{selectedBookingData.makeModel}</b>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <FormLabel>Branch Name</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <b>{selectedBookingData.branchShortName}</b>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <FormLabel>Franchise Name</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <b>{selectedBookingData.franchiseName}</b>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <FormLabel>Date Due In</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <b>{formatters.DateTimeFormatter(selectedBookingData.dateDueIn)}</b>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <FormLabel>Date Due Out</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <b>{formatters.DateTimeFormatter(selectedBookingData.dateDueOut)}</b>
                </Grid>
            </Grid>
            <Grid container item xs={6}></Grid>
        </Grid>
    );
};

export default BookingDetailmodal;
