import { CircularProgress, Grid, InputLabel } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { getMotHistory } from '../../../../Core/Service/ReportScreens-service';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Alert from '@material-ui/lab/Alert';
import ErrorIcon from '@material-ui/icons/Error';
import './motHistory.scss';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import formatters from '../../../../Core/Grid/inputFormatter';

var dateInPast = function (firstDate) {
    if (firstDate && new Date(firstDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
        return true;
    }
    return false;
};

const MotHistoryScreen = (props) => {
    const [state, setState] = useState({
        dvla: null,
        lcvMot: null,
        hgvMOT: null,
        errors: {},
        isResponse: false,
        isLoader: false,
        make: '',
        model: ''
    });
    const pullMotHistory = async (regNo, FranchiseCode) => {
        if (props.regNo || regNo) {
            setState((st) => ({
                ...st,
                isResponse: false,
                isLoader: true
            }));
            const res = await getMotHistory(regNo || props.regNo, props.FranchiseCode || '');
            setState((st) => ({
                ...st,
                dvla: res.data.dvla.response,
                lcvMot: res.data.lcvMot.response,
                hgvMOT: res.data.hgvMOT.response,
                isMotExpiryDate: dateInPast(res.data.dvla?.response?.motExpiryDate),
                isAnnualTestExpiryDate: dateInPast(res.data.hgvMOT?.response?.annualTestExpiryDate),
                isResponse: true,
                isLoader: false,
                model: res?.data?.dvla?.response?.model
                    ? res?.data?.dvla?.response?.model
                    : res?.data?.lcvMot?.response?.model
                    ? res?.data?.lcvMot?.response?.model
                    : res?.data?.hgvMOT?.response?.model
            }));
        } else {
            setState((st) => ({
                ...st,
                dvla: null,
                lcvMot: null,
                hgvMOT: null,
                isMotExpiryDate: null,
                isAnnualTestExpiryDate: null,
                isResponse: false,
                isLoader: false
            }));
        }
    };

    useEffect(() => {
        pullMotHistory();
    }, [props.regNo, props.FranchiseCode]);

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            let newSt = { ...st };
            newSt[name] = value.toUpperCase();
            return newSt;
        });
    };

    const handleSubmit = () => {
        pullMotHistory(state.regNumber, '');
    };

    return (
        <div className="mot-history-container" style={{ padding: !props.hideSeachText ? '10px' : '0px' }}>
            {!props.hideSeachText && (
                <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item xs={12} sm={6} md={3} className="mandatoryfields">
                        <InputLabel shrink>Reg Number</InputLabel>
                        <TextBox autoFocus name="regNumber" onChange={fieldChange} value={state.regNumber} />
                        <span className="mandatoryfields">{state.errors['regNumber']}</span>
                    </Grid>
                    <Grid item xs={12} sm={2} md={1}>
                        <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                            GO
                        </SecondaryButton>
                    </Grid>
                </Grid>
            )}
            {state.isResponse ? (
                <Grid container style={{ textAlign: 'left', border: '1px solid #f0f0f0' }} spacing={1}>
                    <Grid item xs={12} style={{ color: 'white', padding: '5px', backgroundColor: 'rgb(74, 144, 226)' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={3}>
                                <Grid item xs={12}>
                                    Reg No
                                </Grid>
                                <Grid item xs={12}>
                                    <b>{state.dvla.registrationNumber}</b>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={5}>
                                <Grid item xs={12}>
                                    Make/Model
                                </Grid>
                                <Grid xs={12}>
                                    <b>{`${state.dvla.make} (${state.model})`}</b>
                                </Grid>
                            </Grid>
                            {state.lcvMot && (
                                <Grid item xs={6} sm={4}>
                                    <Grid item xs={12}>
                                        {state.lcvMot.motTests ? 'MOT Valid Until' : 'MOT Due Date'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <b>
                                            {state.lcvMot.motTests
                                                ? formatters.YearFormatter(state.lcvMot.motTests[0].expiryDate)
                                                : formatters.YearFormatter(state.lcvMot.motTestDueDate)}
                                        </b>
                                    </Grid>
                                </Grid>
                            )}
                            {state.hgvMOT && (
                                <Grid item xs={6} sm={4}>
                                    {state.hgvMOT?.annualTestExpiryDate != null && (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                MOT Valid Until
                                            </Grid>
                                            <Grid item xs={12}>
                                                <b>{formatters.YearFormatter(state.hgvMOT?.annualTestExpiryDate)}</b>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                            <Grid item xs={6} sm={3}>
                                <Grid item xs={12}>
                                    Tax Status
                                </Grid>
                                <Grid item xs={12}>
                                    <b>{state.dvla.taxStatus}</b>
                                </Grid>
                            </Grid>

                            {state.dvla.taxDueDate != null && (
                                <Grid item xs={6} sm={4}>
                                    <Grid item xs={12}>
                                        Tax Due
                                    </Grid>
                                    <Grid item xs={12}>
                                        <b>{formatters.YearFormatter(state.dvla.taxDueDate)}</b>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {state.lcvMot != null ? (
                        <>
                            {state.isMotExpiryDate && (
                                <Grid item xs={12} style={{ backgroundColor: 'red', color: '#fff', textAlign: 'center' }}>
                                    <h1 style={{ color: '#fff' }}>This Vehicle's MOT has expired</h1>
                                    <h4 style={{ color: '#fff' }}>You can fined up to £1000 for Driving without a valid MOT</h4>
                                    <h4 style={{ color: '#fff' }}>
                                        This vehicle may be MOT exempt, for more information refer to
                                        <a
                                            style={{ color: '#fff !important', borderBottom: '1px solid #fff' }}
                                            target="_blank"
                                            href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/925057/v112-declaration-of-exemption-from-mot.pdf#page=2"
                                        >
                                            <b>MOT exemption guidance</b>
                                        </a>
                                    </h4>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                container
                                style={{
                                    padding: '0px 0px',
                                    ...(state.dvla.taxStatus == 'Untaxed' ? { color: '#f0f0f0', backgroundColor: 'red' } : {})
                                }}
                            >
                                {/* <Grid item xs={12} style={{ color: 'white', padding: '5px', backgroundColor: 'rgb(74, 144, 226)' }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} sm={3}>
                                            <Grid item xs={12}>
                                                Reg No
                                            </Grid>
                                            <Grid item xs={12}>
                                                <b>{state.lcvMot.registration}</b>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} sm={5}>
                                            <Grid item xs={12}>
                                                Make/Model
                                            </Grid>
                                            <Grid xs={12}>
                                                <b>{`${state.lcvMot.make} (${state.lcvMot.model})`}</b>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Grid item xs={12}>
                                                {state.lcvMot.motTests ? 'MOT Valid Until' : 'MOT Due Date'}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <b>
                                                    {state.lcvMot.motTests
                                                        ? formatters.YearFormatter(state.lcvMot.motTests[0].expiryDate)
                                                        : formatters.YearFormatter(state.lcvMot.motTestDueDate)}
                                                </b>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Grid item xs={12}>
                                                Tax Status
                                            </Grid>
                                            <Grid item xs={12}>
                                                <b>{state.dvla.taxStatus}</b>
                                            </Grid>
                                        </Grid>

                                        {state.dvla.taxDueDate != null && (
                                            <Grid item xs={6} sm={4}>
                                                <Grid item xs={12}>
                                                    Tax Due
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <b>{formatters.YearFormatter(state.dvla.taxDueDate)}</b>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid> */}
                                <Grid item xs={12} style={{ padding: '4px' }}>
                                    {state.lcvMot.motTests != null ? (
                                        <>
                                            {state.lcvMot.motTests.map((q, i) => {
                                                return (
                                                    <Grid
                                                        container
                                                        style={{ padding: '10px 8px', backgroundColor: i % 2 === 0 ? '#e7e7e7' : '#fafafa' }}
                                                        spacing={1}
                                                    >
                                                        <Grid item xs={12} className="text-center">
                                                            {`Test Date: ${formatters.YearFormatter(q.completedDate)}`}
                                                        </Grid>
                                                        <Grid item xs={12} className="table-responsive w-100">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr className="text-uppercase small text-muted">
                                                                        <th scope="col">Result</th>
                                                                        <th scope="col">Test No.</th>
                                                                        <th scope="col">Mileage</th>
                                                                        <th scope="col">Expiry Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="mot_history_entry_result" scope="row">
                                                                        {q.testResult == 'PASSED' ? (
                                                                            <td>
                                                                                <span className="icon-container">
                                                                                    <CheckCircleIcon style={{ color: 'green' }} />
                                                                                </span>
                                                                                <span>
                                                                                    <strong className="text-success">Pass</strong>
                                                                                </span>
                                                                            </td>
                                                                        ) : (
                                                                            <td>
                                                                                <span className="icon-container">
                                                                                    <CancelIcon style={{ color: 'red' }} />
                                                                                </span>
                                                                                <span>
                                                                                    <strong className="text-success">Fail</strong>
                                                                                </span>
                                                                            </td>
                                                                        )}
                                                                        <td>{q.motTestNumber}</td>
                                                                        <td>{q.odometerValue}</td>
                                                                        <td>{q.expiryDate != null ? formatters.YearFormatter(q.expiryDate) : ''} </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Grid>

                                                        {q.rfrAndComments != null && (
                                                            <>
                                                                {q.rfrAndComments.map((m) => {
                                                                    return (
                                                                        <Grid item xs={12}>
                                                                            {m.type == 'ADVISORY' ? (
                                                                                <Alert
                                                                                    style={{ textAlign: 'center' }}
                                                                                    severity="warning"
                                                                                    icon={<ErrorIcon fontSize="inherit" />}
                                                                                >
                                                                                    {m.text}
                                                                                </Alert>
                                                                            ) : m.type == 'PRS' ||
                                                                              m.type == 'FAILED' ||
                                                                              //   m.type == 'MAJOR' ||
                                                                              //   m.type == 'MINOR' ||
                                                                              q.testResult == 'FAILED' ? (
                                                                                <Alert severity="error" icon={<CancelIcon fontSize="inherit" />}>
                                                                                    {m.text}
                                                                                </Alert>
                                                                            ) : (
                                                                                <Alert severity="success" icon={<CheckCircleIcon fontSize="inherit" />}>
                                                                                    {m.text}
                                                                                </Alert>
                                                                            )}
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </>
                                                        )}
                                                    </Grid>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            <Grid item xs={12}>
                                                <Alert severity="success" icon={<CheckCircleIcon fontSize="inherit" />}>
                                                    MOT records not available
                                                </Alert>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    ) : state.hgvMOT != null ? (
                        <>
                            {state.isAnnualTestExpiryDate && (
                                <Grid item xs={12} style={{ backgroundColor: 'red', color: '#fff', textAlign: 'center' }}>
                                    <h1 style={{ color: '#fff' }}>This Vehicle's annual test has expired</h1>
                                    <br />
                                    <h4 style={{ color: '#fff' }}>
                                        If the vehicle has been tested recently, it can take up to 10 working day for DVSA to update records
                                    </h4>
                                    <br />
                                    <h4 style={{ color: '#fff' }}>
                                        This vehicle may be MOT exempt, for more information refer to
                                        <a
                                            style={{ color: '#fff !important', borderBottom: '1px solid #fff' }}
                                            target="_blank"
                                            href="https://www.gov.uk/government/publications/exemption-from-heavy-goods-vehicle-hgv-annual-testing-v112g?_ga=2.119468495.733291534.1605659116-1028051267.1603407825"
                                        >
                                            <b>MOT exemption guidance</b>
                                        </a>
                                    </h4>
                                </Grid>
                            )}
                            {/* <Grid
                                item
                                container
                                xs={12}
                                style={{
                                    padding: '0px 0px',
                                    ...(state.dvla.taxStatus == 'Untaxed' ? { color: '#f0f0f0', backgroundColor: 'red' } : {})
                                }}
                             >
                               
                                <Grid item xs={12} style={{ color: 'white', padding: '5px', backgroundColor: 'rgb(74, 144, 226)' }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Grid item xs={12}>
                                                Reg No
                                            </Grid>
                                            <Grid item xs={12}>
                                                <b>{state.hgvMOT.registration}</b>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                Make/Model
                                            </Grid>
                                            <Grid xs={12}>
                                                <b>{`${state.hgvMOT.make} (${state.hgvMOT.model})`}</b>
                                            </Grid>
                                        </Grid>

                                        {state.hgvMOT.annualTestExpiryDate != null && (
                                            <Grid item xs={3}>
                                                <Grid item xs={12}>
                                                    MOT Valid Until
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <b>{formatters.YearFormatter(state.hgvMOT.annualTestExpiryDate)}</b>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ color: 'white', padding: '5px', backgroundColor: 'rgb(74, 144, 226)' }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Grid item xs={12}>
                                                Tax Status
                                            </Grid>
                                            <Grid item xs={12}>
                                                <b>{state.dvla.taxStatus}</b>
                                            </Grid>
                                        </Grid>
                                        {state.dvla.taxDueDate != null && (
                                            <Grid item xs={6}>
                                                <Grid item xs={12}>
                                                    Tax Due
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <b>{formatters.YearFormatter(state.dvla.taxDueDate)}</b>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid> */}

                            <Grid item xs={12} style={{ padding: '4px' }}>
                                {state.hgvMOT.annualTests != null ? (
                                    <>
                                        {state.hgvMOT.annualTests.map((q, i) => {
                                            return (
                                                <Grid
                                                    container
                                                    style={{ padding: '10px 8px', backgroundColor: i % 2 === 0 ? '#e7e7e7' : '#fafafa' }}
                                                    spacing={1}
                                                >
                                                    <Grid item xs={12} className="text-center">
                                                        {`Test Date: ${formatters.YearFormatter(q.testDate)}`}
                                                    </Grid>
                                                    <Grid item xs={12} className="table-responsive w-100">
                                                        <table className="table">
                                                            <thead>
                                                                <tr className="text-uppercase small text-muted">
                                                                    <th scope="col">Result</th>
                                                                    <th scope="col">Test No.</th>
                                                                    <th scope="col">Expiry Date</th>
                                                                    <th scope="col">Location</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className="mot_history_entry_result" scope="row">
                                                                    {q.testResult == 'PASSED' ? (
                                                                        <td>
                                                                            <span className="icon-container">
                                                                                <CheckCircleIcon style={{ color: 'green' }} />
                                                                            </span>
                                                                            <span>
                                                                                <strong className="text-success">Pass</strong>
                                                                            </span>
                                                                        </td>
                                                                    ) : (
                                                                        <td>
                                                                            <span className="icon-container">
                                                                                <CancelIcon style={{ color: 'red' }} />
                                                                            </span>
                                                                            <span>
                                                                                <strong className="text-success">Fail</strong>
                                                                            </span>
                                                                        </td>
                                                                    )}
                                                                    <td>{q.testCertificateNumber}</td>
                                                                    <td>{q.expiryDate != null ? formatters.YearFormatter(q.expiryDate) : ''}</td>
                                                                    <td>{q.location}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Grid>

                                                    {q.defects != null ? (
                                                        <>
                                                            {q.defects.map((m) => {
                                                                return (
                                                                    <Grid item xs={12}>
                                                                        {m.severityDescription.toUpperCase() == 'ADVISORY' ? (
                                                                            <Alert
                                                                                style={{ textAlign: 'center', backgroundColor: 'amber' }}
                                                                                severity="warning"
                                                                                icon={<ErrorIcon fontSize="inherit" />}
                                                                            >
                                                                                {`${m.failureReason}-${m.severityDescription}`}
                                                                            </Alert>
                                                                        ) : m.severityDescription.toUpperCase() == 'FAIL' ? (
                                                                            <Alert
                                                                                severity="error"
                                                                                style={{ backgroundColor: 'red', color: 'white' }}
                                                                                icon={<CancelIcon fontSize="inherit" style={{ color: 'white' }} />}
                                                                            >
                                                                                {`${m.failureReason}-${m.severityDescription}`}
                                                                            </Alert>
                                                                        ) : (
                                                                            <Alert severity="success" icon={<CheckCircleIcon fontSize="inherit" />}>
                                                                                {`${m.failureReason}-${m.severityDescription}`}
                                                                            </Alert>
                                                                        )}
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </>
                                                    ) : q.testResult == 'PASSED' ? (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Alert severity="success" icon={<CheckCircleIcon fontSize="inherit" />}>
                                                                    {`Passed MOT with no advisories`}
                                                                </Alert>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Grid>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Alert severity="success" icon={<CheckCircleIcon fontSize="inherit" />}>
                                                No MOT with no advisories
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Alert severity="error" icon={<CancelIcon fontSize="inherit" />}>
                                Resource not found
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            ) : (
                <Grid item sm={12} container alignItems="center" justify="center" style={{ width: 'calc(100vh - 230px)', height: 640 }}>
                    {state.isLoader ? <CircularProgress /> : null}
                </Grid>
            )}
        </div>
    );
};

export default MotHistoryScreen;
