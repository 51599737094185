import { FormLabel, Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import AppContext from '../../../../App/AppContext';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { addUpdateResultList } from '../../../../Core/Service/qc-service';

const EditResultList = (props) => {
    const [state, setState] = useState({
        QCResultListID: props?.focusedRow?.qcResultListID ? props?.focusedRow?.qcResultListID : null,
        QCResultListDescription: props?.focusedRow?.qcResultListDescription ? props?.focusedRow?.qcResultListDescription : '',

        errors: [],
    });

    const { showToast } = useContext(AppContext);

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        setState((st) => ({
            ...st,
            [name]: val
        }))
    }

    const validations = () => {
        const {
            QCResultListDescription
        } = state;
        let formIsValid = true;
        let errors = {};

        if (QCResultListDescription == null || QCResultListDescription == '') {
            errors.QCResultListDescription = 'Description field is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if(validations()) {
            const data = {
                "QCResultListID": state.QCResultListID ? state.QCResultListID : null,
                "QCResultListDescription": state.QCResultListDescription
            }
            let res = await addUpdateResultList(data);
            if (!res.success) {
                console.error(res);
                showToast("Error occured.")
            } else {
                showToast("QC Results List updated successfully")
                props.onClose()
            }
        }
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        Result List Description
                    </FormLabel>
                    <TextBox value={state.QCResultListDescription} name="QCResultListDescription" onChange={handleFieldChange} />
                    <span className="mandatoryfields">{state.errors['QCResultListDescription']}</span>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
}
export default EditResultList;
