
const getMultipleTxtToImg = (value, cb) => {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext('2d');          
    var lines = value.split("\n");
    ctx.font = "17px Arial"
    let longest = lines.reduce((r, e) => r.length < e.length ? e : r, "");
    canvas.width = ctx.measureText(longest).width + 90;
    canvas.height = lines.length * 22;
    ctx.font = "17px Arial"
    for(var i=0; i<lines.length; i++){
         ctx.fillText(lines[i], 20, 20*i +20);
    }
    var img = document.createElement("img");
    img.src = ctx.canvas.toDataURL();    
    cb(img.src);
};
export default getMultipleTxtToImg;
