import { get } from './http-calls';

export const getWorkflowMovementHistory = async (headerRecordId) => {
    let res = await get(`WorkflowMovement/${headerRecordId}`, { useAuthToken: true });
    return res;
};

export const getNewUsedStockWorkflowMovementHistory = async (StockID, IsNew) => {
    let res = await get(`WorkflowMovement/NewUsedStock/${StockID}?IsNew=${IsNew}`, { useAuthToken: true });
    return res;
};

export const getReturnReasonHistory = async (headerId) => {
    let res = await get(`ReturnReason_History/${headerId}`, { useAuthToken: true });
    return res;
};
export const getNewStockReturnReasonHistory = async (stockID, isNew = true) => {
    let res = await get(`ReturnReasons/Stock/ReturnReason_History/${stockID}?isNew=${isNew}`, { useAuthToken: true });
    return res;
};
