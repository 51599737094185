import React, { useEffect, useState, useMemo } from 'react';
import { Link } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Breadcrumbs } from '@material-ui/core';
import ReportGijgoGrid from '../../internal/ReportsScreens/GijgoForReportsScreen';
import { SquareButton } from '../../../Core/FormInput/AppButton';
import formatters from '../../../Core/Grid/inputFormatter';
import { Call, Image } from '@material-ui/icons';
import ReactDOM from 'react-dom';
import { updateOperator } from '../../../Core/Service/operator-details-service';

const Operators = (props) => {
    const [state, setState] = useState({
        isReload: new Date()
    });

    const handleSelectCall = async (rec) => {
        let isCallCentreOperator = false;

        if(rec.isCallCentreOperator == false || rec.isCallCentreOperator == null) {
            isCallCentreOperator = true;
        }

        const data = {
            Company: rec.company,
            OperatorNumber: rec.operatorNumber,
            OperatorCode: rec.operatorCode,
            IsCallCentreOperator: isCallCentreOperator,
        }

        const res = await updateOperator(data)

        setState((st) => ({
            ...st,
            isReload: new Date()
        }))
    }

    const baseColumns = [
        {
            field: 'action',
            title: `Action`,
            width: '5%',
            filterable: false,
            sortable: false,
        },
        {
            field: 'operatorName',
            title: `Operator Name`,
            filterable: true,
            sortable: true,
        },
        {
            field: 'company',
            title: `Company`,
            filterable: true,
            sortable: true,
        },
        {
            field: 'operatorNumber',
            title: `Operator Number`,
            filterable: true,
            sortable: true,
        },
        {
            field: 'operatorCode',
            title: `Operator Code`,
            filterable: true,
            sortable: true,
        },
        {
            field: 'operatorLocation',
            title: `Operator Location`,
            filterable: true,
            sortable: true,
        },
        {
            field: 'isCallCentreOperator',
            title: `Call Centre Operator`,
            filterable: true,
            sortable: true,
        },
    ];

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent='space-evenly'>
                <SquareButton
                    style={{
                        backgroundColor: record.isCallCentreOperator ? '#00aa00' : '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => handleSelectCall(record)}
                >
                    <Call fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const callCentreRen = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <>
                {record.isCallCentreOperator ? 'Yes' : 'No'}
            </>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');
        let callCentreCol = tempCols.find((element) => element.field === 'isCallCentreOperator');

        if (actionCol) {
            actionCol.renderer = editButton({});
        }
        if (callCentreCol) {
            callCentreCol.renderer = callCentreRen({});
        }

        return tempCols;
    }, []);

    const baseUrl = 'OperatorDetails/OperatorDetails';

    return (
        <div>
            <Breadcrumbs separator=">>" aria-label="breadcrumb" style={{ marginTop: '25px', marginLeft: '5px' }}>
                <Link color="inherit" to={"/"}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: "0.85rem" }}>
                    Operators
                </Typography>
            </Breadcrumbs>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data,
                            total: res?.data[0]?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isReload={state.isReload}
                    />
                </Grid>
            </Grid>
        </div>
    );
};
export default Operators;
