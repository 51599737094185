import { get, post } from './http-calls';

export const getBrakeReport = async (headerId) => {
    let res = await get(`BrakeReports/${headerId}`, { useAuthToken: true });
    return res;
};

export const postBrakeReport = async (data) => {
    let res = await post(`BrakeReports`, data, { useAuthToken: true });
    return res;
};