import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Tooltip, CircularProgress } from '@material-ui/core';
import AppContext from '../../../App/AppContext';
import { SecondaryButton } from '../../../Core/FormInput';
import { getPhotosByPartReqID, postPhotosByPartReqID, PartReqPhotoDeleteByPhotoId } from '../../../Core/Service/partRequisitions-service';
import { Backup } from '@material-ui/icons';
import getMultiImgResized from '../../../Core/CommonFunctions/multiImgResizer';
import _, { find, some, remove } from 'lodash';

function PartReqPhotoModal(props) {
    const [state, setState] = useState({
        id: null,
        Photos: [],
        errors: {},
        showLoader: true
    });

    const [inputList, setInputList] = useState([]);

    const { showToast } = useContext(AppContext);

    useEffect(async () => {
        getPhotos();
    }, []);

    const getPhotos = async () => {
        if (props.partId) {
            let res = await getPhotosByPartReqID(props.partId);
            if (res.success) {
                let data = res?.data?.list;
                setPhotos('oldPhoto', ...data);
                setState((st) => ({
                    ...st,
                    id: props.partId,
                    showLoader: false
                }));
                getOldNewPhotos();
            }
        }
    };

    const getOldNewPhotos = () => {
        let getPhoto = props.rows.filter((x) => x.id == props.partId);
        setState((st) => {
            let newSt = { ...st };
            newSt.Photos = [];
            newSt.Photos = [...getPhoto[0]['oldPhoto'], ...getPhoto[0]['Photos']];
            return newSt;
        });
    };

    const upload = async (e) => {
        getMultiImgResized(e.target.files, (fileUrl) => {
            let data = fileUrl.map((m, i) => {
                return { partReqPhotoID: null, partReqPhotoFile: m };
            });
            setPhotos('Photos', ...data);
            getOldNewPhotos();
        });
    };

    const handleRemoveClick = async (index) => {
        let res = await PartReqPhotoDeleteByPhotoId(index);
        if (res.success) {
            showToast('Image Deleted sucessfully');
            props.onFormSubmit();
            setState((st) => {
                let newSt = { ...st };
                remove(newSt.Photos, (el) => el.partReqPhotoID === index);
                newSt.Photos = [...newSt.Photos];
                return newSt;
            });
        } else {
            showToast(res.message);
        }
    };

    const submitHandler = async () => {
        props.onClose(false);
    };

    const setPhotos = (name, ...val) => {
        let changedArray = [...props.rows];
        let objIndex = props.rows.findIndex((x) => x.id === props.partId);
        changedArray[objIndex][name] = [...changedArray[objIndex][name], ...val];
        props.onImageChange({ rows: changedArray });
    };
    return (
        <div>
            <Grid container spacing={2} className="main-container">
                {state.showLoader ? (
                    <Grid item sm={12} container alignItems="center" justify="center" style={{ width: 'calc(100vh - 230px)', height: 640 }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12} className="mandatory-fields">
                            <div>
                                <InputLabel fullWidth> {props.desc} </InputLabel>
                            </div>
                        </Grid>
                        <Grid item xs={12} container alignContent="flex-start" style={{ width: 'calc(100vh - 230px)', height: 'calc(100vh - 230px)' }}>
                            <Grid item xs={12}>
                                <Tooltip title="Upload Document">
                                    <label>
                                        <Backup color="secondary" />
                                        <input
                                            name="branchStampImage"
                                            multiple
                                            type="file"
                                            onChange={(e) => upload(e)}
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                        />
                                    </label>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} spacing={2} style={{ height: '85%' }}>
                                <Grid container spacing={2} style={{ overflow: 'auto', height: '100%' }}>
                                    {state.Photos.map((item, i) => (
                                        <Grid xs={12} sm={3} md={3} lg={3} item key={i} className="image-item" style={{ marginTop: 15 }}>
                                            <img src={item.partReqPhotoFile} alt="No images" width="100%" height="150px" />
                                            <div className="image-item__btn-wrapper">
                                                {item.partReqPhotoID && (
                                                    <SecondaryButton fullWidth onClick={() => handleRemoveClick(item.partReqPhotoID)}>
                                                        Remove
                                                    </SecondaryButton>
                                                )}
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} clasName="submit_btn">
                                <SecondaryButton fullWidth onClick={submitHandler}>
                                    Add
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
}
//
export default PartReqPhotoModal;

const lable_style = {
    fontSize: '20px',
    fontWeight: 'lighter'
};
