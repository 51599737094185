import React, { useContext, useState, useEffect } from 'react';
import { TextBox, SecondaryButton, Multiselect, YesNoButton } from '../../../../../Core/FormInput';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { UpdateUserDetailsFranchise, getUserDetailsFranchise } from '../../../../../Core/Service/userDetails-service';
import AppContext from '../../../../../App/AppContext';

const SalesExecModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        id: '',
        userName: '',
        videoCategoryActive: true,
        roleIDs: [],
        errors: {},
        fields: {},
        isUsedSalesExec: false,
        isNewSalesExec: false
    });

    const [roleData, setRoleData] = useState({
        roles: []
    });

    const [selectedData, setSelectedData] = useState({
        roles: []
    });

    const AutoRoleChange = (event, value) => {
        setSelectedData((st) => ({ ...st, roles: value }));
        setState((st) => {
            const nst = { ...st, roleIDs: value.map((c) => c.id) };
            return nst;
        });
    };

    const data = props.data;

    useEffect(async () => {
        if (data.operatorID) {
            let res = await getUserDetailsFranchise(data.operatorID);
            if (res.success) {
                const roles = res.data.franchiseList;
                setRoleData((st) => ({ ...st, roles }));
                setState((st) => ({
                    ...st,
                    id: res.data.details[0].operatorID,
                    userName: res.data.details[0].userName,
                    isUsedSalesExec: res.data.details[0].isUsedSalesExec,
                    isNewSalesExec: res.data.details[0].isNewSalesExec
                }));
            }
        }
    }, []);
    useEffect(() => {
        if (props.data) {
            const rolesToAdd = [];
            props.data.letterIdentifier
                .replace(/ /g, '')
                .split(',')
                .map((role) => {
                    roleData.roles.map((r) => {
                        console.log(r);
                        console.log(role);
                        console.log(r.name == role, 'r.name==role');

                        if (r.name.replace(/ /g, '').toLowerCase() == role.replace(/ /g, '').toLowerCase()) {
                            rolesToAdd.push(r.id);
                        }
                    });
                    setState((st) => ({
                        ...st,
                        roleIDs: rolesToAdd
                    }));
                });
        }
    }, [roleData.roles]);

    useEffect(() => {
        if (props.data) {
            const a = state.roleIDs.map((i) => i);
            const selectedModule = roleData.roles?.filter((w) => state.roleIDs.indexOf(w.id) > -1);
            setSelectedData((st) => ({ ...st, roles: selectedModule }));
        }
    }, [roleData.roles, state.roleIDs]);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            const nst = { ...st, [name]: value, fields: fields };
            return nst;
        });
    };

    const handelSubmit = async (e) => {
        e.preventDefault();

        let res = await UpdateUserDetailsFranchise(state.id, state.roleIDs, state.isUsedSalesExec, state.isNewSalesExec);
        if (!res.success) {
            console.error(res);
        } else {
            hideModal();
            props.onFormSubmit(true);
        }
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <FormLabel component="legend" className="mandatoryfields">
                        Name
                    </FormLabel>
                    <TextBox name="userName" onChange={fieldChange} value={state.userName} autoFocus={true} disabled={true} />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Multiselect name="roles" options={roleData.roles} value={selectedData.roles} onChange={AutoRoleChange} />
                </Grid>

                <Grid xs={12} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is New Sales Exec?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.isNewSalesExec}
                        name="isNewSalesExec"
                        onYesClick={() => handleClick('isNewSalesExec', true)}
                        onNoClick={() => handleClick('isNewSalesExec', false)}
                    />
                </Grid>
                <Grid xs={12} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Used Sales Exec?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.isUsedSalesExec}
                        name="isUsedSalesExec"
                        onYesClick={() => handleClick('isUsedSalesExec', true)}
                        onNoClick={() => handleClick('isUsedSalesExec', false)}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default SalesExecModal;
