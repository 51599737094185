import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AppStorage } from './../../../Core/Service/storage-service';
import { IconButton, Tooltip } from '@material-ui/core';
import { ArrowForwardIos, PeopleOutline, Reply } from '@material-ui/icons';
import { ExpBaseURL } from './../../../Core/Service/http-calls';
import AssignToAuditor from './AssignToAuditor';
import './ScreenStyle.scss';
import AppContext from '../../../App/AppContext';
import ReturnToWorkflow from '../workflow-job/ReturnToWorkflow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { GetArchivedJobsExcelFile } from '../../../Core/Service/archivedJobs-service';
import CommonGijgoGrid from '../../../Core/Controls/GijgoGrid';
import SendToWorkflowModal from './SendToWorkflowModal';
import DialogComp from '../../../Core/Modal/dialogModal';

const baseColumns = [
    {
        title: 'Action',
        field: 'id',
        // renderer: editButton,
        width: 40,
        filterable: false
    },
    {
        title: 'WIP Number',
        field: 'wipNumber',
        width: 70,
        sortable: true
    },
    {
        title: 'Vehicle Reg',
        field: 'registration',
        width: 70,
        sortable: true
    },
    {
        title: 'Model',
        field: 'makeModel',
        width: 100,
        sortable: true
    },
    {
        title: 'Customer Name',
        field: 'customerName',
        width: 90,
        sortable: true
    },
    {
        title: 'Chassis Number',
        field: 'chassisNumber',
        width: 100,
        sortable: true
    },
    {
        title: 'Date Due In',
        field: 'dateDueIn',
        width: 90,
        sortable: false,
        type: 'date',
        cssClass: 'text-center',
        renderer: function (value, record) {
            if (record.dateDueIn != null) {
                return moment(record.dateDueIn).format('DD/MM/YYYY');
            }
            return '';
        }
    },
    {
        title: 'Branch',
        field: 'branchShortName',
        width: 80,
        sortable: true
    },
    {
        title: 'Franchise',
        field: 'franchiseName',
        width: 100,
        sortable: true
    },
    {
        title: 'Account',
        field: 'accountNo',
        width: 60,
        sortable: true
    }
];

const AfterSalesOldJobs = (props) => {
    const [state, setState] = useState({
        jobAssignAuditorsTo: false,
        showReturnReason: false,
        workflowID: null,
        jobId: null,
        showModal: false
    });

    const { canAssignJobsToAuditor, showToast } = useContext(AppContext);
    const token = AppStorage.getToken();

    useEffect(async () => {
        if (!token) {
            window.location = '/';
            AppStorage.performLogout();
        }
    }, [token]);

    const handelReturnReason = (job) => {
        setState((st) => ({ ...st, showModal: true, jobDetails: job }));
    };

    const editButton = (vl, job) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center">
                <Grid item>
                    <Tooltip title="Make job active">
                        <IconButton size="small" onClick={() => handelReturnReason(job)}>
                            <Reply fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'id');

        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);

    const closeModal = (Refreshflag) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.showModal = false;
            if (Refreshflag) {
                newSt.isReload = new Date();
            }
            return newSt;
        });
    };

    // const downloadCSVWrapper = async () => {
    //     GetArchivedJobsExcelFile();
    // };

    const baseUrl = 'ArchivedJobs/AfterSalesOldJobs';

    return (
        <div>
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    After Sales Old Jobs
                </Typography>
            </Breadcrumbs>

            <CommonGijgoGrid
                dataConverter={(res) => ({
                    records: res?.data?.rows,
                    total: res?.data?.total
                })}
                columns={column}
                getUrl={baseUrl}
                displayPagination={true}
                isReload={state.isReload}
                // downloadBtnName={downloadCSVWrapper}
            />
            {state.showModal ? (
                <DialogComp title={'Make Job Active'} onClose={() => closeModal(false)} fullWidth maxWidth="sm">
                    <SendToWorkflowModal jobDetails={state.jobDetails} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default AfterSalesOldJobs;
