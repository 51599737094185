import React, { useEffect, useState, useMemo, useContext } from 'react';
import moment from 'moment';
import { Grid, FormLabel, IconButton } from '@material-ui/core';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { SingelSelect, TextBox } from '../../../../Core/FormInput';
import { ExpBaseURL } from '../../../../Core/Service/http-calls';
import { AppStorage } from '../../../../Core/Service/storage-service';
import formatters from '../../../../Core/Grid/inputFormatter';
import GetWIPHeader from '../GetWIPDetails';
import ReactDOM from 'react-dom';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { GetExcelFileFromEndpoint, GetBranchDeportmentScreenDropdowns } from '../../../../Core/Service/reportService';
import AppContext from '../../../../App/AppContext';

const baseColumns = [
    { field: 'bn', title: `Branch`, width: 180 },
    {
        field: 'grossProfit',
        title: `Gross Profit `,
        width: 120,
        align: 'right',
        filterable: false,
        sortable: false,
        renderer: formatters.CurrencyThousandSeparator
    },
    { field: 'grossProfitPercent', title: `Gross Profit % `, width: 120, align: 'right', filterable: false, sortable: false },
    { field: 'saleValue', title: `Sale Value`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    { field: 'costValue', title: `Cost Value`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    {
        field: 'retailPrice',
        title: 'Retail Price',
        width: 120,
        align: 'right',
        renderer: formatters.CurrencyThousandSeparator,
        filterable: false,
        sortable: false
    },
    {
        field: 'surchargeValue',
        title: 'Surcharge Value',
        width: 120,
        align: 'right',
        renderer: formatters.CurrencyThousandSeparator,
        filterable: false,
        sortable: false
    }
];

var opColumns = [
    { title: 'Operator', field: 'opName', width: 90, sortable: true, align: 'left' },
    {
        field: 'grossProfit',
        title: `Gross Profit `,
        width: 120,
        align: 'right',
        filterable: false,
        sortable: false,
        renderer: formatters.CurrencyThousandSeparator
    },
    { field: 'grossProfitPercent', title: `Gross Profit % `, width: 120, align: 'right', filterable: false, sortable: false },
    { field: 'saleValue', title: `Sale Value`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    { field: 'costValue', title: `Cost Value`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    {
        field: 'retailPrice',
        title: 'Retail Price',
        width: 120,
        align: 'right',
        renderer: formatters.CurrencyThousandSeparator,
        filterable: false,
        sortable: false
    },
    {
        field: 'surchargeValue',
        title: 'Surcharge Value',
        width: 120,
        align: 'right',
        renderer: formatters.CurrencyThousandSeparator,
        filterable: false,
        sortable: false
    }
];

const wipColumns = [
    { field: 'wn', title: 'WIP', width: 75 },
    { field: 'inv', title: 'Invoice No', width: 90 },
    { field: 'ind', title: 'Date', width: 90, renderer: formatters.MonthShortFormatter },
    { field: 'an', title: `Account No`, width: 60 },
    { field: 'cn', title: `Customer`, width: 80 },
    { field: 'rp', title: `Retail Price`, width: 60, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'sv', title: `Sale`, width: 80, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'cv', title: `Cost`, width: 80, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'sur', title: `Surcharge`, width: 80, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'gp', title: `Gross Profit`, width: 100, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'gpp', title: `Profit %`, width: 80 }
];

const crumbs = (desc) => [
    { name: 'Home', path: '/' },
    { name: 'Parts', active: true },
    { name: desc, active: true }
];
var mainGrid, opGrid, wipsGrid;
var $ = window.$;

const PartSales = (props) => {
    const { showToast } = useContext(AppContext);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamStartDateType = params.get('startDateType') || '';
    let ParamBranchID = params.get('branchID') || '';
    let ParamDcID = params.get('dc') || params.get('code') || '';

    let dateType = ParamStartDateType == 'ytd' ? 'year' : 'month';
    let startDate = moment().startOf(`${dateType}`).format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');
    let ParamMeasuredesc = params.get('desc') || '';
    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        showWIPDetails: false,
        wipNumber: '',
        company: '',
        BranchList: [],
        DepartmentList: [],
        branchID: null,
        departmentCode: '',
        isAllIdSetFirstTime: false
    });

    useEffect(async () => {
        let res = await GetBranchDeportmentScreenDropdowns();
        if (res.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.BranchList = res.data?.branchList || [];
                newSt.DepartmentList = res.data?.departmentCodes || [];
                if (ParamBranchID) {
                    newSt.branchID = res.data?.branchList.find((r) => r.id == ParamBranchID);
                }
                if (ParamDcID) {
                    let tempObj = res.data?.departmentCodes.find((r) => r.id == ParamDcID || '');
                    newSt.departmentCode = tempObj || '';
                }
                return newSt;
            });
        }
    }, []);

    // useEffect(() => {
    //     if (params.get('code')) {
    //         let findDepartmentcode = state.DepartmentList.find((m) => m.id == params.get('code') || '');
    //         if (findDepartmentcode) {
    //             setState((st) => ({
    //                 ...st,
    //                 departmentCode: findDepartmentcode,
    //                 isAllIdSetFirstTime: true
    //             }));
    //         }
    //     }
    // }, [state.DepartmentList, params.get('code')]);

    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    let token = AppStorage.getToken();

    const mainGridOnSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        mainGrid.render(obj);
    };

    const opGridOnSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        opGrid.render(obj);
    };
    const onSuccessWipFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        wipsGrid.render(obj);
    };
    const getUrl = `${ExpBaseURL}/api/Parts/PartsSales?dc=${state.departmentCode?.id || ''}&StartDate=${state.startDate}&EndDate=${state.endDate}&branchID=${
        state.branchID?.id || ''
    }`;

    const setDataSource = (gridInstance, getUrl) => {
        const onSuccessFunc = function (response) {
            gridInstance.render({
                records: response.data.list,
                total: response.data.total
            });
        };
        gridInstance.data().dataSource = {
            url: `${getUrl}`,
            headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
            success: onSuccessFunc
        };
    };

    const pullPartsSalesList = async () => {
        window.$(`#tb_PartsSales`).grid('destroy', true, true);

        mainGrid = $('#tb_PartsSales').grid({
            primaryKey: 'branchID',
            dataSource: [],
            // dataSource: {
            //     url: getUrl,
            //     headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
            //     success: mainGridOnSuccessFunc
            // },
            detailTemplate: '<div><table/></div>',
            columns: baseColumns,
            pager: { limit: 15 }
        });
        mainGrid.on('dataBound', function (e, records, totalRecords) {
            var sumgrossProfit = 0,
                sumgrossProfitPercent = 0,
                sumsaleValue = 0,
                sumcostValue = 0,
                sumretailPrice = 0,
                sumsurchargeValue = 0,
                $tfoot;
            $.each(records, function () {
                sumgrossProfit += parseFloat(this.grossProfit);
                sumgrossProfitPercent += parseFloat(this.grossProfitPercent);
                sumsaleValue += parseFloat(this.saleValue);
                sumcostValue += parseFloat(this.costValue);
                sumretailPrice += parseFloat(this.retailPrice);
                sumsurchargeValue += parseFloat(this.surchargeValue);
            });
            $tfoot = mainGrid.children('tfoot');
            $tfoot.children('tr[data-role="TotalAmount"]').remove();
            $tfoot.children('tr[data-role="pager"]').remove();
            $tfoot.prepend(
                '<tr data-role="TotalAmount"><th></th><th>Total</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumgrossProfit) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    '' +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumsaleValue) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumcostValue) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumretailPrice) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumsurchargeValue) +
                    '</th></tr>'
            );
        });
        mainGrid.on('detailExpand', function (e, $detailWrapper, branchID) {
            opGrid = $detailWrapper.find('table').grid({
                params: { BranchID: branchID },
                primaryKey: 'opno',
                dataSource: [],
                // dataSource: {
                //     url: `${ExpBaseURL}/api/Parts/PartsSales?dc=${state.departmentCode?.id || ''}&StartDate=${state.startDate}&EndDate=${state.endDate}&Mode=B`,
                //     headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                //     success: opGridOnSuccessFunc
                // },
                columns: opColumns,
                pager: { limit: 10 },
                detailTemplate: '<div><table/></div>'
            });
            setDataSource(
                opGrid,
                `${ExpBaseURL}/api/Parts/PartsSales?dc=${state.departmentCode?.id || ''}&StartDate=${state.startDate}&EndDate=${state.endDate}&Mode=B`
            );
            opGrid.reload();

            opGrid.on('dataBound', function (e, records, totalRecords) {});
            opGrid.on('detailExpand', function (e, $detailWrapper, opno) {
                wipsGrid = $detailWrapper.find('table').grid({
                    params: { BranchID: branchID, opno: opno },
                    primaryKey: 'id',

                    dataSource: {
                        url: `${ExpBaseURL}/api/Parts/PartsSales?dc=${state.departmentCode?.id || ''}&StartDate=${state.startDate}&EndDate=${state.endDate}`,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host
                        },
                        success: onSuccessWipFunc
                    },
                    columns: WipRendererNColumns
                });
            });

            opGrid.on('detailCollapse', function (e, $detailWrapper, opno) {
                $detailWrapper.find('table').grid('destroy', true, true);
            });
        });

        mainGrid.on('detailCollapse', function (e, $detailWrapper, id) {
            $detailWrapper.find('table').grid('destroy', true, true);
        });
        setDataSource(mainGrid, getUrl);
        mainGrid.reload();
    };

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid onClick={() => setState((st) => ({ ...st, showWIPDetails: true, wipNumber: record.wn, company: record.co }))} style={{ cursor: 'pointer' }}>
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const WipRendererNColumns = useMemo(() => {
        const tempCols = [...wipColumns];
        let wipNumberCol = tempCols.find((element) => element.field === 'wn');
        if (wipNumberCol) {
            wipNumberCol.renderer = editButton({});
        }
        return tempCols;
    });

    useEffect(() => {
        if (state.BranchList.length > 0) {
            pullPartsSalesList();
        }
    }, [state.startDate, state.endDate, state.branchID, state.departmentCode, state.BranchList]);

    const downloadCSVWrapper = async () => {
        showToast('Collating Data... This may take a while.');

        const data = mainGrid.data().params;
        let downloadURL = `Parts/PartsSales?dc=${state.departmentCode?.id || ''}&StartDate=${state.startDate}&EndDate=${state.endDate}&fileType=excel`;

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                downloadURL += `&${key}=${value}`;
            }
        }
        await GetExcelFileFromEndpoint(downloadURL, ParamMeasuredesc);
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st, [nm]: val };
            return newSt;
        });
    };

    return (
        <div>
            <BreadCrumbs crumbs={crumbs(ParamMeasuredesc)} />
            {state.showWIPDetails && (
                <DialogComp title="WIP Details" onClose={() => setState((st) => ({ ...st, showWIPDetails: false }))} maxWidth="xl" fullWidth>
                    <GetWIPHeader wipNumber={state.wipNumber} company={state.company} />
                </DialogComp>
            )}
            <Grid item container spacing={1} xs={12} style={{ padding: '0px 10px' }}>
                <Grid item container spacing={1} xs={12} style={{ textAlign: 'left' }}>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormLabel component="legend">Start Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormLabel component="legend">End Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="endDate"
                            fullWidth
                            value={state.endDate}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormLabel component="legend" style={{ marginTop: -1 }}>
                            Select Branch
                        </FormLabel>
                        <SingelSelect onChange={singleSelectChange('branchID')} options={state.BranchList || []} value={state.branchID} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormLabel component="legend" style={{ marginTop: -1 }}>
                            Select Department
                        </FormLabel>
                        <SingelSelect onChange={singleSelectChange('departmentCode')} options={state.DepartmentList || []} value={state.departmentCode} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="report-screen-gijgo " item xs={12} sm={12} style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }}>
                <Grid item style={{ bottom: '3px', left: '16px', zIndex: 99, position: 'absolute' }}>
                    <Tooltip title="Download Excel" arrow>
                        <IconButton aria-label="delete" style={{ padding: '0px' }}>
                            <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} style={{ color: 'red' }} />
                        </IconButton>
                    </Tooltip>
                </Grid>

                <table id={`tb_PartsSales`}></table>
            </Grid>
        </div>
    );
};

export default PartSales;
