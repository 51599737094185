const getTxtToImg = (value, cb) => {
    let imageElem = document.createElement('image'); 
    var canvas = document.createElement("canvas");
    canvas.height = 30;
    var ctx = canvas.getContext('2d');
    ctx.font = "17px Arial"
    ctx.canvas.width = ctx.measureText(value).width;
    ctx.font = "17px Arial"
    ctx.fillText(value, 0, 20);
    imageElem.src = ctx.canvas.toDataURL();
    cb(imageElem.src);
};

export default getTxtToImg;
