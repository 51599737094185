import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions } from '../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import '../User Management/UserManagement.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { addWorkflowStepList, updateWorkflowStepList } from '../../../Core/Service/workflowList-service';
const AddWorkflowStepsListModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        listId: null,
        listName: '',
        errors: {},
        fields: {}
    });
    const WorkflowStepsListsDetails = props.WorkflowStepsListDetails;

    useEffect(async () => {
        if (WorkflowStepsListsDetails) {
            setState((st) => ({
                ...st,
                listName: WorkflowStepsListsDetails.listName,
                listId: WorkflowStepsListsDetails.listId
            }));
        }
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            const nst = { ...st, [name]: value, fields: fields };
            return nst;
        });
    };

    const handelSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            let res = '';
            if (state.listId) {
                res = await updateWorkflowStepList(state.listId, state.listName);
            } else {
                res = await addWorkflowStepList(state.listName);
            }
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
                props.onFormSubmit(false);
            }
            showToast(res.message);
        }
    };
    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.listName) {
            formIsValid = false;
            errors['listName'] = 'listName cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    {state.listId ? <DialogTitles>Update Workflow Steps List</DialogTitles> : <DialogTitles>Add Workflow Steps List</DialogTitles>}
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            List Name
                        </FormLabel>
                        <TextBox name="listName" onChange={fieldChange} value={state.listName} autoFocus={true} />
                        <span className="mandatoryfields">{state.errors['listName']}</span>
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddWorkflowStepsListModal;
