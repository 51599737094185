import React from 'react';
import singleAxel from './VehicleDiagrams/images1.png';
import doubleAxelOne from './VehicleDiagrams/axImage01.png';
import doubleAxelTwo from './VehicleDiagrams/azImage02.png';
import doubleAxelThree from './VehicleDiagrams/axImage03.png';
import { TextBox, SecondaryButton, CustomButton, AppButtonGroup } from '../../../../Core/FormInput';
import Grid from '@material-ui/core/Grid';
import { InputLabel } from '@material-ui/core';
import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';

const input_style = {
    axOS: {
        marginTop: '45px'
    },
    axOS2: {
        marginTop: '152px'
    },
    ax2OS: {
        marginTop: '-18px'
    },
    ax2OS2: {
        marginTop: '0px'
    },
    ax2NS: { marginTop: '135px' },
    ax2Break: { marginBottom: '5px' },
    axBreakHeading: { textAlign: 'center' }
};

const slide_style = {
    axOS: {
        marginTop: '39px'
        // display: 'flex'
    },
    axOS2: {
        marginTop: '160px'
        // display: 'flex'
    },
    ax2OS: {
        marginTop: '-18px'
    },
    ax2OS2: {
        marginTop: '8px'
    },
    ax2NS: { marginTop: '180px' },
    ax2Break: { marginBottom: '5px' },
    axBreakHeading: { textAlign: 'center' }
};

const PrettoSlider = withStyles({
    root: {
        color: '#52af77',
        height: 8,
        padding: '0px !important'
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -7,
        marginLeft: -7
        // '&:focus, &:hover, &$active': {
        //     boxShadow: 'inherit'
        // }
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)'
    },
    track: {
        height: 8,
        borderRadius: 4
    },
    rail: {
        height: 8,
        borderRadius: 4
    }
})(Slider);

function valuetext(value) {
    return <div style={{ backgroundColor: 'yellow' }}>{value}</div>;
}

const SliderCommonComponent = (props) => {
    return (
        <>
            <div style={slide_style[props.styleClassName]}>
                <div>{props.labelName}</div>
                <div style={{ flex: 1, fontSize: 18, fontWeight: 800, color: props.inputValue > props.limitColorValue ? 'green' : 'red' }}>
                    {' '}
                    {props.inputValue || 0}
                </div>
            </div>
            <PrettoSlider
                style={{ color: props.inputValue > props.limitColorValue ? 'green' : 'red' }}
                value={props.inputValue || 0}
                onChange={props.onChange(props.name)}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-small-steps"
                step={props.step}
                // marks
                min={props.min}
                max={props.max}
                // valueLabelDisplay="auto"
            />
        </>
    );
};

const InputCommonComponent = (props) => {
    return (
        <>
            <InputLabel shrink style={input_style[props.styleClassName]}>
                {props.labelName}*
            </InputLabel>
            <TextBox
                inputProps={{ tabIndex: `${props.tabindex}` }}
                placeholder={props.placeholder}
                name={props.name}
                onChange={props.onChange()}
                value={props.inputValue}
            />
        </>
    );
};

let toggleCompMinSize = 1280;

export const SingleAxle = (props) => {
    const [WindowWidths, WindowHeights] = useWindowSize();
    const { state, axNO } = props;
    const axle1 = state.tyreAxlesVM?.find((st) => st.axleNumber === axNO);
    return (
        <Grid item xs={12} sm={6} md={6} lg={3}>
            <Grid item xs={12} sm={12}>
                <h3>AXLE 1</h3>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid container xs={12} direction="row" justify="space-evenly">
                    <Grid item xs={3}>
                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={11}
                                inputValue={axle1?.osTreadDepth}
                                name="osTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'axOS'}
                                labelName={'Depth'}
                                placeholder="mm"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={axle1?.osTreadDepth}
                                name="osTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'axOS'}
                                labelName={'Depth'}
                                step={0.2}
                                min={state.minTreadDepth}
                                max={state.maxTreadDepth}
                                limitColorValue={state.treadDepthRed}
                            />
                        )}

                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={14}
                                inputValue={axle1?.nsTreadDepth}
                                name="nsTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'axOS2'}
                                labelName={'Depth'}
                                placeholder="mm"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={axle1?.nsTreadDepth}
                                name="nsTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'axOS2'}
                                labelName={'Depth'}
                                step={0.2}
                                min={state.minTreadDepth}
                                max={state.maxTreadDepth}
                                limitColorValue={state.treadDepthRed}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={12}
                                inputValue={axle1?.osBrakeLineRemaining}
                                name="osBrakeLineRemaining"
                                onChange={props.onChange}
                                styleClassName={''}
                                labelName={'Brakelining'}
                                placeholder="%"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={axle1?.osBrakeLineRemaining}
                                name="osBrakeLineRemaining"
                                onChange={props.onChange}
                                styleClassName={''}
                                labelName={'Brakelining'}
                                step={1}
                                min={0}
                                max={100}
                                limitColorValue={50}
                            />
                        )}
                        <img
                            src={singleAxel}
                            height="250px"
                            width="100%"
                            style={{
                                marginTop: 5
                            }}
                        />
                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={15}
                                inputValue={axle1?.nsBrakeLineRemaining}
                                name="nsBrakeLineRemaining"
                                onChange={props.onChange}
                                styleClassName={''}
                                labelName={'Brakelining'}
                                placeholder="%"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={axle1?.nsBrakeLineRemaining}
                                name="nsBrakeLineRemaining"
                                onChange={props.onChange}
                                styleClassName={''}
                                labelName={'Brakelining'}
                                step={1}
                                min={0}
                                max={100}
                                limitColorValue={50}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={13}
                                inputValue={axle1?.osTyrePressure}
                                name="osTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'axOS'}
                                labelName={'Pressure'}
                                placeholder="psi"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={axle1?.osTyrePressure}
                                name="osTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'axOS'}
                                labelName={'Pressure'}
                                step={0.2}
                                min={state.minTyrePressure}
                                max={state.maxTyrePressure}
                                limitColorValue={state.tyrePressureRed}
                            />
                        )}

                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={16}
                                inputValue={axle1?.nsTyrePressure}
                                name="nsTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'axOS2'}
                                labelName={'Pressure'}
                                placeholder="psi"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={axle1?.nsTyrePressure}
                                name="nsTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'axOS2'}
                                labelName={'Pressure'}
                                step={0.2}
                                min={state.minTyrePressure}
                                max={state.maxTyrePressure}
                                limitColorValue={state.tyrePressureRed}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const CustomAxle = (props) => {
    const { state, axNO } = props;
    const [WindowWidths, WindowHeights] = useWindowSize();

    let ax = state.tyreAxlesVM?.find((m) => m.axleNumber === axNO);
    return (
        <Grid item xs={12} sm={6} md={6} lg={3}>
            <h3>{props.axleTitle}</h3>
            <Grid item xs={12} sm={12}>
                <Grid container xs={12} direction="row" justify="space-evenly">
                    <Grid item xs={3}>
                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={7 + props.tabIndexMultiplyer}
                                inputValue={ax?.osTreadDepth}
                                name="osTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'ax2OS'}
                                labelName={'Depth'}
                                placeholder="mm"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={ax?.osTreadDepth}
                                name="osTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'ax2OS'}
                                labelName={'Depth'}
                                placeholder="mm"
                                step={0.2}
                                min={state.minTreadDepth}
                                max={state.maxTreadDepth}
                                limitColorValue={state.treadDepthRed}
                            />
                        )}

                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={9 + props.tabIndexMultiplyer}
                                inputValue={ax?.osFrontTreadDepth}
                                name="osFrontTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'ax2OS2'}
                                labelName={''}
                                placeholder="mm"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={ax?.osFrontTreadDepth}
                                name="osFrontTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'ax2OS2'}
                                labelName={''}
                                placeholder="mm"
                                step={0.2}
                                min={state.minTreadDepth}
                                max={state.maxTreadDepth}
                                limitColorValue={state.treadDepthRed}
                            />
                        )}

                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={12 + props.tabIndexMultiplyer}
                                inputValue={ax?.nsFrontTreadDepth}
                                name="nsFrontTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'ax2NS'}
                                labelName={'Depth'}
                                placeholder="mm"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={ax?.nsFrontTreadDepth}
                                name="nsFrontTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'ax2NS'}
                                labelName={'Depth'}
                                placeholder="mm"
                                step={0.2}
                                min={state.minTreadDepth}
                                max={state.maxTreadDepth}
                                limitColorValue={state.treadDepthRed}
                            />
                        )}

                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={15 + props.tabIndexMultiplyer}
                                inputValue={ax?.nsTreadDepth}
                                name="nsTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'ax2OS2'}
                                labelName={''}
                                placeholder="mm"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={ax?.nsTreadDepth}
                                name="nsTreadDepth"
                                onChange={props.onChange}
                                styleClassName={'ax2OS2'}
                                labelName={''}
                                placeholder="mm"
                                step={0.2}
                                min={state.minTreadDepth}
                                max={state.maxTreadDepth}
                                limitColorValue={state.treadDepthRed}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <img src={doubleAxelOne} height="100px" width="100%" />
                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={10 + props.tabIndexMultiplyer}
                                inputValue={ax?.osBrakeLineRemaining}
                                name="osBrakeLineRemaining"
                                onChange={props.onChange}
                                styleClassName={'axBreakHeading'}
                                labelName={'Brakelining'}
                                placeholder="%"
                            />
                        ) : (
                            <>
                                <SliderCommonComponent
                                    inputValue={ax?.osBrakeLineRemaining}
                                    name="osBrakeLineRemaining"
                                    onChange={props.onChange}
                                    styleClassName={'axBreakHeading'}
                                    labelName={'Brakelining'}
                                    placeholder="%"
                                    step={1}
                                    min={0}
                                    max={100}
                                    limitColorValue={50}
                                />
                                <div style={{ marginBottom: 10 }} />
                            </>
                        )}

                        <img src={doubleAxelTwo} height="45px" width="100%" />
                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={13 + props.tabIndexMultiplyer}
                                inputValue={ax?.nsBrakeLineRemaining}
                                name="nsBrakeLineRemaining"
                                onChange={props.onChange}
                                styleClassName={'axBreakHeading'}
                                labelName={'Brakelining'}
                                placeholder="%"
                            />
                        ) : (
                            <>
                                {' '}
                                <SliderCommonComponent
                                    inputValue={ax?.nsBrakeLineRemaining}
                                    name="nsBrakeLineRemaining"
                                    onChange={props.onChange}
                                    styleClassName={'axBreakHeading'}
                                    labelName={'Brakelining'}
                                    placeholder="%"
                                    step={1}
                                    min={0}
                                    max={100}
                                    limitColorValue={50}
                                />
                                <div style={{ marginBottom: 10 }} />
                            </>
                        )}

                        <img src={doubleAxelThree} height="100px" width="100%" />
                    </Grid>
                    <Grid item xs={3}>
                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={8 + props.tabIndexMultiplyer}
                                inputValue={ax?.osTyrePressure}
                                name="osTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'ax2OS'}
                                labelName={'Pressure'}
                                placeholder="mm"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={ax?.osTyrePressure}
                                name="osTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'ax2OS'}
                                labelName={'Pressure'}
                                placeholder="mm"
                                step={0.2}
                                min={state.minTyrePressure}
                                max={state.maxTyrePressure}
                                limitColorValue={state.tyrePressureRed}
                            />
                        )}

                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={11 + props.tabIndexMultiplyer}
                                inputValue={ax?.osFrontTyrePressure}
                                name="osFrontTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'ax2OS2'}
                                labelName={''}
                                placeholder="psi"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={ax?.osFrontTyrePressure}
                                name="osFrontTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'ax2OS2'}
                                labelName={''}
                                placeholder="psi"
                                step={0.2}
                                min={state.minTyrePressure}
                                max={state.maxTyrePressure}
                                limitColorValue={state.tyrePressureRed}
                            />
                        )}

                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={14 + props.tabIndexMultiplyer}
                                inputValue={ax?.nsFrontTyrePressure}
                                name="nsFrontTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'ax2NS'}
                                labelName={'Pressure'}
                                placeholder="psi"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={ax?.nsFrontTyrePressure}
                                name="nsFrontTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'ax2NS'}
                                labelName={'Pressure'}
                                placeholder="psi"
                                step={0.2}
                                min={state.minTyrePressure}
                                max={state.maxTyrePressure}
                                limitColorValue={state.tyrePressureRed}
                            />
                        )}

                        {WindowWidths > toggleCompMinSize ? (
                            <InputCommonComponent
                                tabindex={16 + props.tabIndexMultiplyer}
                                inputValue={ax?.nsTyrePressure}
                                name="nsTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'ax2OS2'}
                                labelName={''}
                                placeholder="psi"
                            />
                        ) : (
                            <SliderCommonComponent
                                inputValue={ax?.nsTyrePressure}
                                name="nsTyrePressure"
                                onChange={props.onChange}
                                styleClassName={'ax2OS2'}
                                labelName={''}
                                placeholder="psi"
                                step={0.2}
                                min={state.minTyrePressure}
                                max={state.maxTyrePressure}
                                limitColorValue={state.tyrePressureRed}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

// export const EditAxle = (props) => {
//     const { state } = props;
//     return (
//         <Grid item xs={12} sm={6} md={6} lg={3}>
//             <h3>AXLE{state.axleNumber}</h3>
//             <Grid item xs={12} sm={12}>
//                 <Grid container xs={12} direction="row" justify="space-evenly">
//                     <Grid item xs={3}>
//                         <InputLabel shrink style={style.ax2OS}>
//                             Depth*
//                         </InputLabel>
//                         <TextBox placeholder="mm" name="osTreadDepth" disabled={true} value={state.osTreadDepth || 'N/A'} />
//                         <InputLabel shrink style={style.ax2OS2}>
//                             Depth*
//                         </InputLabel>
//                         <TextBox placeholder="mm" name="osFrontTreadDepth" disabled={true} value={state.osFrontTreadDepth || 'N/A'} />
//                         <InputLabel shrink style={style.ax2NS}>
//                             Depth*
//                         </InputLabel>
//                         <TextBox placeholder="mm" name="nsFrontTreadDepth" disabled={true} value={state.nsFrontTreadDepth || 'N/A'} />
//                         <InputLabel shrink style={style.ax2OS2}>
//                             Depth*
//                         </InputLabel>
//                         <TextBox placeholder="mm" name="nsTreadDepth" disabled={true} value={state.nsTreadDepth || 'N/A'} />
//                     </Grid>
//                     <Grid item xs={3}>
//                         <img src={doubleAxelOne} height="100px" width="100%" />
//                         <InputLabel shrink style={style.axBreakHeading}>
//                             Brakelining*
//                         </InputLabel>
//                         <TextBox placeholder="%" name="osBrakeLineRemaining" disabled={true} value={state.osBrakeLineRemaining || 'N/A'} />
//                         <img src={doubleAxelTwo} height="45px" width="100%" />
//                         <InputLabel shrink style={style.axBreakHeading}>
//                             Brakelining*
//                         </InputLabel>
//                         <TextBox placeholder="%" name="nsBrakeLineRemaining" style={style.ax2Break} disabled={true} value={state.nsBrakeLineRemaining} />
//                         <img src={doubleAxelThree} height="100px" width="100%" />
//                     </Grid>
//                     <Grid item xs={3}>
//                         <InputLabel shrink style={style.ax2OS}>
//                             Pressure*
//                         </InputLabel>
//                         <TextBox placeholder="psi" name="osTyrePressure" disabled={true} value={state.osTyrePressure || 'N/A'} />
//                         <InputLabel shrink style={style.ax2OS2}>
//                             Pressure*
//                         </InputLabel>
//                         <TextBox placeholder="psi" name="osFrontTyrePressure" disabled={true} value={state.osFrontTyrePressure || 'N/A'} />
//                         <InputLabel shrink style={style.ax2NS}>
//                             Pressure*
//                         </InputLabel>
//                         <TextBox placeholder="psi" name="nsFrontTyrePressure" disabled={true} value={state.nsFrontTyrePressure || 'N/A'} />
//                         <InputLabel shrink style={style.ax2OS2}>
//                             Pressure*
//                         </InputLabel>
//                         <TextBox placeholder="psi" name="nsTyrePressure" disabled={true} value={state.nsTyrePressure || 'N/A'} />
//                     </Grid>
//                 </Grid>
//             </Grid>
//             {/* <FormHelperText  style={{ textAlign: 'center' }}></FormHelperText> */}
//         </Grid>
//     );
// };
