import { Container, FormLabel, Grid, Typography, InputLabel, ButtonGroup, Button } from '@material-ui/core';
import React from 'react';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';

import { getCommunicatorSmsStatus, postCommunicatorSms } from '../../../../Core/Service/communicator-service';
import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
// import AppContext from '../../../../App/AppContext';
// import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { SecondaryButton, SquareButton } from './../../../../Core/FormInput/AppButton';
import SelectBox from './../../../../Core/FormInput/SelectBox';
import { TextBox } from '../../../../Core/FormInput';
import ReportGijgoGrid from '../../../internal/ReportsScreens/GijgoForReportsScreen';
// import { Typography } from '@material-ui/core/Typography';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
import AddCustomerGroups from './addCustomergroup';
import DialogComp from '../../../../Core/Modal/dialogModal';

const baseColumns = [
    { title: 'Action', field: 'action', width: '5%', filterable: false },
    { title: 'Group Name', field: 'customerGroupName', width: '15%', sortable: true },

    {
        title: 'Group Email',
        field: 'customerGroupEmail',
        width: '75%',
        sortable: true
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Communicator', active: true },
    { name: 'Notifications Groups', active: true }
];

const baseUrl = `CustomerGroups`;

const AdminVehicleNotification = () => {
    const [state, setState] = useState({
        showAddCustGroup: false
    });

    const handleAddCust = (val) => {
        setState((st) => ({
            ...st,
            showAddCustGroup: true,
            customerGroupID: val.customerGroupID,
            customerGroupName: val.customerGroupName,
            customerGroupEmail: val.customerGroupEmail
        }));
    };

    const handleClose = (res) => () => {
        setState((st) => ({
            ...st,
            showAddCustGroup: false,
            isReload: res ? new Date() : ''
        }));
    };

    const handleEdit = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid style={{ cursor: 'pointer' }}>
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => handleAddCust(record)}
                >
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        let tempColumn = [...baseColumns];

        let actionCol = tempColumn.find((m) => m.field === 'action');
        if (actionCol) {
            actionCol.renderer = handleEdit;
        }
        return tempColumn;
    }, []);
    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <div style={{ margin: '0px 10px' }}>
                <Grid container>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '0%',
                            // width: '100px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 10px 0px 10px'
                        }}
                        onClick={handleAddCust}
                    >
                        <span style={{ color: 'white', fontSize: 12 }}>+ Add Customer Group</span>
                    </SquareButton>

                    <Grid item xs={12}>
                        <ReportGijgoGrid
                            dataConverter={(res) => ({
                                records: res.data?.list,
                                total: res.data?.total
                            })}
                            columns={columns}
                            getUrl={baseUrl}
                            displayPagination={true}
                            isReload={state.isReload}
                        />
                    </Grid>
                </Grid>
            </div>

            {state.showAddCustGroup ? (
                <DialogComp
                    onClose={handleClose(false)}
                    maxWidth="md"
                    title={state.customerGroupID ? 'Update Customer Group' : 'Add Customer Group'}
                    overflow="auto"
                >
                    <AddCustomerGroups
                        customerGroupID={state.customerGroupID}
                        customerGroupName={state.customerGroupName}
                        customerGroupEmail={state.customerGroupEmail}
                        onClose={handleClose(true)}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default AdminVehicleNotification;
