import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PrimaryButton, SecondaryButton } from '../../../../Core/FormInput';

const DeleteConfirm = props => {
    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const confirmDelete = ()=> {
        props.onConfirm && props.onConfirm();
    }

    return <Dialog open={true} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
            <span>Are you sure do you want to delete this Document?</span>
        </DialogContent>
        <DialogActions>
            <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
            <PrimaryButton onClick={confirmDelete}>Delete</PrimaryButton>
        </DialogActions>
    </Dialog>
}

export default DeleteConfirm;