import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PrimaryButton, SecondaryButton, TextBox } from '../../../../../Core/FormInput';
import { getStockJobNotes, addStockJobNotes, updateStockJobNotes } from '../../../../../Core/Service/jobNotes-service';
import moment from 'moment';
import { Delete, Edit } from '@material-ui/icons';
import { IconButton, Typography } from '@material-ui/core';
import { AppStorage } from '../../../../../Core/Service/storage-service';

const NotesModal = (props) => {
    const [state, setState] = useState({
        loading: false,
        notes: [],
        newNote: '',

        selectedID: 0,
        editMode: false,
        showDeleteConfirm: false
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const [delBtnLoader, setDelBtnLoader] = useState(false);

    const JobSingleNote = (props) => {
        return (
            <div className="single-note">
                <div style={{ display: 'flex' }}>
                    {AppStorage.getCanAmendJobNotes() && (
                        <>
                            <IconButton size="small" onClick={() => handleEditNote(true, props.note)} style={{ padding: '0px' }}>
                                <Edit style={{ color: 'red' }} fontSize="small" />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleDeleteNote(true, props.note)} style={{ marginRight: '5px' }}>
                                <Delete style={{ color: 'red' }} fontSize="small" />
                            </IconButton>
                        </>
                    )}
                    <div className="msg">{props.note.msg}</div>
                </div>
                <div className="footer">
                    {props.note.by} @ {moment(props.note.at).format('LLL')}
                </div>
            </div>
        );
    };

    const notesContainer = useRef(null);
    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const setNewNote = (e) => {
        const newNote = e.target.value;
        setState((st) => ({ ...st, newNote }));
    };

    const deleteNoteFromServer = () => {
        setDelBtnLoader(true);
        let tempNotes = JSON.parse(
            JSON.stringify(
                state.notes.filter((x) => {
                    return x.id != state.selectedID;
                })
            )
        );

        tempNotes.map((note) => {
            delete note.id;
        });

        const data = JSON.stringify(tempNotes);

        updateStockJobNotes(data, props.stockID).then((res) => {
            if (res.success) {
                setDelBtnLoader(false);

                setState((st) => ({ ...st, newNote: '', selectedID: 0, editMode: false, showDeleteConfirm: false }));
                getJobNotesAndSaveToState();
            }
        });
    };

    const addNotesToServer = () => {
        if (state.editMode) {
            setBtnLoader(true);
            let tempNotes = JSON.parse(JSON.stringify(state.notes));

            tempNotes.map((note) => {
                if (note.id == state.selectedID) {
                    note.msg = state.newNote;
                }
                delete note.id;
            });

            const data = JSON.stringify(tempNotes);

            updateStockJobNotes(data, props.stockID).then((res) => {
                if (res.success) {
                    setBtnLoader(false);
                    setState((st) => ({ ...st, newNote: '', selectedID: 0, editMode: false }));
                    getJobNotesAndSaveToState();
                }
            });
        } else {
            setBtnLoader(true);
            addStockJobNotes(props.stockID, state.newNote).then((res) => {
                if (res.success) {
                    setBtnLoader(false);
                    props.onNotesAdded && props.onNotesAdded(state.newNote);
                    setState((st) => ({ ...st, newNote: '' }));
                    getJobNotesAndSaveToState();
                }
            });
        }
    };

    const getJobNotesAndSaveToState = () => {
        setState((st) => ({ ...st, loading: true }));
        getStockJobNotes(props.stockID)
            .then((res) => {
                if (res.success) {
                    const notes = res.data.map((item, index) => ({ ...item, id: index + 1 }));
                    setState((st) => ({ ...st, notes: notes }));
                }
                notesContainer.current && (notesContainer.current.scrollTop = 0);
            })
            .finally(() => {
                setState((st) => ({ ...st, loading: false }));
            });
    };

    const handleEditNote = (editMode, note) => {
        setState((st) => ({ ...st, editMode: editMode, newNote: note ? note.msg : '', selectedID: editMode ? note.id : 0 }));
    };

    const handleDeleteNote = (val, note) => {
        setState((st) => ({ ...st, showDeleteConfirm: val, selectedID: note ? note.id : 0 }));
    };

    useEffect(() => {
        getJobNotesAndSaveToState();
    }, [props.stockID]);

    return (
        <>
            <Dialog open={true} onClose={handleClose} maxWidth={props.maxWidth ? props.maxWidth : 'md'} className="dialog-custom job-notes-modal" fullWidth>
                <DialogTitle>
                    {props.title || 'Job Notes'}
                    <div className="title-header-actions">
                        <PrimaryButton onClick={addNotesToServer} disabled={state.newNote.length === 0} isBtnLoader={btnLoader}>
                            {state.editMode ? 'Save Note' : 'New Note'}
                        </PrimaryButton>

                        {state.editMode && (
                            <PrimaryButton style={{ marginLeft: '5px' }} onClick={() => handleEditNote(false)} disabled={state.newNote.length === 0}>
                                Cancel Edit
                            </PrimaryButton>
                        )}

                        <span className="close-icon" onClick={handleClose}>
                            &times;
                        </span>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {props.modalSubHeaderTitle ? <div style={{ marginBottom: '5px' }}>{props.modalSubHeaderTitle()}</div> : null}

                    <TextBox label={state.editMode ? 'Edit Note' : 'New Note'} required={false} onChange={setNewNote} value={state.newNote} autoFocus={true} />
                    <div className="notes-container custom-scroll" ref={notesContainer}>
                        {state.loading && <label>Loading...</label>}
                        {state.notes.map((n, i) => (
                            <JobSingleNote key={i} note={n} />
                        ))}
                    </div>
                </DialogContent>
            </Dialog>

            {state.showDeleteConfirm && (
                <Dialog open={true} onClose={() => handleDeleteNote(false)} maxWidth="xs" className="dialog-custom job-notes-modal">
                    <DialogTitle>
                        Confirm Delete Note
                        <div className="title-header-actions">
                            <span className="close-icon" onClick={() => handleDeleteNote(false)}>
                                &times;
                            </span>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                        <SecondaryButton style={{ width: '100%', margin: '5px' }} isBtnLoader={delBtnLoader} onClick={() => deleteNoteFromServer()}>
                            Delete
                        </SecondaryButton>
                        <SecondaryButton style={{ width: '100%', margin: '5px' }} onClick={() => handleDeleteNote(false)}>
                            Cancel
                        </SecondaryButton>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default NotesModal;
