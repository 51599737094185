import React, { useReducer } from 'react';
import { Grid, Typography, InputLabel, RadioGroup, FormControlLabel, Radio, Checkbox } from '@material-ui/core';
import { reducer, initState } from '../../../Reducer/action';
import { SecondaryButton, TextBox, YesNoButton } from '../../../../Core/FormInput/index';
import { useState, useCallback } from 'react';

import { useContext } from 'react';
import AppContext from '../../../../App/AppContext';
import { postMakeVisibleToCustome } from '../../../../Core/Service/communicator-service';

const EmailModal = (props) => {
    const { showToast, showModal } = useContext(AppContext);
    const { onClose } = props;
    const [state, setState] = useState({
        email: '',
        msg: props.msg,
        headerRecordID: props.headerRecordID,
        logID: props.id,
        message: ''
    });

    const validation = (status) => {
        let isValid = true;
        const emailRegex = /\S+@\S+\.\S+/;
        let message = '';
        if (status) {
            if (!state.email) {
                isValid = false;
                message = 'Email is required';
            }
            if (!emailRegex.test(state.email)) {
                isValid = false;
                message = 'Please enter a valid email!';
            }
        }

        setState((st) => ({ ...st, message: message }));

        return isValid;
    };
    const handleSubmit = async (status) => {
        if (validation(status)) {
            let data = {
                logID: state.logID,
                logHeaderRecordID: state.headerRecordID,
                email: state.email,
                msg: state.msg,
                logVisibleToCust: true,
                hasEmail: status,
                wIPNo: props.wip
            };
            let res = await postMakeVisibleToCustome(data);
            if (res.success) {
                showToast(res.message);
                onClose();
            }
        }
    };

    const fieldChange = (e) => {
        const { name, value, type, checked } = e.target;
        setState((st) => {
            const nst = { ...st };
            if (type === 'checkbox') {
                nst[name] = checked;
            } else {
                nst[name] = value;
            }
            return nst;
        });
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ fontSize: 15, textAlign: 'left' }}>
                    WIP No:&nbsp; <b> {props.wip}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.reg}</b>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink error>
                        Email
                    </InputLabel>
                    <TextBox placeholder="Email" variant="outlined" name="email" fullWidth onChange={fieldChange} value={state.email} />
                    <InputLabel shrink error>
                        {state.message}
                    </InputLabel>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink error>
                        {' '}
                        Message
                    </InputLabel>
                    <TextBox
                        placeholder="Message"
                        id="outlined-multiline-static"
                        rows={20}
                        // defaultValue="Default Value"
                        variant="outlined"
                        multiline
                        // maxRows={}
                        name="msg"
                        fullWidth
                        onChange={fieldChange}
                        value={state.msg}
                    />
                </Grid>
                <Grid item xs={4}>
                    <SecondaryButton
                        className="Submitbtn"
                        fullWidth
                        onClick={() => {
                            handleSubmit(false);
                        }}
                    >
                        Make Visible to Customer
                    </SecondaryButton>
                </Grid>

                <Grid item xs={4}>
                    <SecondaryButton
                        className="Submitbtn"
                        fullWidth
                        onClick={() => {
                            handleSubmit(true);
                        }}
                    >
                        Make Visible And Email
                    </SecondaryButton>
                </Grid>
                <Grid item xs={4}>
                    <SecondaryButton className="Submitbtn" fullWidth onClick={() => onClose(false)}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default EmailModal;
