import { FormLabel, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, YesNoButton } from '../../../Core/FormInput';
import { Radio } from '@material-ui/core';
import { RadioGroup } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { SecondaryButton } from '../../../Core/FormInput';
import { addUpdateLiftCheckList, getLiftCheckList } from '../../../Core/Service/LiftCheck-service';

const LiftCheckList = (props) => {
    const { hideModal, hideTopNavAndMenu, showToast, portalSettings } = useContext(AppContext);

    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const headerId = props?.match?.params?.jobId;

    const [state, setState] = useState({
        checkedLiftingArms: "",
        checkedPads: "",
        checkedPillarLiftLocks: "",
        checkedWeight: "",
        chocksUsed: "",
        comments: "",
        frameStructurallySound: "",
        locatedStopControl: "",
        name: "",

        errors: {}
    });

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    useEffect(async () => {
        let res = await getLiftCheckList(headerId);
        if (res.data[0]) {
            const data = res.data[0]
            setState((st) => ({
                ...st,
                id: data.liftCheckId,
                checkedLiftingArms: data.liftCheckCheckedLiftingArms ? "Yes" : "No",
                checkedPads: data.liftCheckCheckedPads ? "Yes" : "No",
                checkedPillarLiftLocks: data.liftCheckCheckedPillarLiftLocks ? "Yes" : "No",
                checkedWeight: data.liftCheckCheckedWeight ? "Yes" : "No",
                chocksUsed: data.liftCheckChocksUsed ? "Yes" : "No",
                comments: data.liftCheckComments,
                frameStructurallySound: data.liftCheckFrameStructurallySound ? "Yes" : "No",
                locatedStopControl: data.liftCheckLocatedStopControl ? "Yes" : "No",
                name: data.liftCheckName,
            }));
        }
    }, [headerId]);

    const handleValidation = () => {
        let formIsValid = true;
        let errors = {};

        const {
            checkedLiftingArms,
            checkedPads,
            checkedPillarLiftLocks,
            checkedWeight,
            chocksUsed,
            frameStructurallySound,
            locatedStopControl,
            name
        } = state;

        if (checkedLiftingArms == "") {
            formIsValid = false;
            errors["checkedLiftingArms"] = "This field is mandatory";
        }
        if (checkedPads == "") {
            formIsValid = false;
            errors["checkedPads"] = "This field is mandatory";
        }
        if (checkedPillarLiftLocks == "") {
            formIsValid = false;
            errors["checkedPillarLiftLocks"] = "This field is mandatory";
        }
        if (checkedWeight == "") {
            formIsValid = false;
            errors["checkedWeight"] = "This field is mandatory";
        }
        if (chocksUsed == "") {
            formIsValid = false;
            errors["chocksUsed"] = "This field is mandatory";
        }
        if (frameStructurallySound == "") {
            formIsValid = false;
            errors["frameStructurallySound"] = "This field is mandatory";
        }
        if (locatedStopControl == "") {
            formIsValid = false;
            errors["locatedStopControl"] = "This field is mandatory";
        }
        if (name == "") {
            formIsValid = false;
            errors["name"] = "This field is mandatory";
        }

        setState((st) => ({
            ...st,
            errors: errors,
        }));

        return formIsValid;
    };

    const handleSubmit = async () => {
        if (handleValidation()) {
            const data = {
                LiftCheckId: state.id ? state.id : null,
                LiftCheckHeaderRecordId: headerId,
                LiftCheckCheckedLiftingArms: state.checkedLiftingArms == "Yes",
                LiftCheckCheckedPads: state.checkedPads == "Yes",
                LiftCheckCheckedPillarLiftLocks: state.checkedPillarLiftLocks == "Yes",
                LiftCheckCheckedWeight: state.checkedWeight == "Yes",
                LiftCheckChocksUsed: state.chocksUsed == "Yes",
                LiftCheckFrameStructurallySound: state.frameStructurallySound == "Yes",
                LiftCheckLocatedStopControl: state.locatedStopControl == "Yes",
                LiftCheckComments: state.comments,
                LiftCheckName: state.name,
            }
            const res = await addUpdateLiftCheckList(data);
            if (res.success) {
                showToast(res.data.message);
                if (fromStep) {
                    setTimeout(() => {
                        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    }, 1000);
                }
            } else {
                showToast(res.error);
            }
        }
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;

        setState((st) => ({
            ...st,
            [name]: value
        }))
    }

    return (
        <div style={{ paddingLeft: '20%', paddingRight: '20%', paddingTop: '30px', paddingBottom: '30px', textAlign: 'left' }}>
            <Grid container spacing={4} style={{ outline: '1px solid #D7E2DC' }}>
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ marginTop: '10px', color: '#f50057', textAlign: 'center', fontWeight: 'bold' }}>
                        Daily Lift Check List
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{}}>
                    <Alert severity="info">
                        <Typography style={{ fontWeight: 'bold' }}>
                            It is important that before a lift is used in the workshop the technician inspects the lift
                            to ensure it is in good working order. This checklist helps to ensure the correct areas are checked.
                            If you answer no to any of the following questions, please report the defect to your supervisor or
                            Workshop Controller immediately and ensure there is a sign which confirms the lift is 'out of use' until fixed.
                        </Typography>
                    </Alert>
                </Grid>

                <Grid item xs={12} style={{}}>
                    <span style={{ color: 'red' }}>*</span> Required
                </Grid>

                <Grid xs={12}>
                    <hr style={{ marginLeft: '10px', marginRight: '10px', border: '1px dashed #D7E2DC' }} />
                </Grid>

                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        1. Name
                    </FormLabel>
                    <TextBox
                        style={{ marginTop: 15 }}
                        name="name"
                        value={state.name}
                        onChange={handleFieldChange}
                    />
                    <span className="mandatoryfields">
                        {state.errors["name"]}
                    </span>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        2. Is the frame structurally sound?
                    </FormLabel>
                    <br />
                    <RadioGroup
                        style={{ display: 'block' }}
                        aria-label="frameStructurallySound"
                        name="frameStructurallySound"
                        value={state.frameStructurallySound}
                        onChange={handleFieldChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <br />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <span className="mandatoryfields">
                        {state.errors["frameStructurallySound"]}
                    </span>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        3. Have you checked to make sure the lifting capacity of the lift is rated higher than the weight of the vehicle you are intending to lift?
                    </FormLabel>
                    <br />
                    <RadioGroup
                        style={{ display: 'block' }}
                        aria-label="checkedWeight"
                        name="checkedWeight"
                        value={state.checkedWeight}
                        onChange={handleFieldChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <br />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <span className="mandatoryfields">
                        {state.errors["checkedWeight"]}
                    </span>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        4. Have you located the stop control?
                    </FormLabel>
                    <br />
                    <RadioGroup
                        style={{ display: 'block' }}
                        aria-label="locatedStopControl"
                        name="locatedStopControl"
                        value={state.locatedStopControl}
                        onChange={handleFieldChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <br />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <span className="mandatoryfields">
                        {state.errors["locatedStopControl"]}
                    </span>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        5. Have you checked the lifting arms and are they functioning correctly?
                    </FormLabel>
                    <br />
                    <RadioGroup
                        style={{ display: 'block' }}
                        aria-label="checkedLiftingArms"
                        name="checkedLiftingArms"
                        value={state.checkedLiftingArms}
                        onChange={handleFieldChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <br />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <span className="mandatoryfields">
                        {state.errors["checkedLiftingArms"]}
                    </span>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        6. Please confirm that you have checked the condition of the pads
                    </FormLabel>
                    <br />
                    <RadioGroup
                        style={{ display: 'block' }}
                        aria-label="checkedPads"
                        name="checkedPads"
                        value={state.checkedPads}
                        onChange={handleFieldChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <br />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <span className="mandatoryfields">
                        {state.errors["checkedPads"]}
                    </span>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        7. Confirm that you have checked pillar lift locks have been activated before moving under vehicle
                    </FormLabel>
                    <br />
                    <RadioGroup
                        style={{ display: 'block' }}
                        aria-label="checkedPillarLiftLocks"
                        name="checkedPillarLiftLocks"
                        value={state.checkedPillarLiftLocks}
                        onChange={handleFieldChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <br />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <span className="mandatoryfields">
                        {state.errors["checkedPillarLiftLocks"]}
                    </span>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        8. Confirm that you have used chocks.  If not available, inform the workshop controller to locate some before the lift is used.
                    </FormLabel>
                    <br />
                    <RadioGroup
                        style={{ display: 'block' }}
                        aria-label="chocksUsed"
                        name="chocksUsed"
                        value={state.chocksUsed}
                        onChange={handleFieldChange}
                    >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes, and in use" />
                        <br />
                        <FormControlLabel value="No" control={<Radio />} label="None available - discussed with workshop controller" />
                    </RadioGroup>
                    <span className="mandatoryfields">
                        {state.errors["chocksUsed"]}
                    </span>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel component="legend">
                        9. Use this space to make any comments/issues following your lift check.
                    </FormLabel>
                    <TextBox
                        style={{ marginTop: 15 }}
                        name="comments"
                        value={state.comments}
                        onChange={handleFieldChange}
                        multiline
                        rows={3}
                    />
                </Grid>

                <SecondaryButton className="Submitbtn" onClick={handleSubmit} style={{ margin: '10px' }}>
                    Save
                </SecondaryButton>
            </Grid>
        </div>
    );
};

export default LiftCheckList;
