import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import React, { useEffect, useContext, useState } from 'react';
import AppContext from '../../App/AppContext';
import { Link } from 'react-router-dom';
import { getCustomerDashboard } from '../../Core/Service/customer-services';
import './dashboard.scss';
import { RenderTiles } from './CustCommonDash';
import { AppStorage } from '../../Core/Service/storage-service';

export default function Album() {
    const portalSettings = AppStorage.getPortalSetting();
    const [state, setState] = useState({
        rows_new: []
    });
    const { showToast } = useContext(AppContext);
    useEffect(async () => {
        let res = await getCustomerDashboard();
        if (res.success) {
            setState((st) => ({ ...st, rows_new: res.data }));
        }
        if (!res.success) {
            showToast(res.message);
        }
    }, []);
    const cards = [
        {
            id: 1,
            name: 'Current Month',
            color: '#3498db',
            count: state.rows_new.currentMonth,
            url: '/Customer/Current Month/1'
        },
        {
            id: 2,
            name: 'Current Year',
            color: '#2ecc71',
            count: state.rows_new.currentYear,
            url: '/Customer/Current Year/2'
        },
        {
            id: 3,
            name: 'Last Year',
            color: '#dc3545',
            count: state.rows_new.preYear,
            url: '/Customer/Last Year/3'
        },
        {
            id: 4,
            name: 'Total',
            color: '#868e96',
            count: state.rows_new.total,
            url: '/Customer/Total/4'
        }
    ];
    return (
        // <div className="customer-dashboard">
        //     <Container className="cardGrid" maxWidth="lg">
        //         <Grid container spacing={4}>
        //             {cards.map((option) => (
        //                 <Grid item key={option.id} xs={6} sm={6} md={3}>
        //                     <Link style={{ textDecoration: 'none' }} color="inherit" to={`/Customer/${option.name}/${option.id}`}>
        //                         <Card
        //                             className="card"
        //                             style={{
        //                                 backgroundColor: `${option.color}`
        //                             }}
        //                         >
        //                             <CardContent className="cardContent">
        //                                 <Typography component="h2">{option.name}</Typography>
        //                                 <Typography variant="h5">{option.count}</Typography>
        //                             </CardContent>
        //                         </Card>
        //                     </Link>
        //                 </Grid>
        //             ))}
        //         </Grid>
        //     </Container>
        // </div>
        <div style={{ margin: 10 }}>
            <Grid
                container
                spacing={1}
                direction="row"
                // justify="space-between"
                alignItems="center"
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
            >
                {AppStorage.getWIPAllowed() && portalSettings.portalSettingeWIPEnabled && (
                    <Grid item xs={12} style={{ padding: '6px 8px' }}>
                        <RenderTiles cards={cards} label="eWIP" />
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
