import { unAuthMenu, adminMenu, customerMenu, internalUserMenu, auditorMenu, technicianMenu, webFormRoutes } from './menu';
import TreeImgGallery from '../../Components/_shared/treeImgGallery';
import ResetPasswordBYInternal from '../../Components/internal/resetPassword';
import OpenActionLog from '../../Components/internal/Communicator/OpenActionLogs';
import SiteVehicles from '../../Components/internal/Communicator/SiteVehicles';
import BookingsScreen from '../../Components/internal/Communicator/Bookings';
import * as Components from '../../Components';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ForumIcon from '@material-ui/icons/Forum';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import LockIcon from '@material-ui/icons/Lock';
import { getMenuItems } from './../Service/menuService';
import { AppStorage } from '../Service/storage-service';

import { TypeOfAuth } from './menu/menu-auth-type';

const EwipMenus = ['Dashboard Detail', 'Archived Jobs', 'Videos', 'Dashboard', 'QC Jobs'];

export const getMenuForRole = (roles, isLoggedIn) => {
    const portalSettings = AppStorage.getPortalSetting();
    let menus = [];
    if (!isLoggedIn) {
        menus.push(...unAuthMenu);
    } else {
        const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed() && portalSettings.portalSettingCommunicatorEnabled;
        const isInvoiceAllowed = AppStorage.getInvoiceAllowed() && portalSettings.portalSettingInvoiceEnabled;
        const isReportingAllowed = AppStorage.getReportingAllowed() && portalSettings.portalSettingReportingEnabled;
        const isWIPAllowed = AppStorage.getWIPAllowed() && portalSettings.portalSettingeWIPEnabled;

        const isSpecialEquipmentAllowed = AppStorage.getSpecialEquipmentAllowed() && portalSettings.portalSettingSpecialEquipmentEnabled;
        let menuPermissionCounter = AppStorage.menuPermissionCounter();
        const isReportingBudgetDashboardAllowed = AppStorage.getReportingBudgetDashboardAllowed();
        const isReportingAgedMeasureAllowed = AppStorage.getReportingAgedMeasureAllowed();
        const isReportingDOCAllowed = AppStorage.getReportingDOCAllowed();
        const isSalesPerformanceAllowed = AppStorage.getCanAccessSalesPerformance() && portalSettings.portalSettingSalesPerformanceEnabled;
        const isReportingPartsAllowed = AppStorage.getReportingPartsAllowed();
        const isReportingSalesAllowed = AppStorage.getReportingSalesAllowed();
        const isReportingServiceAllowed = AppStorage.getReportingServiceAllowed();
        const isReportingKPIsAllowed = AppStorage.getReportingKPIsAllowed();
        const isReportingWorkShopAllowed = AppStorage.getReportingWorkShopAllowed();
        const isReportingBudget = AppStorage.getReportingBudgetAccess();
        const isDynamicFormAllowed = portalSettings.portalSettingDynamicFormEnabled;
        const getDefaultInternalDashboard = AppStorage.getDefaultInternalDashboard();
        const isApprisalAllowed = AppStorage.getAppraisalPortalAllowed() && portalSettings.portalSettingsAppraisalEnabled;
        const ReportingDashboard = portalSettings.portalSettingReportingDashboard || '';
        // const ReportingDashboard = portalSettings.portalSettingReportingDashboard || '';

        let reportingMenuScreenConfig = [
            { label: 'Budget Dashboard', isAllowed: isReportingBudgetDashboardAllowed },
            { label: 'Profit & Loss', isAllowed: isReportingBudgetDashboardAllowed },
            { label: 'Aged Measures', isAllowed: isReportingAgedMeasureAllowed },
            { label: 'DOC', isAllowed: isReportingDOCAllowed },
            { label: 'Sales Performance', isAllowed: isSalesPerformanceAllowed },
            { label: 'Sales', isAllowed: isReportingSalesAllowed },
            { label: 'Services', isAllowed: isReportingServiceAllowed },
            { label: 'Parts', isAllowed: isReportingPartsAllowed },
            { label: 'Settings', isAllowed: isReportingBudget }
        ];
        const dashboards = [
            { label: 'eWIP', path: '/eWIPDashboard', allowed: isWIPAllowed, id: 1 },
            { label: 'Reports', path: ReportingDashboard ? `/${ReportingDashboard}` : '/ReportsDashboard', allowed: isReportingAllowed, id: 2 },
            // { label: 'ReportsBellTruck', path: '/ReportsDashboard', allowed: isReportingAllowed, id: 2 },
            { label: 'Invoices', path: '/invoice/dashboard', allowed: isInvoiceAllowed, id: 3 },
            { label: 'Communicator', path: '/communicator_dash', allowed: isCommunicatorAllowed, id: 4 }
        ];

        roles.forEach((role) => {
            // eslint-disable-next-line default-case
            switch (role) {
                case 'admin':
                    //For admin we used PortalSetting Validations
                    menus.push(...adminMenu);
                    if (!portalSettings.portalSettingCommunicatorEnabled) {
                        let Communicator = menus.findIndex((m) => m.label == 'Communicator');
                        menus.splice(Communicator, 1);
                    }
                    if (!portalSettings.portalSettingReportingEnabled) {
                        let Reporting = menus.findIndex((m) => m.label == 'Reporting');
                        // menus[Reporting].label = label;
                        menus.splice(Reporting, 1);
                    }
                    if (!portalSettings.portalSettingeWIPEnabled) {
                        let AdmineWIPMenus = menus.findIndex((m) => m.label == 'eWIP');
                        menus.splice(AdmineWIPMenus, 1);
                    } else {
                        if (!isDynamicFormAllowed) {
                            let eWIPIndex = menus.findIndex((m) => m.label == 'eWIP');
                            if (eWIPIndex > -1) {
                                menus[eWIPIndex].children = menus[eWIPIndex].children.filter((m) => m.path != '/DynamicFormsList');
                            }
                        }
                    }
                    if (!portalSettings.portalSettingInvoiceEnabled) {
                        let AdminInvoice = menus.findIndex((m) => m.label == 'Invoices');
                        menus.splice(AdminInvoice, 1);
                    }
                    if (!portalSettings.portalSettingsAppraisalEnabled) {
                        let AdminAppraisal = menus.findIndex((m) => m.label == 'Appraisal');
                        menus.splice(AdminAppraisal, 1);
                    }
                    break;
                case 'customer':
                    menus.push(...customerMenu);
                    if (!isCommunicatorAllowed) {
                        let Communicator = menus.findIndex((m) => m.label == 'Communicator');
                        menus.splice(Communicator, 1);
                    }
                    if (!isWIPAllowed) {
                        let eWIP = menus.findIndex((m) => m.label == 'eWIP');
                        menus.splice(eWIP, 1);
                    }
                    if (!isInvoiceAllowed) {
                        let InternalInvoice = menus.findIndex((m) => m.label == 'Invoices');
                        menus.splice(InternalInvoice, 1);
                    }

                    if (!isApprisalAllowed) {
                        let CustAppraisal = menus.findIndex((m) => m.label == 'Appraisal');
                        menus.splice(CustAppraisal, 1);
                    }

                    if (menuPermissionCounter == 1) {
                        menus = [...menus].flatMap((elem) => {
                            if (elem.children && elem.children.length > 0 && !elem.dontFlatListChildren) {
                                if (isWIPAllowed) {
                                    let DahboardMenuIndex = elem.children.findIndex((p) => p.path === '/cust_dashboard');
                                    if (DahboardMenuIndex >= 0) {
                                        elem.children.splice(DahboardMenuIndex, 1);
                                    }
                                }
                                if (isInvoiceAllowed) {
                                    let DahboardMenuIndex = elem.children.findIndex((p) => p.path === '/invoice/dashboard');
                                    if (DahboardMenuIndex >= 0) {
                                        elem.children.splice(DahboardMenuIndex, 1);
                                    }
                                } else if (isCommunicatorAllowed) {
                                    let DahboardMenuIndex = elem.children.findIndex((p) => p.path === '/communicator_dash');
                                    if (DahboardMenuIndex >= 0) {
                                        elem.children.splice(DahboardMenuIndex, 1);
                                    }
                                }
                                return elem.children;
                            } else {
                                return elem;
                            }
                        });
                    }

                    break;
                case 'internal':
                    menus.push(...internalUserMenu);
                    if (!isApprisalAllowed) {
                        let CustAppraisal = menus.findIndex((m) => m.label == 'Appraisal');
                        menus.splice(CustAppraisal, 1);
                    }
                    if (!isWIPAllowed) {
                        let eWIP = menus.findIndex((m) => m.label == 'eWIP');
                        menus.splice(eWIP, 1);
                    }
                    if (isWIPAllowed && menuPermissionCounter != 1) {
                        let eWIPIndex = menus.findIndex((m) => m.label == 'eWIP');
                        let FindOtherIndex = menus[eWIPIndex].children?.findIndex((m) => m.label == 'Others');
                        if (FindOtherIndex >= 0) {
                            let OthersMenusChildren = [...menus][eWIPIndex]?.children[FindOtherIndex]?.children;
                            menus[eWIPIndex].children.splice(FindOtherIndex, 1);
                            menus[eWIPIndex].children.push(...OthersMenusChildren);
                        }
                    }
                    if (isWIPAllowed && !isSpecialEquipmentAllowed) {
                        let eWIPIndex = menus.findIndex((m) => m.label == 'eWIP');
                        menus[eWIPIndex].children = menus[eWIPIndex].children.filter((m) => m.label != 'Special Equipments');
                    }

                    if (!isCommunicatorAllowed) {
                        let Communicator = menus.findIndex((m) => m.label == 'Communicator');
                        menus.splice(Communicator, 1);
                    }

                    if (!isReportingAllowed) {
                        let ReportsIndex = menus.findIndex((m) => m.label == 'Reports');
                        console.log(menus[ReportsIndex], '<<<<<<<<<<<menus[Reporting]');
                        menus.splice(ReportsIndex, 1);
                    } else {
                        let ReportsIndex = menus.findIndex((m) => m.label == 'Reports');
                        if (ReportingDashboard && ReportsIndex !== -1) {
                            let index = menus[ReportsIndex].children.findIndex((k) => k.label === 'Group Dashboard');
                            if (index !== -1) {
                                menus[ReportsIndex].children.splice(index, 1, {
                                    label: 'Group Dashboard',
                                    exact: true,
                                    path: `/${ReportingDashboard}`,
                                    component: Components.BellTruckDashboard,
                                    authType: TypeOfAuth.Auth
                                    // icon: <SpeedIcon />
                                });
                            }
                        }
                        reportingMenuScreenConfig.forEach((k) => {
                            if (!k.isAllowed) {
                                let reportingMenuScreenIndex = menus[ReportsIndex].children.findIndex((m) => m.label == k.label);
                                if (reportingMenuScreenIndex >= 0) {
                                    menus[ReportsIndex].children.splice(reportingMenuScreenIndex, 1);
                                }
                            }
                            if (k.label == 'Settings' && !isSalesPerformanceAllowed && isReportingBudget) {
                                let ReportsSettingsMenuScreenIndex = menus[ReportsIndex].children.findIndex((m) => m.label == k.label);
                                if (ReportsSettingsMenuScreenIndex >= 0) {
                                    let SalesPerndex = menus[ReportsIndex].children[ReportsSettingsMenuScreenIndex].children.findIndex(
                                        (m) => m.label == 'Sales Performance '
                                    );
                                    if (SalesPerndex >= 0) {
                                        menus[ReportsIndex].children[ReportsSettingsMenuScreenIndex].children.splice(SalesPerndex, 1);
                                    }
                                }
                            }
                        });
                    }

                    if (!isInvoiceAllowed) {
                        let InternalInvoice = menus.findIndex((m) => m.label == 'Invoices');
                        menus.splice(InternalInvoice, 1);
                    }
                    if (isInvoiceAllowed && menuPermissionCounter != 1) {
                        let InternalInvoice = menus.findIndex((m) => m.label == 'Invoices');
                        let subMenuInvoice = menus[InternalInvoice].children?.findIndex((m) => m.label == 'Invoices');
                        if (subMenuInvoice >= 0) {
                            let OthersMenusChildren = [...menus][InternalInvoice]?.children[subMenuInvoice]?.children;
                            menus[InternalInvoice].children.splice(subMenuInvoice, 1);
                            menus[InternalInvoice].children.push(...OthersMenusChildren);
                        }
                    }
                    if (menuPermissionCounter == 1) {
                        menus = [...menus].flatMap((elem) => {
                            if (elem.children && elem.children.length > 0 && !elem.dontFlatListChildren) {
                                dashboards.forEach((m) => {
                                    if (m.allowed) {
                                        const dashboardMenuIndex = elem.children.findIndex((p) => p.path === m.path);
                                        if (dashboardMenuIndex >= 0) {
                                            elem.children[dashboardMenuIndex].path = '/';
                                        }
                                    }
                                });
                                return elem.children;
                            } else {
                                return elem;
                            }
                        });
                    } else {
                        dashboards.forEach((item) => {
                            if (item.allowed) {
                                if (getDefaultInternalDashboard == 'null' || getDefaultInternalDashboard == 0) {
                                    const allowedDashboards = dashboards.filter((item) => item.allowed);
                                    const topAllowedDashboard = allowedDashboards.find((item) => item.path && item.path !== '/');
                                    if (topAllowedDashboard) {
                                        const menuIndex = menus.findIndex((p) => p.label === topAllowedDashboard.label);
                                        const dashboardMenuIndex = menus[menuIndex].children.findIndex((p) => p.path === topAllowedDashboard.path);
                                        if (dashboardMenuIndex >= 0) {
                                            menus[menuIndex].children[dashboardMenuIndex].path = '/';
                                        }
                                    }
                                } else if (getDefaultInternalDashboard == item.id) {
                                    const menuIndex = menus.findIndex((p) => p.label === item.label);
                                    const dashboardMenuIndex = menus[menuIndex].children.findIndex((p) => p.path === item.path);
                                    if (dashboardMenuIndex >= 0) {
                                        menus[menuIndex].children[dashboardMenuIndex].path = '/';
                                    }
                                }
                            }
                        });
                    }

                    if (AppStorage.getCanResetPassword()) {
                        menus.push({
                            label: 'Reset Password',
                            icon: <LockIcon />,
                            exact: true,
                            path: '/resetpassword',
                            component: (props) => <ResetPasswordBYInternal {...props} />,
                            authType: TypeOfAuth.Auth
                        });
                    }
                    // if (AppStorage.getCanAccessLockScreen()) {
                    //     menus.push({
                    //         label: 'Locked Jobs',
                    //         icon: <LockIcon />,
                    //         exact: true,
                    //         path: '/jobLockList',
                    //         component: (props) => <Components.JobLockListScreen {...props} />,
                    //         authType: TypeOfAuth.Auth
                    //     });
                    // }
                    break;
                case 'auditor':
                    menus.push(...auditorMenu);
                    break;
                case 'technician':
                    menus.push(...technicianMenu);
                    if (!isReportingAllowed) {
                        let ReportsIndex = menus.findIndex((m) => m.label == 'Reports');
                        menus.splice(ReportsIndex, 1);
                    } else {
                        let ReportsIndex = menus.findIndex((m) => m.label == 'Reports');
                        reportingMenuScreenConfig.forEach((k) => {
                            if (!k.isAllowed) {
                                let reportingMenuScreenIndex = menus[ReportsIndex].children.findIndex((m) => m.label == k.label);
                                if (reportingMenuScreenIndex >= 0) {
                                    menus[ReportsIndex].children.splice(reportingMenuScreenIndex, 1);
                                }
                            }
                            if (k.label == 'Settings' && !isSalesPerformanceAllowed && isReportingBudget) {
                                let ReportsSettingsMenuScreenIndex = menus[ReportsIndex].children.findIndex((m) => m.label == k.label);
                                if (ReportsSettingsMenuScreenIndex >= 0) {
                                    let SalesPerndex = menus[ReportsIndex].children[ReportsSettingsMenuScreenIndex].children.findIndex(
                                        (m) => m.label == 'Sales Performance '
                                    );
                                    if (SalesPerndex >= 0) {
                                        menus[ReportsIndex].children[ReportsSettingsMenuScreenIndex].children.splice(SalesPerndex, 1);
                                    }
                                }
                            }
                        });
                    }
                    break;
            }
        });
        menus.push(...webFormRoutes);

        menus.push({
            path: '/treeImgs/:count',
            hidden: true,
            component: (props) => <TreeImgGallery {...props} />,
            authType: TypeOfAuth.Auth
        });
    }

    return menus.filter((m) => m.authType === TypeOfAuth.Both || m.authType === (isLoggedIn ? TypeOfAuth.Auth : TypeOfAuth.NonAuth));
};
