import { useMemo, useState } from 'react';
import { FormLabel, Grid } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../../ReportsScreens/GijgoForReportsScreen';
import { DatePicker } from '../../../../../Core/FormInput';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const baseColumns = [
    { field: 'reg', sortable: true, title: `${'Registration'}`, width: 55 },
    { field: 'cw', sortable: true, title: `Current Workflow`, width: 70 },
    { field: 'nw', sortable: true, title: `Next Workflow`, width: 70 },
    { field: 'dd', sortable: true, title: `Due Date`, width: 90 },
    { field: 'mm', sortable: true, title: 'Model', width: 170 },
    { field: 'rr', sortable: true, title: 'Return Reason', width: 120 },
    {
        field: 'u',
        sortable: true,
        title: 'User',
        width: 100
    },
    { field: 'br', sortable: true, title: 'Branch', width: 120 },
    { field: 'c', sortable: true, title: 'Customer', width: 120 }
];

const crumbs = () => {
    let list = [
        { name: 'Home', path: '/' },
        { name: 'Reports', active: true },
        { name: 'Return Reasons', active: true }
    ];
    return list;
};

const useStyles = makeStyles((theme) => ({
    columnStyle: {
        cursor: 'pointer'
    }
}));

const ReturnReasonReportsList = (props) => {
    const classes = useStyles();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let cwWorkflowId = params.get('cwWorkflowId') || '';
    let rrName = params.get('rrName') || '';
    let nwWorkflowId = params.get('nwWorkflowId') || '';
    let cwWorkflowName = params.get('cwWorkflowName') || '';
    let rrId = params.get('rrId') || '';
    let SelectedUserName = params.get('SelectedUserName') || '';
    let BranchIDParam = params.get('BranchID') || '';
    let BranchNameParam = params.get('BranchName') || '';
    let startDateParams = params.get('startDate') || '';
    let endDateParams = params.get('endDate') || '';
    let SelectedUserId = params.get('SelectedUserId') || '';
    let startDate = startDateParams || moment().startOf('month').format('YYYY-MM-DD');
    let endDate = endDateParams || moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate
    });

    const redirectTo = (data) => {
        const win = window.open(`/Job/${data.hid}/${data.wid}`, '_blank');
        win.focus();
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        col = col.map((o) => {
            o.events = {
                click: (e) => redirectTo(e.data.record)
            };
            return { ...o, cssClass: `${classes.columnStyle}` };
        });
        return col;
    }, []);

    const getUrl = `DashBoard/GetInternalReturnReasonsList?startDate=${state.startDate}&endDate=${state.endDate}`;
    // &cwWorkflowId=${cwWorkflowId}&nwWorkflowId=${nwWorkflowId}&rrId=${16}&SelectedUserId=${SelectedUserId}

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs()} />
            <Grid container spacing={1} style={{ padding: '10px 0px' }}>
                <Grid item xs={4} md={3} lg={2}>
                    <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                        Start Date
                    </FormLabel>
                    <DatePicker placeholder="Start Date" name="startDate" fullWidth value={state.startDate} onChange={handleinput} />
                </Grid>
                <Grid item xs={4} md={3} lg={2}>
                    <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                        End Date
                    </FormLabel>
                    <DatePicker placeholder="End Date" name="endDate" fullWidth value={state.endDate} onChange={handleinput} />
                </Grid>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        FilterdParamsValues={{ cw: cwWorkflowName, u: SelectedUserName, rr: rrName, br: BranchNameParam }}
                        columns={column}
                        getUrl={getUrl}
                        displayPagination={true}
                    />
                </Grid>
            </Grid>
        </div>
    );
};
export default ReturnReasonReportsList;
