import './budget.scss';
import { Grid } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ProfitLoss from './profitLoss';
import ReactSpeedometer from 'react-d3-speedometer';

export const BudgetCard = (props) => {
    const { count, label, subCount1, sublabel1, subCount2, sublabel2, icon_1, icon_2 } = props;
    let Seticon = (props) => {
        let Icon = props.icon === 'up' ? ArrowUpwardIcon : ArrowDownwardIcon;
        return (
            <div className="bheadingOne" style={{ fontSize: 20, padding: '15px 5px 7px', color: props.icon === 'up' ? 'green' : 'red' }}>
                {props.counts || 0}
                {<Icon style={{ fontSize: 18 }} />}
            </div>
        );
    };
    return (
        <div className="budgetCard">
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ paddingBottom: 5 }}>
                    <div className="bheadingOne" style={{ fontSize: 35, padding: '17px 17px 0px 17px' }}>
                        {count || 0}
                    </div>
                    <div className="bheadingOne" style={{ fontSize: 17, paddingBottom: 10 }}>
                        {label}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} style={{ paddingBottom: 5 }}>
                        <Grid item xs={6}>
                            <Seticon icon={icon_1} counts={subCount1} />
                            <div className="bheadingOne" style={{ fontSize: 12, padding: 5 }}>
                                {sublabel1}
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <Seticon icon={icon_2} counts={subCount2} />

                            <div className="bheadingOne" style={{ fontSize: 10, padding: 5 }}>
                                {sublabel2}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export const BudgetCardTwo = (props) => {
    const { count, label, subCount, sublabel, icon } = props;

    let Seticon = (props) => {
        let Icon = props.icons === 'up' ? ArrowUpwardIcon : ArrowDownwardIcon;
        return (
            <div className="bheadingOne" style={{ fontSize: 20, padding: '15px 7px 5px 15px', color: props.icons === 'up' ? 'green' : 'red' }}>
                {props.counts || 0}
                {<Icon style={{ fontSize: 18 }} />}
            </div>
        );
    };
    return (
        <div className="budgetCard">
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                    <div className="bheadingOne" style={{ fontSize: 35, padding: '17px 17px 0px 17px' }}>
                        {count || 0}
                    </div>
                    <div className="bheadingOne" style={{ fontSize: 17, paddingBottom: 10 }}>
                        {label}
                    </div>
                    <Seticon icons={icon} counts={subCount} />
                    <div className="bheadingOne" style={{ fontSize: 15, padding: 6 }}>
                        {sublabel}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export const BudgetCardThree = (props) => {
    const { val, label, count_1, count_2, label_1, label_2, icon_1, icon_2 } = props;

    let Seticon = (props) => {
        let Icon = props.icon === 'up' ? ArrowUpwardIcon : ArrowDownwardIcon;
        return (
            <div className="bheadingOne" style={{ fontSize: 20, padding: '11px 5px 7px', color: props.icon === 'up' ? 'green' : 'red' }}>
                {props.counts || 0}
                {<Icon style={{ fontSize: 18 }} />}
            </div>
        );
    };
    return (
        <div className="budgetCard">
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                    <div className="bheadingOne" style={{ fontSize: 30, padding: '17px 17px 0px 17px' }}>
                        {val ? <>£{val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
                    </div>
                    <div className="bheadingOne" style={{ fontSize: 15, paddingBottom: 5 }}>
                        {label}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} style={{ paddingBottom: 5 }}>
                        <Grid item xs={6}>
                            <Seticon counts={count_1} icon={icon_1} />
                            <div className="bheadingOne" style={{ fontSize: 12, padding: 5 }}>
                                {label_1}
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <Seticon counts={count_2} icon={icon_2} />
                            <div className="bheadingOne" style={{ fontSize: 10, padding: 5 }}>
                                {label_2}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export const ProfitLossCard = (props) => {
    const { val, label, subValue_1, subValue_2 } = props;
    return (
        <div className="meter_budgetCard">
            <ReactSpeedometer
                maxValue={100}
                height={130}
                labelFontSize={'10px'}
                valueTextFontSize={'12px'}
                // needleHeightRatio={0.4}
                value={val}
                needleHeightRatio={0.7}
                width={200}
                ringWidth={17}
                needleColor="black"
                segments={4}
                customSegmentStops={[0, 50, 75, 100]}
                segmentColors={['#ff0000', '#FFBF00', '#00FF00']}
                currentValueText={`${val}% OVERALL TOTAL`}
            />
            <div className="bheadingOne" style={{ fontSize: 17, paddingBottom: 5 }}>
                {label}
            </div>
            <Grid container spacing={3} style={{ padding: 10 }}>
                <Grid item xs={6} style={{ borderRight: '2px solid #e6e6e6' }}>
                    <div className="bheadingOne" style={{ fontSize: 16, padding: '5px 0px 0px' }}>
                        Budget
                    </div>
                    <div className="bheadingOne" style={{ fontSize: 15, padding: 2 }}>
                        {subValue_1 ? <>£{subValue_1.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="bheadingOne" style={{ fontSize: 16, padding: '5px 0px 0px' }}>
                        Profit
                    </div>
                    <div className="bheadingOne" style={{ fontSize: 15, padding: 2 }}>
                        {subValue_2 ? <>£{subValue_2.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export const BudgetSubmeter = (props) => {
    const { val, subVal_1, subVal_2, title } = props;
    return (
        <div className="meter_budgetCard">
            <ReactSpeedometer
                maxValue={100}
                height={130}
                labelFontSize={'10px'}
                valueTextFontSize={'12px'}
                // needleHeightRatio={0.4}
                value={val}
                needleHeightRatio={0.7}
                width={200}
                ringWidth={17}
                needleColor="black"
                segments={4}
                customSegmentStops={[0, 50, 75, 100]}
                segmentColors={['#ff0000', '#FFBF00', '#00FF00']}
                currentValueText={`${val}% ${title}`}
            />
            <Grid container spacing={1} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <div className="bheadingOne" style={{ fontSize: 17, padding: '0px 4px 2px' }}>
                        Profit
                    </div>
                    <div className="bheadingOne" style={{ fontSize: 15, padding: 0 }}>
                        {subVal_1 ? <>£{subVal_1.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="bheadingOne" style={{ fontSize: 17, padding: '5px 4px 2px' }}>
                        Budget
                    </div>
                    <div className="bheadingOne" style={{ fontSize: 15, padding: 0 }}>
                        {subVal_2 ? <>£{subVal_2.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
