import { CircularProgress, FormLabel, Grid, InputLabel, TextField } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import { SelectBox, SingelSelect } from '../../../Core/FormInput';
import { getReportsDropdowns, GetShiftCalender, GetShiftCalenderScreenDropdowns } from '../../../Core/Service/reportService';
import { AddHolidays, getHolidaysList, getHolidaysScreenDropdown } from '../../../Core/Service/admin-service';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import ConfirmationModal from '../../../Core/Controls/Dialog/ConfirmationModal';
import { useContext } from 'react';
import AppContext from '../../../App/AppContext';
import { Autocomplete } from '@material-ui/lab';
import { t } from 'i18next';

function padLeft(nr, n, str) {
    return Array(n - String(nr).length + 1).join(str || '0') + nr;
}
const months = moment.months();

const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: 'Workshop', active: true },
    { name: 'Holiday Calendar', active: true }
];

const colorConfig = {
    0: { color: '#dc3545', name: 'Dormant' },
    3: { color: '#28a745', name: 'Active' },
    2: { color: '#E829E8', name: 'PM commence' },
    1: { color: '#33FFFF', name: 'AM continue' }
};

const HolidayCalenderComp = () => {
    const { showToast, showModal } = useContext(AppContext);

    const [state, setState] = useState({
        data: {},
        branchList: [],
        shiftList: [],
        yearList: [],
        branchID: { id: '', name: '' },
        yearCode: { description: `${new Date().getFullYear()}`, id: `${new Date().getFullYear()}` },
        showLoader: true
    });
    const [calender, setCalender] = useState([]);

    const pullDataNSetCalendar = async () => {
        setState((st) => ({ ...st, showLoader: true }));
        const res = await getHolidaysList(state.yearCode.id);
        let apiHolidaysList = res.data;

        let localDaysCounter = 0;

        var finalCalendar = [];

        months.forEach((month, monthIndex) => {
            var tempMonthData = [];

            const weekStartDate = moment([state.yearCode.id, monthIndex]).clone().startOf('month').startOf('isoWeek');
            const monthStartDate = moment([state.yearCode.id, monthIndex]).clone().startOf('month').subtract(1, 'd');

            const endDate = moment([state.yearCode.id, monthIndex]).clone().endOf('month');

            const day = weekStartDate.clone().subtract(1, 'day');

            while (day.isBefore(endDate, 'day')) {
                tempMonthData.push(
                    Array(7)
                        .fill(0)
                        .map(() => {
                            day.add(1, 'day');
                            if (day.isAfter(monthStartDate, 'day') && day.isSameOrBefore(endDate, 'day')) {
                                localDaysCounter += 1;
                                let isTempDateObj = apiHolidaysList.find((p) => moment(p.date).format('DD-MMM-YYYY') == day.clone().format('DD-MMM-YYYY'));

                                return {
                                    day: day.clone().format('DD'),
                                    fullDate: day.clone().format('DD-MMM-YYYY'),
                                    workingDay: isTempDateObj?.workingDay === 'Y' ? 'Y' : 'N'
                                };
                            } else {
                                return null;
                            }
                        })
                );
            }
            if (tempMonthData.length <= 5) {
                tempMonthData.push(
                    Array(7)
                        .fill(0)
                        .map(() => {
                            return null;
                        })
                );
            }
            finalCalendar.push({ monthName: month, monthData: tempMonthData });
        });

        setState((st) => ({ ...st, showLoader: false }));
        setCalender(finalCalendar);
    };

    useEffect(async () => {
        let res = await getHolidaysScreenDropdown();

        setState((st) => {
            let newSt = { ...st };
            newSt.yearList = res.data.map((o) => {
                return { description: `${o.name}`, id: `${o.id}` };
            });

            return newSt;
        });
    }, []);

    useEffect(async () => {
        pullDataNSetCalendar();
    }, [state.yearCode?.id]);

    const hideConfirmationModal = () => {
        setState((st) => ({ ...st, showWarning: false }));
    };

    const submitFunction = async (selectedData) => {
        if (selectedData == null) {
            return false;
        }
        let data = { Date: selectedData.fullDate, WorkingDay: selectedData.workingDay === 'Y' ? 'N' : 'Y' };
        let res = await AddHolidays(data);
        if (res.success) {
            pullDataNSetCalendar();
            showToast(`Set ${selectedData.workingDay === 'Y' ? 'Holiday' : 'Workday'} for ${moment(selectedData.fullDate).format('DD/MM/YYYY')}`);
            setState((st) => {
                const nst = { ...st };
                // nst.showWarning = false;
                nst.selectedData = {};
                return nst;
            });
        }
    };

    // const selectedDateConfirmationModal = (selectedData) => {
    //     setState((st) => {
    //         const nst = { ...st };
    //         nst.showWarning = true;
    //         nst.selectedData = selectedData;
    //         nst.modalMsg = `Set ${selectedData.workingDay === 'Y' ? 'Workday' : 'Holiday'} for ${moment(selectedData.fullDate).format('DD/MM/YYYY')}`;
    //         return nst;
    //     });
    // };

    const handleAutoChange = (fieldName) => (e, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt[fieldName] = val;
            return newSt;
        });
    };

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs()} />
            <Grid item xs={12} sm={4} md={4}>
                <Grid container alignItems="center">
                    <Grid item>
                        <FormLabel>Select Year&nbsp;</FormLabel>
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            disableClearable
                            autoSelect
                            options={state.yearList}
                            name="Years"
                            value={state.yearCode}
                            onChange={handleAutoChange('yearCode')}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {state.showLoader ? (
                <Grid container alignItems="center" justify="center" style={{ height: 'calc(100vh - 215px)' }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container spacing={2} justifyContent="center" style={{ marginTop: '10px' }}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {calender.map((a, i) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={i}>
                                    <table
                                        style={{
                                            width: '100%',
                                            border: '1px solid rgba(0,0,0,0.4)',
                                            borderCollapse: 'collapse'
                                        }}
                                    >
                                        <tr>
                                            <th colSpan={7} style={{ padding: '10px', backgroundColor: '#555555' }}>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',

                                                        color: 'white',
                                                        fontSize: '16px'
                                                    }}
                                                >
                                                    {a.monthName}
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Mon</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Tue</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Wed</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Thu</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Fri</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Sat</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Sun</th>
                                        </tr>
                                        {a.monthData.map((p) => (
                                            <tr>
                                                {p.map((q) => (
                                                    <td
                                                        style={{
                                                            padding: '5px',
                                                            height: '20px',
                                                            cursor: q !== null ? 'pointer' : 'default',
                                                            border: '1px solid black',
                                                            color: q?.workingDay === 'Y' ? 'black' : 'white',
                                                            backgroundColor: q !== null ? (q?.workingDay === 'Y' ? 'green' : 'red') : 'white'
                                                        }}
                                                        // onClick={() => selectedDateConfirmationModal(q)}
                                                        onClick={() => submitFunction(q)}
                                                    >
                                                        <span>{q !== null ? q.day : ''}</span>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </table>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {state.showWarning ? (
                <DialogComp title="Warning" onClose={hideConfirmationModal} maxWidth="sm">
                    <ConfirmationModal message={state.modalMsg} handleSubmit={submitFunction} handleCancel={hideConfirmationModal} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default HolidayCalenderComp;
