import { Button, ButtonGroup, FormControlLabel, FormLabel, Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { SecondaryCheckbox, TextBox } from '../../../../Core/FormInput';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DiagnosticSheetsImageComponent from '../imageSlider';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    table: {
        minWidth: '100%'
    },

    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    },
    paper: {
        padding: '7px 16px 16px 16px',
        // color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)',
        width: '100%'
        // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
    },
    axelTitles: {
        textAlign: 'center',
        color: '#f50057',
        width: '100%'
    },
    headings: {
        color: '#f50057',
        margin: 0
        // marginLeft: -4,
        // marginBottom: 20
    }
}));

const SectionTwo = (props) => {
    const { state, handleFieldChange, handleCheckbox, handleYesNo, upload, handleRemoveClick } = props;
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <Grid container xs={12}>
                <h3 className={classes.headings}>Section 2: Noise/Vibration</h3>
            </Grid>
            <hr></hr>
            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                    <FormLabel>Description of concern</FormLabel>
                    <TextBox
                        multiline
                        rows={5}
                        value={state.diagnosticSheetNoiseVibrationConcern}
                        onChange={handleFieldChange}
                        name="diagnosticSheetNoiseVibrationConcern"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Noise apparent:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <ButtonGroup disableElevation variant="contained" color="secondary" size="small">
                                        <Button
                                            variant={state.diagnosticSheetIsNoiseApparent ? 'contained' : 'outlined'}
                                            onClick={() => handleYesNo('diagnosticSheetIsNoiseApparent', true)}
                                        >
                                            Yes
                                        </Button>

                                        <Button
                                            variant={state.diagnosticSheetIsNoiseApparent ? 'outlined' : 'contained'}
                                            onClick={() => handleYesNo('diagnosticSheetIsNoiseApparent', false)}
                                        >
                                            No
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                                {/* {state.diagnosticSheetIsNoiseApparent && ( */}
                                <Grid item style={{ visibility: state.diagnosticSheetIsNoiseApparent ? 'visible' : 'hidden' }}>
                                    <Grid container>
                                        {state.noiseApparent.map((c) => {
                                            return (
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <SecondaryCheckbox
                                                                checked={c.checked}
                                                                onChange={handleCheckbox('noiseApparent', c.id)}
                                                                name={`${c.name}`}
                                                            />
                                                        }
                                                        label={`${c.name}`}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                                {/* )} */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Location:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                {state.location.map((c) => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                            <FormControlLabel
                                                control={
                                                    <SecondaryCheckbox checked={c.checked} onChange={handleCheckbox('location', c.id)} name={`${c.name}`} />
                                                }
                                                label={`${c.name}`}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>Type of noise:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                {state.typeOfNoise.map((c) => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                                            <FormControlLabel
                                                control={
                                                    <SecondaryCheckbox checked={c.checked} onChange={handleCheckbox('typeOfNoise', c.id)} name={`${c.name}`} />
                                                }
                                                label={`${c.name}`}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>{`Other (specify)`}:</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextBox
                                        multiline
                                        rows={5}
                                        value={state.diagnosticSheetNoiseVibrationOther}
                                        onChange={handleFieldChange}
                                        name="diagnosticSheetNoiseVibrationOther"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={2} lg={1} xl={1}>
                            <FormLabel>{`Images`}:</FormLabel>
                        </Grid>

                        <Grid item xs={12} sm={10} lg={11} xl={11}>
                            <Tooltip title="Upload Document">
                                <label>
                                    <span
                                        style={{
                                            padding: '1px 10px 6px 12px',
                                            border: '1px solid #f50057',
                                            borderRadius: '7%',
                                            backgroundColor: '#f50057',
                                            color: 'white',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Add Images
                                    </span>
                                    <input
                                        name="diagnosticSheetNoiseVibrationImg"
                                        multiple
                                        type="file"
                                        onChange={(e) => upload(e, 'diagnosticSheetNoiseVibrationImg')}
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                    />
                                </label>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={2} lg={1} xl={1}></Grid>
                        <Grid item xs={12} sm={10} lg={11} xl={11} style={{ maxHeight: '230px', overflow: 'auto', width: '100%', padding: '0px 10px' }}>
                            <DiagnosticSheetsImageComponent
                                ImageList={state.diagnosticSheetNoiseVibrationImg}
                                handleRemoveClick={(i) => handleRemoveClick(i, 'diagnosticSheetNoiseVibrationImg')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SectionTwo;
