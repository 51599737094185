import React, { useContext, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import { Link, NavLink } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import AppContext from '../../App/AppContext';

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
        backgroundColor: (props) => `${props.portalMenuBarBackgroundColour}`,
        height: '100%',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    fullList: {
        width: 'auto'
    },
    linknav: {
        textDecoration: 'none',
        color: (props) => `${props.portalMenuBarTextColour}`,
        fontSize: '15px'
    },
    nested: {
        paddingLeft: theme.spacing(2),
        backgroundColor: (props) => `${props.portalMenuBarBackgroundColour}`
    }
}));

export default function TemporaryDrawer(props) {
    const [state, setState] = React.useState({});
    const { portalSettings } = useContext(AppContext);

    const theme = useTheme();
    const classes = useStyles(portalSettings);

    const handleClick = (item) => {
        setState((st) => ({ ...st, [item]: !st[item] }));
    };

    const handler = (children, isFiltered) => {
        let currentNav = isFiltered ? _.orderBy(children, [(c) => c.label.toLowerCase()], ['asc']) : children;
        return currentNav?.map((subOption, index) => {
            if (!subOption.children) {
                return (
                    <div key={subOption.label}>
                        <NavLink activeClassName="active-link-btn" exact to={subOption.path} className={classes.linknav}>
                            <ListItem
                                className={classes.linknav}
                                button
                                size="small"
                                key={subOption.label}
                                title={subOption.label}
                                onClick={props.onClose('left', false)}
                                onKeyDown={props.onClose('left', false)}
                            >
                                <ListItemText className={classes.itemText}>{subOption.label}</ListItemText>
                            </ListItem>
                        </NavLink>
                    </div>
                );
            }
            return (
                <div key={subOption.label}>
                    <ListItem className={classes.linknav} button onClick={() => handleClick(subOption.label)} title={subOption.label}>
                        <ListItemText className={classes.itemText}>{subOption.label}</ListItemText>
                        <ListItemIcon style={{ display: 'flex', justifyContent: 'flex-end' }} className={classes.linknav}>
                            {state[subOption.label] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                    </ListItem>
                    <Collapse className={classes.nested} in={state[subOption.label]} timeout="auto" unmountOnExit>
                        {handler(subOption.children, true)}
                    </Collapse>
                </div>
            );
        });
    };

    return (
        <div className={clsx(classes.list)} role="presentation">
            <List>
                {handler(
                    props.currentMenus.filter((m) => !m.hidden),
                    false
                )}
            </List>
        </div>
    );
}
