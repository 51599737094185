import { FormLabel, Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import EditOutlined from '@material-ui/icons/EditOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { SecondaryButton, TextBox } from '../../../Core/FormInput';
import DataGridComp from '../../../Core/Grid';
import { getDropdowns, getEngTranslations, getAllTranslations, addUpdate } from '../../../Core/Service/translation-service';

const Translations = (props) => {
    const [state, setState] = useState({
        fileDropdown: [],
        rows: [],
        editMode: false,
        errors: {},
        fields: {},
        disableSubmit: false,

        id: null,
        file: null,
        category: "",
        key: "",
        text: "",
    });

    useEffect(() => {
        pullData();
    }, []);

    const pullData = async () => {
        let dropdownRes = await getDropdowns();
        setState((st) => ({
            ...st,
            fileDropdown: dropdownRes.data?.translationFiles,
        }))

        let transRes = await getEngTranslations();
        setState((st) => ({
            ...st,
            rows: transRes.data?.table,
        }))
    }

    const handleArrowClick = async (input) => {
        setState((st) => ({
            ...st,
            editMode: true,
            file: { fileID: input.fileID, fileName: input.fileName },
            id: input.id,
            key: input.translationKey,
            text: input.translationText,
            category: input.category,
        }))

        let res = await getAllTranslations(input.fileID, input.category, input.translationKey);

        res.data?.table.map((arr) => {
            setState((st) => ({
                ...st,
                [arr.languageDescription]: { "languageCode": arr.languageCode, "translationID": arr.translationID, "translationText": arr.translationText }
            }))
        })
    }

    const ActionButtons = (params) => {
        return (
            <>
                <Tooltip title="Update Row" aria-label="add">
                    <IconButton size="small" onClick={() => handleArrowClick(params.value.row)}>
                        <EditOutlined color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const columns = [
        {
            field: 'action',
            headerName: 'Action',
            width: 80,
            renderCell: (params) => <ActionButtons value={params} />
        },
        {
            field: 'fileName',
            headerName: 'File Name',
            width: 165,
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 165,
        },
        {
            field: 'translationKey',
            headerName: 'Key',
            width: 165,
        },
        {
            field: 'translationText',
            headerName: 'Text',
            flex: 2
        },
    ];

    const handleFieldChange = (e) => {
        const { name, value } = e.target;

        if (name == "Polish" || name == "Romanian" || name == "Hindi") {
            setState((st) => ({
                ...st,
                [name]: { "languageCode": state[name]?.languageCode, "translationID": state[name]?.translationID, "translationText": value }
            }))
        } else {
            setState((st) => ({
                ...st,
                [name]: value
            }))
        }
    }

    const handleAutoChange = (e, value) => {
        if (value)
            setState((st) => {
                let newSt = { ...st };
                newSt.file = value;
                return newSt;
            });
    }

    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;

        if (!state.category) {
            formIsValid = false;
            errors["category"] = "Category cannot be empty";
        }
        if (!state.file) {
            formIsValid = false;
            errors["file"] = "File cannot be empty";
        }
        if (!state.key) {
            formIsValid = false;
            errors["key"] = "Key cannot be empty";
        }
        if (!state.text) {
            formIsValid = false;
            errors["text"] = "Text cannot be empty";
        }

        setState((st) => ({
            ...st,
            errors: errors,
        }));
        return formIsValid;
    };

    const getData = (input) => {
        let data = {
            "TranslationFileID": state.file.fileID,
            "TranslationFileName": state.file.fileName,
            "TranslationCategory": state.category,
            "TranslationKey": state.key,
            "TranslationLanguageCode": "en",
            "TranslationID": state.id,
            "TranslationText": state.text
        }

        if (input == "English") {
            return data;
        } else {
            data["TranslationLanguageCode"] = state[input]?.languageCode
            data["TranslationID"] = state[input]?.translationID || 0
            data["TranslationText"] = state[input]?.translationText
        }
        return data;
    }

    const handleSubmit = async () => {
        if (handleValidation()) {
            setState((st) => ({
                ...st,
                disableSubmit: true,
            }))

            let res = await addUpdate(getData("English"))
            let resPolish = await addUpdate(getData("Polish"))
            let resRomanian = await addUpdate(getData("Romanian"))
            let resHindi = await addUpdate(getData("Hindi"))

            if (res.data?.success && resPolish.data?.success && resRomanian.data?.success && resHindi.data?.success) {
                setState((st) => ({
                    ...st,
                    editMode: false,
                    key: null,
                    category: "",
                    id: null,
                    file: null,
                    category: "",
                    key: "",
                    text: "",
                    Hindi: null,
                    Romanian: null,
                    Polish: null,
                    disableSubmit: false,
                }))
            } else {
                setState((st) => ({
                    ...st,
                    disableSubmit: false,
                }))
            }
        }
    }

    return (
        <div className="screen">
            <Grid container spacing={1} style={{ padding: '15px' }}>
                <Grid item xs={4}>
                    <FormLabel>
                        File
                    </FormLabel>
                    <Autocomplete
                        autoSelect
                        options={state.fileDropdown}
                        name="file"
                        value={state.file}
                        onChange={handleAutoChange}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.fileName)}
                        renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                    />
                    <span className="mandatoryfields">
                        {state.errors["file"]}
                    </span>
                </Grid>
                <Grid item xs={4}>
                    <FormLabel>Category</FormLabel>
                    <TextBox
                        inputLabel="Category"
                        name="category"
                        value={state.category}
                        onChange={handleFieldChange}
                    />
                    <span className="mandatoryfields">
                        {state.errors["category"]}
                    </span>
                </Grid>
                <Grid item xs={4}>
                    <FormLabel>Key</FormLabel>
                    <TextBox
                        inputLabel="Key"
                        name="key"
                        value={state.key}
                        onChange={handleFieldChange}
                    />
                    <span className="mandatoryfields">
                        {state.errors["key"]}
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Text</FormLabel>
                    <TextBox
                        inputLabel="Text"
                        name="text"
                        value={state.text}
                        onChange={handleFieldChange}
                    />
                    <span className="mandatoryfields">
                        {state.errors["text"]}
                    </span>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" style={{ color: 'red', textAlign: 'left' }}>
                        Translation Literals
                    </Typography>
                    <hr />
                </Grid>

                <Grid item xs={12}>
                    <FormLabel>Polish</FormLabel>
                    <TextBox
                        inputLabel="Polish"
                        name="Polish"
                        value={state.Polish ? state.Polish.translationText : ""}
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Romanian</FormLabel>
                    <TextBox
                        inputLabel="Romanian"
                        name="Romanian"
                        value={state.Romanian ? state.Romanian.translationText : ""}
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Hindi</FormLabel>
                    <TextBox
                        inputLabel="Hindi"
                        name="Hindi"
                        value={state.Hindi ? state.Hindi.translationText : ""}
                        onChange={handleFieldChange}
                    />
                </Grid>

                <Grid item xs={12} className="submit_btn">
                    <SecondaryButton
                        style={{ width: '100%' }}
                        onClick={() => handleSubmit()}
                        disabled={state.disableSubmit}
                    >
                        {state.editMode ? "Save" : "Add"}
                    </SecondaryButton>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '-40px' }}>
                    <DataGridComp
                        columns={columns}
                        rows={state.rows}
                        headerHeight={40}
                        rowHeight={40}
                        isRowHovered={true}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
export default Translations