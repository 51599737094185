import React, { useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { Grid, Switch } from '@material-ui/core';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import CircularIndeterminate from '../ReportsScreens/ReportsScreens/BudgetCore/loader';
import { getWSLStatus } from '../../../Core/Service/workflowJobs-service';
import moment from 'moment';
import '../dashboard/dashboard.scss';
import ReactDOM from 'react-dom';
import ClientSideDataRender from '../ReportsScreens/GijgoForReportsScreen/ClientSideGridData';
import { useHistory } from 'react-router-dom';

const baseColumns = [
    { field: 'wipNumber', sortable: true, title: 'WIP No', width: 90, filterable: true },
    { field: 'regNo', sortable: true, title: 'Vehicle Reg', width: 110 },
    { field: 'makeModel', sortable: true, title: 'Model', width: 200 },
    { field: 'cust', sortable: true, title: 'Customer Name', width: 170 },
    {
        field: 'dateDueIn',
        sortable: true,
        title: 'Due Date In',
        width: 145
    },
    {
        field: 'dateDueOut',
        sortable: true,
        title: 'Date Due Out',
        width: 145
    },
    // { field: 'rr', sortable: true, title: 'Return Reason', width: 190 },
    // { field: 'chassisNumber', sortable: true, title: 'Chassis Number', width: 180 },
    { field: 'bn', sortable: true, title: 'Branch', width: 120 },
    { field: 'fn', sortable: true, title: 'Franchise', width: 160 },
    { field: 'st', sortable: true, title: 'Job Description', width: 170 },
    {
        field: 'taken',
        sortable: true,
        title: 'Time Taken',
        width: 120
    },
    {
        field: 'status',
        sortable: true,
        title: 'Status',
        width: 100
    },
    // {
    //     field: 'assignedDays',
    //     sortable: true,
    //     title: 'Assigned Days',
    //     width: 130
    // },
    // {
    //     field: 'owningOperator',
    //     sortable: true,
    //     title: 'Owning Operator',
    //     width: 160
    // },
    // {
    //     field: 'jobAssignedUser',
    //     sortable: true,
    //     title: 'Assigned User',
    //     width: 150
    // },
    // {
    //     field: 'courtesyCarReq',
    //     sortable: true,
    //     title: 'Loan Vehicle',
    //     width: 140
    // },
    // {
    //     field: 'customerWaiting',
    //     sortable: true,
    //     title: 'Customer Waiting',
    //     width: 140
    // },
    {
        field: 'wipAge',
        sortable: true,
        title: 'WIP Age',
        width: 80
    }
    // {
    //     field: 'isFMOrWarranty',
    //     sortable: true,
    //     title: 'Is FM Or Warranty',
    //     width: 80
    // },
    // {
    //     field: 'isBreakdown',
    //     sortable: true,
    //     title: 'Is Breakdown',
    //     width: 80
    // },
    // {
    //     field: 'isNightShift',
    //     sortable: true,
    //     title: 'Is Night Shift',
    //     width: 80
    // }
];

const WorkshopLoadingStatusCode = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let Branch = params.get('bn') || '';
    let fn = params.get('fn');
    let status = params.get('status');

    const history = useHistory();

    const [state, setState] = useState({
        list: [],
        id: null,
        isReload: new Date(),
        showLoader: true
    });

    const wipField = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" spacing={1}>
                <Grid item style={{ cursor: 'pointer' }} onClick={() => history.push(`/Job/${record.headerRecordID}/${record.workflowProcessId}`)}>
                    <b>{value}</b>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    // const columns = useMemo(() => {
    //     const tempCols = [...baseColumns];
    //     let wipCol = tempCols.find((m) => m.field === 'wipNumber');
    //     if (wipCol) {
    //         wipCol.renderer = wipField;
    //     }
    //     return tempCols;
    // }, []);
    let columns = baseColumns.map((c) => {
        if (c.title !== 'Action') {
            return {
                ...c,
                events: {
                    click: function (e) {
                        history.push(`/Job/${e.data.record.headerRecordID}/${e.data.record.workflowProcessId}`);
                    }
                },
                renderer: function (value, record, $cell, $displayEl, id, $grid) {
                    // Applying inline style for pointer
                    $cell.css('cursor', 'pointer');
                    return value; // Render the default value
                }
            };
        }
        return c;
    });

    useEffect(async () => {
        getFilterData();
    }, []);

    const getFilterData = async (value) => {
        setState((st) => ({ ...st, showLoader: true }));
        let res = await getWSLStatus(Branch, fn, status);
        if (res.success) {
            setState((st) => ({
                ...st,
                list: res?.data?.table || [],
                isReload: new Date(),
                showLoader: false
            }));
        }
    };

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Workshop Loading', active: true }
    ];

    return (
        <div className="report-screen-container custom-scroll">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <BreadCrumbs crumbs={crumbs} />
            </div>

            <Grid container>
                {state.showLoader ? (
                    <CircularIndeterminate />
                ) : (
                    <Grid item xs={12}>
                        <ClientSideDataRender
                            dataConverter={(res) => [...state.list]}
                            columns={columns}
                            displayPagination={true}
                            //  detailTemplate={detailTemplate}
                            isReload={state.isReload}
                            renderClientSideData={true}
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};
export default WorkshopLoadingStatusCode;
