import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    Radio,
    RadioGroup,
    Typography,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { TextBox } from '../../../../Core/FormInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { DescriptionIcon } from '@material-ui/icons/Description';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SecondaryBatteryModal from '../../../technician/BatteryTest/SecondaryBatteryModal';
import { SecondaryButton } from '../../../../Core/FormInput/AppButton';
import moment, { relativeTimeRounding } from 'moment';
import { FieldWrappper } from '../../../../Core/FieldWrapper';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.action.hover
        //     color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700
    }
});

const WorkRequestedModal = (props) => {
    const { work, setWork, control } = props;

    const classes = useStyles();

    const addWorkRow = () => {
        setWork((st) => [
            ...st,
            {
                service24WorkID: null,
                service24WorkRequested: '',
                service24WorkReason: '',
                service24WorkAction: '',
                service24WorkTimeTaken: null
            }
        ]);
    };

    const removeWorkAt = (indx) => {
        setWork((st) => {
            const newSt = [...st];
            newSt.splice(indx, 1);
            return newSt;
        });
    };

    const handlwork = (index) => (e) => {
        let { name, value } = e.target;
        setWork((st) => {
            let newSt = [...st];
            let findIndex = [...st].find((n, i) => i === index);
            if (findIndex) {
                findIndex[name] = value;
            }
            return newSt;
        });
    };

    const totalRepair = moment(control.service24RepairEndTime).diff(control.service24RepairStartTime, 'minute');
    let repairHours = Math.floor(totalRepair / 60);
    let repairMinutes = totalRepair % 60;
    const TotalRepairTime = control.service24RepairEndTime && totalRepair > 0 ? `${repairHours} hours ${repairMinutes} minutes` : totalRepair < 0 ? '' : null;

    const Traveltimestart = moment(control.service24RepairStartTime).diff(control.service24DrivingStartTime, 'minute');
    const TraveltimeEnd = moment(control.service24DrivingEndTime).diff(control.service24RepairEndTime, 'minute');

    let TravelDiff = Traveltimestart + TraveltimeEnd;
    let travelHours = Math.floor(TravelDiff / 60);
    let travelMinutes = TravelDiff % 60;

    const TotalTravelTime = control.service24DrivingEndTime && TravelDiff > 0 ? `${travelHours} hours ${travelMinutes} minutes` : TravelDiff < 0 ? '' : null;

    const TechTime = totalRepair + TravelDiff;

    let techHH = Math.floor(TechTime / 60);
    let techMM = TechTime % 60;

    const TotaltechTime = TotalTravelTime && TechTime > 0 ? `${techHH} hours ${techMM} minutes` : TravelDiff < 0 ? '' : null;

    const TotalMileage = control.service24DrivingEndTime && control.service24DrivingFinishMileage - control.service24DrivingStartMileage;

    return (
        <div style={{ width: '100%' }}>
            <Grid container spacing={1}>
                {work.map((row, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Grid item xs={12}>
                                <div style={{ float: 'right' }}>
                                    {index !== 0 && (
                                        <RemoveCircleIcon
                                            style={{ color: 'red', cursor: 'pointer' }}
                                            fontSize="medium"
                                            onClick={() => {
                                                removeWorkAt(index);
                                            }}
                                        />
                                    )}
                                    &nbsp;
                                    {index === work.length - 1 && (
                                        <AddBoxIcon style={{ color: 'green', cursor: 'pointer' }} fontSize="medium" onClick={addWorkRow} />
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <InputLabel shrink>Work Requested</InputLabel>
                                <TextBox
                                    name="service24WorkRequested"
                                    value={row.service24WorkRequested}
                                    placeholder="Work Requested"
                                    onChange={handlwork(index)}
                                    inputProps={{ maxLength: 256 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <InputLabel shrink>Reason/Cause</InputLabel>
                                <TextBox
                                    name="service24WorkReason"
                                    value={row.service24WorkReason}
                                    placeholder="Reason/Cause"
                                    onChange={handlwork(index)}
                                    inputProps={{ maxLength: 256 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <InputLabel shrink>Corrective Action</InputLabel>
                                <TextBox
                                    name="service24WorkAction"
                                    value={row.service24WorkAction}
                                    placeholder="Corrective Action"
                                    onChange={handlwork(index)}
                                    inputProps={{ maxLength: 256 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <InputLabel shrink>Time Taken</InputLabel>
                                <TextBox
                                    name="service24WorkTimeTaken"
                                    type="number"
                                    value={row.service24WorkTimeTaken}
                                    placeholder="Time Taken"
                                    onChange={handlwork(index)}
                                />
                            </Grid>
                            {index != work.length - 1 && (
                                <Grid item xs={12}>
                                    <hr />
                                </Grid>
                            )}
                        </React.Fragment>
                    );
                })}
            </Grid>
        </div>
    );
};

export default WorkRequestedModal;
