import { FormLabel, Grid, InputLabel } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { SelectBox, SingelSelect } from '../../../../../Core/FormInput';
import { getReportsDropdowns, GetShiftCalender, GetShiftCalenderScreenDropdowns } from '../../../../../Core/Service/reportService';

function padLeft(nr, n, str) {
    return Array(n - String(nr).length + 1).join(str || '0') + nr;
}
const months = moment.months();

const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: 'Workshop', active: true },
    { name: 'Shift Calendar', active: true }
];

const colorConfig = {
    0: { color: '#dc3545', name: 'Dormant' },
    3: { color: '#28a745', name: 'Active' },
    2: { color: '#E829E8', name: 'PM commence' },
    1: { color: '#33FFFF', name: 'AM continue' }
};

const ShiftCalenderComp = () => {
    const [state, setState] = useState({
        data: {},
        branchList: [],
        shiftList: [],
        yearList: [],
        branchID: { id: '', name: '' },
        shiftID: { id: '', name: '' },
        yearID: { id: '', name: '' },
        screenMsg: 'Please Select The Branch'
    });
    const [calender, setCalender] = useState([]);

    useEffect(async () => {
        let DropDownRes = await getReportsDropdowns();
        if (DropDownRes.success) {
            setState((st) => ({
                ...st,
                branchList: DropDownRes.data.branch
            }));
        }
    }, []);

    useEffect(async () => {
        if (state.branchID?.id) {
            let DropDownRes = await GetShiftCalenderScreenDropdowns(state.branchID.id);
            if (DropDownRes.success) {
                setState((st) => ({
                    ...st,
                    shiftList: DropDownRes?.data?.code?.map((m) => ({ id: m?.code, name: m?.description })),
                    yearList: DropDownRes?.data?.year?.map((m) => ({ id: m?.year, name: `${m?.year}` })),
                    screenMsg: 'Please Select Shift and Year'
                }));
            }
        }
    }, [state.branchID?.id]);

    useEffect(async () => {
        if (state.branchID?.id && state.shiftID?.id && state.yearID?.id) {
            const res = await GetShiftCalender(state.branchID.id, state.shiftID.id, state.yearID.id);
            let data = res.data.list.length > 0 ? res.data.list[0] : {};

            setState((st) => ({
                ...st,
                data: { ...data },
                screenMsg: res.data.list.length > 0 ? '' : 'No Record Found'
            }));
        }
    }, [state.branchID, state.shiftID, state.yearID]);

    useEffect(async () => {
        let localDaysCounter = 0;
        if (!_.isEmpty(state.data)) {
            var finalCalendar = [];

            months.forEach((month, monthIndex) => {
                var tempMonthData = [];

                const weekStartDate = moment([state.yearID.id, monthIndex]).clone().startOf('month').startOf('isoWeek');
                const monthStartDate = moment([state.yearID.id, monthIndex]).clone().startOf('month').subtract(1, 'd');

                const endDate = moment([state.yearID.id, monthIndex]).clone().endOf('month');

                const day = weekStartDate.clone().subtract(1, 'day');

                while (day.isBefore(endDate, 'day')) {
                    tempMonthData.push(
                        Array(7)
                            .fill(0)
                            .map(() => {
                                day.add(1, 'day');
                                if (day.isAfter(monthStartDate, 'day') && day.isSameOrBefore(endDate, 'day')) {
                                    localDaysCounter += 1;
                                    return {
                                        day: day.clone().format('DD'),
                                        code: state.data[`active${padLeft(localDaysCounter, 3)}`]
                                    };
                                } else {
                                    return null;
                                }
                            })
                    );
                }
                finalCalendar.push({ monthName: month, monthData: tempMonthData });
            });
            setCalender(finalCalendar);
        } else {
            setCalender([]);
        }
    }, [state.data]);

    const fieldChange = (e) => {
        const { name, value } = e.target;
        if (name == 'branchID') {
            setCalender([]);
        }
        setState((st) => {
            const nst = { ...st, [name]: value };

            if (name == 'branchID') {
                nst.shiftID = '';
                nst.yearID = '';
                nst.screenMsg = 'Please Select Shift and Year';
            }

            return nst;
        });
    };

    const singleSelectChange = (nm) => (ev, val) => {
        if (nm == 'branchID') {
            setCalender([]);
        }
        setState((st) => {
            let newSt = { ...st, [nm]: val };
            if (nm == 'branchID') {
                newSt.shiftID = { id: '', name: '' };
                newSt.yearID = { id: '', name: '' };
                newSt.screenMsg = 'Please Select Shift and Year';
            }
            return newSt;
        });
    };

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs()} />
            <Grid container spacing={2} justifyContent="center" style={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} sm={6} md={3} xl={2}>
                            <FormLabel component="legend" style={{ marginBottom: '8px' }}>
                                Branch
                            </FormLabel>
                            <SelectBox List={state.branchList} name="branchID" value={+state.branchID} onChange={fieldChange} insertEmpty={false} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} xl={2}>
                            <FormLabel component="legend" style={{ marginBottom: '8px' }}>
                                Shift
                            </FormLabel>
                            <SelectBox List={state.shiftList} name="shiftID" value={state.shiftID} onChange={fieldChange} insertEmpty={false} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} xl={2}>
                            <FormLabel component="legend" style={{ marginBottom: '8px' }}>
                                Year
                            </FormLabel>
                            <SelectBox List={state.yearList} name="yearID" value={state.yearID} onChange={fieldChange} insertEmpty={false} />
                        </Grid> */}

                        <Grid item xs={12} sm={6} md={3} xl={2} className="mandatory-fields">
                            <InputLabel required shrink>
                                Branch
                            </InputLabel>
                            <SingelSelect disableClearable options={state.branchList || []} value={state.branchID} onChange={singleSelectChange('branchID')} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} xl={2} className="mandatory-fields">
                            <InputLabel required shrink>
                                Shift
                            </InputLabel>
                            <SingelSelect disableClearable options={state.shiftList || []} value={state.shiftID} onChange={singleSelectChange('shiftID')} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} xl={2} className="mandatory-fields">
                            <InputLabel required shrink>
                                Year
                            </InputLabel>
                            <SingelSelect disableClearable options={state.yearList || []} value={state.yearID} onChange={singleSelectChange('yearID')} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {Object.keys(colorConfig).map(function (key, i) {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{ margin: 0 }} key={i}>
                                    <div style={{ backgroundColor: colorConfig[key].color, padding: 10 }}>{colorConfig[key].name}</div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {calender.length > 0 ? (
                        <Grid container spacing={2}>
                            {calender.map((a, i) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={i}>
                                    <table
                                        style={{
                                            width: '100%',
                                            border: '1px solid rgba(0,0,0,0.4)',
                                            borderCollapse: 'collapse'
                                        }}
                                    >
                                        <tr>
                                            <th colSpan={7} style={{ padding: '10px', backgroundColor: '#555555' }}>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',

                                                        color: 'white',
                                                        fontSize: '16px'
                                                    }}
                                                >
                                                    {a.monthName}
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Mon</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Tue</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Wed</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Thu</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Fri</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Sat</th>
                                            <th style={{ background: '#333333', color: 'white', border: 'none', padding: '5px' }}>Sun</th>
                                        </tr>
                                        {a.monthData.map((p) => (
                                            <tr>
                                                {p.map((q) => (
                                                    <td
                                                        style={{
                                                            padding: '5px',
                                                            border: '1px solid white',
                                                            backgroundColor: q !== null ? colorConfig[q.code].color : '#D3D3D3'
                                                        }}
                                                    >
                                                        <span>{q !== null ? q.day : ''}</span>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </table>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} xl={8}>
                                {state.screenMsg}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default ShiftCalenderComp;
