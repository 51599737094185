import { FormLabel, Grid, MenuItem } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox } from '../../../Core/FormInput';
import { DefaultButton } from '../../../Core/FormInput';
import { SecondaryButton } from '../../../Core/FormInput';
import { addUpdateVorType } from '../../../Core/Service/vor-service';

const AddEditVorType = (props) => {
    const [state, setState] = useState({
        errors: {},

        vorTypeDescription: props.focusedRow?.vorTypeDescription ? props.focusedRow.vorTypeDescription : '',
        vorTypeCode: props.focusedRow?.vorTypeCode ? props.focusedRow.vorTypeCode : '',
        vorTypeID: props.focusedRow?.vorTypeID ? props.focusedRow.vorTypeID : null
    });

    const { showToast } = useContext(AppContext);

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        setState((st) => ({
            ...st,
            [name]: val
        }));
    };

    const validations = () => {
        const { vorTypeDescription, vorTypeCode } = state;
        let formIsValid = true;
        let errors = {};

        if (vorTypeDescription == null || vorTypeDescription == '') {
            errors.vorTypeDescription = 'Description field is required';
            formIsValid = false;
        }

        if (vorTypeCode == null || vorTypeCode == '') {
            errors.vorTypeCode = 'Code field is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            const data = {
                vorTypeDescription: state.vorTypeDescription,
                vorTypeCode: state.vorTypeCode,
                vorTypeID: state.vorTypeID
            };
            let res = await addUpdateVorType(data);
            if (!res.success) {
                console.error(res);
                showToast('Error occured.');
            } else {
                showToast('Vor type updated successfully');
                props.onClose();
            }
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        Description
                    </FormLabel>
                    <TextBox value={state.vorTypeDescription} name="vorTypeDescription" onChange={handleFieldChange} />
                    <span className="mandatoryfields">{state.errors['vorTypeDescription']}</span>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        Code
                    </FormLabel>
                    <TextBox value={state.vorTypeCode} name="vorTypeCode" onChange={handleFieldChange} />
                    <span className="mandatoryfields">{state.errors['vorTypeCode']}</span>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};
export default AddEditVorType;
