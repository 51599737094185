import React, { useEffect, useState } from 'react';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { getVideos, getVideoCategories } from '../../../Core/Service/video-service';
import AppContext from '../../../App/AppContext';
import { useContext } from 'react';
import VideosModal from './videosModal';

const Videos = (props) => {
    const [state, setState] = useState({
        videoCategories: [],
        focusedVideo: {},
        show: false,
        showLoader: true,
    });

    const { userRoles } = useContext(AppContext);

    useEffect(async () => {
        pullVideos();
    }, []);

    const getThumbnail = (video) => {
        if (video.videoThumbnail) {
            return video.videoThumbnail?.includes('base64') ? video.videoThumbnail : 'data:image/png;base64,' + video.videoThumbnail;
        }
        const URL = video.videoURL;
        if (URL.includes('/embed/')) {
            return 'http://i3.ytimg.com/vi/' + URL.split('/embed/')[1] + '/maxresdefault.jpg';
        }
        if (URL.includes('?v=')) {
            return 'http://i3.ytimg.com/vi/' + URL.split('?v=')[1] + '/maxresdefault.jpg';
        }
    };

    const pullVideos = async () => {
        let categoriesRes = await getVideoCategories();
        let videosRes = await getVideos();
        let videos = [];

        if (categoriesRes.success && videosRes.success) {
            categoriesRes.data.list.map((row) => {
                if (row.roles.includes(userRoles)) {
                    videos.push({ id: row.videoCategoryID, description: row.videoCategoryDescription, videos: [] });
                }
            });

            videosRes.data.list.map((row) => {
                videos.map((roleCat) => {
                    if (row.videoCategoryID == roleCat.id) {
                        roleCat.videos.push(row);
                    }
                });
            });

            setState((st) => ({
                ...st,
                videoCategories: videos,
                showLoader: false,
            }));
        }
    };

    const handleVideoClick = (video) => {
        setState((st) => ({
            ...st,
            focusedVideo: video,
            show: true
        }));
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            show: false
        }));
    };

    return (
        <div style={{margin:'10px 20px'}}>
            {state.show ? (
                <>
                    <VideosModal closeFunc={handleClose} videoDetails={state.focusedVideo} />
                </>
            ) : (
                <>
                    <Breadcrumbs separator=">>" aria-label="breadcrumb">
                        <Link color="inherit" to={'/'}>
                            Home
                        </Link>
                        <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                            Videos
                        </Typography>
                    </Breadcrumbs>
                    {state.showLoader && (
                        <Typography variant="h5">
                            Loading Videos
                        </Typography>
                     )}
                    <Grid container spacing={3} style={{ overflow: 'auto', height: '100%' }}>
                        {state.videoCategories.map((videoCat) => {
                            return (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            textAlign: 'left',
                                        }}
                                    >
                                        <Typography variant="h4">
                                            {videoCat.description}
                                        </Typography>
                                    </Grid>

                                    {videoCat.videos.length > 0 ? (
                                        videoCat.videos.map((video) => {
                                            return (
                                                <Grid
                                                    xs={12}
                                                    sm={3}
                                                    md={3}
                                                    lg={2}
                                                    item

                                                    onClick={() => handleVideoClick(video)}
                                                >
                                                    <img
                                                        //src={getThumbnailURL(video.videoURL)}
                                                        src={getThumbnail(video)}
                                                        alt={video.videoTitle}
                                                       
                                                            width="100%" height="150px"
                                                       
                                                    />
                                                    <Typography>{video.videoTitle}</Typography>
                                                </Grid>
                                            );
                                        })
                                    ) : (
                                        <Typography variant="h6"style={{height:200}} >
                                            There are no videos to display for this category.
                                        </Typography>
                                    )}
                                </>
                            );
                        })}
                    </Grid>
                </>
            )}
        </div>
    );
};
export default Videos;
