import React from 'react';
import { TextBox, SecondaryButton } from '../../FormInput';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, Grid } from '@material-ui/core';

export default function ConfirmationModal(props) {
    const [value, setValue] = React.useState({});

    return (
        <Grid container spacing={0}>
            {/* <Grid item xs={12}> */}
            <Typography component="legend">{props.message}</Typography>
            {/* </Grid> */}
            <br />
            <Grid item container xs={12} spacing={1} style={{ marginTop: 16 }}>
                {props.handleSubmit && (
                    <Grid item xs={props.handleCancel ? 6 : 12}>
                        {props.isLoad ? (
                            <SecondaryButton fullWidth className="submit_btn">
                                <CircularProgress size={25} />
                            </SecondaryButton>
                        ) : (
                            <SecondaryButton onClick={props.handleSubmit} fullWidth className="submit_btn">
                                Agree
                            </SecondaryButton>
                        )}
                    </Grid>
                )}
                {props.handleCancel && (
                    <Grid item xs={props.handleSubmit ? 6 : 12}>
                        <SecondaryButton onClick={props.handleCancel} fullWidth className="submit_btn">
                            Cancel
                        </SecondaryButton>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
