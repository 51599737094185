import React from 'react';
import Barcode from 'react-barcode';

export default function ShowBarCode(props) {
    return (
        <div className="App">
            <Barcode value={props.wipNo} />
        </div>
    );
}
