import React from 'react';
import moment from 'moment';
import { Grid, FormLabel, Breadcrumbs, Unstable_TrapFocus, InputLabel } from '@material-ui/core';
import { SingelSelect, TextBox } from '../../../../Core/FormInput';
import NestedGijgoGrid from '../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { useState } from 'react';
import { getSalesVehicleDropdown } from '../../../../Core/Service/salesDasboard-service';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import JobNotesModal from '../../dashboard/NewSalesDashboard/WorkFlow/JobNotes';
import { useMemo } from 'react';

var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let addCommas = (val) => {
    return val ? `${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '0.00';
};

let startDate = moment().startOf('month').format('YYYY-MM-DD');
let endDate = moment().format('YYYY-MM-DD');

const baseColumns = [
    { title: 'Sold By', field: 'sp', width: 100, sortable: true },
    { title: 'VSB', field: 'vsb', width: 70, sortable: true },
    { title: 'Customer', field: 'cust', width: 100, sortable: true },
    { title: 'Branch', field: 'loc', width: 90, sortable: true },
    {
        title: 'Reg Number',
        field: 'reg',
        width: 80,
        sortable: true
    },
    { title: 'Model', field: 'model', width: 90, sortable: true },
    {
        title: 'Invoice Date',
        field: 'id',
        width: 90,
        sortable: true,
        type: 'date',
        format: 'dd-mmm-yyyy',
        cssClass: 'text-center',
        renderer: function (value, record) {
            if (record.id != null) {
                let date = new Date(record.id);
                return date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear();
            }
            return '';
        }
    },
    {
        title: 'Total Sale',
        field: 'ts',
        width: 90,
        sortable: true,
        renderer: function (value, record) {
            return '<span style="cursor:pointer" title="' + record.ts + '"  >£' + addCommas(record.ts) + '</span>';
        },
        align: 'right'
    },
    {
        title: 'Total Cost',
        field: 'tc',
        width: 70,
        sortable: true,
        renderer: function (value, record) {
            return '<span style="cursor:pointer" title="' + record.tc + '"  >£' + addCommas(record.tc) + '</span>';
        },
        align: 'right'
    },
    {
        title: 'Gross Profit',
        field: 'gp',
        width: 70,
        sortable: true,
        renderer: function (value, record) {
            return '<span style="cursor:pointer" title="' + record.gpp + '"  >£' + addCommas(record.gp) + '</span>';
        },
        align: 'right'
    }
];

const detailTemplate = () => {
    return `<div style='width:100%'>
        <table style='width:100%;'>
            <tr style='background-color:#DCDCDC;' >
                
                <th style='font-weight:750; text-align:left;'>Order Number</th>
                <th style='font-weight:750; text-align:left;'>Sale Price</th>
                <th style='font-weight:750; text-align:left;'>Cost Price</th>
                <th style='font-weight:750; text-align:left;'>Disc O/A</th>
                <th style='font-weight:750; text-align:left;'>Bonuses</th>
                <th style='font-weight:750; text-align:left;'>Subsidy</th>
                <th style='font-weight:750; text-align:left;'>CPI</th>
                <th style='font-weight:750; text-align:left;'>Finance</th>
                <th style='font-weight:750; text-align:left;'>PDI</th>
                <th style=' font-weight:750; text-align:left;'>DFA Sale</th>
                <th style='text-align:left;font-weight:750;'>DFA Cost</th>
                <th style='text-align:left;font-weight:750;'>Intro Comm</th>
                <th style='width:20%; font-weight:750; text-align:left;'>Finance Company</th>
                <th style='font-weight:750; text-align:left;'>Shipment Number</th>
            </tr>
            <tr>
                
                <td>{ord}</td>
                <td>£{sale}</td>
                <td>£{cost}</td>
                <td>£{discOval}</td>
                <td>£{bonuses}</td>
                <td>£{subsidy}</td>
                <td>£{cpi}</td>
                <td>£{finance}</td>
                <td>£{pdi}</td>
                <td>£{dfaSale}</td>
                <td>£{dfaCost}</td>
                <td>£{introCom}</td>
                <td>{fcompany}</td>
                <td>{ship}</td>
            </tr>
        </table>
        </div>`;
};

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'New Vehicle Profit Report', path: '/', active: true }
];

const NewVehicleProfitReport = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let PramCategoryType = params.get('CategoryType') || '';
    let startDateParams = params.get('start');
    let endDateParams = params.get('end');
    let isElectric = params.get('isElectric') || '';

    const [state, setState] = useState({
        categoriesList: [],
        categoriesId: {},
        modelAnalysisCodeList: [],
        modelAnalysisCodeId: {},
        startDate: startDateParams || moment().startOf('month').format('YYYY-MM-DD'),
        endDate: endDateParams || moment().format('YYYY-MM-DD'),
        handleVehicleNotes: false,
        records: {},
        showNotes: false
    });

    useEffect(async () => {
        let res = await getSalesVehicleDropdown();

        if (res.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.categoriesList = res.data?.category || [];
                newSt.modelAnalysisCodeList = res.data?.modelAnalysisCode || [];
                if (PramCategoryType) {
                    newSt.categoriesId = res.data?.category.find((r) => r.id == PramCategoryType);
                }
                return newSt;
            });
        }
    }, []);

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st, [nm]: val };
            return newSt;
        });
    };

    const baseUrl = `Sales/NewVehicleProfitReport?StartDate=${state.startDate}&EndDate=${state.endDate}&VehicleCategoryID=${state.categoriesId?.id || ''}&mac=${
        state.modelAnalysisCodeId?.id || ''
    }&IsElectric=${isElectric}`;

    const HandelNotesModal = (value = {}) => {
        setState((st) => ({
            ...st,
            handleVehicleNotes: !st.handleVehicleNotes,
            records: { sn: value.vsb, vd: value.v },
            selectedStockID: value?.newStockID || null
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <div
                // style={{ cursor: 'pointer', fontWeight: 600 }} onClick={() => HandelNotesModal(record)}
                >
                    {value}
                </div>
                {/* <EditIcon fontSize="small" style={{ color: 'white' }} /> */}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];

        let StockCol = tempCols.find((k) => k.field === 'vsb');
        if (StockCol) {
            StockCol.renderer = editButton;
        }

        return tempCols;
    }, []);

    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <Grid container style={{ padding: '0px 10px' }}>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={6} sm={6} md={3}>
                        <InputLabel className="mandatoryfields" shrink>
                            Start Date
                        </InputLabel>

                        <TextBox
                            placeholder="Start Date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={handleinput}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <InputLabel className="mandatoryfields" shrink>
                            End Date
                        </InputLabel>

                        <TextBox
                            placeholder="End Date"
                            name="endDate"
                            fullWidth
                            type="date"
                            value={state.endDate}
                            onChange={handleinput}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <InputLabel shrink>Vehicle Categories</InputLabel>
                        <SingelSelect
                            options={state.categoriesList || []}
                            value={state.categoriesId}
                            onChange={singleSelectChange('categoriesId')}
                            // disabled={state.isReadOnly}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <InputLabel shrink>Model</InputLabel>
                        <SingelSelect
                            options={state.modelAnalysisCodeList || []}
                            value={state.modelAnalysisCodeId}
                            onChange={singleSelectChange('modelAnalysisCodeId')}
                            // disabled={state.isReadOnly}
                        />
                    </Grid>
                </Grid>
                {state.handleVehicleNotes && (
                    <JobNotesModal
                        title={`Vehicle Notes -${state.records.sn}`}
                        stockID={state.selectedStockID}
                        onClose={HandelNotesModal}
                        onNotesAdded={HandelNotesModal}
                    />
                )}

                <Grid container>
                    <NestedGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list.map((n) => ({
                                ...n,
                                sale: n.sale || 0,
                                cost: n.cost || 0,
                                discOval: n.discOval || 0,
                                bonuses: n.bonuses || 0,
                                subsidy: n.subsidy || 0,
                                cpi: n.cpi || 0,
                                finance: n.finance || 0,
                                pdi: n.pdi || 0
                            })),
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        displayPagination={true}
                        detailTemplate={detailTemplate}
                        downloadName={'New Vehicle Profit Report'}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default NewVehicleProfitReport;
