import React, { useContext, useState, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextBox } from '../../../Core/FormInput';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import '../User Management/UserManagement.css';
import { getProcesses, getWorkFlowList } from '../../../Core/Service/process-service';
const SetWorkflowNotification = (props) => {
    const { workflowNotificationList, handelworkflowNotification } = props;

    return (
        <Grid container spacing={2}>
            {workflowNotificationList.length > 0
                ? workflowNotificationList.map((m) => (
                      <React.Fragment>
                          <Grid item lg={6}>
                              {m.branchShortName}
                              <TextBox
                                  name="workflowNotificationEmail"
                                  placeholder="Email"
                                  value={m.workflowNotificationEmail}
                                  onChange={handelworkflowNotification(m.workflowNotificationBranchID)}
                              />
                          </Grid>
                      </React.Fragment>
                  ))
                : null}
        </Grid>
    );
};

export default SetWorkflowNotification;
