import { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, NativeSelect, Radio, RadioGroup, TextField } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppContext from '../../../../App/AppContext';
import { SelectBox } from '../../../../Core/FormInput';
import { CustomeSignPad } from '../../../../Core/SignaturePad';

const useStyles = makeStyles((theme) => ({
    appSelectBtnGrp: {
        // marginRight: 4,
        '& button': {
            textTransform: 'unset',
            wordWrap: 'break-word',
            '&.Mui-selected': {
                color: (props) => `${props.foregroundColour || 'white'}`,
                backgroundColor: (props) => `${props.backgroundColour || 'rgb(83, 175, 80)'}`
            },
            '&.Mui-selected:hover': {
                color: (props) => `${props.foregroundColour || 'white'}`,
                backgroundColor: (props) => `${props.backgroundColour || 'rgb(83, 175, 80)'}`
            },
            '&.MuiToggleButton-sizeSmall': {
                // padding: '4px'
            },
            '&.MuiToggleButton-root:hover': {
                backgroundColor: 'lightgray'
            }
        }
    }
}));

const DynamicAppSelect = (props) => {
    const { portalSettings } = useContext(AppContext);

    const classes = useStyles(props.options.find((m) => m[props.valuePropertyName] === +props.value));

    const ConvertOps = (ops) => {
        return ops.map((op) => {
            return {
                value: `${op[props.valuePropertyName || 'value']}`,
                text: `${op[props.textPropertyName || 'text']}`
            };
        });
    };

    const [state, setState] = useState({
        value: `${props.value}`,
        options: ConvertOps(props.options) || []
    });

    useEffect(() => {
        setState((st) => ({ ...st, value: props.value }));
    }, [props.value]);

    useEffect(() => {
        props.options && setState((st) => ({ ...st, options: ConvertOps(props.options) }));
    }, [props.options]);

    const handleChange = (e) => {
        const val = e.target.value;
        setState((st) => ({ ...st, value: val }));
        props.onChange && props.onChange({ [props.name]: val });
    };

    const clickBtnGrup = (e, val) => {
        if (!props.disabled) {
            setState((st) => ({ ...st, value: val }));
            props.onChange && props.onChange({ [props.name]: val });
        }
    };
    const checkBoxOnchange = (value) => (e) => {
        const val = e.target.checked;
        let temp = Array.isArray(state.value) ? [...state.value] : [];
        if (val) {
            temp.push(value);
        } else {
            const index = temp.indexOf(value);
            if (index > -1) {
                temp.splice(index, 1);
            }
        }
        setState((st) => ({ ...st, value: temp }));
        props.onChange && props.onChange({ [props.name]: temp });
    };

    const isValueChecked = (val) => {
        let tempArray = state.value || [];
        let isvalue = tempArray.includes(+val);
        return isvalue;
    };

    if (props.displayType == 1) {
        return (
            <ToggleButtonGroup className={classes.appSelectBtnGrp} size="small" value={state.value} onChange={clickBtnGrup} exclusive>
                {state.options.map((op, i) => (
                    <ToggleButton key={i} value={op.value}>
                        {op.text}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        );
    } else if (props.displayType == 4) {
        return (
            <TextField margin="dense" select size="small" variant="outlined" value={state.value} onChange={handleChange} fullWidth>
                {state.options.length > 0 &&
                    state.options.map((op, i) => (
                        <MenuItem key={i} value={op.value || ''}>
                            {op.text}
                        </MenuItem>
                    ))}
            </TextField>
        );
    } else if (props.displayType == 2) {
        return (
            <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                    {state.options.map((op, i) => (
                        <FormControlLabel
                            control={<Checkbox checked={isValueChecked(+op.value)} onChange={checkBoxOnchange(+op.value)} name="gilad" />}
                            label={op.text}
                        />
                    ))}
                </FormGroup>
            </FormControl>
        );
    } else if (props.displayType == 3) {
        return (
            <RadioGroup aria-label={props.name} name={props.name} value={state.value} onChange={handleChange}>
                {state.options.map((op) => {
                    return (
                        <Grid item>
                            <FormControlLabel value={op.value} control={<Radio />} label={op.text} />
                        </Grid>
                    );
                })}
            </RadioGroup>
        );
    }
};

export default DynamicAppSelect;
