import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, FormLabel, Grid } from '@material-ui/core';
import moment from 'moment';
import { PrimaryButton, SecondaryButton, SingelSelect, TextBox } from '../../../Core/FormInput';
import { searchJobByBranchAndText } from '../../../Core/Service/service24-service';
import { getWorkflowsByProcessID } from '../../../Core/Service/workflow-service';

const MatchingJobRow = ({ job, onJobSelect }) => {
    console.log(job, 'MatchingJobRow job');
    return (
        <tr className={`row${job.isSelected ? ' is-selected-job' : ''}`}>
            <td className="is-selected">
                <Checkbox
                    checked={job.isSelected}
                    color={job.isSelected ? 'primary' : ''}
                    onChange={(e) => onJobSelect(e.target.checked, job.headerRecordID)}
                />
            </td>
            <td className="col-wip">{job.wipNumber}</td>
            <td className="col-reg">{job.registration}</td>
            <td className="col-cust">{job.customerName}</td>
            <td className="col-due-in">{job.dateDueIn ? moment(job.dateDueIn).format('LLL') : ' - '}</td>
            <td className="col-work">{job.workflow}</td>
        </tr>
    );
};

const MatchingJobsTable = ({ jobs, loading, onJobSelect }) => {
    return (
        <>
            <div className="body-table-container custom-scroll">
                <table className="job-search-header">
                    <thead>
                        <tr className="header">
                            <th className="is-selected"></th>
                            <th className="col-wip">WIP</th>
                            <th className="col-reg">Reg.</th>
                            <th className="col-cust">Customer</th>
                            <th className="col-due-in">Due In</th>
                            <th className="col-work">Workflow</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobs.map((j, i) => (
                            <MatchingJobRow key={i} job={j} onJobSelect={onJobSelect} />
                        ))}
                        {jobs.length === 0 && (
                            <tr>
                                <td colSpan="4">{loading ? 'Loading...' : 'No record found.'}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <table className="job-search-body"></table>
        </>
    );
};

let timer;

const SearchWipsByBranch = (props) => {
    const [state, setState] = useState({
        branchID: props.selectedBranchID,
        loading: false,
        selectedHeaderId: undefined,
        searchText: '',
        matchedJob: [],
        workFlowList: [],
        workflowID: {}
    });

    const setSearchText = (e) => {
        const searchText = e.target.value;
        timer && clearTimeout(timer);
        setState((st) => ({ ...st, searchText, selectedHeaderId: undefined }));
        timer = setTimeout(() => {
            if (searchText.length > 2) {
                setState((st) => ({ ...st, loading: true }));
                searchJobByBranchAndText(state.branchID, searchText)
                    .then((res) => {
                        if (res.success) {
                            setState((st) => ({ ...st, matchedJob: res.data.map((j) => ({ ...j, isSelected: false })) }));
                        } else {
                            props.showToast(res.message);
                        }
                    })
                    .finally(() => {
                        setState((st) => ({ ...st, loading: false }));
                    });
            }
        }, 500);
    };

    useEffect(async () => {
        let dropRes = await getWorkflowsByProcessID(1);
        setState((st) => ({ ...st, workFlowList: dropRes.data.map((p) => ({ id: p.id, name: p.workflowName })) }));
    }, []);

    const onJobSelect = (checked, jobHeaderId) => {
        setState((st) => {
            let newSt = { ...st };
            let job = newSt.matchedJob.find((d) => d.headerRecordID === jobHeaderId);
            if (job) {
                newSt.matchedJob.forEach((d) => (d.isSelected = false));
                job.isSelected = checked;
                newSt.selectedHeaderId = checked ? jobHeaderId : undefined;
                newSt.matchedJob = [...newSt.matchedJob];
            }
            return newSt;
        });
    };

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const LinkJobWithWIPs = () => {
        props.onClickingLink && props.onClickingLink(state.selectedHeaderId, state.workflowID.id);
    };
    let customeLabel = `Link WIP (${props.data.caseNo})`;

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st, [nm]: val };
            return newSt;
        });
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="md" fullWidth className="dialog-custom s24-link-job-search-modal">
            <DialogTitle>
                {customeLabel}
                <div className="title-header-actions">
                    <span className="close-icon" onClick={handleClose}>
                        &times;
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <TextBox label="Search WIP No" required={false} onChange={setSearchText} value={state.searchText} autoFocus={true} />
                <div className="job-search">
                    <MatchingJobsTable jobs={state.matchedJob} loading={state.loading} onJobSelect={onJobSelect} />
                </div>
                <Grid item xs={12} style={{ marginTop: 5 }}>
                    <FormLabel component="legend">Select Workflow</FormLabel>
                    <SingelSelect onChange={singleSelectChange('workflowID')} options={state.workFlowList || []} value={state.workflowID} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={LinkJobWithWIPs} disabled={!state.selectedHeaderId}>
                    Link Job
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

export default SearchWipsByBranch;
