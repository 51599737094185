import React from 'react';
// import { s3URL } from './../../Constants/constants';
const selfBgCss = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    filter: 'blur(8px)',
    opacity: '0.5',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0
};
const ImageCard = ({ avtar, selfBg }) => {
    if (avtar) {
        return (
            <div className="image-card" style={{ position: 'relative', margin: '5px 0px' }}>
                {/* {selfBg && <div style={{ ...selfBgCss, backgroundImage: `url(${s3URL + avtar})` }}></div>} */}
                <img className="avtar" src={avtar} alt="wheel" />
            </div>
        );
    } else {
        return null;
    }
};

export default ImageCard;
