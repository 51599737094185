import React, { useContext, useEffect, useState } from 'react';
import {
    ButtonGroup,
    Grid,
    IconButton,
    LinearProgress,
    Tooltip,
    Badge,
    Card,
    CardHeader,
    CardContent,
    Typography,
    TextField,
    FormLabel,
    CircularProgress,
    Chip,
    Avatar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { TextBox } from '../../../Core/FormInput';
import moment from 'moment';

import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import formatters from '../../../Core/Grid/inputFormatter';
import AppContext from '../../../App/AppContext';
import {
    getInvoiceBranch,
    getInvoicedHoursCalender,
    getPartsCalender,
    getReportingGetSafetyCheckDue,
    getReportingGetVehiceMOTDue,
    getReportingGetVehiceServices
} from '../../../Core/Service/ReportScreens-service';
// import { VehicleModalDetail } from './vehicleDetailsModal';
import DialogComp from '../../../Core/Modal/dialogModal';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { first } from 'lodash';
// import VehicleDueDetailsList from './VehicleDueDetailListModal';

const setPercentage = (profit, budget) => {
    if (!profit) {
        return 0;
    } else {
        if (budget == null || budget == 0) {
            return 100;
        } else {
            let value = (+profit / +budget) * 100;

            return Math.round(value) <= 100 ? Math.round(value) : 100;
        }
    }
};

const useStyles = makeStyles({
    yearInput: {
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '4.1px',
            paddingBottom: '4.1px',
            color: 'white'
        }
    },
    root: {
        minWidth: 'auto',
        height: '135px',
        border: ' 1px solid rgba(0,0,0,0.1)',
        borderRadius: 12,
        minWidth: 250

        // height: '100%'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    cardContent: (props) => {
        let cardContentHeight = props.fromTab ? Math.max(120, (window.innerHeight - 170) / 4 - 60) : Math.max(120, (window.innerHeight - 80) / 4 - 90);
        return {
            height: cardContentHeight,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.3em'
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#1976d2',
                opacity: 5,
                outline: '1px solid slategrey'
            }
        };
    },
    cardRow: {
        padding: '3px',
        borderBottom: ' 1px dotted black',
        cursor: 'pointer',
        '&:nth-of-type(odd)': {
            background: '#E8E8E8'
        }
    },
    cardHeader: {
        height: '10px',
        // backgroundColor: (props) => console.log(props, 'ssssssss'),
        // border: (props) => `1px solid ${props.portalMenuBarBackgroundColour}`,
        // color: 'white',
        textAlign: 'left',
        padding: '10px !important'
    },
    loaderContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        zIndex: 99,
        background: 'grey',
        opacity: '0.6'
    }
});

const years = () => {
    const years = [];
    const d = new Date();
    let startYear = d.getFullYear() - 10;
    let endYear = d.getFullYear() + 10;

    while (endYear - startYear >= 0) {
        years.push({ description: `${startYear}`, id: `${startYear}` });
        startYear = startYear + 1;
    }
    return years;
};

const monthsList = formatters.constantMonthList();

const PartsCalender = (props) => {
    const { portalSettings, showTopNavAndMenu, loggedIn, isTopNav, userRoles } = useContext(AppContext);
    const classes = useStyles({ ...props, ...portalSettings });
    const isCustomer = userRoles.includes('customer');
    const isInternal = userRoles.includes('internal');
    const [width, height] = useWindowSize();
    const [state, setState] = useState({
        branchList: [],
        branchCode: {},
        monthCode: monthsList[`${new Date().getMonth()}`],
        yearCode: { description: `${new Date().getFullYear()}`, id: `${new Date().getFullYear()}` },
        currentMonthDays: [],
        filterData: [],
        holidays: [],
        branchID: null,
        branchIDObj: {},
        filterList: [],
        dailybudget: []
    });

    console.log(state);

    useEffect(async () => {
        console.log('UseEffect_1');
        let res = await getInvoiceBranch();
        setState((st) => ({
            ...st,
            branchList: res.data.map((n) => ({
                description: n.branchName,
                id: n.branchID
            })),
            branchID: res.data[0].branchID,
            branchIDObj: {
                description: res.data[0].branchName,
                id: res.data[0].branchID
            }
        }));
    }, []);

    useEffect(() => {
        let HolidaysList = [...state.holidays];
        let dataList = [...state.dailybudget];
        dataList.forEach((j) => {
            let findList = HolidaysList.find((h) => j.dateEditted === h.date);
            if (findList) {
                findList.actualSale = j.actualSale;
                findList.actualSold = j.actualSold;
            }
        });

        setState((st) => ({
            ...st,
            filterList: HolidaysList,
            showLoader: false
        }));
    }, [state.holidays, state.dailybudget]);

    useEffect(async () => {
        let startDate = moment([state.yearCode.id, state.monthCode.id - 1])
            .startOf('month')
            .format('YYYY-MM-DD');
        let endDate = moment([state.yearCode.id, state.monthCode.id - 1])
            .endOf('month')
            .format('YYYY-MM-DD');

        setState((st) => ({ ...st, showLoader: true }));

        if (state.branchID) {
            let newRes = await getPartsCalender(startDate, endDate, state.branchID);
            setState((st) => ({ ...st, budget: newRes?.data?.budget, dailybudget: newRes?.data?.dailybudget, holidays: newRes?.data?.holidays }));
        }
    }, [state.yearCode, state.monthCode, state.branchID]);

    const handleAutoChange = (fieldName) => (e, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt[fieldName] = val;
            newSt.showLoader = true;
            if (fieldName === 'branchIDObj') {
                newSt.branchID = val.id;
            }
            return newSt;
        });
    };

    return (
        <div style={{ padding: '10px', position: 'relative' }}>
            <Grid container>
                <Grid container item spacing={2} style={{ marginBottom: '1px' }}>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                        <FormLabel>Select Month</FormLabel>
                        <Autocomplete
                            disableClearable
                            autoSelect
                            options={monthsList}
                            name="Month"
                            value={state.monthCode}
                            onChange={handleAutoChange('monthCode')}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                        <FormLabel>Select Year</FormLabel>
                        <Autocomplete
                            disableClearable
                            autoSelect
                            options={years()}
                            name="Years"
                            value={state.yearCode}
                            onChange={handleAutoChange('yearCode')}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={3}>
                        <FormLabel>Branch</FormLabel>
                        <Autocomplete
                            disableClearable
                            autoSelect
                            options={state.branchList}
                            name="Years"
                            value={state.branchIDObj}
                            onChange={handleAutoChange('branchIDObj')}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                        />
                    </Grid>
                </Grid>
                {state.showLoader && (
                    <Grid item sm={12} container alignItems="center" justify="center" className={classes.loaderContainer}>
                        <CircularProgress />
                    </Grid>
                )}
                <Grid container item spacing={2}>
                    {state?.holidays?.map((o, i) => {
                        return (
                            <>
                                {width <= 1660 ? (
                                    <Grid item xs={12} lg={3} md={4} sm={6}>
                                        <Card className={classes.root}>
                                            <CardHeader
                                                titleTypographyProps={{ variant: 'body1' }}
                                                title={moment(o.date).format('DD MMM YYYY   dddd')}
                                                className={classes.cardHeader}
                                                style={{
                                                    background: o.workingDay == 'Y' ? 'black' : 'grey',
                                                    color: 'white'
                                                }}
                                            ></CardHeader>
                                            <CardContent style={{ padding: '10px 10px 10px 10px' }} className={classes.cardContent}>
                                                <Grid container justifyContent="space-around" alignItems="center">
                                                    <Grid item xs={6} sm={4} md={7}>
                                                        <div className="vehicleRing" style={{ textAlign: 'center', marginLeft: 20 }}>
                                                            <CircularProgressbar
                                                                value={setPercentage(o.actualSold, state.budget[0].dailyBudgetHours)}
                                                                text={`${setPercentage(o.actualSold, state.budget[0].dailyBudgetHours)}%`}
                                                                styles={buildStyles({
                                                                    textColor:
                                                                        setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) < 50
                                                                            ? '#D92641'
                                                                            : setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) >= 50 &&
                                                                              setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) < 80
                                                                            ? '#ff9f00'
                                                                            : setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) >= 80
                                                                            ? '#569101'
                                                                            : '#D92641',
                                                                    pathColor:
                                                                        setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) < 50
                                                                            ? '#D92641'
                                                                            : setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) >= 50 &&
                                                                              setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) < 80
                                                                            ? '#ff9f00'
                                                                            : setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) >= 80
                                                                            ? '#569101'
                                                                            : '#D92641',
                                                                    trailColor: '#d6d6d6',
                                                                    textSize: '27px'
                                                                })}
                                                            />
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={6} sm={4} md={5}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            <div>
                                                                <Typography style={{ fontSize: 12 }}>Hours</Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontSize: 15,
                                                                        fontWeight: 600,
                                                                        color: o.actualSold
                                                                            ? o.actualSold <= state.budget[0].dailyBudgetHours
                                                                                ? 'red'
                                                                                : 'green'
                                                                            : 'red'
                                                                    }}
                                                                >
                                                                    {formatters.ThousandSeparatorWithoutZero(o.actualSold)}
                                                                </Typography>

                                                                <Typography style={{ fontSize: 12 }}>Value &nbsp;</Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontSize: 15,
                                                                        fontWeight: 600,
                                                                        color: o.actualSale
                                                                            ? o.actualSale <= state.budget[0].dailyBudget
                                                                                ? 'red'
                                                                                : 'green'
                                                                            : 'red'
                                                                    }}
                                                                >
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(o.actualSale)}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ) : (
                                    <Grid item>
                                        <Card className={classes.root}>
                                            <CardHeader
                                                titleTypographyProps={{ variant: 'body1' }}
                                                title={moment(o.date).format('DD MMM YYYY   dddd')}
                                                className={classes.cardHeader}
                                                style={{
                                                    background: o.workingDay == 'Y' ? 'black' : 'grey',
                                                    color: 'white'
                                                }}
                                            ></CardHeader>
                                            <CardContent style={{ padding: '10px 10px 10px 10px' }} className={classes.cardContent}>
                                                <Grid container justifyContent="space-around" alignItems="center">
                                                    <Grid item xs={6} sm={4} md={7}>
                                                        <div className="vehicleRing" style={{ textAlign: 'center', marginLeft: 20 }}>
                                                            <CircularProgressbar
                                                                value={setPercentage(o.actualSold, state.budget[0].dailyBudgetHours)}
                                                                text={`${setPercentage(o.actualSold, state.budget[0].dailyBudgetHours)}%`}
                                                                styles={buildStyles({
                                                                    textColor:
                                                                        setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) < 50
                                                                            ? '#D92641'
                                                                            : setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) >= 50 &&
                                                                              setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) < 80
                                                                            ? '#ff9f00'
                                                                            : setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) >= 80
                                                                            ? '#569101'
                                                                            : '#D92641',
                                                                    pathColor:
                                                                        setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) < 50
                                                                            ? '#D92641'
                                                                            : setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) >= 50 &&
                                                                              setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) < 80
                                                                            ? '#ff9f00'
                                                                            : setPercentage(o.actualSold, state.budget[0].dailyBudgetHours) >= 80
                                                                            ? '#569101'
                                                                            : '#D92641',
                                                                    trailColor: '#d6d6d6',
                                                                    textSize: '27px'
                                                                })}
                                                            />
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={6} sm={4} md={5}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            <div>
                                                                <Typography style={{ fontSize: 12 }}>Hours</Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontSize: 15,
                                                                        fontWeight: 600,
                                                                        color: o.actualSold
                                                                            ? o.actualSold <= state.budget[0].dailyBudgetHours
                                                                                ? 'red'
                                                                                : 'green'
                                                                            : 'red'
                                                                    }}
                                                                >
                                                                    {formatters.ThousandSeparatorWithoutZero(o.actualSold)}
                                                                </Typography>

                                                                <Typography style={{ fontSize: 12 }}>Value &nbsp;</Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontSize: 15,
                                                                        fontWeight: 600,
                                                                        color: o.actualSale
                                                                            ? o.actualSale <= state.budget[0].dailyBudget
                                                                                ? 'red'
                                                                                : 'green'
                                                                            : 'red'
                                                                    }}
                                                                >
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(o.actualSale)}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )}
                            </>
                        );
                    })}
                </Grid>
            </Grid>
        </div>
    );
};

export default PartsCalender;
