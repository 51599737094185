import {get, post} from './http-calls';

export const getFailureCodes = async ()=>{
    let res = await get('/failurecodes', { useAuthToken: true });
    return res;
}
export const addFailureNotes = async (id, name) => {
    let res = await post(`failurecodes`, {
        FailureCode:id,
        FailureDescription: name,
    }, { useAuthToken: true });
    return res;
}
export const DeleteFailureNotes = async (id) => {
    let res = await post(`WebForms/DeleteWebForms/${id}`, {
        Id: id,
    },{ useAuthToken: true });
    return res;
}