import React from 'react';
import './budgetCommon.scss';
import CallMadeIcon from '@material-ui/icons/CallMade';

export const FilterBtn = (props) => {
    const { active, children, ...rest } = props;

    return (
        <>
            <div className={active ? 'buttonValueActive' : 'buttonValue'} {...rest}>
                {children}
            </div>
        </>
    );
};

export const FilterBtnWithIcon = (props) => {
    const { active, children, ...rest } = props;

    return (
        <>
            <div className={active ? 'iconButtonValueActive' : 'iconButtonValue'} {...rest}>
                {children}
            </div>
        </>
    );
};

export const BudgetSendBtn = (props) => {
    return (
        <>
            <div className="sendBtn" {...props}>
                {' '}
                <CallMadeIcon className="btnicon" />{' '}
            </div>
        </>
    );
};

export const SmallBudgetSendBtn = (props) => {
    return (
        <>
            <div className="smallsendBtn" {...props}>
                {' '}
                <CallMadeIcon className="btnicon" />{' '}
            </div>
        </>
    );
};

export const LinkedButton = (props) => {
    return (
        <>
            <div className="linkedButton" {...props}>
                {props.children}
            </div>
        </>
    );
};

export const SmallLinkedButton = (props) => {
    return (
        <>
            <div className={props.active ? 'smalllinkedButton-active' : 'smalllinkedButton'} {...props}>
                {props.children}
            </div>
        </>
    );
};
