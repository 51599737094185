import React, { useMemo } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel, IconButton, CircularProgress } from '@material-ui/core';
import BreadCrumbs from '../../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../../GijgoForReportsScreen';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EditIcon from '@material-ui/icons/Edit';
import ReportNotesModal from '../../../ReportingNotesScreen';
import { useState } from 'react';
import '../../../ReportingScreensStyle.scss';
import { getAgedMeasuresViewDocument, getHoverReportingNotes } from '../../../../../../Core/Service/ReportScreens-service';
import { SquareButton } from '../../../../../../Core/FormInput/AppButton';
import ViewPdfModal from '../../../../../../Core/Modal/PdfViweModal';
import DialogComp from '../../../../../../Core/Controls/Dialog/DialogComp';
import GetWIPHeader from '../../../GetWIPDetails';
import { getReportsDropdownsResolveData } from '../../../../../../Core/Service/reportService';
import formatters from '../../../../../../Core/Grid/inputFormatter';
const gridColumns = [
    {
        title: 'Document',
        field: 'dn',
        width: 80,
        sortable: true,
        align: 'right'
    },
    { title: 'Type', field: 'dt', width: 45, sortable: true, align: 'center' },
    {
        title: 'Original',
        field: 'o',
        width: 120,
        sortable: true,
        isCurrency: true,
        align: 'right'
    },
    {
        title: 'Balance',
        field: 'b',
        width: 120,
        sortable: true,
        isCurrency: true,
        align: 'right'
    },
    {
        title: 'Date',
        field: 'dd',
        width: 90,
        sortable: true,
        renderer: formatters.MonthShortFormatter,
        cssClass: 'text-center'
    },
    {
        title: 'Due Date',
        field: 'dud',
        width: 90,
        sortable: true,
        renderer: formatters.MonthShortFormatter,
        cssClass: 'text-center'
    },
    { title: 'Days', field: 'ad', width: 45, sortable: true, align: 'right' },
    { title: 'Months', field: 'am', width: 60, sortable: true, align: 'right' },
    { title: 'Misc Ref', field: 'mr', width: 90, sortable: true },
    { title: 'Customer Ref', field: 'cr', width: 95, sortable: true },
    { title: 'Account Code', field: 'accountCode', width: 70, sortable: true }
];

const getCrumbs = (
    ParamMeasureID,
    ParamMeasuredesc,
    ParamBranchName,
    ParamMonth,
    ParamUnallocated,
    ParamDepartmentName,
    ParamAccCode,
    Paramsalesexec,
    ParentScreen
) => {
    let isSubTitle = ParamBranchName || ParamDepartmentName || ParamAccCode || ParamMonth || ParamUnallocated;
    let temp = {},
        temp2 = {};
    let final = [
        { name: 'Home', path: '' },
        { name: ParentScreen ? 'Sales' : 'Aged Measures', path: ParentScreen ? '/sales/dashboard' : '/AgedMeasures' }
    ];
    if (ParamMeasuredesc == 'Account Queries by Salesexec') {
        final.push({ name: `${ParamMeasuredesc}`, path: `/sales/AccountQueriesbySalesexec?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` });
        temp = { name: `${Paramsalesexec}`, active: true };
    } else if (ParamMeasureID == '6') {
        temp = { name: `${ParamMeasuredesc}`, path: `/AgedMeasures/AccountQueries?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` };
    } else if (ParamMeasureID == '21') {
        temp = { name: `${ParamMeasuredesc}`, path: `/AgedMeasures/AccountQueriesByDepartment?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` };
    } else if (ParamMeasureID == '3') {
        temp = { name: `${'R&M'}`, path: `/AgedMeasures/R&M?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` };
    } else if (ParamMeasureID == '4') {
        temp = { name: `${ParamMeasuredesc}`, path: `/AgedMeasures/Warranty?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` };
    } else if (ParamMeasureID == '5') {
        temp = { name: `${ParamMeasuredesc}`, path: `/AgedMeasures/Cash?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` };
    } else if (ParamMeasureID == '7') {
        temp = { name: `${ParamMeasuredesc}`, path: `/AgedMeasures/AgedDebt-Branch?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` };
    } else if (ParamMeasureID == '8') {
        temp = { name: `${ParamMeasuredesc}`, path: `/AgedMeasures/AgedDebt-Company?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` };
    } else if (ParamMeasureID == '9') {
        temp = { name: `${ParamMeasuredesc}`, path: `/AgedMeasures/VehicleDebt?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` };
    } else if (ParamMeasuredesc == 'Account Queries by Salesexec') {
        temp = { name: `${ParamMeasuredesc}`, path: `/sales/AccountQueriesbySalesexec?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` };
    } else if (ParamMeasureID == '20') {
        temp = { name: `${ParamMeasuredesc}`, path: `/AgedMeasures/CashByDepartment?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` };
    } else {
        temp = { name: `${ParamMeasuredesc}`, active: true };
    }
    final = [...final, { ...temp }];
    if (isSubTitle) {
        temp = {
            name: `${ParamBranchName ? ParamBranchName : ''} ${ParamDepartmentName ? ParamDepartmentName : ''} ${ParamAccCode ? ParamAccCode : ''} ${
                ParamMonth > 0 ? `(${ParamMonth} ${ParamMonth == 1 ? 'Month' : 'Months'})` : ''
            } ${ParamUnallocated > 0 ? `(Unallocated)` : ''}`,
            active: true
        };
        final = [...final, { ...temp }];
    }

    return final;
};

const GetAgedMeasuresRanMWarrantyCashGen = (props) => {
    const [state, setState] = useState({ hoverDetailNote: '', showWIPDetails: '', wipNumber: '', company: '', showLoader: true });

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamMeasureID = params.get('MeasureID') || '';
    let ParamMeasuredesc = decodeURIComponent(params.get('measuredesc') || '');
    let ParamMonth = params.get('month') || '';
    let ParamBranchName = params.get('bn') || '';
    let ParamDepartmentName = params.get('Department') || '';
    let ParamDepartmentCode = params.get('departmentCode') || '';
    let ParamBranchId = params.get('branchID') || params.get('branchId') || '';
    let ParamUnallocated = params.get('unallocated') || '';
    let ParamAccCode = params.get('accCode') || '';
    let ParamCashAccountTypeID = params.get('cashAccountTypeID') || '';
    let ParamIsGroupingById = params.get('isGroupingById') || '';
    let Paramsalesexec = params.get('salesexec') || '';
    let ParamPRSentTo = params.get('PRSentTo') || '';
    let ParentScreen = params.get('from') || '';
    const notesActionHandler = (record) => {
        setState((st) => ({
            ...st,
            showNotesModal: true,
            smn: record.smn,
            dn: record.dn
        }));
    };
    const notesHoverHandler = async (record) => {
        let res = await getHoverReportingNotes(record.smn);
        setState((st) => ({
            ...st,
            hoverDetailNote: res.data.list[0].notes
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <SquareButton
                    style={{
                        backgroundColor: record.rsmn ? '#183B68' : '#868e96',
                        borderRadius: '20%',
                        width: '29px',
                        height: '28px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => notesActionHandler(record)}
                >
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const documentHandler = async (record) => {
        setState((st) => ({ ...st, showPdfModal: true }));
        let res = await getAgedMeasuresViewDocument(record.invfile);
        if (res) {
            setState((st) => ({ ...st, pdfFileData: res, showLoader: false }));
        } else {
            console.error(res.message);
        }
    };

    const wipNumberHandler = async (record) => {
        setState((st) => ({ ...st, showWIPDetails: true, wipNumber: record.wn, company: record.company }));
    };

    const docEditButton = (value, record) => {
        const spn = document.createElement('span');
        let isFile = record.invfile;
        const lnk = (
            <span style={{ cursor: isFile ? 'pointer' : 'text' }} onClick={isFile ? () => documentHandler(record) : ''}>
                {value}
            </span>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const wipNumberRenderer = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <span style={{ cursor: 'pointer' }} onClick={() => wipNumberHandler(record)}>
                {value}
            </span>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const closeNotesHandler = (res) => {
        setState((st) => {
            let newSt = { ...st };
            if (res) {
                newSt.isReload = new Date();
            } else {
                newSt.showNotesModal = false;
            }
            return newSt;
        });
    };

    let Columns = useMemo(() => {
        let tempColumns = [...gridColumns];
        let actionCol = tempColumns.find((element) => element.field === 'rhid');
        if (ParamMeasuredesc != 'Account Queries by Salesexec') {
            // actionCol.renderer = editButton;
            tempColumns.splice(0, 0, { title: '', width: 55, field: 'rhid', filterable: false, sortable: true, align: 'center', renderer: editButton });
        }
        let ViewDocCol = tempColumns.find((element) => element.field === 'dn');
        if (ViewDocCol) {
            ViewDocCol.renderer = docEditButton;
        }
        if (ParamMeasureID == 6 || ParamMeasureID == 21) {
            // tempColumns.push({
            //     title: 'PR Code',
            //     field: 'prCode',
            //     width: 50,
            //     sortable: true
            // });
            tempColumns.push({
                title: 'Date Raised',
                field: 'prDateRaised',
                width: 90,
                sortable: true,
                type: 'date',
                format: 'dd-mmm-yyyy',
                cssClass: 'text-center'
            });
            // tempColumns.push({
            //     title: 'PR FollowUp',
            //     field: 'prFollowUpDate',
            //     width: 90,
            //     sortable: true,
            //     type: 'date',
            //     format: 'dd-mmm-yyyy',
            //     cssClass: 'text-center'
            // });
            tempColumns.push({
                title: 'PR Comment',
                field: 'prComment',
                width: 260,
                sortable: true
            });
            tempColumns.push({
                title: 'PR SentTo',
                field: 'prSentTo',
                width: 70,
                sortable: true
            });
        } else {
            tempColumns.push({
                title: 'WIP',
                field: 'wn',
                width: 50,
                sortable: true,
                renderer: wipNumberRenderer
            });
        }
        // if (!(ParamMeasureID == 6 || ParamMeasureID == 21)) {
        //     console.log(ParamMeasureID, 'ParamMeasureID');
        //     tempColumns.push({
        //         title: 'PR SentTo',
        //         field: 'prSentTo',
        //         width: 70,
        //         sortable: true
        //     });
        // }
        if (ParamAccCode.trim() != '') {
            tempColumns.push({
                title: 'Branch',
                field: 'branchShortName',
                width: 90,
                sortable: true,
                isDropDown: true,
                listIdFromAPIResponse: 'branch',
                dropDownValueField: 'name',
                dropDownLableField: 'name',
                queryParamName: 'bn'
            });
        }
        return tempColumns;
    }, [ParamMeasureID]);

    const baseUrl = `AgedMeasures/GetAgedMeasuresRanMWarrantyCashGen?BranchID=${ParamBranchId}&MeasureID=${ParamMeasureID}&Unallocated=${ParamUnallocated}&CashAccountTypeID=${ParamCashAccountTypeID}&Department=${ParamDepartmentCode}&salesexec=${Paramsalesexec}&PRSentTo=${ParamPRSentTo}&mont=${ParamMonth}`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs
                crumbs={getCrumbs(
                    ParamMeasureID,
                    ParamMeasuredesc,
                    ParamBranchName,
                    ParamMonth,
                    ParamUnallocated,
                    ParamDepartmentName,
                    ParamAccCode,
                    Paramsalesexec,
                    ParentScreen
                )}
            />
            {state.showWIPDetails && (
                <DialogComp title="WIP Details" onClose={() => setState((st) => ({ ...st, showWIPDetails: false }))} maxWidth="xl" fullWidth>
                    <GetWIPHeader wipNumber={state.wipNumber} company={state.company} />
                </DialogComp>
            )}
            {state.showPdfModal ? (
                <DialogComp title="View PDF" maxWidth="md" onClose={() => setState((st) => ({ ...st, showPdfModal: false }))} fullWidth>
                    {state.showLoader ? (
                        <div style={{ width: '100%' }}>
                            <div
                                style={{
                                    height: 680,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: '2px solid #d3d3d3'
                                }}
                            >
                                <CircularProgress />
                            </div>
                        </div>
                    ) : (
                        <ViewPdfModal pdfFileData={state.pdfFileData} />
                    )}
                </DialogComp>
            ) : null}
            <Grid
                item
                container
                spacing={1}
                xs={12}
                // style={{ padding: '0px 10px' }}
            >
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={Columns}
                        getUrl={baseUrl}
                        isReload={state.isReload}
                        isGroupingId={ParamIsGroupingById ? ParamIsGroupingById : ''}
                        dropDownListProvider={getReportsDropdownsResolveData}
                        FilterdParamsValues={{ accountCode: ParamAccCode, mont: ParamMonth }}
                    />
                </Grid>
            </Grid>
            {state.showNotesModal && <ReportNotesModal onClose={closeNotesHandler} dn={state.dn} smn={state.smn} />}
        </div>
    );
};

export default GetAgedMeasuresRanMWarrantyCashGen;
