import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, CircularProgress } from '@material-ui/core';
import { PrimaryButton, SecondaryButton, TextBox } from '../../../Core/FormInput';
import { assignAuditorsToJob, getAuditorList, getAuditorsByJob } from '../../../Core/Service/Auditor-services';
import { some } from 'lodash';
import AppContext from '../../../App/AppContext';

const OneAuditorRow = ({ item, onClick }) => {
    return (
        <tr className={`row${item.selected ? ' is-selected-auditor' : ''}`}>
            <td className="is-selected">
                <Checkbox checked={item.selected} color={item.selected ? 'primary' : ''} onChange={(e) => onClick(e.target.checked, item.id)} />
            </td>
            <td className="name">{item.name}</td>
            <td className="">{item.email}</td>
            <td className="ph-no">{item.ph}</td>
        </tr>
    );
};

const AuditorListTable = ({ items, onAllSelect, selectedCount, loading, onClick }) => {
    const allSelected = selectedCount === items.length;
    return (
        <>
            <table className="auditor-search-header">
                <thead>
                    <tr className="header">
                        <th className="is-selected">
                            <Checkbox checked={allSelected} color={allSelected ? 'primary' : ''} onChange={(e) => onAllSelect(e.target.checked)} />
                        </th>
                        <th className="name">Name</th>
                        <th className="">Email</th>
                        <th className="ph-no">Phone</th>
                    </tr>
                </thead>
            </table>
            <div className="body-table-container custom-scroll">
                <table className="auditor-search-body">
                    <tbody>
                        {items.map((aud, i) => (
                            <OneAuditorRow key={i} item={aud} onClick={onClick} />
                        ))}
                        {items.length === 0 && (
                            <tr>
                                <td colSpan="4">{loading ? 'Loading...' : 'No record found.'}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

let searchTimer;
const AssignJobAuditorModal = (props) => {
    const [state, setState] = useState({
        archivedJob: props.archivedJob,
        loadingCounter: 2,
        saving: false,
        selectedAud: 0,
        searchText: '',
        auditors: [],
        filteredAud: [],
        isUpdate: false,
        audAssignedAlready: []
    });

    const { showToast } = useContext(AppContext);

    useEffect(() => {
        getAuditorList().then((res) => {
            if (!res.success) {
                showToast(res.message);
                setState((st) => ({ ...st, loadingCounter: st.loadingCounter - 1 }));
            } else {
                let auditors = res.data.map((aud) => ({ ...aud, selected: false }));
                setState((st) => ({ ...st, auditors, loadingCounter: st.loadingCounter - 1 }));
            }
        });
        getAuditorsByJob(state.archivedJob.id).then((res) => {
            if (res.success) {
                setState((st) => ({ ...st, isUpdate: res.data.length > 0, audAssignedAlready: res.data, loadingCounter: st.loadingCounter - 1 }));
            } else {
                setState((st) => ({ ...st, loadingCounter: st.loadingCounter - 1 }));
            }
        });
    }, []);

    useEffect(() => {
        if (state.loadingCounter === 0) {
            setState((st) => {
                let newSt = { ...st };
                newSt.auditors.forEach((a) => (a.selected = newSt.audAssignedAlready.some((ai) => ai == a.id)));
                newSt.selectedAud = newSt.auditors.filter((a) => a.selected).length;
                newSt.auditors = [...newSt.auditors];
                return newSt;
            });
        }
    }, [state.loadingCounter]);

    const auditorClicked = (checked, audId) => {
        setState((st) => {
            let newSt = { ...st };
            let aud = newSt.auditors.find((a) => a.id === audId);
            if (aud) {
                aud.selected = checked;
                newSt.selectedAud = newSt.auditors.filter((a) => a.selected).length;
                newSt.auditors = [...newSt.auditors];
            }
            return newSt;
        });
    };

    const onAllSelect = (checked) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.auditors.forEach((a) => (a.selected = checked));
            newSt.selectedAud = checked ? newSt.auditors.length : 0;
            newSt.auditors = [...newSt.auditors];
            return newSt;
        });
    };

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    useEffect(() => {
        setFilteredRow();
    }, [state.auditors]);

    const setFilteredRow = () => {
        setState((st) => {
            const newSt = { ...st };
            const { auditors, searchText } = newSt;
            let filterd = [];
            if (searchText.length > 0) {
                filterd = auditors.filter((rw) => some(rw, (vl, p) => p !== 'id' && `${vl}`.toLowerCase().includes(searchText.trim().toLowerCase())));
            } else {
                filterd = [...auditors];
            }
            newSt.filteredAud = filterd;
            return newSt;
        });
    };

    const setFilterText = (e) => {
        const vl = e.target.value;
        setState((st) => ({ ...st, searchText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const assignSelectedAuditors = async () => {
        setState((st) => ({ ...st, saving: true }));
        let res = await assignAuditorsToJob(
            state.archivedJob.id,
            state.isUpdate,
            state.auditors.filter((s) => s.selected).map((a) => a.id)
        );
        setState((st) => ({ ...st, saving: false }));
        if (res.success) {
            handleClose();
        }
        showToast(res.message);
    };
    const allSelected = state.selectedAud === state.auditors.length;
    return (
        <Dialog open={true} onClose={handleClose} maxWidth="md" className="dialog-custom assign-auditor-to-job">
            <DialogTitle>
                {`Assign Job to Auditor (WIP: ${state.archivedJob.wipNo})`}
                <div className="title-header-actions">
                    <span className="close-icon" onClick={handleClose}>
                        &times;
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="filter-aud">
                    <TextBox label="Search Auditor" required={false} value={state.searchText} autoFocus={true} onChange={setFilterText} />
                    <label>{`Selected ${state.selectedAud}/${state.auditors.length}`}</label>
                </div>
                <div className="auditor-search">
                    <AuditorListTable
                        selectedCount={state.selectedAud}
                        items={state.filteredAud}
                        loading={state.loadingCounter > 0}
                        onAllSelect={onAllSelect}
                        onClick={auditorClicked}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <PrimaryButton className="no-caps " onClick={assignSelectedAuditors} disabled={state.saving || (!state.isUpdate && state.selectedAud === 0)}>
                    {`Assign ${allSelected ? 'All' : state.selectedAud} Auditors`}
                    {state.saving && <CircularProgress size={24} className="sending-loader" />}
                </PrimaryButton>
                <SecondaryButton className="no-caps" onClick={handleClose}>
                    Cancel
                </SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};

export default AssignJobAuditorModal;
