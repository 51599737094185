import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Grid, FormLabel, Hidden, Collapse } from '@material-ui/core';
import { Multiselect, SecondaryButton, DatePicker } from '../../../../Core/FormInput';
import NestedGijgoGrid from '../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import formatters from '../../../../Core/Grid/inputFormatter';
import { getReportsDropdowns } from '../../../../Core/Service/reportService';
import FilterListIcon from '@material-ui/icons/FilterList';
import ReactDOM from 'react-dom';

const baseColumns = [
    { title: 'Type', field: 'moi_type', width: 40, sortable: true },
    { title: 'Sales Type', field: 'salestypedesc', width: 55, sortable: true },
    { title: 'Branch', field: 'branchdesc', width: 110, sortable: true },
    { title: 'Date', field: 'solddate', width: 59, sortable: true },
    { title: 'Name', field: 'name', width: 90, sortable: true },
    { title: 'Make', field: 'modelofinterestmanu', width: 80, sortable: true },
    { title: 'Model', field: 'modelofinterestmodel', width: 130, sortable: true },
    { title: 'Reg No', field: 'regNo', width: 55, sortable: true },
    // { title: 'Profit', field: 'ownedbydesc', width: 80, sortable: true },// need to add this column
    { title: 'Media', field: 'mediasourcelabel', width: 70, sortable: true },
    {
        title: 'Cash/Finance',
        field: 'isfinanced',
        width: 60,
        sortable: true
    },
    { title: 'Sales Exec', field: 'ownedbydesc', width: 70, sortable: true }
];

const detailTemplate = () => {
    return `<div style='width:100%'>
            <table style='width:100%;'>
                <tr style='background-color:#DCDCDC;' >
                    <th style='width: 5%;font-weight:750;text-align:left;'>Id</th >
                    <th style='width: 8%;font-weight:750;text-align:left;'>Monthly Payment</th >
                    <th style='width: 6%;font-weight:750;text-align:left;'>Deposit</th >
                    <th style='width: 6%;font-weight:750;text-align:left;'>Mileage</th >
                    <th style='width: 30%;text-align:left;font-weight:750;'>Description</th>
                    <th style='width: 7%;text-align:left;font-weight:750;'>PX Reg</th>
                    <th style='text-align:left;font-weight:750;'>PX DOR</th>
                    <th style='text-align:left;font-weight:750;'>PX Condition</th>
                    <th style='text-align:left;font-weight:750;'>PX Offer</th>
                    <th style='text-align:left;font-weight:750;'>Settlement</th>
                </tr>
                <tr>
                    <td>{enquiry_id}</td>
                    <td>{approxmonthlypayment}</td>
                    <td>{deposit}</td>
                    <td>{Mileage}</td>
                    <td>{px_description}</td>
                    <td>{partExchange}</td> 
                    <td>{px_dor}</td>
                    <td>{px_condition}</td>
                    <td>{px_offered}</td>
                    <td>{px_settlement}</td>
                </tr>
            </table>
        </div>`;
};
//                    <td>{partExchange}</td>

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Enquiry', path: '/', active: true }
];

const EnquirieList = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    let startDate = moment().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        moiType: '',
        isReload: null,
        franchiseIds: [],
        bnIds: [],
        branchList: [],
        franchise: []
    });

    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const [selectedData, setSelectedData] = useState({
        brnIds: [],
        fnIds: []
    });
    useEffect(async () => {
        let DropDownRes = await getReportsDropdowns();
        if (DropDownRes.success) {
            setState((st) => ({
                ...st,
                branchList: DropDownRes.data.branch,
                franchise: DropDownRes.data.franchiseCode
            }));
        }
    }, []);
    useEffect(() => {
        setState((st) => ({
            ...st,
            startDate: params.get('start') || startDate,
            endDate: params.get('end') || endDate,
            moiType: params.get('type') || '',
            franchiseIds: props.location?.state?.fnCode,
            bnIds: props.location?.state?.bn
        }));
    }, [window.location.search]);

    useEffect(() => {
        if (state.branchList.lenght > 0 || state.franchise.length > 0) {
            const selectedfranchises = state.franchise.filter((w) => props.location?.state?.fnCode?.indexOf(w.id) > -1);
            const selectedCust = state.branchList.filter((w) => props.location?.state?.bn?.indexOf(w.id) > -1);
            setSelectedData((st) => ({
                ...st,
                brnIds: selectedCust,
                fnIds: selectedfranchises
            }));
        }
    }, [props.location?.state, state.branchList, state.franchise]);

    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };
    const handleChanges = (e, value) => {
        setSelectedData((st) => ({ ...st, brnIds: value }));
        setState((st) => {
            const nst = { ...st, bnIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handleChangesfranch = (e, value) => {
        setSelectedData((st) => ({ ...st, fnIds: value }));
        setState((st) => {
            const nst = { ...st, franchiseIds: value.map((c) => c.id) };
            return nst;
        });
    };
    let FranchiseCode = `FranchiseCode=${state.franchiseIds.join('&FranchiseCode=')}`;
    let BranchID = state.bnIds.length > 0 ? `BranchID=${state.bnIds.join('&BranchID=')}` : '';
    const RenderFinance = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <div>
                {record.isfinanced}({record.financeterm})
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let isFinancedCol = tempCols.find((element) => element.field === 'isfinanced');
        if (isFinancedCol) {
            isFinancedCol.renderer = RenderFinance();
        }
        return tempCols;
    }, []);
    const baseUrl = `AgedMeasures/GetEnquirieList?moi_type=${state.moiType}&StartDate=${state.startDate}&EndDate=${state.endDate}&${FranchiseCode}&${BranchID}`;
    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <Grid container style={{ padding: '0px 10px', textAlign: 'left' }}>
                <Hidden only={['lg', 'md', 'xl']}>
                    <Grid item xs={12}>
                        <div style={{ float: 'right' }}>
                            <SecondaryButton onClick={handleChange}>
                                <FilterListIcon />
                                &nbsp; Filters
                            </SecondaryButton>
                        </div>
                    </Grid>
                    <Collapse in={checked}>
                        <Grid item container spacing={2} xs={12}>
                            <Grid item xs={12}>
                                <FormLabel component="legend" style={{ marginBottom: 2 }} className="mandatoryfields">
                                    Branch
                                </FormLabel>
                                <Multiselect value={selectedData.brnIds} options={state.branchList || []} onChange={handleChanges} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel component="legend" style={{ marginBottom: 2 }} className="mandatoryfields">
                                    Franchise
                                </FormLabel>
                                <Multiselect value={selectedData.fnIds || []} options={state.franchise || []} onChange={handleChangesfranch} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                    Start Date
                                </FormLabel>
                                <DatePicker size="large" placeholder="Start Date" name="startDate" fullWidth value={state.startDate} onChange={fieldChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                    End Date
                                </FormLabel>
                                <DatePicker size="large" placeholder="End Date" name="endDate" fullWidth value={state.endDate} onChange={fieldChange} />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Hidden>
                <Hidden only={['sm', 'xs']}>
                    <Grid item container spacing={2} xs={12}>
                        <Grid item xs={6} md={3} lg={4}>
                            <FormLabel component="legend" style={{ marginBottom: 2 }} className="mandatoryfields">
                                Branch
                            </FormLabel>
                            <Multiselect value={selectedData.brnIds} options={state.branchList || []} onChange={handleChanges} />
                        </Grid>
                        <Grid item xs={6} md={3} lg={4}>
                            <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 2 }}>
                                Franchise
                            </FormLabel>
                            <Multiselect value={selectedData.fnIds || []} options={state.franchise || []} onChange={handleChangesfranch} />
                        </Grid>
                        <Grid item xs={4} md={3} lg={2}>
                            <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                Start Date
                            </FormLabel>
                            <DatePicker placeholder="Start Date" name="startDate" fullWidth value={state.startDate} onChange={fieldChange} />
                        </Grid>
                        <Grid item xs={4} md={3} lg={2}>
                            <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                End Date
                            </FormLabel>
                            <DatePicker placeholder="End Date" name="endDate" fullWidth value={state.endDate} onChange={fieldChange} />
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid container>
                    <NestedGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        displayPagination={true}
                        detailTemplate={detailTemplate}
                        isReload={state.isReload}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default EnquirieList;
