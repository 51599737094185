import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Grid, Select, IconButton, Dialog } from '@material-ui/core'; // Removed unnecessary imports
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { AppStorage } from '../../../../../Core/Service/storage-service';

import EditIcon from '@material-ui/icons/Edit';
import { SquareButton } from '../../../../../Core/FormInput/AppButton';
import { postCategoryData, postFinanceProviders, postStockingCharges } from '../../../../../Core/Service/SalesPerformance-service';
import AppContext from '../../../../../App/AppContext';
import { getFPProviderDropdownList, getFPProviderList } from '../../../../../Core/Service/ReportScreens-service';

import ReportGijgoGrid from '../../GijgoForReportsScreen';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { SelectBox } from '../../../../../Core/FormInput';

const baseColumns = [
    { field: 'letterIdentifier', title: 'Letter', align: 'center', filterable: true, sortable: false, width: 100 },
    { field: 'franchiseName', title: 'Franchise Name', filterable: true },
    { field: 'financeProviderID', title: 'Finance Provider', align: 'left', filterable: false, sortable: false, width: 600 },
    { field: 'vehicleCategoryID', title: 'Category', align: 'left', filterable: false, sortable: false, width: 600 }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Franchise', active: true }
];

const FranchiseScreen = (props) => {
    const { showToast } = useContext(AppContext);
    let menuPermissionCounter = AppStorage.menuPermissionCounter();
    const history = useHistory();
    const [state, setState] = useState({
        showModal: false,
        stockObj: {},
        isReload: false,
        providerList: []
    });

    const [providerList, setProviderList] = useState({
        fpList: [],
        categoryList: []
    });

    useEffect(() => {
        async function fetchData() {
            let res = await getFPProviderDropdownList();
            if (res.success) {
                setProviderList((st) => ({
                    ...st,
                    isReload: new Date(),
                    fpList:
                        res.data.fp.map((m) => ({
                            id: m.id,
                            name: m.name
                        })) || [],
                    categoryList:
                        res.data.category.map((m) => ({
                            id: m.categoryID,
                            name: m.name
                        })) || []
                }));
            }
        }
        fetchData();
    }, []);

    const HandleFpSaveData = (value, record) => async (e) => {
        let data = {
            ID: e.target.value,
            letterIdentifier: record.letterIdentifier
        };

        let res = await postFinanceProviders(data);
        if (res.success) {
            showToast('Finance Provider Added Successfully');
        }

        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const HandleCategorySaveData = (value, record) => async (e) => {
        let data = {
            vehicleCategoryID: e.target.value,
            letterIdentifier: record.letterIdentifier
        };

        let res = await postCategoryData(data);
        if (res.success) {
            showToast('Category Added Successfully');
        }

        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const fpColumnComp = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid title={value} style={{ cursor: 'pointer', textAlign: 'center' }}>
                <SelectBox List={providerList.fpList} onChange={HandleFpSaveData(value, record)} value={value} />
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const categoryColumnComp = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid title={value} style={{ cursor: 'pointer', textAlign: 'center' }}>
                <SelectBox List={providerList.categoryList} onChange={HandleCategorySaveData(value, record)} value={value} />
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'financeProviderID');

        if (actionCol) {
            actionCol.renderer = fpColumnComp;
        }

        let categoryActionCol = tempCols.find((element) => element.field === 'vehicleCategoryID');

        if (categoryActionCol) {
            categoryActionCol.renderer = categoryColumnComp;
        }

        return tempCols;
    }, [providerList]);

    const baseUrl = 'FinanceProviders/Franchise';

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res.data,
                            total: res?.data?.length
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isShowTotal={false}
                        hidePagination={false}
                        hideFilterRow={false}
                        isReload={state.isReload}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default FranchiseScreen;
