import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions, SelectBox } from '../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { addOcrDocument, getOcrDocument, getTableList, getOcrApiList, getTableColumnsList } from '../../../Core/Service/OcrDocuments-service';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import '../User Management/UserManagement.css';
import { YesNoButton } from '../../../Core/FormInput';
import { getProcesses } from '../../../Core/Service/process-service';

const AddOcrDocumentsModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        name: '',
        tableName: '',
        OcrApiId: null,
        columnName: '',
        tableList: [],
        ocrList: [],
        columnNameList: [],
        OCRDocumentIgnoreDepartment: false,
        processID: props.processID
    });
    const OcrDocumentId = props.OcrDocumentId;
    // useEffect(async () => {
    //     let res1 = await getTableColumnsList(state.tableName);
    //     if (res1.data) {
    //         setState((st) => ({
    //             ...st,
    //             columnNameList: res1.data.rows
    //         }));
    //     }
    // }, [state.tableName]);
    useEffect(async () => {
        let res = await getProcesses();
        if (res.success) {
            setState((st) => ({
                ...st,
                ProcessList: res.data.map((m) => ({ name: m.processName, id: m.processID }))
            }));
        }

        if (OcrDocumentId) {
            let result = await getOcrDocument(OcrDocumentId);
            const ocrDoc = result.data.rows;
            if (result.data) {
                setState((st) => ({
                    ...st,
                    name: ocrDoc.name,
                    tableName: ocrDoc.tableName,
                    OcrApiId: ocrDoc.ocrApiId,
                    columnName: ocrDoc.columnName,
                    OCRDocumentIgnoreDepartment: ocrDoc.ocrDocumentIgnoreDepartment,
                    processID: ocrDoc.processID || null
                }));
            }
        }
        // let pros = [];
        // pros.push(getTableList(), getOcrApiList());
        // let responses = await Promise.all(pros);
        // if (responses[0].success && responses[1].success) {
        //     setState((st) => ({
        //         ...st,
        //         tableList: responses[0].data.rows,
        //         ocrList: responses[1].data.rows
        //     }));
        // }
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handelSubmit = async (e) => {
        e.preventDefault();

        if (state.name) {
            let res = await addOcrDocument(
                OcrDocumentId,
                state.name,
                state.OcrApiId,
                state.tableName,
                state.columnName,
                state.OCRDocumentIgnoreDepartment,
                state.processID
            );
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
                props.onFormSubmit(true);
            }
            showToast(res.message);
        } else {
            showToast('Name is required.');
        }
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="sm">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{OcrDocumentId ? <DialogTitles>Update OCR Document</DialogTitles> : <DialogTitles>Add OCR Document</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Name</FormLabel>
                        <TextBox autoFocus name="name" onChange={fieldChange} value={state.name} />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <FormLabel>Select Process</FormLabel>
                        <SelectBox onChange={fieldChange} value={state.processID} name="processID" List={state.ProcessList || []} />
                    </Grid> */}
                    <Grid xs={12} container item>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel>Ignore Department?</FormLabel>
                        </Grid>
                        <YesNoButton
                            state={state.OCRDocumentIgnoreDepartment}
                            name="OCRDocumentIgnoreDepartment"
                            onYesClick={() => handleClick('OCRDocumentIgnoreDepartment', true)}
                            onNoClick={() => handleClick('OCRDocumentIgnoreDepartment', false)}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            Select Table Name
                        </FormLabel>
                        <TextBox
                            select
                            value={state.tableName}
                            name="tableName"
                            onChange={fieldChange}
                            rows={2}
                        >
                            {state.tableList.length > 0 &&
                                state.tableList.map((option) => (
                                    <MenuItem
                                        key={option.tablE_NAME}
                                        value={option.tablE_NAME}
                                    >
                                        {option.tablE_NAME}
                                    </MenuItem>
                                ))}
                        </TextBox>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            Select Column Name
                        </FormLabel>
                        <TextBox
                            select
                            value={state.columnName}
                            name="columnName"
                            onChange={fieldChange}
                            variant="outlined"
                            rows={2}
                        >
                            {state.columnNameList.length > 0 &&
                                state.columnNameList.map((option) => (
                                    <MenuItem
                                        key={option.columN_NAME}
                                        value={option.columN_NAME}
                                    >
                                        {option.columN_NAME}
                                    </MenuItem>
                                ))}
                        </TextBox>
                    </Grid>
                    */}
                    {/* <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Select OCR Api</FormLabel>
                        <Select
                            className="setHeight"
                            variant="outlined"
                            fullWidth
                            label="Select OCR Api"
                            id="demo-simple-select"
                            name="OcrApiId"
                            value={state.OcrApiId}
                            onChange={fieldChange}
                        >
                            {state.ocrList.length > 0 &&
                                state.ocrList.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid> */}
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddOcrDocumentsModal;
